import { Component, ElementRef, ViewChild } from '@angular/core'

@Component({
  selector: 'app-ticker',
  template: `
    <div
      #container
      class="ticker-container"
      (mouseenter)="startTicker()"
      (mouseleave)="stopTicker()"
    >
      <div class="ticker-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: `
    .ticker-container {
      white-space: nowrap;
      overflow: hidden;
      height: var(--container-height, fit-content);
    }
    .ticker-content {
      display: var(--content-display, block);
      transition: transform 0.5s linear;
      transform: translateX(var(--offset-x, 0));
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
})
export class TickerComponent {
  @ViewChild('container')
  public readonly containerRef!: ElementRef<HTMLElement>

  protected animationInterval?: any

  public startTicker() {
    const el = this.containerRef.nativeElement
    if (el) {
      const initialHeight = el.offsetHeight
      el.style.setProperty('--content-display', 'inline-block')
      el.style.setProperty('--container-height', `${initialHeight}px`)

      const maxOffset = el.scrollWidth - el.offsetWidth
      if (maxOffset > 0) {
        let currentOffset = 0
        const animate = () => {
          if (currentOffset > maxOffset) {
            return this.stopTicker(false)
          }
          currentOffset += 20
          el.style.setProperty('--offset-x', `-${currentOffset}px`)
        }

        animate()
        this.animationInterval = setInterval(animate, 510)
      }
    }
  }

  public stopTicker(reset = true) {
    // Cancel active animation
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }

    if (reset) {
      this.containerRef.nativeElement.style.removeProperty('--offset-x')
      setTimeout(() => {
        this.containerRef.nativeElement.style.removeProperty('--content-display')
        this.containerRef.nativeElement.style.removeProperty('--container-height')
      }, 125)
    }
  }
}
