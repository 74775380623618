export interface Channel {
  id: number
  host: string
  port: number
  protocolId: number
  createdAt: Date
}

export enum ChannelType {
  Telemetry,
  Video,
}
