import { isPlatformBrowser } from '@angular/common'
import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  NgZone,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import { Tooltip } from 'primeng/tooltip'
import { DeviceService } from '../../i18n'
import { MapAdapter } from '@ti-platform/web/map'
import { injectDestroy$ } from '@ti-platform/web/common'
import { takeUntil, throttleTime } from 'rxjs'

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective extends Tooltip implements AfterViewInit {
  @Input() tooltipMobile = false
  @Input() tooltipDisableOnMobile = false

  @Input('appTooltip')
  public override content: string | TemplateRef<HTMLElement> | undefined

  protected readonly deviceService = inject(DeviceService)
  protected readonly mapAdapter = inject(MapAdapter, { optional: true })
  protected readonly destroy$ = injectDestroy$()

  private _forceActivate = false
  private _platformId!: any

  get tooltipForceActivate() {
    return this._forceActivate
  }

  @Input()
  set tooltipForceActivate(value: boolean) {
    this._forceActivate = value
    if (this.tooltipForceActivate) {
      this.activate()
      this._forceActivate = false
    }
  }

  _forceDeactivate = false
  get tooltipForceDeactivate() {
    return this._forceDeactivate
  }

  @Input()
  set tooltipForceDeactivate(value: boolean) {
    this._forceDeactivate = value
    if (this.tooltipForceDeactivate) {
      this.deactivate()
      this._forceDeactivate = false
    }
  }

  constructor(
    public override el: ElementRef,
    public override zone: NgZone,
    public override config: PrimeNGConfig,
    renderer: Renderer2,
    viewContainer: ViewContainerRef,
  ) {
    const platformId = inject(PLATFORM_ID)
    super(platformId, el, zone, config, renderer, viewContainer)
    this._platformId = platformId
  }

  elementClickListener: any
  windowClickListener: any

  override ngAfterViewInit() {
    if (this.tooltipDisableOnMobile && this.deviceService.isMobile()) {
      return
    }

    super.ngAfterViewInit()

    // Fix for tooltips inside map component
    if (this.mapAdapter) {
      this.mapAdapter.onMove$
        .pipe(
          throttleTime(1500, undefined, { leading: true, trailing: true }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.onWindowClickListener()
        })
    }

    if (isPlatformBrowser(this._platformId)) {
      this.zone.runOutsideAngular(() => {
        const tooltipEvent = this.getOption('tooltipEvent')

        if (
          (tooltipEvent === 'hover' || tooltipEvent === 'both') &&
          this.deviceService.isMobilePlatform &&
          this.tooltipMobile
        ) {
          this.elementClickListener = this.onElementClick.bind(this)
          this.windowClickListener = this.onWindowClickListener.bind(this)
          this.el.nativeElement.addEventListener('click', this.elementClickListener)
          window.addEventListener('click', this.windowClickListener, true)
        }
      })
    }
  }

  onElementClick(e: Event) {
    if (!this.container && !this.showTimeout) {
      this.activate()
    }
  }

  onWindowClickListener() {
    this.deactivate()
  }

  override unbindEvents() {
    super.unbindEvents()

    const tooltipEvent = this.getOption('tooltipEvent')

    if (
      (tooltipEvent === 'hover' || tooltipEvent === 'both') &&
      this.deviceService.isMobilePlatform &&
      this.tooltipMobile
    ) {
      this.el.nativeElement.removeEventListener('click', this.elementClickListener)
      window.removeEventListener('click', this.windowClickListener)
    }
  }
}
