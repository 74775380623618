import { inject, Injectable } from '@angular/core'
import { NewPasswordViewModel } from '@ti-platform/web/auth/components'
import { ApiService } from '@ti-platform/web/api'

@Injectable()
export class TspAdminNewPasswordViewModel extends NewPasswordViewModel {
  protected readonly api = inject(ApiService)

  protected async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    await this.api.tsp.user.changePassword(currentPassword, newPassword)
  }

  protected async confirmPasswordReset(password: string, secret: string): Promise<void> {
    await this.api.tsp.user.confirmPasswordReset(password, secret)
  }
}
