import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-fleets-count',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 18.3333V6.2963L9.99984 1.66667L18.3332 6.2963V18.3333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 13.889V12.3587C5 11.9163 5.17564 11.492 5.48833 11.179L6.11111 10.5557H13.8889L14.5117 11.1784C14.8243 11.4911 15 11.9152 15 12.3574V13.889C15 14.5026 14.5025 15.0001 13.8889 15.0001H6.11111C5.49746 15.0001 5 14.5026 5 13.889Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 13.8887V15.8331C15.0001 16.1399 14.7513 16.3887 14.4445 16.3887H13.3334C13.0266 16.3887 12.7778 16.1399 12.7778 15.8331V14.9998"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.22222 14.9998V15.8331C7.22222 16.1399 6.97349 16.3887 6.66667 16.3887H5.55556C5.24873 16.3887 5 16.1399 5 15.8331V13.8887"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9998 10.0002L13.8887 10.278"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.11111 10.278L5 10.0002"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.11133 10.5556V10.2744L6.57577 8.53167C6.73789 7.92331 7.2888 7.49999 7.91838 7.5H12.131C12.7739 7.5 13.3328 7.94128 13.4819 8.56667L13.8891 10.2744V10.5556"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 12.7778H7.77762"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2222 12.7778H13.3333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FleetsCountIconComponent extends BaseIconComponent {}
