import { inject, Injectable } from '@angular/core'
import { Action, DataQueryProps, Resource, UserProfile, UserRole } from '@ti-platform/contracts'
import { AuthSession, Profile } from '@ti-platform/web/auth'
import {
  AccessControl,
  ErrorHandler,
  ListModel,
  ListModelConfig,
  ListModelState,
} from '@ti-platform/web/common'
import {
  DataGridColumnActionsFn,
  DataGridColumnType,
} from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { combineLatest, map, Observable, shareReplay, takeUntil } from 'rxjs'
import { UserRolesProvider } from '../providers'

export type UserGridData = {
  user: UserProfile
  role: string
  lastActivity: {
    type: string
    date: Date | string
  }
}

export type UsersListState = ListModelState<UserProfile> & {
  gridData$: Observable<UserGridData[] | null>
}

@Injectable()
export abstract class BaseUsersModel extends ListModel<UserProfile, UsersListState> {
  public hideCurrentUser = true

  protected readonly accessControl = inject(AccessControl)
  protected readonly errorHandler = inject(ErrorHandler)
  protected readonly profile = inject(Profile)
  protected readonly messageService = inject(MessageService)
  protected readonly authSession = inject(AuthSession)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly userOptionsProvider = inject(UserRolesProvider)

  protected isResendInvitationLoading = false

  // TODO: Move translation keys to method
  protected readonly createMessageSummaryKey: string = 'users.created-message.summary'
  protected readonly createMessageDetailKey: string = 'users.created-message.detail'
  protected readonly updateMessageSummaryKey: string = 'users.updated-message.summary'
  protected readonly updateMessageDetailKey: string = 'users.updated-message.detail'
  protected readonly deleteMessageSummaryKey: string = 'users.deleted-message.summary'
  protected readonly deleteMessageDetailKey: string = 'users.deleted-message.detail'
  protected readonly resendInviteMessageSummaryKey: string = 'users.invited-message.summary'
  protected readonly resendInviteMessageDetailKey: string = 'users.invited-message.detail'
  protected abstract modelName: string

  public async create(user: UserProfile) {
    try {
      await this.createUser(user)
      await this.displaySuccessMessage(this.createMessageSummaryKey, this.createMessageDetailKey, {
        email: user.email,
      })
      this.reset()
    } catch (error) {
      await this.errorHandler.notifyUser(error, 'Cannot create user')
    }
  }

  public async update(user: UserProfile) {
    try {
      await this.updateUser(user.id, user)
      await this.displaySuccessMessage(this.updateMessageSummaryKey, this.updateMessageDetailKey)
      this.reset()
    } catch (error) {
      await this.errorHandler.notifyUser(error, 'Cannot update user')
    }
    this.unselect()
  }

  public async resendInvitation(data: UserGridData) {
    if (!this.isResendInvitationLoading) {
      this.isResendInvitationLoading = true
      try {
        await this.sendInviteEmail(data.user.id)
        await this.displaySuccessMessage(
          this.resendInviteMessageSummaryKey,
          this.resendInviteMessageDetailKey,
          { email: data.user.email },
        )
      } catch (error) {
        await this.errorHandler.notifyUser(error, 'Cannot resend the invite')
      } finally {
        this.isResendInvitationLoading = false
      }
    }
  }

  protected async delete(user: UserProfile) {
    const labels = await this.getDeleteDialogLabels(user.name)
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      try {
        await this.deleteUser(user.id)
        await this.displaySuccessMessage(
          this.deleteMessageSummaryKey,
          this.deleteMessageDetailKey,
          { name: user.name },
        )
        this.reset()
        this.createCountStream()
      } catch (error) {
        await this.errorHandler.notifyUser(error, 'Cannot delete user')
      }
    }
  }

  protected override loadState(): UsersListState {
    const data = super.loadState()
    return {
      ...data,
      gridData$: combineLatest([
        this.authSession.state.currentUserAttributes$,
        data.items$,
        this.userOptionsProvider.userRoleLabels$,
      ]).pipe(
        takeUntil(this.destroy$),
        map(([currentUser, users, roleLabels]) => {
          return users
            .filter((user) => {
              return this.hideCurrentUser ? user.id !== currentUser?.sub : true
            })
            .map((user) => {
              return {
                user: user,
                role: roleLabels[user.roleId],
                lastActivity: user.inviteAcceptedAt
                  ? {
                      type: 'login',
                      date: user.inviteAcceptedAt,
                    }
                  : {
                      type: 'invite',
                      date: user.invitedAt as Date,
                    },
              }
            })
        }),
      ),
    } as UsersListState
  }

  protected async getDeleteDialogLabels(name: string) {
    return this.languageService.massTranslate(
      {
        summary: 'users.delete-dialog.summary',
        description: 'users.delete-dialog.description',
        confirmButton: 'users.delete-dialog.confirm-button',
        cancelButton: 'users.delete-dialog.cancel-button',
      },
      { name },
    )
  }

  protected config(): ListModelConfig {
    return {
      name: this.modelName,
      loadCount: () => this.loadCount(),
      gridColumns: [
        {
          field: 'user',
          label: 'users.grid.user',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'role',
          label: 'users.grid.role',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'lastActivity',
          label: 'users.grid.last-activity',
          type: DataGridColumnType.Template,
          sortable: false,
        },
        {
          field: 'loginAs',
          label: '',
          type: DataGridColumnType.Template,
          sortable: false,
        },
        {
          field: 'actions',
          label: '',
          type: DataGridColumnType.Actions,
          actions: ((data: UserGridData) => {
            return combineLatest([
              this.profile.state,
              this.accessControl.massCheck$([
                [Resource.User, Action.Update],
                [Resource.User, Action.Delete],
              ]),
            ]).pipe(
              map(([user, [canUpdate, canDelete]]) => {
                if (
                  !user ||
                  (user.roleId >= data.user.roleId && user.roleId !== UserRole.FleetDemo)
                ) {
                  return []
                }
                return [
                  {
                    label: 'users.grid.edit-btn',
                    icon: 'pi pi-pencil',
                    styleClass: `has-bottom-border ${canUpdate ? '' : 'not-allowed'}`,
                    command: () => this.select(data.user),
                  },
                  {
                    label: 'users.grid.delete-btn',
                    icon: 'pi pi-trash',
                    styleClass: `error ${canDelete ? '' : 'not-allowed'}`,
                    command: () => this.delete(data.user),
                  },
                ]
              }),
              takeUntil(this.destroy$),
              shareReplay({ bufferSize: 1, refCount: false }),
            )
          }) as DataGridColumnActionsFn,
        },
      ],
    }
  }

  protected async loadCount() {
    const count = await this.countAllUsers()
    return count - (this.hideCurrentUser ? 1 : 0)
  }

  protected async displaySuccessMessage(summary: string, detail: string, ctx?: object) {
    const labels = await this.languageService.massTranslate({ summary, detail }, ctx)
    this.messageService.add({ ...labels, life: 5000, severity: 'success' })
  }

  protected abstract createUser(user: UserProfile): Promise<unknown>

  protected abstract updateUser(id: string, user: UserProfile): Promise<unknown>

  protected abstract deleteUser(userId: string): Promise<unknown>

  protected abstract sendInviteEmail(userId: string): Promise<unknown>

  protected abstract countAllUsers(): Promise<number>

  protected abstract override loadPage(props: DataQueryProps): Promise<UserProfile[]>
}
