import { Injectable } from '@angular/core'

import { MemoryStorage } from './memory.storage'
import { SessionStorage } from '../../contracts/storage/session-storage.interface'
import { AbstractStorage } from '../../contracts/storage/abstract-storage.interface'
import { isStorageAvailable } from '../../utils/is-storage-available'

@Injectable()
export class BrowserSessionStorage implements SessionStorage {
  /**
   * Contains available storage implementation
   *
   * @param {AbstractStorage}
   */
  private readonly storage: AbstractStorage

  public constructor() {
    if (isStorageAvailable('sessionStorage')) {
      this.storage = window.sessionStorage
    } else {
      this.storage = new MemoryStorage()
    }
  }

  get length(): number {
    return this.storage.length
  }

  clear(): void {
    this.storage.clear()
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key)
  }

  key(index: number): string | null {
    return this.storage.key(index)
  }

  removeItem(key: string): void {
    this.storage.removeItem(key)
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value)
  }
}
