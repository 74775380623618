import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-camera',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3_70522"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          d="M21.2499 21.25V2.75007H2.75V21.25H21.2499Z"
          fill="white"
          stroke="white"
          style="fill:white;fill-opacity:1;stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
        />
      </mask>
      <g mask="url(#mask0_3_70522)">
        <path
          d="M19.1874 21.4141H4.8125C4.8125 21.4141 5.0332 17.6121 8.65155 16.1071C9.65154 16.6332 10.791 16.9313 12 16.9313C13.209 16.9313 14.3484 16.6332 15.3484 16.1071C18.9667 17.6121 19.1874 21.4141 19.1874 21.4141Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.1874 9.75901C19.1874 12.5137 17.6312 14.9055 15.3484 16.1074C14.3484 16.6336 13.209 16.9316 12 16.9316C10.791 16.9316 9.65154 16.6336 8.65155 16.1074C6.36874 14.9055 4.8125 12.5137 4.8125 9.75901C4.8125 5.7977 8.03046 2.58638 12 2.58638C15.9695 2.58638 19.1874 5.7977 19.1874 9.75901Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.1445 9.75885C15.1445 11.4919 13.7367 12.8969 12 12.8969C10.2633 12.8969 8.85547 11.4919 8.85547 9.75885C8.85547 8.02577 10.2633 6.62086 12 6.62086C13.7367 6.62086 15.1445 8.02577 15.1445 9.75885Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0001 9.59473C11.9374 9.59473 11.9049 9.61862 11.8904 9.63277C11.877 9.64574 11.849 9.6812 11.8396 9.73973C11.8396 9.73973 11.8396 9.73973 11.8396 9.73973C11.8371 9.75558 11.8359 9.77311 11.8368 9.79235C11.8368 9.79235 11.8368 9.79236 11.8368 9.79236C11.8377 9.8142 11.8467 9.84236 11.8727 9.8691C11.9023 9.89967 11.9504 9.92286 12.0001 9.92286C12.0627 9.92286 12.0952 9.89896 12.1097 9.88481C12.1231 9.87184 12.1511 9.83639 12.1605 9.77786C12.1605 9.77785 12.1605 9.77785 12.1605 9.77785C12.1631 9.762 12.1642 9.74447 12.1634 9.72523C12.1634 9.72523 12.1634 9.72522 12.1634 9.72522C12.1624 9.70338 12.1534 9.67522 12.1275 9.64848C12.0978 9.61792 12.0497 9.59473 12.0001 9.59473Z"
          fill="#7F56D9"
          [attr.stroke]="color"
          style="fill-opacity:1;stroke-opacity:1;"
          stroke-width="1.5"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CameraIconComponent extends BaseIconComponent {}
