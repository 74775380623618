import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-battery',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 4.66732H14.6667C15.0347 4.66732 15.3333 4.96598 15.3333 5.33398V12.0007C15.3333 12.7373 14.7367 13.334 14 13.334H3.33333C2.59667 13.334 2 12.7373 2 12.0007V5.33398C2 4.96598 2.29867 4.66732 2.66667 4.66732H5.33333L6.47133 3.52932C6.59667 3.40398 6.766 3.33398 6.94267 3.33398H10.3907C10.5673 3.33398 10.7373 3.40398 10.862 3.52932L12 4.66732Z"
        [attr.fill]="color"
        [attr.stroke]="color"
        style="fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 4.66699V3.33366C12 2.96566 12.2987 2.66699 12.6667 2.66699H14C14.368 2.66699 14.6667 2.96566 14.6667 3.33366V4.66699"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9993 7.66699V9.66699"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 8.66732H11"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33398 8.66732H4.33398"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66602 4.66699V3.33366C2.66602 2.96566 2.96468 2.66699 3.33268 2.66699H4.66602C5.03402 2.66699 5.33268 2.96566 5.33268 3.33366V4.66699"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class BatteryIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
