import { Component, inject } from '@angular/core'
import { DevicesMediaUsageReportModel } from '@ti-platform/tsp-admin/app/devices/models'
import { injectDestroy$, selectState } from '@ti-platform/web/common'
import { FormatDurationStyle } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-tsp-media-usage-grid',
  templateUrl: 'media-usage-grid.component.html',
  styleUrls: ['media-usage-grid.component.scss'],
})
export class MediaUsageGridComponent {
  protected readonly destroy$ = injectDestroy$()
  protected readonly model = inject(DevicesMediaUsageReportModel)
  protected readonly state = selectState(DevicesMediaUsageReportModel)

  protected readonly FormatDurationTypes = FormatDurationStyle

  protected get noDataDescriptionKey() {
    return this.model.isActiveSearch
      ? 'search.no-data-description'
      : 'tsp-admin.devices.media-usage.no-video-devices'
  }

  protected get noDataTitleKey() {
    return this.model.isActiveSearch ? 'search.no-data-title' : 'tsp-admin.devices.no-data.title'
  }
}
