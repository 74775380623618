import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-no-alert',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3335 7.66699L27.3335 3.66699"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3335 7.66699L23.3335 3.66699"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 29V11.93"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 22.02V12"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 22C5.5 22 6.375 21.273 9 21.273C11.625 21.273 13.375 23 16 23C18.625 23 19.5 22.023 19.5 22.023"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 11.932C5.5 11.932 6.375 11 9 11C11.625 11 13.375 12.727 16 12.727C18.625 12.727 19.5 12 19.5 12"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NoAlertIconComponent extends BaseIconComponent {
  @Input() override height = 32
  @Input() override width = 32
}
