import { Pipe, PipeTransform } from '@angular/core'
import { FormatDurationStyle } from '../contracts'

@Pipe({ name: 'formatDuration' })
export class FormatDurationPipe implements PipeTransform {
  public transform(
    value: number | undefined,
    type: FormatDurationStyle = FormatDurationStyle.Fleet,
  ): string {
    switch (type) {
      case FormatDurationStyle.Fleet:
        return this.transformFleet(value)
      case FormatDurationStyle.Alert:
        return this.transformAlert(value)
      case FormatDurationStyle.Clock:
        return this.transformClock(value)
    }
  }

  public transformFleet(value: number | undefined): string {
    if (value === 0) {
      return '0h 0m'
    } else if (!value) {
      return ''
    }

    const totalSeconds = Math.abs(value / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = totalSeconds < 60 ? 1 : Math.floor((totalSeconds - hours * 3600) / 60)

    return `${hours}h ${minutes}m`
  }

  public transformAlert(value: number | undefined): string {
    if (value === 0) {
      return '0s'
    } else if (!value) {
      return ''
    }
    const totalSeconds = Math.abs(value / 1000)
    if (totalSeconds < 60) {
      return `${totalSeconds.toFixed(0)}s`
    } else if (totalSeconds < 3600) {
      const minutes = Math.floor(totalSeconds / 60)
      const seconds = totalSeconds - minutes * 60
      return seconds ? `${minutes.toFixed(0)}m ${seconds.toFixed(0)}s` : `${minutes.toFixed(0)}m`
    } else if (totalSeconds < 86400) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      return `${hours.toFixed(0)}h ${minutes.toFixed(0)}m`
    } else {
      const days = Math.floor(totalSeconds / 86400)
      const hours = Math.floor((totalSeconds - days * 86400) / 3600)
      return `${days.toFixed(0)}d ${hours.toFixed(0)}h`
    }
  }

  public transformClock(value: number | undefined) {
    if (!value) return ''
    const minutes = Math.floor(value / 60_000)
    const seconds = Math.floor((value - minutes * 60_000) / 1000)
    return `${minutes.toFixed(0).padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`
  }
}
