import { inject, Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { AppFeatures, MyProfile, PricingPlanFeatureMap, PricingPlans } from '@ti-platform/contracts'
import { Profile } from '@ti-platform/web/auth'

@Injectable({
  providedIn: 'root',
})
export class PricingPlanService {
  protected readonly profileModel = inject(Profile)

  public async areFeaturesAllowed(features: AppFeatures[]) {
    const user = await firstValueFrom(this.profileModel.state)
    return this.checkFeaturesAllowed(features, this.getUserPlanFeatures(user))
  }

  public async massAreFeaturesAllowed<T extends object>(
    features: Record<keyof T, AppFeatures[]>,
  ): Promise<Record<keyof T, boolean>> {
    const user = await firstValueFrom(this.profileModel.state)
    const userPricingPlanFeatures = this.getUserPlanFeatures(user)

    const result = {} as Record<keyof T, boolean>
    for (const key in features) {
      result[key] = this.checkFeaturesAllowed(features[key], userPricingPlanFeatures)
    }
    return result
  }

  protected getUserPlanFeatures(user: MyProfile) {
    return typeof user.fleet?.pricingPlanId === 'number'
      ? PricingPlanFeatureMap[user.fleet.pricingPlanId as PricingPlans]
      : []
  }

  protected checkFeaturesAllowed(
    featuresToCheck: AppFeatures[],
    userPricingPlanFeatures: AppFeatures[],
  ) {
    return featuresToCheck.length > 0
      ? featuresToCheck.every((feature) => userPricingPlanFeatures.includes(feature))
      : true
  }
}
