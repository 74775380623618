import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-dollar',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15.6C7 17.5882 8.49238 19.2 10.3333 19.2H13.6667C15.5076 19.2 17 17.5882 17 15.6C17 13.6118 15.5076 12 13.6667 12H10.3333C8.49238 12 7 10.3882 7 8.4C7 6.41177 8.49238 4.8 10.3333 4.8H13.6667C15.5076 4.8 17 6.41177 17 8.4M12 3V21"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DollarIconComponent extends BaseIconComponent {}
