import { Component, inject, OnInit } from '@angular/core'
import { Memoize } from '@ti-platform/web/common'
import { combineLatest, map, Observable } from 'rxjs'
import { DataGridColumn, DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '@ti-platform/web/api'
import { Action, Invoice, Resource } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-billing-dashboard',
  templateUrl: 'billing-dashboard.component.html',
  styleUrls: ['billing-dashboard.component.scss'],
})
export class BillingDashboardComponent implements OnInit {
  protected api = inject(ApiService)
  protected readonly languageService = inject(LanguageService)
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected invoice: Invoice | null = null
  protected readonly Resource = Resource
  protected readonly Action = Action
  protected billingStartDate: Date = new Date()
  protected billingEndDate: Date = new Date()

  @Memoize()
  protected get columns$(): Observable<DataGridColumn[]> {
    return combineLatest([
      this.languageService.translate$('tsp-admin.devices.grid.imei-serial'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.0'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.1'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.2'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.3'),
      this.languageService.translate$('tsp-admin.billing.grid.amount'),
    ]).pipe(
      map((labels) => {
        const [deviceIdentifier, tnt, basic, standard, pro, amount] = labels
        return [
          {
            field: 'deviceIdentifier',
            label: deviceIdentifier,
            type: DataGridColumnType.Text,
            sortable: false,
          },
          {
            field: 'tnt',
            label: tnt,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'basic',
            label: basic,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'standard',
            label: standard,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'pro',
            label: pro,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'amount',
            label: `${amount} ($)`,
            type: DataGridColumnType.Count,
            sortable: false,
          },
        ]
      }),
    )
  }

  async ngOnInit() {
    const today = new Date()

    const year = today.getUTCFullYear()
    const month = today.getUTCMonth() // 0 = January, 11 = December

    this.invoice = await this.api.tsp.billing.invoice(year, month)
    this.billingStartDate = new Date(today.getFullYear(), today.getMonth(), 1)
  }
}
