<header class="tsp-admin-header">
  <div class="heading-group flex">
    <h1 class="text-32 fw-600">{{ 'tsp-admin.menu.recycling-bin' | translate }}</h1>
  </div>
</header>

<div class="table-wrapper">
  <div class="table-footer no-data">
    <div class="no-data-icon mb-12">
      <ng-container [ngTemplateOutlet]="noDataIconTemplate"></ng-container>
    </div>

    <div class="title mb-4 text-18 fw-600">
      {{ 'tsp-admin.recycling-bin.grid.no-data-title' | translate }}
    </div>
    <div class="description text-14">
      {{ 'tsp-admin.recycling-bin.grid.no-data-description' | translate }}
    </div>
  </div>
</div>

<ng-template #noDataIconTemplate>
  <i>
    <app-icon-recycle-bin-disable [color]="'var(--color-gray-900)'"></app-icon-recycle-bin-disable>
  </i>
</ng-template>
