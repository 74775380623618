import { HttpClient } from '@angular/common/http'
import { inject, Injectable, Type } from '@angular/core'
import { StyleSpecification } from '@maplibre/maplibre-gl-style-spec'
import {
  BaseConfig,
  BrowserSessionStorage,
  CONFIG,
  injectDestroy$,
  WhiteLabelSettingsProvider,
} from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { AttributionControl, LngLatBounds, Map as MaplibreGlMap } from 'maplibre-gl'
import { distinctUntilChanged, firstValueFrom, skip, Subject, takeUntil, throttleTime } from 'rxjs'
import { Coordinates, MapBounds, MapPadding } from '../contracts'
import {
  BaseMarker,
  MaplibreStaticVehicleMarker,
  MaplibreStopMarker,
  MaplibreTripMarker,
  StaticVehicleMarkerOptions,
  StopMarkerOptions,
  TripMarkerOptions,
} from '../markers'
import { toLngLat } from '../utils'
import { AWS_TILES, DEFAULT_POSITION, MapAdapter, TilesSource } from './base'

@Injectable()
export class MapLibreMapAdapter extends MapAdapter {
  public override map!: MaplibreGlMap

  protected readonly config = inject<BaseConfig>(CONFIG)
  protected readonly device = inject(DeviceService)
  protected readonly styleFactory = new MaplibreStyleFactory()
  protected readonly destroy$ = injectDestroy$()

  public override zoomIn() {
    this.map.zoomIn()
  }

  public override zoomOut() {
    this.map.zoomOut()
  }

  public override reset() {
    this.map.setBearing(0)
    this.map.setPitch(0)
  }

  public override resize() {
    this.map?.resize()
  }

  public override moveTo(latLng: Coordinates, zoom = 8, speed = 3, padding?: number | MapPadding) {
    if (this.animationsDisabled()) {
      speed = 99
    }

    // Assign default padding
    if (!padding && this.padding$.value) {
      padding = this.padding$.value
    }

    this.map.flyTo({
      center: toLngLat(latLng),
      zoom,
      speed,
      animate: this.isTabActive() && speed < 20,
      padding,
    })
  }

  public override fitBounds(
    latLngRoute: Coordinates[],
    maxZoom = 16,
    speed = 3,
    padding?: number | MapPadding,
  ) {
    if (this.animationsDisabled()) {
      speed = 99
    }

    // Override the padding if greater than 20% of smallest side size
    if (typeof padding === 'number') {
      const canvas = this.map.getCanvas()
      const minSizePx = Math.min(canvas.offsetWidth, canvas.offsetHeight)
      if (this.device.isMobile() && minSizePx * 0.2 < padding) {
        padding = Math.floor(minSizePx * 0.2)
      }
    }

    // Assign default padding
    if (!padding && this.padding$.value) {
      padding = this.padding$.value
    }

    const bounds = latLngRoute.reduce(
      (bounds, coords) => bounds.extend(toLngLat(coords)),
      new LngLatBounds(toLngLat(latLngRoute[0]), toLngLat(latLngRoute[0])),
    )
    this.map.fitBounds(bounds, {
      maxZoom,
      speed,
      padding,
      animate: this.isTabActive() && speed < 20,
    })
  }

  public override getZoomLevel(): number {
    return this.map.getZoom()
  }

  public override getMaxZoomLevel(): number {
    return this.map.getMaxZoom()
  }

  public override isEasing(): boolean {
    return this.map.isEasing()
  }

  public override isMoving(): boolean {
    return this.map.isMoving()
  }

  public override getCenter(): Coordinates {
    const center = this.map.getCenter()
    return [center.lat, center.lng]
  }

  public override getBounds(): MapBounds {
    return this.map.getBounds()
  }

  public override getBearing(): number {
    return this.map?.getBearing() || 0
  }

  public override getPitch(): number {
    return this.map?.getPitch() || 0
  }

  public override getContainer(): HTMLElement {
    return this.map?.getContainer()
  }

  public override disableInteraction() {
    this.map.dragPan.disable()
    this.map.boxZoom.disable()
    this.map.scrollZoom.disable()
    this.map.dragRotate.disable()
    this.map.doubleClickZoom.disable()
  }

  public override async init(el: HTMLElement) {
    if (this.map || this.hostEl) {
      throw new Error(`Already initialized!`)
    }

    this.hostEl = el
    this.map = new MaplibreGlMap({
      container: el,
      style: await this.styleFactory.getStyle(this.tilesSource$.value, this.config),
      center: toLngLat(DEFAULT_POSITION),
      zoom: 4,
      attributionControl: false,
    })

    const attributionControl = new AttributionControl({ compact: true })
    this.map.addControl(attributionControl, this.device.isMobile() ? 'bottom-right' : 'top-left')

    this.onInit$.subscribe(() => {
      // Automatically collapse the attribution control
      if (attributionControl._container.classList.contains('maplibregl-compact-show')) {
        attributionControl._toggleAttribution()
      }
    })

    this.destroy$.subscribe(() => {
      this.map.remove()
      this.hostEl.remove()
    })

    // Switch style when tiles source is updated
    this.tilesSource$
      .pipe(skip(1), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(async () => {
        return this.map.setStyle(
          await this.styleFactory.getStyle(this.tilesSource$.value, this.config),
        )
      })

    this.initEventListeners()
    this.initResizeObserver()

    // Wait until map is initialized
    await new Promise((resolve) => this.map.once('load', resolve))

    setTimeout(() => {
      this.onInit$.next()
      this.onInit$.complete()
    }, 50)
  }

  public addMarker<T extends BaseMarker>(MarkerType: Type<T>, options: T['options']): T {
    return new MarkerType(options, this, this.getComponentFactory())
  }

  public addTripMarker(options: TripMarkerOptions): MaplibreTripMarker {
    return this.addMarker(MaplibreTripMarker, options)
  }

  public addStopMarker(options: StopMarkerOptions): MaplibreStopMarker {
    return this.addMarker(MaplibreStopMarker, options)
  }

  public addStaticVehicleMarker(options: StaticVehicleMarkerOptions): MaplibreStaticVehicleMarker {
    return this.addMarker(MaplibreStaticVehicleMarker, options)
  }

  protected animationsDisabled() {
    return (
      this.tilesSource$.value === TilesSource.Google ||
      this.tilesSource$.value === TilesSource.GoogleSatellite
    )
  }

  protected initResizeObserver() {
    const resize$ = new Subject<void>()

    resize$.pipe(takeUntil(this.destroy$), throttleTime(16)).subscribe(() => {
      this.map.resize()
      this.map.redraw()
    })

    const observer = new ResizeObserver(() => resize$.next())
    observer.observe(this.hostEl)
    this.destroy$.subscribe(() => {
      resize$.complete()
      observer.disconnect()
    })
  }

  protected initEventListeners() {
    const onClick = () => this.onClick$.next()
    const onDrag = () => this.onDrag$.next()
    const onMove = () => this.onMove$.next()
    const onMoveEnd = () => this.onMoveEnd$.next()
    const onZoom = () => this.onZoom$.next()
    const onPitch = () => this.state.pitch$.next(this.getPitch())
    const onRotate = () => this.state.bearing$.next(this.getBearing())

    this.map.on('click', onClick)
    this.map.on('drag', onDrag)
    this.map.on('move', onMove)
    this.map.on('moveend', onMoveEnd)
    this.map.on('zoom', onZoom)
    this.map.on('pitchend', onPitch)
    this.map.on('rotateend', onRotate)
    this.destroy$.subscribe(() => {
      this.destroy()
      this.map.off('click', onClick)
      this.map.off('drag', onDrag)
      this.map.off('move', onMove)
      this.map.off('moveend', onMoveEnd)
      this.map.off('zoom', onZoom)
      this.map.off('pitchend', onPitch)
      this.map.off('rotateend', onRotate)
    })
  }
}

export type GoogleMapsMapType = 'roadmap' | 'satellite'

export interface GoogleMapsSessionData {
  session: string
  expiry: string
  tileWidth: number
  tileHeight: number
  imageFormat: string
}

export const DEMO_GLYPHS_URL = 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf'
export const FONT_OPEN_SANS_SEMIBOLD = 'Open Sans Semibold'

@Injectable()
export class MaplibreStyleFactory {
  protected readonly config = inject(CONFIG)
  protected readonly httpService = inject(HttpClient)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly whiteLabelSettings = inject(WhiteLabelSettingsProvider)

  public async getStyle(tileSource: TilesSource, config: BaseConfig) {
    let style: StyleSpecification | string

    if (tileSource === TilesSource.MapTiler && !this.config.mapTilerApiKey) {
      tileSource = TilesSource.OSM
    }

    // Do not initialize google options if API key is not provided
    const whiteLabelSettings = await this.whiteLabelSettings.getData()
    if (
      !whiteLabelSettings.googleMapsKey &&
      (tileSource === TilesSource.Google || tileSource === TilesSource.GoogleSatellite)
    ) {
      tileSource = TilesSource.OSM
    }

    switch (tileSource) {
      case TilesSource.Standard:
        style = this.getAWS(config.awsRegion, config.awsMapName, config.awsMapApiKey)
        break

      case TilesSource.Satellite:
        style = this.getAWS(config.awsRegion, config.hereHybridMapName, config.hereHybridMapApiKey)
        break

      case TilesSource.HEREImagery:
        style = this.getAWS(
          config.awsRegion,
          config.hereSatelliteMapName,
          config.hereSatelliteMapApiKey,
        )
        break

      case TilesSource.EsriImagery:
        style = this.getAWS(
          config.awsRegion,
          config.esriSatelliteMapName,
          config.esriSatelliteMapApiKey,
        )
        break

      case TilesSource.MapTiler:
        style = this.getMapTiler(config.mapTilerStyleName, config.mapTilerApiKey)
        break

      case TilesSource.OSM:
        style = this.getOpenStreetMap()
        break

      case TilesSource.Google:
        style = await this.getGoogleMap(whiteLabelSettings.googleMapsKey, 'roadmap')
        break

      case TilesSource.GoogleSatellite:
        style = await this.getGoogleMap(whiteLabelSettings.googleMapsKey, 'satellite')
        break

      default:
        throw new Error(`The source '${tileSource}' is not supported`)
    }

    // Overwrite glyphs and fonts in AWS tiles
    if (AWS_TILES.includes(tileSource) && typeof style === 'string') {
      const data = await fetch(style).then((res) => res.json())
      if (data && typeof data === 'object') {
        data['glyphs'] = DEMO_GLYPHS_URL
        data.layers.forEach((layer: Record<string, any>) => {
          const layout = layer?.layout
          if (!layout?.['text-font']) return

          if (Array.isArray(layout['text-font']) && layout['text-font'].length) {
            layout['text-font'] = [FONT_OPEN_SANS_SEMIBOLD]
          } else if (
            layout['text-font'] &&
            typeof layout['text-font'] === 'object' &&
            layout['text-font']['stops']?.length
          ) {
            layout['text-font']['stops'].forEach((stop: [number, string[]]) => {
              stop[1] = [FONT_OPEN_SANS_SEMIBOLD]
            })
          }
        })

        style = data
      }
    }

    return style
  }

  public getAWS(region: string, mapName: string, apiKey: string) {
    return `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`
  }

  public getMapTiler(styleName: string, apiKey: string) {
    return `https://api.maptiler.com/maps/${styleName}/style.json?key=${apiKey}`
  }

  public getOpenStreetMap(): StyleSpecification {
    return {
      version: 8,
      sources: {
        'raster-tiles': {
          type: 'raster',
          tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'simple-tiles',
          type: 'raster',
          source: 'raster-tiles',
          minzoom: 0,
          maxzoom: 22,
        },
      ],
      glyphs: DEMO_GLYPHS_URL,
    }
  }

  public async getGoogleMap(
    apiKey: string,
    mapType: GoogleMapsMapType,
  ): Promise<StyleSpecification> {
    const session = await this.getGoogleMapSession(apiKey, mapType)
    if (!session) {
      throw new Error(`Cannot obtain Google Maps session token`)
    }
    const SOURCE_ID = `google-${mapType}`
    return {
      version: 8,
      sources: {
        [SOURCE_ID]: {
          type: 'raster',
          tiles: [
            `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${session.session}&key=${apiKey}`,
          ],
          // Decrease tile size for high-DPI look
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'raster-tiles',
          type: 'raster',
          source: SOURCE_ID,
          minzoom: 0,
          maxzoom: 22,
        },
      ],
      glyphs: DEMO_GLYPHS_URL,
    }
  }

  protected async getGoogleMapSession(
    apiKey: string,
    mapType: GoogleMapsMapType,
  ): Promise<GoogleMapsSessionData | undefined> {
    let session: GoogleMapsSessionData | undefined
    const key = `TI_GOOGLE_MAPS_SESSION_${apiKey.slice(-6)}_${mapType}`
    const cachedSession = this.sessionStorage.getItem(key)
    if (cachedSession) {
      session = JSON.parse(cachedSession) as GoogleMapsSessionData
      if (parseInt(session.expiry) >= Date.now() - 60 * 60) {
        session = undefined // Reset expired session or close to being expired
      }
    }

    if (!session) {
      const url = `https://tile.googleapis.com/v1/createSession?key=${apiKey}`
      session = await firstValueFrom(
        this.httpService.post<GoogleMapsSessionData>(url, {
          mapType: mapType,
          language: 'en-US',
          region: 'US',
          // scale: 'scaleFactor2x',
          // highDpi: true,
          // Include roadmap layer to the satellite maps
          layerTypes: mapType === 'satellite' ? ['layerRoadmap'] : [],
        }),
      )
      this.sessionStorage.setItem(key, JSON.stringify(session))
    }

    return session
  }
}
