import { Map as MaplibreGlMap, MapTouchEvent } from 'maplibre-gl'
import { addMaplibreEventListener } from './add-event-listener'

export const addMaplibreSingleTouchListener = (
  map: MaplibreGlMap,
  layerId: string | undefined,
  cb: (event: MapTouchEvent) => void,
): (() => void) => {
  let active = false
  const touchStartListener = () => {
    active = true
  }
  const touchEndListener = (e: MapTouchEvent) => {
    if (active) {
      cb(e)
    }
    active = false
  }
  const touchCancelListener = () => {
    active = false
  }
  const offCallbacks = new Array<() => void>()
  offCallbacks.push(addMaplibreEventListener(map, 'touchstart', layerId, touchStartListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchend', layerId, touchEndListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchcancel', layerId, touchCancelListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchmove', layerId, touchCancelListener))
  return () => offCallbacks.forEach((cb) => cb())
}
