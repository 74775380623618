<div class="overlay">
  <div class="content">
    <svg
      class="mb-16"
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7378_7525)">
        <path
          d="M39.9337 7.03016C44.4383 6.76524 48.8739 8.23169 52.3329 11.1295C55.7919 14.0273 58.0128 18.1373 58.5412 22.6186C58.5773 22.921 58.6055 23.2225 58.626 23.5229"
          stroke="#593C99"
          style="stroke: #593c99; stroke: color(display-p3 0.3492 0.2341 0.6005); stroke-opacity: 1"
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45.1152 2.66218L39.9283 6.95621L44.0704 11.9619"
          stroke="#593C99"
          style="stroke: #593c99; stroke: color(display-p3 0.3492 0.2341 0.6005); stroke-opacity: 1"
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.814 62.6423H59.1012C60.1718 62.6423 61.1987 62.217 61.9557 61.4599C62.7128 60.7028 63.1381 59.676 63.1381 58.6054V32.0286C63.1381 30.958 62.7128 29.9312 61.9557 29.1741C61.1987 28.417 60.1718 27.9917 59.1012 27.9917H44.4796"
          stroke="#D6BBFB"
          style="stroke: #d6bbfb; stroke: color(display-p3 0.8392 0.7333 0.9843); stroke-opacity: 1"
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4452 6.66748H5.87067C3.64046 6.66748 1.83252 8.47542 1.83252 10.7056V58.6037C1.83252 60.8339 3.64046 62.6418 5.87067 62.6418H32.4452C34.6754 62.6418 36.4833 60.8339 36.4833 58.6037V10.7056C36.4833 8.47542 34.6754 6.66748 32.4452 6.66748Z"
          stroke="#7F56D9"
          style="stroke: #7f56d9; stroke: color(display-p3 0.4964 0.3384 0.8498); stroke-opacity: 1"
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.83252 14.6636H36.4833V54.6452H1.83252V14.6636Z"
          fill="#F4EBFF"
          stroke="#7F56D9"
          style="
            fill: #f4ebff;
            fill: color(display-p3 0.9569 0.9216 1);
            fill-opacity: 1;
            stroke: #7f56d9;
            stroke: color(display-p3 0.4964 0.3384 0.8498);
            stroke-opacity: 1;
          "
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55.1416 27.9912V62.666"
          stroke="#D6BBFB"
          style="stroke: #d6bbfb; stroke: color(display-p3 0.8392 0.7333 0.9843); stroke-opacity: 1"
          stroke-width="2.66545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7378_7525">
          <rect
            width="64"
            height="64.008"
            fill="white"
            style="fill: white; fill-opacity: 1"
            transform="translate(0.5 -0.00805664)"
          />
        </clipPath>
      </defs>
    </svg>

    <div class="text text-16 fw-600 mb-12">{{ 'landscape-overlay.turn-request' | translate }}</div>
    <div class="text text-16 fw-600">
      {{ 'landscape-overlay.portrait-orientation-text' | translate }}
    </div>
  </div>
</div>
