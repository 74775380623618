<p-dialog
  class="dialog-primary reset-form large"
  [modal]="true"
  [visible]="opened()"
  (onHide)="resetSelectedLanguage()"
  (visibleChange)="close()"
>
  <ng-template pTemplate="header">
    <header>
      <div class="title text-18 fw-600">{{ 'fleet.menu.language' | translate }}</div>
    </header>
  </ng-template>

  <form class="form">
    <a
      class="flex mb-12 language-option clickable"
      pRipple
      [class.selected]="localLanguageService.current() === LanguageCode.English"
      (click)="localLanguageService.selectLanguage(LanguageCode.English)"
    >
      <div class="text-14 fw-500">English</div>

      <app-icon-check
        [color]="'var(--color-primary-500)'"
        [height]="20"
        [width]="20"
      ></app-icon-check>
    </a>

    <a
      class="flex language-option clickable"
      pRipple
      [class.selected]="localLanguageService.current() === LanguageCode.Spanish"
      (click)="localLanguageService.selectLanguage(LanguageCode.Spanish)"
    >
      <div class="text-14 fw-500">Español</div>

      <app-icon-check
        [color]="'var(--color-primary-500)'"
        [height]="20"
        [width]="20"
      ></app-icon-check>
    </a>
  </form>

  <div class="footer">
    <p-button (click)="saveSelectedLanguage()" class="text-14 fw-600 h--40 w100">
      {{ 'button.save' | translate }}
    </p-button>
  </div>
</p-dialog>
