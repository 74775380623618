import { Observable } from 'rxjs'
import { Coordinates } from '../contracts'

export abstract class MapHomebaseProvider {
  public abstract point$: Observable<Coordinates | undefined>
  public abstract fullAddress$: Observable<string | undefined>
  public abstract addressData$: Observable<
    { city?: string; country: string; street?: string } | undefined
  >
}
