import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import { AuthService, AuthSession } from './services'
import { BaseSignInComponent, ForgotPasswordComponent, NewPasswordComponent } from './components'

@NgModule({
  imports: [CommonModule, UiKitModule],
  exports: [ForgotPasswordComponent, NewPasswordComponent],
  providers: [AuthService, AuthSession],
  declarations: [BaseSignInComponent, ForgotPasswordComponent, NewPasswordComponent],
})
export class AuthModule {}
