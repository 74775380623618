import { Component } from '@angular/core'
import { BaseIconComponent } from '@ti-platform/web/ui-kit/icons/components/base-icon.component'

@Component({
  selector: 'app-icon-satellite',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1755_27890)">
        <path
          d="M19.7844 13.4901L15.4778 9.18362L16.6031 8.05842C16.7414 7.92018 16.819 7.73281 16.819 7.53729C16.819 7.34186 16.7414 7.15449 16.6031 7.01615L12.984 3.3971C12.6962 3.10942 12.2297 3.10942 11.9419 3.3971L10.8167 4.52229L6.50977 0.215764C6.22189 -0.0719212 5.75539 -0.0719212 5.4677 0.215764L1.84865 3.83482C1.56087 4.1227 1.56087 4.5892 1.84865 4.87699L6.15518 9.18352L5.02989 10.3087C4.89165 10.447 4.81403 10.6343 4.81403 10.8298C4.81403 11.0254 4.89165 11.2126 5.02989 11.351L8.64924 14.9702C8.79308 15.1141 8.98172 15.186 9.17027 15.186C9.35882 15.186 9.54746 15.1141 9.69131 14.9702L10.8166 13.8449L15.123 18.1514C15.267 18.2952 15.4555 18.3671 15.6441 18.3671C15.8326 18.3671 16.0213 18.2952 16.1651 18.1514L19.7843 14.5323C19.9225 14.3941 20.0001 14.2067 20.0001 14.0112C20.0002 13.8158 19.9226 13.6284 19.7844 13.4901ZM3.41186 4.35595L5.98874 1.77907L9.77443 5.56437L8.08782 7.25098L7.19735 8.14145L3.41186 4.35595ZM9.17027 13.407L6.59309 10.8299L7.7179 9.70524C7.71809 9.70505 7.71819 9.70495 7.71839 9.70475L8.94095 8.48219L12.4628 4.9604L15.0399 7.53739L13.9153 8.66199C13.915 8.66229 13.9148 8.66248 13.9146 8.66268L12.1051 10.4723L9.17027 13.407ZM15.6442 16.5882L11.8588 12.8029L14.4358 10.2258L18.2212 14.0112L15.6442 16.5882Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          d="M7.10802 18.5264C5.60308 18.5265 4.18823 17.9404 3.12405 16.8762C2.05988 15.8121 1.4738 14.3971 1.4738 12.8922C1.4738 12.4851 1.14386 12.1553 0.736898 12.1553C0.329835 12.1553 0 12.4852 0 12.8922C0 14.7908 0.739452 16.5758 2.08188 17.9183C3.42432 19.2608 5.20918 20.0002 7.10772 20.0002C7.10772 20.0002 7.10792 20.0002 7.10812 20.0002C7.51508 20.0002 7.84492 19.6703 7.84492 19.2633C7.84502 18.8562 7.51498 18.5264 7.10802 18.5264Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          d="M6.81367 17.1096C7.22063 17.1096 7.55057 16.7797 7.55057 16.3727C7.55057 15.9656 7.22063 15.6358 6.81367 15.6358C6.1595 15.6358 5.54463 15.381 5.08206 14.9185C4.61958 14.456 4.36491 13.8412 4.36491 13.1871C4.36491 12.7801 4.03507 12.4502 3.62801 12.4502C3.22105 12.4502 2.89111 12.7801 2.89111 13.1871C2.89102 14.2348 3.29906 15.2198 4.03999 15.9607C4.78081 16.7016 5.7659 17.1096 6.81367 17.1096Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class SatelliteIconComponent extends BaseIconComponent {}
