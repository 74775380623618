import { Invoice } from '@ti-platform/contracts'
import { ApiRoute } from '../../../services/api.route'
import { BillingReport } from '@ti-platform/contracts/data/billing-report'

export class BillingRoute extends ApiRoute {
  public override init() {
    this.route = 'tsp/billing'
  }

  public report(from: string, to: string) {
    return this.get<BillingReport>()
      .withParams({
        startDate: from,
        endDate: to,
      })
      .endpoint('report')
  }

  public invoice(year: number, month: number) {
    return this.get<Invoice>()
      .withParams({
        year,
        month,
      })
      .endpoint('invoice')
  }
}
