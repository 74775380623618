import { Component, inject, signal } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
  checkExistApiError,
  getMessageFromException,
  getApiErrorKey,
} from '@ti-platform/web/common'
import { ApiErrors } from '@ti-platform/contracts'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

export abstract class ForgotPasswordViewModel {
  public readonly isLoading = signal(false)
  public readonly isFormOpened = signal(false)
  public readonly isResetRequested = signal(false)
  public readonly apiErrorMessage = signal('')

  protected readonly languageService = inject(LanguageService)
  public readonly form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })

  public async submitForm() {
    if (!this.form.valid) {
      return this.form.markAllAsTouched()
    }

    const email = this.form.value.email
    if (email && !this.isLoading()) {
      try {
        this.isLoading.set(true)
        this.apiErrorMessage.set('')
        await this.requestPasswordReset(email)
        this.isFormOpened.set(false)
        this.isResetRequested.set(true)
      } catch (error) {
        console.warn(`Cannot reset password`, error)
        const message = getMessageFromException(error)
        this.apiErrorMessage.set(
          await this.languageService.translate(
            checkExistApiError(message)
              ? getApiErrorKey(message)
              : getApiErrorKey(ApiErrors.unknown),
          ),
        )
      } finally {
        this.isLoading.set(false)
      }
    }
  }

  public async closeForm() {
    this.isResetRequested.set(false)
    this.form.reset()
  }

  protected abstract requestPasswordReset(email: string): Promise<void>
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrl: 'forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  protected readonly viewModel = inject(ForgotPasswordViewModel)

  public open() {
    this.viewModel.form.reset()
    this.viewModel.isFormOpened.set(true)
    this.viewModel.apiErrorMessage.set('')
  }
}
