import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-group-3',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1308_61846)">
        <path
          d="M14.894 19C15.6312 19 15.9998 19 16.2813 18.8874C16.529 18.7883 16.7303 18.6301 16.8565 18.4357C17 18.2146 17 17.9252 17 17.3463V13.6537C17 13.0748 17 12.7854 16.8565 12.5643C16.7303 12.3699 16.529 12.2117 16.2813 12.1127C15.9998 12 15.6312 12 14.894 12L5.10598 12C4.36882 12 4.00024 12 3.71868 12.1127C3.47101 12.2117 3.26965 12.3699 3.14346 12.5643C3 12.7854 3 13.0748 3 13.6537V17.3463C3 17.9252 3 18.2146 3.14346 18.4357C3.26965 18.6301 3.47101 18.7883 3.71868 18.8874C4.00024 19 4.36882 19 5.10598 19H14.894Z"
          [attr.stroke]="color"
          style="stroke-opacity: 1"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.894 8C15.6312 8 15.9998 8 16.2813 7.88735C16.529 7.78826 16.7303 7.63015 16.8565 7.43567C17 7.21458 17 6.92515 17 6.34631V2.65369C17 2.07485 17 1.78542 16.8565 1.56433C16.7303 1.36986 16.529 1.21174 16.2813 1.11265C15.9998 1 15.6312 1 14.894 1L5.10598 1C4.36882 1 4.00024 1 3.71868 1.11265C3.47101 1.21174 3.26965 1.36986 3.14346 1.56433C3 1.78542 3 2.07485 3 2.65369V6.34631C3 6.92515 3 7.21458 3.14346 7.43567C3.26965 7.63014 3.47101 7.78826 3.71868 7.88735C4.00024 8 4.36882 8 5.10598 8H14.894Z"
          [attr.stroke]="color"
          style="stroke-opacity: 1"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_61846">
          <rect width="20" height="20" fill="white" style="fill: white; fill-opacity: 1" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Group3IconComponent extends BaseIconComponent {}
