import { ApiRoute } from '@ti-platform/web/api/services/api.route'
import { LanguageCode, UserProfile } from '@ti-platform/contracts'

export abstract class BaseUserRoute extends ApiRoute {
  public create(data: Partial<UserProfile>) {
    return this.post().withData(data).endpoint('')
  }

  public update(id: string, data: Partial<UserProfile>) {
    return this.patch().withData(data).endpoint(`${id}`)
  }

  public remove(id: string) {
    return this.delete().endpoint(`${id}`)
  }

  // Current user related requests

  public resetPassword(email: string) {
    return this.post<void>().withData({ email }).endpoint('reset-password')
  }

  public confirmPasswordReset(password: string, secret: string) {
    return this.post<void>().withData({ password, secret }).endpoint(`confirm-password-reset`)
  }

  public sendInviteEmail(userId: string) {
    return this.post<void>().withData({ userId }).endpoint(`send-invite-email`)
  }

  public setLanguage(language: LanguageCode) {
    return this.post<void>().withData({ language }).endpoint(`set-language`)
  }

  public setTimezone(timezone: string) {
    return this.post<void>().withData({ timezone }).endpoint(`set-timezone`)
  }

  public setName(name: string) {
    return this.post<void>().withData({ name }).endpoint(`set-name`)
  }

  public setPhotoBase64(base64: string, contentType: string) {
    return this.post<void>().withData({ base64, contentType }).endpoint(`set-photo-base64`)
  }

  public removePhoto() {
    return this.post<void>().endpoint('remove-photo')
  }

  public changePassword(previousPassword: string, proposedPassword: string) {
    return this.post<void>()
      .withData({ previousPassword, proposedPassword })
      .endpoint(`change-password`)
  }

  public ensureDeviceRegistration(token: string, type: string) {
    return this.post<boolean>().withData({ token, type }).endpoint(`ensure-device-registration`)
  }

  public unassignDevice(token: string) {
    return this.post<boolean>().withData({ token }).endpoint(`unassign-device`)
  }
}
