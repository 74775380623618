<p-dialog
  (visibleChange)="$event ? null : cancelEdit.next()"
  [header]="'users.form.header-update' | translate"
  [modal]="true"
  [visible]="opened"
  class="dialog-primary medium"
>
  <app-user-form
    [data]="user"
    [displayRoles]="displayRoles"
    (cancelForm)="cancelEdit.next()"
    (submitForm)="submitEdit.next($event)"
  ></app-user-form>
</p-dialog>
