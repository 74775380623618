import {
  DateStyle,
  FuelConsumptionType,
  LanguageCode,
  MeasurementSystem,
  MeasurementSystemConfig,
  SeparatorType,
} from '@ti-platform/contracts'
import { I18NConfig } from '@ti-platform/web/ui-kit/i18n'

const METRIC: MeasurementSystemConfig = {
  distance: MeasurementSystem.Metric,
  volume: MeasurementSystem.Metric,
  consumption: FuelConsumptionType.L100Km,
  temperature: MeasurementSystem.Metric,
}

const IMPERIAL: MeasurementSystemConfig = {
  distance: MeasurementSystem.Imperial,
  volume: MeasurementSystem.Imperial,
  consumption: FuelConsumptionType.MPG,
  temperature: MeasurementSystem.Imperial,
}

const MIXED_SOUTH_AMERICA = {
  ...METRIC,
  consumption: FuelConsumptionType.KmGal,
  volume: MeasurementSystem.Imperial,
}

export const COUNTRY_SETTINGS: Record<string, I18NConfig> = {
  AR: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  BZ: {
    lang: LanguageCode.English,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.US,
    measurementSystem: { ...IMPERIAL, temperature: MeasurementSystem.Metric },
  },
  BO: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  BR: {
    lang: LanguageCode.English, // Portuguese
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  CA: {
    lang: LanguageCode.English,
    decimalSeparator: SeparatorType.Dot,
    dateStyle: DateStyle.US,
    measurementSystem: METRIC,
  },
  CL: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  CO: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  CR: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  DO: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  EC: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  SV: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  GT: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  HN: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  JM: {
    lang: LanguageCode.English,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  MX: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Dot,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  NI: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  PA: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  PY: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  PE: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: MIXED_SOUTH_AMERICA,
  },
  TT: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  US: {
    lang: LanguageCode.English,
    dateStyle: DateStyle.US,
    decimalSeparator: SeparatorType.Dot,
    measurementSystem: IMPERIAL,
  },
  UY: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
  VE: {
    lang: LanguageCode.Spanish,
    decimalSeparator: SeparatorType.Comma,
    dateStyle: DateStyle.EU,
    measurementSystem: METRIC,
  },
}

export const getSettingsByCountryCode = (countryCode: string): I18NConfig => {
  const settings = Object.entries(COUNTRY_SETTINGS)
    .filter(([code]) => code === countryCode)
    .map(([, settings]) => settings)[0]
  return settings ?? COUNTRY_SETTINGS.US
}
