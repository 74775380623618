<div class="tag" [class]="styleClass">
  <div class="text">
    {{ label }}
  </div>
  <app-icon-close
    class="clickable"
    [width]="16"
    [height]="16"
    [color]="'var(--color-primary-500)'"
    (click)="onCloseClick()"
  ></app-icon-close>
</div>
