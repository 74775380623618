@if (items) {
  <p-breadcrumb class="max-w-full" [model]="items | translateLabels">
    <ng-template pTemplate="separator">
      <app-icon-chevron
        [direction]="'right'"
        [width]="24"
        [height]="24"
        [color]="'var(--color-gray-500)'"
      ></app-icon-chevron>
    </ng-template>
  </p-breadcrumb>
}
