import { Component, inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ApiService } from '@ti-platform/web/api'
import { Profile } from '@ti-platform/web/auth'
import { CONFIG, injectDestroy$, selectState } from '@ti-platform/web/common'
import { provideTspUsersModel, TspUsersModel } from '../models/users.model'
import { Action, Resource } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-admin-users-page',
  templateUrl: 'users-page.component.html',
  styleUrls: ['users-page.component.scss'],
  providers: [provideTspUsersModel()],
})
export class TspAdminUsersPageComponent {
  protected readonly api = inject(ApiService)
  protected readonly config = inject(CONFIG)
  protected readonly profile = inject(Profile)
  protected readonly model = inject(TspUsersModel)
  protected readonly state = selectState(TspUsersModel)

  protected readonly destroy$ = injectDestroy$()
  protected readonly createUserFormOpened$ = new BehaviorSubject<boolean>(false)
  protected readonly Resource = Resource
  protected readonly Action = Action
}
