import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-no-geofence',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3335 7.66699L27.3335 3.66699"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3335 7.66699L23.3335 3.66699"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.616 22.187C6.062 22.655 3.5 23.96 3.5 25.5C3.5 27.433 7.529 29 12.5 29C17.471 29 21.5 27.433 21.5 25.5C21.5 23.96 18.938 22.655 15.384 22.187"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5 15.833C18.5 12.612 15.814 10 12.5 10C9.186 10 6.5 12.612 6.5 15.833C6.5 20.208 12.5 25 12.5 25C12.5 25 18.5 20.208 18.5 15.833Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9142 14.5858C14.6953 15.3668 14.6953 16.6332 13.9142 17.4142C13.1332 18.1953 11.8668 18.1953 11.0858 17.4142C10.3047 16.6332 10.3047 15.3668 11.0858 14.5858C11.8668 13.8047 13.1332 13.8047 13.9142 14.5858"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NoGeofenceIconComponent extends BaseIconComponent {
  @Input() override height = 32
  @Input() override width = 32
}
