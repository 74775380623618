import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core'
import { takeUntil, timer } from 'rxjs'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { injectDestroy$ } from '@ti-platform/web/common'

// TODO: Create tests for this pipe
@Pipe({ name: 'formatTimeSince', pure: false })
export class FormatTimeSincePipe implements PipeTransform {
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected lastValue?: number
  protected lastLocale?: string
  protected lastResult?: string
  protected shortFormat?: boolean

  public constructor() {
    // Update value every 3 seconds
    timer(4000, 3000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.lastValue && this.lastLocale) {
          this.updateValue(this.lastValue)
        }
      })

    // Subscribe to the language changes
    this.languageService.current$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      if (lang && this.lastValue !== undefined) {
        this.lastLocale = lang
        this.updateValue(this.lastValue)
      }
    })
  }

  public transform(date: Date | string | number | undefined, shortFormat = false): string {
    if (!date) {
      return ''
    }
    if (typeof date === 'string' || typeof date === 'number') {
      date = new Date(date)
    }

    const value = date.valueOf()
    const locale = this.languageService.current() ?? 'en'
    // Update the value only when inputs changed
    if (this.lastLocale !== locale || this.lastValue !== value) {
      this.lastLocale = locale
      this.shortFormat = shortFormat
      this.updateValue(value)
    }

    return this.lastResult ?? ''
  }

  protected updateValue(value: number) {
    const promise = !this.shortFormat
      ? this.getLongRelativeTimeText(value)
      : this.getShortRelativeTimeText(value)
    this.lastValue = value
    promise.then((message) => {
      this.lastResult = message
      this.changeDetector.markForCheck()
    })
  }

  protected async getLongRelativeTimeText(timestamp: number) {
    const seconds = Math.abs(Math.floor(Date.now() - timestamp) / 1000)
    if (seconds < 60) {
      return this.languageService.translate('time-since.less-than-minute')
    } else if (seconds < 3600) {
      const value = Math.round(seconds / 60)
      return this.languageService.translate('time-since.minutes', { val: value })
    } else if (seconds < 86400) {
      const value = Math.round(seconds / 3600)
      return this.languageService.translate('time-since.hours', { val: value })
    } else {
      const value = Math.round(seconds / 86400)
      return this.languageService.translate('time-since.days', { val: value })
    }
  }

  protected async getShortRelativeTimeText(timestamp: number) {
    if (!timestamp) {
      return ''
    }
    const totalSeconds = Math.abs(Math.floor(Date.now() - timestamp) / 1000)
    if (totalSeconds < 60) {
      return '< 1 min'
    } else if (totalSeconds < 3600) {
      const minutes = Math.floor(totalSeconds / 60)
      return `${minutes} min${minutes === 1 ? '' : 's'}`
    } else if (totalSeconds < 86400) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      return minutes > 0
        ? `${hours}h ${minutes}m`
        : `${hours} ${await this.languageService.translate('unit.hour')}${hours === 1 ? '' : 's'}`
    } else if (totalSeconds < 864000) {
      const days = Math.floor(totalSeconds / 86400)
      const hours = Math.floor((totalSeconds - days * 86400) / 3600)
      return hours > 0
        ? `${days}d ${hours}h`
        : `${days} ${await this.languageService.translate('unit.day')}${days === 1 ? '' : 's'}`
    } else {
      const days = Math.floor(totalSeconds / 86400)
      return `${days} ${await this.languageService.translate('unit.day')}${days === 1 ? '' : 's'}`
    }
  }
}
