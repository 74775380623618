import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-taxi',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0033 10.9997L18.1445 11.9001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.86387 11.9001L3.99609 10.9997"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1434 11.9001L17.3881 8.28855C17.17 7.24511 16.2497 6.4978 15.1842 6.4978H8.9096C7.86416 6.4978 6.95578 7.2181 6.71768 8.23653L5.86133 11.9001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4518 6.49787L14.1026 4.82617C14.0016 4.34297 13.5754 3.99683 13.0812 3.99683H10.9633C10.4791 3.99683 10.0589 4.32996 9.9489 4.80116L9.55273 6.49787"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99776 18.2806V20.0033C7.99776 20.5556 7.54957 21.0038 6.99734 21.0038H4.99651C4.44428 21.0038 3.99609 20.5556 3.99609 20.0033V17.6003"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0017 17.6003V20.0033C20.0017 20.5556 19.5535 21.0038 19.0013 21.0038H17.0004C16.4482 21.0038 16 20.5556 16 20.0033V18.2806"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5032 16.28V14.6013C20.5032 13.1097 19.2937 11.9001 17.8021 11.9001H6.19722C4.7056 11.9001 3.49609 13.1097 3.49609 14.6013V16.28C3.49609 17.3854 4.39147 18.2808 5.49693 18.2808H18.5023C19.6078 18.2808 20.5032 17.3844 20.5032 16.28Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33025 14.8623H7.27564"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.7228 14.8623H17.6682"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TaxiIconComponent extends BaseIconComponent {}
