import { CommonModule, NgOptimizedImage } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppCommonModule } from '../../common/common.module'
import { I18nModule } from '../i18n/i18n.module'
import { IconsModule } from '../icons/icons.module'
import { PrimeNgModule } from '../prime-ng/prime-ng.module'
import {
  AccessDeniedPageComponent,
  AccordionComponent,
  AutocompleteSelectComponent,
  AvatarComponent,
  BreadcrumbsComponent,
  BulkActionWithLoadingDialogComponent,
  ChangeTooltipOnClickWrapperComponent,
  ClosableTagComponent,
  ConfirmDialogComponent,
  ConfirmWithCommentDialogComponent,
  ContentPaginatorComponent,
  CopyButtonComponent,
  CounterComponent,
  DataGridComponent,
  DateRangePickerComponent,
  DateRangePickerDialogComponent,
  DesignSystemComponent,
  DropdownSelectComponent,
  DurationDialogComponent,
  DurationInputComponent,
  DynamicHostComponent,
  EditableInputComponent,
  ExportBtnComponent,
  FeedbackButtonComponent,
  FilterBarComponent,
  FilterButtonComponent,
  FilterListComponent,
  FilterPanelComponent,
  GridControlsComponent,
  HeaderActionMenuComponent,
  HeaderPanelComponent,
  InlineTextCopyComponent,
  InlineTextEditorComponent,
  LandscapeModeOverlayComponent,
  LangSwitchDialogComponent,
  LangToggleComponent,
  LayoutSelectorComponent,
  MaintenancePageComponent,
  MonthRangeSelectComponent,
  NotFoundPageComponent,
  PasswordInputComponent,
  PwaInstallGuideComponent,
  RadioGroupComponent,
  RecipientsListComponent,
  RoundTimerComponent,
  SliderComponent,
  SortListComponent,
  SplitViewPanelComponent,
  TagSelectComponent,
  TickerComponent,
  TimeInputComponent,
  TransportIconSelectorComponent,
  TripsListComponent,
  UnexpectedErrorPageComponent,
  VehicleIndicatorsComponent,
  VehiclesListComponent,
  VehicleStateComponent,
  VideoPlayerComponent,
  WarnDialogComponent,
  YesBadgeComponent,
} from './components'
import {
  DropzoneDirective,
  LifecycleDirective,
  RepeatDirective,
  ScrollDetectorDirective,
} from './directives'

const COMPONENTS = [
  AccordionComponent,
  AvatarComponent,
  AutocompleteSelectComponent,
  BreadcrumbsComponent,
  ConfirmDialogComponent,
  ConfirmWithCommentDialogComponent,
  ContentPaginatorComponent,
  WarnDialogComponent,
  BulkActionWithLoadingDialogComponent,
  CounterComponent,
  DataGridComponent,
  DateRangePickerComponent,
  DateRangePickerDialogComponent,
  DesignSystemComponent,
  DropdownSelectComponent,
  DynamicHostComponent,
  DurationInputComponent,
  DurationDialogComponent,
  EditableInputComponent,
  FeedbackButtonComponent,
  GridControlsComponent,
  HeaderPanelComponent,
  HeaderActionMenuComponent,
  InlineTextCopyComponent,
  InlineTextEditorComponent,
  LangSwitchDialogComponent,
  LangToggleComponent,
  LandscapeModeOverlayComponent,
  PasswordInputComponent,
  PwaInstallGuideComponent,
  RadioGroupComponent,
  RecipientsListComponent,
  SplitViewPanelComponent,
  TagSelectComponent,
  TickerComponent,
  TimeInputComponent,
  TransportIconSelectorComponent,
  TripsListComponent,
  VehiclesListComponent,
  YesBadgeComponent,
  EditableInputComponent,
  LangSwitchDialogComponent,
  LayoutSelectorComponent,
  SplitViewPanelComponent,
  SliderComponent,
  InlineTextCopyComponent,
  ChangeTooltipOnClickWrapperComponent,
  CopyButtonComponent,
  FilterBarComponent,
  FilterButtonComponent,
  FilterListComponent,
  FilterPanelComponent,
  SortListComponent,
  ExportBtnComponent,
  NotFoundPageComponent,
  AccessDeniedPageComponent,
  UnexpectedErrorPageComponent,
  MaintenancePageComponent,
  ClosableTagComponent,
  VideoPlayerComponent,
  VehicleStateComponent,
  VehicleIndicatorsComponent,
  RoundTimerComponent,
  MonthRangeSelectComponent,
]
const DIRECTIVES = [RepeatDirective, ScrollDetectorDirective, DropzoneDirective, LifecycleDirective]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    IconsModule,
    I18nModule,
    NgOptimizedImage,
    AppCommonModule,
  ],
  exports: [CommonModule, ReactiveFormsModule, ...COMPONENTS, ...DIRECTIVES],
  providers: [],
  declarations: [...COMPONENTS, ...DIRECTIVES],
})
export class LayoutModule {}
