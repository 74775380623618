import { NgModule } from '@angular/core'
import { AuthModule } from '@ti-platform/web/auth'
import { ApiService } from './services/api.service'
import { ApiClient } from './services/api.client'

@NgModule({
  imports: [AuthModule],
  providers: [ApiService, ApiClient],
})
export class ApiModule {}
