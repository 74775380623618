import { Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@ti-platform/web/auth'
import { Location } from '@angular/common'

@Component({
  selector: 'app-access-denied-page',
  templateUrl: 'access-denied-page.component.html',
  styleUrl: 'access-denied-page.component.scss',
})
export class AccessDeniedPageComponent {
  protected readonly router = inject(Router)
  protected readonly location = inject(Location)
  private readonly authService = inject(AuthService)

  protected backToApp() {
    this.location.back()
  }

  protected async logOut() {
    await this.authService.signOut()
    return this.router.navigate(['sign-in'])
  }
}
