import { inject, Injectable } from '@angular/core'
import { formatCommaSeparatedNumber } from '@ti-platform/web/common'
import { SeparatorType } from '@ti-platform/contracts'
import { FormatUnitTypeOptions, UnitType } from '../contracts'
import { I18NConfigProvider, UnitConversionService } from './unit-conversion.service'

@Injectable({ providedIn: 'root' })
export class UnitFormattingService {
  protected readonly i18NConfigProvider = inject(I18NConfigProvider)
  protected readonly unitConversionService = inject(UnitConversionService)

  public async getUnitText(
    inputValue: number,
    unit: UnitType,
    options: Required<FormatUnitTypeOptions>,
  ): Promise<string> {
    const i18NConfig = await this.i18NConfigProvider.getConfig()
    const [value, unitLabel] = await this.unitConversionService.toUser(inputValue, unit)
    let fixedValue = value.toFixed(options.precision)
    if (options.precision > 0 && options.usePreciseIfLess && value >= options.usePreciseIfLess) {
      fixedValue = value.toFixed(0)
    }
    const formattedValue = options.commaSeparated
      ? formatCommaSeparatedNumber(
          fixedValue,
          i18NConfig.decimalSeparator === SeparatorType.Dot ? ',' : '.',
        )
      : fixedValue
    return options.addLabel ? `${formattedValue} ${unitLabel}` : `${formattedValue}`
  }
}
