<div class="full-screen-container error-page">
  <div class="content-wrapper">
    <svg
      class="illustration"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.43 80.7604C150.662 80.7604 132.614 80.0249 115.732 75.2544C98.8495 70.4843 84.0196 61.49 70.3556 50.9765C61.4714 44.1177 53.3572 38.6831 41.8613 39.5141C30.5865 40.1245 19.8037 44.4096 11.1051 51.7361C-3.65505 64.8123 -1.32309 88.9956 4.50643 105.988C13.3205 131.618 40.159 149.394 62.9637 160.998C89.3128 174.384 118.25 182.168 147.28 186.653C172.93 190.592 205.411 193.44 227.446 176.519C247.709 160.998 253.259 125.519 248.292 101.573C247.089 94.4959 243.393 88.1083 237.893 83.6083C223.669 73.0238 202.45 80.0959 186.454 80.452C180.555 80.5944 174.492 80.7368 168.43 80.7604Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M24.0234 105.683L216.786 71.4072L229.606 146.1C229.984 148.301 229.487 150.565 228.226 152.393C226.964 154.221 225.04 155.464 222.877 155.849L46.4236 187.224C44.261 187.609 42.0369 187.104 40.2405 185.82C38.4441 184.533 37.2227 182.575 36.8448 180.376L24.0234 105.683Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M215.899 71.5676L24.9512 105.521L27.7124 121.604L218.66 87.6518L215.899 71.5676Z"
        fill="#F2F4F7"
        style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
      />
      <path
        d="M103.194 91.6012L137.648 85.4746L145.104 128.911C145.329 130.219 145.034 131.565 144.284 132.651C143.534 133.738 142.39 134.477 141.105 134.706L116.32 139.112C115.035 139.341 113.713 139.041 112.645 138.277C111.577 137.514 110.851 136.35 110.627 135.041L103.171 91.6053L103.194 91.6012Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.1748 58.9042L185.149 33.3036C191.632 32.1511 198.296 33.6665 203.682 37.5162C209.066 41.3659 212.727 47.2348 213.858 53.8321L216.875 71.4125L24.0453 105.7L21.0235 88.0965C19.8985 81.5068 21.3886 74.7314 25.1667 69.2585C28.9447 63.7855 34.7015 60.0616 41.1748 58.9042Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M130.632 104.351C130.876 105.75 130.735 107.192 130.225 108.515C129.715 109.839 128.855 110.993 127.741 111.85L129.256 120.18C129.34 120.645 129.331 121.122 129.229 121.584C129.128 122.045 128.937 122.481 128.668 122.866C128.398 123.251 128.055 123.577 127.66 123.825C127.265 124.073 126.824 124.238 126.365 124.309C125.909 124.395 125.44 124.387 124.986 124.288C124.533 124.189 124.103 123.999 123.721 123.73C123.34 123.461 123.015 123.118 122.764 122.721C122.514 122.323 122.342 121.879 122.261 121.414L120.839 113.084C119.514 112.644 118.331 111.847 117.415 110.777C116.5 109.707 115.886 108.405 115.639 107.009C115.293 104.992 115.748 102.917 116.904 101.242C118.061 99.566 119.824 98.4266 121.806 98.0742C123.789 97.7218 125.827 98.185 127.473 99.362C129.12 100.539 130.239 102.334 130.585 104.351L130.632 104.351Z"
        fill="#98A2B3"
        style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
      />
      <path
        d="M111.091 41.2937C112.237 41.2937 113.166 40.3478 113.166 39.1814C113.166 38.015 112.237 37.0691 111.091 37.0691C109.945 37.0691 109.015 38.015 109.015 39.1814C109.015 40.3482 109.945 41.2937 111.091 41.2937Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M12.3406 39.0159C13.4866 39.0159 14.416 38.0704 14.416 36.9036C14.416 35.7372 13.4866 34.7913 12.3406 34.7913C11.1942 34.7913 10.2651 35.7372 10.2651 36.9036C10.2655 38.0704 11.1945 39.0159 12.3406 39.0159Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M15.3262 163.846C16.4722 163.846 17.4016 162.902 17.4016 161.734C17.4016 160.566 16.4722 159.622 15.3262 159.622C14.1801 159.622 13.2507 160.566 13.2507 161.734C13.2507 162.902 14.1801 163.846 15.3262 163.846Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M85.5352 22V31.3031"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.9656 26.6509H90.0826"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M98.0107 190.593V199.872"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.4629 195.221H102.58"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.896 46.5623V55.8654"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M234.348 51.2141H243.465"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.011 228.611C167.985 228.611 202.822 226.402 202.822 223.675C202.822 220.948 167.985 218.739 125.011 218.739C82.0374 218.739 47.2002 220.949 47.2002 223.675C47.2002 226.402 82.0374 228.611 125.011 228.611Z"
        fill="#F2F4F7"
        style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
      />
    </svg>
    <h1>{{ 'error-pages.access-denied-page.caption' | translate }}</h1>
    <h3>{{ 'error-pages.access-denied-page.description' | translate }}</h3>
    <div class="flex">
      <p-button [severity]="'secondary'" class="h--40 p--16 text-14 fw-600" (click)="logOut()">{{
        'error-pages.access-denied-page.log-out' | translate
      }}</p-button>
      <p-button class="h--40 p--16 text-14 fw-600" (click)="backToApp()">{{
        'error-pages.back-to-app' | translate
      }}</p-button>
    </div>
  </div>
</div>
