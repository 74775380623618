<p-panel class="panel-primary w-380 header-24 content-24 border-top">
  <ng-template pTemplate="header">
    <header class="header">
      <div class="mb-16">
        @if (iconComponent) {
          <div class="icon-component">
            <ng-container
              *ngComponentOutlet="
                iconComponent;
                inputs: iconComponentInputs || { width: 64, height: 64 }
              "
            ></ng-container>
          </div>
        } @else {
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32 5.3335C17.296 5.3335 5.33333 17.2962 5.33333 32.0002C5.33333 46.7042 17.296 58.6668 32 58.6668C46.704 58.6668 58.6667 46.7042 58.6667 32.0002C58.6667 17.2962 46.704 5.3335 32 5.3335ZM33.8933 44.5602C32.88 45.5468 31.12 45.5468 30.1067 44.5602C29.624 44.0535 29.3333 43.3602 29.3333 42.6668C29.3333 41.9735 29.624 41.2802 30.1067 40.7735C30.6133 40.2935 31.3067 40.0002 32 40.0002C32.6933 40.0002 33.3867 40.2935 33.8933 40.7735C34.3733 41.2802 34.6667 41.9735 34.6667 42.6668C34.6667 43.3602 34.3733 44.0535 33.8933 44.5602ZM34.6667 34.6668C34.6667 35.3741 34.3857 36.0524 33.8856 36.5524C33.3855 37.0525 32.7072 37.3335 32 37.3335C31.2928 37.3335 30.6145 37.0525 30.1144 36.5524C29.6143 36.0524 29.3333 35.3741 29.3333 34.6668V21.3335C29.3333 20.6263 29.6143 19.948 30.1144 19.4479C30.6145 18.9478 31.2928 18.6668 32 18.6668C32.7072 18.6668 33.3855 18.9478 33.8856 19.4479C34.3857 19.948 34.6667 20.6263 34.6667 21.3335V34.6668Z"
              fill="#7F56D9"
              style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
            />
          </svg>
        }
      </div>
      <div class="title center text-18 fw-600" [class]="{ 'mb-16': !!descriptionKey }">
        {{ summaryKey | translate }}
      </div>
      @if (descriptionKey) {
        <div class="subtitle center text-14">
          {{ descriptionKey | translate }}
        </div>
      }
    </header>
  </ng-template>
  <div class="content">
    <p-button
      class="close text-14 fw-600 h--40 w-grow"
      [severity]="'secondary'"
      (onClick)="dynamicDialogRef.close()"
    >
      {{ closeButtonKey | translate }}
    </p-button>
  </div>
</p-panel>
