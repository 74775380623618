import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core'

import { FleetDisplayData } from '@ti-platform/contracts'
import {
  DevicesListModel,
  DevicesTypesDataProvider,
} from '@ti-platform/tsp-admin/app/devices/models'
import { injectDestroy$ } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete'

@Component({
  selector: 'app-bulk-devices-assign',
  templateUrl: 'bulk-devices-assign.component.html',
  styleUrl: 'bulk-devices-assign.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkDevicesAssignComponent {
  @Output() cancelEvent = new EventEmitter<void>()
  @Output() confirmEvent = new EventEmitter<FleetDisplayData | null>()

  @Input() opened = false
  @Input() numberToAssignment = 0

  protected filteredFleets: FleetDisplayData[] = []
  protected fleet: FleetDisplayData | null = null

  protected readonly destroy$ = injectDestroy$()

  protected readonly devicesViewModel = inject(DevicesListModel)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly languageService = inject(LanguageService)

  public onCancel() {
    this.cancelEvent.emit()
  }

  protected onCloseClick() {
    this.onCancel()
  }

  protected onConfirm() {
    this.confirmEvent.emit(this.fleet)
  }

  protected async onFilterFleet(event: AutoCompleteCompleteEvent) {
    const query = event.query
    const allFleetCount = this.devicesViewModel.billableFleetsCount$.value
    const recentFleets = this.devicesViewModel.recentBillableFleetsList$.value
    if (!query.length || allFleetCount >= recentFleets.length) {
      this.filteredFleets = query
        ? recentFleets.filter((fleet) => fleet.name.toLowerCase().includes(query.toLowerCase()))
        : [...recentFleets]
    } else {
      try {
        const result = await this.devicesViewModel.getAssignFleetSearch(query, true)
        this.filteredFleets = result ?? []
      } catch (e) {
        this.filteredFleets = []
        console.error('Error get searched data')
      }
    }
  }
}
