<p-button
  [class.p--10]="iconOnly"
  [class.p--16]="!iconOnly"
  [outlined]="true"
  [severity]="'primary'"
  badge="{{ filtersCount || undefined }}"
  class="flat text-14 fw-600 h--40"
  icon="{{ iconOnly && !filtersCount ? 'pi' : '' }}"
>
  @if (sortingApplied) {
    <app-icon-filters-active
      [height]="20"
      [width]="20"
      class="filters icon"
      [class.mr-0]="iconOnly"
    ></app-icon-filters-active>
  } @else {
    <app-icon-filters [height]="20" [width]="20" class="filters icon"></app-icon-filters>
  }

  @if (!iconOnly) {
    {{ 'button.filters' | translate }}
  }
</p-button>
