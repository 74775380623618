import { Map as MaplibreGlMap, MapEventType, MapLayerEventType, MapTouchEvent } from 'maplibre-gl'

export const addMaplibreEventListener = (
  map: MaplibreGlMap,
  event: keyof MapLayerEventType | keyof MapEventType,
  layerId: string | undefined,
  cb: (event: MapTouchEvent) => void,
) => {
  const args = [event, layerId, cb].filter(Boolean)
  // @ts-expect-error void
  map.on(...args)
  // @ts-expect-error void
  return () => map.off(...args)
}
