<p-button
  class="h-40 p-18 text-14 fw-500"
  (click)="monthsMenu.toggle($event)"
  [severity]="severity"
>
  <app-icon-date [height]="20" [width]="20" class="icon"></app-icon-date>
  @if (value && (monthLabels$ | async)) {
    {{ (monthLabels$ | async)?.[value.month] }} {{ value.year }}
  }
</p-button>

<p-menu
  #monthsMenu
  (mouseleave)="monthsMenu.hide()"
  [model]="(items$ | async) || []"
  [popup]="true"
  styleClass="month-select-menu"
>
  <ng-template pTemplate="item" let-item>
    <div class="menu-item month-menu-item" [class]="{ active: item.isActive() }">
      <div class="text-14 fw-400 label">
        {{ item.label }}
      </div>
      <div class="active_icon">
        <app-icon-check [width]="24" [height]="24" class="icon"></app-icon-check>
      </div>
    </div>
  </ng-template>
</p-menu>
