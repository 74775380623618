<p-dialog
  [(visible)]="opened"
  [modal]="true"
  class="dialog-primary extra-large headless"
  maskStyleClass="dialog-primary extra-large headless"
  closable="false"
  [focusOnShow]="false"
  appendTo="body"
>
  <ng-template pTemplate="header"></ng-template>
  <app-command-form
    (cancelEvent)="onCancel()"
    (submitEvent)="onSubmit($event)"
    [command]="command ?? {}"
    [header]="('tsp-admin.devices.commands.edit-command' | translate) ?? ''"
    [processing]="processing"
    [errorMessage]="errorMessage"
  ></app-command-form>
</p-dialog>
