import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-arrow',
  template: `
    <svg
      [class.rotated-180]="direction === 'right'"
      [class.rotated-90]="direction === 'up'"
      [class.rotated--90]="direction === 'down'"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 7L5 12"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 17L5 12"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rotated-180 {
        transform: rotate(180deg);
      }
      .rotated-90 {
        transform: rotate(90deg);
      }
      .rotated--90 {
        transform: rotate(-90deg);
      }
    `,
  ],
})
export class ArrowIconComponent extends BaseIconComponent {
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'left'
}
