import { APP_INITIALIZER, FactoryProvider, inject, Injectable, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { TranslateService } from '@ngx-translate/core'
import { LanguageCode } from '@ti-platform/contracts'
import { AuthSession, Profile } from '@ti-platform/web/auth'
import { BrowserLocalStorage, injectDestroy$ } from '@ti-platform/web/common'
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public readonly current = signal<LanguageCode | null>(null)
  public readonly current$ = toObservable(this.current)

  protected readonly DEFAULT_LANG = LanguageCode.English
  protected readonly SUPPORTED_LANGUAGES = [LanguageCode.English, LanguageCode.Spanish]
  protected readonly profile = inject(Profile)
  protected readonly authSession = inject(AuthSession)
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly translateService = inject(TranslateService)
  protected readonly destroy$ = injectDestroy$()

  public async initialize() {
    let targetLanguage: LanguageCode
    const isLoggedIn = await this.authSession.isLoggedIn()
    if (isLoggedIn) {
      const user = await firstValueFrom(this.profile.state)
      targetLanguage = user ? user.language : this.DEFAULT_LANG
    } else {
      const savedLang = this.localStorage.getItem('app.preferred_language') as LanguageCode
      targetLanguage =
        (new URLSearchParams(location.search).get('lang') as LanguageCode) ||
        savedLang ||
        this.translateService.getBrowserLang() ||
        this.DEFAULT_LANG
    }

    const preferredLang = this.SUPPORTED_LANGUAGES.includes(targetLanguage)
      ? targetLanguage
      : this.DEFAULT_LANG

    this.current.set(preferredLang)
    this.translateService.setDefaultLang(preferredLang)
    this.subscribeToCurrentUserLanguage()
    // Load the translation file and return a promise to ensure completion
    return firstValueFrom(this.translateService.use(preferredLang))
  }

  public selectLanguage(lang: LanguageCode, save = true) {
    if (this.SUPPORTED_LANGUAGES.includes(lang)) {
      this.current.set(lang)
      this.localStorage.setItem('app.preferred_language', lang)
      if (save) {
        this.saveCurrentUserLanguage(lang).catch((error) => {
          console.error(`Cannot save current user language`, error)
        })
      }
      return firstValueFrom(this.translateService.use(lang))
    }
  }

  public instant<T extends string | Array<string>>(
    key: T,
    interpolateParams?: NonNullable<unknown>,
  ): T {
    return this.translateService.instant(key, interpolateParams)
  }

  public translate<T extends string | Array<string>>(
    key: T,
    interpolateParams?: NonNullable<unknown>,
  ): Promise<T> {
    return firstValueFrom(this.translateService.get(key, interpolateParams))
  }

  public translate$<T extends string | Array<string>>(
    key: T,
    interpolateParams?: NonNullable<unknown>,
  ): Observable<T> {
    return this.translateService.stream(key, interpolateParams)
  }

  public massTranslate<T extends Record<string, string>>(
    keys: T,
    interpolateParams?: NonNullable<unknown>,
  ): Promise<T> {
    const keysSorted = Object.keys(keys).sort()
    const translationKeys = keysSorted.map((key) => keys[key])
    return this.translate(translationKeys, interpolateParams).then((keyTranslations) => {
      const result: Record<string, string> = {}
      Object.values(keyTranslations).forEach((translation, i) => {
        result[keysSorted[i]] = translation
      })
      return result as T
    })
  }

  public massTranslate$<T extends Record<string, string>>(
    keys: T,
    interpolateParams?: NonNullable<unknown>,
  ): Observable<T> {
    const keysSorted = Object.keys(keys).sort()
    const observables = keysSorted.map((key) => this.translate$(keys[key], interpolateParams))
    return combineLatest(observables).pipe(
      debounceTime(1),
      map((keyTranslations) => {
        const result: Record<string, string> = {}
        keyTranslations.forEach((translation, i) => {
          result[keysSorted[i]] = translation
        })
        return result as T
      }),
      takeUntil(this.destroy$),
    )
  }

  protected subscribeToCurrentUserLanguage() {
    this.profile.state.pipe(takeUntil(this.destroy$)).subscribe(async (user) => {
      if (user) {
        await this.selectLanguage(user.language, false)
      }
    })
  }

  protected async saveCurrentUserLanguage(language: LanguageCode) {
    // Override this method to save the user language
  }

  public getPrimengTranslations() {
    return this.translate$('primeng').pipe(
      takeUntil(this.destroy$),
      map((result) => {
        return this.fixPrimeNGTranslations(result)
      }),
    )
  }

  private fixPrimeNGTranslations(translations: any): any {
    switch (typeof translations) {
      case 'string':
        return translations
      case 'function':
        return translations()
      case 'object':
        if (Array.isArray(translations)) {
          return translations.map((t) => this.fixPrimeNGTranslations(t))
        }
        for (const key in translations) {
          translations[key] = this.fixPrimeNGTranslations(translations[key])
        }
        return translations
      default:
        return translations
    }
  }
}

export const languageInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: (languageInitializer: LanguageService) => () => languageInitializer.initialize(),
  deps: [LanguageService],
  multi: true,
}
