import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core'
import { FleetView } from '@ti-platform/contracts'
import { FleetAccountsModel } from '@ti-platform/tsp-admin/app/fleet/models/fleets.model'
import { OrderByData, selectState } from '@ti-platform/web/common'
import { PricingPlansProvider } from '../../models'

@Component({
  selector: 'app-tsp-fleets-grid',
  templateUrl: 'fleets-grid.component.html',
  styleUrls: ['fleets-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetAccountsGridComponent {
  @Output() readonly editFleet = new EventEmitter<FleetView>()
  @Output() readonly deleteFleet = new EventEmitter<FleetView>()
  @Output() readonly rowItemClicked = new EventEmitter<FleetView>()

  protected readonly model = inject(FleetAccountsModel)
  protected readonly state = selectState(FleetAccountsModel)

  protected readonly pricingPlansProvider = inject(PricingPlansProvider)

  protected onSortUpdated(event: OrderByData) {
    this.model.setOrder(event)
  }

  protected onLoadMoreClicked() {
    this.model.loadNextPage()
  }
}
