import { Component } from '@angular/core';
import { BaseIconComponent } from '../base-icon.component';

@Component({
  selector: 'app-icon-minus',
  template: `
    <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12H19" [attr.stroke]="color" style="stroke-opacity:1;" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `]
})
export class MinusIconComponent extends BaseIconComponent {}
