<div class="full-screen-container error-page">
  <div class="content-wrapper">
    <svg
      class="illustration"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.033 97.3323C143.415 97.4254 127.554 96.6985 112.719 92.5796C97.8832 88.4606 84.7625 80.6887 72.8344 71.6123C65.0253 65.7043 57.9243 61.0076 47.7853 61.7156C37.8719 62.2367 28.3897 65.9352 20.7421 72.2643C7.84496 83.5588 9.80169 104.433 14.9457 119.119C22.6988 141.242 46.2941 156.6 66.3297 166.608C89.4963 178.182 114.937 184.91 140.452 188.768C162.817 192.16 191.537 194.639 210.902 180.027C228.701 166.626 233.584 135.986 229.223 115.298C228.16 109.188 224.908 103.675 220.072 99.7919C207.566 90.6596 188.928 96.7541 174.876 97.0708C169.75 97.1832 164.364 97.3137 159.033 97.3323Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M82.8802 25V33.0142"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="1.58418"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M78.873 29.0068H86.8872"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="1.58418"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M234.926 184.743V192.757"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="1.58418"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M230.918 188.75H238.933"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="1.58418"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.3268 150.133C23.3355 150.133 24.1533 149.315 24.1533 148.306C24.1533 147.297 23.3355 146.48 22.3268 146.48C21.3181 146.48 20.5002 147.297 20.5002 148.306C20.5002 149.315 21.3177 150.133 22.3268 150.133Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M177.839 33.2753C178.847 33.2753 179.665 32.4575 179.665 31.4487C179.665 30.44 178.847 29.6222 177.839 29.6222C176.831 29.6222 176.012 30.44 176.012 31.4487C176.012 32.4571 176.831 33.2753 177.839 33.2753Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M127.219 225C164.996 225 195.619 223.097 195.619 220.751C195.619 218.404 164.996 216.501 127.219 216.501C89.4429 216.501 58.8192 218.404 58.8192 220.751C58.8192 223.097 89.4429 225 127.219 225Z"
        fill="#F2F4F7"
        style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
      />
      <path
        d="M72.9173 46.3693H167.16C169.504 46.3693 171.753 47.3524 173.412 49.1023C175.068 50.8523 176 53.2256 176 55.7005V174.038C176 176.513 175.068 178.887 173.412 180.637C171.753 182.386 169.504 183.369 167.16 183.369H60.8409C58.496 183.369 56.2474 182.386 54.5894 180.637C52.9315 178.887 52 176.513 52 174.038V68.6708L72.9173 46.3693Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.74 104.359H80.3083V184.724H182.74V104.359Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M52 69.3693H69.201C70.2102 69.3644 71.1762 68.9261 71.8883 68.1507C72.6003 67.3748 73 66.3256 73 65.2313V46.3693L52 69.3693Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.237 78.6949H80.9232C76.981 78.6949 73.785 81.8909 73.785 85.8331V107.509C73.785 111.451 76.981 114.647 80.9232 114.647H182.237C186.179 114.647 189.375 111.451 189.375 107.509V85.8331C189.376 81.8909 186.179 78.6949 182.237 78.6949Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.4665 102.942C96.9044 102.942 99.6916 100.156 99.6916 96.7174C99.6916 93.2792 96.9047 90.4923 93.4665 90.4923C90.0284 90.4923 87.2415 93.2792 87.2415 96.7174C87.2415 100.156 90.0284 102.942 93.4665 102.942Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M113.036 102.942C116.474 102.942 119.261 100.156 119.261 96.7174C119.261 93.2792 116.474 90.4923 113.036 90.4923C109.598 90.4923 106.811 93.2792 106.811 96.7174C106.811 100.156 109.598 102.942 113.036 102.942Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M132.587 102.942C136.024 102.942 138.812 100.156 138.812 96.7174C138.812 93.2792 136.025 90.4923 132.587 90.4923C129.148 90.4923 126.362 93.2792 126.362 96.7174C126.362 100.156 129.149 102.942 132.587 102.942Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M182.237 118.542H80.9232C76.981 118.542 73.785 121.738 73.785 125.68V147.356C73.785 151.298 76.981 154.494 80.9232 154.494H182.237C186.179 154.494 189.375 151.298 189.375 147.356V125.68C189.376 121.738 186.179 118.542 182.237 118.542Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.4665 142.808C96.9044 142.808 99.6916 140.021 99.6916 136.583C99.6916 133.145 96.9047 130.358 93.4665 130.358C90.0284 130.358 87.2415 133.145 87.2415 136.583C87.2415 140.021 90.0284 142.808 93.4665 142.808Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M113.036 142.808C116.474 142.808 119.261 140.021 119.261 136.583C119.261 133.145 116.474 130.358 113.036 130.358C109.598 130.358 106.811 133.145 106.811 136.583C106.811 140.021 109.598 142.808 113.036 142.808Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M132.587 142.808C136.024 142.808 138.812 140.021 138.812 136.583C138.812 133.145 136.025 130.358 132.587 130.358C129.148 130.358 126.362 133.145 126.362 136.583C126.362 140.021 129.149 142.808 132.587 142.808Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M182.237 158.408H80.9232C76.981 158.408 73.785 161.604 73.785 165.546V187.222C73.785 191.164 76.981 194.36 80.9232 194.36H182.237C186.179 194.36 189.375 191.164 189.375 187.222V165.546C189.376 161.604 186.179 158.408 182.237 158.408Z"
        fill="white"
        stroke="#98A2B3"
        style="
          fill: white;
          fill-opacity: 1;
          stroke: #98a2b3;
          stroke: color(display-p3 0.5964 0.6352 0.7017);
          stroke-opacity: 1;
        "
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.4665 182.655C96.9044 182.655 99.6916 179.869 99.6916 176.43C99.6916 172.992 96.9047 170.205 93.4665 170.205C90.0284 170.205 87.2415 172.992 87.2415 176.43C87.2415 179.869 90.0284 182.655 93.4665 182.655Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M113.036 182.655C116.474 182.655 119.261 179.869 119.261 176.43C119.261 172.992 116.474 170.205 113.036 170.205C109.598 170.205 106.811 172.992 106.811 176.43C106.811 179.869 109.598 182.655 113.036 182.655Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M132.587 182.655C136.024 182.655 138.812 179.869 138.812 176.43C138.812 172.992 136.025 170.205 132.587 170.205C129.148 170.205 126.362 172.992 126.362 176.43C126.362 179.869 129.149 182.655 132.587 182.655Z"
        fill="#D0D5DD"
        style="fill: #d0d5dd; fill: color(display-p3 0.8157 0.8353 0.8667); fill-opacity: 1"
      />
      <path
        d="M184.395 94.7743C186.551 91.0398 191.941 91.0398 194.097 94.7743L214.311 129.785C216.467 133.519 213.772 138.188 209.46 138.188H169.033C164.72 138.188 162.025 133.519 164.181 129.785L184.395 94.7743Z"
        fill="#98A2B3"
        style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
      />
      <path
        d="M187.232 123.912V108.229H191.304V123.912H187.232ZM187.009 129.86V125.884H191.526V129.86H187.009Z"
        fill="white"
        style="fill: white; fill-opacity: 1"
      />
    </svg>

    <h1>{{ 'error-pages.unexpected-error-page.caption' | translate }}</h1>
    <h3>{{ 'error-pages.unexpected-error-page.description' | translate }}</h3>
    <p-button class="h--40 p--16 text-14 fw-600" (click)="backToApp()">{{
      'error-pages.back-to-app' | translate
    }}</p-button>
  </div>
</div>
