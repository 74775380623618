export interface APIResponse {
  status: ResponseStatus
  statusCode?: number
  data?: unknown
  trace?: string
  message?: string
}

export enum ResponseStatus {
  Success = 'success',
  Error = 'error',
}
