import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Fleet, FleetForm } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-admin-add-fleet-dialog',
  templateUrl: 'fleet-add.component.html',
  styleUrl: 'fleet-add.component.scss',
})
export class TspAdminFleetAddDialogComponent {
  @Output() cancel = new EventEmitter<void>()
  @Output() launch = new EventEmitter<FleetForm>()

  @Input() opened = false
  @Input() processing = false
  @Input() fleet: Partial<Fleet> = {}
  @Input() errorMessage = ''
}
