<div class="player">
  <div class="left">
    @if (!isEnded) {
      @if (isPaused) {
        <div class="play" (click)="play()">
          <i class="pi pi-play"></i>
        </div>
      } @else {
        <div class="pause" (click)="pause()">
          <i class="pi pi-pause"></i>
        </div>
      }
    } @else {
      <div class="pause" (click)="restart()">
        <i class="pi pi-refresh"></i>
      </div>
    }

    <div class="time fw-400">{{ startTime | formatDate: 'long-time' }}</div>
  </div>

  <div class="slider">
    <div class="duration full" #slider (click)="sliderClick($event)"></div>
    <div class="duration current" [style.width]="cursorPositionPct$ | async"></div>

    @if (alertSegmentPositionPct$ | async; as alertSegmentPos) {
      <div class="highlight" [style.left]="alertSegmentPos"></div>
    }

    <div #cursor class="cursor-anchor" [style.left]="cursorPositionPct$ | async">
      <div class="cursor" (mousedown)="handleMouseDown(); $event.stopPropagation()"></div>
    </div>
  </div>

  <div class="right">
    <div class="time fw-400">{{ endTime | formatDate: 'long-time' }}</div>
  </div>
</div>
