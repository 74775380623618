import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { Action, Resource } from '@ti-platform/contracts'
import { AccessControl } from '@ti-platform/web/common'

export const checkAccess = (resource: Resource, action: Action): CanActivateFn => {
  return async () => {
    const router = inject(Router)
    const accessControl = inject(AccessControl)
    const isAllowed = await accessControl.check(resource, action)
    if (!isAllowed) {
      setTimeout(() => router.navigate(['access-denied'], { skipLocationChange: true }))
    }
    return true
  }
}
