import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-car-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0036 18.5029V20.0036C21.0036 20.5558 20.5554 21.004 20.0032 21.004H18.0024C17.4501 21.004 17.002 20.5558 17.002 20.0036V18.5029"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.99776 18.5029V20.0036C6.99776 20.5558 6.54957 21.004 5.99734 21.004H3.99651C3.44428 21.004 2.99609 20.5558 2.99609 20.0036V18.5029"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.99609 18.5028V13.7439C2.99609 12.9475 3.31223 12.1842 3.87546 11.622L4.99693 10.4995H19.0028L20.1242 11.621C20.6875 12.1842 21.0036 12.9475 21.0036 13.7439V18.5028H2.99609Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0032 14.5014L18.002 14.7515"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99734 14.7515L2.99609 14.5014"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99805 18.503L8.99846 15.5017H15.001L16.0014 18.503"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0028 9.49913L19.002 9.99934"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.99693 9.99934L2.99609 9.49913"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.99609 10.4996V9.9934L5.83244 6.85509C6.12356 5.75963 7.11598 4.99731 8.24845 4.99731H15.8356C16.9931 4.99731 17.9995 5.79165 18.2686 6.91811L19.0019 9.9934V10.4996"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Car1IconComponent extends BaseIconComponent {}
