import { DataQueryProps, PAGE_SIZE, UserProfile } from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { BaseUserRoute } from '../../common/base-user.route'

export class UserRoute extends BaseUserRoute {
  public override init() {
    this.route = 'fleet/user'
  }

  public list(options: DataQueryProps) {
    return this.get<UserProfile[]>()
      .withParams(
        stripEmptyKeys({
          page: `${options.page}`,
          pageSize: `${options.pageSize ?? PAGE_SIZE}`,
          search: options.search ?? '',
          order: options.order,
          orderBy: options.orderBy,
        }),
      )
      .endpoint('list')
  }

  // TODO: Remove and use `list` instead
  public listForAlerts() {
    return this.get<UserProfile[]>().endpoint('list-for-alerts')
  }

  public count() {
    return this.get<number>().endpoint('count')
  }

  public obtainFlespiToken() {
    return this.get<FlespiTokenData>().endpoint('obtain-flespi-token')
  }
}

export interface FlespiTokenData {
  id: number
  created: number
  enabled: boolean
  expire: number
  key: string
  metadata: Record<string, string>
}
