"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MissingRefError = exports.ValidationError = void 0;
const resolve_1 = require("./resolve");
class ValidationError extends Error {
    constructor(errors) {
        super("validation failed");
        this.errors = errors;
        this.ajv = this.validation = true;
    }
}
exports.ValidationError = ValidationError;
class MissingRefError extends Error {
    constructor(baseId, ref) {
        super(`can't resolve reference ${ref} from id ${baseId}`);
        this.missingRef = resolve_1.resolveUrl(baseId, ref);
        this.missingSchema = resolve_1.normalizeId(resolve_1.getFullPath(this.missingRef));
    }
}
exports.MissingRefError = MissingRefError;
module.exports = {
    ValidationError,
    MissingRefError,
};
