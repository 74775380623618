import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-car-cargo',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9746 10H20.9996C21.5518 10.0002 21.9994 10.4478 21.9996 11V12C21.9994 12.5522 21.5518 12.9998 20.9996 13H19.4996"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 13H3C2.4478 12.9998 2.0002 12.5522 2 12V11C2.0002 10.4478 2.4478 10.0002 3 10H5.025"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 16.5V21C19.5 21.5523 19.0523 22 18.5 22H16.5C15.9477 22 15.5 21.5523 15.5 21V19.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 19.5V21C8.5 21.5523 8.05228 22 7.5 22H5.5C4.94772 22 4.5 21.5523 4.5 21V16.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6083 7.5C14.2283 6.83333 9.77259 6.83333 5.39258 7.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 13C9.47778 13.6667 14.5222 13.6667 19.5 13"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.884 16.5H15"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 16.5H7"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 19.5H6.5C5.39543 19.5 4.5 18.6046 4.5 17.5V13.5L5.7445 5.20332C5.89136 4.22428 6.73238 3.5 7.72237 3.5H16.2776C17.2676 3.5 18.1086 4.22428 18.2555 5.20332L19.5 13.5V17.5C19.5 18.6046 18.6046 19.5 17.5 19.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CarCargoIconComponent extends BaseIconComponent {}
