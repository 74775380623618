import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Action, CommandFormData, Resource } from '@ti-platform/contracts'
import { injectDestroy$, selectState } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MessageService } from 'primeng/api'
import { BehaviorSubject } from 'rxjs'
import { CommandSelectedPurpose, CommandsListModel, DevicesTypesDataProvider } from '../../models'

@Component({
  selector: 'app-tsp-commands-list',
  templateUrl: 'commands-list.component.html',
  styleUrls: ['commands-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsListComponent implements OnInit {
  protected readonly destroy$ = injectDestroy$()
  protected readonly languageService = inject(LanguageService)
  protected readonly messageService = inject(MessageService)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly model = inject(CommandsListModel)
  protected readonly router = inject(Router)
  protected readonly state = selectState(CommandsListModel)
  protected readonly showAddCommandDialog$ = new BehaviorSubject(false)

  protected readonly Action = Action
  protected readonly Resource = Resource

  protected readonly CommandSelectedPurpose = CommandSelectedPurpose

  public async ngOnInit() {
    await this.devicesTypesDataProvider.getDevicesTypesData()
  }

  protected goBack() {
    this.router.navigate(['devices'])
  }

  protected onClearFilter() {
    const filter = this.model.getFilterValue()
    if (filter?.deviceTypes?.length) {
      this.model.unselect()
      this.model.resetFilter()
    }
  }

  protected onSaveFilter(data: any) {
    this.model.unselect()
    if (data?.deviceTypes?.length) {
      this.model.setFilter(data)
    } else {
      this.model.resetFilter()
    }
  }

  public async addCommand(data: CommandFormData) {
    const result = await this.model.createCommand(data)
    if (result) {
      this.model.refresh()
      this.showAddCommandDialog$.next(false)
    }
  }

  protected async editCommand(data: CommandFormData) {
    const result = await this.model.editCommand(data)
    if (result) {
      this.model.refresh()
    }
  }

  protected async onAddClick() {
    this.showAddCommandDialog$.next(true)
  }

  protected onCancelAddCommand() {
    this.model.cancelCreating()
    this.showAddCommandDialog$.next(false)
  }

  protected onCancelEditCommand() {
    this.model.cancelEdit()
  }

  protected onCancelBulkAssignOrUnassign() {
    this.model.unselect()
  }

  protected async onConfirmBulkAssignOrUnassign(event: { success: number; all: number }) {
    // show message
    let messageKey = ''
    const success = event.all === event.success
    const commandName = this.model.selectedItem?.name || ''
    if (this.model.selectedPurpose === CommandSelectedPurpose.AssignDevices) {
      messageKey = success
        ? 'tsp-admin.devices.commands.bulk.assign.success-message'
        : 'tsp-admin.devices.commands.bulk.assign.warn-message'
    } else {
      messageKey = success
        ? 'tsp-admin.devices.commands.bulk.unassign.success-message'
        : 'tsp-admin.devices.commands.bulk.unassign.warn-message'
    }
    const message = await this.languageService.translate(messageKey, {
      val: event.success,
      allVal: event.all,
      commandName,
    })
    this.messageService.add({
      severity: success ? 'success' : 'warn',
      detail: message,
      life: 5000,
    })

    // unselect and update
    this.model.unselect()
    this.model.refresh()
  }
}
