import { InjectionToken } from '@angular/core'
import { LoginAsSessionData } from '../contracts'
import { LOGIN_AS_SESSION_STORAGE_KEY } from '@ti-platform/web/common'

export const LOGIN_AS_SESSION = new InjectionToken<LoginAsSessionData | undefined>(
  'LOGIN_AS_SESSION',
  {
    factory: () => {
      try {
        const cachedSession = window.sessionStorage.getItem(LOGIN_AS_SESSION_STORAGE_KEY)
        if (cachedSession) {
          return JSON.parse(cachedSession)
        }
      } catch (error) {
        console.error(`Cannot parse cached session data`, error)
      }
    },
    providedIn: 'root',
  },
)
