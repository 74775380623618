import { inject, Injectable, Provider } from '@angular/core'
import { DataQueryProps, UserProfile } from '@ti-platform/contracts'
import { CONFIG, openLinkBlank, WhiteLabelSettingsProvider } from '@ti-platform/web/common'
import { BaseUsersModel } from '@ti-platform/web/user'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class TspUsersModel extends BaseUsersModel {
  protected readonly modelName = 'TspUsersModel'
  protected override readonly updateMessageSummaryKey: string = 'users.updated-message.detail'
  protected override readonly updateMessageDetailKey: string = ''
  protected override readonly deleteMessageSummaryKey: string = 'users.deleted-message.detail'
  protected override readonly deleteMessageDetailKey: string = ''

  protected readonly selectedFleet$ = new BehaviorSubject<number>(0)

  public filterByFleet(id: number) {
    this.selectedFleet$.next(id)
  }

  protected createUser(data: UserProfile) {
    return this.api.tsp.user.create(data)
  }

  protected updateUser(userId: string, data: UserProfile) {
    return this.api.tsp.user.update(userId, data)
  }

  public async sendInviteEmail(userId: string) {
    return this.api.tsp.user.sendInviteEmail(userId)
  }

  protected async deleteUser(userId: string) {
    return this.api.tsp.user.remove(userId)
  }

  protected loadPage(props: DataQueryProps): Promise<UserProfile[]> {
    const fleetId = this.selectedFleet$.value
    if (fleetId) {
      return this.api.tsp.user.listForTspFleet(fleetId, props)
    }
    return this.api.tsp.user.listTsp(props)
  }

  protected async countAllUsers() {
    const options = { search: '' }
    const fleetId = this.selectedFleet$.value
    if (fleetId) {
      return this.api.tsp.user.countForTspFleet(fleetId, options)
    }
    return this.api.tsp.user.countTsp(options)
  }
}

@Injectable()
export class TspFleetUsersModel extends TspUsersModel {
  protected readonly appConfig = inject(CONFIG)
  protected readonly whiteLabelProvider = inject(WhiteLabelSettingsProvider)

  public async loginAsFleetUser(user: UserProfile) {
    const idToken = await this.authSession.getIdToken()
    const session = await this.api.tsp.user.initLoginAsSession(idToken, user.id)
    const whiteLabelSettings = await this.whiteLabelProvider.getData()
    const fleetWebUrl = `https://${
      whiteLabelSettings.domain ? whiteLabelSettings.domain : this.appConfig.fleetDefaultDomain
    }`
    openLinkBlank(`${fleetWebUrl}/?sessionId=${session.sessionId}`)
  }
}

export const provideTspUsersModel = (): Provider[] => {
  return [TspUsersModel, { provide: BaseUsersModel, useExisting: TspUsersModel }]
}

export const provideTspFleetUsersModel = (): Provider[] => {
  return [TspFleetUsersModel, { provide: BaseUsersModel, useExisting: TspFleetUsersModel }]
}
