import { HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import * as Sentry from '@sentry/angular'
import environment from '@ti-platform/tsp-admin/env'
import { ApiModule } from '@ti-platform/web/api/api.module'
import { AuthModule } from '@ti-platform/web/auth'
import { CONFIG, WhiteLabelSettingsProvider } from '@ti-platform/web/common'
import { AppCommonModule } from '@ti-platform/web/common/common.module'
import { languageInitializerProvider } from '@ti-platform/web/ui-kit/i18n'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import { NgxCsvParserModule } from 'ngx-csv-parser'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { MessageService } from 'primeng/api'
import { DialogService } from 'primeng/dynamicdialog'

import { AppInitializer } from '@ti-platform/tsp-admin/app/app.initializer'
import { DomainAssociationProvider } from '@ti-platform/tsp-admin/app/white-label'
import { TspAdminWhiteLabelPageComponent } from '@ti-platform/tsp-admin/app/white-label/white-label-page.component'
import { MapModule } from '@ti-platform/web/map/map.module'
import { I18NConfigProvider, LangI18NConfigProvider } from '@ti-platform/web/ui-kit/i18n/services'
import { UserModule } from '@ti-platform/web/user/user.module'
import { ImageCropperComponent } from 'ngx-image-cropper'
import { AppRoutingModule } from './app-routing.module'
import {
  BillingDashboardComponent,
  BillingHistoryListComponent,
  BillingInvoiceDetailsComponent,
  TspAdminBillingPageComponent,
} from './billing'
import {
  AUTH_PROVIDERS,
  LANG_PROVIDERS,
  TspAdminAppComponent,
  TspAdminLayoutComponent,
  TspAdminMenuComponent,
  TspAdminSignInPageComponent,
  TspAdminWhiteLabelSettingsProvider,
} from './common'
import {
  BulkCommandDevicesDialogComponent,
  BulkDevicesAssignComponent,
  CommandAddComponent,
  CommandEditComponent,
  CommandFormComponent,
  CommandsGridComponent,
  CommandsListComponent,
  CopyDeviceMediaConfigDialogComponent,
  DeviceAddComponent,
  DeviceEditComponent,
  DeviceFormComponent,
  DevicesListComponent,
  DevicesPageComponent,
  DevicesTypesDataProvider,
  LoadDevicesComponent,
  MediaUsageGridComponent,
  MediaUsageReportComponent,
  TspAdminDevicesGridComponent,
} from './devices'
import {
  CountyOptionsProvider,
  FleetAccountBillingComponent,
  FleetAccountsGridComponent,
  FleetAccountsListComponent,
  FleetAccountsPageComponent,
  FleetAccountViewComponent,
  PricingPlansProvider,
  TspAdminFleetAddDialogComponent,
  TspAdminFleetEditDialogComponent,
  TspAdminFleetFormComponent,
} from './fleet'
import { TspAdminRecyclingBinPageComponent } from './recycling-bin'
import { TspAdminUsersPageComponent } from './users/components'

@NgModule({
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    // ngx-translate
    HttpClientModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.envType !== 'local',
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // App
    ApiModule,
    AppRoutingModule,
    AppCommonModule,
    AuthModule,
    UserModule,
    UiKitModule,
    MapModule,
    ImageCropperComponent,
    NgxCsvParserModule,
  ],
  providers: [
    DialogService,
    MessageService,
    languageInitializerProvider,
    DevicesTypesDataProvider,
    PricingPlansProvider,
    CountyOptionsProvider,
    DomainAssociationProvider,
    {
      provide: CONFIG,
      useValue: environment,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...AUTH_PROVIDERS,
    ...LANG_PROVIDERS,
    { provide: I18NConfigProvider, useClass: LangI18NConfigProvider },
    { provide: WhiteLabelSettingsProvider, useClass: TspAdminWhiteLabelSettingsProvider },
  ],
  bootstrap: [TspAdminAppComponent],
  declarations: [
    TspAdminAppComponent,
    BillingDashboardComponent,
    BillingHistoryListComponent,
    BillingInvoiceDetailsComponent,
    CopyDeviceMediaConfigDialogComponent,
    DevicesListComponent,
    DeviceAddComponent,
    DeviceEditComponent,
    DeviceFormComponent,
    LoadDevicesComponent,
    BulkDevicesAssignComponent,
    BulkCommandDevicesDialogComponent,
    TspAdminDevicesGridComponent,
    DevicesPageComponent,
    CommandsListComponent,
    CommandFormComponent,
    CommandAddComponent,
    CommandEditComponent,
    CommandsGridComponent,
    MediaUsageReportComponent,
    MediaUsageGridComponent,
    FleetAccountViewComponent,
    FleetAccountsGridComponent,
    FleetAccountsPageComponent,
    FleetAccountsListComponent,
    FleetAccountBillingComponent,
    TspAdminFleetAddDialogComponent,
    TspAdminFleetEditDialogComponent,
    TspAdminFleetFormComponent,
    BillingDashboardComponent,
    TspAdminBillingPageComponent,
    TspAdminLayoutComponent,
    TspAdminMenuComponent,
    TspAdminRecyclingBinPageComponent,
    TspAdminSignInPageComponent,
    TspAdminUsersPageComponent,
    TspAdminWhiteLabelPageComponent,
  ],
})
export class AppModule {
  protected readonly initializer = inject(AppInitializer)

  public constructor() {
    this.initializer.init()
  }
}
