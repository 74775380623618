<div class="scroll-wrapper scrollbar-gray">
  <p-tabView>
    <p-tabPanel header="Buttons">
      <div class="wrapper">
        <h2 class="mb-12">Text Buttons</h2>
        <div class="table mb-24">
          <div></div>
          <div>34px</div>
          <div>38px</div>
          <div>40px</div>
          <div>42px</div>
          <div>48px</div>
          <div>Outlined</div>
          <div>Flat</div>
          <div>Disabled</div>
          <div>Hover</div>
          <div>Pressed</div>

          <div class="row-index text-14 fw-500">Primary</div>
          <p-button class="h--34 text-14"> Button </p-button>
          <p-button class="h--38 text-14"> Button </p-button>
          <p-button class="h--40 text-14"> Button </p-button>
          <p-button class="h--42 text-14"> Button </p-button>
          <p-button class="h--48 text-16"> Button </p-button>
          <p-button [outlined]="true" class="h--40 text-14"> Button </p-button>
          <p-button [outlined]="true" class="h--40 text-14 flat"> Button </p-button>
          <p-button [disabled]="true" class="h--40 text-14"> Button </p-button>
          <p-button class="h--40 text-14 hover"> Button </p-button>
          <p-button class="h--40 text-14 pressed"> Button </p-button>

          <div class="row-index text-14 fw-500">Secondary</div>
          <p-button [severity]="'secondary'" class="h--34 text-14"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--38 text-14"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--42 text-14"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--48 text-16"> Button </p-button>
          <p-button [severity]="'secondary'" [outlined]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'secondary'" [outlined]="true" class="h--40 text-14 flat">
            Button
          </p-button>
          <p-button [severity]="'secondary'" [disabled]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14 hover-secondary">
            Button
          </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14 pressed-secondary">
            Button
          </p-button>

          <div class="row-index text-14 fw-500">Warning</div>
          <p-button [severity]="'warning'" class="h--34 text-14"> Button </p-button>
          <p-button [severity]="'warning'" class="h--38 text-14"> Button </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14"> Button </p-button>
          <p-button [severity]="'warning'" class="h--42 text-14"> Button </p-button>
          <p-button [severity]="'warning'" class="h--48 text-16"> Button </p-button>
          <p-button [severity]="'warning'" [outlined]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'warning'" [outlined]="true" class="h--40 text-14 flat">
            Button
          </p-button>
          <p-button [severity]="'warning'" [disabled]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14 hover"> Button </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14 pressed"> Button </p-button>

          <div class="row-index text-14 fw-500">Danger</div>
          <p-button [severity]="'danger'" class="h--34 text-14"> Button </p-button>
          <p-button [severity]="'danger'" class="h--38 text-14"> Button </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14"> Button </p-button>
          <p-button [severity]="'danger'" class="h--42 text-14"> Button </p-button>
          <p-button [severity]="'danger'" class="h--48 text-16"> Button </p-button>
          <p-button [severity]="'danger'" [outlined]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'danger'" [outlined]="true" class="h--40 text-14 flat">
            Button
          </p-button>
          <p-button [severity]="'danger'" [disabled]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14 hover"> Button </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14 pressed"> Button </p-button>

          <div class="row-index text-14 fw-500">Success</div>
          <p-button [severity]="'success'" class="h--34 text-14"> Button </p-button>
          <p-button [severity]="'success'" class="h--38 text-14"> Button </p-button>
          <p-button [severity]="'success'" class="h--40 text-14"> Button </p-button>
          <p-button [severity]="'success'" class="h--42 text-14"> Button </p-button>
          <p-button [severity]="'success'" class="h--48 text-16"> Button </p-button>
          <p-button [severity]="'success'" [outlined]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'success'" [outlined]="true" class="h--40 text-14 flat">
            Button
          </p-button>
          <p-button [severity]="'success'" [disabled]="true" class="h--40 text-14">
            Button
          </p-button>
          <p-button [severity]="'success'" class="h--40 text-14 hover"> Button </p-button>
          <p-button [severity]="'success'" class="h--40 text-14 pressed"> Button </p-button>
        </div>

        <h2 class="mb-12">Button badge</h2>
        <div class="table badge mb-24">
          <div></div>
          <div>34px - 2</div>
          <div>40px - 2</div>
          <div>34px - 12</div>
          <div>40px - 12</div>
          <div>34px - 123</div>
          <div>40px - 123</div>
          <div>Outlined</div>
          <div>Flat</div>

          <div class="row-index text-14 fw-500">Primary</div>
          <p-button class="h--34 text-14" badge="2"> Button </p-button>
          <p-button class="h--40 text-14" badge="2"> Button </p-button>
          <p-button class="h--34 text-14" badge="12"> Button </p-button>
          <p-button class="h--40 text-14" badge="12"> Button </p-button>
          <p-button class="h--34 text-14" badge="123"> Button </p-button>
          <p-button class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" class="h--40 text-14 flat" badge="123"> Button </p-button>

          <div class="row-index text-14 fw-500">Secondary</div>
          <p-button [severity]="'secondary'" class="h--34 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--34 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--34 text-14" badge="123"> Button </p-button>
          <p-button [severity]="'secondary'" class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" [severity]="'secondary'" class="h--40 text-14" badge="123">
            Button
          </p-button>
          <p-button
            [outlined]="true"
            [severity]="'secondary'"
            class="h--40 text-14 flat"
            badge="123"
          >
            Button
          </p-button>

          <div class="row-index text-14 fw-500">Warning</div>
          <p-button [severity]="'warning'" class="h--34 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'warning'" class="h--34 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'warning'" class="h--34 text-14" badge="123"> Button </p-button>
          <p-button [severity]="'warning'" class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" [severity]="'warning'" class="h--40 text-14" badge="123">
            Button
          </p-button>
          <p-button [outlined]="true" [severity]="'warning'" class="h--40 text-14 flat" badge="123">
            Button
          </p-button>

          <div class="row-index text-14 fw-500">Danger</div>
          <p-button [severity]="'danger'" class="h--34 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'danger'" class="h--34 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'danger'" class="h--34 text-14" badge="123"> Button </p-button>
          <p-button [severity]="'danger'" class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" [severity]="'danger'" class="h--40 text-14" badge="123">
            Button
          </p-button>
          <p-button [outlined]="true" [severity]="'danger'" class="h--40 text-14 flat" badge="123">
            Button
          </p-button>

          <div class="row-index text-14 fw-500">Success</div>
          <p-button [severity]="'success'" class="h--34 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'success'" class="h--40 text-14" badge="2"> Button </p-button>
          <p-button [severity]="'success'" class="h--34 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'success'" class="h--40 text-14" badge="12"> Button </p-button>
          <p-button [severity]="'success'" class="h--34 text-14" badge="123"> Button </p-button>
          <p-button [severity]="'success'" class="h--40 text-14" badge="123"> Button </p-button>
          <p-button [outlined]="true" [severity]="'success'" class="h--40 text-14" badge="123">
            Button
          </p-button>
          <p-button [outlined]="true" [severity]="'success'" class="h--40 text-14 flat" badge="123">
            Button
          </p-button>
        </div>

        <h2 class="mb-12">Icon only Buttons</h2>
        <div class="table mb-24">
          <div></div>
          <div>34px</div>
          <div>38px</div>
          <div>40px</div>
          <div>42px</div>
          <div>48px</div>
          <div>Outlined</div>
          <div>Flat</div>
          <div>Disabled</div>
          <div>Hover</div>
          <div>Pressed</div>

          <div class="row-index text-14 fw-500">Primary</div>
          <p-button icon="pi pi-check" class="h--34 text-14"></p-button>
          <p-button icon="pi pi-check" class="h--38 text-14"></p-button>
          <p-button icon="pi pi-check" class="h--40 text-14"></p-button>
          <p-button icon="pi pi-check" class="h--42 text-14"></p-button>
          <p-button icon="pi pi-check" class="h--48 text-16"></p-button>
          <p-button icon="pi pi-check" [outlined]="true" class="h--40 text-14"></p-button>
          <p-button icon="pi pi-check" [outlined]="true" class="h--40 text-14 flat"></p-button>
          <p-button icon="pi pi-check" [disabled]="true" class="h--40 text-14"></p-button>
          <p-button icon="pi pi-check" class="h--40 text-14 hover"></p-button>
          <p-button icon="pi pi-check" class="h--40 text-14 pressed"></p-button>

          <div class="row-index text-14 fw-500">Secondary</div>
          <p-button [severity]="'secondary'" icon="pi pi-check" class="h--34 text-14"></p-button>
          <p-button [severity]="'secondary'" icon="pi pi-check" class="h--38 text-14"></p-button>
          <p-button [severity]="'secondary'" icon="pi pi-check" class="h--40 text-14"></p-button>
          <p-button [severity]="'secondary'" icon="pi pi-check" class="h--42 text-14"></p-button>
          <p-button [severity]="'secondary'" icon="pi pi-check" class="h--48 text-16"></p-button>
          <p-button
            [severity]="'secondary'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'secondary'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14 flat"
          ></p-button>
          <p-button
            [severity]="'secondary'"
            icon="pi pi-check"
            [disabled]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'secondary'"
            icon="pi pi-check"
            class="h--40 text-14 hover-secondary"
          ></p-button>
          <p-button
            [severity]="'secondary'"
            icon="pi pi-check"
            class="h--40 text-14 pressed-secondary"
          ></p-button>

          <div class="row-index text-14 fw-500">Warning</div>
          <p-button [severity]="'warning'" icon="pi pi-check" class="h--34 text-14"></p-button>
          <p-button [severity]="'warning'" icon="pi pi-check" class="h--38 text-14"></p-button>
          <p-button [severity]="'warning'" icon="pi pi-check" class="h--40 text-14"></p-button>
          <p-button [severity]="'warning'" icon="pi pi-check" class="h--42 text-14"></p-button>
          <p-button [severity]="'warning'" icon="pi pi-check" class="h--48 text-16"></p-button>
          <p-button
            [severity]="'warning'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'warning'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14 flat"
          ></p-button>
          <p-button
            [severity]="'warning'"
            icon="pi pi-check"
            [disabled]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'warning'"
            icon="pi pi-check"
            class="h--40 text-14 hover"
          ></p-button>
          <p-button
            [severity]="'warning'"
            icon="pi pi-check"
            class="h--40 text-14 pressed"
          ></p-button>

          <div class="row-index text-14 fw-500">Danger</div>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--34 text-14"></p-button>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--38 text-14"></p-button>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--40 text-14"></p-button>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--42 text-14"></p-button>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--48 text-16"></p-button>
          <p-button
            [severity]="'danger'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'danger'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14 flat"
          ></p-button>
          <p-button
            [severity]="'danger'"
            icon="pi pi-check"
            [disabled]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button [severity]="'danger'" icon="pi pi-check" class="h--40 text-14 hover"></p-button>
          <p-button
            [severity]="'danger'"
            icon="pi pi-check"
            class="h--40 text-14 pressed"
          ></p-button>

          <div class="row-index text-14 fw-500">Success</div>
          <p-button [severity]="'success'" icon="pi pi-check" class="h--34 text-14"></p-button>
          <p-button [severity]="'success'" icon="pi pi-check" class="h--38 text-14"></p-button>
          <p-button [severity]="'success'" icon="pi pi-check" class="h--40 text-14"></p-button>
          <p-button [severity]="'success'" icon="pi pi-check" class="h--42 text-14"></p-button>
          <p-button [severity]="'success'" icon="pi pi-check" class="h--48 text-16"></p-button>
          <p-button
            [severity]="'success'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'success'"
            icon="pi pi-check"
            [outlined]="true"
            class="h--40 text-14 flat"
          ></p-button>
          <p-button
            [severity]="'success'"
            icon="pi pi-check"
            [disabled]="true"
            class="h--40 text-14"
          ></p-button>
          <p-button
            [severity]="'success'"
            icon="pi pi-check"
            class="h--40 text-14 hover"
          ></p-button>
          <p-button
            [severity]="'success'"
            icon="pi pi-check"
            class="h--40 text-14 pressed"
          ></p-button>
        </div>

        <h2 class="mb-12">Use cases</h2>
        <div class="table use-cases mb-24">
          <div></div>
          <div>Icon left</div>
          <div>Icon right</div>
          <div>Icon double</div>
          <div>Width 100%</div>

          <div class="row-index text-14 fw-500">Primary</div>
          <p-button>
            <i class="pi pi-check"></i>
            Button
          </p-button>

          <p-button>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button>
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button class="w100">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <div class="row-index text-14 fw-500">Secondary</div>
          <p-button [severity]="'secondary'">
            <i class="pi pi-check"></i>
            Button
          </p-button>

          <p-button [severity]="'secondary'">
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'secondary'">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'secondary'" class="w100">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <div class="row-index text-14 fw-500">Warning</div>
          <p-button [severity]="'warning'">
            <i class="pi pi-check"></i>
            Button
          </p-button>

          <p-button [severity]="'warning'">
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'warning'">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'warning'" class="w100">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <div class="row-index text-14 fw-500">Danger</div>
          <p-button [severity]="'danger'">
            <i class="pi pi-check"></i>
            Button
          </p-button>

          <p-button [severity]="'danger'">
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'danger'">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'danger'" class="w100">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <div class="row-index text-14 fw-500">Success</div>
          <p-button [severity]="'success'">
            <i class="pi pi-check"></i>
            Button
          </p-button>

          <p-button [severity]="'success'">
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'success'">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>

          <p-button [severity]="'success'" class="w100">
            <i class="pi pi-check"></i>
            Button
            <i class="pi right pi-check"></i>
          </p-button>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Inputs">
      <div class="wrapper">
        <h2 class="mb-12">Inputs</h2>
        <div class="table-inputs p-fluid mb-24">
          <div></div>
          <div>34px</div>
          <div>38px</div>
          <div>40px</div>
          <div>Hover</div>
          <div>Focus</div>
          <div>Disabled</div>
          <div>Invalid</div>

          <div class="row-index text-14 fw-500">Input</div>
          <input class="h--34" pInputText placeholder="34px" type="text" />
          <input class="h--38" pInputText placeholder="38px" type="text" />
          <input class="h--40" pInputText placeholder="40px" type="text" />
          <input class="h--40 input-hover" pInputText placeholder="40px" type="text" />
          <input class="h--40 input-hover input-focus" pInputText placeholder="40px" type="text" />
          <input class="h--40" [disabled]="true" pInputText placeholder="40px" type="text" />
          <input class="h--40 ng-invalid ng-touched" pInputText placeholder="40px" type="text" />

          <div class="row-index text-14 fw-500">Input icon</div>
          <p-iconField class="h--34" iconPosition="left">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20"></app-icon-search>
            </p-inputIcon>
            <input pInputText type="text" />
          </p-iconField>

          <p-iconField class="h--38" iconPosition="left">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20"></app-icon-search>
            </p-inputIcon>
            <input pInputText type="text" />
          </p-iconField>

          <p-iconField class="h--40" iconPosition="right">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20"></app-icon-search>
            </p-inputIcon>
            <input pInputText type="text" />
          </p-iconField>

          <p-iconField class="h--40" iconPosition="right">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20"></app-icon-search>
            </p-inputIcon>
            <input pInputText class="input-hover" type="text" />
          </p-iconField>

          <p-iconField class="h--40">
            <p-inputIcon>
              <app-icon-search [height]="22" [width]="22"></app-icon-search>
            </p-inputIcon>
            <input pInputText class="input-hover input-focus" type="text" />
            <p-inputIcon>
              <app-icon-close [height]="22" [width]="22"></app-icon-close>
            </p-inputIcon>
          </p-iconField>

          <p-iconField class="h--40">
            <p-inputIcon>
              <app-icon-search [height]="22" [width]="22"></app-icon-search>
            </p-inputIcon>
            <input pInputText [disabled]="true" type="text" />
            <p-inputIcon>
              <app-icon-close [height]="22" [width]="22"></app-icon-close>
            </p-inputIcon>
          </p-iconField>

          <p-iconField class="h--40">
            <p-inputIcon>
              <app-icon-search [height]="22" [width]="22"></app-icon-search>
            </p-inputIcon>
            <input pInputText class="ng-invalid ng-touched" type="text" />
            <p-inputIcon>
              <app-icon-close [height]="22" [width]="22"></app-icon-close>
            </p-inputIcon>
          </p-iconField>

          <div class="row-index text-14 fw-500">Input hint</div>
          <div class="field with-hint" data-hint="km/h">
            <input class="h--34" placeholder="Enter speed" pInputText type="text" />
          </div>

          <div class="field with-hint" data-hint="sec.">
            <input class="h--38" placeholder="Enter time" pInputText type="text" />
          </div>

          <div class="field with-hint" data-hint="alt.">
            <input class="h--40" placeholder="Enter altitude" pInputText type="text" />
          </div>

          <div class="field with-hint" data-hint="alt.">
            <input class="h--40 input-hover" placeholder="Enter altitude" pInputText type="text" />
          </div>

          <div class="field with-hint" data-hint="alt.">
            <input
              class="h--40 input-hover input-focus"
              placeholder="Enter altitude"
              pInputText
              type="text"
            />
          </div>

          <div class="field with-hint" data-hint="alt.">
            <input
              [disabled]="true"
              class="h--40"
              placeholder="Enter altitude"
              pInputText
              type="text"
            />
          </div>

          <div class="field with-hint" data-hint="alt.">
            <input
              class="h--40 ng-invalid ng-touched"
              placeholder="Enter altitude"
              pInputText
              type="text"
            />
          </div>

          <div class="row-index text-14 fw-500">Input Mask</div>
          <p-inputMask
            [unmask]="true"
            class="h--34"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />
          <p-inputMask
            [unmask]="true"
            class="h--38"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />
          <p-inputMask
            [unmask]="true"
            class="h--40"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />

          <p-inputMask
            [unmask]="true"
            class="h--40 input-hover-mask"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />

          <p-inputMask
            [unmask]="true"
            class="h--40 input-hover-mask input-focus-mask"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />

          <p-inputMask
            [disabled]="true"
            [unmask]="true"
            class="h--40"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />

          <p-inputMask
            [unmask]="true"
            class="h--40 invalid"
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />

          <div class="row-index text-14 fw-500">Input dropdown</div>
          <p-dropdown [options]="videoChannels" appendTo="body" class="h--34">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <p-dropdown [options]="videoChannels" appendTo="body" class="h--38">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <p-dropdown [options]="videoChannels" appendTo="body" class="h--40">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [options]="videoChannels" appendTo="body" class="h--40 input-hover-dropdown">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          <p-dropdown
            [options]="videoChannels"
            appendTo="body"
            class="h--40 input-hover-dropdown input-focus-dropdown"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [disabled]="true" [options]="videoChannels" appendTo="body" class="h--40">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [options]="videoChannels" appendTo="body" class="h--40 invalid">
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>

          <div class="row-index text-14 fw-500">Input autocomplete</div>
          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [suggestions]="countries"
            [completeOnFocus]="true"
            placeholder="34px"
            class="h--34"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="38px"
            class="h--38"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="40px"
            class="h--40"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="40px"
            class="h--40 input-hover-autocomplete"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="40px"
            class="h--40 input-hover-autocomplete input-focus-autocomplete"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [disabled]="true"
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="40px"
            class="h--40"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <p-autoComplete
            [forceSelection]="true"
            optionLabel="title"
            [completeOnFocus]="true"
            placeholder="40px"
            class="h--40 invalid"
          >
            <ng-template let-param pTemplate="item">
              <div class="option-item text-14">
                <div>{{ param.title }}</div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="option-item text-14">
                <div>{{ 'filter.no-results' | translate }}</div>
              </div>
            </ng-template>
          </p-autoComplete>

          <div class="row-index text-14 fw-500">Input multiselect</div>
          <p-multiSelect
            class="token-wrap clear-all-hidden h--34 text-14"
            [options]="triggerOption"
            [maxSelectedLabels]="10"
            placeholder="Select filter"
            optionLabel="label"
            display="chip"
            [showClear]="true"
            [emptyFilterMessage]="'common.no-results' | translate"
          >
          </p-multiSelect>

          <p-multiSelect
            class="token-wrap clear-all-hidden h--38 text-14"
            [options]="triggerOption"
            [maxSelectedLabels]="10"
            placeholder="Select filter"
            optionLabel="label"
            display="chip"
            [showClear]="true"
            [emptyFilterMessage]="'common.no-results' | translate"
          >
          </p-multiSelect>

          <p-multiSelect
            class="token-wrap clear-all-hidden h--40 text-14"
            [options]="triggerOption"
            [maxSelectedLabels]="10"
            placeholder="Select filter"
            optionLabel="label"
            display="chip"
            [showClear]="true"
            [emptyFilterMessage]="'common.no-results' | translate"
          >
          </p-multiSelect>

          <p-multiSelect
            class="token-wrap clear-all-hidden input-hover-multiSelect h--40 text-14"
            [options]="triggerOption"
            [maxSelectedLabels]="10"
            placeholder="Select filter"
            optionLabel="label"
            display="chip"
            [showClear]="true"
            [emptyFilterMessage]="'common.no-results' | translate"
          >
          </p-multiSelect>

          <div class="empty">-</div>
          <div class="empty">-</div>
          <div class="empty">-</div>
        </div>

        <h2 class="mb-12">Use cases</h2>
        <div class="table-inputs use-cases p-fluid mb-24">
          <div>Single</div>
          <div>Double</div>
          <div>Double - label</div>

          <div>
            <div class="mb-8 text-14">Label</div>
            <input class="h--40" pInputText placeholder="40px" type="text" />
          </div>

          <div class="form">
            <div class="field-double flex">
              <div class="col">
                <div class="mb-8 text-14">Label</div>
                <input class="h--40" pInputText placeholder="40px" type="text" />
              </div>

              <div class="col">
                <div class="mb-8 text-14">Label</div>
                <input class="h--40" pInputText placeholder="40px" type="text" />
              </div>
            </div>
          </div>

          <div class="form">
            <div class="field-double flex">
              <div class="col flex">
                <div class="text-14">Label</div>
                <input class="h--40" pInputText placeholder="40px" type="text" />
              </div>

              <div class="col flex">
                <div class="text-14">Label</div>
                <input class="h--40" pInputText placeholder="40px" type="text" />
              </div>
            </div>
          </div>
        </div>

        <h2 class="mb-12">Text edit input</h2>
        <div class="table-inputs text-edit p-fluid">
          <div>Input edit state</div>
          <div>Input loading state</div>

          <div class="text-edit-wrapper m-32">
            <app-inline-text-editor
              [value]="'text'"
              [isLoading]="false"
              [isEditMode]="true"
            ></app-inline-text-editor>
          </div>

          <div class="text-edit-wrapper m-32">
            <app-inline-text-editor
              [value]="'text'"
              [isLoading]="true"
              [isEditMode]="true"
            ></app-inline-text-editor>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Icons">
      <div class="wrapper">
        <h2 class="mb-16">Icons</h2>
        <div class="table icons">
          <div class="icon-wrapper" *ngFor="let icon of icons">
            <app-dynamic-host
              class="mb-8"
              [componentInputs]="{ color: 'var(--color-gray-700)', height: 32, width: 32 }"
              [componentType]="$any(icon)"
            >
            </app-dynamic-host>
            <div class="text">
              {{ getComponentSelector(icon) }}
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <h2 class="mb-16">Transport</h2>
        <div class="table icons">
          <div class="icon-wrapper" *ngFor="let icon of transportIcons">
            <app-transport-icon-selector
              class="mb-8"
              [icon]="icon"
              [color]="'var(--color-gray-700)'"
            >
            </app-transport-icon-selector>
            <div class="text">{{ icon }}</div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Accordions">
      <div class="wrapper">
        <h2 class="mb-12">General</h2>
        <div class="table accordions mb-24">
          <div></div>
          <div>Size - small</div>
          <div>Size - medium</div>
          <div>Size - large</div>

          <div>Default</div>
          <p-accordion>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="large">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>

          <div>Disabled</div>
          <p-accordion disabled="true" class="">
            <p-accordionTab [disabled]="true">
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion disabled="true" class="medium">
            <p-accordionTab [disabled]="true">
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion disabled="true" class="large">
            <p-accordionTab [disabled]="true">
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>

        <h2 class="mb-12">Use cases</h2>
        <div class="table accordions use-cases mb-24">
          <div>Chevron right</div>
          <div>No header bottom border</div>
          <div>Borderless, inherit content color</div>
          <div>Custom colors</div>
          <div>Hover disabled</div>
          <div>Multiple tab</div>

          <p-accordion class="medium chevron-right">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <div class="flex">
                  <app-icon-info
                    [color]="'var(--color-gray-500)'"
                    [width]="16"
                    [height]="16"
                    class="mr-8"
                  ></app-icon-info>
                  <span class="text-14 fw-600">Accordion header</span>
                </div>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium no-border-bottom">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium borderless inherit-content-color color-custom gray">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium color-custom">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium color-custom hover-disabled">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
          <p-accordion class="medium color-custom">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
            <p-accordionTab>
              <ng-template pTemplate="header">
                <app-icon-info
                  [color]="'var(--color-gray-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-info>
                <span class="text-14 fw-600">Accordion header</span>
              </ng-template>
              <div class="accordion-content">
                <p class="text-14">1. Text</p>
                <p class="text-14">2. Text</p>
                <p class="text-14">3. Text</p>
                <p class="text-14">4. Text</p>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
