<div
  #wrapper
  class="icon"
  [appTooltip]="!!(isClicked$ | async) ? tooltipOnClick : tooltip"
  [tooltipPosition]="tooltipPosition"
  [tooltipForceActivate]="!!(tooltipForceActivate$ | async)"
  [tooltipForceDeactivate]="!!(tooltipForceDeactivate$ | async)"
  (click)="onClick()"
>
  <ng-content></ng-content>
</div>
