import { Subject } from 'rxjs'
import { MapAdapter } from '../adapters'

export abstract class BaseMarker {
  public constructor(
    public readonly options: any,
    protected readonly adapter: MapAdapter,
  ) {}

  public readonly destroy$ = new Subject<void>()
  public destroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
