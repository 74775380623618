import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-safari-symbol',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08197 11.7554C7.4249 11.7554 7.71201 11.4683 7.71201 11.1334V2.94284L7.66416 1.74657L8.19849 2.3128L9.41072 3.60479C9.52238 3.73239 9.68985 3.79619 9.84138 3.79619C10.1684 3.79619 10.4236 3.55693 10.4236 3.22995C10.4236 3.06247 10.3518 2.93487 10.2322 2.81524L7.53655 0.21533C7.37705 0.0558263 7.24147 0 7.08197 0C6.91449 0 6.77891 0.0558263 6.61941 0.21533L3.92379 2.81524C3.80416 2.93487 3.73239 3.06247 3.73239 3.22995C3.73239 3.55693 3.97164 3.79619 4.3066 3.79619C4.45813 3.79619 4.63358 3.73239 4.74524 3.60479L5.95747 2.3128L6.4918 1.74657L6.44395 2.94284V11.1334C6.44395 11.4683 6.73903 11.7554 7.08197 11.7554ZM2.50421 18H11.6518C13.3186 18 14.156 17.1706 14.156 15.5277V7.56845C14.156 5.92557 13.3186 5.09615 11.6518 5.09615H9.42667V6.38015H11.6278C12.4174 6.38015 12.872 6.81081 12.872 7.64023V15.4559C12.872 16.2853 12.4174 16.716 11.6278 16.716H2.52016C1.72264 16.716 1.28401 16.2853 1.28401 15.4559V7.64023C1.28401 6.81081 1.72264 6.38015 2.52016 6.38015H4.72929V5.09615H2.50421C0.837395 5.09615 0 5.92557 0 7.56845V15.5277C0 17.1706 0.837395 18 2.50421 18Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class SafariSymbolIconComponent extends BaseIconComponent {
  @Input() override height = 18
  @Input() override width = 15
  @Input() override color = '#0657DA'
}
