import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-copy',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_114_20470)">
        <path
          d="M1.33398 3.46658C1.33398 2.71985 1.33398 2.34648 1.47931 2.06126C1.60714 1.81038 1.81111 1.60641 2.062 1.47858C2.34721 1.33325 2.72058 1.33325 3.46732 1.33325H8.53398C9.28072 1.33325 9.65409 1.33325 9.9393 1.47858C10.1902 1.60641 10.3942 1.81038 10.522 2.06126C10.6673 2.34648 10.6673 2.71985 10.6673 3.46659V8.53325C10.6673 9.27999 10.6673 9.65336 10.522 9.93857C10.3942 10.1895 10.1902 10.3934 9.9393 10.5213C9.65409 10.6666 9.28072 10.6666 8.53398 10.6666H3.46732C2.72058 10.6666 2.34721 10.6666 2.062 10.5213C1.81111 10.3934 1.60714 10.1895 1.47931 9.93857C1.33398 9.65336 1.33398 9.27999 1.33398 8.53325V3.46658Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          d="M1.33398 3.46659C1.33398 2.71985 1.33398 2.34648 1.47931 2.06126C1.60714 1.81038 1.81111 1.60641 2.062 1.47858C2.34721 1.33325 2.72058 1.33325 3.46732 1.33325H8.53398C9.28072 1.33325 9.65409 1.33325 9.93931 1.47858C10.1902 1.60641 10.3942 1.81038 10.522 2.06126C10.6673 2.34648 10.6673 2.71985 10.6673 3.46659V8.53325C10.6673 9.27999 10.6673 9.65336 10.522 9.93857C10.3942 10.1895 10.1902 10.3934 9.93931 10.5213C9.65409 10.6666 9.28072 10.6666 8.53398 10.6666H3.46732C2.72058 10.6666 2.34721 10.6666 2.062 10.5213C1.81111 10.3934 1.60714 10.1895 1.47931 9.93857C1.33398 9.65336 1.33398 9.27999 1.33398 8.53325V3.46659Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          d="M5.33398 10.6666V12.5333C5.33398 13.28 5.33398 13.6534 5.47931 13.9386C5.60714 14.1895 5.81111 14.3934 6.062 14.5213C6.34721 14.6666 6.72058 14.6666 7.46732 14.6666H12.534C13.2807 14.6666 13.6541 14.6666 13.9393 14.5213C14.1902 14.3934 14.3942 14.1895 14.522 13.9386C14.6673 13.6534 14.6673 13.28 14.6673 12.5333V7.46659C14.6673 6.71985 14.6673 6.34648 14.522 6.06126C14.3942 5.81038 14.1902 5.60641 13.9393 5.47858C13.6541 5.33325 13.2807 5.33325 12.534 5.33325H10.6673M3.46732 10.6666H8.53398C9.28072 10.6666 9.65409 10.6666 9.93931 10.5213C10.1902 10.3934 10.3942 10.1895 10.522 9.93857C10.6673 9.65336 10.6673 9.27999 10.6673 8.53325V3.46659C10.6673 2.71985 10.6673 2.34648 10.522 2.06126C10.3942 1.81038 10.1902 1.60641 9.93931 1.47858C9.65409 1.33325 9.28072 1.33325 8.53398 1.33325H3.46732C2.72058 1.33325 2.34721 1.33325 2.062 1.47858C1.81111 1.60641 1.60714 1.81038 1.47931 2.06126C1.33398 2.34648 1.33398 2.71985 1.33398 3.46659V8.53325C1.33398 9.27999 1.33398 9.65336 1.47931 9.93857C1.60714 10.1895 1.81111 10.3934 2.062 10.5213C2.34721 10.6666 2.72058 10.6666 3.46732 10.6666Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CopyIconComponent extends BaseIconComponent {
  @Input() override height = 16
  @Input() override width = 16
}
