import { Provider } from '@angular/core'
import { ForgotPasswordViewModel, NewPasswordViewModel } from '@ti-platform/web/auth'
import { TspAdminNewPasswordViewModel } from './new-password.view-model'
import { TspAdminForgotPasswordViewModel } from './forgot-password.view-model'

export * from './forgot-password.view-model'
export * from './new-password.view-model'

export const AUTH_PROVIDERS: Provider[] = [
  {
    provide: NewPasswordViewModel,
    useClass: TspAdminNewPasswordViewModel,
  },
  {
    provide: ForgotPasswordViewModel,
    useClass: TspAdminForgotPasswordViewModel,
  },
]
