import { Component, inject, OnInit } from '@angular/core'
import {
  CONFIG,
  injectDestroy$,
  Memoize,
  WhiteLabelSettingsProvider,
} from '@ti-platform/web/common'
import { MapAdapter, TilesSource } from '../../adapters'
import { combineLatest, map, Observable, shareReplay, takeUntil } from 'rxjs'
import { SelectItem } from 'primeng/api'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-map-tiles-selector',
  templateUrl: 'tiles-selector.component.html',
  styleUrls: ['tiles-selector.component.scss'],
})
export class TilesSelectorComponent implements OnInit {
  protected readonly config = inject(CONFIG)
  protected readonly languageService = inject(LanguageService)
  protected readonly map = inject(MapAdapter)
  protected readonly whiteLabelSettings = inject(WhiteLabelSettingsProvider)
  protected readonly destroy$ = injectDestroy$()

  protected currentTile: TilesSource | undefined

  @Memoize()
  public get tileSourceOptions$(): Observable<SelectItem[]> {
    return combineLatest([
      this.whiteLabelSettings.data$,
      this.languageService.massTranslate$({
        aws: 'map.tiles.aws-maps',
        googleMaps: 'map.tiles.google-maps',
        googleMapsSatellite: 'map.tiles.google-maps-satellite',
        mapTiler: 'map.tiles.maptiler',
        openStreetMaps: 'map.tiles.open-street-maps',
        esriImagery: 'map.tiles.esri-imagery',
        hereHybrid: 'map.tiles.here-hybrid',
        hereImagery: 'map.tiles.here-imagery',
      }),
    ]).pipe(
      map(([whiteLabelSettings, labels]) => {
        const options = [
          { label: labels.aws, value: TilesSource.Standard },
          { label: labels.hereHybrid, value: TilesSource.Satellite },
          { label: labels.openStreetMaps, value: TilesSource.OSM },
        ]
        if (this.config.envType != 'production') {
          options.push({ label: labels.hereImagery, value: TilesSource.HEREImagery })
          options.push({ label: labels.esriImagery, value: TilesSource.EsriImagery })
        }
        if (this.config.mapTilerApiKey) {
          options.push({ label: labels.mapTiler, value: TilesSource.MapTiler })
        }
        if (whiteLabelSettings.googleMapsKey) {
          options.push(
            ...[
              { label: labels.googleMaps, value: TilesSource.Google },
              { label: labels.googleMapsSatellite, value: TilesSource.GoogleSatellite },
            ],
          )
        }
        return options
      }),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: true }),
    )
  }

  public ngOnInit() {
    this.map.tilesSource$.subscribe((value) => {
      this.currentTile = value
    })
  }
}
