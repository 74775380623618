import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[appRepeat]',
})
export class RepeatDirective implements OnInit {
  @Input('appRepeat') repeatCount = 0

  public constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
  ) {}

  public ngOnInit() {
    for (let i = 0; i < this.repeatCount; i++) {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    }
  }
}
