import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'

@Component({
  selector: 'app-dynamic-host',
  template: `<ng-container #container></ng-container>`,
})
export class DynamicHostComponent implements AfterViewInit {
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)

  @Input() componentType!: Type<unknown>
  @Input() componentInputs?: object

  @ViewChild('container', { read: ViewContainerRef })
  protected readonly container!: ViewContainerRef

  public ngAfterViewInit() {
    if (this.componentType) {
      const componentRef = this.container.createComponent(this.componentType)
      const componentInputs = this.componentInputs ? Object.entries(this.componentInputs) : []
      if (componentInputs.length) {
        Object.getOwnPropertyNames(this.componentInputs).forEach((key) => {
          // @ts-expect-error Error
          componentRef.instance[key] = this.componentInputs[key]
        })

        // Ensure component is properly rendered
        componentRef.changeDetectorRef.markForCheck()
        this.changeDetectorRef.detectChanges()
      }
    }
  }
}
