import { DatePipe } from '@angular/common'
import { inject, Injectable } from '@angular/core'
import { firstValueFrom, shareReplay, takeUntil } from 'rxjs'
import { DateStyle } from '@ti-platform/contracts'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { I18NConfig, I18NConfigProvider } from '@ti-platform/web/ui-kit/i18n'
import { LanguageService } from './language.service'

export type DateFormatType =
  | 'short-date'
  | 'month-date'
  | 'long-time'
  | 'grid-date'
  | 'grid-date-time'
  | 'grid-date-time-sec'
  | 'triggered-alert-view'
  | 'month-year'

type DateTranslations = {
  fullMonthLabels: string[]
  shortMonthLabels: string[]
}

@Injectable()
export class DateFormatService {
  protected readonly datePipe = inject(DatePipe)
  protected readonly languageService = inject(LanguageService)
  protected readonly i18nConfigProvider = inject(I18NConfigProvider)
  protected readonly destroy$ = injectDestroy$()

  protected readonly shortMonthRE = /Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/
  protected readonly fullMonthRE =
    /January|February|March|April|May|June|July|August|September|October|November|December/

  protected readonly dateFormatOptions = {
    [DateStyle.EU]: {
      'short-date': 'dd/MM/yyyy',
      'month-date': 'MMM d',
      'long-time': 'hh:mm:ss a',
      'grid-date': 'MMM d, YYYY',
      'grid-date-time': 'MMM d, yyyy h:mm a',
      'grid-date-time-sec': 'MMM d, yyyy h:mm:ss a',
      'triggered-alert-view': 'MMM d, h:mm:ss a',
      'month-year': 'MMMM YYY',
    },
    [DateStyle.US]: {
      'short-date': 'MM/dd/yyyy',
      'month-date': 'MMM d',
      'long-time': 'hh:mm:ss a',
      'grid-date': 'MMM d, YYYY',
      'grid-date-time': 'MMM d, yyyy h:mm a',
      'grid-date-time-sec': 'MMM d, yyyy h:mm:ss a',
      'triggered-alert-view': 'MMM d, h:mm:ss a',
      'month-year': 'MMMM YYY',
    },
  } as Record<DateStyle, Record<DateFormatType, string>>

  protected lastI18NConfig?: I18NConfig
  protected lastTranslation?: DateTranslations

  public constructor() {
    Promise.resolve().then(() => {
      this.i18nConfigProvider.config$
        .pipe(takeUntil(this.destroy$))
        .subscribe((config) => (this.lastI18NConfig = config))

      // Save last translations value to the cache
      this.rawTranslations$.subscribe((raw) => {
        this.lastTranslation = this.prepareTranslations(raw)
      })
    })
  }

  public async formatDate(date: Date | string | number, formatType: DateFormatType) {
    const config = await this.i18nConfigProvider.getConfig()
    const translations = await this.getTranslations()
    return this.doFormat(date, this.dateFormatOptions[config.dateStyle][formatType], translations)
  }

  public formatDateInstant(date: Date | string | number, formatType: DateFormatType) {
    if (!this.lastI18NConfig || !this.lastTranslation) return ''

    return this.doFormat(
      date,
      this.dateFormatOptions[this.lastI18NConfig.dateStyle][formatType],
      this.lastTranslation,
    )
  }

  public async format(date: Date | string | number, format: string) {
    const translations = await this.getTranslations()
    return this.doFormat(date, format, translations)
  }

  public formatInstant(date: Date | string | number, format: string) {
    if (!this.lastI18NConfig || !this.lastTranslation) return ''
    return this.doFormat(date, format, this.lastTranslation)
  }

  protected doFormat(date: Date | string | number, format: string, monthLabels: DateTranslations) {
    if (!(date instanceof Date)) date = new Date(date)

    const formatted = this.datePipe.transform(date, format) || ''

    // Translate short month labels
    if (format.includes('MMMM')) {
      const month = date.getMonth()
      const monthName = monthLabels.fullMonthLabels[month]
      return formatted.replace(this.fullMonthRE, monthName)
    } else if (format.includes('MMM')) {
      const month = date.getMonth()
      const monthName = monthLabels.shortMonthLabels[month]
      return formatted.replace(this.shortMonthRE, monthName)
    }

    return formatted
  }

  protected async getTranslations(): Promise<DateTranslations> {
    return this.lastTranslation
      ? this.lastTranslation
      : this.prepareTranslations(await firstValueFrom(this.rawTranslations$))
  }

  protected prepareTranslations(raw: any): DateTranslations {
    return {
      fullMonthLabels: this.prepareArrayTranslation(raw.fullMonthLabels),
      shortMonthLabels: this.prepareArrayTranslation(raw.shortMonthLabels),
    }
  }

  protected prepareArrayTranslation(input: any) {
    return (Array.from(Object.values(input)) as unknown as Array<() => string>).map((cb) => cb())
  }

  @Memoize()
  protected get rawTranslations$() {
    return this.languageService
      .massTranslate$({
        fullMonthLabels: 'date-time.month-full',
        shortMonthLabels: 'date-time.month-short',
      })
      .pipe(takeUntil(this.destroy$), shareReplay({ bufferSize: 1, refCount: false }))
  }
}
