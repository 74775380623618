<p-dialog
  class="dialog-primary reset-form medium"
  [modal]="true"
  [visible]="viewModel.isFormOpened()"
  (visibleChange)="viewModel.isFormOpened.set($event)"
>
  <ng-template pTemplate="header">
    <header>
      <div class="title mb-6 text-18 fw-600">
        {{ 'auth.forgot-password.title' | translate }}
      </div>
      <div class="subtitle text-14">
        {{ 'auth.forgot-password.subtitle' | translate }}
      </div>
    </header>
  </ng-template>

  <form [formGroup]="viewModel.form" class="p-fluid medium p-t-16">
    <input class="h--40" formControlName="email" pInputText type="text" />

    <div class="errors-box">
      @if (viewModel.form.touched && viewModel.form.invalid) {
        @if (viewModel.form.controls.email.hasError('required')) {
          <p class="error-message">{{ 'auth.error.email-is-required' | translate }}</p>
        }
        @if (viewModel.form.controls.email.hasError('email')) {
          <p class="error-message">{{ 'auth.error.email-is-not-valid' | translate }}</p>
        }
      }

      @if (viewModel.apiErrorMessage()) {
        <p class="error-message">{{ viewModel.apiErrorMessage() }}</p>
      }
    </div>
  </form>

  <div class="footer border-top">
    <p-button
      class="text-14 fw-600 h--40 w100"
      [disabled]="viewModel.isLoading()"
      (click)="viewModel.submitForm()"
    >
      {{ 'button.reset' | translate }}
    </p-button>
  </div>
</p-dialog>

<p-dialog
  class="dialog-primary reset-initiated small no-controls"
  [modal]="true"
  [visible]="viewModel.isResetRequested()"
  (visibleChange)="viewModel.isResetRequested.set($event)"
>
  <ng-template pTemplate="header">
    <header class="header">
      <div class="icon-check-container mb-16">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6666 8.66675L11.9999 23.3334L5.33325 16.6667"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="title mb-6 text-18 fw-600">
        {{ 'auth.forgot-password-success.title' | translate }}
      </div>
      <div class="subtitle text-14">
        {{ 'auth.forgot-password-success.subtitle' | translate }}
      </div>
    </header>
  </ng-template>

  <div class="footer">
    <p-button class="text-14 fw-600 h--40 w100" (click)="viewModel.closeForm()"> OK </p-button>
  </div>
</p-dialog>
