import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { Action, DevicesGridData, Resource } from '@ti-platform/contracts'
import {
  defaultEditNameStatus,
  defaultTestStatus,
  DevicesListModel,
  editNameStatus,
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
  testStatus,
} from '@ti-platform/tsp-admin/app/devices/models'
import { BrowserSessionStorage, selectState } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout/components'

const defaultDeviceColumns = [
  'name',
  'model',
  'identifier',
  'sim',
  'createdAt',
  'fleetName',
  'server',
  'lastActive',
  'deviceTest',
  'actions',
]

@Component({
  selector: 'app-tsp-admin-devices-list',
  templateUrl: 'devices-grid.component.html',
  styleUrls: ['devices-grid.component.scss'],
})
export class TspAdminDevicesGridComponent {
  @Input() deviceTestStatus: testStatus = defaultTestStatus
  @Input() deviceEditNameStatus: editNameStatus = defaultEditNameStatus
  @Input() gridOrder: DataGridSortOrder = {
    column: 'createdAt',
    order: 'DESC',
  }
  _displayColumns = [...defaultDeviceColumns]

  get displayColumns() {
    return this._displayColumns
  }

  @Input()
  set displayColumns(value: string[]) {
    const columns = value?.length ? value : defaultDeviceColumns
    this.devicesViewModel.limitDisplayColumns(columns)
  }
  @Output() addEvent = new EventEmitter<string>()
  @Output() editEvent = new EventEmitter<string>()
  @Output() deleteEvent = new EventEmitter<string>()
  @Output() testClickEvent = new EventEmitter<string>()
  @Output() menuClickEvent = new EventEmitter()
  @Output() sortUpdated = new EventEmitter<DataGridSortOrder>()
  @Output() editNameClickEvent = new EventEmitter<DevicesGridData>()
  @Output() changeNameEvent = new EventEmitter<{ device: DevicesGridData; newName: string }>()
  @Output() saveNameEvent = new EventEmitter<{ device: DevicesGridData; savedName: string }>()
  @Output() cancelEditNameEvent = new EventEmitter()

  protected readonly languageService = inject(LanguageService)
  protected readonly devicesViewModel = inject(DevicesListModel)
  protected readonly state = selectState(DevicesListModel)
  protected readonly sessionStorage = inject(BrowserSessionStorage)

  protected readonly nameMinLength = MIN_NAME_LENGTH
  protected readonly nameMaxLength = MAX_NAME_LENGTH

  protected readonly Action = Action
  protected readonly Resource = Resource

  protected get noDataTitleKey() {
    let result = 'tsp-admin.devices.no-data.title'
    if (this.devicesViewModel.isActiveSearch) {
      result = 'search.no-data-title'
    }
    return result
  }

  protected get noDataDescriptionKey() {
    let result = 'tsp-admin.devices.no-data.description'
    if (this.devicesViewModel.isActiveSearch) {
      result = 'search.no-data-description'
    }
    return result
  }

  async onMenuClick() {
    this.menuClickEvent.emit()
  }

  protected onSortUpdated(event: DataGridSortOrder) {
    this.devicesViewModel.unselect()
    this.devicesViewModel.setOrder(event)
    this.sortUpdated.emit(event)
  }

  protected onLoadMore() {
    this.devicesViewModel.loadNextPage()
  }

  protected async onClickTest(deviceId: string) {
    this.testClickEvent.emit(deviceId)
  }

  protected onClickEditName(device: DevicesGridData) {
    this.editNameClickEvent.emit(device)
  }

  protected onChangeName(device: DevicesGridData, newName: string) {
    this.changeNameEvent.emit({ device, newName })
  }

  protected onSaveDeviceName(device: DevicesGridData, savedName: string) {
    this.saveNameEvent.emit({ device, savedName })
  }

  protected onCancelEditDeviceName() {
    this.cancelEditNameEvent.emit()
  }

  protected onAddClick() {
    this.addEvent.emit()
  }
}
