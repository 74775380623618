import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MenuItem } from 'primeng/api'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  styleUrls: ['breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() public items: MenuItem[] | null | undefined = undefined
}
