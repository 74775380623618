import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-video',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 10.2924L19.4752 8.15023C19.7837 7.96003 20.171 7.95165 20.4875 8.12832C20.8039 8.30499 21 8.63906 21 9.00149V15.9983C21 16.3607 20.8039 16.6948 20.4875 16.8714C20.171 17.0481 19.7838 17.0397 19.4753 16.8496L16 14.7074"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="3"
        y="6"
        width="13"
        height="13"
        rx="3"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class VideoIconComponent extends BaseIconComponent {}
