import { Geometry } from '../data'

export interface Location {
  id: string
  name: string
  type: LocationType
  address: string
  geolocation: Geometry
  properties: LocationProps
  createdAt?: Date
  updatedAt?: Date
  externalId?: number
}

export interface LocationProps {
  center?: number[]
  radius?: number
}

export const enum LocationType {
  Address = 0,
  Polygon = 1,
  Circle = 2,
}

export const ADDRESS_LOCATION_RADIUS = 0.03 // 30 meters
