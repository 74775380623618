import { inject, Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'
import { combineLatest, firstValueFrom } from 'rxjs'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { injectDestroy$ } from '@ti-platform/web/common'

@Injectable({ providedIn: 'root' })
export class TitleController {
  protected readonly router = inject(Router)
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected readonly titleService = inject(Title)
  protected readonly branding = inject(BrandingService)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  subscribe() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        switchMap(() => {
          const currentRoute = this.getCurrentRoute(this.activatedRoute)
          return combineLatest([this.branding.branding$, currentRoute.data])
        }),
        map(([branding, data]) => ({
          title: data?.title ?? 'fleet.heading',
          module: data?.module ?? undefined,
          tsp: branding.name,
        })),
        switchMap(async (data) => {
          let title = await this.languageService.translate(data.title)
          if (data?.module) {
            const module = await this.languageService.translate(data.module)
            title = `${module}: ${title}`
          }
          return {
            ...data,
            title,
          }
        }),
      )
      .subscribe((data) => {
        this.titleService.setTitle(`${data.tsp} | ${data.title}`)
      })
  }

  async setTitle(title: string) {
    title = await this.languageService.translate(title)
    const branding = await firstValueFrom(this.branding.branding$)
    this.titleService.setTitle(`${branding.name} | ${title}`)
  }

  private getCurrentRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild
    }
    return route
  }
}
