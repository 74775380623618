import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { FilterOptions } from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumn, DataGridSortOrder } from '../data-grid'
import { FilterPanelService } from '../filter-panel/filter-panel.service'
import { CustomSortOption, SortListComponent } from '../sort-list/sort-list.component'

@Component({
  selector: 'app-filter-panel',
  templateUrl: 'filter-panel.component.html',
  styleUrls: ['filter-panel.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'var(--transform-in)',
          display: 'flex',
        }),
      ),
      state(
        'out',
        style({
          transform: 'var(--transform-out)',
          display: 'none',
        }),
      ),
      transition('in => out', [
        animate('200ms ease-in-out', style({ transform: 'var(--transform-out)' })),
        style({ display: 'none' }),
      ]),
      transition('out => in', [
        style({ display: 'flex' }),
        animate('200ms ease-in-out', style({ transform: 'var(--transform-in)' })),
      ]),
    ]),
  ],
})
export class FilterPanelComponent implements OnChanges {
  @Output() gridSortChanged = new EventEmitter<DataGridSortOrder>()
  @Output() customSortChanged = new EventEmitter<any>()
  @Output() filterValueChange = new EventEmitter<Record<string, any>>()

  @Input() isFilteringEnabled = true
  @Input() isSortingEnabled = true

  @Input() gridColumns?: DataGridColumn[] | null
  @Input() gridSortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null

  // Custom sort options without direction
  @Input() customSortOptions?: CustomSortOption[]
  @Input() customSortValue?: any

  @Input() filterOptions?: FilterOptions[] | null
  @Input() filterValue?: Record<string, any> | null

  @Input() expandSortByDefault?: boolean

  protected localCustomSortValue?: any
  protected localGridSortOrder?: DataGridSortOrder | null
  protected localFilterValue: Record<string, Set<any>> = {}
  protected localFiltersCount?: number

  @ViewChild(SortListComponent)
  protected readonly sortList!: SortListComponent

  protected readonly deviceService = inject(DeviceService)
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)
  protected readonly filterPanelService = inject(FilterPanelService)

  public open() {
    this.filterPanelService.openPanel()
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterValue) {
      this.localFilterValue = this.getLocalFilterValue(changes.filterValue.currentValue)
      this.recalculateLocalFiltersCount()
    }
    if (changes.gridSortOrder) {
      this.localGridSortOrder = changes.gridSortOrder.currentValue
    }
    if (changes.customSortValue) {
      this.localCustomSortValue = changes.customSortValue.currentValue
    }
  }

  protected closeFilterPanel() {
    this.filterPanelService.closePanel()
  }

  protected gridSortOrderChanged(order: DataGridSortOrder) {
    this.localGridSortOrder = order
  }

  protected customSortValueChanged(newSortingValue: any) {
    this.localCustomSortValue = newSortingValue
  }

  protected onSelectedItemsChange(key: string, selectedItems: Set<any>) {
    this.localFilterValue[key] = selectedItems
    this.recalculateLocalFiltersCount()
  }

  protected clearSelectedFilters(filterOption: FilterOptions) {
    this.localFilterValue[filterOption.key] = new Set()
    this.recalculateLocalFiltersCount()
    this.changeDetectorRef.markForCheck()
  }

  protected resetAll() {
    this.filterValueChange.emit({})
    this.customSortChanged.emit(null)
    this.localFilterValue = {}
    if (this.defaultSortOrder) {
      this.gridSortChanged.emit(this.defaultSortOrder)
    }
    this.recalculateLocalFiltersCount()
    this.closeFilterPanel()
  }

  protected onCancel() {
    this.localGridSortOrder = this.gridSortOrder
    this.localCustomSortValue = this.customSortValue
    this.localFilterValue = this.getLocalFilterValue(this.filterValue)
    this.recalculateLocalFiltersCount()
    this.closeFilterPanel()
  }

  protected onApply() {
    if (this.localGridSortOrder && this.localGridSortOrder !== this.gridSortOrder) {
      this.gridSortChanged.emit(this.localGridSortOrder)
    }
    if (this.localCustomSortValue !== null && this.localCustomSortValue !== undefined) {
      this.customSortChanged.emit(this.localCustomSortValue)
    }
    if (this.isFilteringEnabled && Object.getOwnPropertyNames(this.localFilterValue).length) {
      this.filterValueChange.emit(this.getGlobalFilterValue(this.localFilterValue))
    }
    this.closeFilterPanel()
  }

  protected recalculateLocalFiltersCount() {
    let localFiltersCount = 0
    Object.keys(this.localFilterValue).forEach((key) => {
      if (this.localFilterValue[key]) {
        localFiltersCount += this.localFilterValue[key].size
      }
    })
    this.localFiltersCount = localFiltersCount
  }

  // TODO: Consider removing this method
  protected getLocalFilterValue(
    globalFilterValue: Record<string, unknown> | undefined | null,
  ): Record<string, Set<any>> {
    const localFilterValue: Record<string, Set<any>> = {}
    for (const property in globalFilterValue) {
      if (Array.isArray(globalFilterValue[property])) {
        localFilterValue[property] = new Set(globalFilterValue[property] as Set<any>)
      }
    }
    return localFilterValue
  }

  // TODO: Consider removing this method
  protected getGlobalFilterValue(localFilterValue: Record<string, any>): Record<string, any> {
    const globalFilterValue: Record<string, any> = {}
    for (const property in localFilterValue) {
      globalFilterValue[property] = Array.from(localFilterValue[property])
    }
    return globalFilterValue
  }
}
