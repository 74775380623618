import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-group-2',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94701 8C8.31559 8 8.49988 8 8.64066 7.91954C8.76449 7.84876 8.86517 7.73582 8.92827 7.59691C9 7.43898 9 7.23225 9 6.81879V4.18121C9 3.76775 9 3.56102 8.92827 3.40309C8.86517 3.26418 8.76449 3.15124 8.64066 3.08047C8.49988 3 8.31559 3 7.94701 3L3.05299 3C2.68441 3 2.50012 3 2.35934 3.08047C2.23551 3.15124 2.13483 3.26418 2.07173 3.40309C2 3.56102 2 3.76775 2 4.18121V6.81879C2 7.23225 2 7.43898 2.07173 7.59691C2.13483 7.73582 2.23551 7.84876 2.35934 7.91954C2.50012 8 2.68441 8 3.05299 8H7.94701Z"
        [attr.stroke]="color"
        style="
                  stroke-opacity: 1;
                "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.94701 16C8.31559 16 8.49988 16 8.64066 15.9195C8.76449 15.8488 8.86517 15.7358 8.92827 15.5969C9 15.439 9 15.2323 9 14.8188V12.1812C9 11.7677 9 11.561 8.92827 11.4031C8.86517 11.2642 8.76449 11.1512 8.64066 11.0805C8.49988 11 8.31559 11 7.94701 11L3.05299 11C2.68441 11 2.50012 11 2.35934 11.0805C2.23551 11.1512 2.13483 11.2642 2.07173 11.4031C2 11.561 2 11.7677 2 12.1812V14.8188C2 15.2323 2 15.439 2.07173 15.5969C2.13483 15.7358 2.23551 15.8488 2.35934 15.9195C2.50012 16 2.68441 16 3.05299 16H7.94701Z"
        [attr.stroke]="color"
        style="
                  stroke-opacity: 1;
                "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.947 8C17.3156 8 17.4999 8 17.6407 7.91954C17.7645 7.84876 17.8652 7.73582 17.9283 7.59691C18 7.43898 18 7.23225 18 6.81879V4.18121C18 3.76775 18 3.56102 17.9283 3.40309C17.8652 3.26418 17.7645 3.15124 17.6407 3.08047C17.4999 3 17.3156 3 16.947 3L12.053 3C11.6844 3 11.5001 3 11.3593 3.08047C11.2355 3.15124 11.1348 3.26418 11.0717 3.40309C11 3.56102 11 3.76775 11 4.18121V6.81879C11 7.23225 11 7.43898 11.0717 7.59691C11.1348 7.73582 11.2355 7.84876 11.3593 7.91954C11.5001 8 11.6844 8 12.053 8H16.947Z"
        [attr.stroke]="color"
        style="
                  stroke-opacity: 1;
                "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.947 16C17.3156 16 17.4999 16 17.6407 15.9195C17.7645 15.8488 17.8652 15.7358 17.9283 15.5969C18 15.439 18 15.2323 18 14.8188V12.1812C18 11.7677 18 11.561 17.9283 11.4031C17.8652 11.2642 17.7645 11.1512 17.6407 11.0805C17.4999 11 17.3156 11 16.947 11L12.053 11C11.6844 11 11.5001 11 11.3593 11.0805C11.2355 11.1512 11.1348 11.2642 11.0717 11.4031C11 11.561 11 11.7677 11 12.1812V14.8188C11 15.2323 11 15.439 11.0717 15.5969C11.1348 15.7358 11.2355 15.8488 11.3593 15.9195C11.5001 16 11.6844 16 12.053 16H16.947Z"
        [attr.stroke]="color"
        style="
                  stroke-opacity: 1;
                "
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Group2IconComponent extends BaseIconComponent {}
