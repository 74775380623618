import { Component, inject, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { takeUntil, combineLatest, firstValueFrom } from 'rxjs'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { GeofenceMarkerEvents, GeofenceMarkerState } from '../../contracts'
import { GeofenceEditMarkerStep } from '../markers/geofence/geofence-marker.component'
import { BaseMapOverlayComponent } from '../map-overlay/base-map-overlay.component'

@Component({
  selector: 'app-map-geofence-form',
  templateUrl: 'geofence-form.component.html',
  styleUrls: ['geofence-form.component.scss'],
})
export class GeofenceFormComponent extends BaseMapOverlayComponent implements OnInit {
  public state!: GeofenceMarkerState
  public events!: GeofenceMarkerEvents

  protected readonly deviceService = inject(DeviceService)

  protected readonly form = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required],
      asyncValidators: [
        (control: AbstractControl) =>
          firstValueFrom(this.state.notAllowedNames$).then((notAllowedNames) =>
            notAllowedNames.includes(control.value?.trim()) ? { 'not-allowed': true } : null,
          ),
      ],
    }),
  })

  public ngOnInit() {
    this.state.name$
      .pipe(takeUntil(this.destroy$))
      .subscribe((name) => this.form.controls.name.setValue(name))

    this.state.offset$.pipe(takeUntil(this.destroy$)).subscribe((offset) => this.setOffset(offset))
  }

  public setOffset(offset: number) {
    this.elementRef.style.setProperty('--offset-top-px', `${offset.toFixed(2)}px`)
  }

  public override bindToElement(targetEl: HTMLElement, hostEl: HTMLElement) {
    if (this.deviceService.isMobile()) {
      document.body?.appendChild(this.elementRef)
      return
    }
    super.bindToElement(targetEl, hostEl)
  }

  protected override observeElement(targetEl: HTMLElement) {
    combineLatest([this.createElementObserver(targetEl), this.state.isPinned$, this.state.step$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([, pinned, step]) => {
        const isPinned =
          pinned && (step === GeofenceEditMarkerStep.Create || step === GeofenceEditMarkerStep.Edit)
        if (isPinned) {
          this.elementRef.style.transform = ''
          this.elementRef.classList.add('pinned')
          this.elementRef.classList.add('no-transition')
        } else {
          this.elementRef.classList.remove('pinned')
          setTimeout(() => this.elementRef.classList.remove('no-transition'), 16)
          if (this.elementRef.style.transform !== targetEl.style.transform) {
            this.elementRef.style.transform = targetEl.style.transform
          }
        }
      })
  }

  protected onSubmit(setupAlert = false) {
    if (!this.form.valid) {
      return this.form.markAllAsTouched()
    }
    this.events.save$.next({ data: this.form.value as any, setupAlert })
  }

  protected readonly GeofenceEditMarkerStep = GeofenceEditMarkerStep
}
