import { Component, inject, OnInit, signal } from '@angular/core'
import { Profile, PushClient } from '@ti-platform/web/auth'
import { CONFIG, selectState } from '@ti-platform/web/common'
import { BaseUserSettingsModel } from '../../models'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: 'user-settings-dialog.component.html',
  styleUrls: ['user-settings-dialog.component.scss'],
})
export class UserSettingsDialogComponent implements OnInit {
  protected readonly config = inject(CONFIG)
  protected readonly profile = inject(Profile)
  protected readonly model = inject(BaseUserSettingsModel)
  protected readonly state = selectState(BaseUserSettingsModel)
  protected readonly pushes = inject(PushClient)
  protected readonly languageService = inject(LanguageService)

  protected readonly isOpened = signal(false)
  protected readonly isPushNotificationsAvailable = signal(false)

  protected readonly PASSWORD_PLACEHOLDER = '****************'

  public open() {
    this.isOpened.set(true)
  }

  public close() {
    this.isOpened.set(false)
  }

  public async ngOnInit() {
    this.isPushNotificationsAvailable.set(!!this.config.fcmVapidKey)
    await this.model.init()
  }

  async enablePushes() {
    this.model.setErrorMessage('')

    if (!((await this.pushes.register()) && this.pushes.isRegistered())) {
      this.model.setErrorMessage(
        await this.languageService.translate('users.profile.pushes-denied-summary'),
      )
    }
  }
}
