import { ActivatedRoute, Router } from '@angular/router'
import { Component, inject, OnInit, signal } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { takeUntil } from 'rxjs'
import { AuthService, AuthSession } from '@ti-platform/web/auth'
import { BrowserLocalStorage, injectDestroy$ } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ApiService } from '@ti-platform/web/api'

@Component({
  selector: 'app-auth-base-sign-in',
  template: ``,
})
export class BaseSignInComponent implements OnInit {
  protected readonly signingIn = signal(false)
  protected readonly errorMessage = signal('')
  protected readonly api = inject(ApiService)
  protected readonly authService = inject(AuthService)
  protected readonly authSession = inject(AuthSession)
  protected readonly router = inject(Router)
  protected readonly route = inject(ActivatedRoute)
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly signInForm = new FormGroup({
    username: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
    rememberMe: new FormControl<boolean>(false),
  })

  protected readonly USERNAME_STORAGE_KEY = 'SAVED_USERNAME'
  protected readonly returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'

  public async ngOnInit() {
    if (await this.authSession.isLoggedIn()) {
      return this.authService.signOut()
    }

    const savedUsername = this.localStorage.getItem(this.USERNAME_STORAGE_KEY)
    if (savedUsername) {
      this.signInForm.get('username')?.setValue(savedUsername)
    }

    // Leave sign-in page if signed from another tab
    this.authSession.state.onSignedIn$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.signingIn()) {
        return this.router.navigateByUrl(this.returnUrl)
      }
    })
  }

  public async onSignIn() {
    if (this.signInForm.invalid) {
      return this.signInForm.markAsTouched()
    }

    let signedIn = false
    try {
      this.signingIn.set(true)
      const data = this.signInForm.value
      const result = await this.authService.signIn(data.username || '', data.password || '')
      if (result.isSignedIn) {
        const isActive = await this.checkIsUserActive()
        if (isActive) {
          this.errorMessage.set('') // Clear error message on success
          console.log('Sign-in successful!')

          if (data.rememberMe && data.username) {
            this.localStorage.setItem(this.USERNAME_STORAGE_KEY, data.username)
          }
          signedIn = true
          this.router.navigateByUrl(this.returnUrl)
        } else {
          console.log('User is inactive')
          signedIn = false
          setTimeout(() => this.authService.signOut(), 500)
        }
      }
    } catch (e) {
      console.warn(`Unable to sign in: `, e)
    } finally {
      this.signingIn.set(false)
    }

    if (!signedIn) {
      this.errorMessage.set(await this.languageService.translate('sign-in.error-message'))
    }
  }

  protected async checkIsUserActive() {
    try {
      return this.api.myProfile.isActive()
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
