@if (invoice) {
  <header class="tsp-admin-header">
    <section class="heading-group flex">
      <h1 class="text-32 fw-600">
        {{ 'tsp-admin.billing.grid.billingPeriod' | translate }}: {{ billingStartDate | formatDate: 'grid-date' }}
        - {{ billingEndDate | formatDate: 'grid-date' }}
      </h1>
    </section>
    <section class="controls">
      <app-icon-billing class="mr-12" [color]="'var(--color-primary-500)'"></app-icon-billing>
      <div class="controls-spacer"></div>
      <h3 class="mr-12">
        {{ 'tsp-admin.billing.month-to-date-amount' | translate }}:
        ${{ invoice.totalAmount }}
      </h3>
      <p-button
        class="h--40 p--18 text-14 fw-600"
        [severity]="'secondary'"
        [appCheckAccess]="[Resource.Billing, Action.View]"
        [routerLink]="['/billing/history']"
      >
        {{ 'tsp-admin.billing.invoice-history' | translate }}
      </p-button>
    </section>
  </header>
  <div class="table-wrapper" [ngClass]="{ 'flex-grow-1': invoice.items.length === 0 }">
    <app-data-grid
      [columns]="(columns$ | async) ?? []"
      [data]="invoice.items"
      [isLoading]="false"
      [noDataDescription]="'tsp-admin.billing.grid.no-data-description' | translate"
      [noDataIconTemplate]="gridEmptyStateIconTmpl"
      [noDataTitle]="'tsp-admin.billing.grid.no-data-title' | translate"
      [paginationEnabled]="false"
      [sortOrder]="undefined"
      [templates]="{
        tnt,
        basic,
        standard,
        pro
      }"
    ></app-data-grid>
  </div>
}

<ng-template #tnt let-row="row">
  @if (row.plans[0]?.daysCharged) {
    {{ row.plans[0].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #basic let-row="row">
  @if (row.plans[1]?.daysCharged) {
    {{ row.plans[1].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #standard let-row="row">
  @if (row.plans[2]?.daysCharged) {
    {{ row.plans[2].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #pro let-row="row">
  @if (row.plans[3]?.daysCharged) {
    {{ row.plans[3].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #gridEmptyStateIconTmpl>
  <i>
    <app-icon-pay-disable [color]="'var(--color-gray-900)'"></app-icon-pay-disable>
  </i>
</ng-template>
