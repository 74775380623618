import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-new-outlined',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_675_43865)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9013 2H3.83333C2.82052 2 2 2.82052 2 3.83333V13.1667C2 14.1795 2.82052 15 3.83333 15H13.1667C14.1795 15 15 14.1795 15 13.1667V6.09865C14.6963 6.27433 14.359 6.3983 14 6.45852V13.1667C14 13.6272 13.6272 14 13.1667 14H3.83333C3.37281 14 3 13.6272 3 13.1667V3.83333C3 3.37281 3.37281 3 3.83333 3H10.5415C10.6017 2.64104 10.7257 2.30369 10.9013 2ZM4.66667 11.5C4.66667 11.2239 4.89052 11 5.16667 11H11.8333C12.1095 11 12.3333 11.2239 12.3333 11.5C12.3333 11.7761 12.1095 12 11.8333 12H5.16667C4.89052 12 4.66667 11.7761 4.66667 11.5Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 6.32929C11.6476 6.02801 10.972 5.35241 10.6707 4.5H5.16667C4.79848 4.5 4.5 4.79848 4.5 5.16667V8.5C4.5 8.86819 4.79848 9.16667 5.16667 9.16667H11.8333C12.2015 9.16667 12.5 8.86819 12.5 8.5V6.32929Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <circle cx="13.8346" cy="3.16667" r="2.66667" [attr.fill]="color" style="fill-opacity:1;" />
      </g>
      <defs>
        <clipPath id="clip0_675_43865">
          <rect
            width="16"
            height="16"
            fill="white"
            style="fill:white;fill-opacity:1;"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NewOutlinedIconComponent extends BaseIconComponent {}
