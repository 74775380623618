import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { map, Observable } from 'rxjs'
import { MenuItem } from 'primeng/api'
import { Menu } from 'primeng/menu'
import { AuthService, Profile } from '@ti-platform/web/auth'
import { Memoize } from '@ti-platform/web/common'
import {
  LanguageIconComponent,
  PencilIconComponent,
  SignOutIconComponent,
} from '@ti-platform/web/ui-kit/icons/components'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-personal-menu',
  templateUrl: 'personal-menu.component.html',
  styleUrls: ['personal-menu.component.scss'],
})
export class PersonalMenuComponent {
  @Output() languageClicked = new EventEmitter<void>()
  @Output() settingsClicked = new EventEmitter<void>()

  protected readonly router = inject(Router)
  protected readonly profile = inject(Profile)
  protected readonly authService = inject(AuthService)
  protected readonly languageService = inject(LanguageService)

  @ViewChild('menuRef')
  protected menuRef!: Menu

  @Memoize()
  protected get menuItems$(): Observable<MenuItem[]> {
    return this.languageService
      .massTranslate$({
        accountSettings: 'users.profile.edit-profile',
        language: 'fleet.menu.language',
        signOut: 'button.sign-out',
      })
      .pipe(
        map((labels) => {
          return [
            {
              iconType: PencilIconComponent,
              label: labels.accountSettings,
              command: () => this.settingsClicked.emit(),
            },
            {
              iconType: LanguageIconComponent,
              label: labels.language,
              command: () => this.languageClicked.emit(),
            },
            {
              iconType: SignOutIconComponent,
              label: labels.signOut,
              cssClass: 'color-custom alert',
              command: async () => {
                await this.authService.signOut()
                return this.router.navigate(['sign-in'])
              },
            },
          ]
        }),
      )
  }

  public toggle(event: MouseEvent) {
    return this.menuRef.toggle(event)
  }
}
