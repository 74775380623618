<div class="table-wrapper">
  <div class="table-footer no-data">
    <div class="no-data-icon mb-12">
      <ng-container [ngTemplateOutlet]="noDataIconTemplate"></ng-container>
    </div>

    <div class="title mb-4 text-18 fw-600">
      {{ 'tsp-admin.fleets.billing.not-enabled' | translate }}
    </div>
    <div class="description text-14">
      {{ 'tsp-admin.fleets.billing.not-enabled-description' | translate }}
    </div>
  </div>
</div>

<ng-template #noDataIconTemplate>
  <i>
    <app-icon-pay-disable [color]="'var(--color-gray-900)'"></app-icon-pay-disable>
  </i>
</ng-template>
