import { inject, Injectable } from '@angular/core'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Injectable({
  providedIn: 'root',
})
export class ReportBuilder {
  protected readonly languageService = inject(LanguageService)

  async generateCSV(columnMap: { [key: string]: string }, rows: any[]): Promise<void> {
    const { Parser } = await import('@json2csv/plainjs')
    const columns = Object.values(columnMap)
    const mappedRows = rows.map((row) => this.mapRow(row, columnMap))
    const json2csvParser = new Parser({ fields: columns })
    const csv = json2csvParser.parse(mappedRows)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'report.csv')
  }

  async generateExcel(columnMap: { [key: string]: string }, rows: any[]): Promise<void> {
    const XLSX = await import('xlsx')
    const columns = Object.values(columnMap)
    const mappedRows = rows.map((row) => this.mapRow(row, columnMap))
    const worksheetData = [columns, ...mappedRows.map((row) => columns.map((col) => row[col]))]
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, 'report.xlsx')
  }

  async generatePDF(
    columnMap: { [key: string]: string },
    rows: any[],
    title: string,
  ): Promise<void> {
    const doc = new jsPDF({
      orientation: 'landscape',
    })
    const columns = Object.values(columnMap)
    const mappedRows = rows.map((row) => this.mapRow(row, columnMap))

    // Add title
    doc.setFontSize(16)
    if (title) {
      doc.text(title, 14, 20)
    }

    // Generate table data
    const tableData = mappedRows.map((row) => columns.map((col) => row[col]))

    // Prepare templates
    const paginationTemplate = await this.languageService.translate('reports.export.pagination')
    const totalPagesExp = '--totalPages--'

    autoTable(doc, {
      head: [columns],
      body: tableData,
      startY: 25,
      rowPageBreak: 'auto',
      bodyStyles: { valign: 'top' },
      headStyles: { fillColor: 0 },
      didDrawPage: (data) => this.addPagination(doc, data.pageNumber, paginationTemplate),
    })

    // Total page number plugin requires to put page number after rendering
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    doc.save('report.pdf')
  }

  private addPagination(doc: jsPDF, pageNumber: number, paginationTemplate: string) {
    const text = paginationTemplate.replace('--pageNumber--', pageNumber.toString())

    doc.setFontSize(10)
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    doc.text(text, pageWidth / 2, pageHeight - 10, { align: 'center' }) // Center at bottom
  }

  private mapRow(row: any, columnMap: { [key: string]: string }): any {
    const mappedRow: Record<string, any> = {}
    for (const [propertyName, columnTitle] of Object.entries(columnMap)) {
      mappedRow[columnTitle] = row[propertyName]
    }
    return mappedRow
  }
}
