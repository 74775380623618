import { Component, inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Action, FleetForm, FleetView, Resource } from '@ti-platform/contracts'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ApiService } from '@ti-platform/web/api'
import { MessageService } from 'primeng/api'
import { BrowserSessionStorage, selectState } from '@ti-platform/web/common'
import { FleetAccountsModel } from '@ti-platform/tsp-admin/app/fleet/models'
import { Router } from '@angular/router'

@Component({
  selector: 'app-tsp-fleet-accounts-list',
  templateUrl: 'fleet-accounts-list.component.html',
  styleUrls: ['fleet-accounts-list.component.scss'],
})
export class FleetAccountsListComponent {
  protected readonly api = inject(ApiService)
  protected readonly router = inject(Router)

  protected readonly model = inject(FleetAccountsModel)
  protected readonly state = selectState(FleetAccountsModel)

  protected readonly languageService = inject(LanguageService)
  protected readonly messageService = inject(MessageService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)

  protected readonly launchErrorMessage$ = new BehaviorSubject<string>('')
  protected readonly showLaunchForm$ = new BehaviorSubject<boolean>(false)
  protected readonly launchingFleet$ = new BehaviorSubject<boolean>(false)

  protected readonly Action = Action
  protected readonly Resource = Resource

  openFleetLaunchDialog() {
    this.launchErrorMessage$.next('')
    this.showLaunchForm$.next(true)
  }

  protected async openFleetPage(fleet: FleetView) {
    this.model.select(fleet)
    return this.router.navigate(['accounts', fleet.id])
  }

  protected async launchFleet(fleet: FleetForm) {
    this.launchingFleet$.next(true)

    const isLaunched = await this.model.launchFleet(fleet)
    if (isLaunched) {
      this.showLaunchForm$.next(false)
    }

    this.launchingFleet$.next(false)
  }
}
