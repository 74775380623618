import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-fleet',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="#F4EBFF"
        style="fill:#F4EBFF;fill:color(display-p3 0.9569 0.9216 1.0000);fill-opacity:1;"
      />
      <path
        d="M15 24.5H25"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 29H25"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 29V20H15V29"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 17H22"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 16L20 11L11 16V29H29V16Z"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FleetIconComponent extends BaseIconComponent {}
