import { inject, ProviderToken } from '@angular/core'

// Define a generic interface that describes a ViewModel with a state
interface ModelWithState<T> {
  state: T
}

// Modify the selectState function to infer the state type
export const selectState = <VM extends ModelWithState<any>>(
  token: ProviderToken<VM>,
): VM['state'] => {
  const model = inject(token)
  return model.state
}
