import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-image-outlined',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83333 14.5H13.1667C13.903 14.5 14.5 13.903 14.5 13.1667V3.83333C14.5 3.09695 13.903 2.5 13.1667 2.5H3.83333C3.09695 2.5 2.5 3.09695 2.5 3.83333V13.1667C2.5 13.903 3.09695 14.5 3.83333 14.5ZM3.83333 14.5L11.1667 7.16667L14.5 10.5M7.16667 6.16667C7.16667 6.71895 6.71895 7.16667 6.16667 7.16667C5.61438 7.16667 5.16667 6.71895 5.16667 6.16667C5.16667 5.61438 5.61438 5.16667 6.16667 5.16667C6.71895 5.16667 7.16667 5.61438 7.16667 6.16667Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class ImageOutlinedIconComponent extends BaseIconComponent {
  @Input() override width = 16
  @Input() override height = 16
}
