import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-trolleybus',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5015 19.0031H6.49693C5.39147 19.0031 4.49609 18.1077 4.49609 17.0022V5.99766C4.49609 4.8922 5.39147 3.99683 6.49693 3.99683H17.5015C18.607 3.99683 19.5023 4.8922 19.5023 5.99766V17.0022C19.5023 18.1077 18.607 19.0031 17.5015 19.0031Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.49609 16.0019H8.49651"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 16.0019H16.5004"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.49609 13.0007H19.5023"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.23367 19.0031V20.5037C8.23367 21.0559 7.78549 21.5041 7.23326 21.5041H6.23284C5.68061 21.5041 5.23242 21.0559 5.23242 20.5037V18.5469"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7649 18.5469V20.5037C18.7649 21.0559 18.3167 21.5041 17.7645 21.5041H16.7641C16.2119 21.5041 15.7637 21.0559 15.7637 20.5037V19.0031"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0025 12.0002V8.99895C22.0025 8.44672 21.5543 7.99854 21.0021 7.99854H2.99456C2.44233 7.99854 1.99414 8.44672 1.99414 8.99895V12.0002"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99609 3.99686L10.4967 0.995605"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 3.99686L15.5006 0.995605"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TrolleybusIconComponent extends BaseIconComponent {}
