import { AfterViewInit, Component, inject, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MenuItem } from 'primeng/api'
import { Menubar } from 'primeng/menubar'
import { combineLatest, map, Observable, takeUntil } from 'rxjs'
import { Profile } from '@ti-platform/web/auth'
import { AccessControl, injectDestroy$, Memoize } from '@ti-platform/web/common'
import {
  BillingIconComponent,
  DeviceIconComponent,
  GarageIconComponent,
  RecycleBinIconComponent,
  UsersIconComponent,
  WhiteLabelIconComponent,
} from '@ti-platform/web/ui-kit/icons'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { BaseUserSettingsModel } from '@ti-platform/web/user'
import { TspUserSettingsModel } from '../../../../users/models'
import { Action, Resource } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-admin-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  providers: [{ provide: BaseUserSettingsModel, useClass: TspUserSettingsModel }],
})
export class TspAdminMenuComponent implements AfterViewInit {
  protected readonly accessControl = inject(AccessControl)
  protected readonly router = inject(Router)
  protected readonly profile = inject(Profile)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  @ViewChild('menubarRef')
  protected readonly menubar!: Menubar

  @Memoize()
  protected get menuItems$(): Observable<MenuItem[]> {
    return combineLatest([
      this.accessControl.massCheck$([
        [Resource.Fleet, Action.View],
        [Resource.Device, Action.View],
        [Resource.Billing, Action.View],
        [Resource.User, Action.View],
        [Resource.WhiteLabel, Action.View],
      ]),
      this.languageService.massTranslate$({
        fleetAccounts: 'tsp-admin.menu.fleet-accounts',
        devices: 'tsp-admin.menu.devices',
        billing: 'tsp-admin.menu.billing',
        users: 'tsp-admin.menu.users',
        whiteLabel: 'tsp-admin.menu.white-label',
        recyclingBin: 'tsp-admin.menu.recycling-bin',
      }),
    ]).pipe(
      takeUntil(this.destroy$),
      map(
        ([
          [fleetAllowed, deviceAllowed, billingAllowed, userAllowed, whiteLabelAllowed],
          labels,
        ]) => {
          return [
            {
              label: labels.fleetAccounts,
              iconType: GarageIconComponent,
              routerLink: '/accounts',
              styleClass: fleetAllowed ? '' : 'not-allowed',
            },
            {
              label: labels.devices,
              iconType: DeviceIconComponent,
              routerLink: '/devices',
              styleClass: deviceAllowed ? '' : 'not-allowed',
            },
            {
              label: labels.billing,
              iconType: BillingIconComponent,
              routerLink: '/billing',
              styleClass: billingAllowed ? '' : 'not-allowed',
            },
            {
              label: labels.users,
              iconType: UsersIconComponent,
              routerLink: 'users',
              styleClass: userAllowed ? '' : 'not-allowed',
            },
            {
              label: labels.whiteLabel,
              iconType: WhiteLabelIconComponent,
              routerLink: '/white-label',
              styleClass: whiteLabelAllowed ? '' : 'not-allowed',
            },
            {
              label: labels.recyclingBin,
              iconType: RecycleBinIconComponent,
              routerLink: '/recycling-bin',
            },
          ]
        },
      ),
    )
  }

  public ngAfterViewInit() {
    // Disable the focus handler as a hotfix
    this.menubar.onMenuFocus = () => undefined
  }
}
