<div
  class="duration-input flex gap-12"
  [ngClass]="{ outlined: isOutlined, 'seconds-support': isSecondsSupported }"
>
  <input
    #durationInputRef
    pInputText
    [placeholder]="placeholderMask"
    (blur)="onDurationChanges(durationInputRef.value)"
    [ngModel]="_duration"
  />
</div>
