import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-command',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83464 5.83333H3.16797"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.90797 11.1538L3.16797 11.1671"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.90536 8.50033H1.83203"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.24507 8.99527L8.3704 11.1826C8.13774 11.7653 8.7644 12.3179 9.31307 12.0133L14.9397 8.88727C15.2431 8.7186 15.2431 8.2826 14.9397 8.11393L9.31307 4.98793C8.7644 4.68327 8.13707 5.23593 8.3704 5.8186L9.24507 8.00593C9.3724 8.3226 9.3724 8.67727 9.24507 8.99527Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CommandIconComponent extends BaseIconComponent {}
