import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-garage',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22V7.55556L12 2L22 7.55556V22"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 16.6667V14.8304C6 14.2995 6.21077 13.7903 6.586 13.4147L7.33333 12.6667H16.6667L17.414 13.4141C17.7892 13.7893 18 14.2982 18 14.8288V16.6667C18 17.4031 17.403 18.0001 16.6667 18.0001H7.33333C6.59695 18.0001 6 17.4031 6 16.6667Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 16.6664V18.9997C18 19.3679 17.7016 19.6664 17.3334 19.6664H16C15.6319 19.6664 15.3334 19.3679 15.3334 18.9997V17.9997"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.66667 17.9997V18.9997C8.66667 19.3679 8.36819 19.6664 8 19.6664H6.66667C6.29848 19.6664 6 19.3679 6 18.9997V16.6664"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 12.0003L16.6667 12.3336"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.33333 12.3336L6 12.0003"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.33331 12.6667V12.3293L7.89065 10.238C8.08519 9.50797 8.74628 8.99999 9.50178 9H14.5569C15.3284 9 15.999 9.52953 16.178 10.28L16.6666 12.3293V12.6667"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99994 15.3333H9.33327"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6666 15.3333H16"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class GarageIconComponent extends BaseIconComponent {}
