import { NgModule } from '@angular/core'
// PrimeNG
import { AccordionModule } from 'primeng/accordion'
import { SharedModule } from 'primeng/api'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { BadgeModule } from 'primeng/badge'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ButtonModule } from 'primeng/button'
import { ButtonGroupModule } from 'primeng/buttongroup'
import { CalendarModule } from 'primeng/calendar'
import { CardModule } from 'primeng/card'
import { CheckboxModule } from 'primeng/checkbox'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { IconFieldModule } from 'primeng/iconfield'
import { AngleDownIcon } from 'primeng/icons/angledown'
import { AngleRightIcon } from 'primeng/icons/angleright'
import { InputIconModule } from 'primeng/inputicon'
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { KeyFilterModule } from 'primeng/keyfilter'
import { MenuModule } from 'primeng/menu'
import { MenubarModule } from 'primeng/menubar'
import { MessageModule } from 'primeng/message'
import { MessagesModule } from 'primeng/messages'
import { MultiSelectModule } from 'primeng/multiselect'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { PanelModule } from 'primeng/panel'
import { ProgressBarModule } from 'primeng/progressbar'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RippleModule } from 'primeng/ripple'
import { ScrollPanelModule } from 'primeng/scrollpanel'
import { SelectButtonModule } from 'primeng/selectbutton'
import { SkeletonModule } from 'primeng/skeleton'
import { SplitButtonModule } from 'primeng/splitbutton'
import { SplitterModule } from 'primeng/splitter'
import { TableModule } from 'primeng/table'
import { TabViewModule } from 'primeng/tabview'
import { TagModule } from 'primeng/tag'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { ToastModule } from 'primeng/toast'
import { TooltipDirective } from './directives/tooltip.directive'

const MODULES = [
  AutoCompleteModule,
  AccordionModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  IconFieldModule,
  InputIconModule,
  InputTextModule,
  InputMaskModule,
  InputSwitchModule,
  InputTextareaModule,
  InputNumberModule,
  BadgeModule,
  MenuModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  PanelModule,
  PanelModule,
  RippleModule,
  RadioButtonModule,
  SplitButtonModule,
  InputSwitchModule,
  TableModule,
  OverlayPanelModule,
  ScrollPanelModule,
  SelectButtonModule,
  MultiSelectModule,
  SharedModule,
  SkeletonModule,
  SplitterModule,
  ToastModule,
  TagModule,
  TabViewModule,
  TieredMenuModule,
  KeyFilterModule,
  // Additional icons
  AngleDownIcon,
  AngleRightIcon,
  ProgressBarModule,
  ProgressSpinnerModule,
]

const DIRECTIVES = [TooltipDirective]

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...DIRECTIVES],
  declarations: [...DIRECTIVES],
})
export class PrimeNgModule {}
