<p-menu #menu [popup]="true" [model]="model.recipientsInfo.list" [appendTo]="appendTo">
  <ng-template pTemplate="start">
    @if (model.recipientsInfo.number === 0) {
      <div class="menu-item name text-14 fw-500">{{ model.recipientsInfo.title }}</div>
    } @else if (model.recipientsInfo.noRecipientsInfo) {
      <div class="menu-item name text-14 fw-500">
        {{ 'alerts.recipients.no-data' | translate }}
      </div>
    }
  </ng-template>
  <ng-template pTemplate="item" let-item>
    @if (model.recipientsInfo.list?.length) {
      <div class="menu-item avatar-double-description alert-manage-menu-item">
        <app-avatar [photo]="item.photo"></app-avatar>
        <div>
          <div class="text-14 fw-500" [class]="{ name: item.email, full: !item.email }">
            {{ item.name }}
          </div>
          @if (item.email) {
            <div class="description text-14 fw-400">{{ item.email }}</div>
          }
        </div>
      </div>
    }
  </ng-template>
</p-menu>
