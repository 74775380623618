import { inject, Injectable } from '@angular/core'
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs'
import { WhiteLabelSettings } from '@ti-platform/contracts'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { ApiService } from '@ti-platform/web/api'

@Injectable()
export abstract class WhiteLabelSettingsProvider {
  protected readonly api = inject(ApiService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly refresh$ = new BehaviorSubject<void>(undefined)

  public constructor() {
    // Early initialization of data stream
    Promise.resolve().then(() => this.data$.pipe(takeUntil(this.destroy$)).subscribe())
  }

  @Memoize()
  public get data$(): Observable<WhiteLabelSettings> {
    return this.refresh$.pipe(
      switchMap(() => this.getSettings()),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }

  public getData(): Promise<WhiteLabelSettings> {
    return firstValueFrom(this.data$)
  }

  public refresh() {
    this.refresh$.next()
  }

  protected abstract getSettings(): Promise<WhiteLabelSettings>
}
