import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { AutoComplete } from 'primeng/autocomplete'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: 'autocomplete-select.component.html',
  styleUrl: 'autocomplete-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSelectComponent),
      multi: true,
    },
  ],
})
export class AutocompleteSelectComponent<V = unknown> implements ControlValueAccessor {
  protected readonly filterDropdownShown$ = new BehaviorSubject<boolean>(false)

  protected onChange: (value: any) => void = () => undefined
  protected onTouched: () => void = () => undefined
  protected touched = false

  @Input() public className = ''
  @Input() public completeOnFocus = true
  @Input() public delay = 300
  @Input() public disabled = false
  @Input() public forceSelection = true
  @Input() public isCheckmarkVisible? = false
  @ViewChild('itemSelect')
  public itemSelect: AutoComplete | null = null
  @Input() public optionLabel = 'title'
  @Input() public optionValue: string | undefined = undefined
  @Input() public placeholder = ''
  @Output() public selectHide = new EventEmitter<void>()
  @Output() public selectShown = new EventEmitter<void>()
  @Input() public suggestions: any[] = []
  @Input() public value: V | null = null
  @Output() public valueChange = new EventEmitter<V | null>()
  @Output() public completeMethod = new EventEmitter<any>()

  protected get filterBtnStyleClass(): string {
    return this.getFilterBtnClass(this.filterDropdownShown$.getValue(), this.disabled)
  }

  public registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange
  }

  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }

  public setFilterDropdownShown(value: boolean) {
    this.filterDropdownShown$.next(value)
  }

  public writeValue(value: V | null) {
    this.value = value
  }

  protected getFilterBtnClass(dropdownStatus: boolean, disableModeStatus: boolean) {
    let filterButtonClass = dropdownStatus ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
    filterButtonClass = disableModeStatus
      ? `${filterButtonClass} disabled`
      : `${filterButtonClass} clickable`
    return filterButtonClass
  }

  protected markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  protected onClickDropdown() {
    if (!this.disabled && this.itemSelect?.value?.value) {
      this.itemSelect.show(!this.filterDropdownShown$.getValue())
    } else if (this.itemSelect && !this.filterDropdownShown$.getValue()) {
      this.itemSelect.onInputFocus({ target: { value: '' } })
    }
  }

  protected onSelectHide() {
    if (this.filterDropdownShown$.getValue()) {
      this.filterDropdownShown$.next(false)
    }
    this.selectHide.emit()
  }

  protected onSelectShow() {
    if (!this.filterDropdownShown$.getValue()) {
      this.filterDropdownShown$.next(true)
    }
    this.selectShown.emit()
  }

  protected selectOption(item: any) {
    this.markAsTouched()
    const value = this.optionValue ? item.value?.[this.optionValue] : item.value
    this.writeValue(value)
    this.valueChange.emit(value)
    this.onChange(value)
  }

  protected unselectOption() {
    this.markAsTouched()
    this.writeValue(null)
    this.valueChange.emit(null)
    this.onChange(null)
  }

  protected clearOption() {
    this.writeValue(null)
    this.valueChange.emit(null)
    this.onChange(null)
  }

  protected onCompleteMethod(event: any) {
    this.completeMethod.emit(event)
  }
}
