<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (state.gridData$ | async)?.length === 0 }">
  <app-data-grid
    (loadMoreClicked)="onLoadMoreClicked()"
    (sortUpdated)="onSortUpdated($event)"
    [columns]="(state.columns$ | async) ?? []"
    [data]="(state.gridData$ | async) ?? []"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [noDataIconTemplate]="
      model.isSearchOrFilterApplied ? gridEmptySearchIconTmpl : gridEmptyStateIconTmpl
    "
    [noDataTitle]="
      (model.isSearchOrFilterApplied ? 'search.no-data-title' : 'users.grid.no-data-title')
        | translate
    "
    [noDataDescription]="
      (model.isSearchOrFilterApplied ? 'search.no-data-description' : '') | translate
    "
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [templates]="{ user, lastActivity, loginAs: loginAsTmpl }"
  ></app-data-grid>
</div>

<ng-template #user let-data="row" let-profile>
  <div class="user-section flex">
    @if (data.lastActivity.type === 'invite') {
      <app-avatar class="secondary" [iconTemplate]="avatarIconTmpl"></app-avatar>
      <ng-template #avatarIconTmpl>
        <app-icon-clock color="var(--icon-color)"></app-icon-clock>
      </ng-template>
    } @else {
      <app-avatar [photo]="profile.photo"></app-avatar>
    }
    <div class="info-wrapper">
      <div class="name text-14">{{ profile.name }}</div>
      <div class="email text-14">{{ profile.email }}</div>
    </div>
  </div>
</ng-template>

<ng-template #lastActivity let-data="row" let-activity>
  <div class="activity-section flex">
    <div class="activity text-14">
      @switch (activity.type) {
        @case ('invite') {
          <p-tag
            class="warning clickable"
            [value]="
              ('users.grid.invited-msg' | translate) +
              ' ' +
              (activity.date | formatDate: 'grid-date')
            "
            [appCheckAccess]="[Resource.User, Action.Create]"
            [pTooltip]="'users.grid.resend-invite-msg' | translate"
            [tooltipPosition]="'bottom'"
            (click)="model.resendInvitation(data)"
          />
        }
        @default {
          {{ activity.date | formatDate: 'grid-date' }}
        }
      }
    </div>
  </div>
</ng-template>

<ng-template #loginAsTmpl let-data="row">
  <div class="activity-section flex">
    <p-button
      class="h--40 p--18 text-14 fw-600"
      [severity]="'secondary'"
      [appCheckAccess]="[Resource.Fleet, Action.LoginAs]"
      (onClick)="loginAs.emit(data.user)"
    >
      {{ 'users.grid.login-as-btn' | translate }}
    </p-button>
  </div>
</ng-template>

<ng-template #gridEmptySearchIconTmpl>
  <app-icon-no-search></app-icon-no-search>
</ng-template>

<ng-template #gridEmptyStateIconTmpl>
  <app-icon-file-search></app-icon-file-search>
</ng-template>
