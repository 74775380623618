import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { BehaviorSubject, takeUntil, throttleTime } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'
import { SidebarService } from '@ti-platform/web/ui-kit/layout'

@Component({
  selector: 'app-split-view-panel',
  templateUrl: 'split-view-panel.component.html',
  styleUrls: ['split-view-panel.component.scss'],
})
export class SplitViewPanelComponent implements OnInit {
  @Output() resized = new EventEmitter<void>()
  @Output() splitViewExited = new EventEmitter<void>()

  @Input() counterTmpl!: TemplateRef<unknown>
  @Input() headerTmpl!: TemplateRef<unknown>
  @Input() topTmpl!: TemplateRef<unknown>
  @Input() bottomTmpl!: TemplateRef<unknown>
  @Input() switchViewIconTmpl?: TemplateRef<unknown>

  @Input() minPanelHeight = 80
  @Input() defaultPanelHeight = 300
  @Input() exitSplitViewThreshold = 125

  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly viewContainerRef = inject(ViewContainerRef)
  protected readonly sidebarService = inject(SidebarService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly isSplitView$ = new BehaviorSubject(false)
  protected readonly panelHeight$ = new BehaviorSubject(this.minPanelHeight)

  public ngOnInit() {
    this.initMobilePanel()
  }

  public collapse() {
    if (this.panelHeight$.value > this.minPanelHeight) {
      this.panelHeight$.next(this.minPanelHeight)
      this.changeDetector.detectChanges()
    }
  }

  public ensurePanelIsOpened() {
    if (this.panelHeight$.value <= this.defaultPanelHeight) {
      this.isSplitView$.next(true)
      this.panelHeight$.next(this.defaultPanelHeight)
      this.changeDetector.detectChanges()
    }
  }

  protected initMobilePanel() {
    // Update map height with throttle for better performance
    this.panelHeight$
      .pipe(throttleTime(8, undefined, { leading: true, trailing: true }), takeUntil(this.destroy$))
      .subscribe((panelHeight) => {
        const element = this.viewContainerRef.element.nativeElement
        if (element) {
          const mapHeight = document.body.clientHeight - panelHeight + 20
          element.style.setProperty('--top-panel-height', `${mapHeight}px`)
          element.style.setProperty('--bottom-panel-height', `${panelHeight}px`)
          setTimeout(() => this.resized.emit(), 4)
        }
      })
  }

  protected startMobilePanelResize(e: Event) {
    e.stopPropagation()

    const stopListener = () => {
      document.body.removeEventListener('mouseup', stopListener)
      document.body.removeEventListener('mousemove', moveListener)
      document.body.removeEventListener('touchend', stopListener)
      document.body.removeEventListener('touchmove', moveListener)
    }
    const moveListener = (event: MouseEvent | TouchEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const position =
        event instanceof MouseEvent
          ? event.clientY
          : Math.min(...Array.from(event.touches).map((t) => t.clientY))
      if (position <= this.exitSplitViewThreshold) {
        stopListener()
        this.isSplitView$.next(false)
        this.panelHeight$.next(this.minPanelHeight)
        this.splitViewExited.emit()
      } else {
        this.panelHeight$.next(
          Math.max(document.body.offsetHeight - position + 25, this.minPanelHeight),
        )
      }
    }

    document.body.addEventListener('mouseup', stopListener)
    document.body.addEventListener('mousemove', moveListener)
    document.body.addEventListener('touchend', stopListener)
    document.body.addEventListener('touchmove', moveListener, { passive: false })
  }
}
