import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-minus-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" [attr.fill]="color" style="fill-opacity:1;" />
      <path
        d="M4.5 8H11.5"
        stroke="#F2F4F7"
        style="stroke:#F2F4F7;stroke:color(display-p3 0.9490 0.9569 0.9686);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class MinusSolidIconComponent extends BaseIconComponent {
  @Input() override width = 24
  @Input() override height = 24
}
