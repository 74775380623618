import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-rpm',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3333 40.3334C32.4586 40.3334 40.6667 32.1253 40.6667 22.0001C40.6667 11.8749 32.4586 3.66675 22.3333 3.66675C12.2081 3.66675 4 11.8749 4 22.0001C4 32.1253 12.2081 40.3334 22.3333 40.3334Z"
        [attr.fill]="color"
        [attr.stroke]="color"
        style="fill-opacity:1;stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.3328 4.60571V8.71952M34.6743 21.061H38.7881M5.8776 21.061H9.99141M33.9805 9.54228L31.1231 12.3997"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1883 12.5479L22.4383 20.7979M20.6048 20.7979C20.6048 21.8104 21.4256 22.6312 22.4382 22.6312C23.4507 22.6312 24.2715 21.8104 24.2715 20.7979C24.2715 19.7853 23.4507 18.9645 22.4382 18.9645C21.4256 18.9645 20.6048 19.7853 20.6048 20.7979Z"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class RpmIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
