<form [formGroup]="fleetEditForm" class="p-fluid medium scrollbar-gray">
  <section class="inputs-section">
    <div class="field-double mb-16">
      <div class="col">
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.fleets.form.input-label.company-name' | translate }}
          <span class="required">*</span>
        </div>
        <input
          class="h--40"
          formControlName="name"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.enter-name' | translate }}"
          type="text"
        />
      </div>

      <div class="col">
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.fleets.form.input-label.contact-name' | translate }}
          <span class="required">*</span>
        </div>
        <input
          class="h--40"
          formControlName="contactName"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.enter-name' | translate }}"
          type="text"
        />
      </div>
    </div>

    <div class="field-double mb-16">
      <div class="col">
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.fleets.form.input-label.email' | translate }}
          <span class="required">*</span>
        </div>
        <input
          class="h--40"
          formControlName="email"
          pInputText
          placeholder="email@telematics.com"
          type="text"
        />
      </div>

      <div class="col">
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.fleets.form.input-label.phone-number' | translate }}
        </div>
        <p-inputMask
          [unmask]="true"
          class="h--40"
          formControlName="phone"
          mask="(999) 999-9999"
          placeholder="(999) 999-9999"
        />
      </div>
    </div>

    <div class="label mb-8 text-14 fw-500">
      {{ 'tsp-admin.fleets.form.input-label.country' | translate }}
      <span class="required">*</span>
    </div>
    <app-autocomplete-select
      (completeMethod)="onCountrySearch($event)"
      [completeOnFocus]="true"
      [forceSelection]="true"
      [suggestions]="
        (countryOptions$ | async) ?? (countyOptionsProvider.allCountryOptions$ | async) ?? []
      "
      formControlName="country"
      optionLabel="label"
      optionValue="value"
      className="mb-16"
    ></app-autocomplete-select>

    <div class="label mb-8 text-14 fw-500">
      {{ 'tsp-admin.fleets.form.input-label.address' | translate }}
    </div>

    <div class="field-double mb-12">
      <div class="col">
        <input
          class="h--40"
          formControlName="address"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.street-address' | translate }}"
          type="text"
        />
      </div>

      <div class="col">
        <input
          class="h--40"
          formControlName="city"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.city' | translate }}"
          type="text"
        />
      </div>
    </div>

    <div class="field-double mb-16">
      <div class="col">
        <input
          class="h--40"
          formControlName="region"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.state-region' | translate }}"
          type="text"
        />
      </div>

      <div class="col">
        <input
          class="h--40"
          formControlName="zipCode"
          pInputText
          placeholder="{{ 'tsp-admin.fleets.form.input-placeholder.postal-code-zip' | translate }}"
          type="text"
        />
      </div>
    </div>

    <div class="label mb-8 text-14 fw-500">
      {{ 'tsp-admin.fleets.form.input-label.units-of-measure' | translate }}
    </div>

    <div class="field-double mb-12">
      <div class="col flex">
        <div class="description text-14">
          {{ 'tsp-admin.fleets.form.input-placeholder.distance' | translate }}:
        </div>
        <p-dropdown
          [options]="(formatOptionsProvider.distance$ | async) ?? []"
          appendTo="body"
          class="h--40 w100"
          formControlName="distance"
        >
          <ng-template let-param pTemplate="item">
            <div class="option-item text-14">
              <div>{{ param.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col flex">
        <div class="description text-14">
          {{ 'tsp-admin.fleets.form.input-placeholder.volume' | translate }}:
        </div>
        <p-dropdown
          [options]="(formatOptionsProvider.volume$ | async) ?? []"
          appendTo="body"
          class="h--40 w100"
          formControlName="volume"
        >
          <ng-template let-param pTemplate="item">
            <div class="option-item text-14">
              <div>{{ param.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="field-double mb-16">
      <div class="col flex">
        <div class="description text-14">
          {{ 'tsp-admin.fleets.form.input-placeholder.consumption' | translate }}:
        </div>
        <p-dropdown
          [options]="(formatOptionsProvider.consumption$ | async) ?? []"
          appendTo="body"
          class="h--40 w100"
          formControlName="consumption"
        >
          <ng-template let-param pTemplate="item">
            <div class="option-item text-14">
              <div>{{ param.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="col flex">
        <div class="description text-14">
          {{ 'tsp-admin.fleets.form.input-placeholder.temperature' | translate }}:
        </div>
        <p-dropdown
          [options]="(formatOptionsProvider.temperature$ | async) ?? []"
          appendTo="body"
          class="h--40 w100"
          formControlName="temperature"
        >
          <ng-template let-param pTemplate="item">
            <div class="option-item text-14">
              <div>{{ param.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="label mb-8 text-14 fw-500">
      {{ 'tsp-admin.fleets.form.input-label.pricing-plan' | translate }}
    </div>
    <p-dropdown
      [options]="(pricingPlanOptions$ | async) || []"
      appendTo="body"
      class="h--40"
      formControlName="pricingPlanId"
    >
      <ng-template let-param pTemplate="item">
        <div class="option-item text-14">
          <div>{{ param.label }}</div>
        </div>
      </ng-template>
    </p-dropdown>

    <div class="horizontal-spacer--16"></div>

    <div class="demo flex text-14">
      <p-checkbox [binary]="true" formControlName="isDemo" inputId="binary"></p-checkbox>
      {{ 'tsp-admin.fleets.form.input-placeholder.demo' | translate }}
    </div>

    <section #errorBlock [class.hidden]="!errorMessage" class="errors-section">
      <div class="errors-section-icon">
        <i class="pi pi-times-circle"></i>
      </div>
      <div class="text-14">
        {{ errorMessage | translate: { fleetName: fleet.name } }}
      </div>
    </section>
  </section>
</form>

<div class="footer border-top flex">
  <p-button
    (click)="onCancel()"
    [disabled]="!!(disableMode$ | async)"
    [severity]="'secondary'"
    class="cancel text-14 fw-600 w50 p--8 h--40"
    type="submit"
  >
    {{ 'button.cancel' | translate }}
  </p-button>

  <p-button
    (click)="onSubmit()"
    [disabled]="!modelWasChanged || fleetEditForm.invalid || processing"
    [loading]="isLoading"
    class="submit text-14 fw-600 w50 h--40"
    type="submit"
  >
    @switch (newFleetMode) {
      @case (true) {
        <app-icon-launch
          class="icon"
          [height]="20"
          [width]="20"
          [color]="'var(--color-gray-0)'"
        ></app-icon-launch>
        {{ 'button.launch' | translate }}
      }
      @case (false) {
        <app-icon-check class="icon" [width]="20" [height]="20"></app-icon-check>
        {{ 'button.save' | translate }}
      }
    }
  </p-button>
</div>
