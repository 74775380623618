import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-password-input',
  templateUrl: 'password-input.component.html',
  styleUrl: 'password-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor {
  protected onChange: (value: any) => void = () => undefined
  protected onTouched: () => void = () => undefined

  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly isPasswordRevealed$ = new BehaviorSubject<boolean>(false)

  protected touched = false
  protected disabled = false

  @Input() height?: number
  @Input() placeholder = ''
  @Input() value = ''

  @ViewChild('input') input!: ElementRef

  getHeight() {
    return this.height + 'px'
  }

  onPasswordPaste(event: ClipboardEvent) {
    event.preventDefault()
    // prevent potential issues on iOS
    const pastedText = event.clipboardData?.getData('text') || ''
    this.writeValue(pastedText.trim())
  }

  public togglePasswordReveal() {
    if (!this.disabled) {
      this.isPasswordRevealed$.next(!this.isPasswordRevealed$.value)
    }
  }

  public writeValue(value: any) {
    this.onChange(value)
    this.value = value
  }

  public registerOnChange(onChange: () => void) {
    this.onChange = onChange
  }

  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }

  protected onKeyUp() {
    this.markAsTouched()
    this.writeValue(this.input.nativeElement.value)
  }

  protected markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }
}
