import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MessageService } from 'primeng/api'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-change-tooltip-on-click-wrapper',
  templateUrl: 'change-tooltip-on-click-wrapper.component.html',
  styleUrl: 'change-tooltip-on-click-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeTooltipOnClickWrapperComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) tooltip = ''
  @Input({ required: true }) tooltipOnClick = ''
  @Input() tooltipPosition: 'left' | 'top' | 'bottom' | 'right' = 'top'
  @Input() clickAction: undefined | null | (() => Promise<void>) = null

  @ViewChild('wrapper')
  protected readonly wrapper!: ElementRef<HTMLElement>

  protected readonly messageService = inject(MessageService)
  protected readonly languageService = inject(LanguageService)

  protected readonly isClicked$ = new BehaviorSubject<boolean>(false)
  protected readonly tooltipForceActivate$ = new BehaviorSubject<boolean>(false)
  protected readonly tooltipForceDeactivate$ = new BehaviorSubject<boolean>(false)
  protected mouseLeaveListener: null | any = null
  protected timoutId: any = null

  protected async onClick() {
    this.clearTimeout()
    if (this.clickAction) {
      await this.clickAction()
    }
    this.isClicked$.next(true)
    this.tooltipForceActivate$.next(true)
    setTimeout(() => {
      this.tooltipForceActivate$.next(false)
    }, 50)
    this.timoutId = setTimeout(() => {
      this.tooltipForceDeactivate$.next(true)
      setTimeout(() => {
        this.tooltipForceDeactivate$.next(false)
      }, 50)
    }, 2000)
  }

  protected async onMouseLeave() {
    this.isClicked$.next(false)
    this.tooltipForceActivate$.next(false)
    this.tooltipForceDeactivate$.next(false)
    this.clearTimeout()
  }

  protected clearTimeout() {
    if (this.timoutId) {
      clearTimeout(this.timoutId)
    }
  }

  ngAfterViewInit(): void {
    if (this.wrapper) {
      this.mouseLeaveListener = this.onMouseLeave.bind(this)
      this.wrapper.nativeElement.addEventListener('mouseleave', this.mouseLeaveListener)
    }
  }

  ngOnDestroy(): void {
    if (this.wrapper) {
      this.wrapper.nativeElement.removeEventListener('mouseleave', this.mouseLeaveListener)
    }
  }
}
