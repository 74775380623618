<div
  class="full-screen-container background-container"
  [style.background-image]="'url(./assets/images/login-page-background.png)'"
>
  <div class="sign-container">
    @switch (model.newPasswordSaved()) {
      @case (false) {
        <p-panel class="panel-primary header-32 w-608">
          <ng-template pTemplate="header">
            <header>
              <div class="caption mb-8 text-24 fw-600">
                {{ 'auth.' + labelsPrefix + '.title' | translate }}
              </div>
              <div class="subtitle text-14">
                {{ 'auth.' + labelsPrefix + '.subtitle' | translate }}
              </div>
            </header>
          </ng-template>

          <form [formGroup]="model.form" class="p-fluid">
            <section class="inputs-section">
              @if (model.mode() === 'change') {
                <div class="p-field mb-12">
                  <app-password-input
                    [height]="40"
                    formControlName="current"
                    [placeholder]="'auth.' + labelsPrefix + '.curr-placeholder' | translate"
                  >
                  </app-password-input>
                </div>
              }
              <div class="p-field mb-12">
                <app-password-input
                  [height]="40"
                  formControlName="password"
                  [placeholder]="'auth.' + labelsPrefix + '.pass-placeholder' | translate"
                >
                </app-password-input>
              </div>
              <div class="field">
                <app-password-input
                  [height]="40"
                  formControlName="repeatPassword"
                  [placeholder]="'auth.' + labelsPrefix + '.pass-repeat-placeholder' | translate"
                >
                </app-password-input>
              </div>

              <div class="errors-box">
                @if (model.form.touched && model.form.controls.current?.hasError('required')) {
                  <p class="error-message">
                    {{ 'auth.error.current-password-is-required' | translate }}
                  </p>
                }

                @if (model.form.touched && model.form.controls.password.invalid) {
                  <ul>
                    <p class="error-message">
                      {{ 'auth.label.password-requirements' | translate }}
                    </p>
                    @if (
                      model.form.controls.password.hasError('required') ||
                      model.form.controls.password.hasError('minlength')
                    ) {
                      <li class="error-message">
                        {{ 'auth.error.password-is-too-short' | translate }}
                      </li>
                    }
                    @if (model.form.controls.password.hasError('password-must-contain-numeric')) {
                      <li class="error-message">
                        {{ 'auth.error.password-must-contain-numeric' | translate }}
                      </li>
                    }
                    @if (model.form.controls.password.hasError('password-must-contain-uppercase')) {
                      <li class="error-message">
                        {{ 'auth.error.password-must-contain-uppercase' | translate }}
                      </li>
                    }
                    @if (model.form.controls.password.hasError('new-password-equal-previous')) {
                      <li class="error-message">
                        {{ 'auth.error.new-password-equal-previous' | translate }}
                      </li>
                    }
                    @if (model.form.controls.password.hasError('passwords-are-not-equal')) {
                      <li class="error-message">
                        {{ 'auth.error.passwords-are-not-equal' | translate }}
                      </li>
                    }
                  </ul>
                }

                @if (model.apiErrorMessage(); as errorMessage) {
                  <p class="error-message">{{ errorMessage }}</p>
                }
              </div>
            </section>
          </form>

          <div class="footer">
            <p-button
              class="h--40 w100 text-14 fw-600"
              [disabled]="model.isLoading()"
              (click)="model.submitForm()"
            >
              {{ 'button.save' | translate }}
            </p-button>
          </div>
        </p-panel>
      }

      @case (true) {
        <p-panel class="panel-primary header-32 created w-608">
          <ng-template pTemplate="header">
            <header>
              <div class="icon-check-container">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6666 8.66675L11.9999 23.3334L5.33325 16.6667"
                    stroke="white"
                    style="stroke: white; stroke-opacity: 1"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </header>
          </ng-template>

          <div class="caption mb-6 text-24 fw-600">
            {{ 'auth.' + labelsPrefix + '-success.title' | translate }}
          </div>
          <div class="subtitle text-14">
            {{ 'auth.' + labelsPrefix + '-success.subtitle' | translate }}
          </div>

          <div class="footer">
            <p-button class="h--40 w100 text-14 fw-600" (click)="complete()">
              {{ 'auth.' + labelsPrefix + '-success.button' | translate }}
            </p-button>
          </div>
        </p-panel>
      }
    }
  </div>

  <app-lang-toggle class="lang-toggle"></app-lang-toggle>
</div>
