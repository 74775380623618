export const METERS_IN_KILOMETER = 1000
export const FEET_IN_MILE = 5280
export const FEET_IN_METER = 3.28084

export const kmToMil = (value: number) => value * 0.621371
export const milToKm = (value: number) => value / 0.621371
export const metersToFeet = (meters: number) => meters * FEET_IN_METER
export const feetToMeters = (feet: number) => feet / FEET_IN_METER
export const l100kmToMpg = (value: number) => 235.214583 / value
export const mpgToL100km = l100kmToMpg
export const l100kmToKmGal = (value: number) => (100 * 3.785411784) / value
export const kmGalTol100km = l100kmToKmGal
export const celsiusToFahrenheit = (value: number) => 32 + (value * 9) / 5
export const fahrenheitToCelsius = (value: number) => (value - 32) * (5 / 9)
export const atmToPsi = (value: number) => value * 14.696
export const kmhToMph = (value: number) => value / 1.60934
export const mphToKmh = (value: number) => value * 1.60934
export const literToGal = (value: number) => value * 0.264172
export const galToLiter = (value: number) => value / 0.264172
