import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-map-stop-marker',
  templateUrl: 'stop-marker.component.html',
})
export class StopMarkerComponent implements AfterViewInit {
  @Output() afterViewInit = new EventEmitter()

  public ngAfterViewInit() {
    this.afterViewInit.emit()
  }
}
