import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CommandFormData, TspCommandListData } from '@ti-platform/contracts'

@Component({
  selector: 'app-add-new-command',
  templateUrl: 'command-add.component.html',
  styleUrl: 'command-add.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandAddComponent {
  @Input() opened = false
  @Input() command: Partial<TspCommandListData> = {}

  @Input() processing = false
  @Input() errorMessage = ''

  @Output() submitEvent = new EventEmitter<CommandFormData>()
  @Output() cancelEvent = new EventEmitter<void>()

  public onSubmit(data: CommandFormData) {
    this.submitEvent.emit(data)
  }

  public onCancel() {
    this.cancelEvent.emit()
  }
}
