import {
  CommandExecutionResult,
  GetDevicesMessagesData,
  Vehicle,
  VehicleCommandData,
  VehiclesSummarizedMediaDataForAlerts,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class VehiclesRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/vehicle'
  }

  public list(loadPrimaryDevice = false) {
    return this.get<Vehicle[]>()
      .withParams({ loadPrimaryDevice: `${loadPrimaryDevice}` })
      .endpoint('list')
  }

  public listForAlerts() {
    return this.get<Vehicle[]>().endpoint('list-for-alerts')
  }

  public summarizedMediaDataForAlerts() {
    return this.get<VehiclesSummarizedMediaDataForAlerts>().endpoint(
      'summarized-media-data-for-alerts',
    )
  }

  public timeline(
    deviceId: string | number,
    options: { limit: number; timestampTo: number; timestampFrom?: number },
  ) {
    return this.get<TimelineItem[]>()
      .withParams(
        stripEmptyKeys({
          deviceId: deviceId,
          limit: options.limit,
          timestampTo: options.timestampTo,
          timestampFrom: options.timestampFrom,
        }),
      )
      .endpoint(`timeline`)
  }

  public tripHistory(deviceId: string | number, options: { limit: number; timestampTo: number }) {
    return this.get<TripHistoryItem[]>()
      .withParams(
        stripEmptyKeys({
          deviceId: this.formatDeviceId(deviceId),
          limit: `${options.limit}`,
          timestampTo: `${options.timestampTo}`,
        }),
      )
      .endpoint(`trip-history`)
  }

  public currentStatus(deviceId: string | number) {
    return this.get<CurrentStatusResponse>()
      .withParams({ deviceId: this.formatDeviceId(deviceId) })
      .endpoint(`current-status`)
  }

  public totalEngineHours(deviceId: string | number) {
    return this.get<EngineHoursResponse>()
      .withParams({ deviceId: this.formatDeviceId(deviceId) })
      .endpoint(`total-engine-hours`)
  }

  public totalMileage(deviceId: string | number) {
    return this.get<MileageResponse>()
      .withParams({ deviceId: this.formatDeviceId(deviceId) })
      .endpoint(`total-mileage`)
  }

  public massCurrentStatus(deviceIds: Array<string | number>) {
    return this.get<MassCurrentStatusResponse>()
      .withParams({ deviceIds: deviceIds.map((id) => this.formatDeviceId(id)) })
      .endpoint(`mass-current-status`)
  }

  protected formatDeviceId(deviceId: string | number): string {
    return typeof deviceId === 'string' ? deviceId : `${deviceId}`
  }

  public messages(vehicleId: string, params: GetDevicesMessagesData) {
    const data = params ? stripEmptyKeys(params) : {}
    Object.keys(data)?.forEach((key) => {
      if (key === 'fields') {
        data[key] = data[key].join(',')
      } else if (key === 'filter') {
        data[key] = `${data[key]}`
      }
    })
    return this.post<Record<string, any>[]>().withData(data).endpoint(`${vehicleId}/messages`)
  }

  public update(vehicleId: string, data: Partial<Vehicle>) {
    return this.post().withData(data).endpoint(`update/${vehicleId}`)
  }

  public setOdometerValue(vehicleId: string, value: number) {
    return this.post()
      .withData({ currentMileage: value })
      .endpoint(`${vehicleId}/set-odometer-value`)
  }

  public vehicleCommands(vehicleId: string) {
    return this.get<VehicleCommandData[]>().endpoint(`${vehicleId}/commands`)
  }

  public sendVehicleCommand(vehicleId: string, commandId: string) {
    return this.post<CommandExecutionResult>()
      .withData({})
      .endpoint(`${vehicleId}/send-direct-command/${commandId}`)
  }
}

export interface EngineHoursResponse {
  total_duration: number
  total_engine_hours: number
  timestamp: number
}

export interface MileageResponse {
  total_mileage: number
  timestamp: number
}

export interface CurrentStatusResponse {
  status: 'trip' | 'idling' | 'stop'
  timestamp_from: number
}

export interface MassCurrentStatusResponse {
  [key: number]: CurrentStatusResponse
}

export interface TripHistoryItem {
  active: boolean
  avg_speed: number
  begin: number
  device_id: number
  device_ti_id: number
  driver: unknown
  drivers_set: boolean
  duration: number
  end: number
  id: number
  lat_end: number
  lat_start: number
  lon_end: number
  lon_start: number
  max_speed: number
  mileage: number
  name: string
  route: string
  timestamp: number
  vehicle: unknown
  vehicles_set: boolean
  wialon_address_end: string
  wialon_address_start: string
}

export interface TimelineItem {
  active: boolean
  avg_speed: number
  begin: number
  device_id: number
  device_ti_id: number
  driver: unknown
  drivers_set: boolean
  duration: number
  end: number
  id: number
  lat_end: number
  lat_start: number
  lon_end: number
  lon_start: number
  max_speed: number
  mileage: number
  name: 'trip' | 'stop'
  route: string
  timestamp: number
  vehicle: unknown
  vehicles_set: boolean
  wialon_address_end: string
  wialon_address_start: string
}
