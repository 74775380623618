import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-device-remove',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1672 7.83268L13.9381 4.38724C13.8801 3.69562 13.3025 3.16406 12.6089 3.16406H4.39217C3.69855 3.16406 3.12097 3.69562 3.06295 4.38724L2.49805 11.1674"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.83382 9.83203L3.83194 9.83268C3.09496 9.83268 2.49805 10.4296 2.49805 11.1666V13.1674C2.49805 13.9044 3.09496 14.5013 3.83194 14.5013H8.50049"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.50114 12.168H5.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0536 11.2801L11.2824 15.0514M15.0536 15.0514L11.2824 11.2801"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DeviceRemoveIconComponent extends BaseIconComponent {}
