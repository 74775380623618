import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-drivers',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 18C3 15.791 4.791 14 7 14H11C13.209 14 15 15.791 15 18"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 4.65088C17.381 4.65088 18.5 5.76988 18.5 7.15088C18.5 8.53188 17.381 9.65088 16 9.65088"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4045 4.99613C12.7327 6.3243 12.7327 8.4777 11.4045 9.80587C10.0763 11.134 7.92293 11.134 6.59476 9.80587C5.26659 8.4777 5.26659 6.3243 6.59476 4.99613C7.92293 3.66796 10.0763 3.66796 11.4045 4.99613"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 13C19.209 13 21 14.791 21 17"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DriversIconComponent extends BaseIconComponent {}
