@if (invoice) {
  <header class="tsp-admin-header">
    <section class="heading-group flex">
      <p-button
        (click)="goBack()"
        class="button-back mr-12 h--48 flex"
        icon="pi"
        [severity]="'secondary'"
      >
        <app-icon-arrow [height]="24" [width]="24" direction="left"></app-icon-arrow>
      </p-button>
      <h1 class="text-32 fw-600">
        {{ 'tsp-admin.billing.grid.billingPeriod' | translate }}: {{ invoiceDate | formatDate: 'month-year' }}
      </h1>
    </section>
    <section class="controls">
      <app-icon-billing class="mr-12" [color]="'var(--color-primary-500)'"></app-icon-billing>
      <div class="controls-spacer"></div>
      <h3>
        {{ 'tsp-admin.billing.grid.amount' | translate }}:
        ${{ invoice.totalAmount }}
      </h3>
    </section>
  </header>
  <div class="table-wrapper" [ngClass]="{ 'flex-grow-1': invoice.items.length === 0 }">
    <app-data-grid
      [columns]="(columns$ | async) ?? []"
      [data]="invoice.items"
      [isLoading]="false"
      [noDataDescription]="'tsp-admin.billing.grid.no-data-description' | translate"
      [noDataIconTemplate]="gridEmptyStateIconTmpl"
      [noDataTitle]="'tsp-admin.billing.grid.no-data-title' | translate"
      [paginationEnabled]="false"
      [sortOrder]="undefined"
      [templates]="{
        tnt,
        basic,
        standard,
        pro
      }"
    ></app-data-grid>
  </div>
}

<ng-template #tnt let-row="row">
  @if (row.plans[0]?.daysCharged) {
    {{ row.plans[0].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #basic let-row="row">
  @if (row.plans[1]?.daysCharged) {
    {{ row.plans[1].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #standard let-row="row">
  @if (row.plans[2]?.daysCharged) {
    {{ row.plans[2].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #pro let-row="row">
  @if (row.plans[3]?.daysCharged) {
    {{ row.plans[3].daysCharged }}
  } @else {
    ---
  }
</ng-template>

<ng-template #gridEmptyStateIconTmpl>
  <i>
    <app-icon-pay-disable [color]="'var(--color-gray-900)'"></app-icon-pay-disable>
  </i>
</ng-template>
