import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapseExpand', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0,
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      transition('collapsed => expanded', [animate('1000ms ease-out')]),
      transition('expanded => collapsed', [animate('1000ms ease-in')]),
    ]),
  ],
})
export class AccordionComponent<T> {
  @Input() title?: string
  @Input() count?: number

  @Output() clearButtonPress = new EventEmitter<void>()

  public isCollapsed = false

  protected toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed
  }
}
