import { Subject } from 'rxjs'

export const completeStore = (store: Record<string, Subject<any>>) => {
  Object.values(store).forEach((subject) => {
    if (!subject.closed) {
      subject.complete()
      subject.unsubscribe()
    }
  })
}
