import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-xls-file',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.625 3.5C3.625 2.46447 4.46447 1.625 5.5 1.625H15.5C16.5355 1.625 17.375 2.46447 17.375 3.5V5.16667V16.8333C17.375 17.8689 16.5355 18.7083 15.5 18.7083H5.5C4.46447 18.7083 3.625 17.8689 3.625 16.8333V3.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
      />
      <rect
        x="2"
        y="6"
        width="17"
        height="9"
        rx="0.833333"
        fill="#107C41"
        style="fill:#107C41;fill:color(display-p3 0.0627 0.4863 0.2549);fill-opacity:1;"
      />
      <path
        d="M13.8991 9.34129C13.8991 10.3596 16.7081 9.49579 16.7081 11.448C16.7081 12.4312 15.8935 13 14.7769 13C13.6463 13 12.8387 12.4803 12.6702 11.3919H13.857C13.9483 11.8624 14.2783 12.1222 14.7839 12.1222C15.2895 12.1222 15.5564 11.9185 15.5564 11.5885C15.5564 10.5211 12.7404 11.3357 12.7404 9.44663C12.7404 8.64607 13.4075 8 14.5803 8C15.5704 8 16.4272 8.47051 16.5747 9.57303H15.3738C15.2755 9.10253 15.0016 8.87781 14.5381 8.87781C14.1449 8.87781 13.8991 9.06039 13.8991 9.34129Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M9.08252 12.8866V8.11133H10.2061V11.9737H12.3058V12.8866H9.08252Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M7.24438 12.8866L6.41573 11.6577L6.2191 11.3136L6.01545 11.6577L5.17978 12.8866H4L5.62921 10.4287L4.11236 8.11133H5.41854L6.12079 9.14363L6.33848 9.53689L6.5632 9.14363L7.26545 8.11133H8.4382L6.92135 10.4007L8.55056 12.8866H7.24438Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class XLSFileIconComponent extends BaseIconComponent {}
