import { inject, Injectable } from '@angular/core'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs'
import { DnsVerificationStatus, DomainAssociation } from '@ti-platform/contracts'

@Injectable()
export class DomainAssociationProvider {
  protected readonly api = inject(ApiService)

  protected readonly destroy$ = injectDestroy$()
  protected readonly refresh$ = new BehaviorSubject<void>(undefined)

  @Memoize()
  public get data$(): Observable<DomainAssociation> {
    return this.refresh$.pipe(
      switchMap(() => this.loadDomainAssociation()),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }

  public getData(): Promise<DomainAssociation> {
    return firstValueFrom(this.data$)
  }

  public refresh() {
    this.refresh$.next()
  }

  protected async loadDomainAssociation() {
    const data = await this.api.tsp.whiteLabel.getDomainStatus()

    if (
      data.status === DnsVerificationStatus.InProgress ||
      data.status === DnsVerificationStatus.PendingVerification
    ) {
      setTimeout(() => this.refresh(), 30000)
    }

    return data
  }
}
