<p-panel class="confirm-with-comment panel-primary w-430 header-24 content-24 border-top">
  <!-- header -->
  <ng-template pTemplate="header">
    <header class="header">
      {{ header }}
    </header>
  </ng-template>
  <!-- close button -->
  <ng-template pTemplate="icons">
    <button
      class="p-panel-header-icon"
      (click)="dynamicDialogRef.close({ result: true, commentText })"
    >
      <span class="pi pi-times"></span>
    </button>
  </ng-template>
  <!-- comment -->
  <div class="content">
    <div class="subheader">{{ commentSubheader }}</div>
    <div>
      <textarea
        pInputTextarea
        [(ngModel)]="commentText"
        [placeholder]="commentPlaceholder"
        class="scrollbar-gray"
      ></textarea>
    </div>
  </div>
  <!-- buttons -->
  <ng-template pTemplate="footer">
    <div class="footer">
      <p-button
        [severity]="'secondary'"
        class="cancel text-14 fw-600 h--40 w50"
        (onClick)="dynamicDialogRef.close({ result: false })"
      >
        {{ cancelButton }}
      </p-button>
      <p-button
        class="delete text-14 fw-600 h--40 w50"
        (onClick)="dynamicDialogRef.close({ result: true, commentText })"
      >
        {{ confirmButton }}
      </p-button>
    </div>
  </ng-template>
</p-panel>
