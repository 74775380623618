import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-chevron',
  template: `
    <svg
      [class.rotated-180]="direction === 'up'"
      [class.rotated-90]="direction === 'left'"
      [class.rotated--90]="direction === 'right'"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10L12 14L16 10"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        [attr.stroke]="color"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rotated-180 {
        transform: rotate(180deg);
      }
      .rotated-90 {
        transform: rotate(90deg);
      }
      .rotated--90 {
        transform: rotate(-90deg);
      }
    `,
  ],
})
export class ChevronIconComponent extends BaseIconComponent {
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'down'
}
