import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { inject, Injectable } from '@angular/core'
import { AuthService, AuthSession } from '../services'
import { ApiService } from '@ti-platform/web/api'

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private readonly router: Router,
    private readonly authSession: AuthSession,
    protected readonly authService: AuthService,
    protected readonly api: ApiService,
  ) {}

  async isLoggedIn(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    if (await this.authSession.isLoggedIn()) {
      const isActive = await this.checkIsUserActive()
      if (!isActive) {
        await this.authService.signOut()
        return false
      }
      return true
    } else {
      this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } })
      return false
    }
  }

  async notLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.authSession.isLoggedIn()) {
      this.router.navigate(['/'])
      return false
    } else {
      return true
    }
  }

  protected async checkIsUserActive() {
    try {
      return this.api.myProfile.isActive()
    } catch (e) {
      console.error(`AuthGuard::checkIsUserActive`, e)
    }
    return false
  }
}

export const loggedInGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(AuthGuardService).isLoggedIn(route, state)
}

export const notLoggedInGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(AuthGuardService).notLoggedIn(route, state)
}
