import {
  DataQueryProps,
  LiveStream,
  LiveStreamStopResult,
  MediaFile,
  MediaLibraryItem,
  RefreshLiveStreamResponse,
  VehicleLiveStreamLimitStatusData,
  VideoQualityStreamtype,
} from '@ti-platform/contracts'
import { DateRange, stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'
import { prepareQueryOptions } from '../../../utils'
import { MediaLibraryGroup } from '@ti-platform/web/video/contracts'

export interface StartLiveStreamData {
  vehicleId: string

  channels: number[]

  streamtype?: VideoQualityStreamtype
}

export class MediaRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/media'
  }

  public getMediaGroup(id: string) {
    return this.get<MediaLibraryGroup>().endpoint(`group/${id}`)
  }

  public list(options: DataQueryProps) {
    return this.get<MediaLibraryItem[]>().withParams(prepareQueryOptions(options)).endpoint('list')
  }

  public countAll() {
    return this.get<number>().endpoint('count')
  }

  public countNew() {
    return this.get<number>()
      .withParams(prepareQueryOptions({ filter: { isNew: true } }))
      .endpoint(`count`)
  }

  public updateMediaFileGroup(groupId: string, data: { isNew?: boolean; isLocked?: boolean }) {
    return this.post<void>().withData(data).endpoint(`update/file-group/${groupId}`)
  }

  public toggleLockedMediaFileGroup(groupId: string, isLocked: boolean) {
    return this.post<{
      limitExceeded: boolean
      isLocked: boolean
    }>()
      .withData({ isLocked })
      .endpoint(`toggle-locked/file-group/${groupId}`)
  }

  public startLiveStream(data: StartLiveStreamData) {
    return this.post<LiveStream[]>().withData(data).endpoint(`start-livestream`)
  }

  public stopLiveStream(vehicleId: string, channels: number[]) {
    return this.delete<LiveStreamStopResult>()
      .withParams(stripEmptyKeys({ channels: channels.map((id) => id.toString()) }))
      .endpoint(`vehicle/${vehicleId}/stop-livestream`)
  }

  public refreshLiveStream(vehicleId: string, channels: number[]) {
    return this.get<RefreshLiveStreamResponse>()
      .withParams(stripEmptyKeys({ channels: channels.map((id) => id.toString()) }))
      .endpoint(`vehicle/${vehicleId}/refresh-livestream`)
  }

  public getVehicleCurrentLiveStreamLimitStatus(vehicleId: string) {
    return this.get<VehicleLiveStreamLimitStatusData>().endpoint(
      `vehicle/${vehicleId}/live-stream-current-limit-status`,
    )
  }

  public takeRealTimePhoto(vehicleId: string, channels: number[], saveAsTemporary?: boolean) {
    return this.post<MediaFile[]>()
      .withData({ vehicleId, channels, saveAsTemporary })
      .endpoint(`take-real-time-photo`)
  }

  public createVideoRequest(data: VideoRequestData) {
    return this.post()
      .withData({ ...data })
      .endpoint(`video-request`)
  }

  public deleteMediaGroupById(groupId: string) {
    return this.delete().endpoint(`media-group/${groupId}/delete`)
  }

  public retryFailedByGroupId(groupId: string) {
    return this.put().endpoint(`request-group/${groupId}/retry-failed`)
  }

  public removeFailedByGroupId(groupId: string) {
    return this.delete().endpoint(`request-group/${groupId}/delete-failed`)
  }

  public cancelProcessingRequestByGroupId(groupId: string) {
    return this.delete().endpoint(`request-group/${groupId}/cancel-processing`)
  }

  public getVehicleTimeline(vehicleId: string, dateRange: DateRange) {
    return this.get<VideoTimelineResponseItem[]>()
      .withParams({
        beginSecTimestamp: (dateRange[0].valueOf() / 1000).toFixed(0),
        endSecTimestamp: (dateRange[1].valueOf() / 1000).toFixed(0),
      })
      .endpoint(`vehicle/${vehicleId}/video-timeline`)
  }

  public getVehicleConnectionStatus(vehicleId: string) {
    return this.get().endpoint(`vehicle/${vehicleId}/connection-status`)
  }

  public deleteVehicleConnections() {
    //
  }
}

export interface VideoTimelineResponseItem {
  begin: number
  channel: number
  end: number
  filetype: 'video'
  locked: boolean
  size: number
  streamtype: VideoQualityStreamtype
}

export interface VideoRequestData {
  vehicleId: string
  channels: number[]
  beginSecTimestamp: number
  endSecTimestamp: number
  streamtype?: VideoQualityStreamtype
  latitude?: number
  longitude?: number
  address?: string
  locationIsAccurate?: boolean
  triggeredAlertId?: string
}
