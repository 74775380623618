import { inject, Injectable } from '@angular/core'
import { injectDestroy$, Memoize, WhiteLabelSettingsProvider } from '@ti-platform/web/common'
import { map, Observable, startWith, takeUntil, withLatestFrom } from 'rxjs'
import { Profile } from '@ti-platform/web/auth'
import { MyProfile, WhiteLabelSettings } from '@ti-platform/contracts'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

export interface LogoData {
  size: [number, number]
  href: string
  type: string
}

export interface BrandingData {
  name: string
  theme: {
    primaryColor: string
    backgroundColor: string
  }
  logo: {
    sm: LogoData
    lg: LogoData
  }
}

@Injectable({ providedIn: 'root' })
export class BrandingService {
  protected readonly device = inject(DeviceService)
  protected readonly profile = inject(Profile)
  protected readonly whiteLabelSettings = inject(WhiteLabelSettingsProvider)
  protected readonly destroy$ = injectDestroy$()

  protected readonly defaultTheme = {
    primaryColor: '#7f56d9',
    backgroundColor: '#ffffff',
  }
  protected readonly DEFAULT_BRANDING_DATA = {
    name: 'Fleet App',
    theme: this.defaultTheme,
    logo: {
      sm: {
        size: [144, 144],
        href: `${location.origin}/assets/images/telematics-logo-small.png`,
        type: 'image/png',
      },
      lg: {
        size: [432, 144],
        href: `${location.origin}/assets/images/telematics-logo.png`,
        type: 'image/png',
      },
    },
  } as BrandingData

  @Memoize()
  public get branding$(): Observable<BrandingData> {
    return this.whiteLabelSettings.data$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.profile.state.pipe(startWith(null))),
      map(([whiteLabelSettings, profile]) => {
        if (whiteLabelSettings) {
          return this.whiteLabelToBranding(whiteLabelSettings)
        } else if (profile) {
          return this.getFallbackBranding(profile)
        }
        return this.DEFAULT_BRANDING_DATA
      }),
    )
  }

  public applyBranding() {
    this.branding$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.updateFavicon(data)
      this.injectManifest(data)
    })
  }

  public applyFavicon() {
    this.branding$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.updateFavicon(data)
    })
  }

  protected injectManifest(branding: BrandingData) {
    const manifest = {
      name: branding.name,
      short_name: branding.name,
      theme_color: branding.theme.primaryColor,
      background_color: branding.theme.backgroundColor,
      display: 'standalone',
      orientation: this.device.isMobile() ? 'portrait' : 'portrait-primary',
      src: location.origin,
      scope: location.origin,
      start_url: location.origin,
      icons: [
        {
          src: branding.logo.sm.href,
          sizes: `${branding.logo.sm.size[0]}x${branding.logo.sm.size[1]}`,
          type: branding.logo.sm.type,
          purpose: 'any',
        },
      ],
    }

    const manifestLink = document.querySelector('#manifest') as HTMLLinkElement
    if (manifestLink) {
      manifestLink.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(manifest)),
      )
    }
  }

  protected updateFavicon(branding: BrandingData) {
    const faviconLink = document.querySelector('#favicon') as HTMLLinkElement
    if (faviconLink) {
      faviconLink.href = branding.logo.sm.href
    }
  }

  protected whiteLabelToBranding(data: WhiteLabelSettings): BrandingData {
    return {
      name: data.name,
      theme: this.defaultTheme,
      logo: {
        lg: data.logoLargeUrl
          ? {
              size: [432, 144],
              href: data.logoLargeUrl,
              type: 'image/png',
            }
          : this.DEFAULT_BRANDING_DATA.logo.lg,
        sm: data.logoCompactUrl
          ? {
              size: [144, 144],
              href: data.logoCompactUrl,
              type: 'image/png',
            }
          : this.DEFAULT_BRANDING_DATA.logo.sm,
      },
    }
  }

  /**
   * @deprecated
   */
  protected getFallbackBranding(user: MyProfile): BrandingData {
    const tspId = user.tspId
    switch (tspId) {
      case 1920477: // GPS Monitor
        return {
          name: 'GPS Monitor',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/gps-monitor-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [0, 0],
              href: `${location.origin}/assets/images/tsp/gps-monitor-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920480: // MFS
        return {
          name: 'MFS',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/mfs-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [0, 0],
              href: `${location.origin}/assets/images/tsp/mfs-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920482: // Iguo Digital
        return {
          name: 'Iguo Digital',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/iquo-digital-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [0, 0],
              href: `${location.origin}/assets/images/tsp/iquo-digital-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920520: // LVM Tech
        return {
          name: 'LVM Tech',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/lvm-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [0, 0],
              href: `${location.origin}/assets/images/tsp/lvm-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920636: // DCS
        return {
          name: 'DCS',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/dcs-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [0, 0],
              href: `${location.origin}/assets/images/tsp/dcs-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920641: // CTTMX
        return {
          name: 'CTTMX',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [144, 144],
              href: `${location.origin}/assets/images/tsp/cttmx-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [1291, 392],
              href: `${location.origin}/assets/images/tsp/cttmx-logo.png`,
              type: 'image/png',
            },
          },
        }

      case 1920643: // GPS7000
        return {
          name: 'GPS7000',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [176, 200],
              href: `${location.origin}/assets/images/tsp/fondo-blanco-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [1100, 200],
              href: `${location.origin}/assets/images/tsp/fondo-blanco-logo.jpg`,
              type: 'image/jpeg',
            },
          },
        }

      case 1938943: // Global Positioning Colombia SAS
        return {
          name: 'Global Positioning Colombia SAS',
          theme: this.defaultTheme,
          logo: {
            sm: {
              size: [176, 200],
              href: `${location.origin}/assets/images/tsp/gpc-logo-small.png`,
              type: 'image/png',
            },
            lg: {
              size: [1100, 200],
              href: `${location.origin}/assets/images/tsp/gpc-logo-big.png`,
              type: 'image/jpeg',
            },
          },
        }

      default:
        return this.DEFAULT_BRANDING_DATA
    }
  }
}
