import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-layout-selector',
  templateUrl: 'layout-selector.component.html',
  styleUrl: 'layout-selector.component.scss',
})
export class LayoutSelectorComponent {
  @Output() layout = new EventEmitter<LayoutType>()

  @Input() allowedLayouts?: LayoutType[] = [LayoutType.Block]
  @Input() value: LayoutType = LayoutType.Block
  @Input() layoutChangingDisabled = false

  protected changeLayout(type: LayoutType) {
    this.value = type
    this.layout.emit(type)
  }

  protected readonly LayoutType = LayoutType
}

export enum LayoutType {
  List = 'list',
  Block = 'block',
  Column = 'column',
}
