import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-bar-chart-2',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 20C17.25 20.4142 17.5858 20.75 18 20.75C18.4142 20.75 18.75 20.4142 18.75 20H17.25ZM18.75 10C18.75 9.58579 18.4142 9.25 18 9.25C17.5858 9.25 17.25 9.58579 17.25 10H18.75ZM11.25 20C11.25 20.4142 11.5858 20.75 12 20.75C12.4142 20.75 12.75 20.4142 12.75 20H11.25ZM12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4H12.75ZM5.25 20C5.25 20.4142 5.58579 20.75 6 20.75C6.41421 20.75 6.75 20.4142 6.75 20H5.25ZM6.75 14C6.75 13.5858 6.41421 13.25 6 13.25C5.58579 13.25 5.25 13.5858 5.25 14H6.75ZM18.75 20V10H17.25V20H18.75ZM12.75 20V4H11.25V20H12.75ZM6.75 20V14H5.25V20H6.75Z"
        [attr.fill]="color"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class BarChart2IconComponent extends BaseIconComponent {}
