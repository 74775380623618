<div class="tsp-admin-header">
  <h1 class="text-32 fw-600">{{ 'tsp-admin.menu.white-label' | translate }}</h1>
</div>

<div class="content-wrapper scrollbar-gray">
  <div [formGroup]="form" class="form p-fluid">
    <h3 class="logo-section text-18 fw-600 mb-16">
      {{ 'tsp-admin.white-label.company-logo' | translate }}
      <i
        #icon
        (click)="previewMenuRef.toggle($event)"
        [ngClass]="{ clicked: previewMenuRef.overlayVisible }"
        class="icon pi pi-eye clickable ml-10"
      ></i>

      <p-overlayPanel
        #previewMenuRef
        [appendTo]="icon"
        styleClass="custom-overlay-panel no-padding"
      >
        <ng-template pTemplate="content">
          <div class="logo-preview">
            <section class="left">
              <div class="container expanded">
                <header class="mb-24">
                  <div class="company-logo mb-24 full">
                    @if (form.value.logoLargeUrl) {
                      <img [src]="form.value.logoLargeUrl" alt="" />
                    } @else {
                      <img [src]="'assets/images/telematics-logo.svg'" alt="" />
                    }
                  </div>

                  <p-iconField class="h--40 click-disabled" iconPosition="left">
                    <p-inputIcon>
                      <app-icon-search [height]="20" [width]="20"></app-icon-search>
                    </p-inputIcon>
                    <input [placeholder]="'button.search' | translate" pInputText type="text" />
                  </p-iconField>
                </header>
                <nav>
                  <div class="navigation-item">
                    <app-icon-favorites
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-favorites>
                    {{ 'fleet.menu.bookmarks' | translate }}
                  </div>
                  <div class="navigation-item">
                    <app-icon-bar-chart-2
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-bar-chart-2>
                    {{ 'fleet.menu.dashboard' | translate }}
                  </div>
                  <div class="navigation-item active">
                    <app-transport-icon-selector
                      [color]="'var(--icon-color)'"
                      class="icon"
                      icon="truck"
                    ></app-transport-icon-selector>
                    {{ 'fleet.menu.fleet' | translate }}
                  </div>
                  <div class="navigation-item">
                    <app-icon-alerts
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-alerts>
                    {{ 'fleet.menu.alters' | translate }}
                  </div>
                  <div class="navigation-item">
                    <app-icon-video
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-video>
                    {{ 'fleet.menu.video' | translate }}
                  </div>
                </nav>
              </div>
            </section>
            <section class="right">
              <div class="container collapsed">
                <header class="mb-24">
                  <div class="company-logo compact mb-24">
                    @if (form.value.logoCompactUrl) {
                      <img [src]="form.value.logoCompactUrl" alt="" />
                    } @else {
                      <img [src]="'assets/images/telematics-logo-small.svg'" alt="" />
                    }
                  </div>
                  <p-button [severity]="'secondary'" class="h--38 text-14 click-disabled" icon="pi">
                    <app-icon-search
                      [color]="'var(--color-gray-500)'"
                      [height]="20"
                      [width]="20"
                    ></app-icon-search>
                  </p-button>
                </header>
                <nav>
                  <div class="navigation-item">
                    <app-icon-favorites
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-favorites>
                  </div>
                  <div class="navigation-item">
                    <app-icon-bar-chart-2
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-bar-chart-2>
                  </div>
                  <div class="navigation-item active">
                    <app-transport-icon-selector
                      [color]="'var(--icon-color)'"
                      class="icon"
                      icon="truck"
                    ></app-transport-icon-selector>
                  </div>
                  <div class="navigation-item">
                    <app-icon-alerts
                      [color]="'var(--icon-color)'"
                      [height]="24"
                      [width]="24"
                      class="icon"
                    ></app-icon-alerts>
                  </div>
                </nav>
              </div>
            </section>

            <div class="fade-overlay"></div>
          </div>
        </ng-template>
      </p-overlayPanel>
    </h3>

    <div
      (dropzoneDropped)="loadLogoImage($event[0], ImageSize.Large)"
      class="image-card full mb-16"
      dropzone
    >
      @if (form.value.logoLargeUrl) {
        <div class="cropped-image-wrapper full mb-12">
          <img [src]="form.value.logoLargeUrl" alt="" />
          <div class="delete-button clickable" (click)="deleteCroppedImage(ImageSize.Large)">
            <app-icon-close
              [width]="24"
              [height]="24"
              [color]="'var(--color-gray-0)'"
            ></app-icon-close>
          </div>
        </div>
      } @else {
        <svg
          class="mb-12"
          width="235"
          height="88"
          viewBox="0 0 235 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="235"
            height="87"
            rx="12"
            fill="#F2F4F7"
            style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
          />
          <path
            d="M79.1002 38.7353C79.1002 52.6037 66.643 60.9706 57.3001 70.5C47.1786 60.1765 35.5 52.6037 35.5 38.7353C35.5 26.4551 45.2602 16.5 57.3001 16.5C69.34 16.5 79.1002 26.4551 79.1002 38.7353Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M73.6501 38.1C73.6501 47.047 66.3299 54.3 57.3 54.3C48.2701 54.3 40.95 47.047 40.95 38.1C40.95 29.153 48.2701 21.9 57.3 21.9C66.3299 21.9 73.6501 29.153 73.6501 38.1Z"
            fill="#F9FAFB"
            style="fill: #f9fafb; fill: color(display-p3 0.9765 0.9804 0.9843); fill-opacity: 1"
          />
          <path
            d="M63.5434 34.4746C64.632 33.808 65.4323 33.995 65.8138 34.1738C66.1953 34.3446 66.8373 34.8079 66.8373 35.9704V40.23C66.8373 41.3924 66.1953 41.8558 65.8138 42.0265C65.637 42.1078 65.3671 42.1972 65.0228 42.1972C64.6227 42.1972 64.1203 42.0834 63.5434 41.7257L62.1755 40.8803C62.0732 43.4084 60.817 44.4002 57.765 44.4002H52.182C48.9253 44.4002 47.7622 42.4086 47.7622 40.5389V35.6615C47.7622 32.8813 48.9998 31.8002 52.182 31.8002H57.765C60.817 31.8002 62.0732 32.7919 62.1755 35.32L63.5434 34.4746Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M166.989 53.7601C166.989 56.4169 174.357 54.1705 174.357 58.8145C174.357 61.0393 172.286 62.5513 169.365 62.5513C166.488 62.5513 164.417 61.2337 164.002 58.4473H166.793C166.989 59.7001 167.992 60.4129 169.452 60.4129C170.826 60.4129 171.545 59.8297 171.545 58.9873C171.545 56.3953 164.329 58.6201 164.329 53.9329C164.329 52.0537 165.812 50.3257 168.864 50.3257C171.545 50.3257 173.573 51.5785 173.921 54.4513H171.131C170.913 53.0473 170.085 52.4857 168.755 52.4857C167.643 52.4857 166.989 53.0257 166.989 53.7601Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M157.767 58.6201V52.7881H156.263V50.6497H157.767V47.9929H160.558V50.6497H163.021V52.7881H160.558V58.3825C160.558 59.5921 160.907 60.2401 162.454 60.2401H162.978V62.2705C162.672 62.4217 162.04 62.5513 161.277 62.5513C158.901 62.5513 157.767 61.2121 157.767 58.6201Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M144.645 62.2273V46.6753H147.435V52.2049C148.198 50.9521 149.419 50.3257 150.901 50.3257C153.234 50.3257 154.825 51.7081 154.825 54.5161V62.2273H152.035V55.0777C152.035 53.5009 151.359 52.6153 149.942 52.6153C148.394 52.6153 147.435 53.9545 147.435 55.5097V62.2273H144.645Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M136.079 62.2705C133.115 62.2705 131.283 60.0025 131.283 56.3089C131.283 52.4641 133.158 50.3257 136.079 50.3257C137.475 50.3257 138.565 50.8441 139.284 51.8161V50.6497H142.075V62.5945C142.075 65.7265 139.894 67.3249 136.995 67.3249C134.117 67.3249 132.025 66.0073 131.61 63.2641H134.423C134.597 64.4953 135.6 65.1433 136.951 65.1433C138.347 65.1433 139.284 64.4737 139.284 62.6377V60.6937C138.521 61.7305 137.431 62.2705 136.079 62.2705ZM136.712 59.9809C138.39 59.9809 139.284 58.6849 139.284 56.3089C139.284 53.9545 138.347 52.6153 136.712 52.6153C135.055 52.6153 134.161 53.9113 134.161 56.3089C134.161 58.6633 135.055 59.9809 136.712 59.9809Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M126.485 49.3537V46.6753H129.275V49.3537H126.485ZM126.485 62.2273V50.6497H129.275V62.2273H126.485Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M115.647 51.2329C115.647 54.7537 124.52 51.9025 124.52 57.9073C124.52 60.8665 122.078 62.5513 118.721 62.5513C115.342 62.5513 112.944 60.9961 112.377 57.7993H115.451C115.822 59.3545 116.955 60.2185 118.743 60.2185C120.574 60.2185 121.511 59.4625 121.511 58.2745C121.511 54.5377 112.661 57.3457 112.661 51.4921C112.661 49.1593 114.601 47.2153 118.111 47.2153C121.097 47.2153 123.626 48.6409 124.106 51.9457H120.988C120.639 50.3041 119.615 49.5481 118.023 49.5481C116.541 49.5481 115.647 50.2177 115.647 51.2329Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M100.235 62.2273V50.6497H103.026V52.2049C103.789 50.9521 105.01 50.3257 106.492 50.3257C108.825 50.3257 110.416 51.7081 110.416 54.5161V62.2273H107.626V55.0777C107.626 53.5009 106.95 52.6153 105.533 52.6153C103.985 52.6153 103.026 53.9545 103.026 55.5097V62.2273H100.235Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M94.8918 49.3537V46.6753H97.6823V49.3537H94.8918ZM94.8918 62.2273V50.6497H97.6823V62.2273H94.8918Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M192.132 26.7601C192.132 29.4169 199.5 27.1705 199.5 31.8145C199.5 34.0393 197.429 35.5513 194.508 35.5513C191.63 35.5513 189.559 34.2337 189.145 31.4473H191.935C192.132 32.7001 193.134 33.4129 194.595 33.4129C195.968 33.4129 196.688 32.8297 196.688 31.9873C196.688 29.3953 189.472 31.6201 189.472 26.9329C189.472 25.0537 190.954 23.3257 194.006 23.3257C196.688 23.3257 198.715 24.5785 199.064 27.4513H196.274C196.056 26.0473 195.227 25.4857 193.897 25.4857C192.786 25.4857 192.132 26.0257 192.132 26.7601Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M187.779 27.7537H184.989C184.727 26.2417 183.812 25.6153 182.635 25.6153C180.912 25.6153 179.997 26.9761 179.997 29.4601C179.997 31.9009 180.956 33.2617 182.722 33.2617C183.921 33.2617 184.771 32.6137 185.054 31.2961H187.867C187.343 34.1905 185.294 35.5513 182.656 35.5513C179.212 35.5513 177.119 33.2185 177.119 29.4601C177.119 25.5289 179.277 23.3257 182.635 23.3257C185.294 23.3257 187.387 24.7945 187.779 27.7537Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M172.321 22.3537V19.6753H175.111V22.3537H172.321ZM172.321 35.2273V23.6497H175.111V35.2273H172.321Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M165.431 31.6201V25.7881H163.927V23.6497H165.431V20.9929H168.222V23.6497H170.685V25.7881H168.222V31.3825C168.222 32.5921 168.571 33.2401 170.118 33.2401H170.642V35.2705C170.336 35.4217 169.704 35.5513 168.941 35.5513C166.565 35.5513 165.431 34.2121 165.431 31.6201Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M155.097 27.4513H152.285C152.656 24.8809 154.552 23.3257 157.495 23.3257C160.743 23.3257 162.4 25.2265 162.4 27.9049V32.3977C162.4 33.8233 162.531 34.6225 162.749 35.2273H159.85C159.697 34.8385 159.632 34.2769 159.61 33.6505C158.651 34.9897 157.212 35.5513 155.817 35.5513C153.528 35.5513 151.893 34.4281 151.893 32.2033C151.893 30.6265 152.786 29.4385 154.574 28.8553C156.122 28.3369 157.626 28.2073 159.588 28.1857V27.9049C159.588 26.4145 158.869 25.6153 157.321 25.6153C155.991 25.6153 155.272 26.3497 155.097 27.4513ZM154.705 32.0521C154.705 32.9377 155.402 33.4993 156.536 33.4993C158.258 33.4993 159.588 32.1169 159.588 30.3889V29.8921C155.62 29.9569 154.705 30.8857 154.705 32.0521Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M133.255 35.2273V23.6497H136.045V25.1401C136.786 23.9305 137.92 23.3257 139.294 23.3257C140.776 23.3257 141.931 23.9737 142.52 25.2913C143.305 23.9737 144.547 23.3257 146.052 23.3257C148.297 23.3257 149.823 24.7081 149.823 27.5161V35.2273H147.033V28.0777C147.033 26.5009 146.444 25.6153 145.201 25.6153C143.806 25.6153 142.934 26.9545 142.934 28.5097V35.2273H140.144V28.0777C140.144 26.5009 139.555 25.6153 138.313 25.6153C136.917 25.6153 136.045 26.9545 136.045 28.5097V35.2273H133.255Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M131.248 31.4473C130.616 34.1257 128.523 35.5513 125.733 35.5513C122.201 35.5513 120.043 33.2401 120.043 29.4601C120.043 25.5289 122.223 23.3257 125.624 23.3257C129.046 23.3257 131.161 25.6153 131.161 29.3521V30.0001H122.92C123.051 32.1169 124.032 33.2833 125.733 33.2833C127.041 33.2833 127.934 32.6785 128.305 31.4473H131.248ZM125.624 25.5937C124.141 25.5937 123.247 26.5225 122.986 28.2721H128.24C127.978 26.5441 127.084 25.5937 125.624 25.5937Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M115.244 35.2273V19.6753H118.035V35.2273H115.244Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M113.237 31.4473C112.605 34.1257 110.512 35.5513 107.722 35.5513C104.19 35.5513 102.032 33.2401 102.032 29.4601C102.032 25.5289 104.212 23.3257 107.613 23.3257C111.036 23.3257 113.15 25.6153 113.15 29.3521V30.0001H104.91C105.041 32.1169 106.022 33.2833 107.722 33.2833C109.03 33.2833 109.924 32.6785 110.294 31.4473H113.237ZM107.613 25.5937C106.131 25.5937 105.237 26.5225 104.975 28.2721H110.229C109.967 26.5441 109.074 25.5937 107.613 25.5937Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path
            d="M94.7745 35.2273V22.9369H90.0002V20.5393H102.492V22.9369H97.7175V35.2273H94.7745Z"
            fill="#98A2B3"
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
          />
          <path d="M16 0L16 88" stroke="white" style="stroke: white; stroke-opacity: 1" />
          <path d="M219 0L219 88" stroke="white" style="stroke: white; stroke-opacity: 1" />
          <path
            d="M235 16L-7.62939e-06 16"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
          />
          <path
            d="M235 71L-7.62939e-06 71"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
          />
        </svg>
      }

      <div class="logo-type text-14 fw-600 mb-4">
        {{ 'tsp-admin.white-label.full-logo' | translate }}
      </div>
      <div class="flex text-group">
        <span class="manual-upload text-14 fw-600">
          <input
            (change)="onLogoImageFileSelected($event, ImageSize.Large)"
            accept="image/*"
            hidden
            id="uploadFull"
            type="file"
          />
          <label class="clickable" for="uploadFull">
            @if (form.value.logoLargeUrl) {
              {{ 'button.replace' | translate }}
            } @else {
              {{ 'button.upload' | translate }}
            }
          </label>
        </span>
        <span class="description text-14 fw-400">
          &nbsp; {{ 'tsp-admin.white-label.drag-and-drop' | translate }}</span
        >
      </div>
    </div>
    <div
      (dropzoneDropped)="loadLogoImage($event[0], ImageSize.Compact)"
      class="image-card compact mb-32"
      dropzone
    >
      @if (form.value.logoCompactUrl) {
        <div class="cropped-image-wrapper compact mb-12">
          <img [src]="form.value.logoCompactUrl" alt="" />
          <div class="delete-button clickable" (click)="deleteCroppedImage(ImageSize.Compact)">
            <app-icon-close
              [width]="24"
              [height]="24"
              [color]="'var(--color-gray-0)'"
            ></app-icon-close>
          </div>
        </div>
      } @else {
        <svg
          class="mb-12"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="73.18015665796345 0 88.25065274151436 87.32307692307693"
          style="max-height: 500px"
          width="88.25065274151436"
          height="87.32307692307693"
        >
          <rect
            style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
            fill="#F2F4F7"
            rx="12"
            height="87"
            width="87"
            x="74"
          />
          <path style="stroke: white; stroke-opacity: 1" stroke="white" d="M90 0L90 88" />
          <path style="stroke: white; stroke-opacity: 1" stroke="white" d="M145 0L145 88" />
          <path
            style="stroke: white; stroke-opacity: 1"
            stroke="white"
            d="M235 16L-7.62939e-06 16"
          />
          <path
            style="stroke: white; stroke-opacity: 1"
            stroke="white"
            d="M235 71L-7.62939e-06 71"
          />
          <path
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
            fill="#98A2B3"
            d="M139.6 38.7353C139.6 52.6037 127.143 60.9706 117.8 70.5C107.679 60.1765 96 52.6037 96 38.7353C96 26.4551 105.76 16.5 117.8 16.5C129.84 16.5 139.6 26.4551 139.6 38.7353Z"
          />
          <path
            style="fill: #f9fafb; fill: color(display-p3 0.9765 0.9804 0.9843); fill-opacity: 1"
            fill="#F9FAFB"
            d="M134.15 38.1C134.15 47.047 126.83 54.3 117.8 54.3C108.77 54.3 101.45 47.047 101.45 38.1C101.45 29.153 108.77 21.9 117.8 21.9C126.83 21.9 134.15 29.153 134.15 38.1Z"
          />
          <path
            style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
            fill="#98A2B3"
            d="M124.044 34.4746C125.133 33.808 125.933 33.995 126.314 34.1738C126.696 34.3446 127.338 34.8079 127.338 35.9704V40.23C127.338 41.3924 126.696 41.8558 126.314 42.0265C126.137 42.1078 125.868 42.1972 125.523 42.1972C125.123 42.1972 124.621 42.0834 124.044 41.7257L122.676 40.8803C122.574 43.4084 121.318 44.4002 118.265 44.4002H112.683C109.426 44.4002 108.263 42.4086 108.263 40.5389V35.6615C108.263 32.8813 109.5 31.8002 112.683 31.8002H118.265C121.318 31.8002 122.574 32.7919 122.676 35.32L124.044 34.4746Z"
          />
        </svg>
      }

      <div class="logo-type text-14 fw-600 mb-4">
        {{ 'tsp-admin.white-label.compact-logo' | translate }}
      </div>
      <div class="flex text-group">
        <span class="manual-upload text-14 fw-600">
          <input
            (change)="onLogoImageFileSelected($event, ImageSize.Compact)"
            accept="image/*"
            hidden
            id="uploadCompact"
            type="file"
          />
          <label class="clickable" for="uploadCompact">
            @if (form.value.logoCompactUrl) {
              {{ 'button.replace' | translate }}
            } @else {
              {{ 'button.upload' | translate }}
            }
          </label>
        </span>
        <span class="description text-14 fw-400">
          &nbsp; {{ 'tsp-admin.white-label.drag-and-drop' | translate }}
        </span>
      </div>
    </div>

    <h3 *ngIf="false" class="text-18 fw-600 mb-16">Interface color</h3>
    <div *ngIf="false" class="colors-list">
      <div class="interface-color-item purple">
        <div class="color-container">
          <div class="primary-color"></div>
          <div class="secondary-color"></div>
        </div>
        <div class="circle"></div>
      </div>

      <div class="interface-color-item orange">
        <div class="color-container">
          <div class="primary-color"></div>
          <div class="secondary-color"></div>
        </div>
        <div class="circle"></div>
        <div class="checkmark">
          <app-icon-check [color]="'var(--icon-color)'" [height]="20" [width]="20"></app-icon-check>
        </div>
      </div>

      <div class="interface-color-item blue">
        <div class="color-container">
          <div class="primary-color"></div>
          <div class="secondary-color"></div>
        </div>
        <div class="circle"></div>
      </div>

      <div class="interface-color-item green">
        <div class="color-container">
          <div class="primary-color"></div>
          <div class="secondary-color"></div>
        </div>
        <div class="circle"></div>
      </div>

      <div class="interface-color-item aquamarine">
        <div class="color-container">
          <div class="primary-color"></div>
          <div class="secondary-color"></div>
        </div>
        <div class="circle"></div>
      </div>
    </div>

    <h3 class="text-18 fw-600 mb-16">{{ 'tsp-admin.white-label.custom-domain' | translate }}</h3>

    @if (this.isCustomDomainSet) {
      <input
        [placeholder]="(whiteLabelSettings.data$ | async)?.domain ?? ''"
        class="h--40 input-domain mb-16"
        formControlName="domain"
        pInputText
      />
    } @else {
      <input
        [placeholder]="'tsp-admin.white-label.enter-domain' | translate"
        class="h--40 input-domain mb-16"
        formControlName="domain"
        pInputText
      />
    }
    <!--    <app-editable-input class="input-domain mb-16"></app-editable-input>-->

    @if (this.isCustomDomainSet) {
      <p-accordion class="domain-set large chevron-right mb-32">
        <p-accordionTab [disabled]="!this.isCustomDomainSet">
          <ng-template pTemplate="header">
            <span class="header text-14 fw-600">
              {{ 'tsp-admin.white-label.domain-instructions' | translate }}
            </span>
          </ng-template>
          @if (domainInstructionsReady) {
            <div class="domain-setup-container">
              <header>
                <h3 class="caption text-14 fw-600">
                  {{ 'tsp-admin.white-label.dns-label' | translate }}
                </h3>
                <app-icon-info-solid
                  [pTooltip]="'tsp-admin.white-label.dns-hint' | translate"
                  [tooltipPosition]="'bottom'"
                  [width]="20"
                  [height]="20"
                  [color]="'var(--color-gray-400)'"
                ></app-icon-info-solid>
              </header>

              @for (
                instruction of (domainAssociation.data$ | async)?.dnsVerification ?? [];
                track instruction.hostname
              ) {
                <section class="domain-data">
                  <div class="row">
                    <div class="label">{{ 'tsp-admin.white-label.type' | translate }}</div>
                    <div class="type-text">{{ instruction.type }}</div>
                  </div>
                  <div class="row">
                    <div class="label">{{ 'tsp-admin.white-label.hostname' | translate }}</div>
                    <div class="counter">
                      <div class="data">{{ instruction.hostname }}</div>
                      <app-copy-button
                        class="copy-icon"
                        [iconStyle]="'outlined'"
                        [text]="instruction.hostname"
                      >
                      </app-copy-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="label">
                      {{ 'tsp-admin.white-label.data-url' | translate }}
                    </div>
                    <div class="counter">
                      <div class="data">{{ instruction.value }}</div>
                      <app-copy-button
                        class="copy-icon"
                        [iconStyle]="'outlined'"
                        [text]="instruction.value"
                      >
                      </app-copy-button>
                    </div>
                  </div>
                </section>
              }
            </div>
          } @else {
            <div class="accordion-message flex gap-12">
              <app-icon-clock
                [width]="20"
                [height]="20"
                [color]="'var(--color-gray-500)'"
              ></app-icon-clock>

              <div class="text text-14 fw-400">
                {{ 'tsp-admin.white-label.domain-instructions-not-ready' | translate }}
              </div>
            </div>
          }
        </p-accordionTab>
      </p-accordion>
    }

    <h3 class="text-18 fw-600 mb-16">
      {{ 'tsp-admin.white-label.google-maps-api-key' | translate }}
    </h3>

    <input
      [placeholder]="'tsp-admin.white-label.enter-key' | translate"
      class="h--40"
      formControlName="googleMapsKey"
      pInputText
    />
    <!--    <app-editable-input-->
    <!--      [height]="40"-->
    <!--      [value]="$any(form.value.googleMapsKey)"-->
    <!--      (submitted)="form.patchValue({ googleMapsKey: $event })"-->
    <!--    ></app-editable-input>-->
  </div>
</div>

<div class="horizontal-spacer"></div>

<div class="footer">
  <div class="button-wrapper">
    <p-button
      class="h--40 w100 text-14 fw-600"
      [disabled]="!hasChanges()"
      [appCheckAccess]="[Resource.WhiteLabel, Action.Update]"
      (click)="saveChanges()"
    >
      {{ 'button.save' | translate }}
    </p-button>
  </div>
</div>

<!-- TODO: Move cropper dialog to a separate component -->
<p-dialog
  (onHide)="onImageCancel()"
  [(visible)]="cropperDialogOpened"
  [modal]="true"
  class="dialog-primary large image-cropper-dialog"
>
  <ng-template pTemplate="header">
    <header class="">
      <h2 class="mb-4 text-18 fw-600">
        {{ 'tsp-admin.white-label.image-cropper.crop-image' | translate }}
      </h2>
      <div class="restrictions text-14 fw-400">
        {{ 'tsp-admin.white-label.image-cropper.image-resize' | translate }}
      </div>
      <div class="restrictions text-14 fw-400">
        {{ 'tsp-admin.white-label.image-cropper.aspect-ratio' | translate }}
      </div>
    </header>
  </ng-template>

  <div *ngIf="cropperDimensions$ | async as dimensions" class="content">
    <image-cropper
      (imageCropped)="imageCropped($event)"
      [aspectRatio]="dimensions.ratio"
      [containWithinAspectRatio]="true"
      [imageBase64]="$any(selectedImageDataUrl$ | async)"
      [output]="'base64'"
      [resizeToHeight]="dimensions.height"
      [resizeToWidth]="dimensions.width"
    ></image-cropper>
  </div>

  <div class="footer border-top flex">
    <p-button
      (click)="cropperDialogOpened.set(false)"
      (onClick)="onImageCancel()"
      [severity]="'secondary'"
      class="text-14 fw-600 w50 h--40"
    >
      {{ 'button.cancel' | translate }}
    </p-button>

    <p-button (onClick)="onCroppedImageSave()" class="text-14 fw-600 w50 h--40">
      <app-icon-check
        [color]="'var(--icon-color)'"
        [height]="20"
        [width]="20"
        class="icon"
      ></app-icon-check>
      {{ 'button.save' | translate }}
    </p-button>
  </div>
</p-dialog>
