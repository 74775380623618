import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { TspCommandListData } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize, selectState } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { cloneDeep } from 'lodash'
import { MessageService } from 'primeng/api'
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  Observable,
  switchMap,
  takeUntil,
} from 'rxjs'
import { CommandsListModel, DevicesTypesDataProvider } from '../../models'

type ExtendedTspCommandListData = TspCommandListData & {
  configFields: string
  devicesList: {
    name: string
    description: string
  }[]
  devicesMore: number
}

@Component({
  selector: 'app-tsp-commands-grid',
  templateUrl: 'commands-grid.component.html',
  styleUrls: ['commands-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandsGridComponent {
  protected readonly destroy$ = injectDestroy$()
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly languageService = inject(LanguageService)
  protected readonly maxDevicesToShow = 10
  protected readonly messageService = inject(MessageService)
  protected readonly model = inject(CommandsListModel)
  protected readonly router = inject(Router)
  protected readonly state = selectState(CommandsListModel)
  protected readonly showAddCommandDialog$ = new BehaviorSubject(false)
  protected readonly api = inject(ApiService)
  // protected readonly currentMenuItem$ = new BehaviorSubject<ExtendedTspCommandListData | null>(null)
  protected readonly deviceListByCommandId$ = new BehaviorSubject<
    Record<string, { name: string; description: string }[]>
  >({})

  @Memoize()
  protected get data$(): Observable<ExtendedTspCommandListData[]> {
    return combineLatest([
      this.state.items$,
      this.languageService.current$,
      this.model.commandFieldsLabels$,
    ]).pipe(
      debounceTime(100),
      takeUntil(this.destroy$),
      switchMap(async ([commandItems, lang, labels]) => {
        if (commandItems?.length) {
          return await this.processCommandItems(commandItems)
        } else {
          return []
        }
      }),
    )
  }

  protected get noDataDescriptionKey() {
    return this.model.isFiltered
      ? 'search.no-data-description'
      : 'tsp-admin.devices.commands.no-data.description'
  }

  protected get noDataTitleKey() {
    return this.model.isFiltered
      ? 'search.no-data-title'
      : 'tsp-admin.devices.commands.no-data.title'
  }

  protected onLoadMoreClicked() {
    this.model.loadNextPage()
  }

  protected onSortUpdated(event) {
    this.model.unselect()
    this.model.setOrder(event)
  }

  protected get commandFieldsLabels() {
    return this.model.commandFieldsLabels$.getValue()
  }

  protected async processCommandItems(
    items: TspCommandListData[],
  ): Promise<ExtendedTspCommandListData[]> {
    const falseLabel = await this.languageService.translate('tsp-admin.devices.commands.false')
    const trueLabel = await this.languageService.translate('tsp-admin.devices.commands.true')
    this.deviceListByCommandId$.next({})
    return items.map((item) => {
      const devicesList: { name: string; description: string }[] = []
      let devicesMore = 0
      const commandDevicesLength = item.numberOfDevices
      if (commandDevicesLength) {
        if (commandDevicesLength > this.maxDevicesToShow) {
          devicesMore = commandDevicesLength - this.maxDevicesToShow
        }
      }

      let configView = ''
      Object.keys(item.config).forEach((key) => {
        const label = this.commandFieldsLabels[key] ?? key
        const value = item.config[key]
        let processedValue = value
        switch (typeof value) {
          case 'boolean':
            processedValue = value ? trueLabel : falseLabel
            break
          case 'object':
            processedValue = JSON.stringify(value)
            break
        }
        configView = `${configView}${configView ? ', ' : ''}${label}: ${processedValue}`
      })
      return {
        ...cloneDeep(item),
        configFields: configView,
        devicesMore,
        devicesList,
      }
    })
  }

  protected async onDeviceColumnClick(
    menuComponent: any,
    event: any,
    data: ExtendedTspCommandListData,
  ) {
    // menuComponent.toggle(event)
    const deviceListByCommandId = this.deviceListByCommandId$.value
    const savedDevicesList = deviceListByCommandId[data.id]
    if (data.numberOfDevices === 0 || !!savedDevicesList?.length) {
      menuComponent.toggle(event)
    } else if (data.numberOfDevices && !savedDevicesList?.length) {
      const devicesList: { name: string; description: string }[] = []
      try {
        const newEvent = this.cloneEvent(event)
        const devices = await this.api.tsp.devices.listAssignedToCommand(data.id, {
          pageSize: this.maxDevicesToShow,
        })
        devices.forEach((device) => {
          devicesList.push({
            name: device.name as string,
            description: `${device.model} / ${device.identifier}`,
          })
        })
        if (devicesList.length) {
          deviceListByCommandId[data.id] = devicesList
          this.deviceListByCommandId$.next(deviceListByCommandId)
          menuComponent.toggle(newEvent)
        }
      } catch (e) {
        console.error(e)
        menuComponent.hide()
      }
    }
  }

  protected cloneEvent(e) {
    function ClonedEvent() {}
    const clone = new ClonedEvent()
    for (const p in e) {
      const d = Object.getOwnPropertyDescriptor(e, p)
      if (d && (!d.writable || !d.configurable || !d.enumerable || d.get || d.set)) {
        Object.defineProperty(clone, p, d)
      } else {
        clone[p] = e[p]
      }
    }
    Object.setPrototypeOf(clone, e)
    return clone
  }
}
