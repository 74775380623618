@if (header) {
  <div class="header flex">
    <div class="header-title">{{ header }}</div>

    <div class="header-icon-wrapper">
      <i
        class="pi pi-times header-icon clickable"
        [class]="{ disabled: !!(disableMode$ | async) }"
        (click)="onCloseClick()"
      ></i>
    </div>
  </div>
}

<form [formGroup]="commandForm" class="p-fluid medium command-form">
  <section class="inputs-section">
    <div class="label mb-8 text-14 fw-500">
      {{ 'tsp-admin.devices.device-brand-and-model' | translate }}
    </div>

    @if (newCommandMode) {
      <app-autocomplete-select
        (completeMethod)="onFilterTypesData($event)"
        [completeOnFocus]="true"
        [forceSelection]="true"
        [suggestions]="filteredTypesData"
        formControlName="deviceType"
        optionLabel="label"
        placeholder="{{ 'tsp-admin.devices.select-brand-and-model' | translate }}"
        className="{{ deviceType ? 'mb-16' : '' }}"
      ></app-autocomplete-select>
    } @else {
      <div class="non-active-device-type mb-16">
        <div class="non-active-device-type__text">
          {{ deviceType?.label }}
        </div>
        <div class="non-active-device-type__icon">
          <i class="pi pi-chevron-down"></i>
        </div>
      </div>
    }

    @if (deviceType) {
      <div class="horizontal-spacer--16"></div>
      @if (commandDescription) {
        <div class="label mb-8 text-14 fw-500">
          {{ 'tsp-admin.devices.commands.grid.name' | translate }}
        </div>
        <input
          class="h--40"
          formControlName="name"
          pInputText
          placeholder="{{ 'tsp-admin.devices.commands.enter-command-name' | translate }}"
          type="text"
        />

        @if (configFieldsSet$ | async) {
          <div formGroupName="config">
            @for (propertyName of commandPropertiesList; track propertyName) {
              <div class="label mt-16 mb-8 text-14 fw-500">
                {{ commandFieldsLabels[propertyName] || propertyName }}
              </div>
              @switch (commandProperties?.[propertyName]?.type) {
                @case ('string') {
                  <input
                    class="h--40"
                    [formControlName]="propertyName"
                    pInputText
                    placeholder="{{ 'tsp-admin.devices.commands.enter-string' | translate }}"
                    type="text"
                  />
                }
                @case ('object') {
                  <input
                    class="h--40"
                    [formControlName]="propertyName"
                    pInputText
                    placeholder="{{ 'tsp-admin.devices.commands.enter-json' | translate }}"
                    type="text"
                  />
                }
                @case ('integer') {
                  <p-inputNumber
                    class="h--40"
                    [formControlName]="propertyName"
                    mode="decimal"
                    inputId="integeronly"
                    [useGrouping]="false"
                    placeholder="{{ 'tsp-admin.devices.commands.enter-number' | translate }}"
                  />
                }
                @case ('number') {
                  <p-inputNumber
                    class="h--40"
                    [formControlName]="propertyName"
                    mode="decimal"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    placeholder="{{ 'tsp-admin.devices.commands.enter-number' | translate }}"
                  />
                }
                @case ('boolean') {
                  <div class="field flex">
                    <p-radioButton
                      [formControlName]="propertyName"
                      class="label-margin-9 text-14 fw-500 mr-8"
                      [label]="'tsp-admin.devices.commands.true' | translate"
                      [value]="true"
                    ></p-radioButton>
                    <p-radioButton
                      [formControlName]="propertyName"
                      [value]="false"
                      class="label-margin-9 text-14 fw-500"
                      [label]="'tsp-admin.devices.commands.false' | translate"
                    >
                    </p-radioButton>
                  </div>
                }
              }
            }
          </div>
        }
      } @else {
        <div class="no-command-support">
          <div class="no-command-support__summary">
            {{ 'tsp-admin.devices.commands.not-available' | translate }}
          </div>
          <div class="no-command-support__description">
            {{ 'tsp-admin.devices.commands.not-available-description' | translate }}
          </div>
        </div>
      }
    }
  </section>

  @if ((errorMessageKey$ | async) || false) {
    <section class="errors-section">
      <div class="errors-section-icon">
        <i class="pi pi-times-circle"></i>
      </div>
      <div>
        {{ (errorMessageKey$ | async) || '' | translate }}
      </div>
    </section>
  }
</form>

<div class="footer flex">
  <p-button
    (click)="onCancel()"
    [disabled]="!!(disableMode$ | async)"
    [severity]="'secondary'"
    class="cancel text-14 fw-600 w50 p--8 h--40"
    type="submit"
  >
    {{ 'button.cancel' | translate }}
  </p-button>

  <p-button
    (click)="onSubmit()"
    [disabled]="(modelWasChanged$ | async) === false || !isValidForm || !!(disableMode$ | async)"
    [loading]="(isLoading$ | async) || false"
    class="submit text-14 fw-600 w50 h--40"
    type="submit"
  >
    @if (newCommandMode) {
      {{ 'button.add' | translate }}
    } @else {
      {{ 'button.save' | translate }}
    }
  </p-button>
</div>
