import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-content-paginator',
  templateUrl: 'content-paginator.component.html',
  styleUrl: 'content-paginator.component.scss',
})
export class ContentPaginatorComponent {
  @Output() onSelectedItemChange = new EventEmitter()

  @Input() selectedItem = 0

  protected itemsArray: number[] = []
  protected _items = 0

  @Input()
  set items(value: number) {
    this._items = value
    this.itemsArray = Array.from({ length: this._items }, (_, index) => index)
  }
}
