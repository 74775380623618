import { AfterViewInit, Component, ElementRef, inject, input } from '@angular/core'
import { distinctUntilChanged, takeUntil } from 'rxjs'
import * as Sentry from '@sentry/angular'
import { Integration } from '@sentry/types/build/types/integration'
import {
  FeedbackGeneralConfiguration,
  FeedbackThemeConfiguration,
  FeedbackTextConfiguration,
  FeedbackCallbacks,
} from '@sentry/types/build/types/feedback/config'
import { injectDestroy$ } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

type SentryFeedbackIntegration = Integration & {
  attachTo: (
    el: HTMLElement,
    options: Partial<
      FeedbackGeneralConfiguration &
        FeedbackThemeConfiguration &
        FeedbackTextConfiguration &
        FeedbackCallbacks
    >,
  ) => void
  remove: () => void
}

@Component({
  selector: 'app-feedback-button',
  templateUrl: 'feedback-button.component.html',
  styles: `
    .feedback-button {
      --p-button__bg-color: var(--color-gray-900);
      --p-button__border-color: var(--color-gray-900);
      --p-button__hover-bg-color: var(--color-gray-900);
      --p-button__hover-border-color: var(--color-gray-900);
      --p-button__active-bg-color: var(--color-gray-900);
      --p-button__active-border-color: var(--color-gray-900);
    }

    .compact {
      .icon {
        margin: 0;
      }
    }
  `,
})
export class FeedbackButtonComponent implements AfterViewInit {
  isCompact = input(false)
  isFloating = input(false)
  protected readonly elementRef = inject(ElementRef)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly feedbackOptions = {
    isNameRequired: true,
    isEmailRequired: true,
    showBranding: false,
    autoInject: false,
    arguments: [],
    colorScheme: 'light',
    themeLight: {
      accentBackground: '#7f56d9', // color-primary-500
    },
  } as Partial<FeedbackGeneralConfiguration & FeedbackThemeConfiguration>

  public async ngAfterViewInit() {
    let currentFeedbackIntegration: SentryFeedbackIntegration

    this.languageService.current$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(async () => {
        // Remove previous integration instance
        this.cleanupIntegration(currentFeedbackIntegration)

        const textConfiguration = await this.getTextConfig()
        currentFeedbackIntegration = Sentry.feedbackIntegration()
        currentFeedbackIntegration.attachTo(this.elementRef.nativeElement, {
          ...textConfiguration,
          ...this.feedbackOptions,
          onFormOpen: this.onFeedbackFormOpened,
        })
      })

    // Remove form after the component is destroyed
    this.destroy$.subscribe(() => {
      this.cleanupIntegration(currentFeedbackIntegration)
    })
  }

  protected cleanupIntegration(currentFeedbackIntegration: SentryFeedbackIntegration) {
    if (currentFeedbackIntegration) {
      try {
        currentFeedbackIntegration?.remove()
      } catch (e) {
        return
      }
    }
  }

  protected async getTextConfig() {
    const translations = await this.getTranslations()
    return {
      formTitle: translations.title,
      cancelButtonLabel: translations.cancelButton,
      submitButtonLabel: translations.submitButton,
      confirmButtonLabel: translations.confirmButton,
      addScreenshotButtonLabel: translations.addScreenshotButton,
      removeScreenshotButtonLabel: translations.removeScreenshotButton,
      nameLabel: translations.nameLabel,
      namePlaceholder: translations.namePlaceholder,
      emailLabel: translations.emailLabel,
      emailPlaceholder: translations.emailPlaceholder,
      messageLabel: translations.messageLabel,
      messagePlaceholder: translations.messagePlaceholder,
      isRequiredLabel: '',
      successMessageText: translations.successMessageText,
    } as FeedbackTextConfiguration
  }

  protected async getTranslations() {
    const [
      title,
      cancelButton,
      submitButton,
      confirmButton,
      addScreenshotButton,
      removeScreenshotButton,
      nameLabel,
      namePlaceholder,
      emailLabel,
      emailPlaceholder,
      messageLabel,
      messagePlaceholder,
      successMessageText,
    ] = await Promise.all([
      this.languageService.translate('feedback.title'),
      this.languageService.translate('feedback.cancel-button'),
      this.languageService.translate('feedback.submit-button'),
      this.languageService.translate('feedback.confirm-button'),
      this.languageService.translate('feedback.add-screenshot-button'),
      this.languageService.translate('feedback.remove-screenshot-button'),
      this.languageService.translate('feedback.name-label'),
      this.languageService.translate('feedback.name-placeholder'),
      this.languageService.translate('feedback.email-label'),
      this.languageService.translate('feedback.email-placeholder'),
      this.languageService.translate('feedback.message-label'),
      this.languageService.translate('feedback.message-placeholder'),
      this.languageService.translate('feedback.success-message-text'),
    ])
    return {
      title,
      cancelButton,
      submitButton,
      confirmButton,
      addScreenshotButton,
      removeScreenshotButton,
      nameLabel,
      namePlaceholder,
      emailLabel,
      emailPlaceholder,
      messageLabel,
      messagePlaceholder,
      successMessageText,
    }
  }

  protected readonly onFeedbackFormOpened = () => {
    const sentryRootElements = Array.from(
      document.querySelectorAll<HTMLElement>('#sentry-feedback'),
    )
    if (sentryRootElements.length) {
      const targetElement = sentryRootElements.at(-1)
      if (targetElement) {
        const style = document.createElement('style')
        style.innerHTML = sentryFeedbackCSSOverrides
        style.innerHTML = sentryFeedbackCSSOverrides
        targetElement.shadowRoot?.appendChild(style)
      }

      const elementsToRemove = sentryRootElements.slice(0, sentryRootElements.length - 1)
      if (elementsToRemove.length) {
        elementsToRemove.forEach((el) => {
          try {
            el.parentNode?.removeChild(el)
          } catch (e) {
            return
          }
        })
      }
    }
  }
}

const sentryFeedbackCSSOverrides = `
  .dialog {
    --form-width: 312px;
    --input-border: 1px solid #d0d5dd;
    --button-border: 1px solid #d0d5dd;

    font-family: 'Switzer', sans-serif;
    backdrop-filter: blur(3px);
    background-color: rgba(16, 24, 40, 0.6);
  }

  @supports (height: 100dvh) {
    .dialog {
      max-height: 100dvh;
    }
  }

  .dialog__content {
    gap: 0;
    padding: 0;
  }

  .dialog__header {
    padding: 24px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(16, 24, 40, 1);
    border-bottom: 1px solid var(--Gray-200, #e2e7ee);
  }

  form.form {
    padding: 24px;
  }

  .form__top {
    gap: 16px;
  }

  .form__label__text,
  .form__label__text--required {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(16, 24, 40, 1);
  }

  .btn-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: stretch;
    padding: 0 2px;
  }

  .btn-group .btn {
    width: 48%;
  }

  .btn-group button.btn--default {
    --button-color: var(--color-primary-500);
  }

  label[for='screenshot'] button {
    font-weight: 600;
    color: var(--accent-background);
  }

  .editor {
    background-color: white;
    background-image: unset;
  }

  .editor__crop-btn-group {
    width: fit-content;
  }

  @media all and (min-width: 480px) {
    .dialog__content {
      min-width: 360px;
    }
  }
`
