import { Component } from '@angular/core'
import { FleetAccountsModel } from '@ti-platform/tsp-admin/app/fleet/models'

@Component({
  selector: 'app-tsp-fleet-accounts-page',
  templateUrl: 'fleet-accounts-page.component.html',
  styleUrls: ['fleet-accounts-page.component.scss'],
  providers: [FleetAccountsModel],
})
export class FleetAccountsPageComponent {}
