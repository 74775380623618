<div
  class="trips-list"
  *ngIf="{
    trips: tripsHistory$ | async,
    areTripsLoading: trips.state.areTripsLoading$ | async,
  } as view"
  [ngClass]="{ 'request-video-mode': listStyle === 'video' }"
>
  @for (trip of view.trips; track tripsTrackBy) {
    <div class="trip-item" [class.selected]="trip.id === (trips.state.selectedTrip$ | async)?.id">
      @switch (trip.type) {
        @case ('trip') {
          <div class="status flex--col">
            <i><app-icon-finish [color]="'var(--color-gray-500)'"></app-icon-finish></i>
            <i class="spacer">
              <app-icon-line-dashed [color]="'var(--color-gray-500)'"></app-icon-line-dashed>
            </i>
            <i>
              <app-icon-map-pin
                [width]="24"
                [height]="24"
                [color]="'var(--color-gray-500)'"
              ></app-icon-map-pin>
            </i>
          </div>
          <div class="info flex--col clickable" (click)="trips.selectTrip(trip)">
            @if (trip.mileage) {
              <div class="distance-badge text-14 fw-500">
                <app-icon-distance
                  [width]="20"
                  [height]="20"
                  [color]="'var(--icon-color)'"
                  class="mr-4"
                ></app-icon-distance>
                {{
                  trip.mileage
                    | formatUnit: UnitType.Distance : { precision: 1, usePreciseIfLess: 10 }
                }}
              </div>
            }

            <div class="trip-part">
              <div class="time flex text-14 fw-500">
                {{ trip.endTimestamp | formatDate: 'short-date' }}
                <div class="vertical-spacer--12"></div>
                {{ trip.endTimestamp | date: 'h:mm'
                }}{{ trip.endTimestamp | date: 'a' | lowercase }}
              </div>
              <div class="location text-16 fw-600">{{ trip.endAddress }}</div>
            </div>

            <div class="trip-part">
              <div class="time flex text-14 fw-500">
                {{ trip.startTimestamp | formatDate: 'short-date' }}
                <div class="vertical-spacer--12"></div>
                {{ trip.startTimestamp | date: 'h:mm'
                }}{{ trip.startTimestamp | date: 'a' | lowercase }}
              </div>
              <div class="location text-16 fw-600">{{ trip.startAddress }}</div>
            </div>
          </div>
        }
        @case ('stop') {
          <div class="status">
            <i>
              <app-icon-stop-solid-2
                [width]="20"
                [height]="20"
                [color]="'var(--color-gray-500)'"
              ></app-icon-stop-solid-2>
            </i>
          </div>
          <div class="info flex--col clickable" (click)="trips.selectTrip(trip)">
            <div class="time text-14 fw-500">{{ trip.duration | formatDuration }}</div>
            <div class="location text-16 fw-600">{{ trip.startAddress }}</div>
          </div>
        }
      }
    </div>
  }

  @if (!view.trips?.length && !view.areTripsLoading) {
    <p class="no-data text-16">
      @if (listStyle === 'video') {
        {{ 'video.request.no-recorded-activity' | translate }}
      } @else {
        {{ 'fleet.message.no-history-yet' | translate }}
      }
    </p>
  }

  @if (trips.state.areTripsLoading$ | async) {
    <ng-container [ngTemplateOutlet]="VehicleTripsSkeletonTmpl"></ng-container>
  } @else if (trips.state.canLoadMoreTrips$ | async) {
    <div class="more-button text-14 fw-600" (click)="trips.loadTripsNextPage()">
      {{ 'button.show-more' | translate }}
    </div>
  }
</div>

<ng-template #VehicleTripsSkeletonTmpl>
  <div class="trip-item-skeleton" *appRepeat="9">
    <p-skeleton width="100%" height="100%" animation="wave"></p-skeleton>
  </div>
</ng-template>
