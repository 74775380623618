import { Component, Type } from '@angular/core'
import { SelectItem } from 'primeng/api'
import { AlertTriggerTypes } from '@ti-platform/contracts'
import { ICONS } from '../../../icons/icons.module'

@Component({
  selector: 'app-design-system',
  templateUrl: 'design-system.component.html',
  styleUrls: ['design-system.component.scss'],
})
export class DesignSystemComponent {
  protected triggerOption: SelectItem[] = [
    {
      label: 'SOS / Alarm',
      value: AlertTriggerTypes.sos_alarm,
    },
    {
      label: 'Speeding',
      value: AlertTriggerTypes.speeding,
    },
    {
      label: 'Movement',
      value: AlertTriggerTypes.movement,
    },
    {
      label: 'Ignition',
      value: AlertTriggerTypes.ignition,
    },
    {
      label: 'Engine idling',
      value: AlertTriggerTypes.engine_idling,
    },
    {
      label: 'Geofence activity',
      value: AlertTriggerTypes.geofence_activity,
    },
    {
      label: 'Towing',
      value: AlertTriggerTypes.towing,
    },
  ]

  protected readonly videoChannels: SelectItem[] = [
    { label: 'Text 1', value: 1 },
    { label: 'Text 2', value: 2 },
    { label: 'Text 3', value: 3 },
    { label: 'Text 4', value: 4 },
    { label: 'Text 5', value: 5 },
    { label: 'Text 6', value: 6 },
  ]

  protected countries: any[] = [
    {
      title: 'afg',
      value: 1,
    },
  ]

  protected readonly icons: Array<Type<unknown>>

  protected readonly transportIcons = [
    'bicycle',
    'bus',
    'bus-1',
    'bus-1-side',
    'bus-2',
    'bus-double',
    'car',
    'car-1',
    'car-cargo',
    'mclaren',
    'police',
    'scooter',
    'taxi',
    'taxi-1',
    'trolleybus',
    'truck',
    'truck-big',
    'truck-cargo',
    'truck-cargo-big',
    'truck-highway',
  ]

  public constructor() {
    this.icons = ICONS.filter((iconComponent) => {
      const selector = this.getComponentSelector(iconComponent)
      return (
        selector !== 'ng-component' &&
        !this.transportIcons.find((iconKey) => selector.includes(iconKey))
      )
    })
  }

  public getComponentSelector(component: any): string {
    const componentDef = component['ɵcmp'] as { selectors: string[][] }
    if (componentDef && componentDef.selectors) {
      return componentDef.selectors[0][0]
    }
    return ''
  }
}
