import { inject, Injectable } from '@angular/core'
import { DeviceType } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$ } from '@ti-platform/web/common'
import { BehaviorSubject } from 'rxjs'

export interface CommandDescription {
  name: string
  schema: Record<string, any>
}

export interface DeviceTypeDataOption {
  label: string
  value: number
}

@Injectable()
export class DevicesTypesDataProvider {
  protected readonly api = inject(ApiService)
  protected readonly destroy$ = injectDestroy$()
  readonly allDevicesTypesData$ = new BehaviorSubject<DeviceType[]>([])
  readonly deviceTypeDataByTypeId$ = new BehaviorSubject<Record<number, DeviceType>>({})
  readonly devicesTypesDataWithCommands$ = new BehaviorSubject<DeviceType[]>([])
  readonly commandDataByDeviceTypeId$ = new BehaviorSubject<Record<number, CommandDescription>>({})

  public get allDevicesTypesData() {
    return this.allDevicesTypesData$.getValue()
  }

  public get deviceTypeDataByTypeId() {
    return this.deviceTypeDataByTypeId$.getValue()
  }

  public get devicesTypesDataWithCommands() {
    return this.devicesTypesDataWithCommands$.getValue()
  }

  public get commandDataByDeviceTypeId() {
    return this.commandDataByDeviceTypeId$.getValue()
  }

  public get deviceTypesDataOptions(): DeviceTypeDataOption[] {
    return this.allDevicesTypesData.map((item) => {
      return {
        label: item.title,
        value: item.id,
      }
    })
  }

  public processAllDeviceTypeData() {
    const allTypes = this.allDevicesTypesData
    const devicesTypesDataWithCommands: DeviceType[] = []
    const commandDataByDeviceTypeId: Record<number, CommandDescription> = {}
    const deviceTypeDataByTypeId: Record<number, DeviceType> = {}

    for (const deviceType of allTypes) {
      deviceTypeDataByTypeId[deviceType.id] = deviceType

      const customCommand = deviceType.customCommand
      if (!customCommand) {
        continue
      }
      let schema = customCommand.schema
      if (schema.anyOf) {
        schema = schema.anyOf[0]
      }
      devicesTypesDataWithCommands.push(deviceType)
      commandDataByDeviceTypeId[deviceType.id] = {
        name: customCommand.name,
        schema,
      }
    }
    this.deviceTypeDataByTypeId$.next(deviceTypeDataByTypeId)
    this.devicesTypesDataWithCommands$.next(devicesTypesDataWithCommands)
    this.commandDataByDeviceTypeId$.next(commandDataByDeviceTypeId)
  }

  public async getDevicesTypesData() {
    if (!this.allDevicesTypesData$.getValue().length) {
      try {
        const devicesTypesDataList = await this.api.tsp.devices.typesData({
          withConfiguration: true,
          withCustomCommand: true,
        })
        if (devicesTypesDataList.length) {
          this.allDevicesTypesData$.next(devicesTypesDataList)
          this.processAllDeviceTypeData()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
