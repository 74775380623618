import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core'

export interface TagBarOption<T = any> {
  label: string
  value: T
}

@Component({
  selector: 'app-slider',
  templateUrl: 'slider.component.html',
  styleUrl: 'slider.component.scss',
})
export class SliderComponent implements AfterViewInit {
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)

  @Output() tagClicked = new EventEmitter<TagBarOption>()
  @Output() tagRemoved = new EventEmitter<TagBarOption>()

  @ViewChild('tagsBar') tagsBar!: ElementRef

  protected showScrollRightButton = signal(false)
  protected showScrollLeftButton = signal(false)

  protected scrollTimeout: any
  protected scrollTimeoutThreshold = 50
  protected scrollPositionThreshold = 5

  @Input() customContentMode? = false
  @Input() tags: TagBarOption[] = []

  ngAfterViewInit() {
    this.calculateButtonsState()

    const resizeObserver = new ResizeObserver(() => this.updateButtons())
    resizeObserver.observe(this.tagsBar.nativeElement)
  }

  protected calculateButtonsState() {
    const bar = this.tagsBar.nativeElement

    const scrollLeft = bar.scrollLeft
    const scrollRight = bar.scrollWidth - bar.clientWidth - bar.scrollLeft

    const isAtLeft = scrollLeft < this.scrollPositionThreshold
    const isAtRight = scrollRight < this.scrollPositionThreshold

    this.showScrollLeftButton.set(!isAtLeft)
    this.showScrollRightButton.set(!isAtRight)

    //For some reason change detection is not working properly when using this function in resizeObserver callback
    this.changeDetectorRef.detectChanges()
  }

  protected updateButtons() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    this.scrollTimeout = setTimeout(() => {
      this.calculateButtonsState()
    }, this.scrollTimeoutThreshold)
  }

  protected scrollLeft() {
    const tagsBarEl = this.tagsBar.nativeElement
    tagsBarEl.scrollBy({ left: -400, behavior: 'smooth' })
    this.calculateButtonsState()
  }

  protected scrollRight() {
    const tagsBarEl = this.tagsBar.nativeElement
    tagsBarEl.scrollBy({ left: 400, behavior: 'smooth' })
    this.calculateButtonsState()
  }

  protected onCloseClick(event: Event, tag: TagBarOption) {
    event.preventDefault()
    event.stopPropagation()
    this.tagRemoved.emit(tag)
  }
}
