import { Component, Input, ViewChild } from '@angular/core'
import { Menu } from 'primeng/menu'

@Component({
  selector: 'app-recipients-list',
  templateUrl: 'recipients-list.component.html',
  styleUrl: 'recipients-list.component.scss',
})
export class RecipientsListComponent {
  @Input() model: any
  @Input() appendTo = 'body'

  @ViewChild('menu') menu!: Menu

  public toggle($event: MouseEvent) {
    this.menu.toggle($event)
  }
}
