import { LngLatBounds } from 'maplibre-gl'
import circle from '@turf/circle'
import length from '@turf/length'
import square from '@turf/square'
import bboxPolygon from '@turf/bbox-polygon'
import { lineString } from '@turf/helpers'
import { BBox, Feature, GeoJsonProperties, Geometry } from 'geojson'
import { Coordinates } from '../contracts'

export const getSquarePolygonFeature = (
  id: string,
  center: Coordinates,
  bounds: LngLatBounds,
  properties?: Record<string, any>,
): Feature => {
  const mapSizeKm = getMapMinKmSizeFromBounds(bounds)
  const sideLength = mapSizeKm / 4
  const halfSideLength = (sideLength / 2) * 0.009 // Approximation of degrees per km

  // Calculate the bounding box
  const minX = center[0] - halfSideLength
  const minY = center[1] - halfSideLength
  const maxX = center[0] + halfSideLength
  const maxY = center[1] + halfSideLength
  const bbox = [minX, minY, maxX, maxY] as BBox

  const res = square(bbox)
  const polygon = bboxPolygon(res)

  return {
    id,
    type: 'Feature',
    geometry: polygon.geometry,
    properties: {
      ...(properties ?? {}),
    },
  }
}

export const getCircleFeatureFromCoords = (
  id: string,
  center: Coordinates,
  { bounds, radius }: { bounds: LngLatBounds; radius?: number },
  properties?: Record<string, any>,
): Feature<Geometry, GeoJsonProperties> => {
  if (!bounds && !radius) {
    throw new Error('Circle requires a bounds or a radius')
  }

  validateCoordinates(center)

  const circleRadius = radius ?? getMapMinKmSizeFromBounds(bounds) / 8
  const circleFeature = circle(center, circleRadius, { units: 'kilometers' })

  return {
    id,
    type: 'Feature',
    properties: {
      ...(properties ?? {}),
      center,
      isCircle: true,
      radiusInKm: circleRadius,
    },
    geometry: circleFeature.geometry,
  }
}

export function validateCoordinates(coordinates: Coordinates): void {
  const [lng, lat] = coordinates
  if (!Number.isFinite(lng) || !Number.isFinite(lat)) {
    throw new Error(`Invalid coordinates: [${lng},${lat}]`)
  }
  if (lat < -90 || lat > 90) {
    const errorString = 'Latitude must be between -90 and 90 degrees inclusive.'
    console.warn(errorString)
    throw new Error(errorString)
  } else if (lng < -180 || lng > 180) {
    const errorString = 'Longitude must be between -180 and 180 degrees inclusive.'
    console.warn(errorString)
    throw new Error(errorString)
  }
}

const getMapMinKmSizeFromBounds = (bounds: LngLatBounds): number => {
  const lineX = lineString([bounds.getSouthEast().toArray(), bounds.getNorthEast().toArray()])
  const lineY = lineString([bounds.getSouthEast().toArray(), bounds.getSouthWest().toArray()])
  const distanceKmX = length(lineX, { units: 'kilometers' })
  const distanceKmY = length(lineY, { units: 'kilometers' })
  return Math.min(distanceKmX, distanceKmY)
}
