import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-yes-badge',
  template: `
    @switch (value) {
      @case (false) {
        <span class="text-14">{{ 'common.no' | translate }}</span>
      }
      @case (true) {
        <div [style.--color]="color" class="text-14 badge">
          {{ 'common.yes' | translate }}
          <div class="bg"></div>
        </div>
      }
    }
  `,
  styles: `
    .text-14 {
      color: var(--color-gray-900);
    }

    .badge {
      line-height: 21px;
      padding: 4px 6px;
      width: fit-content;
      border-radius: 6px;
      color: var(--color);
      overflow: hidden;
      position: relative;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.16;
      background-color: var(--color);
      border-radius: 6px;
    }
  `,
})
export class YesBadgeComponent {
  @Input() value?: boolean
  @Input() color?: string
}
