import { Component } from '@angular/core'
import {
  CommandDevicesListModel,
  CommandsListModel,
  CopyMediaConfigDevicesListModel,
  DevicesListModel,
  DevicesMediaUsageReportModel,
} from '../models'

@Component({
  selector: 'app-tsp-devices-page',
  templateUrl: 'devices-page.component.html',
  styleUrls: ['devices-page.component.scss'],
  providers: [
    DevicesListModel,
    CommandsListModel,
    CommandDevicesListModel,
    DevicesMediaUsageReportModel,
    CopyMediaConfigDevicesListModel,
  ],
})
export class DevicesPageComponent {}
