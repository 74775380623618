import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-water',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73482 17.6655V17.6655C4.42173 15.0745 4.42173 11.2256 6.73482 8.63461L10.1335 4.82757C10.6028 4.30194 11.2838 4 12 4C12.7162 4 13.3972 4.30194 13.8665 4.82757L17.2652 8.63461C19.5783 11.2256 19.5783 15.0745 17.2652 17.6655V17.6655C15.9414 19.1482 14.0204 20 12 20C9.97956 20 8.05856 19.1482 6.73482 17.6655Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0087 15.541C14.2523 16.4678 13.1545 17.0001 12 17.0001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class WaterIconComponent extends BaseIconComponent {}
