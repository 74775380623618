import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-wrench',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3_69783)">
        <path
          d="M22.1684 5.9914C22.7018 7.85118 22.2361 9.93703 20.7711 11.402C19.1221 13.051 16.6866 13.434 14.6722 12.5511L6.01024 21.2131C5.61972 21.6036 4.98655 21.6036 4.59603 21.2131L3.18182 19.7989C2.79129 19.4084 2.79129 18.7752 3.18182 18.3847L11.8438 9.7227C10.9609 7.70834 11.3439 5.27283 12.9929 3.62387C14.4579 2.15886 16.5437 1.69309 18.4035 2.22656C18.7419 2.32361 18.823 2.74352 18.5741 2.99241L15.5562 6.01031L18.3846 8.83874L21.4025 5.82083C21.6514 5.57194 22.0713 5.65306 22.1684 5.9914Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class WrenchIconComponent extends BaseIconComponent {}
