import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-feedback',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.07783 21.808C9.78583 21.808 11.9818 19.612 11.9818 16.904C11.9818 14.196 9.78683 12 7.07783 12C4.36883 12 2.17383 14.196 2.17383 16.904"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.083 21.8131C6.345 21.8131 5.646 21.6501 5.018 21.3591L2 22.0001L2.631 18.9761C2.338 18.3461 2.174 17.6441 2.174 16.9041"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.557 12.0281C6.199 11.2071 6 10.3121 6 9.37306C6 5.35606 9.598 2.13306 14 2.13306C18.402 2.13306 22 5.35606 22 9.37306C22 11.7471 20.738 13.8371 18.801 15.1551C18.802 15.9111 18.8 16.9301 18.8 18.0001L15.661 16.4541C15.124 16.5571 14.569 16.6131 14 16.6131C13.293 16.6131 12.607 16.5291 11.953 16.3721"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FeedbackIconComponent extends BaseIconComponent {}
