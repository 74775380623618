import { Component, inject } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-not-found-page',
  templateUrl: 'not-found-page.component.html',
  styleUrl: 'not-found-page.component.scss',
})
export class NotFoundPageComponent {
  protected readonly router = inject(Router)

  protected backToApp() {
    this.router.navigate(['/'])
  }
}
