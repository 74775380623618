import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-maintenance',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2774 10.5353L14.2527 7.56008C14.2714 7.54125 14.2798 7.51442 14.275 7.48826C14.2702 7.46211 14.2529 7.43998 14.2287 7.42903C12.4727 6.67412 10.4344 7.05732 9.0725 8.39843C7.76758 9.67401 7.34814 11.6044 8.00606 13.3065L3.5482 17.7673C2.81206 18.5044 2.81206 19.6984 3.5482 20.4354C4.28524 21.1716 5.47927 21.1716 6.21631 20.4354L10.7102 15.9416C12.4611 16.5931 14.4319 16.134 15.7146 14.7758C16.9973 13.4176 17.3431 11.4238 16.5926 9.71298C16.5811 9.68962 16.5591 9.67316 16.5334 9.66862C16.5078 9.66408 16.4815 9.672 16.4626 9.68997L13.4123 12.6692L12.3459 11.5998L11.2774 10.5353Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.1033 19.5492C10.9742 22.0648 16.1195 21.253 19.028 17.668C21.9365 14.0829 21.6701 8.88085 18.4107 5.61162C15.1512 2.34239 9.94996 2.06043 6.35621 4.95815C2.76245 7.85587 1.9353 12.9987 4.43919 16.8771"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class MaintenanceIconComponent extends BaseIconComponent {}
