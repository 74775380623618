import { inject, ViewContainerRef } from '@angular/core'
import { combineLatest, startWith, Subject, takeUntil } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'

export class BaseMapOverlayComponent {
  protected readonly viewContainerRef = inject(ViewContainerRef)
  protected readonly destroy$ = injectDestroy$()

  public bindToElement(targetEl: HTMLElement, hostEl: HTMLElement) {
    // Move current element to the map container
    hostEl.parentElement?.appendChild(this.elementRef)

    this.observeElement(targetEl)
  }

  protected observeElement(targetEl: HTMLElement) {
    combineLatest([this.createElementObserver(targetEl).pipe(startWith(null))])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.elementRef.style.transform !== targetEl.style.transform) {
          this.elementRef.style.transform = targetEl.style.transform
        }
      })
  }

  protected createElementObserver(targetEl: HTMLElement) {
    const observerEvents$ = new Subject<MutationRecord[]>()
    const observer = new MutationObserver((changes) => observerEvents$.next(changes))
    observer.observe(targetEl, { attributes: true, attributeFilter: ['style'] })
    this.destroy$.subscribe(() => {
      observer.disconnect()
      observerEvents$.complete()
    })
    return observerEvents$
  }

  protected get elementRef(): HTMLElement {
    return this.viewContainerRef.element.nativeElement
  }
}
