var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// index.ts
import { bearing } from "@turf/bearing";
import { distance } from "@turf/distance";
import { destination } from "@turf/destination";
import { lineIntersect as lineIntersects } from "@turf/line-intersect";
import { flattenEach } from "@turf/meta";
import { point, lineString } from "@turf/helpers";
import { getCoords } from "@turf/invariant";
function nearestPointOnLine(lines, pt, options = {}) {
  if (!lines || !pt) {
    throw new Error("lines and pt are required arguments");
  }
  let closestPt = point([Infinity, Infinity], {
    dist: Infinity,
    index: -1,
    multiFeatureIndex: -1,
    location: -1
  });
  let length = 0;
  flattenEach(
    lines,
    function(line, _featureIndex, multiFeatureIndex) {
      const coords = getCoords(line);
      for (let i = 0; i < coords.length - 1; i++) {
        const start = point(coords[i]);
        start.properties.dist = distance(pt, start, options);
        const stop = point(coords[i + 1]);
        stop.properties.dist = distance(pt, stop, options);
        const sectionLength = distance(start, stop, options);
        const heightDistance = Math.max(
          start.properties.dist,
          stop.properties.dist
        );
        const direction = bearing(start, stop);
        const perpendicularPt1 = destination(
          pt,
          heightDistance,
          direction + 90,
          options
        );
        const perpendicularPt2 = destination(
          pt,
          heightDistance,
          direction - 90,
          options
        );
        const intersect = lineIntersects(
          lineString([
            perpendicularPt1.geometry.coordinates,
            perpendicularPt2.geometry.coordinates
          ]),
          lineString([start.geometry.coordinates, stop.geometry.coordinates])
        );
        let intersectPt;
        if (intersect.features.length > 0 && intersect.features[0]) {
          intersectPt = __spreadProps(__spreadValues({}, intersect.features[0]), {
            properties: {
              dist: distance(pt, intersect.features[0], options),
              multiFeatureIndex,
              location: length + distance(start, intersect.features[0], options)
            }
          });
        }
        if (start.properties.dist < closestPt.properties.dist) {
          closestPt = __spreadProps(__spreadValues({}, start), {
            properties: __spreadProps(__spreadValues({}, start.properties), {
              index: i,
              multiFeatureIndex,
              location: length
            })
          });
        }
        if (stop.properties.dist < closestPt.properties.dist) {
          closestPt = __spreadProps(__spreadValues({}, stop), {
            properties: __spreadProps(__spreadValues({}, stop.properties), {
              index: i + 1,
              multiFeatureIndex,
              location: length + sectionLength
            })
          });
        }
        if (intersectPt && intersectPt.properties.dist < closestPt.properties.dist) {
          closestPt = __spreadProps(__spreadValues({}, intersectPt), {
            properties: __spreadProps(__spreadValues({}, intersectPt.properties), { index: i })
          });
        }
        length += sectionLength;
      }
    }
  );
  return closestPt;
}
var turf_nearest_point_on_line_default = nearestPointOnLine;
export {
  turf_nearest_point_on_line_default as default,
  nearestPointOnLine
};
