import { inject, Injectable } from '@angular/core'
import { ForgotPasswordViewModel } from '@ti-platform/web/auth'
import { ApiService } from '@ti-platform/web/api'

@Injectable()
export class TspAdminForgotPasswordViewModel extends ForgotPasswordViewModel {
  protected readonly api = inject(ApiService)

  protected async requestPasswordReset(email: string) {
    await this.api.tsp.user.resetPassword(email)
  }
}
