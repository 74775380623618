import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-pdf-file',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.625 3.5C3.625 2.46447 4.46447 1.625 5.5 1.625H15.5C16.5355 1.625 17.375 2.46447 17.375 3.5V5.16667V16.8333C17.375 17.8689 16.5355 18.7083 15.5 18.7083H5.5C4.46447 18.7083 3.625 17.8689 3.625 16.8333V3.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
      />
      <rect
        x="2"
        y="6"
        width="17"
        height="9"
        rx="0.833333"
        fill="#D22323"
        style="fill:#D22323;fill:color(display-p3 0.8223 0.1387 0.1387);fill-opacity:1;"
      />
      <path
        d="M13.3652 12.76V8H16.7812V8.889H14.4852V10.051H16.5082V10.94H14.4852V12.76H13.3652Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M8.39551 12.76V8H10.2225C11.7275 8 12.6655 8.903 12.6655 10.387C12.6655 11.871 11.7275 12.76 10.2225 12.76H8.39551ZM10.2225 11.85C11.0415 11.85 11.5175 11.311 11.5175 10.387C11.5175 9.456 11.0415 8.91 10.2225 8.91H9.51551V11.85H10.2225Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M4 12.76V8H5.855C7.031 8 7.759 8.595 7.759 9.596C7.759 10.618 7.024 11.206 5.869 11.206H5.12V12.76H4ZM5.834 8.889H5.12V10.317H5.806C6.352 10.317 6.604 10.03 6.604 9.603C6.604 9.169 6.338 8.889 5.834 8.889Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PDFFileIconComponent extends BaseIconComponent {}
