import { Component, Input, OnChanges, signal, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-round-timer',
  templateUrl: 'round-timer.component.html',
  styleUrl: 'round-timer.component.scss',
})
export class RoundTimerComponent implements OnChanges {
  @Input() accuracy = 0
  @Input() duration = 5000
  @Input() currentTime = 0

  protected readonly svgOffset = signal<number>(0)
  protected readonly timerValue = signal<string>('')

  protected readonly CIRCLE_CONST = 126

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.accuracy || changes.duration || changes.currentTime) {
      this.refreshValues()
    }
  }

  protected refreshValues() {
    this.svgOffset.set(
      this.CIRCLE_CONST - (this.CIRCLE_CONST * (this.currentTime / 1000)) / (this.duration / 1000),
    )
    this.timerValue.set((this.duration / 1000 - this.currentTime / 1000).toFixed(this.accuracy))
  }
}
