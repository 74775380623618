import { inject, Injectable } from '@angular/core'
import { DataQueryProps, TspVideoDeviceTrafficReportData } from '@ti-platform/contracts'
import {
  AccessControl,
  DateRange,
  ListModel,
  ListModelConfig,
  PreDefinedRange,
} from '@ti-platform/web/common'
import { DateFormatService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { DevicesTypesDataProvider } from './devices-types-data.provider'

@Injectable()
export class DevicesMediaUsageReportModel extends ListModel<TspVideoDeviceTrafficReportData> {
  public override title = 'tsp-admin.devices.media-usage.title'
  protected readonly accessControl = inject(AccessControl)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly messageService = inject(MessageService)
  protected readonly dateFormatService = inject(DateFormatService)

  protected override _exportColumns = [
    {
      field: 'name',
      labelTranslation: 'tsp-admin.devices.grid.device-name',
    },
    {
      field: 'model',
      labelTranslation: 'tsp-admin.devices.grid.brand-and-model',
    },
    {
      field: 'identifier',
      labelTranslation: 'tsp-admin.devices.grid.imei-serial',
    },
    {
      field: 'video_channels_number',
      labelTranslation: 'tsp-admin.devices.media-usage.grid.cameras',
    },
    {
      field: 'fleet_name',
      labelTranslation: 'tsp-admin.devices.grid.fleet',
    },
    {
      field: 'media_files_size_gb',
      labelTranslation: 'tsp-admin.devices.media-usage.export.media-storage',
    },
    {
      field: 'media_traffic_gb',
      labelTranslation: 'tsp-admin.devices.media-usage.export.media-traffic',
    },
    {
      field: 'media_stream_duration_seconds',
      labelTranslation: 'tsp-admin.devices.media-usage.export.live-stream-duration',
    },
    {
      field: 'media_stream_traffic_gb',
      labelTranslation: 'tsp-admin.devices.media-usage.export.live-stream-traffic',
    },
  ]

  public get isActiveSearch() {
    return !!this.store.search$.value
  }

  public getOrderByValue() {
    return this.store.orderBy$.getValue()
  }

  public updateCurrentDateRange() {
    const dateRange = this.store.dateRange$.value
    if (dateRange && Array.isArray(dateRange) && dateRange?.[0]) {
      const now = new Date(Date.now())
      if (
        now.getUTCMonth() === dateRange[0].getUTCMonth() &&
        now.getUTCFullYear() === dateRange[0].getUTCFullYear()
      ) {
        this.setDateRange(this.getCurrentUTCDateRangePeriod())
      }
    }
  }

  public formatGBValue(value: number): number {
    if (!value) {
      return 0
    }
    if (value < 0.01) {
      return 0.01
    } else if (value < 1) {
      return parseFloat(value.toFixed(2))
    } else {
      return parseFloat(value.toFixed(1))
    }
  }

  public formatSecDurationToMin(value: number): number {
    if (!value) {
      return 0
    }
    const minutes = Math.ceil(value / 60)
    return minutes < 1 ? 1 : minutes
  }

  async loadCount() {
    return this.api.tsp.devices.countVideo()
  }

  protected override async convertDataToExport(
    data: TspVideoDeviceTrafficReportData[],
  ): Promise<any[]> {
    const result: any[] = []
    for (const row of data) {
      result.push({
        name: row.name,
        model: row.model,
        identifier: row.identifier,
        video_channels_number: row.video_channels_number,
        fleet_name: row.fleet_name,
        media_files_size_gb: this.formatGBValue(row.media_files_size_gb),
        media_traffic_gb: this.formatGBValue(row.media_traffic_gb),
        media_stream_duration_seconds: this.formatSecDurationToMin(
          row.media_stream_duration_seconds,
        ),
        media_stream_traffic_gb: this.formatGBValue(row.media_stream_traffic_gb),
      })
    }
    return result
  }

  protected config(): ListModelConfig {
    return {
      name: 'DevicesMediaUsageReportModel',
      defaultOrderColumn: 'name',
      defaultOrderDirection: 'ASC',
      loadCount: () => this.loadCount(),
      isMultiSelectable: false,
      keyColumn: 'id',
      defaultDateRangeFn: () => this.getCurrentUTCDateRangePeriod(),
      applyRequestDateRange: (...args) => this.applyRequestDateRange(...args),
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.devices.grid.device-name',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'model',
          label: 'tsp-admin.devices.grid.brand-and-model',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'identifier',
          label: 'tsp-admin.devices.grid.imei-serial',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'video_channels_number',
          label: 'tsp-admin.devices.media-usage.grid.cameras',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'fleet_name',
          label: 'tsp-admin.devices.grid.fleet',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'media_files_size_gb',
          label: 'tsp-admin.devices.media-usage.grid.media-storage',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'media_traffic_gb',
          label: 'tsp-admin.devices.media-usage.grid.media-traffic',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'media_stream_duration_seconds',
          label: 'tsp-admin.devices.media-usage.grid.live-stream-duration',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'media_stream_traffic_gb',
          label: 'tsp-admin.devices.media-usage.grid.live-stream-traffic',
          type: DataGridColumnType.Template,
          sortable: true,
        },
      ],
    }
  }

  protected getCurrentUTCDateRangePeriod(): DateRange {
    const now = new Date(Date.now())
    const month = now.getUTCMonth()
    const year = now.getUTCFullYear()
    const beginDate = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0))

    const daysInMonth = new Date(
      month + 1 === 12 ? year + 1 : year,
      month + 1 === 12 ? 0 : month + 1,
      0,
    ).getDate()

    let endDate = new Date(Date.UTC(year, month, daysInMonth, 23, 59, 59, 999))
    if (endDate > now) {
      endDate = now
    }

    return [beginDate, endDate]
  }

  protected applyRequestDateRange(
    queryProps: DataQueryProps,
    dateRange?: DateRange | PreDefinedRange,
    defaultDateRange?: PreDefinedRange,
    defaultDateRangeFn?: () => DateRange | PreDefinedRange,
  ) {
    const range =
      Array.isArray(dateRange) && dateRange[0] && dateRange[1]
        ? dateRange
        : this.getCurrentUTCDateRangePeriod()
    const beginDate = range[0]
    const endDate = range[1]

    const filters = queryProps?.filter ?? {}
    filters['interval'] = {
      gte: beginDate,
      lte: endDate,
    }

    queryProps.filter = filters
  }

  protected async loadPage(props: DataQueryProps): Promise<TspVideoDeviceTrafficReportData[]> {
    return this.api.tsp.devices.mediaTrafficReportList(props)
  }
}
