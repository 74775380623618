<p-menubar #menubarRef [model]="$any(menuItems$ | async)">
  <ng-template pTemplate="start">
    <div class="logo-wrapper">
      <img alt="" src="assets/images/logo-sm.svg" />
    </div>
  </ng-template>

  <ng-template let-item let-root="root" pTemplate="item">
    @if (!menubarRef.getItemProp(item, 'routerLink')) {
      <a
        [attr.href]="menubarRef.getItemProp(item, 'url')"
        [attr.data-automationid]="menubarRef.getItemProp(item, 'automationId')"
        [attr.data-pc-section]="'action'"
        [target]="menubarRef.getItemProp(item, 'target')"
        [ngClass]="{
          'p-menuitem-link': true,
          'p-disabled': menubarRef.getItemProp(item, 'disabled'),
        }"
        [attr.tabindex]="-1"
        pRipple
      >
        <span
          *ngIf="menubarRef.getItemProp(item, 'icon')"
          class="p-menuitem-icon"
          [ngClass]="menubarRef.getItemProp(item, 'icon')"
          [ngStyle]="menubarRef.getItemProp(item, 'iconStyle')"
          [attr.data-pc-section]="'icon'"
          [attr.tabindex]="-1"
        >
        </span>
        <!-- Custom icon rendering -->
        @if (item.iconType) {
          <app-dynamic-host
            [componentInputs]="{ color: 'var(--icon-color)', height: 24, width: 24 }"
            [componentType]="item.iconType"
            class="p-menuitem-icon"
          ></app-dynamic-host>
        }
        <span
          *ngIf="menubarRef.getItemProp(item, 'escape'); else htmlLabel"
          class="p-menuitem-text text-14 fw-500"
          [attr.data-pc-section]="'label'"
          [id]="$any(menubarRef).getItemLabelId(item)"
        >
          {{ menubarRef.getItemLabel(item) }}
        </span>
        <ng-template #htmlLabel>
          <span
            class="p-menuitem-text text-14 fw-500"
            [innerHTML]="menubarRef.getItemLabel(item)"
            [attr.data-pc-section]="'label'"
            [id]="$any(menubarRef).getItemLabelId(item)"
          ></span>
        </ng-template>
        <span
          class="p-menuitem-badge"
          *ngIf="menubarRef.getItemProp(item, 'badge')"
          [ngClass]="menubarRef.getItemProp(item, 'badgeStyleClass')"
          >{{ menubarRef.getItemProp(item, 'badge') }}</span
        >
        <ng-container *ngIf="menubarRef.isProcessedItemGroup(item)">
          <ng-container *ngIf="!menubarRef.submenuIconTemplate">
            <AngleDownIcon
              [styleClass]="'p-submenu-icon'"
              *ngIf="root"
              [attr.data-pc-section]="'submenuicon'"
            />
            <AngleRightIcon
              [styleClass]="'p-submenu-icon'"
              *ngIf="!root"
              [attr.data-pc-section]="'submenuicon'"
            />
          </ng-container>
        </ng-container>
      </a>
    } @else {
      <a
        [routerLink]="menubarRef.getItemProp(item, 'routerLink')"
        [attr.tabindex]="-1"
        [attr.data-pc-section]="'action'"
        [queryParams]="menubarRef.getItemProp(item, 'queryParams')"
        [routerLinkActive]="'p-menuitem-link-active'"
        [routerLinkActiveOptions]="
          menubarRef.getItemProp(item, 'routerLinkActiveOptions') || { exact: false }
        "
        [target]="menubarRef.getItemProp(item, 'target')"
        [ngClass]="{
          'p-menuitem-link': true,
          'p-disabled': menubarRef.getItemProp(item, 'disabled'),
        }"
        [fragment]="menubarRef.getItemProp(item, 'fragment')"
        [queryParamsHandling]="menubarRef.getItemProp(item, 'queryParamsHandling')"
        [preserveFragment]="menubarRef.getItemProp(item, 'preserveFragment')"
        [skipLocationChange]="menubarRef.getItemProp(item, 'skipLocationChange')"
        [replaceUrl]="menubarRef.getItemProp(item, 'replaceUrl')"
        [state]="menubarRef.getItemProp(item, 'state')"
        pRipple
      >
        <span
          class="p-menuitem-icon"
          *ngIf="menubarRef.getItemProp(item, 'icon')"
          [ngClass]="menubarRef.getItemProp(item, 'icon')"
          [ngStyle]="menubarRef.getItemProp(item, 'iconStyle')"
          [attr.data-pc-section]="'icon'"
          [attr.tabindex]="-1"
        ></span>
        <!-- Custom icon rendering -->
        @if (item.iconType) {
          <app-dynamic-host
            [componentInputs]="{ color: 'var(--icon-color)', height: 24, width: 24 }"
            [componentType]="item.iconType"
            class="p-menuitem-icon"
          ></app-dynamic-host>
        }
        <span
          class="p-menuitem-text text-14 fw-500"
          *ngIf="menubarRef.getItemProp(item, 'escape'); else htmlRouteLabel"
        >
          {{ menubarRef.getItemLabel(item) }}
        </span>
        <ng-template #htmlRouteLabel
          ><span
            class="p-menuitem-text text-14 fw-500"
            [innerHTML]="menubarRef.getItemLabel(item)"
            [attr.data-pc-section]="'label'"
          ></span
        ></ng-template>
        <span
          class="p-menuitem-badge"
          *ngIf="menubarRef.getItemProp(item, 'badge')"
          [ngClass]="menubarRef.getItemProp(item, 'badgeStyleClass')"
          >{{ menubarRef.getItemProp(item, 'badge') }}</span
        >
        <ng-container *ngIf="menubarRef.isProcessedItemGroup(item)">
          <ng-container *ngIf="!menubarRef.submenuIconTemplate">
            <AngleDownIcon
              [styleClass]="'p-submenu-icon'"
              [attr.data-pc-section]="'submenuicon'"
              *ngIf="root"
            />
            <AngleRightIcon
              [styleClass]="'p-submenu-icon'"
              [attr.data-pc-section]="'submenuicon'"
              *ngIf="!root"
            />
          </ng-container>
        </ng-container>
      </a>
    }
  </ng-template>

  <ng-template pTemplate="end">
    <div class="flex gap-16">
      <div class="feedback-wrapper">
        <app-feedback-button [isCompact]="true" />
      </div>
      <div class="user-wrapper flex">
        <app-avatar
          (click)="personalMenuRef.toggle($event)"
          [photo]="(profile.state | async)?.photo"
          class="clickable"
        ></app-avatar>

        <app-icon-chevron
          (click)="personalMenuRef.toggle($event)"
          class="clickable"
          direction="down"
        ></app-icon-chevron>
      </div>
    </div>
  </ng-template>
</p-menubar>

<app-personal-menu
  #personalMenuRef
  (languageClicked)="langSwitchRef.open()"
  (settingsClicked)="userSettingsRef.open()"
></app-personal-menu>

<app-lang-switch-dialog #langSwitchRef></app-lang-switch-dialog>

<app-user-settings-dialog #userSettingsRef></app-user-settings-dialog>
