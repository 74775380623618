import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-favorites',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 9V19.996C4 20.755 4.807 21.24 5.474 20.877C7.103 19.988 9.5 18.681 9.5 18.681C9.5 18.681 11.897 19.989 13.526 20.877C14.193 21.24 15 20.755 15 19.996V9C15 7.895 14.105 7 13 7H6C4.896 7 4 7.895 4 9Z"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        [attr.stroke]="color"
      />
      <path
        d="M9 7V5C9 3.895 9.895 3 11 3H18C19.105 3 20 3.895 20 5V16.043C20 16.802 19.188 17.284 18.521 16.921L15 15"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        [attr.stroke]="color"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FavoritesIconComponent extends BaseIconComponent {}
