import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Resource } from '@ti-platform/contracts'
import { injectDestroy$, selectState } from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { DevicesMediaUsageReportModel } from '../../models'

@Component({
  selector: 'app-tsp-media-usage-report',
  templateUrl: 'media-usage-report.component.html',
  styleUrls: ['media-usage-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUsageReportComponent implements OnInit {
  protected readonly destroy$ = injectDestroy$()
  protected readonly router = inject(Router)
  protected readonly model = inject(DevicesMediaUsageReportModel)
  protected readonly state = selectState(DevicesMediaUsageReportModel)
  protected readonly deviceService = inject(DeviceService)

  protected readonly Action = Action
  protected readonly Resource = Resource

  public async ngOnInit() {
    this.model.updateCurrentDateRange()
  }

  protected goBack() {
    this.router.navigate(['devices'])
  }
}
