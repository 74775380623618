import { AbstractControl, ValidationErrors } from '@angular/forms'
import { COUNTRY_CODES } from '@ti-platform/contracts'

export const countryValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value
  if (!value || !COUNTRY_CODES.includes(value)) {
    return { country: true }
  }
  return null
}
