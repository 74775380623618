import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, FleetForm, FleetView, Resource } from '@ti-platform/contracts'
import { COUNTRY_CODES } from '@ti-platform/contracts/api/country-codes'
import {
  CommandsListModel,
  CopyMediaConfigDevicesListModel,
  DevicesListModel,
} from '@ti-platform/tsp-admin/app/devices'
import { FleetAccountsModel } from '@ti-platform/tsp-admin/app/fleet/models/fleets.model'
import {
  provideTspFleetUsersModel,
  TspFleetUsersModel,
} from '@ti-platform/tsp-admin/app/users/models/users.model'
import { AccessControl, injectDestroy$, Memoize, selectState } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MessageService } from 'primeng/api'
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  switchMap,
  takeUntil,
} from 'rxjs'
import { CountyOptionsProvider, PricingPlansProvider } from '../../models'

@Component({
  selector: 'app-tsp-fleet-account-view',
  templateUrl: 'feet-account-view.component.html',
  styleUrls: ['feet-account-view.component.scss'],
  providers: [
    provideTspFleetUsersModel(),
    DevicesListModel,
    CommandsListModel,
    CopyMediaConfigDevicesListModel,
  ],
})
export class FleetAccountViewComponent implements OnInit, OnDestroy {
  @Output() readonly editFleet = new EventEmitter<FleetView>()

  protected readonly router = inject(Router)
  protected readonly accessControl = inject(AccessControl)
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected readonly messageService = inject(MessageService)
  protected readonly languageService = inject(LanguageService)
  protected readonly pricingPlansProvider = inject(PricingPlansProvider)
  protected readonly countyOptionsProvider = inject(CountyOptionsProvider)
  protected readonly destroy$ = injectDestroy$()

  protected readonly usersModel = inject(TspFleetUsersModel)
  protected readonly devicesModel = inject(DevicesListModel)

  protected readonly model = inject(FleetAccountsModel)
  protected readonly state = selectState(FleetAccountsModel)

  protected readonly showEditForm$ = new BehaviorSubject<boolean>(false)
  protected readonly updatingFleet$ = new BehaviorSubject<boolean>(false)

  protected readonly devicesDisplayColumns = [
    'name',
    'model',
    'identifier',
    'lastActive',
    'actions',
  ]

  protected readonly consumptionLabels = {
    0: 'measurement-system.lkm',
    1: 'measurement-system.mpg',
    2: 'measurement-system.km-gal',
  }

  protected readonly usersDisplayColumns = ['user', 'role', 'lastActivity', 'loginAs']

  protected readonly Action = Action
  protected readonly Resource = Resource

  @Memoize()
  protected get formattedAddress$(): Observable<string> {
    return combineLatest([this.languageService.current$, this.model.state.selectedItem$]).pipe(
      switchMap(async ([, fleet]) => {
        if (!fleet) {
          return ''
        }
        const country = COUNTRY_CODES.includes(fleet.country)
          ? await this.languageService.translate(`countries.${fleet.country}`)
          : fleet.country
        return [fleet.address, fleet.city, fleet.region, fleet.zipCode, country]
          .filter((el) => !!el)
          .join(', ')
      }),
    )
  }

  async ngOnInit() {
    const selectedItem = await firstValueFrom(this.state.selectedItem$)

    if (selectedItem) {
      this.usersModel.filterByFleet(selectedItem.id)
      this.devicesModel.filterByFleet(selectedItem)
    } else {
      this.activatedRoute.paramMap.subscribe(async (params) => {
        try {
          const id = parseInt(params.get('id') || '0')
          if (id) {
            await this.refreshSelectedFleet(id)
          }
        } catch (e) {
          return this.goBack()
        }
      })
    }

    this.devicesModel.limitExportColumns(['name', 'model', 'identifier', 'lastActive'])
    // needed here so that in edit form there were selected option to show right label for selected country
    this.countyOptionsProvider.countryOptions$.pipe(takeUntil(this.destroy$)).subscribe()
  }

  ngOnDestroy() {
    this.model.unselect()
  }

  async refreshSelectedFleet(id: number) {
    const fleet = await this.model.selectById(id)
    this.usersModel.filterByFleet(id)
    this.devicesModel.filterByFleet(fleet)
  }

  goBack() {
    this.model.unselect()
    return this.router.navigate(['accounts'])
  }

  protected formatFleetDevicesCount(fleet: FleetView) {
    return !fleet.devicesCount || fleet.devicesCount < 1 ? 0 : fleet.devicesCount
  }

  protected async updateFleet(fleet: FleetForm) {
    this.updatingFleet$.next(true)

    if (await this.model.updateFleet(fleet)) {
      this.showEditForm$.next(false)
    }

    this.updatingFleet$.next(false)
  }

  protected async activateFleet(fleet: FleetView) {
    await this.model.activateFleet(fleet)
  }

  protected async deleteFleet(fleet: FleetView) {
    if (await this.model.deleteFleet(fleet)) {
      return this.goBack()
    }
  }

  protected async suspendFleet(fleet: FleetView) {
    if (await this.model.suspendFleet(fleet)) {
      return this.goBack()
    }
  }
}
