<div class="filter-bar-wrapper">
  <div class="date">
    @if (showDatePicker) {
      <app-date-range-picker-dialog
        [btnClass]="'p--16'"
        [dateRange]="dateRange ?? undefined"
        (dateRangeChange)="dateRangeChanged.emit($event)"
        class="controls-button"
      ></app-date-range-picker-dialog>
    }
  </div>

  @if (showFilters) {
    <app-filter-button
      [filtersCount]="filtersCount"
      [sortingApplied]="defaultSortOrder?.order !== gridSortOrder?.order ||
  defaultSortOrder?.column !== gridSortOrder?.column"
      (click)="filterPanelService.openPanel()"
    ></app-filter-button>
  }
</div>
