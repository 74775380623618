import { WhiteLabelSettings } from '@ti-platform/contracts'
import { ApiRoute } from '../../../services/api.route'

export class WhiteLabelRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/white-label'
  }

  public getCurrentUserSettings() {
    return this.get<WhiteLabelSettings>().endpoint('settings')
  }

  public getSettingsByDomainName(domain: string) {
    return this.get<WhiteLabelSettings>().endpoint(`settings/${domain}`)
  }
}
