export const stripEmptyKeys = <T = Record<string, unknown>>(
  target: T,
  preserveEmptyStrings = false,
): any => {
  for (const key in target) {
    if (
      target[key] === undefined ||
      target[key] === null ||
      (target[key] === '' && !preserveEmptyStrings)
    ) {
      delete target[key]
    }
  }
  return target
}
