import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-filters-active',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 6.82857H16.25"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8631 10.9952H5.14084"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5266 15.507H13.471"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="12.2708"
        y="1.43746"
        width="8.45833"
        height="8.45833"
        rx="4.22917"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <rect
        x="12.2708"
        y="1.43746"
        width="8.45833"
        height="8.45833"
        rx="4.22917"
        stroke="#F9F5FF"
        style="stroke:#F9F5FF;stroke:color(display-p3 0.9765 0.9608 1.0000);stroke-opacity:1;"
        stroke-width="1.45833"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FiltersActiveIconComponent extends BaseIconComponent {}
