import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-lock-opened',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1654 7.16673H5.83203H3.83203V11.8333V13.1666C3.83203 13.5202 3.97251 13.8594 4.22256 14.1094C4.4726 14.3595 4.81174 14.5 5.16536 14.5H11.832C12.1857 14.5 12.5248 14.3595 12.7748 14.1094C13.0249 13.8594 13.1654 13.5202 13.1654 13.1666V7.16673H11.1654ZM11.1654 7.16673V5.50007C11.1654 3.84321 9.82222 2.50007 8.16536 2.50007V2.50007C7.38759 2.50007 6.12594 3.28384 5.96137 4.4964"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LockOpenedIconComponent extends BaseIconComponent {
  @Input() override height = 16
  @Input() override width = 16
}
