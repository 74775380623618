import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map, ReplaySubject, Subject } from 'rxjs'
import { Hub } from 'aws-amplify/utils'
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import { UserAttributes } from '../contracts'
import {
  BrowserSessionStorage,
  formatUserLabel,
  injectDestroy$,
  Memoize,
} from '@ti-platform/web/common'
import { LanguageCode } from '@ti-platform/contracts'
import { AuthService } from './auth.service'
import { LOGIN_AS_SESSION } from '@ti-platform/web/auth/constants'

@Injectable({
  providedIn: 'root',
})
export class AuthSession {
  protected readonly router = inject(Router)
  protected readonly authService = inject(AuthService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly loginAsSession = inject(LOGIN_AS_SESSION, { optional: true })
  protected readonly destroy$ = injectDestroy$()

  protected readonly isAuthenticated$ = new ReplaySubject<boolean>(1)
  protected readonly currentUserAttributes$ = new ReplaySubject<UserAttributes | null>(1)

  // Auth events
  protected readonly onSignedIn$ = new Subject<void>()
  protected readonly onSignedOut$ = new Subject<void>()

  public constructor() {
    this.bootstrapAuthStreams()
    this.subscribeToAuthEvents()

    // Temporary solution for resetting app state
    this.state.onSignedOut$.subscribe(async () => {
      await this.router.navigate(['/', 'sign-in'])
      // Reload the page later to ensure onSignOut$ listeners executed
      setTimeout(() => window.location.reload(), 150)
    })
  }

  @Memoize()
  public get state() {
    return {
      isAuthenticated$: this.isAuthenticated$.asObservable(),
      currentUserAttributes$: this.currentUserAttributes$.asObservable(),
      onSignedIn$: this.onSignedIn$.asObservable(),
      onSignedOut$: this.onSignedOut$.asObservable(),
      currentUserLabel$: this.createCurrentUserLabelStream(),
    }
  }

  public async isLoggedIn() {
    const session = await fetchAuthSession()
    return !!session?.tokens
  }

  public async getAccessToken(): Promise<string> {
    const session = await fetchAuthSession()
    return session?.tokens?.accessToken?.toString() ?? ''
  }

  public async getIdToken(): Promise<string> {
    const session = await fetchAuthSession()
    return session?.tokens?.idToken?.toString() ?? ''
  }

  public async getUserAttributes(): Promise<UserAttributes> {
    try {
      const attributes = await fetchUserAttributes()
      return attributes
        ? {
            sub: attributes.sub ?? '',
            email: attributes.email ?? '',
            emailVerified: attributes.email_verified === 'true',
            name: attributes.name ?? '',
            tspId: parseInt(attributes['custom:tsp_id'] ?? '0'),
            fleetId: parseInt(attributes['custom:fleet_id'] ?? '0'),
            role: parseInt(attributes['custom:role'] ?? '-1'),
            phoneNumber: attributes.phone_number ?? '',
            phoneNumberVerified: attributes.phone_number_verified === 'true',
            photo: attributes['custom:photo'] ?? '',
            language: (attributes['custom:language'] as LanguageCode) ?? LanguageCode.English,
          }
        : attributes
    } catch (error) {
      console.error(`Cannot fetch user attributes`, error)
      await this.authService.signOut()
    }
    return undefined as unknown as UserAttributes
  }

  public async bootstrapAuthStreams() {
    const session = await fetchAuthSession()
    if (session?.tokens) {
      this.isAuthenticated$.next(true)
      const attributes = await this.getUserAttributes()
      if (attributes?.sub) {
        this.currentUserAttributes$.next(attributes)
      }
    }
  }

  private subscribeToAuthEvents() {
    const channel = new BroadcastChannel('TI_AUTH_EVENTS')
    const onMessage = (e: MessageEvent) => {
      const data = e.data ? JSON.parse(e.data) : null
      if (data) {
        onAuthStateChanges(data)
      }
    }
    const onAuthStateChanges = (payload: { event: string }) => {
      switch (payload.event) {
        case 'signedIn':
          console.info('user have been signedIn successfully.', payload)
          this.onSignedIn$.next()
          this.isAuthenticated$.next(true)
          this.getUserAttributes().then((currentUser) =>
            this.currentUserAttributes$.next(currentUser),
          )
          break
        case 'signedOut':
          console.warn(`SIGNED OUT!`)
          console.info('user have been signedOut successfully.', payload)
          this.onSignedOut$.next()
          this.isAuthenticated$.next(false)
          this.currentUserAttributes$.next(null)
          break
        case 'tokenRefresh':
          console.info('auth tokens have been refreshed.', payload)
          break
        case 'tokenRefresh_failure':
          console.warn('failure while refreshing auth tokens.', payload)
          break
        case 'signInWithRedirect':
          console.info('signInWithRedirect API has successfully been resolved.', payload)
          break
        case 'signInWithRedirect_failure':
          console.warn('failure while trying to resolve signInWithRedirect API.', payload)
          break
        case 'customOAuthState':
          console.info('custom state returned from CognitoHosted UI', payload)
          break
      }
    }

    const stopHubCb = Hub.listen('auth', ({ payload }) => {
      onAuthStateChanges(payload)

      if (!this.loginAsSession) {
        // Broadcast the message to all opened tabs
        channel.postMessage(JSON.stringify(payload))
      }
    })

    if (!this.loginAsSession) {
      channel.addEventListener('message', onMessage)
    }

    this.destroy$.subscribe(() => {
      stopHubCb()
      channel.removeEventListener('message', onMessage)
      channel.close()
    })
  }

  private createCurrentUserLabelStream() {
    return this.currentUserAttributes$.pipe(map((data) => formatUserLabel(data?.name)))
  }
}
