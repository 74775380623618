import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-timeline-handles',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        width="5.78571"
        height="18"
        rx="1.28571"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M6.24944 7.28564L4.53516 8.99993L6.24944 10.7142"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="0.757653"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="16.6445"
        y="18"
        width="5.78571"
        height="18"
        rx="1.28571"
        transform="rotate(-180 16.6445 18)"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M12.8951 10.7144L14.6094 9.00007L12.8951 7.28578"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="0.757653"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TimelineHandlesIconComponent extends BaseIconComponent {
  override height = 18
  override width = 18
}
