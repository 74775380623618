<p-dialog
  [(visible)]="opened"
  [modal]="true"
  class="dialog-primary w1400 headless"
  closable="false"
  [focusOnShow]="false"
>
  <ng-template pTemplate="header"></ng-template>
  <div class="dialog-content">
    <div class="header flex">
      <div class="header-title">
        @if (commandAssignedStatus) {
          {{ 'tsp-admin.devices.commands.bulk.unassign.header' | translate }}
        } @else {
          {{ 'tsp-admin.devices.commands.bulk.assign.header' | translate }}
        }
      </div>

      <div class="header-icon-wrapper" [class]="{ disabled: isExecuting$ | async }">
        <i class="pi pi-times header-icon clickable" (click)="onCloseClick()"></i>
      </div>
    </div>

    <div class="body">
      <div class="overlay" [class]="{ disabled: isExecuting$ | async }">
        <p-progressSpinner styleClass="progress" strokeWidth="3" animationDuration="1s" />
      </div>
      <!-- command part -->
      <div class="body-part command-part">
        <!-- description -->
        <div class="body-description text-14 fw-400">
          @if (commandAssignedStatus) {
            {{ 'tsp-admin.devices.commands.bulk.unassign.description.command' | translate }}:
          } @else {
            {{ 'tsp-admin.devices.commands.bulk.assign.description.command' | translate }}:
          }
        </div>

        <!-- command data -->
        <div class="command-description">
          <div class="command-description__item">
            <div class="command-description__label text-11 fw-500">
              {{ 'tsp-admin.devices.grid.brand-and-model' | translate }}
            </div>
            <div class="command-description__value text-14 fw-500">
              {{ command?.deviceModel }}
            </div>
          </div>
          <div class="command-description__item">
            <div class="command-description__label text-11 fw-500">
              {{ 'tsp-admin.devices.commands.grid.name' | translate }}
            </div>
            <div class="command-description__value text-14 fw-500">
              {{ command?.name }}
            </div>
          </div>
          <div class="command-description__item">
            <div class="command-description__label text-11 fw-500">
              {{ 'tsp-admin.devices.commands.grid.config' | translate }}
            </div>
            <div class="command-description__value text-14 fw-500">
              {{ configFields }}
            </div>
          </div>
        </div>
      </div>

      <!-- grid part -->
      <div class="body-part grid-part">
        <!-- description -->
        <div class="body-description text-14 fw-400">
          @if (commandAssignedStatus) {
            {{ 'tsp-admin.devices.commands.bulk.unassign.description.grid' | translate }}:
          } @else {
            {{ 'tsp-admin.devices.commands.bulk.assign.description.grid' | translate }}:
          }
        </div>

        <!-- controls -->
        <div class="grid-controls">
          <div class="search-container p-fluid">
            <p-iconField class="h--40">
              <p-inputIcon>
                <app-icon-search [height]="20" [width]="20"></app-icon-search>
              </p-inputIcon>
              <input
                (ngModelChange)="model.setSearch($event)"
                [ngModel]="(model.state.search$ | async) ?? false"
                [placeholder]="'tsp-admin.devices.commands.bulk.search-devices' | translate"
                pInputText
                type="text"
              />

              @if (model.state.search$ | async) {
                <p-inputIcon>
                  <app-icon-close
                    (click)="model.setSearch('')"
                    [color]="'var(--color-gray-500)'"
                    [height]="22"
                    [width]="22"
                    class="clickable"
                  ></app-icon-close>
                </p-inputIcon>
              }
            </p-iconField>
          </div>
          @if ((model.state.multiSelectedItems$ | async)?.length) {
            <app-closable-tag
              styleClass="bg-200"
              [label]="
                'tsp-admin.devices.bulk-actions.device-selected'
                  | translate: { val: (model.state.multiSelectedItems$ | async)?.length }
              "
              (closeClicked)="model.resetMultiSelectedItems()"
            ></app-closable-tag>
          }
        </div>

        <!-- grid -->
        <div class="table-wrapper">
          <app-data-grid
            [data]="(model.state.items$ | async) ?? []"
            [columns]="(model.state.columns$ | async) ?? []"
            [sortOrder]="(model.state.gridSortOrder$ | async) || undefined"
            [isLoading]="(model.state.isLoading$ | async) ?? false"
            [paginationEnabled]="(model.state.canLoadNextPage$ | async) ?? false"
            [noDataTitle]="noDataTitleKey | translate"
            [noDataDescription]="noDataDescriptionKey | translate"
            [noDataIconTemplate]="noDataIconTmpl"
            (sortUpdated)="model.setOrder($event)"
            (loadMoreClicked)="model.loadNextPage()"
            [isMultiSelectable]="model.isMultiSelectable"
            [keyColumn]="model.keyColumn"
            [multiSelection]="(model.state.multiSelectedItems$ | async) ?? []"
            (rowSelected)="model.multiSelectItem($event)"
            (rowUnselected)="model.multiUnselectItem($event)"
          ></app-data-grid>
        </div>
      </div>
    </div>
    <div class="footer">
      <p-button
        (click)="onCancel()"
        [severity]="'secondary'"
        [disabled]="isExecuting$ | async"
        class="cancel text-14 fw-600 w50 p--8 h--40 footer-button"
      >
        {{ 'button.cancel' | translate }}
      </p-button>

      <p-button
        (click)="onConfirm()"
        [disabled]="(isExecuting$ | async) || !(model.state.multiSelectedItems$ | async)?.length"
        class="submit text-14 fw-600 w50 h--40 footer-button"
        type="submit"
      >
        @if (commandAssignedStatus) {
          {{ 'tsp-admin.devices.commands.bulk.unassign.confirm-button' | translate }}
        } @else {
          {{ 'tsp-admin.devices.commands.bulk.assign.confirm-button' | translate }}
        }
      </p-button>
    </div>
  </div>
</p-dialog>

<ng-template #noDataIconTmpl>
  @if (!!(model.state.search$ | async)) {
    <app-icon-no-search [width]="32" [height]="32"></app-icon-no-search>
  } @else {
    <app-icon-no-devices></app-icon-no-devices>
  }
</ng-template>
