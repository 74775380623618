import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-device',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0037 16.0017L20.1564 5.83147C20.0694 4.79404 19.203 3.9967 18.1626 3.9967H5.83743C4.797 3.9967 3.93064 4.79404 3.8436 5.83147L2.99625 16.0017"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0037 16.0017C21.0037 14.8962 20.1084 14.0009 19.0029 14.0009H4.99708C3.89162 14.0009 2.99625 14.8962 2.99625 16.0017V19.0029C2.99625 20.1084 3.89162 21.0038 4.99708 21.0038H19.0029C20.1084 21.0038 21.0037 20.1084 21.0037 19.0029V16.0017Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5019 17.5023H7.49811"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DeviceIconComponent extends BaseIconComponent {}
