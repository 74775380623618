import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core'
import { chunk as lChunk } from 'lodash'

import { TspCommandListData } from '@ti-platform/contracts'
import { CommandDevicesListModel } from '@ti-platform/tsp-admin/app/devices/models'
import { injectDestroy$ } from '@ti-platform/web/common'
import { BehaviorSubject } from 'rxjs'

interface AssignUnassignResultData {
  all: number
  success: number
}

@Component({
  selector: 'app-bulk-command-devices-dialog',
  templateUrl: 'bulk-command-devices-dialog.component.html',
  styleUrl: 'bulk-command-devices-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkCommandDevicesDialogComponent {
  protected readonly destroy$ = injectDestroy$()
  protected readonly model = inject(CommandDevicesListModel)
  protected readonly isExecuting$ = new BehaviorSubject<boolean>(false)

  @Output() cancelEvent = new EventEmitter<void>()
  @Output() confirmEvent = new EventEmitter<AssignUnassignResultData>()

  @Input() command: TspCommandListData | null = null
  @Input() commandAssignedStatus: boolean | null = null
  protected _opened = false

  get opened() {
    return this._opened
  }

  @Input()
  set opened(value: boolean) {
    this._opened = value
    if (value) {
      this.model.setSelectedCommandAndStatus(null, null)
      setTimeout(() => {
        this.model.setSelectedCommandAndStatus(this.command, this.commandAssignedStatus)
      }, 200)
    } else {
      this.model.setSelectedCommandAndStatus(null, null)
      this.model.resetMultiSelectedItems()
    }
  }

  get configFields(): string {
    const command = (this.command || {}) as Record<string, any>
    return command?.configFields || ''
  }

  protected get noDataTitleKey() {
    let result = 'tsp-admin.devices.commands.bulk.no-devices'
    if (this.model.isActiveSearch) {
      result = 'search.no-data-title'
    }
    return result
  }

  protected get noDataDescriptionKey() {
    let result = ''
    if (this.model.isActiveSearch) {
      result = 'search.no-data-description'
    } else if (this.commandAssignedStatus) {
      result = 'tsp-admin.devices.commands.bulk.unassign.no-items'
    } else {
      result = 'tsp-admin.devices.commands.bulk.assign.no-items'
    }
    return result
  }

  public onCancel() {
    if (!this.isExecuting$.value) {
      this.cancelEvent.emit()
    }
  }

  protected onCloseClick() {
    this.onCancel()
  }

  protected async executeAction(deviceIds: string[], resultData: Record<string, boolean>) {
    const commandId = this.command?.id as string
    let result: string[] = []
    try {
      if (this.commandAssignedStatus) {
        result = await this.model.unassignCommandFromDevices(commandId, deviceIds)
      } else {
        result = await this.model.assignCommandToDevices(commandId, deviceIds)
      }
    } catch (e) {
      console.error(e)
    }
    deviceIds.forEach((deviceId) => {
      resultData[deviceId] = result.includes(deviceId)
    })
  }

  protected async processAll(): Promise<AssignUnassignResultData> {
    const deviceIds: string[] = this.model.selectedItems.map((device) => device.id as string)
    const result: AssignUnassignResultData = {
      all: deviceIds.length,
      success: 0,
    }
    const resultData: Record<string, boolean> = {}
    deviceIds.forEach((deviceId) => {
      resultData[deviceId] = false
    })
    const chunkSize = 50
    const devicesChunks = lChunk(deviceIds, chunkSize)
    const promises: Promise<void>[] = []
    devicesChunks.forEach((chunkDeviceIds) => {
      promises.push(this.executeAction(chunkDeviceIds, resultData))
    })
    await Promise.all(promises)
    const success = Object.values(resultData).filter((value) => !!value).length
    result.success = success

    return result
  }

  protected async onConfirm() {
    this.isExecuting$.next(true)
    const result = await this.processAll()
    this.isExecuting$.next(false)
    this.confirmEvent.emit(result)
  }
}
