<div class="accordion-wrapper">
  <div class="header" (click)="toggleCollapsed()">
    <div class="text-16 fw-600 flex--row items-center">
      {{ title }}
      @if (count) {
        <div class="count">
          {{ count }}
        </div>
      }
    </div>
    @if (count) {
      <div class="clear" (click)="clearButtonPress.emit()">
        {{ 'filter-panel.clear' | translate }}
      </div>
    }
  </div>
  <div
    class="content text-14 fw-400"
    *ngIf="!isCollapsed"
    [@collapseExpand]="isCollapsed ? 'collapsed' : 'expanded'"
  >
    <ng-content />
  </div>
</div>
