import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-finish',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5875 3.82499C20.4618 3.76495 20.3221 3.74022 20.1834 3.75343C20.0447 3.76664 19.9121 3.8173 19.8 3.89999C17.1465 5.88749 14.8973 4.93124 12.3 3.81599C9.70275 2.70074 6.64725 1.38749 3.3 3.89999C3.20685 3.96985 3.13125 4.06044 3.07918 4.16458C3.02711 4.26873 3 4.38356 3 4.49999V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21C3.94891 21 4.13968 20.921 4.28033 20.7803C4.42098 20.6397 4.5 20.4489 4.5 20.25V16.1347C7.0215 14.4375 9.1965 15.366 11.7 16.4437C13.2277 17.091 14.8687 17.7937 16.6312 17.7937C17.925 17.7937 19.284 17.4187 20.7 16.35C20.7926 16.2796 20.8678 16.1889 20.9198 16.0849C20.9718 15.9809 20.9993 15.8663 21 15.75V4.49999C20.9989 4.36066 20.9599 4.22426 20.8872 4.10536C20.8146 3.98647 20.711 3.88956 20.5875 3.82499ZM14.625 15.966V10.95C12.8535 10.4437 11.1465 9.45974 9.375 8.95349V13.9785C7.8465 13.5847 6.225 13.5285 4.5 14.4V9.45C6.216 8.45624 7.81875 8.51249 9.375 8.95349V4.28474C10.1662 4.54499 10.9425 4.84874 11.7 5.19374C12.6375 5.58749 13.6125 6.00974 14.625 6.27224V10.95C16.1812 11.391 17.7847 11.4472 19.5 10.4535V15.366C17.784 16.5187 16.2285 16.4625 14.625 15.966Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FinishIconComponent extends BaseIconComponent {}
