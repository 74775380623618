import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-area',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5625 17.1874C16.5625 19.2171 11.5397 19.3749 10 19.3749C8.46031 19.3749 3.4375 19.2186 3.4375 17.1874C3.4375 15.8593 5.60906 15.338 7.43062 15.1346C7.59429 15.1182 7.7578 15.1671 7.88568 15.2705C8.01357 15.374 8.09553 15.5236 8.11379 15.6871C8.13205 15.8506 8.08513 16.0146 7.98322 16.1437C7.88131 16.2728 7.73261 16.3566 7.56938 16.3768C5.64094 16.5921 4.89156 17.0046 4.71469 17.1893C4.99063 17.4827 6.53125 18.1268 10 18.1268C13.4688 18.1268 15.0094 17.4839 15.2853 17.1893C15.1084 17.0052 14.3591 16.5927 12.4306 16.3768C12.2674 16.3566 12.1187 16.2728 12.0168 16.1437C11.9149 16.0146 11.868 15.8506 11.8862 15.6871C11.9045 15.5236 11.9864 15.374 12.1143 15.2705C12.2422 15.1671 12.4057 15.1182 12.5694 15.1346C14.3909 15.338 16.5625 15.8593 16.5625 17.1874ZM5.9375 4.68738C5.93659 3.91039 6.15852 3.14944 6.57696 2.49476C6.99541 1.84007 7.59282 1.31912 8.29837 0.993669C9.00391 0.668218 9.78798 0.551922 10.5576 0.658571C11.3272 0.76522 12.0502 1.09034 12.6406 1.59538C13.2311 2.10042 13.6643 2.7642 13.889 3.508C14.1136 4.25179 14.1203 5.04442 13.9081 5.79188C13.696 6.53934 13.2739 7.21029 12.692 7.72515C12.1101 8.24002 11.3927 8.57722 10.625 8.69675V16.8749C10.625 17.0406 10.5592 17.1996 10.4419 17.3168C10.3247 17.434 10.1658 17.4999 10 17.4999C9.83424 17.4999 9.67527 17.434 9.55806 17.3168C9.44085 17.1996 9.375 17.0406 9.375 16.8749V8.69675C8.41846 8.54724 7.54669 8.06127 6.91653 7.32628C6.28637 6.59128 5.93921 5.65553 5.9375 4.68738ZM7.5 3.43738C7.5 3.68461 7.57331 3.92628 7.71066 4.13184C7.84801 4.3374 8.04324 4.49762 8.27165 4.59223C8.50005 4.68684 8.75139 4.71159 8.99386 4.66336C9.23634 4.61513 9.45907 4.49608 9.63388 4.32126C9.8087 4.14645 9.92775 3.92372 9.97598 3.68124C10.0242 3.43877 9.99946 3.18743 9.90485 2.95902C9.81024 2.73062 9.65002 2.53539 9.44446 2.39804C9.2389 2.26069 8.99723 2.18738 8.75 2.18738C8.41848 2.18738 8.10054 2.31908 7.86612 2.5535C7.6317 2.78792 7.5 3.10586 7.5 3.43738Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class AreaIconComponent extends BaseIconComponent {
  @Input() override width = 20
  @Input() override height = 20
}
