import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { Menu } from 'primeng/menu'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export type DropdownSelectMenuItem<V = any> = MenuItem & { value: V }

@Component({
  selector: 'app-dropdown-select',
  templateUrl: 'dropdown-select.component.html',
  styleUrl: 'dropdown-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownSelectComponent<V = unknown> implements ControlValueAccessor {
  protected onChange: () => void = () => undefined
  protected onTouched: () => void = () => undefined

  protected touched = false
  protected disabled = false

  @Input() isCheckmarkVisible? = false
  @Input() value: V | null = null
  @Input() options: DropdownSelectMenuItem[] = []
  @Output() valueChange = new EventEmitter<V>()

  @ViewChild('menu') menu!: Menu

  protected selectOption(item: DropdownSelectMenuItem) {
    this.markAsTouched()
    this.writeValue(item.value)
    this.valueChange.emit(item.value)
  }

  protected markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  public toggle($event: MouseEvent) {
    this.menu.toggle($event)
  }

  public writeValue(value: V) {
    this.value = value
  }

  public registerOnChange(onChange: () => void) {
    this.onChange = onChange
  }

  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }
}
