export const addTouchListener = (target: HTMLElement, cb: () => void): (() => void) => {
  let active = false
  const touchStartListener = () => {
    active = true
  }
  const touchEndListener = () => {
    if (active) {
      cb()
    }
    active = false
  }
  const touchCancelListener = () => {
    active = false
  }

  target.addEventListener('touchstart', touchStartListener)
  target.addEventListener('touchend', touchEndListener)
  target.addEventListener('touchcancel', touchCancelListener)
  target.addEventListener('touchmove', touchCancelListener)

  return () => {
    target.removeEventListener('touchstart', touchStartListener)
    target.removeEventListener('touchend', touchEndListener)
    target.removeEventListener('touchcancel', touchCancelListener)
    target.removeEventListener('touchmove', touchCancelListener)
  }
}
