import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-geofence-bold',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.59667 11.8232C4.635 12.2132 2.5 13.3007 2.5 14.5841C2.5 16.1949 5.8575 17.5007 10 17.5007C14.1425 17.5007 17.5 16.1949 17.5 14.5841C17.5 13.3007 15.365 12.2132 12.4033 11.8232"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 6.52783C15 3.84366 12.7617 1.66699 10 1.66699C7.23833 1.66699 5 3.84366 5 6.52783C5 10.1737 10 14.167 10 14.167C10 14.167 15 10.1737 15 6.52783Z"
        [attr.fill]="color"
        [attr.stroke]="color"
        style="fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1792 5.48816C11.83 6.13903 11.83 7.1943 11.1792 7.84518C10.5283 8.49605 9.47301 8.49605 8.82214 7.84518C8.17127 7.1943 8.17127 6.13903 8.82214 5.48816C9.47301 4.83728 10.5283 4.83728 11.1792 5.48816Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class GeofenceBoldIconComponent extends BaseIconComponent {
  @Input() override height = 20
  @Input() override width = 20
}
