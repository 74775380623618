import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-pay-disable',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00133 11.1667C4.00133 12.6401 6.98533 13.8334 10.668 13.8334"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6667 20.5C14.6667 21.9733 17.6507 23.1667 21.3333 23.1667C25.016 23.1667 28 21.9733 28 20.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.668 20.5V25.8333C14.668 27.3067 17.652 28.5 21.3347 28.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9987 20.5V25.8333C27.9987 27.3067 25.0147 28.5 21.332 28.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6667 24.4999C6.98533 24.4999 4 23.3066 4 21.8333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 11.1667V21.8334"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.996 20.4999C27.9987 19.0266 25.012 17.8333 21.332 17.8333C17.652 17.8333 14.6653 19.0266 14.6667 20.4999C14.6667 21.9733 17.6507 23.1666 21.3333 23.1666C25.016 23.1666 28 21.9733 28 20.4999"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="10.6667"
        cy="11.1667"
        rx="6.66667"
        ry="2.66667"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3333 11.1667V14.5001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3333 9.16675L27.3333 5.16675"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3333 9.16675L23.3333 5.16675"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.00133 16.5005C4.00133 17.9735 6.98473 19.1666 10.6667 19.1666"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6653 19.1666C6.98417 19.1666 4 17.973 4 16.5005"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PayDisableIconComponent extends BaseIconComponent {
  @Input() override width = 32
  @Input() override height = 32
}
