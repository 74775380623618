<div class="input-wrapper">
  <input
    #input
    class="h--38"
    [value]="value"
    [placeholder]="placeholder"
    [style.height]="getHeight()"
    pInputText
    [type]="(isPasswordRevealed$ | async) ? 'text' : 'password'"
    (keyup)="onKeyUp()"
    (paste)="onPasswordPaste($event)"
  />
  <div
    class="reveal-password-button"
    [class]="{ clickable: !disabled, disabled: !!disabled }"
    (click)="togglePasswordReveal()"
  >
    @switch (isPasswordRevealed$ | async) {
      @case (false) {
        <i class="pi pi-eye"></i>
      }
      @case (true) {
        <i class="pi pi-eye-slash"></i>
      }
    }
  </div>
</div>
