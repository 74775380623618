import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-fuel-level',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.90136 2.3125C6.11308 2.3125 4.62012 3.80547 4.62012 5.59374V40.0468C4.62012 40.4819 4.79297 40.8992 5.10064 41.2069C5.40832 41.5146 5.82562 41.6874 6.26074 41.6874H25.9482C26.3833 41.6874 26.8006 41.5146 27.1083 41.2069C27.416 40.8992 27.5888 40.4819 27.5888 40.0468V32.664C27.5888 32.4465 27.6753 32.2378 27.8291 32.084C27.9829 31.9301 28.1916 31.8437 28.4091 31.8437H29.2295C30.1548 31.8437 30.8701 32.559 30.8701 33.4843V35.125C30.8701 37.8238 33.0931 40.0468 35.7919 40.0468C38.4908 40.0468 40.7138 37.8238 40.7138 35.125V13.7969C40.7138 12.2498 39.9837 10.788 38.7451 9.85936L33.4951 5.92187C33.1467 5.66165 32.7094 5.55008 32.2789 5.61157C31.8484 5.67307 31.4598 5.90263 31.1982 6.24999C30.9384 6.59855 30.8271 7.03575 30.8886 7.4661C30.9501 7.89645 31.1793 8.28499 31.5263 8.54687L33.4951 10.0234C33.6988 10.1762 33.8642 10.3744 33.9781 10.6022C34.092 10.83 34.1513 11.0812 34.1513 11.3359V15.4375C34.1513 16.9501 35.2128 18.2495 36.6221 18.617C37.0585 18.7302 37.4326 19.0862 37.4326 19.539V35.125C37.4326 36.0503 36.7173 36.7656 35.7919 36.7656C34.8666 36.7656 34.1513 36.0503 34.1513 35.125V33.4843C34.1513 30.7855 31.9283 28.5625 29.2295 28.5625H28.4091C28.1916 28.5625 27.9829 28.476 27.8291 28.3222C27.6753 28.1684 27.5888 27.9597 27.5888 27.7421V5.59374C27.5888 3.80547 26.0959 2.3125 24.3076 2.3125H7.90136ZM8.72167 5.59374H23.4873C23.9417 5.59374 24.3076 5.9596 24.3076 6.41406V17.8984C24.3076 18.3529 23.9417 18.7187 23.4873 18.7187H8.72167C8.61389 18.7189 8.50712 18.6979 8.4075 18.6567C8.30788 18.6156 8.21736 18.5552 8.14114 18.4789C8.06493 18.4027 8.00451 18.3122 7.96336 18.2126C7.92222 18.113 7.90115 18.0062 7.90136 17.8984V6.41406C7.90136 5.9596 8.26722 5.59374 8.72167 5.59374Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FuelLevelIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
