import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { Action, Resource, UserProfile } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { AccessControl, selectState } from '@ti-platform/web/common'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout/components'
import { BaseUsersModel } from '../../models'

@Component({
  selector: 'app-users-grid',
  templateUrl: 'users-grid.component.html',
  styleUrls: ['users-grid.component.scss'],
})
export class UsersGridComponent implements OnInit {
  @Output() loginAs = new EventEmitter<UserProfile>()

  protected _displayColumns: string[] = ['user', 'role', 'lastActivity', 'actions']

  get displayColumns(): string[] {
    return this._displayColumns
  }

  @Input()
  set displayColumns(value: string[]) {
    this._displayColumns = value
    this.model.limitDisplayColumns(this.displayColumns)
  }

  @Input() hideCurrentUser = true

  protected readonly accessControl = inject(AccessControl)
  protected readonly api = inject(ApiService)
  protected readonly model = inject(BaseUsersModel)
  protected readonly state = selectState(BaseUsersModel)

  protected readonly Action = Action
  protected readonly Resource = Resource

  public ngOnInit() {
    this.model.hideCurrentUser = this.hideCurrentUser
    this.model.limitDisplayColumns(this.displayColumns)
  }

  protected onSortUpdated(event: DataGridSortOrder) {
    this.model.setOrder(event)
  }

  protected onLoadMoreClicked() {
    this.model.loadNextPage()
  }
}
