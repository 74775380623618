<p-dialog
  [(visible)]="opened"
  [modal]="true"
  class="dialog-primary extra-large headless"
  closable="false"
>
  <ng-template pTemplate="header"></ng-template>
  <app-device-form
    (cancelEvent)="onCancel()"
    (submitEvent)="onSubmit($event)"
    (submitWithStartCopyMediaConfigEvent)="onSubmitWithStartCopyMediaConfigEvent($event)"
    (copyMediaConfigEvent)="onCopyMediaConfigEvent($event)"
    [device]="device ?? {}"
    [header]="('tsp-admin.devices.edit-device' | translate) ?? ''"
  ></app-device-form>
</p-dialog>
