<p-dialog
  (visibleChange)="close()"
  [modal]="true"
  [visible]="isOpened()"
  class="dialog-primary large"
>
  <ng-template pTemplate="header">
    <header>
      <div class="title text-18 fw-600">
        {{ 'users.profile.edit-profile' | translate }}
      </div>
    </header>
  </ng-template>

  <form class="form p-fluid">
    <div class="profile-section mb-16">
      <header class="label text-16 fw-600">
        {{ 'users.profile.title' | translate }}
        <div class="counter text-12 fw-600">
          {{ (profile.roleLabelKey$ | async) || '' | translate }}
        </div>
      </header>
      <div class="horizontal-spacer--16"></div>
      <div class="profile-body">
        <div class="personal-info mb-12">
          <app-avatar [photo]="state.avatarUrl$ | async" class="clickable" size="56"></app-avatar>

          <div class="info-wrapper">
            <div class="name text-14 fw-500">
              {{ 'users.profile.profile-photo' | translate }}
            </div>
            <div class="email text-13 fw-500">
              {{ 'users.profile.recommended-resolution' | translate }} 300 x 300
            </div>
          </div>
        </div>

        <div class="controls flex mb-16 gap-8">
          @if (state.avatarUrl$ | async) {
            <p-button
              class="text-14 fw-600 h--40 w50"
              [disabled]="profile.isDemoUser$ | async"
              [severity]="'secondary'"
              (click)="fileInput.click()"
            >
              {{ 'button.change' | translate }}
            </p-button>
            <p-button
              class="text-14 fw-600 h--40 w50"
              [disabled]="profile.isDemoUser$ | async"
              [severity]="'secondary'"
              (click)="model.removeAvatar()"
            >
              {{ 'button.remove' | translate }}
            </p-button>
          } @else {
            <p-button
              class="text-14 fw-600 h--40 w100"
              [disabled]="profile.isDemoUser$ | async"
              [severity]="'secondary'"
              (click)="fileInput.click()"
            >
              {{ 'button.upload' | translate }}
            </p-button>
          }
        </div>

        <div>
          <div class="label mb-12 text-14 fw-500">{{ 'feedback.name-label' | translate }}</div>
          <input
            #nameInput
            (blur)="model.setName(nameInput.value)"
            [value]="state.userName$ | async"
            class="h--40 text-14 fw-500"
            pInputText
          />
        </div>
      </div>
    </div>

    <div class="password-section">
      <div>
        <div class="label text-16 fw-600">{{ 'sign-in.password' | translate }}</div>
        <div class="password-hidden text-13 fw-500">{{ PASSWORD_PLACEHOLDER }}</div>
      </div>
      <p-button
        [routerLink]="['/', 'change-password']"
        [severity]="'secondary'"
        [disabled]="profile.isDemoUser$ | async"
        class="p--18 h--40 text-14 fw-600"
      >
        {{ 'users.profile.change-password' | translate }}
      </p-button>
    </div>

    @if (isPushNotificationsAvailable()) {
      <div class="pushes-section mt-16">
        <div>
          <div class="label text-16 fw-600">
            {{ 'users.profile.push-notifications' | translate }}
          </div>
        </div>
        @if (pushes.isRegistered$ | async) {
          {{ 'common.enabled' | translate }}
        } @else {
          <p-button
            (click)="enablePushes()"
            [severity]="'secondary'"
            class="p--18 h--40 text-14 fw-600"
          >
            {{ 'common.enable' | translate }}
          </p-button>
        }
      </div>
    }

    <div class="errors-box">
      @if (state.errorMessage$ | async; as error) {
        <p class="error-message">{{ error }}</p>
      }
    </div>
  </form>

  <div class="footer">
    <p-button
      class="text-14 fw-600 h--40 w100"
      [disabled]="(state.isLoading$ | async) || (profile.isDemoUser$ | async)"
      (click)="model.saveUpdates(); close()"
    >
      {{ 'button.save' | translate }}
    </p-button>
  </div>

  <input
    #fileInput
    (change)="model.onUserAvatarSelected($event)"
    accept=".jpg, .jpeg, .png"
    type="file"
  />
</p-dialog>
