import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-truck-highway',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 19.5V21C7.9998 21.5522 7.5522 21.9998 7 22H5.5C4.9478 21.9998 4.5002 21.5522 4.5 21V19.1143"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 19.1143V21C19.4998 21.5522 19.0522 21.9998 18.5 22H17C16.4478 21.9998 16.0002 21.5522 16 21V19.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 12L11.483 11.068C11.8217 10.9773 12.1783 10.9773 12.517 11.068L16 12V16.9384H8V12Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 6.5H6"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 12V5C18 3.89543 17.1046 3 16 3H8C6.89543 3 6 3.89543 6 5V11.9407"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 10H19.5C20.0523 10 20.5 9.55228 20.5 9V7C20.5 6.44772 20.0523 6 19.5 6H18"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10H4.5C3.94772 10 3.5 9.55228 3.5 9V7C3.5 6.44772 3.94772 6 4.5 6H6"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 19.5H8.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12H5.5C4.67157 12 4 12.6716 4 13.5V18C4 18.8284 4.67157 19.5 5.5 19.5H18.5C19.3284 19.5 20 18.8284 20 18V13.5C20 12.6716 19.3284 12 18.5 12H16"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 5V2"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 5V2"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TruckHighwayIconComponent extends BaseIconComponent {}
