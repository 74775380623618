import { Observable, Subject } from 'rxjs'
import { DestroyRef, inject } from '@angular/core'

export const injectDestroy$ = (): Observable<void> => {
  const subject = new Subject<void>()
  const destroyRef = inject(DestroyRef)
  destroyRef.onDestroy(() => {
    subject.next()
    subject.complete()
  })
  return subject.asObservable()
}
