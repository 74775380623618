import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-flag-bold',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66699 9.99967C2.66699 9.99967 3.33366 9.33301 5.33366 9.33301C7.33366 9.33301 8.66699 10.6663 10.667 10.6663C12.667 10.6663 13.3337 9.99967 13.3337 9.99967V1.99967C13.3337 1.99967 12.667 2.66634 10.667 2.66634C8.66699 2.66634 7.33366 1.33301 5.33366 1.33301C3.33366 1.33301 2.66699 1.99967 2.66699 1.99967V9.99967Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M2.66699 9.99967C2.66699 9.99967 3.33366 9.33301 5.33366 9.33301C7.33366 9.33301 8.66699 10.6663 10.667 10.6663C12.667 10.6663 13.3337 9.99967 13.3337 9.99967V1.99967C13.3337 1.99967 12.667 2.66634 10.667 2.66634C8.66699 2.66634 7.33366 1.33301 5.33366 1.33301C3.33366 1.33301 2.66699 1.99967 2.66699 1.99967V9.99967ZM2.66699 9.99967V14.6663"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FlagBoldIconComponent extends BaseIconComponent {}
