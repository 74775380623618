import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { distinctUntilChanged, firstValueFrom, skip, takeUntil } from 'rxjs'
import { Action, LocationPlace, LocationType, Resource } from '@ti-platform/contracts'
import {
  AccessControl,
  BrowserLocalStorage,
  CONFIG,
  injectDestroy$,
  WhiteLabelSettingsProvider,
} from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MapAdapter, TilesSource } from '../../adapters'
import { Coordinates, MapGeofenceManager, MapHomebaseProvider } from '../../contracts'
import { LocationSearchComponent } from '../../components'
import { GeofenceEditMarkerStep } from '../markers'
import {
  MapGuideModel,
  MapGuideOptions,
  MapVehiclesManager,
  VehicleMarkerController,
} from '../../services'
import { toLatLng } from '../../utils'

@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, AfterViewInit {
  @Input() showGuide = true
  @Input() showTilesSelector = true
  @Input() showLocationSearch = true
  @Input() showGeofenceControl = true
  @Input() showFitAllVehiclesBtn = true
  @Input() showNavigateHomebaseBtn = true
  @Input() showZoomControl = true
  @ViewChild('mapRef')
  protected readonly mapEl!: ElementRef<HTMLElement>
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  protected readonly container!: ViewContainerRef
  @ViewChild('locationSearchRef')
  protected readonly locationSearchRef?: LocationSearchComponent
  protected readonly config = inject(CONFIG)
  protected readonly accessControl = inject(AccessControl)
  protected readonly adapter = inject(MapAdapter)
  protected readonly mapGuide = inject(MapGuideModel, { optional: true })
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly deviceService = inject(DeviceService)
  protected readonly vehiclesManager = inject(MapVehiclesManager, { optional: true })
  protected readonly vehicleMarkerCtrl = inject(VehicleMarkerController, { optional: true })
  protected readonly geofenceManager = inject(MapGeofenceManager, { optional: true })
  protected readonly homebaseProvider = inject(MapHomebaseProvider, { optional: true })
  protected readonly whiteLabelSettings = inject(WhiteLabelSettingsProvider)
  protected readonly destroy$ = injectDestroy$()
  protected readonly defaultTileSources = [
    TilesSource.Standard,
    TilesSource.Satellite,
    TilesSource.OSM,
    TilesSource.MapTiler,
    TilesSource.EsriImagery,
    TilesSource.HEREImagery,
  ]
  protected readonly googleTileSources = [TilesSource.Google, TilesSource.GoogleSatellite]

  protected readonly Action = Action
  protected readonly Resource = Resource
  protected readonly TILE_SOURCE_KEY = 'TILE_SOURCE'
  protected readonly GEOFENCE_ENABLED_STORAGE_KEY = 'TI_GEOFENCE_UI_ENABLED'

  public ngOnInit() {
    this.initGeofenceManager()
  }

  public async ngAfterViewInit() {
    await this.initMapTiles()
    this.adapter.setComponentFactory(this.componentFactory)
    this.initMap().catch((error) => console.error(`Cannot init map`, error))
  }

  protected async initMap() {
    if (!this.adapter.hostEl) {
      await this.adapter.init(this.mapEl.nativeElement)
    } else {
      const parentEl = this.mapEl.nativeElement.parentElement
      if (parentEl) {
        parentEl.removeChild(this.mapEl.nativeElement)
        if (parentEl.childNodes.length) {
          parentEl.insertBefore(this.adapter.hostEl, parentEl.childNodes[0])
        } else {
          parentEl.appendChild(this.adapter.hostEl)
        }
      }
    }
  }

  protected async initMapTiles() {
    const whiteLabelSettings = await this.whiteLabelSettings.getData()
    const storedTileSource = this.localStorage.getItem(this.TILE_SOURCE_KEY) as TilesSource
    if (
      storedTileSource &&
      (this.defaultTileSources.includes(storedTileSource) ||
        (whiteLabelSettings.googleMapsKey && this.googleTileSources.includes(storedTileSource)))
    ) {
      this.adapter.tilesSource$.next(storedTileSource)
    }

    this.adapter.tilesSource$
      .pipe(distinctUntilChanged(), skip(1), takeUntil(this.destroy$))
      .subscribe((tileSource) => this.localStorage.setItem(this.TILE_SOURCE_KEY, tileSource))
  }

  protected initGeofenceManager() {
    if (this.geofenceManager) {
      // Clear location search component after geofence edit is cancelled
      this.geofenceManager.editModeExited$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.locationSearchRef) {
          this.locationSearchRef.clear()
        }
      })

      // Store and retrieve isGeofenceControlEnabled value
      if (this.showGeofenceControl) {
        if (!this.deviceService.isMobile()) {
          this.mapGuide?.showOption(MapGuideOptions.GeofenceToggleIntro)
        }

        this.geofenceManager.isEnabled$.next(this.getIsGeofenceUiEnabled())
        this.geofenceManager.isEnabled$
          .pipe(distinctUntilChanged(), skip(1), takeUntil(this.destroy$))
          .subscribe((isEnabled) => {
            this.setIsGeofenceUiEnabled(isEnabled)
            if (isEnabled) {
              this.mapGuide?.showOption(MapGuideOptions.GeofenceToggleIntro)
            }
          })

        // Disable geofence manager based on permissions
        this.accessControl.check(Resource.Location, Action.View).then((result) => {
          if (!result) {
            this.geofenceManager?.isEnabled$.next(false)
          }
        })
      }
    }
  }

  protected async cancelGeofenceEditing() {
    this.geofenceManager?.unselectGeofence()
  }

  protected async fitMapToViewAllVehicles() {
    this.vehiclesManager?.fitMapToViewAll()
    this.vehicleMarkerCtrl?.resetTracking$.next()
  }

  protected async centerMapOverFleetHomeBase() {
    if (this.homebaseProvider) {
      this.vehicleMarkerCtrl?.resetTracking$.next()
      const [latLng, addressData] = await Promise.all([
        firstValueFrom(this.homebaseProvider.point$),
        firstValueFrom(this.homebaseProvider.addressData$),
      ])
      if (latLng && addressData) {
        this.adapter.moveTo(latLng, addressData?.street ? 14 : 10)
      }
    }
  }

  protected async addDraftGeofenceFromLocation(location: LocationPlace) {
    const coordinates = toLatLng(location.point as Coordinates)
    this.geofenceManager?.createDraftGeofence({
      address: location.label,
      name: location.label,
      step: GeofenceEditMarkerStep.MapPin,
      latLng: coordinates,
      type: LocationType.Address,
    })

    this.adapter.moveTo(coordinates, 16)
    this.mapGuide?.showOption(MapGuideOptions.SavingAddress)
  }

  protected getIsGeofenceUiEnabled() {
    const cache = this.localStorage.getItem(this.GEOFENCE_ENABLED_STORAGE_KEY)
    if (cache !== null) {
      try {
        return JSON.parse(cache)
      } catch (error) {
        console.error(error)
      }
    }
    return true
  }

  protected setIsGeofenceUiEnabled(value: boolean) {
    this.localStorage.setItem(this.GEOFENCE_ENABLED_STORAGE_KEY, JSON.stringify(value))
  }

  protected readonly componentFactory = (type: Type<any>, options: NonNullable<any>) => {
    return new Promise<ComponentRef<any>>((resolve) => {
      const componentRef = this.container.createComponent(type)
      for (const key in options) {
        componentRef.instance[key] = options[key]
      }

      // Force the component to update
      componentRef.changeDetectorRef.markForCheck()

      resolve(componentRef)
    })
  }
}
