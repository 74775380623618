import { Component, inject, Input, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

export interface ConfirmWithCommentDialogData {
  header: string
  commentSubheader: string
  commentPlaceholder?: string
  confirmButton?: string
  cancelButton?: string
}

@Component({
  selector: 'app-confirm-with-comment-dialog',
  templateUrl: 'confirm-with-comment-dialog.component.html',
  styleUrl: 'confirm-with-comment-dialog.component.scss',
})
export class ConfirmWithCommentDialogComponent implements OnInit, ConfirmWithCommentDialogData {
  @Input() header = ''
  @Input() commentSubheader = ''
  @Input() commentPlaceholder = ''
  @Input() confirmButton = 'Confirm'
  @Input() cancelButton = 'Cancel'

  protected readonly dynamicDialogRef = inject(DynamicDialogRef)
  protected readonly dynamicDialogConfig = inject(DynamicDialogConfig)

  protected commentText = ''

  public ngOnInit() {
    // Assign data from dynamic dialog config
    Object.assign(this, this.dynamicDialogConfig.data)
  }
}
