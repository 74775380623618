import {
  ApiContext,
  HttpOptions,
  HttpRequestHeaders,
  HttpRequestParams,
  RequestBuilder,
  RequestType,
} from '../contracts'

export abstract class ApiRoute {
  protected baseURL!: string
  protected route!: string

  public constructor(protected context: ApiContext) {
    this.baseURL = this.context.config.fleetApiUrl
    this.init()
  }

  /**
   * Override this method to initialize specific route implementation
   */
  abstract init(): void

  protected get<ResponseData>(): RequestBuilder<ResponseData> {
    return this.prepareRequestBuilder<ResponseData>(RequestType.Get)
  }

  protected post<ResponseData>(): RequestBuilder<ResponseData> {
    return this.prepareRequestBuilder<ResponseData>(RequestType.Post)
  }

  protected delete<ResponseData>(): RequestBuilder<ResponseData> {
    return this.prepareRequestBuilder<ResponseData>(RequestType.Delete)
  }

  protected put<ResponseData>(): RequestBuilder<ResponseData> {
    return this.prepareRequestBuilder<ResponseData>(RequestType.Put)
  }

  protected patch<ResponseData>(): RequestBuilder<ResponseData> {
    return this.prepareRequestBuilder<ResponseData>(RequestType.Patch)
  }

  protected prepareRequestBuilder<ResponseData>(
    requestMethod: RequestType,
  ): RequestBuilder<ResponseData> {
    const requestOptions: HttpOptions = { headers: {} }
    let requestData: unknown = {}
    const requestBuilder = {
      withHeaders: (headers: HttpRequestHeaders) => {
        requestOptions.headers = headers
        return requestBuilder
      },

      withParams: (params: HttpRequestParams) => {
        requestOptions.params = params
        return requestBuilder
      },

      reportProgress: () => {
        requestOptions.reportProgress = true
        return requestBuilder
      },

      withData: (data: unknown) => {
        requestData = data
        return requestBuilder
      },

      withCredentials: () => {
        requestOptions.withCredentials = true
        return requestBuilder
      },

      endpoint: async (endpoint: string) => {
        const requestURL = this.buildEndpointURL(endpoint)
        if (requestMethod == RequestType.Get) {
          return this.context.client.get<ResponseData>(requestURL, requestOptions)
        } else if (requestMethod == RequestType.Post) {
          return this.context.client.post<ResponseData>(requestURL, requestData, requestOptions)
        } else if (requestMethod == RequestType.Put) {
          return this.context.client.put<ResponseData>(requestURL, requestData, requestOptions)
        } else if (requestMethod == RequestType.Patch) {
          return this.context.client.patch<ResponseData>(requestURL, requestData, requestOptions)
        } else if (requestMethod == RequestType.Delete) {
          return this.context.client.delete<ResponseData>(requestURL, requestOptions)
        }
        return null
      },
    } as RequestBuilder<ResponseData>
    return requestBuilder
  }

  protected buildEndpointURL(endpoint: string) {
    return [this.baseURL, this.route, endpoint].filter(Boolean).join('/')
  }
}
