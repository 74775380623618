import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-users',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 19C3 16.791 4.791 15 7 15H11C13.209 15 15 16.791 15 19"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 5.651C17.381 5.651 18.5 6.77 18.5 8.151C18.5 9.532 17.381 10.651 16 10.651"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4049 5.99613C12.7331 7.3243 12.7331 9.4777 11.4049 10.8059C10.0767 12.134 7.92333 12.134 6.59516 10.8059C5.26699 9.4777 5.26699 7.3243 6.59516 5.99613C7.92333 4.66796 10.0767 4.66796 11.4049 5.99613"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 14C19.209 14 21 15.791 21 18"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class UsersIconComponent extends BaseIconComponent {}
