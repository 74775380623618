<p-iconField class="h--40 p-fluid" iconPosition="left">
  <p-inputIcon>
    <app-icon-search [height]="20" [width]="20"></app-icon-search>
  </p-inputIcon>

  <p-autoComplete
    class="h--40"
    #autoCompleteRef
    [placeholder]="'map.address-search.placeholder' | translate"
    [suggestions]="(suggestions$ | async) ?? []"
    (onSelect)="onLocationSelected($event.value)"
    (completeMethod)="searchCriteria$.next($event.query)"
  >
    <ng-template let-param pTemplate="item">
      <div class="option-item text-14">{{ param.label }}</div>
    </ng-template>
    <ng-template pTemplate="empty">
      <div class="option-item text-14">
        <div>{{ 'common.no-results' | translate }}</div>
      </div>
    </ng-template>
  </p-autoComplete>

  @if (autoCompleteRef._filled && !autoCompleteRef.loading) {
    <p-inputIcon class="clickable">
      <app-icon-close
        (click)="autoCompleteRef.clear(); cleared.emit()"
        [color]="'var(--color-gray-500)'"
      ></app-icon-close>
    </p-inputIcon>
  }
</p-iconField>
