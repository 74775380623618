import { inject, Injectable } from '@angular/core'
import { ApiService } from '@ti-platform/web/api'
import { Profile } from '@ti-platform/web/auth'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import {
  checkExistApiError,
  downsizeImage,
  getApiErrorKey,
  getMessageFromException,
  Memoize,
} from '@ti-platform/web/common'
import { ApiErrors } from '@ti-platform/contracts'

@Injectable()
export abstract class BaseUserSettingsModel {
  protected readonly api = inject(ApiService)
  protected readonly profile = inject(Profile)
  protected readonly languageService = inject(LanguageService)

  protected readonly store = {
    isLoading$: new BehaviorSubject(false),
    errorMessage$: new BehaviorSubject(''),
    userName$: new BehaviorSubject(''),
    avatarUrl$: new BehaviorSubject(''),
  }

  @Memoize()
  public get state() {
    return {
      isLoading$: this.store.isLoading$.asObservable(),
      errorMessage$: this.store.errorMessage$.asObservable(),
      userName$: this.store.userName$.asObservable(),
      avatarUrl$: this.store.avatarUrl$.asObservable(),
    }
  }

  public async init() {
    await this.initWithUserProperties()
  }

  public setName(value: string) {
    this.store.userName$.next(value)
  }

  public removeAvatar() {
    this.store.avatarUrl$.next('')
  }

  public async saveUpdates() {
    this.store.isLoading$.next(true)
    try {
      const user = await firstValueFrom(this.profile.state)
      if (user) {
        const name = this.store.userName$.getValue()
        if (name && name !== user.name) {
          await this.saveName(name)
        }

        const photo = this.store.avatarUrl$.getValue()
        if (photo !== user.photo) {
          const base64UrlMatch = photo.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
          if (base64UrlMatch) {
            await this.savePhotoBase64(base64UrlMatch[2], base64UrlMatch[1])
          } else if (photo === '') {
            await this.removePhoto()
          }
        }

        this.profile.refresh()
      }
    } catch (error) {
      console.error(`Cannot update user profile data`, error)

      const message = getMessageFromException(error)
      this.store.errorMessage$.next(
        await this.languageService.translate(
          checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown),
        ),
      )

      // Refresh actual user data
      await this.initWithUserProperties()
    } finally {
      this.store.isLoading$.next(false)
    }
  }

  public onUserAvatarSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement
    if (fileInput?.files?.length) {
      const file = fileInput.files[0]
      const reader = new FileReader()
      reader.onload = async (e) => {
        if (typeof e.target?.result === 'string') {
          // Reduce the image size to fit the profile photo
          this.store.avatarUrl$.next(await downsizeImage(e.target.result))
        }
      }
      reader.readAsDataURL(file)
      fileInput.value = '' // Reset the file input
    }
  }

  public setErrorMessage(message: string) {
    this.store.errorMessage$.next(message)
  }

  protected async initWithUserProperties() {
    const user = await firstValueFrom(this.profile.state)
    if (user) {
      this.store.userName$.next(user.name)
      this.store.avatarUrl$.next(user.photo)
    }
  }

  protected abstract saveName(value: string): Promise<unknown>

  protected abstract savePhotoBase64(value: string, contentType: string): Promise<unknown>

  protected abstract removePhoto(): Promise<unknown>
}
