<app-grid-controls
  (clearFilter)="onClearFilter()"
  (filterChanged)="onSaveFilter($event)"
  (sortChanged)="model.setOrder($event)"
  [actionButtonsTemplate]="actionButtons"
  [columns]="state.columns$ | async"
  [returnButtonTemplate]="returnButton"
  [filterOptions]="state.filterOptions$ | async"
  [filterValue]="state.filter$ | async"
  [sortOrder]="state.gridSortOrder$ | async"
  [defaultSortOrder]="state.defaultSortOrder$ | async"
  [showSearch]="false"
  [showMobileMenuButton]="false"
  titleText="tsp-admin.devices.commands.title"
></app-grid-controls>

<app-tsp-commands-grid></app-tsp-commands-grid>

<ng-template #actionButtons let-isMobile="isMobile">
  <p-button
    class="setup text-14 fw-600 h--40 p--18"
    [icon]="isMobile ? 'pi' : ''"
    [severity]="isMobile ? 'secondary' : 'primary'"
    [appCheckAccess]="[Resource.Device, Action.Create]"
    (click)="onAddClick()"
  >
    <app-icon-plus class="icon" [height]="20" [width]="20"></app-icon-plus>
    @if (!isMobile) {
      {{ 'tsp-admin.devices.commands.add-command' | translate }}
    }
  </p-button>
</ng-template>

<ng-template #returnButton>
  <p-button (click)="goBack()" class="button-back h--48 flex" icon="pi" [severity]="'secondary'">
    <app-icon-arrow [height]="24" [width]="24" direction="left"></app-icon-arrow>
  </p-button>
</ng-template>

<app-command-edit
  #commandEdit
  (cancelEvent)="onCancelEditCommand()"
  (submitEvent)="editCommand($event)"
  [command]="state.selectedItem$ | async"
  [opened]="
    model.selectedPurpose === CommandSelectedPurpose.Edit && !!(state.selectedItem$ | async)
  "
  [processing]="model.isEditingCommand"
  [errorMessage]="model.editingErrorMessage"
></app-command-edit>

@if (showAddCommandDialog$ | async; as showAddDialog) {
  <app-add-new-command
    (cancelEvent)="onCancelAddCommand()"
    (submitEvent)="addCommand($event)"
    [opened]="showAddDialog"
    [processing]="model.isCreatingCommand"
    [errorMessage]="model.creationErrorMessage"
  ></app-add-new-command>
}

<app-bulk-command-devices-dialog
  [command]="state.selectedItem$ | async"
  [opened]="
    !!(state.selectedItem$ | async) &&
    (model.selectedPurpose === CommandSelectedPurpose.AssignDevices ||
      model.selectedPurpose === CommandSelectedPurpose.UnassignDevices)
  "
  [commandAssignedStatus]="model.selectedPurpose === CommandSelectedPurpose.UnassignDevices"
  (cancelEvent)="onCancelBulkAssignOrUnassign()"
  (confirmEvent)="onConfirmBulkAssignOrUnassign($event)"
>
</app-bulk-command-devices-dialog>
