<div class="full-screen-container error-page">
  <div class="content-wrapper">
    <svg
      class="illustration"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M168.43 81.88C150.662 81.88 132.614 81.168 115.732 76.5502C98.8497 71.9327 84.0197 63.2262 70.3557 53.0491C61.4715 46.4099 53.3573 41.1491 41.8614 41.9535C30.5866 42.5444 19.8038 46.6924 11.1053 53.7844C-3.65493 66.4422 -1.32297 89.8516 4.50655 106.3C13.3206 131.11 40.1591 148.317 62.9638 159.55C89.3129 172.507 118.25 180.042 147.28 184.384C172.93 188.197 205.411 190.954 227.446 174.574C247.71 159.55 253.259 125.206 248.292 102.027C247.089 95.176 243.393 88.9927 237.893 84.6367C223.669 74.3909 202.45 81.2367 186.454 81.5815C180.555 81.7193 174.492 81.8571 168.43 81.88Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M111.091 43.6764C112.237 43.6764 113.166 42.7607 113.166 41.6316C113.166 40.5025 112.237 39.5869 111.091 39.5869C109.945 39.5869 109.015 40.5025 109.015 41.6316C109.015 42.7611 109.945 43.6764 111.091 43.6764Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M12.3406 41.4713C13.4866 41.4713 14.416 40.556 14.416 39.4266C14.416 38.2975 13.4866 37.3818 12.3406 37.3818C11.1942 37.3818 10.2652 38.2975 10.2652 39.4266C10.2655 40.556 11.1946 41.4713 12.3406 41.4713Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M15.3262 162.307C16.4722 162.307 17.4016 161.393 17.4016 160.262C17.4016 159.132 16.4722 158.218 15.3262 158.218C14.1801 158.218 13.2507 159.132 13.2507 160.262C13.2507 161.393 14.1801 162.307 15.3262 162.307Z"
        fill="#E4E7EC"
        style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
      />
      <path
        d="M85.535 25V34.0054"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.9655 29.502H90.0826"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M98.0107 188.198V197.18"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.4629 192.678H102.58"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.896 48.7764V57.7818"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M234.348 53.2793H243.465"
        stroke="#D0D5DD"
        style="stroke: #d0d5dd; stroke: color(display-p3 0.8157 0.8353 0.8667); stroke-opacity: 1"
        stroke-width="2.29727"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.011 225C167.986 225 202.822 222.861 202.822 220.222C202.822 217.582 167.986 215.443 125.011 215.443C82.0375 215.443 47.2003 217.582 47.2003 220.222C47.2003 222.861 82.0375 225 125.011 225Z"
        fill="#F2F4F7"
        style="fill: #f2f4f7; fill: color(display-p3 0.949 0.9569 0.9686); fill-opacity: 1"
      />
      <path
        d="M189.472 153.889H178.108V141.913H152.058V131.947L179.332 97.6804H189.472V133.346H196.815V141.913H189.472V153.889ZM161.062 133.346H178.108V111.142L161.062 133.346Z"
        fill="#98A2B3"
        style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
      />
      <path
        d="M123.545 155.2C109.384 155.2 101.079 144.273 101.079 125.828C101.079 107.034 109.471 96.3691 123.545 96.3691C137.707 96.3691 146.011 107.296 146.011 125.828C146.011 144.535 137.619 155.2 123.545 155.2ZM123.545 145.759C130.626 145.759 134.472 138.678 134.472 125.828C134.472 112.978 130.539 105.81 123.545 105.81C116.377 105.81 112.531 112.891 112.531 125.828C112.531 138.504 116.465 145.759 123.545 145.759Z"
        fill="#98A2B3"
        style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
      />
      <path
        d="M88.7388 153.889H77.3747V141.913H51.3248V131.947L78.5985 97.6804H88.7388V133.346H96.0817V141.913H88.7388V153.889ZM60.3286 133.346H77.3747V111.142L60.3286 133.346Z"
        fill="#98A2B3"
        style="fill: #98a2b3; fill: color(display-p3 0.5964 0.6352 0.7017); fill-opacity: 1"
      />
    </svg>
    <h1>{{ 'error-pages.not-found-page.caption' | translate }}</h1>
    <h3>{{ 'error-pages.not-found-page.description' | translate }}</h3>
    <p-button class="h--40 p--16 text-14 fw-600" (click)="backToApp()">{{
      'error-pages.back-to-app' | translate
    }}</p-button>
  </div>
</div>
