<p-table
  #tableRef
  (sortFunction)="sortFunction($event)"
  [customSort]="true"
  [loading]="isLoading"
  [paginator]="paginationEnabled && paginationControl === 'paginator'"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [rows]="rows"
  [sortField]="$any(sortOrder?.column)"
  [sortOrder]="sortOrder?.order === 'ASC' ? 1 : -1"
  [value]="data"
  [scrollable]="true"
  [resizableColumns]="true"
  [selection]="isMultiSelectable ? multiSelection : undefined"
  [selectionMode]="isMultiSelectable ? 'multiple' : undefined"
  [dataKey]="isMultiSelectable ? keyColumn : undefined"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)"
  scrollHeight="flex"
  scrollWidth="flex"
  class="table-primary no-wrap-cols"
  [ngClass]="{ 'empty-state': data.length === 0 }"
>
  <ng-template pTemplate="header">
    <tr>
      @if (isMultiSelectable) {
        <th class="multiselection-column-header"></th>
      }
      @for (column of columns; track colsTrackByFn) {
        @if (column.sortable) {
          <th class="{{ column.type }}" [pSortableColumn]="column.field" pResizableColumn>
            @if (column.label) {
              {{ column.label | translate }}
            }
            <p-sortIcon [field]="column.field" />
          </th>
        } @else {
          <th class="{{ column.type }}" pResizableColumn>
            @if (column.label) {
              {{ column.label | translate }}
            }
          </th>
        }
      }
    </tr>
  </ng-template>

  <ng-template let-data pTemplate="body">
    <tr
      [class.selected]="isSelected(data)"
      [class.multi-selected]="isMultiSelected(data)"
      [class]="getRowClass(data)"
      (click)="onRowItemClicked(data)"
    >
      @if (isMultiSelectable) {
        <td class="multiselection-column-checkbox">
          <p-tableCheckbox [value]="data" />
        </td>
      }
      @for (column of columns; track colsTrackByFn) {
        <td class="{{ column.type }}" [ngClass]="{ clickable: column.clickable }">
          @switch (column.type) {
            @case (ColumnType.Text) {
              <div class="text-col text-14">
                @if (shouldUsePlaceholder(data[column.field])) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] }}
                }
              </div>
            }
            @case (ColumnType.TextWithCopy) {
              <div class="text-col text-14">
                @if (shouldUsePlaceholder(data[column.field])) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  <app-inline-text-copy [text]="data[column.field]"></app-inline-text-copy>
                }
              </div>
            }
            @case (ColumnType.OnOffStatus) {
              <div class="text-col text-14">
                @if (shouldUsePlaceholder(data[column.field])) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ (data[column.field] ? 'common.on' : 'common.off') | translate }}
                }
              </div>
            }
            @case (ColumnType.Date) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] | formatDate: column.format || 'grid-date-time' }}
                }
              </div>
            }
            @case (ColumnType.Battery) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{
                    data[column.field]
                      | formatUnit
                        : UnitType.Voltage
                        : {
                            precision: 1,
                          }
                  }}
                }
              </div>
            }
            @case (ColumnType.Count) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] }}
                }
              </div>
            }
            @case (ColumnType.Duration) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] ?? 0 | formatDuration }}
                }
              </div>
            }
            @case (ColumnType.Distance) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{
                    data[column.field] ?? 0
                      | formatUnit
                        : UnitType.Distance
                        : {
                            precision: column.distancePrecision ?? 0,
                            usePreciseIfLess: column.distancePrecisionIfLess ?? null,
                          }
                  }}
                }
              </div>
            }
            @case (ColumnType.FuelEfficiency) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] | formatUnit: UnitType.FuelEfficiency }}
                }
              </div>
            }
            @case (ColumnType.Pressure) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] | formatUnit: UnitType.Pressure }}
                }
              </div>
            }
            @case (ColumnType.Speed) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] ?? 0 | formatUnit: UnitType.Speed }}
                }
              </div>
            }
            @case (ColumnType.Temperature) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] ?? 0 | formatUnit: UnitType.Temperature }}
                }
              </div>
            }
            @case (ColumnType.Volume) {
              <div class="date-col text-14">
                @if (
                  (!data[column.field] && data[column.field] !== 0) ||
                  shouldUsePlaceholder(data[column.field])
                ) {
                  {{ getPlaceholder(column) | translate }}
                } @else {
                  {{ data[column.field] | formatUnit: UnitType.Volume }}
                }
              </div>
            }
            @case (ColumnType.Switch) {}
            @case (ColumnType.Button) {}
            @case (ColumnType.User) {
              <div class="user-col flex">
                <app-avatar class="clickable" [photo]="data[column.field].photo"></app-avatar>

                <div class="double-row-col">
                  <div class="primary text-14">
                    {{ data[column.field].name }}
                  </div>
                  <div class="secondary text-14">
                    {{ data[column.field].email }}
                  </div>
                </div>
              </div>
            }
            @case (ColumnType.DoubleRow) {
              <div class="double-row-col">
                <div class="primary text-14">
                  {{ data[column.field].primary }}
                </div>
                <div class="secondary text-14">
                  {{ data[column.field].secondary }}
                </div>
              </div>
            }
            @case (ColumnType.Tags) {}
            @case (ColumnType.Actions) {
              @if (prepareActions(data, column.actions) | async; as actions) {
                @if (actions) {
                  <div class="actions-col flex" [class.disabled]="!actions.length">
                    <i
                      class="pi pi-ellipsis-h clickable"
                      (click)="onClickActions(); menuRef.show($event)"
                    ></i>
                  </div>

                  <p-menu
                    #menuRef
                    [popup]="true"
                    [model]="(actions | translateLabels) ?? []"
                    [appendTo]="tableRef"
                    styleClass="grid-actions-menu"
                  >
                    <ng-template pTemplate="item" let-item let-i="index">
                      <div class="menu-item" [ngClass]="item.styleClass">
                        @if (item.iconComponent) {
                          <div class="action-menu-app-icon-wrapper">
                            <ng-container
                              *ngComponentOutlet="
                                item.iconComponent;
                                inputs: item.iconComponentInputs || { width: 16, height: 16 }
                              "
                            ></ng-container>
                          </div>
                        } @else if (item.icon) {
                          <i [ngClass]="item.icon"></i>
                        }
                        {{ item.label }}
                      </div>
                    </ng-template>
                  </p-menu>
                }
              }
            }
            @case (ColumnType.Template) {
              @if (templates[column.field]) {
                <ng-container
                  [ngTemplateOutlet]="templates[column.field]"
                  [ngTemplateOutletContext]="{ $implicit: data[column.field], row: data }"
                ></ng-container>
              }
            }
            @default {
              <div class="text-12">
                {{ 'Column type "' + column.field + '" is not supported' }}
              </div>
            }
          }
        </td>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    @if (!isLoading) {
      @if (data.length === 0) {
        <tr class="t-footer">
          <td [attr.colspan]="isMultiSelectable ? columns.length + 1 : columns.length">
            <div class="table-footer no-data">
              @if (noDataIconTemplate) {
                <div class="no-data-icon mb-12">
                  <ng-container [ngTemplateOutlet]="noDataIconTemplate"></ng-container>
                </div>
              }
              <div class="title mb-4 text-18 fw-600">{{ noDataTitle }}</div>
              <div class="description text-14 fw-400">{{ noDataDescription }}</div>
              @if (noDataAdditionalContentTemplate) {
                <div class="no-data-additional-content">
                  <ng-container [ngTemplateOutlet]="noDataAdditionalContentTemplate"></ng-container>
                </div>
              }
            </div>
          </td>
        </tr>
      } @else if (paginationEnabled && paginationControl === 'infinite-list') {
        <tr class="t-footer">
          <td [attr.colspan]="isMultiSelectable ? columns.length + 1 : columns.length">
            <div class="table-footer">
              <p-button
                [outlined]="true"
                (onClick)="onLoadMore()"
                [disabled]="isLoading"
                class="p--18 text-14 fw-600 h--40"
                type="submit"
              >
                {{ 'button.load-more' | translate }}
              </p-button>
            </div>
          </td>
        </tr>
      }
    }
  </ng-template>
</p-table>
