@if(loading$ | async) {
  <section id="loader-skeleton">
    <div class="menu-skeleton">
      <p-skeleton width="100%" height="100%" animation="wave"></p-skeleton>
    </div>
    <div class="content-skeleton">
      <p-skeleton width="100%" height="100%" animation="wave"></p-skeleton>
    </div>
  </section>
}

<router-outlet></router-outlet>
