export const COUNTRY_CODES = [
  'AR', // Argentina
  'BZ', // Belize
  'BO', // Bolivia
  'BR', // Brazil
  'CA', // Canada
  'CL', // Chile
  'CO', // Colombia
  'CR', // Costa Rica
  'DO', // Dominican Republic
  'EC', // Ecuador
  'SV', // El Salvador
  'GT', // Guatemala
  'HN', // Honduras
  'JM', // Jamaica
  'MX', // Mexico
  'NI', // Nicaragua
  'PA', // Panama
  'PY', // Paraguay
  'PE', // Peru
  'TT', // Trinidad and Tobago
  'US', // United States
  'UY', // Uruguay
  'VE', // Venezuela
]
