import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-change',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 0.833008L17.5 4.16634M17.5 4.16634L14.1667 7.49967M17.5 4.16634H5.83333C4.94928 4.16634 4.10143 4.51753 3.47631 5.14265C2.85119 5.76777 2.5 6.61562 2.5 7.49967V9.16634M5.83333 19.1663L2.5 15.833M2.5 15.833L5.83333 12.4997M2.5 15.833H14.1667C15.0507 15.833 15.8986 15.4818 16.5237 14.8567C17.1488 14.2316 17.5 13.3837 17.5 12.4997V10.833"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class ChangeIconComponent extends BaseIconComponent {}
