import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-export',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 15C22 14.4477 21.5523 14 21 14C20.4477 14 20 14.4477 20 15H22ZM3 19H2H3ZM4 15C4 14.4477 3.55228 14 3 14C2.44772 14 2 14.4477 2 15H4ZM16.2929 8.70711C16.6834 9.09763 17.3166 9.09763 17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L16.2929 8.70711ZM12 3L12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L12 3ZM6.29289 7.29289C5.90237 7.68342 5.90237 8.31658 6.29289 8.70711C6.68342 9.09763 7.31658 9.09763 7.70711 8.70711L6.29289 7.29289ZM11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15H11ZM20 15V19H22V15H20ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071L21.1213 21.1213C21.6839 20.5587 22 19.7957 22 19H20ZM19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20V22C19.7957 22 20.5587 21.6839 21.1213 21.1213L19.7071 19.7071ZM19 20H5V22H19V20ZM5 20C4.73478 20 4.48043 19.8946 4.29289 19.7071L2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22V20ZM4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19H2C2 19.7957 2.31607 20.5587 2.87868 21.1213L4.29289 19.7071ZM4 19V15H2V19H4ZM17.7071 7.29289L12.7071 2.29289L11.2929 3.70711L16.2929 8.70711L17.7071 7.29289ZM11.2929 2.29289L6.29289 7.29289L7.70711 8.70711L12.7071 3.70711L11.2929 2.29289ZM11 3V15H13V3H11Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class ExportIconComponent extends BaseIconComponent {}
