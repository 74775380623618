import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-billing',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99625 6.99789H21.0037"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99873 10.9996H6.47668"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99916 17.002H5.9975C4.33995 17.002 2.99625 15.6583 2.99625 14.0008V5.99747C2.99625 4.33992 4.33995 2.99622 5.9975 2.99622H18.0025C19.66 2.99622 21.0037 4.33992 21.0037 5.99747V8.99872"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0004 12.6002V19.4031C13.0016 20.2869 14.792 21.0037 17.0021 21.0037C19.2122 21.0037 21.0026 20.2869 21.0038 19.4031V12.6002"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0026 12.6003C21.0026 13.4841 19.211 14.201 17.001 14.201C14.7909 14.201 13.0004 13.4841 13.0004 12.6003C13.0004 11.7154 14.7932 10.9996 17.0021 10.9996C19.211 10.9996 21.0026 11.7165 21.0038 12.6003"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.001 16.0017C13.001 16.8855 14.7914 17.6024 17.0015 17.6024C19.2116 17.6024 21.0032 16.8855 21.0032 16.0017"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class BillingIconComponent extends BaseIconComponent {}
