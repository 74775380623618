import { Component, EventEmitter, Output } from '@angular/core'
import { GeofenceMarkerEvents, GeofenceMarkerState } from '../../../contracts'

export enum GeofenceEditMarkerStep {
  MapPin = 'map-pin',
  SelectShape = 'select-shape',
  Create = 'create',
  Edit = 'edit',
}

@Component({
  selector: 'app-fleet-geofence-marker',
  templateUrl: 'geofence-marker.component.html',
  styleUrls: ['geofence-marker.component.scss'],
})
export class GeofenceMarkerComponent {
  @Output() afterViewInit = new EventEmitter()

  public state!: GeofenceMarkerState
  public events!: GeofenceMarkerEvents

  protected readonly GeofenceMarkerMode = GeofenceEditMarkerStep
}
