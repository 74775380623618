<p-iconField iconPosition="right">
  <input
    #inputRef
    class="h--{{ height }}"
    type="text"
    pInputText
    [pKeyFilter]="$any(keyFilter)"
    [value]="currentValue()"
    [readonly]="!isEditActive() || isLoading"
    (keyup)="currentValue.set(inputRef.value)"
  />
  <p-inputIcon>
    @if (isLoading) {
      <i class="pi pi-spinner pi-spin disabled"></i>
    } @else {
      @if (isEditActive()) {
        <i class="pi pi-times clickable" (click)="onCancel()"></i>
        <i class="pi pi-check clickable" (click)="onSubmit()"></i>
      } @else {
        <i class="pi pi-pencil clickable" (click)="isEditActive.set(true)"></i>
      }
    }
  </p-inputIcon>
</p-iconField>
