import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-stop-solid-2',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" [attr.fill]="color" style="fill-opacity:1;" />
      <g clip-path="url(#clip0_677_7951)">
        <rect
          x="5.5"
          y="5.5"
          width="9"
          height="9"
          rx="1.5"
          fill="#F2F4F7"
          style="fill:#F2F4F7;fill:color(display-p3 0.9490 0.9569 0.9686);fill-opacity:1;"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class StopSolid2IconComponent extends BaseIconComponent {
  @Input() override width = 20
  @Input() override height = 20
}
