import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-gallery',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.0625"
        y="1.5625"
        width="16.875"
        height="16.875"
        rx="4.4375"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.125"
      />
      <path
        d="M7.9987 8.33333C8.91917 8.33333 9.66536 7.58714 9.66536 6.66667C9.66536 5.74619 8.91917 5 7.9987 5C7.07822 5 6.33203 5.74619 6.33203 6.66667C6.33203 7.58714 7.07822 8.33333 7.9987 8.33333Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.72656 15.7917L6.8349 13.0333C7.49323 12.5917 8.44323 12.6417 9.0349 13.15L9.3099 13.3917C9.9599 13.95 11.0099 13.95 11.6599 13.3917L18.5015 7"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class GalleryIconComponent extends BaseIconComponent {}
