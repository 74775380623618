import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-lock-warn',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 25.9922L13.0061 27.0503C13.0061 33.9781 12 47.3555 12 49.6755C12 52.5755 16.2105 54.9922 18.8421 54.9922H46.2105C50.8421 54.9922 52 51.4477 52 49.6755V25.9922Z"
        fill="#7F56D9"
        style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;"
      />
      <path
        d="M51.4081 26.5219V49.631C51.4081 51.1108 50.8202 52.5301 49.7738 53.5765C48.7273 54.623 47.3081 55.2109 45.8282 55.2109H17.9287C16.4488 55.2109 15.0295 54.623 13.9831 53.5765C12.9366 52.5301 12.3488 51.1108 12.3488 49.631V44.051V30.5219"
        fill="#7F56D9"
        style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;"
      />
      <path
        d="M20.7186 26.5219H16.3488C14.1396 26.5219 12.3488 28.3127 12.3488 30.5219V44.051V49.631C12.3488 51.1108 12.9366 52.5301 13.9831 53.5765C15.0295 54.623 16.4488 55.2109 17.9287 55.2109H45.8282C47.3081 55.2109 48.7273 54.623 49.7738 53.5765C50.8202 52.5301 51.4081 51.1108 51.4081 49.631V26.5219H43.0382M20.7186 26.5219V18.152C20.7186 11.9886 25.715 6.99219 31.8784 6.99219V6.99219C38.0418 6.99219 43.0382 11.9886 43.0382 18.152V26.5219M20.7186 26.5219H43.0382"
        stroke="#7F56D9"
        style="stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
        stroke-width="2.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="46.9999" cy="28.9921" r="9.6" fill="white" style="fill:white;fill-opacity:1;" />
      <g clip-path="url(#clip0_9503_43116)">
        <path
          d="M50 25.9922L44 31.9922M44 25.9922L50 31.9922M57 28.9922C57 34.515 52.5228 38.9922 47 38.9922C41.4772 38.9922 37 34.515 37 28.9922C37 23.4693 41.4772 18.9922 47 18.9922C52.5228 18.9922 57 23.4693 57 28.9922Z"
          stroke="#593C99"
          style="stroke:#593C99;stroke:color(display-p3 0.3492 0.2341 0.6005);stroke-opacity:1;"
          stroke-width="2.64"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9503_43116">
          <rect
            width="24"
            height="24"
            fill="white"
            style="fill:white;fill-opacity:1;"
            transform="translate(35 16.9922)"
          />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LockWarnIconComponent extends BaseIconComponent {
  @Input() override height = 64
  @Input() override width = 64
}
