<form (submit)="onFormSubmit()" [formGroup]="form" class="p-fluid">
  <section *ngIf="{ newUserMode: !data?.id } as view" class="inputs-section">
    <div class="label mb-8 text-14 fw-500">
      {{ 'users.form.name' | translate }}
      <span *ngIf="view.newUserMode" class="required">*</span>
    </div>
    <input
      class="mb-16 h--40"
      formControlName="name"
      pInputText
      placeholder="{{ 'users.form.name-placeholder' | translate }}"
      type="text"
    />

    <div class="label mb-8 text-14 fw-500">
      {{ 'users.form.email' | translate }}
      <span *ngIf="view.newUserMode" class="required">*</span>
    </div>
    <input
      class="h--40 mb-16"
      formControlName="email"
      pInputText
      placeholder="email@telematics.com"
      type="text"
    />

    <div class="label mb-8 text-14 fw-500">
      {{ 'users.form.phone' | translate }}
    </div>
    <p-inputMask
      [unmask]="true"
      class="mb-16 h--40"
      formControlName="phoneNumber"
      mask="(999) 999-9999"
      placeholder="(999) 999-9999"
    />

    <div class="label mb-8 text-14 fw-500">
      {{ 'users.form.role' | translate }}
      <span *ngIf="view.newUserMode" class="required">*</span>
    </div>
    <p-dropdown
      [options]="((displayRoles === 'TSP' ? tspRoleOptions$ : fleetRoleOptions$) | async) || []"
      [placeholder]="'users.form.select-role' | translate"
      appendTo="body"
      class="h--40"
      formControlName="roleId"
    >
      <ng-template let-param pTemplate="item">
        <div class="option-item">
          <div class="text-14 fw-400">{{ param.label }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </section>
</form>

<div class="footer flex">
  <p-button
    (click)="cancel()"
    [severity]="'secondary'"
    class="cancel text-14 fw-600 w50 h--40"
    type="submit"
  >
    {{ 'button.cancel' | translate }}
  </p-button>

  <p-button (click)="onFormSubmit()" class="submit text-14 fw-600 w50 h--40" type="submit">
    @switch (!data.id) {
      @case (true) {
        <app-icon-send [height]="20" [width]="20" class="icon"></app-icon-send>
        {{ 'users.form.invite-user' | translate }}
      }
      @case (false) {
        <app-icon-check [height]="20" [width]="20" class="icon"></app-icon-check>
        {{ 'button.save' | translate }}
      }
    }
  </p-button>
</div>
