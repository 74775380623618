import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-upload',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 10.5L8.5 8.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 8.5L6.5 10.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.49984 13.8333V8.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.8335 13.166H4.50016C3.0274 13.166 1.8335 11.9721 1.8335 10.4993C1.8335 9.02659 3.0274 7.83268 4.50016 7.83268L4.4935 7.78578C4.32757 6.62427 4.67384 5.44793 5.44258 4.56155C6.21132 3.67518 7.32687 3.16602 8.50016 3.16602C9.67346 3.16602 10.789 3.67518 11.5577 4.56155C12.3265 5.44793 12.6728 6.62427 12.5068 7.78578L12.5002 7.83268C13.9729 7.83268 15.1668 9.02659 15.1668 10.4993C15.1668 11.9721 13.9729 13.166 12.5002 13.166H11.1668"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class UploadIconComponent extends BaseIconComponent {}
