import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-white-label',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.307 3.54099L15.734 5.96799C16.455 6.68899 16.455 7.85999 15.734 8.58199L13.307 11.009C12.586 11.73 11.415 11.73 10.693 11.009L8.266 8.58199C7.545 7.86099 7.545 6.68999 8.266 5.96799L10.693 3.54099C11.415 2.81999 12.585 2.81999 13.307 3.54099V3.54099Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 15.667V19.134C21 20.164 20.164 21 19.133 21H15.666C14.635 21 13.799 20.164 13.799 19.133V15.666C13.799 14.636 14.635 13.799 15.666 13.799H19.133C20.164 13.8 21 14.636 21 15.667Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 17.4V17.4C3 15.412 4.612 13.8 6.6 13.8V13.8C8.588 13.8 10.2 15.412 10.2 17.4V17.4C10.2 19.388 8.588 21 6.6 21V21C4.612 21 3 19.388 3 17.4Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class WhiteLabelIconComponent extends BaseIconComponent {}
