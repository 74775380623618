import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-rotate',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 3.1668C1.75 2.75258 1.41421 2.4168 1 2.4168C0.585786 2.4168 0.25 2.75258 0.25 3.1668H1.75ZM1 7.1668H0.25C0.25 7.58101 0.585786 7.9168 1 7.9168L1 7.1668ZM5 7.9168C5.41421 7.9168 5.75 7.58101 5.75 7.1668C5.75 6.75258 5.41421 6.4168 5 6.4168V7.9168ZM3.38071 10.2509C3.24307 9.86023 2.81479 9.65511 2.42411 9.79275C2.03344 9.93039 1.82831 10.3587 1.96595 10.7494L3.38071 10.2509ZM4.09333 4.26013L4.60692 4.80669C4.61243 4.80151 4.61786 4.79625 4.62321 4.79091L4.09333 4.26013ZM0.25 3.1668V7.1668H1.75V3.1668H0.25ZM1 7.9168H5V6.4168H1V7.9168ZM1.96595 10.7494C2.45225 12.1296 3.37395 13.3145 4.59219 14.1254L5.42335 12.8767C4.47583 12.246 3.75894 11.3245 3.38071 10.2509L1.96595 10.7494ZM4.59219 14.1254C5.81043 14.9363 7.25921 15.3293 8.72024 15.2452L8.63405 13.7477C7.4977 13.8131 6.37087 13.5074 5.42335 12.8767L4.59219 14.1254ZM8.72024 15.2452C10.1813 15.1611 11.5754 14.6045 12.6925 13.6592L11.7236 12.5141C10.8547 13.2493 9.7704 13.6823 8.63405 13.7477L8.72024 15.2452ZM12.6925 13.6592C13.8097 12.7139 14.5894 11.4311 14.9141 10.0041L13.4515 9.67128C13.1989 10.7811 12.5925 11.7789 11.7236 12.5141L12.6925 13.6592ZM14.9141 10.0041C15.2388 8.57716 15.091 7.08332 14.4929 5.74768L13.1239 6.36072C13.5891 7.39955 13.7041 8.56143 13.4515 9.67128L14.9141 10.0041ZM14.4929 5.74768C13.8948 4.41203 12.8788 3.30695 11.5981 2.59892L10.8724 3.91169C11.8685 4.46237 12.6587 5.32188 13.1239 6.36072L14.4929 5.74768ZM11.5981 2.59892C10.3173 1.8909 8.84113 1.6183 7.39196 1.82219L7.60094 3.30756C8.72808 3.14898 9.87622 3.361 10.8724 3.91169L11.5981 2.59892ZM7.39196 1.82219C5.94279 2.02608 4.59914 2.69542 3.56345 3.72935L4.62321 4.79091C5.42875 3.98674 6.47381 3.46614 7.60094 3.30756L7.39196 1.82219ZM3.57975 3.71356L0.486418 6.62023L1.51358 7.71336L4.60692 4.80669L3.57975 3.71356Z"
        [attr.fill]="color"
        style="=fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class RotateIconComponent extends BaseIconComponent {
  @Input() override height = 16
  @Input() override width = 16
}
