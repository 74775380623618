import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { AppFeatures } from '@ti-platform/contracts'
import { Profile } from '@ti-platform/web/auth'
import { PricingPlanService } from '@ti-platform/web/common'

@Directive({
  selector: '[appCheckPricingPlan]',
})
export class CheckPricingPlanDirective {
  protected readonly profile = inject(Profile)
  protected readonly templateRef = inject(TemplateRef)
  protected readonly viewContainerRef = inject(ViewContainerRef)
  protected readonly pricingPlanService = inject(PricingPlanService)

  protected prevFeatures?: AppFeatures | AppFeatures[]

  @Input() set appCheckPricingPlan(features: AppFeatures | AppFeatures[]) {
    if (this.prevFeatures !== features) {
      this.prevFeatures = features
      this.refresh(Array.isArray(features) ? features : features ? [features] : [])
    }
  }

  protected refresh(features: AppFeatures[]) {
    this.pricingPlanService
      .areFeaturesAllowed(features)
      .then((isAllowed: boolean) => {
        if (isAllowed) {
          this.viewContainerRef.createEmbeddedView(this.templateRef).markForCheck()
        } else {
          this.viewContainerRef.clear()
        }
      })
      .catch((error) => {
        console.error(`Cannot refresh CheckPricingPlanDirective`, error)
      })
  }
}
