import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { DateRange, PreDefinedRange } from '@ti-platform/web/common'
import { FilterPanelService } from '../filter-panel/filter-panel.service'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout'

@Component({
  selector: 'app-header-action-menu',
  templateUrl: 'header-action-menu.component.html',
  styleUrls: ['header-action-menu.component.scss'],
})
export class HeaderActionMenuComponent {
  @Output() dateRangeChanged = new EventEmitter<DateRange | PreDefinedRange | undefined>()

  @Input() style: 'filter' | 'menu' = 'filter'
  @Input() dateRange?: DateRange | PreDefinedRange | null
  @Input() showDatePicker = true
  @Input() showFilters = true
  @Input() filtersCount?: number

  @Input() gridSortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null

  protected readonly deviceService = inject(DeviceService)
  protected readonly filterPanelService = inject(FilterPanelService)
}
