<div #mapRef class="map-container"></div>

@if (showZoomControl) {
  <div class="zoom-controls">
    <p-button
      icon="pi"
      class="h--38 zoom-out"
      [severity]="'secondary'"
      (onClick)="adapter.zoomOut()"
    >
      <app-icon-minus [width]="20" [height]="20" color="var(--color-primary-500)"></app-icon-minus>
    </p-button>
    <p-button icon="pi" class="h--38 zoom-in" [severity]="'secondary'" (onClick)="adapter.zoomIn()">
      <app-icon-plus [width]="20" [height]="20" color="var(--color-primary-500)"></app-icon-plus>
    </p-button>
  </div>
}

@if (showGuide) {
  <app-map-guide [addBottomOffset]="showGeofenceControl"></app-map-guide>
}

@if (showTilesSelector) {
  <app-map-tiles-selector></app-map-tiles-selector>
}

@if (showGeofenceControl && geofenceManager) {
  <div class="geofence-control" [appCheckAccess]="[Resource.Location, Action.View]">
    <app-icon-geofence height="20" width="21" color="var(--color-primary-500)"></app-icon-geofence>
    <p-inputSwitch
      [ngModel]="geofenceManager ? (geofenceManager.isEnabled$ | async) : false"
      (ngModelChange)="geofenceManager ? geofenceManager.isEnabled$.next($event) : null"
    ></p-inputSwitch>
  </div>
}

@if ((adapter.state.pitch$ | async) || (adapter.state.bearing$ | async)) {
  <div class="zoom-reset">
    <p-button
      icon="pi"
      class="h--38"
      [severity]="'secondary'"
      [pTooltip]="'map.tooltips.reset-the-map' | translate"
      [tooltipPosition]="'left'"
      (onClick)="adapter.reset()"
    >
      <app-icon-compass [width]="20" [height]="20" [color]="'var(--icon-color)'"></app-icon-compass>
    </p-button>
  </div>
}

<div class="location-search-actions-wrapper">
  @if (showLocationSearch) {
    <div class="location-search" [appCheckAccess]="[Resource.Location, Action.View]">
      <app-map-location-search
        #locationSearchRef
        (cleared)="cancelGeofenceEditing()"
        (placeSelected)="addDraftGeofenceFromLocation($event)"
      ></app-map-location-search>
    </div>
  }

  <div class="actions flex gap-12">
    @if (showFitAllVehiclesBtn && vehiclesManager) {
      <p-button
        icon="pi"
        class="h--38"
        [severity]="'secondary'"
        [appTooltip]="'map.tooltips.see-all-vehicles' | translate"
        [tooltipPosition]="'bottom'"
        [tooltipMobile]="true"
        (click)="fitMapToViewAllVehicles()"
      >
        <app-icon-vehicles
          [width]="26"
          [height]="26"
          [color]="'var(--icon-color)'"
        ></app-icon-vehicles>
      </p-button>
    }

    @if (showNavigateHomebaseBtn && homebaseProvider) {
      <div
        class="button-wrapper"
        [appTooltip]="
          ((homebaseProvider.point$ | async)
            ? 'map.tooltips.go-to-homebase'
            : 'map.tooltips.homebase-address-invalid'
          ) | translate
        "
        [tooltipMobile]="true"
        [tooltipPosition]="'bottom'"
      >
        <p-button
          icon="pi"
          class="h--38"
          [disabled]="(homebaseProvider.point$ | async) === undefined"
          [severity]="'secondary'"
          (click)="centerMapOverFleetHomeBase()"
        >
          <app-icon-garage
            [width]="18"
            [height]="18"
            [color]="'var(--icon-color)'"
          ></app-icon-garage>
        </p-button>
      </div>
    }
  </div>
</div>

<div style="display: none">
  <ng-container #dynamicComponentContainer></ng-container>
</div>
