import { Component, inject } from '@angular/core'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n/services/language.service'

@Component({
  selector: 'app-lang-toggle',
  template: `
    <p-button
      [severity]="'secondary'"
      class="h--42"
      icon="pi pi-globe"
      (click)="menu.toggle($event)"
    ></p-button>

    <app-dropdown-select
      #menu
      [isCheckmarkVisible]="true"
      [value]="languageService.current$ | async"
      [options]="langOptions"
      (valueChange)="toggleLanguage($event)"
    ></app-dropdown-select>
  `,
})
export class LangToggleComponent {
  protected readonly languageService = inject(LanguageService)

  protected readonly langOptions = [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' },
  ]

  protected async toggleLanguage(event: any) {
    return this.languageService.selectLanguage(event)
  }
}
