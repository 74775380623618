@if (columnSortOptions().length) {
  @for (option of columnSortOptions(); track option.column) {
    <div
      class="list-item text-14 fw-400 clickable"
      [class.selected]="option.column === gridSortOrder?.column"
      (click)="toggleColumnOrder(option.column)"
    >
      <div class="option-data">
        {{ option.label | translate }}

        <div class="sort-icon" [class.rotated]="gridSortOrder?.order === 'ASC'">
          <ng-container [ngTemplateOutlet]="sortIconTmpl"></ng-container>
        </div>
      </div>

      @if (gridDefaultSortOrder && gridDefaultSortOrder.column === option.column) {
        <div
          class="default text-14 fw-400"
          [class.active]="
            gridSortOrder?.column === gridDefaultSortOrder.column &&
            gridSortOrder?.order === gridDefaultSortOrder.order
          "
        >
          {{ 'filter-panel.default' | translate }}
        </div>
      }
    </div>
  }
}

@if (customSortOptions?.length) {
  @for (option of customSortOptions; track option.value) {
    <div
      class="list-item text-14 fw-400 clickable"
      [class.selected]="option.value === customSortValue"
      (click)="selectCustomOption(option.value)"
    >
      {{ option.label | translate }}
    </div>
  }
}

<!-- TODO: Move this icon to the shared components -->
<ng-template #sortIconTmpl>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.80788 13.0004C2.6631 13.0004 2.51832 12.9462 2.40402 12.83L0.498989 10.8946C0.278006 10.67 0.278006 10.2984 0.498989 10.0739C0.719973 9.8494 1.08574 9.8494 1.30672 10.0739L2.80788 11.5991L4.30904 10.0739C4.53003 9.8494 4.89579 9.8494 5.11678 10.0739C5.33776 10.2984 5.33776 10.67 5.11678 10.8946L3.21175 12.83C3.09745 12.9462 2.95267 13.0004 2.80788 13.0004Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
    <path
      d="M2.80788 13.0004C2.49546 13.0004 2.23642 12.7371 2.23642 12.4197V1.58101C2.23642 1.26359 2.4955 1.00037 2.80793 1.00037C3.12035 1.00037 3.37944 1.26359 3.37944 1.58101V12.4197C3.37944 12.7371 3.12031 13.0004 2.80788 13.0004Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
    <path
      d="M13.0951 4.09714H6.99899C6.68656 4.09714 6.42748 3.83391 6.42748 3.51649C6.42748 3.19908 6.68656 2.93585 6.99899 2.93585H13.0951C13.4075 2.93585 13.6666 3.19908 13.6666 3.51649C13.6666 3.83391 13.4075 4.09714 13.0951 4.09714Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
    <path
      d="M10.047 8.7423H6.99899C6.68656 8.7423 6.42748 8.47907 6.42748 8.16166C6.42748 7.84424 6.68656 7.58101 6.99899 7.58101H10.047C10.3595 7.58101 10.6185 7.84424 10.6185 8.16166C10.6185 8.47907 10.3595 8.7423 10.047 8.7423Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
    <path
      d="M8.52301 11.0649H6.99899C6.68656 11.0649 6.42748 10.8017 6.42748 10.4842C6.42748 10.1668 6.68656 9.90359 6.99899 9.90359H8.52301C8.83543 9.90359 9.09452 10.1668 9.09452 10.4842C9.09452 10.8017 8.83543 11.0649 8.52301 11.0649Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
    <path
      d="M11.5711 6.41972H6.99899C6.68656 6.41972 6.42748 6.15649 6.42748 5.83908C6.42748 5.52166 6.68656 5.25843 6.99899 5.25843H11.5711C11.8835 5.25843 12.1426 5.52166 12.1426 5.83908C12.1426 6.15649 11.8835 6.41972 11.5711 6.41972Z"
      fill="#7F56D9"
      style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
    />
  </svg>
</ng-template>
