<div
  [style.background-image]="'url(./assets/images/login-page-background.png)'"
  class="full-screen-container background-container"
>
  <div class="sign-container">
    <p-panel class="panel-primary header-32 w-608">
      <ng-template pTemplate="header">
        <header class="flex">
          <svg
            fill="none"
            height="48"
            viewBox="0 0 146 48"
            width="146"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.8148 19.7647C38.8148 32.0922 27.7249 39.5294 19.4074 48C10.3968 38.8235 0 32.0922 0 19.7647C0 8.84896 8.68899 0 19.4074 0C30.1258 0 38.8148 8.84896 38.8148 19.7647Z"
              fill="#7F56D9"
              style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
            />
            <path
              d="M33.9629 19.2001C33.9629 27.153 27.4462 33.6001 19.4074 33.6001C11.3686 33.6001 4.85181 27.153 4.85181 19.2001C4.85181 11.2471 11.3686 4.80005 19.4074 4.80005C27.4462 4.80005 33.9629 11.2471 33.9629 19.2001Z"
              fill="white"
              style="fill: white; fill-opacity: 1"
            />
            <path
              d="M24.9656 15.9774C25.9348 15.3849 26.6472 15.5511 26.9868 15.71C27.3264 15.8618 27.898 16.2736 27.898 17.3069V21.0933C27.898 22.1266 27.3264 22.5384 26.9868 22.6902C26.8294 22.7624 26.5892 22.8419 26.2827 22.8419C25.9265 22.8419 25.4792 22.7407 24.9656 22.4228L23.7479 21.6713C23.6568 23.9186 22.5385 24.8001 19.8214 24.8001H14.8512C11.952 24.8001 10.9165 23.0298 10.9165 21.3678V17.0324C10.9165 14.5611 12.0182 13.6001 14.8512 13.6001H19.8214C22.5385 13.6001 23.6568 14.4816 23.7479 16.7289L24.9656 15.9774Z"
              fill="#7F56D9"
              style="fill: #7f56d9; fill: color(display-p3 0.4964 0.3384 0.8498); fill-opacity: 1"
            />
            <path
              d="M117.057 33.1202C117.057 35.4818 123.617 33.485 123.617 37.613C123.617 39.5906 121.773 40.9346 119.173 40.9346C116.611 40.9346 114.767 39.7634 114.398 37.2866H116.883C117.057 38.4002 117.95 39.0338 119.25 39.0338C120.473 39.0338 121.113 38.5154 121.113 37.7666C121.113 35.4626 114.69 37.4402 114.69 33.2738C114.69 31.6034 116.009 30.0674 118.726 30.0674C121.113 30.0674 122.918 31.181 123.229 33.7346H120.745C120.551 32.4866 119.813 31.9874 118.629 31.9874C117.639 31.9874 117.057 32.4674 117.057 33.1202Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M108.848 37.4401V32.2561H107.509V30.3553H108.848V27.9937H111.332V30.3553H113.525V32.2561H111.332V37.2289C111.332 38.3041 111.643 38.8801 113.02 38.8801H113.486V40.6849C113.215 40.8193 112.652 40.9345 111.972 40.9345C109.857 40.9345 108.848 39.7441 108.848 37.4401Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M97.1653 40.6465V26.8225H99.6494V31.7377C100.329 30.6241 101.416 30.0673 102.735 30.0673C104.812 30.0673 106.229 31.2961 106.229 33.7921V40.6465H103.744V34.2913C103.744 32.8897 103.143 32.1025 101.881 32.1025C100.503 32.1025 99.6494 33.2929 99.6494 34.6753V40.6465H97.1653Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M89.5404 40.685C86.901 40.685 85.2708 38.669 85.2708 35.3858C85.2708 31.9682 86.9398 30.0674 89.5404 30.0674C90.7825 30.0674 91.7528 30.5282 92.3933 31.3922V30.3554H94.8774V40.973C94.8774 43.757 92.9367 45.1778 90.3555 45.1778C87.7937 45.1778 85.9306 44.0066 85.5619 41.5682H88.0654C88.2207 42.6626 89.1134 43.2386 90.3167 43.2386C91.5587 43.2386 92.3933 42.6434 92.3933 41.0114V39.2834C91.714 40.205 90.7436 40.685 89.5404 40.685ZM90.1032 38.6498C91.5976 38.6498 92.3933 37.4978 92.3933 35.3858C92.3933 33.293 91.5588 32.1026 90.1032 32.1026C88.6282 32.1026 87.8325 33.2546 87.8325 35.3858C87.8325 37.4786 88.6282 38.6498 90.1032 38.6498Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M80.9988 29.2033V26.8225H83.4829V29.2033H80.9988ZM80.9988 40.6465V30.3553H83.4829V40.6465H80.9988Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M71.3506 30.8737C71.3506 34.0033 79.2494 31.4689 79.2494 36.8065C79.2494 39.4369 77.0758 40.9345 74.087 40.9345C71.0789 40.9345 68.944 39.5521 68.4395 36.7105H71.1759C71.5058 38.0929 72.515 38.8609 74.1064 38.8609C75.7366 38.8609 76.5712 38.1889 76.5712 37.1329C76.5712 33.8113 68.6917 36.3073 68.6917 31.1041C68.6917 29.0305 70.419 27.3025 73.5436 27.3025C76.2024 27.3025 78.4537 28.5697 78.8806 31.5073H76.1054C75.7949 30.0481 74.8827 29.3761 73.466 29.3761C72.1463 29.3761 71.3506 29.9713 71.3506 30.8737Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M57.6304 40.6466V30.3554H60.1145V31.7378C60.7938 30.6242 61.8806 30.0674 63.2003 30.0674C65.2769 30.0674 66.6936 31.2962 66.6936 33.7922V40.6466H64.2095V34.2914C64.2095 32.8898 63.6079 32.1026 62.3464 32.1026C60.9684 32.1026 60.1145 33.293 60.1145 34.6754V40.6466H57.6304Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M52.8733 29.2033V26.8225H55.3574V29.2033H52.8733ZM52.8733 40.6465V30.3553H55.3574V40.6465H52.8733Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M139.44 9.12018C139.44 11.4818 146 9.48498 146 13.613C146 15.5906 144.156 16.9346 141.556 16.9346C138.994 16.9346 137.15 15.7634 136.781 13.2866H139.266C139.44 14.4002 140.333 15.0338 141.633 15.0338C142.856 15.0338 143.496 14.5154 143.496 13.7666C143.496 11.4626 137.073 13.4402 137.073 9.27378C137.073 7.60338 138.392 6.06738 141.109 6.06738C143.496 6.06738 145.301 7.18098 145.612 9.73458H143.128C142.934 8.48658 142.196 7.98738 141.012 7.98738C140.023 7.98738 139.44 8.46738 139.44 9.12018Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M135.566 10.0034H133.082C132.849 8.65938 132.034 8.10258 130.986 8.10258C129.453 8.10258 128.637 9.31218 128.637 11.5202C128.637 13.6898 129.491 14.8994 131.063 14.8994C132.131 14.8994 132.888 14.3234 133.14 13.1522H135.644C135.178 15.725 133.353 16.9346 131.005 16.9346C127.939 16.9346 126.076 14.861 126.076 11.5202C126.076 8.02578 127.997 6.06738 130.986 6.06738C133.353 6.06738 135.217 7.37298 135.566 10.0034Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M121.804 5.20331V2.82251H124.288V5.20331H121.804ZM121.804 16.6465V6.35531H124.288V16.6465H121.804Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M115.671 13.4401V8.25605H114.332V6.35525H115.671V3.99365H118.155V6.35525H120.348V8.25605H118.155V13.2289C118.155 14.3041 118.465 14.8801 119.843 14.8801H120.309V16.6849C120.037 16.8193 119.475 16.9345 118.795 16.9345C116.68 16.9345 115.671 15.7441 115.671 13.4401Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M106.471 9.73458H103.967C104.297 7.44978 105.985 6.06738 108.605 6.06738C111.497 6.06738 112.972 7.75698 112.972 10.1378V14.1314C112.972 15.3986 113.088 16.109 113.283 16.6466H110.701C110.566 16.301 110.507 15.8018 110.488 15.245C109.634 16.4354 108.353 16.9346 107.111 16.9346C105.073 16.9346 103.618 15.9362 103.618 13.9586C103.618 12.557 104.413 11.501 106.005 10.9826C107.383 10.5218 108.722 10.4066 110.468 10.3874V10.1378C110.468 8.81298 109.828 8.10258 108.45 8.10258C107.266 8.10258 106.626 8.75538 106.471 9.73458ZM106.121 13.8242C106.121 14.6114 106.742 15.1106 107.751 15.1106C109.285 15.1106 110.468 13.8818 110.468 12.3458V11.9042C106.936 11.9618 106.121 12.7874 106.121 13.8242Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M87.0256 16.6466V6.35538H89.5098V7.68018C90.1696 6.60498 91.1788 6.06738 92.4015 6.06738C93.7212 6.06738 94.7498 6.64338 95.2738 7.81458C95.9725 6.64338 97.0787 6.06738 98.4178 6.06738C100.417 6.06738 101.775 7.29618 101.775 9.79218V16.6466H99.2911V10.2914C99.2911 8.88978 98.7671 8.10258 97.6609 8.10258C96.4188 8.10258 95.6425 9.29298 95.6425 10.6754V16.6466H93.1584V10.2914C93.1584 8.88978 92.6344 8.10258 91.5282 8.10258C90.2861 8.10258 89.5098 9.29298 89.5098 10.6754V16.6466H87.0256Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M85.2391 13.2866C84.6763 15.6674 82.8132 16.9346 80.329 16.9346C77.185 16.9346 75.2637 14.8802 75.2637 11.5202C75.2637 8.02578 77.2044 6.06738 80.232 6.06738C83.2789 6.06738 85.1615 8.10258 85.1615 11.4242V12.0002H77.8254C77.9419 13.8818 78.8152 14.9186 80.329 14.9186C81.4935 14.9186 82.2892 14.381 82.6191 13.2866H85.2391ZM80.232 8.08338C78.9123 8.08338 78.1166 8.90898 77.8837 10.4642H82.5609C82.328 8.92818 81.5323 8.08338 80.232 8.08338Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M70.9919 16.6465V2.82251H73.4761V16.6465H70.9919Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M69.2054 13.2866C68.6426 15.6674 66.7795 16.9346 64.2953 16.9346C61.1513 16.9346 59.23 14.8802 59.23 11.5202C59.23 8.02578 61.1707 6.06738 64.1983 6.06738C67.2452 6.06738 69.1278 8.10258 69.1278 11.4242V12.0002H61.7918C61.9082 13.8818 62.7815 14.9186 64.2953 14.9186C65.4598 14.9186 66.2555 14.381 66.5854 13.2866H69.2054ZM64.1983 8.08338C62.8786 8.08338 62.0829 8.90898 61.85 10.4642H66.5272C66.2943 8.92818 65.4986 8.08338 64.1983 8.08338Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
            <path
              d="M52.7688 16.6466V5.72178H48.5186V3.59058H59.639V5.72178H55.3888V16.6466H52.7688Z"
              fill="#101828"
              style="fill: #101828; fill: color(display-p3 0.0627 0.0941 0.1569); fill-opacity: 1"
            />
          </svg>

          <div class="counter text-14">TSP admin</div>
        </header>
      </ng-template>

      <form [formGroup]="signInForm" class="p-fluid">
        <section class="inputs-section">
          <input
            class="h--40 mb-10"
            formControlName="username"
            id="username"
            pInputText
            placeholder="{{ 'sign-in.username' | translate }}"
            type="text"
          />

          <app-password-input
            [height]="40"
            formControlName="password"
            placeholder="{{ 'sign-in.password' | translate }}"
          ></app-password-input>

          <div class="errors-box">
            <p class="error-message">{{ errorMessage() }}</p>
          </div>
        </section>

        <div (click)="forgotPassword.open()" class="forgot-section mb-24 text-14 fw-600">
          {{ 'sign-in.forgot_password' | translate }}
        </div>

        <app-forgot-password #forgotPassword></app-forgot-password>

        <p-button
          (onClick)="onSignIn()"
          [disabled]="signingIn()"
          [label]="'sign-in.heading' | translate"
          class="mb-24 h--40 text-14 fw-700 w100"
          type="submit"
        ></p-button>

        <div class="remember-section flex text-14">
          <p-checkbox [binary]="true" formControlName="rememberMe" inputId="binary"></p-checkbox>
          {{ 'sign-in.remember_me' | translate }}
        </div>
      </form>
    </p-panel>

    <div
      [style.background-image]="bannerUrl"
      (click)="openBannerLink()"
      class="banner clickable"
    ></div>
  </div>

  <app-lang-toggle class="lang-toggle"></app-lang-toggle>
</div>
