import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'

@Directive({
  selector: '[appOnInit],[appOnDestroy]',
})
export class LifecycleDirective implements OnInit, OnDestroy {
  public constructor(private elementRef: ElementRef) {}

  @Output() appOnInit: EventEmitter<ElementRef> = new EventEmitter<ElementRef>()
  @Output() appOnDestroy: EventEmitter<ElementRef> = new EventEmitter<ElementRef>()

  public ngOnInit(): void {
    this.appOnInit.emit(this.elementRef)
  }

  public ngOnDestroy(): void {
    this.appOnDestroy.emit(this.elementRef)
  }
}
