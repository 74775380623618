import { MapLibreMapAdapter } from '../adapters'
import { StaticVehicleMarkerComponent } from '../components'
import { Coordinates, VehicleStatus } from '../contracts'
import { BaseMarker } from './base'
import { MaplibreComponentMarker } from './component'

export interface StaticVehicleMarkerOptions {
  id: string
  coordinates: Coordinates
  color: string
  icon: string
  status?: VehicleStatus.Idling | VehicleStatus.Stopped
}

export abstract class AbstractStaticVehicleMarker extends BaseMarker {
  public override readonly options!: StaticVehicleMarkerOptions
}

export class MaplibreStaticVehicleMarker extends AbstractStaticVehicleMarker {
  protected readonly marker!: MaplibreComponentMarker<StaticVehicleMarkerComponent>

  public constructor(
    public override readonly options: StaticVehicleMarkerOptions,
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.marker = new MaplibreComponentMarker(
      {
        icon: this.options.icon,
        color: this.options.color,
        status: this.options.status,
        latLng: this.options.coordinates,
      },
      this.adapter,
      StaticVehicleMarkerComponent,
    )
  }

  public override destroy() {
    super.destroy()
    this.marker?.destroy()
  }
}
