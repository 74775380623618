export const formatFileSize = (size: number) => {
  if (!size) {
    return '0 MB'
  }
  const megabytes = Math.round((size / (1024 * 1024)) * 10) / 10
  if (megabytes) {
    return `${megabytes} MB`
  }

  const kilobytes = Math.round((size / 1024) * 10) / 10
  if (kilobytes) {
    return `${kilobytes} KB`
  }

  return `${size} B`
}
