import { Component, inject } from '@angular/core'
import { BaseSignInComponent } from '@ti-platform/web/auth/components'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-sign-in-page',
  templateUrl: 'sign-in-page.component.html',
  styleUrl: 'sign-in-page.component.scss',
})
export class TspAdminSignInPageComponent extends BaseSignInComponent {
  protected readonly is1NCEBannerEnabled = true
  protected override languageService = inject(LanguageService)

  get bannerUrl() {
    if (this.is1NCEBannerEnabled) {
      switch (this.languageService.current()) {
        case 'en': {
          return 'url(./assets/images/banner-1NCE-english.png)'
        }
        case 'es': {
          return 'url(./assets/images/banner-1NCE-spanish.png)'
        }
      }
    }
    return 'url(./assets/images/banner-placeholder.png)'
  }

  public openBannerLink() {
    if (this.is1NCEBannerEnabled) {
      switch (this.languageService.current()) {
        case 'en': {
          return window.open('https://hubs.li/Q02N_D6l0', '_blank')
        }
        case 'es': {
          return window.open('https://hubs.li/Q02N_FfB0', '_blank')
        }
      }
    }
  }
}
