<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (state.items$ | async)?.length === 0 }">
  <app-data-grid
    [data]="(state.items$ | async) ?? []"
    [columns]="(state.columns$ | async) ?? []"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [noDataTitle]="noDataTitleKey | translate"
    [noDataDescription]="noDataDescriptionKey | translate"
    [noDataIconTemplate]="noDataIconTmpl"
    [noDataAdditionalContentTemplate]="noDevicesAdditionalTmpl"
    [templates]="{
      name: deviceNameTmpl,
      deviceTest: deviceTestTmpl,
    }"
    (actionsClicked)="onMenuClick()"
    (loadMoreClicked)="onLoadMore()"
    (sortUpdated)="onSortUpdated($event)"
    [isMultiSelectable]="devicesViewModel.isMultiSelectable"
    [keyColumn]="devicesViewModel.keyColumn"
    [multiSelection]="(state.multiSelectedItems$ | async) ?? []"
    (rowSelected)="devicesViewModel.multiSelectItem($event)"
    (rowUnselected)="devicesViewModel.multiUnselectItem($event)"
  ></app-data-grid>
</div>

<ng-template #deviceTestTmpl let-deviceTest let-row="row">
  <div class="button-col-wrapper">
    @if (deviceTest === true) {
      <p-button
        class="text-button h--34 p--8 text-12 fw-600"
        [outlined]="true"
        [disabled]="!!(deviceTestStatus.deviceId && deviceTestStatus.isLoading)"
        [appCheckAccess]="[Resource.Device, Action.Update]"
        (onClick)="onClickTest(row.id)"
      >
        <app-icon-play-solid
          class="icon"
          [width]="16"
          [height]="16"
          [color]="'var(--color-primary-500)'"
        ></app-icon-play-solid>
        {{ 'tsp-admin.devices.grid.test' | translate }}
      </p-button>
    }
  </div>
</ng-template>

<ng-template #deviceNameTmpl let-name let-row="row">
  <app-inline-text-editor
    (cancelled)="onCancelEditDeviceName()"
    (enterEdit)="onClickEditName(row)"
    (submitted)="onSaveDeviceName(row, $event)"
    [isEditMode]="deviceEditNameStatus.device?.id === row.id"
    [isLoading]="deviceEditNameStatus.isLoading"
    [maxLength]="nameMaxLength"
    [minLength]="nameMinLength"
    [value]="name"
  ></app-inline-text-editor>
</ng-template>

<ng-template #noDataIconTmpl>
  @if (!!(devicesViewModel.state.search$ | async)) {
    <app-icon-no-search [width]="32" [height]="32"></app-icon-no-search>
  } @else {
    <app-icon-no-devices></app-icon-no-devices>
  }
</ng-template>

<ng-template #noDevicesAdditionalTmpl>
  @if (!!!(devicesViewModel.state.search$ | async)) {
    <p-button
      class="no-device-button h--40 p--18 text-14 fw-500"
      [severity]="'secondary'"
      [appCheckAccess]="[Resource.Device, Action.Create]"
      (click)="onAddClick()"
    >
      <app-icon-plus
        class="icon"
        [width]="20"
        [height]="20"
        [color]="'var(--color-primary-500)'"
      ></app-icon-plus>
      {{ 'tsp-admin.devices.add-new-device' | translate }}
    </p-button>
  }
</ng-template>
