<header class="tsp-admin-header">
  <div class="heading-group flex">
    <p-button
      (click)="goBack()"
      [severity]="'secondary'"
      class="button-back mr-12 h--48 flex"
      icon="pi"
    >
      <app-icon-arrow [height]="24" [width]="24" direction="left"></app-icon-arrow>
    </p-button>
    <h1 class="text-32 fw-600">{{ 'tsp-admin.billing.history' | translate }}</h1>
  </div>
</header>

<main class="scroll-wrapper scrollbar-gray">
  <section class="invoices">
    @for (record of records; track record.displayDate) {
      <div
        [routerLink]="['/billing/', record.year, record.month]"
        class="report-item"
      >
        <header class="flex clickable report-details">
          <div class="avatar flex mr-12">
            <app-icon-billing [color]="'var(--color-primary-500)'"></app-icon-billing>
          </div>
          <div class="details-group flex--col">
            <div class="text-18 fw-600">
              {{ 'tsp-admin.billing.grid.billingPeriod' | translate }}
              : {{ record.displayDate | formatDate: 'month-year' }}
            </div>
          </div>
        </header>
        <section class="flex row view-details">
          <div class="details-button text-14 fw-600" [routerLink]="['/billing/', record.year, record.month]">
            {{ 'tsp-admin.billing.view-details' | translate }}
          </div>
        </section>
      </div>
    }
  </section>
</main>
