import { Component, inject, Input, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

export interface WarnDialogData {
  summaryKey?: string
  descriptionKey?: string
  closeButtonKey?: string
  iconComponent?: any | null
  iconComponentInputs?: Record<string, any> | null
}

@Component({
  selector: 'app-warn-dialog',
  templateUrl: 'warn-dialog.component.html',
  styleUrl: 'warn-dialog.component.scss',
})
export class WarnDialogComponent implements OnInit, WarnDialogData {
  @Input() summaryKey = ''
  @Input() descriptionKey = ''
  @Input() closeButtonKey = 'common.ok'
  @Input() iconComponent?: any | null = null
  @Input() iconComponentInputs?: Record<string, any> | null = null

  protected readonly dynamicDialogRef = inject(DynamicDialogRef)
  protected readonly dynamicDialogConfig = inject(DynamicDialogConfig)

  public ngOnInit() {
    // Assign data from dynamic dialog config
    Object.assign(this, this.dynamicDialogConfig.data)
  }
}
