import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'clearBaseUrl',
})
export class ClearBaseUrlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return ''
    }

    return value.replace(/^(https?:\/\/)?/, '').replace(/\/$/, '')
  }
}
