import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-general',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 12.75C12.4141 12.75 12.7498 12.4142 12.7498 12C12.7498 11.5858 12.4141 11.25 11.9998 11.25V12.75ZM6.99776 11.25C6.58355 11.25 6.24776 11.5858 6.24776 12C6.24776 12.4142 6.58355 12.75 6.99776 12.75V11.25ZM15.0011 16.7516C15.4153 16.7516 15.7511 16.4158 15.7511 16.0016C15.7511 15.5874 15.4153 15.2516 15.0011 15.2516V16.7516ZM6.99776 15.2516C6.58355 15.2516 6.24776 15.5874 6.24776 16.0016C6.24776 16.4158 6.58355 16.7516 6.99776 16.7516V15.2516ZM17.0019 8.7483C17.4161 8.7483 17.7519 8.41251 17.7519 7.9983C17.7519 7.58409 17.4161 7.2483 17.0019 7.2483V8.7483ZM6.99776 7.2483C6.58355 7.2483 6.24776 7.58409 6.24776 7.9983C6.24776 8.41251 6.58355 8.7483 6.99776 8.7483V7.2483ZM11.9998 11.25H6.99776V12.75H11.9998V11.25ZM15.0011 15.2516H6.99776V16.7516H15.0011V15.2516ZM16.0015 20.2537H7.99818V21.7537H16.0015V20.2537ZM7.99818 20.2537C5.64982 20.2537 3.74609 18.35 3.74609 16.0016H2.24609C2.24609 19.1784 4.82139 21.7537 7.99818 21.7537V20.2537ZM3.74609 16.0016V7.9983H2.24609V16.0016H3.74609ZM3.74609 7.9983C3.74609 5.64994 5.64982 3.74622 7.99818 3.74622V2.24622C4.82139 2.24622 2.24609 4.82151 2.24609 7.9983H3.74609ZM7.99818 3.74622H16.0015V2.24622H7.99818V3.74622ZM16.0015 3.74622C18.3499 3.74622 20.2536 5.64994 20.2536 7.9983H21.7536C21.7536 4.82151 19.1783 2.24622 16.0015 2.24622V3.74622ZM20.2536 7.9983V16.0016H21.7536V7.9983H20.2536ZM20.2536 16.0016C20.2536 18.35 18.3499 20.2537 16.0015 20.2537V21.7537C19.1783 21.7537 21.7536 19.1784 21.7536 16.0016H20.2536ZM17.0019 7.2483H6.99776V8.7483H17.0019V7.2483Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class GeneralIconComponent extends BaseIconComponent {}
