import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-route',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4 12.4L6.4 12.0525C6.4 9.61469 6.4 8.39579 6.83783 7.93309C7.21629 7.53313 7.77382 7.35589 8.31377 7.46387C8.93841 7.58879 9.64228 8.58392 11.05 10.5742L13.35 13.8258C14.7577 15.8161 15.4616 16.8112 16.0862 16.9361C16.6262 17.0441 17.1837 16.8669 17.5622 16.4669C18 16.0042 18 14.7853 18 12.3475L18 11.6M6.4 15.2C7.72548 15.2 8.8 16.2745 8.8 17.6C8.8 18.9255 7.72548 20 6.4 20C5.07452 20 4 18.9255 4 17.6C4 16.2745 5.07452 15.2 6.4 15.2ZM17.6 4C18.9255 4 20 5.07452 20 6.4C20 7.72548 18.9255 8.8 17.6 8.8C16.2745 8.8 15.2 7.72548 15.2 6.4C15.2 5.07452 16.2745 4 17.6 4Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class RouteIconComponent extends BaseIconComponent {}
