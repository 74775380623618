import { Marker } from 'maplibre-gl'
import { MapLibreMapAdapter } from '../adapters'
import { BaseMarker } from './base'
import { Coordinates } from '../contracts'
import { toLngLat } from '../utils'

export class MaplibreGeofenceLabelMarker extends BaseMarker {
  protected nativeRef: Marker

  public constructor(
    public override readonly options: { latLng: Coordinates; label: string },
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))

    // Disable cursor interaction for marker
    this.nativeRef.getElement().style.pointerEvents = 'none'

    this.nativeRef.getElement().innerHTML = `
      <div class="geofence-label text-12 fw-600">
        ${this.options.label}
      </div>
    `

    this.nativeRef.addTo(this.adapter.map)
    this.destroy$.subscribe(() => this.nativeRef.remove())
  }
}
