<p-panel
  class="panel-primary w-430 header-24 content-24 with-footer footer-24 border-top border-bottom"
>
  <!-- header -->
  <ng-template pTemplate="header">
    <header class="header">
      {{ header }}
    </header>
  </ng-template>
  <!-- close button -->
  <ng-template pTemplate="icons">
    <button class="p-panel-header-icon" (click)="onClose()">
      <span class="pi pi-times"></span>
    </button>
  </ng-template>
  <!-- comment -->
  <div class="content">
    @if (completed$ | async) {
      <div class="description-text text-20 fw-500">
        {{ 'bulk-actions.completed' | translate }}
      </div>
      <div class="description-text text-14 fw-500 mt-8">
        {{ completeDescription }}
      </div>
      <p-progressBar [value]="100" styleClass="action-progress">
        <ng-template pTemplate="content"> </ng-template>
      </p-progressBar>
      @if ((failedItemKeys$ | async)?.length) {
        <p-accordion
          class="bulk-action-errors chevron-right expand-chevron-left"
          [(activeIndex)]="errorsAccordionIndex"
          [class]="{ expanded: errorsAccordionIndex === 0 }"
        >
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="bulk-action-errors__header">
                <app-icon-warning-circle
                  [color]="'var(--color-secondary-500)'"
                  [width]="16"
                  [height]="16"
                  class="mr-8"
                ></app-icon-warning-circle>
                <span class="bulk-action-errors__title text-13 fw-400">
                  {{ errorsSummary$ | async }}:
                </span>
              </div>
            </ng-template>
            <div class="bulk-action-errors__list scroll-wrapper scrollbar-gray">
              <ul>
                @for (element of failedDescriptionList$ | async; track element; let i = $index) {
                  <li>
                    {{ element }}
                  </li>
                }
              </ul>
            </div>
          </p-accordionTab>
        </p-accordion>
      }
    } @else {
      <div class="description-text text-14 fw-500">
        {{ description }}
      </div>
      <div class="description-text text-14 fw-500 mt-8">
        {{ 'bulk-actions.take-time' | translate }}...
      </div>
      <p-progressBar [value]="(progress$ | async) || 0" styleClass="action-progress">
        <ng-template pTemplate="content"> </ng-template>
      </p-progressBar>
    }
  </div>
  <!-- buttons -->
  <ng-template pTemplate="footer">
    <div class="footer">
      @if (completed$ | async) {
        <p-button
          (click)="onConfirm()"
          class="submit text-14 fw-600 w50 h--40 footer-button"
          type="submit"
        >
          {{ confirmButton }}
        </p-button>
      } @else {
        <p-button
          (click)="onCancel()"
          [severity]="'secondary'"
          class="cancel text-14 fw-600 w50 p--8 h--40 footer-button"
        >
          {{ cancelButton }}
        </p-button>
      }
    </div>
  </ng-template>
</p-panel>
