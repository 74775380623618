import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Fleet, FleetForm } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-admin-edit-fleet-dialog',
  templateUrl: 'fleet-edit.component.html',
  styleUrl: 'fleet-edit.component.scss',
})
export class TspAdminFleetEditDialogComponent {
  @Output() cancel = new EventEmitter<void>()
  @Output() update = new EventEmitter<FleetForm>()

  @Input() processing = false
  @Input() opened = false
  @Input() fleet: Partial<Fleet> = {}
  @Input() errorMessage = ''
}
