<p-dialog
  (visibleChange)="$event ? null : cancel.next()"
  [header]="'tsp-admin.fleets.grid.launch-new-btn' | translate"
  [modal]="true"
  [visible]="opened"
  class="dialog-primary dialog-content-flex extra-large"
>
  <app-tsp-admin-fleet-form
    (cancelEvent)="cancel.next()"
    (submitEvent)="launch.next($event)"
    [errorMessage]="errorMessage"
    [fleet]="fleet"
    [processing]="processing"
  ></app-tsp-admin-fleet-form>
</p-dialog>
