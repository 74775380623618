import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { PrimeNgModule } from '@ti-platform/web/ui-kit/prime-ng/prime-ng.module'
import { IconsModule } from '@ti-platform/web/ui-kit/icons/icons.module'
import { FormsModule } from '@angular/forms'
import { FormatDatePipe, FormatDurationPipe, FormatTimeSincePipe, FormatUnitPipe } from './pipes'
import { DateFormatService, UnitConversionService } from './services'
import { TranslateLabelsPipe } from '@ti-platform/web/ui-kit/i18n/pipes/translate-lables.pipe'

const PIPES = [
  FormatDatePipe,
  FormatDurationPipe,
  FormatTimeSincePipe,
  FormatUnitPipe,
  TranslateLabelsPipe,
]

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, PrimeNgModule, IconsModule],
  declarations: [...PIPES],
  providers: [
    DatePipe,
    FormatDurationPipe,
    DateFormatService,
    UnitConversionService,
    FormatUnitPipe,
  ],
  exports: [TranslateModule, ...PIPES, FormatUnitPipe],
})
export class I18nModule {}
