import { Injectable, inject } from '@angular/core'
import { UserRole } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { Memoize } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { Observable } from 'rxjs'

@Injectable()
export class UserRolesProvider {
  protected readonly api = inject(ApiService)
  protected readonly languageService = inject(LanguageService)

  @Memoize()
  get userRoleLabels$(): Observable<Record<UserRole, string>> {
    const roleList = Object.values(UserRole)
    const labelsMap: Record<number, string> = {}
    roleList.forEach((roleId) => {
      labelsMap[roleId as number] = `users.roles.${roleId}`
    })
    return this.languageService.massTranslate$(labelsMap)
  }
}
