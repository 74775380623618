import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-mileage',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4935 2.65771C16.3097 2.65771 15.268 3.41546 14.9014 4.5432L4.10017 37.7644C3.83021 38.5947 3.97527 39.512 4.48815 40.218C5.00714 40.9324 5.81052 41.3422 6.6924 41.3422H8.89115L17.6895 2.65771H17.4935ZM23.7774 2.65771V6.25892C23.7774 6.88482 23.27 7.39225 22.6441 7.39225C22.0182 7.39225 21.5107 6.88482 21.5107 6.25892V2.65771H20.0142L11.2158 41.3422H21.5064V37.741C21.5064 37.1151 22.0139 36.6076 22.6398 36.6076C23.2657 36.6076 23.7731 37.1151 23.7731 37.741V41.3422H34.8449L26.0466 2.65771H23.7774ZM21.5042 13.3866V10.3143C21.5042 9.68839 22.0117 9.18096 22.6376 9.18096C23.2635 9.18096 23.7709 9.68839 23.7709 10.3143V13.3866C23.7709 14.0125 23.2635 14.5199 22.6376 14.5199C22.0117 14.5199 21.5042 14.0125 21.5042 13.3866ZM23.7752 16.8121V20.0355C23.7752 20.6614 23.2678 21.1688 22.6419 21.1688C22.016 21.1688 21.5085 20.6614 21.5085 20.0355V16.8121C21.5085 16.1862 22.016 15.6787 22.6419 15.6787C23.2678 15.6787 23.7752 16.1862 23.7752 16.8121ZM21.5064 23.461C21.5064 22.8351 22.0138 22.3276 22.6397 22.3276C23.2656 22.3276 23.773 22.8351 23.773 23.461V26.6844C23.773 27.3103 23.2656 27.8177 22.6397 27.8177C22.0138 27.8177 21.5064 27.3103 21.5064 26.6844V23.461ZM23.7774 33.4088C23.7774 34.0347 23.27 34.5422 22.6441 34.5422C22.0182 34.5422 21.5107 34.0347 21.5107 33.4088V30.1854C21.5107 29.5595 22.0182 29.0521 22.6441 29.0521C23.27 29.0521 23.7774 29.5595 23.7774 30.1854V33.4088ZM41.2457 37.8129L31.1236 4.59171C30.7711 3.43495 29.7232 2.65771 28.5162 2.65771H28.3713L37.1696 41.3422H38.6383C39.51 41.3422 40.3079 40.9399 40.8274 40.2385C41.3485 39.535 41.501 38.6508 41.2457 37.8129Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class MileageIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
