import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-group-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.894 19C17.6312 19 17.9998 19 18.2813 18.8713C18.529 18.758 18.7303 18.5773 18.8565 18.3551C19 18.1024 19 17.7716 19 17.1101V12.8899C19 12.2284 19 11.8976 18.8565 11.645C18.7303 11.4227 18.529 11.242 18.2813 11.1287C17.9998 11 17.6312 11 16.894 11L7.10598 11C6.36882 11 6.00024 11 5.71868 11.1287C5.47101 11.242 5.26965 11.4227 5.14346 11.645C5 11.8976 5 12.2284 5 12.8899V17.1101C5 17.7716 5 18.1024 5.14346 18.355C5.26965 18.5773 5.47101 18.758 5.71868 18.8713C6.00024 19 6.36882 19 7.10598 19H16.894Z"
        [attr.stroke]="color"
        style="stroke-opacity: 1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.39829 8C7.60891 8 7.71422 8 7.79466 7.95172C7.86542 7.90925 7.92296 7.84149 7.95901 7.75814C8 7.66339 8 7.53935 8 7.29128V5.70872C8 5.46065 8 5.33661 7.95901 5.24186C7.92296 5.15851 7.86543 5.09075 7.79466 5.04828C7.71422 5 7.60891 5 7.39829 5L4.60171 5C4.39109 5 4.28578 5 4.20534 5.04828C4.13458 5.09075 4.07704 5.15851 4.04099 5.24186C4 5.33661 4 5.46065 4 5.70872V7.29128C4 7.53935 4 7.66339 4.04099 7.75814C4.07704 7.84149 4.13458 7.90925 4.20534 7.95172C4.28578 8 4.39109 8 4.60171 8H7.39829Z"
        [attr.stroke]="color"
        style="stroke-opacity: 1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3983 8C13.6089 8 13.7142 8 13.7947 7.95172C13.8654 7.90925 13.923 7.84149 13.959 7.75814C14 7.66339 14 7.53935 14 7.29128V5.70872C14 5.46065 14 5.33661 13.959 5.24186C13.923 5.15851 13.8654 5.09075 13.7947 5.04828C13.7142 5 13.6089 5 13.3983 5L10.6017 5C10.3911 5 10.2858 5 10.2053 5.04828C10.1346 5.09075 10.077 5.15851 10.041 5.24186C10 5.33661 10 5.46065 10 5.70872V7.29128C10 7.53935 10 7.66339 10.041 7.75814C10.077 7.84149 10.1346 7.90925 10.2053 7.95172C10.2858 8 10.3911 8 10.6017 8H13.3983Z"
        [attr.stroke]="color"
        style="stroke-opacity: 1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.3983 8C19.6089 8 19.7142 8 19.7947 7.95172C19.8654 7.90925 19.923 7.84149 19.959 7.75814C20 7.66339 20 7.53935 20 7.29128V5.70872C20 5.46065 20 5.33661 19.959 5.24186C19.923 5.15851 19.8654 5.09075 19.7947 5.04828C19.7142 5 19.6089 5 19.3983 5L16.6017 5C16.3911 5 16.2858 5 16.2053 5.04828C16.1346 5.09075 16.077 5.15851 16.041 5.24186C16 5.33661 16 5.46065 16 5.70872V7.29128C16 7.53935 16 7.66339 16.041 7.75814C16.077 7.84149 16.1346 7.90925 16.2053 7.95172C16.2858 8 16.3911 8 16.6017 8H19.3983Z"
        [attr.stroke]="color"
        style="stroke-opacity: 1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Group1IconComponent extends BaseIconComponent {}
