import {
  EventEmitter,
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  inject,
  NgZone,
} from '@angular/core'
import { injectDestroy$ } from '@ti-platform/web/common'
import { createTextMaskInputElement } from 'text-mask-core'
import { fromEvent, takeUntil } from 'rxjs'
import { createDateTextMaskPipe } from '../../utils/create-date-text-mask-pipe'

@Component({
  selector: 'app-duration-input',
  templateUrl: 'duration-input.component.html',
  styleUrls: ['duration-input.component.scss'],
})
export class DurationInputComponent implements AfterViewInit {
  @Output() durationChanged = new EventEmitter<number>()

  @ViewChild('durationInputRef')
  protected readonly durationInputRef?: ElementRef

  protected readonly ngZone = inject(NgZone)
  protected readonly destroy$ = injectDestroy$()
  protected _duration = '00:00'

  @Input() placeholderMask? = 'min:sec'
  @Input() isSecondsSupported? = false
  @Input() isOutlined? = false

  @Input() set duration(milliseconds: number) {
    const value = this.convertMilliseconds(milliseconds)
    this.updateDuration(value)
  }

  public ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      if (this.durationInputRef?.nativeElement) {
        this.initDurationInputMask(this.durationInputRef.nativeElement)
      }
    })
  }

  protected updateDuration(value: { minutes: number; seconds: number }) {
    const formattedMinutes = value.minutes.toString().padStart(2, '0')
    const formattedSeconds = value.seconds.toString().padStart(2, '0')
    this._duration = formattedMinutes + ':' + formattedSeconds
  }

  protected convertMilliseconds(ms: number) {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor(ms / (1000 * 60))
    return { minutes, seconds }
  }

  protected initDurationInputMask(durationInput: HTMLInputElement) {
    durationInput.value = this._duration
    durationInput.focus()
    durationInput.setSelectionRange(3, 3)

    // Create the text mask input element
    const textMask = createTextMaskInputElement({
      inputElement: durationInput,
      mask: [/\d/, /\d/, ':', /\d/, /\d/],
      pipe: createDateTextMaskPipe('MM:SS'),
      guide: true,
    })

    fromEvent(durationInput, 'input')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        if ((event as KeyboardEvent).key === 'Enter') {
          durationInput.blur()
        } else {
          textMask.update() // Apply the mask
        }
      })
  }

  protected onDurationChanges(duration?: string | null) {
    if (duration) {
      let [minutes, seconds] = duration.split(':').map((v) => parseInt(v))

      if (minutes > 60) {
        minutes = 59
      }

      if (seconds > 60) {
        seconds = 59
      }

      const valueInMilliseconds = (minutes * 60 + seconds) * 1000
      this.durationChanged.emit(valueInMilliseconds)
    }
  }
}
