import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { Action, Resource } from '@ti-platform/contracts'
import { AccessControl } from '@ti-platform/web/common'

export const smartRedirect = (
  routes: Record<string, [Resource, Action]>,
  prefer?: string,
): CanActivateFn => {
  return async () => {
    const accessControl = inject(AccessControl)
    const router = inject(Router)

    const keys = Object.getOwnPropertyNames(routes)
    const results = await accessControl.massCheck(keys.map((key) => routes[key]))

    if (prefer && results[keys.indexOf(prefer)]) {
      return router.createUrlTree([prefer])
    }

    if (results.includes(true)) {
      return router.createUrlTree([keys[results.indexOf(true)]])
    }

    return true
  }
}
