import { Component, inject, OnInit } from '@angular/core'
import { AppFeatures } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { Router } from '@angular/router'

interface BillingRecord {
  year: number
  month: number
  displayDate: Date
}

@Component({
  selector: 'app-tsp-admin-billing-page',
  templateUrl: 'billing-history-list.component.html',
  styleUrls: ['billing-history-list.component.scss'],
})
export class BillingHistoryListComponent implements OnInit {
  protected readonly api = inject(ApiService)
  protected readonly router = inject(Router)
  protected readonly AppFeatures = AppFeatures
  protected records: BillingRecord[] = []

  async ngOnInit() {
    const tsp = await this.api.myProfile.getTspView()
    this.records = this.generateBillingRecords(new Date(tsp.createdAt))
  }

  generateBillingRecords(tspCreatedAt: Date, today: Date = new Date()): BillingRecord[] {
    const invoices: BillingRecord[] = []

    let currentYear = tspCreatedAt.getFullYear()
    let currentMonth = tspCreatedAt.getMonth()

    const endYear = today.getFullYear()
    const endMonth = today.getMonth()

    while (currentYear < endYear || (currentYear === endYear && currentMonth < endMonth)) {
      // For display and month identification, using the first day of the month at UTC midnight
      const displayDate = new Date(currentYear, currentMonth, 1, 0, 0, 0)

      invoices.push({
        year: currentYear,
        month: currentMonth + 1, // Month starts from 0
        displayDate,
      })

      currentMonth++
      if (currentMonth > 11) {
        currentMonth = 0
        currentYear++
      }
    }

    // Sort invoices in descending order if needed
    invoices.sort((a, b) => b.displayDate.getTime() - a.displayDate.getTime())
    return invoices
  }

  goBack() {
    return this.router.navigate(['billing'])
  }
}
