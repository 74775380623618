import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-police',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0033 10.9997L18.1445 11.9001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.86387 11.9001L3.99609 10.9997"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1434 11.9001L17.3881 8.28855C17.17 7.24511 16.2497 6.4978 15.1842 6.4978H8.9096C7.86416 6.4978 6.95578 7.2181 6.71768 8.23653L5.86133 11.9001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2241 6.49787L14.8749 4.82617C14.7729 4.34297 14.3477 3.99683 13.8535 3.99683H10.186C9.70278 3.99683 9.2826 4.32996 9.17156 4.80116L8.77539 6.49787"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0002 6.49787V3.99683"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99776 18.2809V20.0036C7.99776 20.5558 7.54957 21.004 6.99734 21.004H4.99651C4.44428 21.004 3.99609 20.5558 3.99609 20.0036V17.6006"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0036 17.6006V20.0036C20.0036 20.5558 19.5554 21.004 19.0032 21.004H17.0024C16.4501 21.004 16.002 20.5558 16.002 20.0036V18.2809"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5032 16.28V14.6013C20.5032 13.1097 19.2937 11.9001 17.8021 11.9001H6.19722C4.7056 11.9001 3.49609 13.1097 3.49609 14.6013V16.28C3.49609 17.3854 4.39147 18.2808 5.49693 18.2808H18.5023C19.6078 18.2808 20.5032 17.3844 20.5032 16.28Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33008 14.8625L7.99777 15.1126"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0762 15.1126L17.6688 14.8625"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PoliceIconComponent extends BaseIconComponent {}
