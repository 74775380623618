<div
  class="panel-wrapper"
  [@slideInOut]="(filterPanelService.isPanelOpened$ | async) ? 'in' : 'out'"
  (click)="$event.stopPropagation()"
>
  <div class="header">
    <div class="top">
      <div class="title flex text-18 fw-600">
        {{ 'filter-panel.filter-and-sort' | translate }}
        @if (localFiltersCount) {
          <div class="badge">{{ localFiltersCount }}</div>
        }
      </div>
      <div>
        <div class="link clickable" (click)="resetAll()">
          {{ 'filter-panel.reset-all' | translate }}
        </div>
      </div>
    </div>
    <div class="text-light-description--panel text-14 fw-500">
      {{ 'filter-panel.caption' | translate }}
    </div>
  </div>
  <div class="content scroll-wrapper scrollbar-gray">
    @if (isFilteringEnabled && filterOptions) {
      <div class="filtering">
        @for (filterOption of filterOptions; track filterOption.key) {
          <p-accordion
            class="filters-accordion chevron-small no-border-bottom"
            [activeIndex]="$any(deviceService.isMobile() ? undefined : 0)"
          >
            <p-accordionTab>
              <ng-template pTemplate="header">
                <div class="accordion-header">
                  <div class="flex type text-16 fw-600">
                    {{ filterOption.title | translate }}
                    @if (
                      localFilterValue[filterOption.key] && localFilterValue[filterOption.key].size
                    ) {
                      <div class="badge">{{ localFilterValue[filterOption.key].size }}</div>
                    }
                  </div>

                  @if (
                    localFilterValue[filterOption.key] && localFilterValue[filterOption.key].size
                  ) {
                    <div
                      class="clear text-14 fw-600"
                      (click)="clearSelectedFilters(filterOption); $event.stopPropagation()"
                    >
                      {{ 'filter-panel.clear' | translate }}
                    </div>
                  }
                </div>
              </ng-template>

              <div class="accordion-content">
                <app-filter-list
                  [items]="filterOption.options"
                  [selectedItems]="localFilterValue[filterOption.key]"
                  (selectedItemsChange)="onSelectedItemsChange(filterOption.key, $event)"
                />
              </div>
            </p-accordionTab>
          </p-accordion>
        }
      </div>
    }

    @if (isSortingEnabled && (customSortOptions || gridColumns)) {
      <div class="sorting">
        <p-accordion
          class="filters-accordion chevron-small no-border-bottom"
          [activeIndex]="$any(!expandSortByDefault ? undefined : 0)"
        >
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="accordion-header">
                <div class="flex type text-16 fw-600">
                  {{ 'filter-panel.sort-by' | translate }}
                  @if (
                    sortListRef.gridSortOrder?.column !== defaultSortOrder?.column ||
                    sortListRef.gridSortOrder?.order !== defaultSortOrder?.order
                  ) {
                    <div class="dot--purple"></div>
                  }
                </div>
              </div>
            </ng-template>

            <div class="accordion-content">
              <app-sort-list
                #sortListRef
                [gridColumns]="gridColumns"
                [gridSortOrder]="localGridSortOrder"
                [gridDefaultSortOrder]="defaultSortOrder"
                [customSortOptions]="customSortOptions"
                [customSortValue]="localCustomSortValue"
                (gridSortOrderChanged)="gridSortOrderChanged($event)"
                (customSortValueChanged)="customSortValueChanged($event)"
              />
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    }
  </div>
  <div class="action-buttons">
    <p-button
      [label]="'filter-panel.action-buttons.cancel-button' | translate"
      [severity]="'secondary'"
      class="cancel text-14 fw-600 w50 p--8 h--40"
      (click)="onCancel()"
    />
    <p-button
      [label]="'filter-panel.action-buttons.apply-button' | translate"
      class="cancel text-14 fw-600 w50 p--8 h--40"
      (click)="onApply()"
    />
  </div>
</div>
