import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-info',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 16C11.75 16.4142 12.0858 16.75 12.5 16.75C12.9142 16.75 13.25 16.4142 13.25 16H11.75ZM13.25 12C13.25 11.5858 12.9142 11.25 12.5 11.25C12.0858 11.25 11.75 11.5858 11.75 12H13.25ZM12.5 7.25C12.0858 7.25 11.75 7.58579 11.75 8C11.75 8.41421 12.0858 8.75 12.5 8.75V7.25ZM12.51 8.75C12.9242 8.75 13.26 8.41421 13.26 8C13.26 7.58579 12.9242 7.25 12.51 7.25V8.75ZM21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25V22.75C18.4371 22.75 23.25 17.9371 23.25 12H21.75ZM12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12H1.75C1.75 17.9371 6.56294 22.75 12.5 22.75V21.25ZM3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75V1.25C6.56294 1.25 1.75 6.06294 1.75 12H3.25ZM12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12H23.25C23.25 6.06294 18.4371 1.25 12.5 1.25V2.75ZM13.25 16V12H11.75V16H13.25ZM12.5 8.75H12.51V7.25H12.5V8.75Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class InfoIconComponent extends BaseIconComponent {
  @Input() override color = 'var(--color-gray-400)'
}
