import { VideoStreamData } from '../media'

export enum LiveStreamStatus {
  isActive = 0,
  isSuspended = 1,
}

export interface LiveStream {
  id: string
  deviceId: string
  channelId: number
  vehicleId: string
  externalDeviceId: number
  data: VideoStreamData
  viewers: Record<string, { joinedAt: Date }> // Map of viewers with metadata
  lastUsedAt: Date
  createdAt: Date
  createdBy: string
  tspId: number
  fleetId: number
  status?: LiveStreamStatus
}
