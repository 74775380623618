import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-recycle-bin-disable',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3335 7.66675L27.3335 3.66675"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3335 7.66675L23.3335 3.66675"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="12"
        cy="13.5"
        rx="8"
        ry="2.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9895 13.628C19.9895 13.628 18.8056 22.1792 18.2644 26.0896C18.0981 27.336 17.1682 28.3443 15.9393 28.6108C13.3387 29.1298 10.6611 29.1298 8.06047 28.6108C6.83159 28.3443 5.9017 27.336 5.7354 26.0896C5.1942 22.1792 4.01031 13.6279 4.01031 13.6279"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 24V20"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 24V20"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class RecycleBinDisableIconComponent extends BaseIconComponent {
  @Input() override width = 32
  @Input() override height = 32
}
