import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-lock-closed',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 6.66667H3.3335V11.3332V12.6666C3.3335 13.0202 3.47397 13.3593 3.72402 13.6094C3.97407 13.8594 4.31321 13.9999 4.66683 13.9999H11.3335C11.6871 13.9999 12.0263 13.8594 12.2763 13.6094C12.5264 13.3593 12.6668 13.0202 12.6668 12.6666V6.66667H10.6668M5.3335 6.66667V4.66667C5.3335 3.19391 6.5274 2 8.00016 2V2C9.47292 2 10.6668 3.19391 10.6668 4.66667V6.66667M5.3335 6.66667H10.6668"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LockClosedIconComponent extends BaseIconComponent {
  @Input() override height = 16
  @Input() override width = 16
}
