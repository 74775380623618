import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserProfile } from '@ti-platform/contracts'

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: 'user-add.component.html',
  styleUrl: 'user-add.component.scss',
})
export class UserAddDialogComponent {
  @Output() cancelAdd = new EventEmitter<void>()
  @Output() submitAdd = new EventEmitter<UserProfile>()

  @Input() opened = false
  @Input() user: Partial<UserProfile> = {}
  @Input() displayRoles: 'TSP' | 'Fleet' = 'TSP'
}
