import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { firstValueFrom, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LOGIN_AS_HEADER_NAME } from '@ti-platform/contracts'
import { AuthSession, LOGIN_AS_SESSION } from '@ti-platform/web/auth'
import { ApiResponse, HttpOptions } from '../contracts'

@Injectable()
export class ApiClient {
  private http = inject(HttpClient)
  private authSession = inject(AuthSession)
  private loginAsSession = inject(LOGIN_AS_SESSION, { optional: true })

  async get<ResponseData = unknown>(
    url: string,
    options?: HttpOptions,
  ): Promise<ResponseData | null> {
    const request = this.http.get<ApiResponse<ResponseData>>(
      url,
      await this.addAuthHeadersToOptions(options),
    )

    return await this.executeRequest<ResponseData>(request)
  }

  async post<ResponseData = unknown>(
    url: string,
    body: any | null,
    options?: HttpOptions,
  ): Promise<ResponseData | null> {
    const request = this.http.post<ApiResponse<ResponseData>>(
      url,
      body,
      await this.addAuthHeadersToOptions(options),
    )

    return await this.executeRequest<ResponseData>(request)
  }

  async delete<ResponseData = unknown>(
    url: string,
    options?: HttpOptions,
  ): Promise<ResponseData | null> {
    const request = this.http.delete<ApiResponse<ResponseData>>(
      url,
      await this.addAuthHeadersToOptions(options),
    )

    return await this.executeRequest<ResponseData>(request)
  }

  async put<ResponseData = unknown>(
    url: string,
    body: any | null,
    options?: HttpOptions,
  ): Promise<ResponseData | null> {
    const request = this.http.put<ApiResponse<ResponseData>>(
      url,
      body,
      await this.addAuthHeadersToOptions(options),
    )

    return await this.executeRequest<ResponseData>(request)
  }

  async patch<ResponseData = unknown>(
    url: string,
    body: any | null,
    options?: HttpOptions,
  ): Promise<ResponseData | null> {
    const request = this.http.patch<ApiResponse<ResponseData>>(
      url,
      body,
      await this.addAuthHeadersToOptions(options),
    )

    return await this.executeRequest<ResponseData>(request)
  }

  private async addAuthHeadersToOptions(options: HttpOptions = {}): Promise<HttpOptions> {
    if (!(options.headers instanceof HttpHeaders)) {
      options.headers = new HttpHeaders(options.headers ?? {})
    }
    if (!options.headers.get('Authorization')) {
      const token = await this.authSession.getAccessToken()
      options.headers = options.headers.set('Authorization', token)
    }

    // Assign custom header to use login-as session in request
    if (this.loginAsSession) {
      options.headers = options.headers.set(LOGIN_AS_HEADER_NAME, this.loginAsSession.sessionId)
    }

    return options
  }

  /**
   * @throws HttpException
   */
  private async executeRequest<ResponseType>(
    request: Observable<any>,
  ): Promise<ResponseType | null> {
    let response = null
    try {
      response = await firstValueFrom(request.pipe(map((response) => response.data)))
    } catch (error) {
      console.warn(`API error`, error)
      if (error instanceof HttpErrorResponse) {
        throw new HttpException(error.message ?? '', error.status, error.error)
      }
    }

    return response
  }
}

export class HttpException extends Error {
  protected status: number
  protected error: any

  public constructor(message: string, statusCode: number, error?: any) {
    super(message)

    this.status = statusCode
    this.error = error
    this.name = HttpException.name
  }

  public getMessage() {
    return this.message
  }

  public getStatus() {
    return this.status
  }

  public getError() {
    return this.error
  }
}
