<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (data$ | async)?.length === 0 }">
  <app-data-grid
    [data]="(data$ | async) ?? []"
    [columns]="(state.columns$ | async) ?? []"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [noDataTitle]="noDataTitleKey | translate"
    [noDataDescription]="noDataDescriptionKey | translate"
    [noDataIconTemplate]="noDataIconTmpl"
    [templates]="{ config: configTmpl, numberOfDevices: numberOfDevicesTmpl }"
    (sortUpdated)="onSortUpdated($event)"
    (loadMoreClicked)="onLoadMoreClicked()"
  ></app-data-grid>
</div>

<ng-template #numberOfDevicesTmpl let-data="row">
  <div
    class="number-of-devices-wrapper clickable"
    (click)="onDeviceColumnClick(menu, $event, data)"
  >
    @if (data.numberOfDevices) {
      {{ 'tsp-admin.devices.commands.several-devices' | translate: { val: data.numberOfDevices } }}
    } @else {
      {{ 'tsp-admin.devices.commands.no-devices' | translate }}
    }
  </div>
  <p-menu
    #menu
    [popup]="true"
    [model]="(deviceListByCommandId$ | async)?.[data.id] || []"
    [appendTo]="'body'"
  >
    <ng-template pTemplate="start">
      @if (data.numberOfDevices === 0) {
        <div class="menu-item name text-14 fw-500">
          {{ 'tsp-admin.devices.commands.no-devices' | translate }}
        </div>
      }
    </ng-template>
    <ng-template pTemplate="item" let-item>
      @if ((deviceListByCommandId$ | async)?.[data.id]?.length) {
        <div class="menu-item avatar-double-description commands-menu-item">
          <div class="avatar flex" style="width: 40px; height: 40px">
            <div class="bg"></div>
            <app-icon-device [width]="20" [height]="20" class="icon"></app-icon-device>
          </div>
          <div>
            <div class="text-14 fw-500 name">
              {{ item.name }}
            </div>
            <div class="description text-14 fw-400">{{ item.description }}</div>
          </div>
        </div>
      }
    </ng-template>
    <ng-template pTemplate="end">
      @if (data.devicesMore) {
        <div class="menu-item avatar-double-description commands-menu-item">
          <div class="avatar flex" style="width: 40px; height: 40px">
            <i class="pi pi-ellipsis-h" style="color: var(--primary-color)"></i>
          </div>
          <div>
            <div class="full text-14 fw-500">
              {{
                'tsp-admin.devices.commands.several-devices-more'
                  | translate: { val: data.devicesMore }
              }}
            </div>
          </div>
        </div>
      }
    </ng-template>
  </p-menu>
</ng-template>

<ng-template #configTmpl let-data="row">
  <div class="config-info">
    {{ data.configFields }}
  </div>
</ng-template>

<ng-template #noDataIconTmpl>
  @if (model.isFiltered) {
    <app-icon-no-search [width]="32" [height]="32"></app-icon-no-search>
  } @else {
    <app-icon-settings [height]="32" [width]="32" class="icon"></app-icon-settings>
  }
</ng-template>
