import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { inject, Injectable } from '@angular/core'
import { AuthService, AuthSession } from '../services'

@Injectable({ providedIn: 'root' })
/** @deprecated TODO: Remove this service */
export class RoleGuardService {
  constructor(
    private router: Router,
    private authSession: AuthSession,
    private authService: AuthService,
  ) {}

  async allowRoles(params: RolesGuardParams): Promise<boolean | UrlTree> {
    const { roles, forceLogout = false, redirectTo } = params
    const user = await this.authSession.getUserAttributes()
    if (roles.includes(user.role)) {
      return true
    } else if (forceLogout) {
      await this.authService.signOut()
      this.router.navigate(['/sign-in'])
    } else if (redirectTo) {
      this.router.navigate([redirectTo])
    }

    return false
  }
}

export const allowRolesGuard = (params: RolesGuardParams) => {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(RoleGuardService).allowRoles(params)
  }
}

export type RolesGuardParams = {
  roles: number[]
  forceLogout?: boolean
  redirectTo?: string
}
