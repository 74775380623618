<div class="overflow-container" [ngClass]="{ 'custom-tags-mode': customContentMode }">
  <div class="tags-bar-wrapper">
    <div *ngIf="showScrollLeftButton()" class="scroll-button scroll-left" (click)="scrollLeft()">
      <app-icon-chevron
        [width]="24"
        [height]="24"
        [color]="'var(--color-gray-500)'"
        direction="left"
      ></app-icon-chevron>
    </div>
    <div class="tags-bar" #tagsBar (scroll)="updateButtons()">
      @if (customContentMode) {
        <div class="custom-tag-wrapper">
          <ng-content></ng-content>
        </div>
      } @else {
        <div class="tag" *ngFor="let tag of tags" (click)="tagClicked.emit(tag)">
          <div class="text">
            {{ tag.label }}
          </div>
          <app-icon-close
            class="clickable"
            [width]="16"
            [height]="16"
            [color]="'var(--color-primary-500)'"
            (click)="onCloseClick($event, tag)"
          ></app-icon-close>
        </div>
      }
    </div>

    <div *ngIf="showScrollRightButton()" class="scroll-button scroll-right" (click)="scrollRight()">
      <app-icon-chevron
        [width]="24"
        [height]="24"
        [color]="'var(--color-gray-500)'"
        direction="right"
      ></app-icon-chevron>
    </div>

    <div *ngIf="showScrollLeftButton()" class="shadow-mask left"></div>
    <div *ngIf="showScrollRightButton()" class="shadow-mask right"></div>
  </div>
</div>
<div class="height-spacer" *ngIf="tags.length"></div>
