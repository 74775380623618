import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'

export interface RadioOption<T> {
  value: T
  template?: TemplateRef<any>
}

@Component({
  selector: 'app-radio-group',
  templateUrl: 'radio-group.component.html',
  styleUrls: ['radio-group.component.scss'],
})
export class RadioGroupComponent<T> {
  @Input() caption?: string
  @Input() description?: string
  @Input() options: RadioOption<T>[] = []
  @Input() selectedValue: T | null = null
  @Output() selectionChange = new EventEmitter<T>()

  selectOption(option: RadioOption<T>) {
    if (this.selectedValue !== option.value) {
      this.selectedValue = option.value
      this.selectionChange.emit(option.value)
    }
  }
}
