import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-video-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 6.42857L12.7273 10L17.5 13.5714V6.42857Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M11.3636 5H3.86364C3.11052 5 2.5 5.63959 2.5 6.42857V13.5714C2.5 14.3604 3.11052 15 3.86364 15H11.3636C12.1168 15 12.7273 14.3604 12.7273 13.5714V6.42857C12.7273 5.63959 12.1168 5 11.3636 5Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M17.5 6.42857L12.7273 10L17.5 13.5714V6.42857Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3636 5H3.86364C3.11052 5 2.5 5.63959 2.5 6.42857V13.5714C2.5 14.3604 3.11052 15 3.86364 15H11.3636C12.1168 15 12.7273 14.3604 12.7273 13.5714V6.42857C12.7273 5.63959 12.1168 5 11.3636 5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class VideoSolidIconComponent extends BaseIconComponent {
  @Input() override width = 16
  @Input() override height = 16
}
