import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-dispatch',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13V10"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 5V3"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 5H17.185C17.474 5 17.748 5.125 17.938 5.342L19.255 6.851C19.58 7.223 19.58 7.777 19.255 8.149L17.938 9.658C17.748 9.875 17.474 10 17.185 10H6.5C5.948 10 5.5 9.552 5.5 9V6C5.5 5.448 5.948 5 6.5 5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4987 18H6.81375C6.52475 18 6.25075 17.875 6.06075 17.658L4.74375 16.149C4.41875 15.777 4.41875 15.223 4.74375 14.851L6.06075 13.342C6.24975 13.125 6.52475 13 6.81375 13H17.4987C18.0507 13 18.4987 13.448 18.4987 14V17C18.4987 17.552 18.0507 18 17.4987 18Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 21V18"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DispatchIconComponent extends BaseIconComponent {}
