import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-video-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.668 6.86175L12.9848 5.43365C13.1905 5.30685 13.4486 5.30126 13.6596 5.41904C13.8706 5.53682 14.0013 5.75953 14.0013 6.00115V10.6657C14.0013 10.9073 13.8706 11.13 13.6596 11.2478C13.4487 11.3656 13.1905 11.36 12.9848 11.2332L10.668 9.80507"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="2"
        y="4"
        width="8.66667"
        height="8.66667"
        rx="3"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Video1IconComponent extends BaseIconComponent {}
