import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-truck-cargo-big',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2637 18.4341V21.0001C19.2637 21.5524 18.816 22.0001 18.2637 22.0001H16.2637C15.7114 22.0001 15.2637 21.5524 15.2637 21.0001V19.5001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.73633 19.5001V21.0001C8.73633 21.5524 8.28861 22.0001 7.73633 22.0001H5.73633C5.18404 22.0001 4.73633 21.5524 4.73633 21.0001V18.4341"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5006 12.5H21.0006C21.5529 12.5 22.0006 12.0523 22.0006 11.5V8.5C22.0006 7.94772 21.5529 7.5 21.0006 7.5H19.2324"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 12.5H3C2.44772 12.5 2 12.0523 2 11.5V8.5C2 7.94772 2.44772 7.5 3 7.5H4.76956"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 7.178V4.1C19 3.21634 18.2837 2.5 17.4 2.5H6.6C5.71634 2.5 5 3.21634 5 4.1V7.178"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 12.5C9.47778 13.1667 14.5222 13.1667 19.5 12.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.884 16H15"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 16H7"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="4.5"
        y="6.5"
        width="15"
        height="13"
        rx="2"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TruckCargoBigIconComponent extends BaseIconComponent {}
