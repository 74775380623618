import { inject, Injectable } from '@angular/core'
import { fromEvent, Observable, takeUntil, throttleTime } from 'rxjs'
import { BrowserSessionStorage } from './storage/session.storage'
import { injectDestroy$ } from '../utils'

@Injectable({ providedIn: 'root' })
export class RetainScroll {
  protected readonly storage = inject(BrowserSessionStorage)
  protected readonly destroy$ = injectDestroy$()

  protected readonly MAX_RETRIES = 25

  public registerScrollRestore(
    key: string,
    scrollableElement: HTMLElement,
    destroy$: Observable<void>,
  ): (delay?: number) => void {
    const cacheKey = `TI_SCROLL_RESTORE_${key.toUpperCase()}`

    fromEvent(scrollableElement, 'scroll')
      .pipe(
        takeUntil(destroy$),
        takeUntil(this.destroy$),
        throttleTime(200, undefined, { leading: false, trailing: true }),
      )
      .subscribe(() => {
        this.storage.setItem(cacheKey, scrollableElement.scrollTop.toFixed(0))
      })

    return (restoreDelay = 8) => {
      const cachedScroll = this.storage.getItem(cacheKey)
      const scroll = cachedScroll ? parseFloat(cachedScroll) : 0
      if (!scroll) {
        return undefined
      }

      let retries = 0
      const interval = setInterval(() => {
        if (retries >= this.MAX_RETRIES) {
          clearInterval(interval)
        }

        if (scrollableElement.scrollHeight > scrollableElement.offsetHeight) {
          scrollableElement.scrollTop = scroll
          clearInterval(interval)
        }
        retries++
      }, restoreDelay)
    }
  }
}
