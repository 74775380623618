<div class="tiles-selector">
  <p-button
    [severity]="'secondary'"
    (click)="menu.toggle($event)"
    icon="pi"
    class="tiles-selector h--40"
  >
    <app-icon-map-style height="20" width="20"></app-icon-map-style>
  </p-button>

  <p-menu #menu [appendTo]="'body'" [model]="(tileSourceOptions$ | async) ?? []" popup="true">
    <ng-template pTemplate="item" let-item let-i="index">
      <a
        pRipple
        class="flex tiles-selector-menu-item menu-item p-menuitem-link"
        [class.selected]="currentTile === item.value"
        (click)="map.setTileSource(item.value)"
      >
        <div class="label text-14">{{ item.label }}</div>
      </a>
    </ng-template>
  </p-menu>
</div>
