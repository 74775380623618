import { Component, inject, OnInit } from '@angular/core'
import { Memoize } from '@ti-platform/web/common'
import { combineLatest, map, Observable } from 'rxjs'
import { DataGridColumn, DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '@ti-platform/web/api'
import { Action, Invoice, Resource } from '@ti-platform/contracts'

@Component({
  selector: 'app-tsp-admin-billing-invoice-details',
  templateUrl: 'billing-invoice-details.component.html',
  styleUrls: ['billing-invoice-details.component.scss'],
})
export class BillingInvoiceDetailsComponent implements OnInit {
  protected api = inject(ApiService)
  protected readonly languageService = inject(LanguageService)
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected readonly router = inject(Router)
  protected invoice: Invoice | null = null
  protected invoiceDate: Date = new Date()
  protected readonly Resource = Resource
  protected readonly Action = Action

  @Memoize()
  protected get columns$(): Observable<DataGridColumn[]> {
    return combineLatest([
      this.languageService.translate$('tsp-admin.devices.grid.imei-serial'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.0'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.1'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.2'),
      this.languageService.translate$('tsp-admin.fleets.pricing-plans.3'),
      this.languageService.translate$('tsp-admin.billing.grid.amount'),
    ]).pipe(
      map((labels) => {
        const [deviceIdentifier, tnt, basic, standard, pro, amount] = labels
        return [
          {
            field: 'deviceIdentifier',
            label: deviceIdentifier,
            type: DataGridColumnType.Text,
            sortable: false,
          },
          {
            field: 'tnt',
            label: tnt,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'basic',
            label: basic,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'standard',
            label: standard,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'pro',
            label: pro,
            type: DataGridColumnType.Template,
            sortable: false,
          },
          {
            field: 'amount',
            label: `${amount} ($)`,
            type: DataGridColumnType.Count,
            sortable: false,
          },
        ]
      }),
    )
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async (params) => {
      try {
        const year = params.get('year')
        const month = params.get('month')

        if (!year || !month) {
          return this.goBack()
        }

        const parsedYear = parseInt(year, 10)
        const parsedMonth = parseInt(month, 10) - 1 // Convert to zero-based month index

        // Create invoice date in local time-zone for the first day of the specified month and year
        this.invoiceDate = new Date(parsedYear, parsedMonth, 1)

        this.invoice = await this.api.tsp.billing.invoice(parsedYear, parsedMonth)
      } catch (e) {
        return this.goBack()
      }
    })
  }

  goBack() {
    return this.router.navigate(['/billing/history'])
  }
}
