import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-play-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 15.1667C11.6821 15.1667 14.6668 12.1819 14.6668 8.5C14.6668 4.8181 11.6821 1.83333 8.00016 1.83333C4.31826 1.83333 1.3335 4.8181 1.3335 8.5C1.3335 12.1819 4.31826 15.1667 8.00016 15.1667Z"
        [attr.fill]="color"
        [attr.stroke]="color"
        style="fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 5.83333L10.6665 8.49999L6.6665 11.1667V5.83333Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PlaySolidIconComponent extends BaseIconComponent {
  @Input() override width = 24
  @Input() override height = 24
}
