import { Component, Input } from '@angular/core'
import { UIVehicleAggregate } from '@ti-platform/web/fleet/contracts'
import { UnitType } from '@ti-platform/web/ui-kit/i18n/contracts'

@Component({
  selector: 'app-fleet-vehicle-indicators',
  templateUrl: 'vehicle-indicators.component.html',
  styleUrl: 'vehicle-indicators.component.scss',
})
export class VehicleIndicatorsComponent {
  @Input() ALPHA_1_MODE = true
  @Input() vehicle!: UIVehicleAggregate

  protected readonly UnitType = UnitType
}
