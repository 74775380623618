import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-map-foldable',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5822 3.40869C16.3802 3.27447 16.1479 3.19275 15.9064 3.17092C15.6648 3.1491 15.4216 3.18785 15.1988 3.28369L12.2238 4.55869L8.26551 2.89202C8.11 2.82601 7.94279 2.79199 7.77385 2.79199C7.60491 2.79199 7.43769 2.82601 7.28218 2.89202L3.64885 4.42536C3.36561 4.54854 3.12511 4.7527 2.95756 5.01217C2.79002 5.27165 2.70291 5.57485 2.70718 5.88369V15.2587C2.70565 15.5222 2.76956 15.782 2.8932 16.0147C3.01683 16.2474 3.1963 16.4458 3.41551 16.592C3.61752 16.7262 3.8498 16.808 4.09134 16.8298C4.33289 16.8516 4.57606 16.8129 4.79885 16.717L7.77385 15.442L11.7322 17.1087C11.8873 17.1761 12.0548 17.2101 12.2238 17.2087C12.3928 17.2085 12.5599 17.1745 12.7155 17.1087L16.3488 15.5504C16.6321 15.4272 16.8726 15.223 17.0401 14.9635C17.2077 14.7041 17.2948 14.4009 17.2905 14.092V4.74202C17.292 4.47851 17.2281 4.21874 17.1045 3.98602C16.9809 3.75331 16.8014 3.55493 16.5822 3.40869ZM8.39885 4.24202L11.5988 5.60869V15.717L8.39885 14.3504V4.24202ZM4.30718 15.567C4.27551 15.5853 4.23958 15.5949 4.20301 15.5949C4.16644 15.5949 4.13052 15.5853 4.09885 15.567C4.05236 15.5345 4.01495 15.4906 3.99016 15.4396C3.96537 15.3885 3.95402 15.332 3.95718 15.2754V5.88369C3.95616 5.81715 3.97559 5.7519 4.01285 5.69675C4.05011 5.64161 4.1034 5.59924 4.16551 5.57536L7.14885 4.28369V14.3504L4.30718 15.567ZM16.0405 14.117C16.0436 14.1807 16.0278 14.2439 15.9952 14.2988C15.9626 14.3536 15.9146 14.3976 15.8572 14.4254L12.8488 15.717V5.65036L15.6905 4.43369C15.7222 4.4154 15.7581 4.40578 15.7947 4.40578C15.8313 4.40578 15.8672 4.4154 15.8988 4.43369C15.9453 4.46623 15.9827 4.51009 16.0075 4.56114C16.0323 4.61218 16.0437 4.6687 16.0405 4.72536V14.117Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class MapFoldableIconComponent extends BaseIconComponent {}
