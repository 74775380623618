import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-warning',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 7.99923V11.9992M11.9998 15.9992H12.0098M10.2898 2.85923L1.81978 16.9992C1.64514 17.3017 1.55274 17.6445 1.55177 17.9937C1.55079 18.343 1.64127 18.6864 1.8142 18.9898C1.98714 19.2931 2.2365 19.546 2.53748 19.7231C2.83847 19.9002 3.18058 19.9954 3.52978 19.9992H20.4698C20.819 19.9954 21.1611 19.9002 21.4621 19.7231C21.7631 19.546 22.0124 19.2931 22.1854 18.9898C22.3583 18.6864 22.4488 18.343 22.4478 17.9937C22.4468 17.6445 22.3544 17.3017 22.1798 16.9992L13.7098 2.85923C13.5315 2.56533 13.2805 2.32235 12.981 2.15371C12.6814 1.98508 12.3435 1.89648 11.9998 1.89648C11.656 1.89648 11.3181 1.98508 11.0186 2.15371C10.7191 2.32235 10.468 2.56533 10.2898 2.85923Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class WarningIconComponent extends BaseIconComponent {}
