import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core'

@Component({
  selector: 'app-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() photo?: string | null
  @Input() size: string | number = '40'
  @Input() iconTemplate?: TemplateRef<unknown>
}
