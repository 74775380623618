import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-tpms',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_232_26125"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="36"
        height="36"
      >
        <path
          d="M8.89062 5.64075C7.0957 5.64075 5.64062 7.09583 5.64062 8.89075V35.1094C5.64062 36.9043 7.0957 38.3594 8.89063 38.3594H35.1092C36.9042 38.3594 38.3592 36.9043 38.3592 35.1094V8.89075C38.3592 7.09583 36.9042 5.64075 35.1092 5.64075H8.89062Z"
          fill="white"
          stroke="white"
          style="fill:white;fill-opacity:1;stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
        />
      </mask>
      <g mask="url(#mask0_232_26125)">
        <path
          d="M11.4396 4.89075V7.61694C11.4396 8.7494 10.9389 9.81098 10.188 10.6587C7.72318 13.4416 6.22656 17.1016 6.22656 21.1114C6.22656 25.1213 7.72317 28.7813 10.188 31.5641C10.9388 32.4119 11.4396 33.4735 11.4396 34.606V35.0994C11.4396 36.5758 12.6364 37.7727 14.1129 37.7727H29.8855C31.362 37.7727 32.5589 36.5758 32.5589 35.0994V34.606C32.5589 33.4735 33.0596 32.4119 33.8105 31.5641C36.2752 28.7813 37.7719 25.1213 37.7719 21.1114C37.7719 17.1016 36.2752 13.4416 33.8104 10.6587C33.0596 9.81098 32.5589 8.7494 32.5589 7.61694V4.89075"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
        <path
          d="M11.4396 4.89075V7.61694C11.4396 8.74941 10.9389 9.81098 10.188 10.6587C7.72318 13.4416 6.22656 17.1016 6.22656 21.1114C6.22656 25.1213 7.72317 28.7813 10.188 31.5641C10.9388 32.4119 11.4396 33.4735 11.4396 34.606V35.0994C11.4396 36.5758 12.6364 37.7727 14.1129 37.7727H29.8855C31.362 37.7727 32.5589 36.5758 32.5589 35.0994V34.606C32.5589 33.4735 33.0596 32.4119 33.8105 31.5641C36.2752 28.7813 37.7719 25.1213 37.7719 21.1114C37.7719 17.1016 36.2752 13.4416 33.8104 10.6587C33.0596 9.81098 32.5589 8.7494 32.5589 7.61694V4.89075"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M16.8535 33.0275V37.7727"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M27.4805 33.0275V37.7727"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M22.1992 33.0275V37.7727"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M22.1992 25.6758V28.3491"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M22.1992 10.3039V23.0691"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TpmsIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
