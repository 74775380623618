import { DataQueryProps, TriggeredAlert, TriggeredAlertStatuses } from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class TriggeredAlertRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/triggered-alert'
  }

  public list(options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<TriggeredAlert[]>().withParams(data).endpoint('list')
  }

  public unreviewedCount() {
    return this.get<number>().endpoint('unreviewed-count')
  }

  public countAll() {
    return this.get<number>().endpoint('count-all')
  }

  public getById(id: string, withVehicle = false, withAlert = false) {
    return this.get<TriggeredAlert>()
      .withParams({
        withVehicle: `${!!withVehicle}`,
        withAlert: `${!!withAlert}`,
      })
      .endpoint(id)
  }

  public setStatus(id: string, status: TriggeredAlertStatuses) {
    return this.put<TriggeredAlert>().withData({ status }).endpoint(`${id}/set-status`)
  }
}
