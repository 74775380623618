import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core'
import { Subscription } from 'rxjs'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { map } from 'rxjs/operators'
import { MenuItem } from 'primeng/api'

interface Labeled {
  label?: string
}

@Pipe({
  name: 'translateLabels',
  pure: false,
})
export class TranslateLabelsPipe implements PipeTransform, OnDestroy {
  private subscription: Subscription | null = null
  private lastItems: Labeled[] | null = null
  private transformedItems: Labeled[] | null = null

  constructor(
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef,
  ) {}

  transform<T extends Labeled>(items: T[] | null | undefined): T[] {
    if (!items || items.length === 0) {
      this.transformedItems = []
      return this.transformedItems as T[]
    }

    if (this.lastItems !== items) {
      this.lastItems = items
      this.subscribeToTranslations(items)
    }

    return (this.transformedItems || []) as T[]
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.lastItems = null
      this.transformedItems = null
    }
  }

  private subscribeToTranslations(items: MenuItem[] | null): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }

    if (!items || items.length === 0) {
      this.transformedItems = []
      return
    }

    const translationLabels: Record<number, string> = {}
    items.forEach((item, index) => {
      translationLabels[index] = item.label ?? ''
    })

    this.subscription = this.languageService
      .massTranslate$(translationLabels)
      .pipe(
        map((labels) =>
          items.map((item, index) => ({
            ...item,
            label: labels[index] ?? item.label,
          })),
        ),
      )
      .subscribe((translatedItems) => {
        this.transformedItems = translatedItems
        this.cdr.markForCheck()
      })
  }
}
