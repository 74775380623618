import MapboxDraw from '@mapbox/mapbox-gl-draw'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle'

let mapboxDrawOverridesApplied = false

export const applyMapboxDrawOverrides = () => {
  if (mapboxDrawOverridesApplied) {
    return undefined
  }

  // Override default classes in MapBox controls layer
  MapboxDraw.constants.classes.CONTROL_BASE = 'maplibregl-ctrl' as 'mapboxgl-ctrl'
  MapboxDraw.constants.classes.CONTROL_PREFIX = 'maplibregl-ctrl-' as 'mapboxgl-ctrl-'
  MapboxDraw.constants.classes.CONTROL_GROUP = 'maplibregl-ctrl-group' as 'mapboxgl-ctrl-group'

  // Plugin for DirectMode for emitting additional events
  const original_dragFeature = DirectMode.dragFeature
  DirectMode.dragFeature = function (...args: unknown[]) {
    this.map.fire('draw.drag-feature')
    return original_dragFeature.call(this, ...args)
  }

  // Plugin for DirectMode for emitting additional events
  const original_dragVertex = DirectMode.dragVertex
  DirectMode.dragVertex = function (...args: unknown[]) {
    this.map.fire('draw.drag-vertex')
    return original_dragVertex.call(this, ...args)
  }

  // Plugin for SimpleSelectMode for emitting additional events
  const original_dragMove = SimpleSelectMode.dragMove
  SimpleSelectMode.dragMove = function (...args: unknown[]) {
    this.map.fire('draw.drag-feature')
    return original_dragMove.call(this, ...args)
  }

  // Clear vertex selection after changes
  const original_stopDragging = DirectMode.stopDragging
  DirectMode.stopDragging = function (state: any) {
    if (state.dragMoving && state.selectedCoordPaths.length) {
      if (state.timeout_stopDragging) {
        clearTimeout(state.timeout_stopDragging)
      }
      state.timeout_stopDragging = setTimeout(() => {
        state.selectedCoordPaths = []
        state.timeout_stopDragging = undefined
      }, 25)
    }
    return original_stopDragging.call(this, state)
  }

  // Clear vertex selection after changes
  const original_onMidpoint = DirectMode.onMidpoint
  DirectMode.onMidpoint = function (state: any, e: unknown) {
    if (state.timeout_onMidpoint) {
      clearTimeout(state.timeout_onMidpoint)
    }
    state.timeout_onMidpoint = setTimeout(() => {
      if (!state.dragMoving) {
        state.selectedCoordPaths = []
      }
      state.timeout_onMidpoint = undefined
    }, 250)
    return original_onMidpoint.call(this, state, e)
  }

  // Fixes unnecessary drag after short tap
  const original_onTap = DirectMode.onTap
  DirectMode.onTap = function (state: any, e: unknown) {
    DirectMode.onTouchEnd.call(this, state, e)
    return original_onTap.call(this, state, e)
  }

  mapboxDrawOverridesApplied = true
}
