import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-polygon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05078 5.72884C5.17787 5.15692 5.68514 4.75 6.27101 4.75H13.729C14.3149 4.75 14.8221 5.15692 14.9492 5.72884L16.727 13.7288C16.9005 14.5095 16.3065 15.25 15.5068 15.25H4.49323C3.69355 15.25 3.09952 14.5095 3.273 13.7288L5.05078 5.72884Z"
        [attr.fill]="color"
        fill-opacity="0.12"
        [attr.stroke]="color"
        style="fill-opacity:0.12;stroke-opacity:1;"
        stroke-width="1.5"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PolygonIconComponent extends BaseIconComponent {}
