import { ComponentRef } from '@angular/core'
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs'
import { Marker } from 'maplibre-gl'
import { MapLibreMapAdapter } from '../adapters'
import { Coordinates, GeofenceData, GeofenceMarkerEvents, GeofenceMarkerState } from '../contracts'
import {
  GeofenceEditMarkerStep,
  GeofenceFormComponent,
  GeofenceMarkerComponent,
} from '../components'
import { BaseMarker } from '../markers/base'
import { toLngLat } from '../utils'

export interface GeofenceMarkerOptions extends GeofenceData {
  step?: GeofenceEditMarkerStep
  latLng: Coordinates
}

export abstract class GeofenceMarker extends BaseMarker {
  public readonly store = {
    step$: new ReplaySubject<GeofenceEditMarkerStep>(1),
    name$: new ReplaySubject<string>(1),
    offset$: new BehaviorSubject<number>(20),
    isSaving$: new BehaviorSubject<boolean>(false),
    isPinned$: new BehaviorSubject<boolean>(true),
    notAllowedNames$: new BehaviorSubject<string[]>([]),
  } satisfies GeofenceMarkerState

  public readonly events = {
    activate$: new Subject<void>(),
    cancel$: new Subject<void>(),
    delete$: new Subject<void>(),
    save$: new Subject<{ data: { name?: string }; setupAlert?: boolean }>(),
    setShape$: new Subject<'polygon' | 'circle'>(),
    pinned$: new Subject<boolean>(),
  } satisfies GeofenceMarkerEvents

  public abstract getLatLng(): Coordinates
  public abstract setLatLng(value: Coordinates): void

  public setName(name: string) {
    this.store.name$.next(name)
  }

  public setStep(step: GeofenceEditMarkerStep) {
    this.store.step$.next(step)
  }

  public setOffset(offset: number) {
    this.store.offset$.next(offset)
  }

  public setIsPinned(isPinned: boolean) {
    this.store.isPinned$.next(isPinned)
  }

  public setNotAllowedNames(names: string[]) {
    this.store.notAllowedNames$.next(names)
  }

  public override destroy() {
    super.destroy()
    Object.values(this.store).forEach((subject) => subject.complete())
    Object.values(this.events).forEach((subject) => subject.complete())
  }
}

export class MapLibreGeofenceMarker extends GeofenceMarker {
  protected markerRef!: Marker
  protected formComponentRef?: ComponentRef<GeofenceFormComponent>
  protected markerComponentRef?: ComponentRef<GeofenceMarkerComponent>

  public constructor(
    public override readonly options: GeofenceMarkerOptions,
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.markerRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))
    this.markerRef.addTo(this.adapter.map)

    this.render()

    this.setName(options.name ?? '')
    this.setStep(options.step ?? GeofenceEditMarkerStep.MapPin)

    this.initFormComponent()
  }

  public override getLatLng(): Coordinates {
    return this.options.latLng
  }

  public override setLatLng(value: Coordinates): void {
    this.markerRef.setLngLat(toLngLat(value))
  }

  protected render() {
    if (!this.markerComponentRef) {
      this.adapter
        .getComponentFactory()(GeofenceMarkerComponent, { state: this.store, events: this.events })
        .then((ref) => {
          this.markerRef.getElement().appendChild(ref.location.nativeElement)
          this.markerComponentRef = ref
          this.markerComponentRef.changeDetectorRef.detectChanges()
        })
    }
  }

  protected initFormComponent() {
    this.store.step$.subscribe(async (step) => {
      if (step !== GeofenceEditMarkerStep.MapPin) {
        if (!this.formComponentRef) {
          this.formComponentRef = await this.adapter.getComponentFactory()(GeofenceFormComponent, {
            events: this.events,
            state: this.store,
          })
          this.formComponentRef.instance.setOffset(this.store.offset$.getValue())
          this.formComponentRef.instance.bindToElement(
            this.markerRef.getElement(),
            this.adapter.map.getContainer(),
          )
        }
      } else if (this.formComponentRef) {
        this.formComponentRef.destroy()
        this.formComponentRef = undefined
      }
    })
  }

  public override destroy() {
    super.destroy()
    this.markerRef.remove()
    this.formComponentRef?.destroy()
    this.markerComponentRef?.destroy()
  }
}
