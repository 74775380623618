@if (isSearchEnabled()) {
  <section class="search p-fluid">
    <p-iconField class="sidebar" iconPosition="left">
      <p-inputIcon>
        <app-icon-search [height]="18" [width]="18"></app-icon-search>
      </p-inputIcon>
      <input
        #searchInput
        class="h--40 search-input"
        pInputText
        type="text"
        [value]="searchTerm()"
        [placeholder]="'button.search' | translate"
        (keyup)="searchTerm.set(searchInput.value)"
      />

      @if (searchTerm().length) {
        <p-inputIcon>
          <app-icon-close
            class="clickable"
            [color]="'var(--color-gray-500)'"
            (click)="searchTerm.set('')"
          ></app-icon-close>
        </p-inputIcon>
      }
    </p-iconField>
  </section>
}

@if (isSelectAllCheckboxEnabled()) {
  <div class="list-item select-all has-bottom-separator">
    <p-checkbox
      #checkboxRef
      class="h--20"
      type="checkbox"
      [binary]="true"
      [ngModel]="selectAllCheckboxValue()"
      (onChange)="toggleSelectAll()"
    />
    <div
      class="filter-label text-14 fw-400 clickable"
      (click)="checkboxRef.updateModel(!checkboxRef.checked())"
    >
      {{ 'filter-panel.select-all' | translate }}
    </div>
  </div>
}

@for (item of visibleItems(); track item.value) {
  <div class="list-item" [ngClass]="{ active: _selectedItems().has(item.value) }">
    <p-checkbox
      #checkboxRef
      class="h--20"
      type="checkbox"
      [binary]="true"
      [ngModel]="_selectedItems().has(item.value)"
      (onChange)="onItemSelect(item.value)"
    />
    <div
      class="filter-label text-14 fw-400 clickable"
      (click)="checkboxRef.updateModel(!checkboxRef.checked())"
    >
      {{ item.label }}
    </div>
  </div>
}

@if (visibleItems().length < filteredItems().length) {
  <p-button class="show-more" severity="primary" link="true" (click)="showMoreClicked.set(true)">
    {{ 'button.show-more' | translate }}
  </p-button>
}
