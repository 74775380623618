import { Map as MaplibreGlMap, MapTouchEvent } from 'maplibre-gl'
import { addMaplibreEventListener } from './add-event-listener'

export const addMaplibreLongTouchListener = (
  map: MaplibreGlMap,
  layerId: string | undefined,
  cb: (event: MapTouchEvent) => void,
  duration = 750,
): (() => void) => {
  let longPressTimer: number | undefined
  const touchStartListener = (e: MapTouchEvent) => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
    }
    longPressTimer = window.setTimeout(() => cb(e), duration)
  }
  const touchCancelListener = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer)
    }
    longPressTimer = undefined
  }

  const offCallbacks = new Array<() => void>()
  offCallbacks.push(addMaplibreEventListener(map, 'touchstart', layerId, touchStartListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchend', layerId, touchCancelListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchcancel', layerId, touchCancelListener))
  offCallbacks.push(addMaplibreEventListener(map, 'touchmove', undefined, touchCancelListener))
  offCallbacks.push(addMaplibreEventListener(map, 'movestart', undefined, touchCancelListener))
  return () => offCallbacks.forEach((cb) => cb())
}
