import {
  EventEmitter,
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core'
import { createTextMaskInputElement } from 'text-mask-core'
import { fromEvent, takeUntil } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'
import { createDateTextMaskPipe } from '../../utils/create-date-text-mask-pipe'

@Component({
  selector: 'app-time-input',
  templateUrl: 'time-input.component.html',
  styleUrls: ['time-input.component.scss'],
})
export class TimeInputComponent implements AfterViewInit {
  @Output() timeChanged = new EventEmitter<Date>()

  @ViewChild('timeInputRef')
  protected readonly timeInputRef?: ElementRef

  protected readonly destroy$ = injectDestroy$()

  protected time?: string
  protected period?: DayPeriod
  protected _date?: Date

  @Input() placeholderMask? = ''
  @Input() isSecondsSupported? = false
  @Input() isOutlined? = false
  @Input() set date(value: Date) {
    if (this._date !== value) {
      this._date = value
      setTimeout(() => this.readTimeFromDate(), 8)
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.timeInputRef?.nativeElement) {
        this.initTimeInputMask(this.timeInputRef.nativeElement)
      }
    }, 16)
  }

  protected readTimeFromDate() {
    if (this._date) {
      this.time = formatTimeTo12H(
        this._date.getHours(),
        this._date.getMinutes(),
        this.isSecondsSupported ? this._date.getSeconds() : undefined,
      )
      this.period = this._date.getHours() >= 12 ? 'pm' : 'am'
    }
  }

  protected onTimeChanges(time?: string | null) {
    if (!time) {
      return this.readTimeFromDate()
    }

    const parsed = parseTimeFrom12H(time, this.period || 'am')
    if (parsed) {
      const time = formatTimeTo12H(parsed.hours, parsed.minutes, parsed.seconds)
      if (this.time !== time || this.period !== parsed.period) {
        this.time = formatTimeTo12H(parsed.hours, parsed.minutes, parsed.seconds)
        this.period = parsed.period
      }

      let hasChanges = false
      if (this?._date) {
        const date = new Date(this._date)
        if (this._date.getHours() !== parsed.hours) {
          date.setHours(parsed.hours)
          hasChanges = true
        }
        if (this._date.getMinutes() !== parsed.minutes) {
          date.setMinutes(parsed.minutes)
          hasChanges = true
        }
        if (parsed.seconds) {
          if (this._date.getSeconds() !== parsed.seconds) {
            date.setSeconds(parsed.seconds)
            hasChanges = true
          }
        }

        if (hasChanges) {
          this.timeChanged.emit(date)
        }
      }
    } else {
      this.readTimeFromDate()
    }
  }

  protected onPeriodChanged(period: DayPeriod) {
    if (this.period !== period) {
      this.period = period
      if (this.time) {
        this.onTimeChanges(this.time)
      }
    }
  }

  protected initTimeInputMask(timeInput: HTMLInputElement) {
    // Create the text mask input element
    const textMask = createTextMaskInputElement({
      inputElement: timeInput,
      mask: this.isSecondsSupported
        ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]
        : [/\d/, /\d/, ':', /\d/, /\d/],
      pipe: createDateTextMaskPipe(this.isSecondsSupported ? 'HH:MM:SS' : 'HH:MM'),
      guide: true,
    })

    timeInput.value = this.time ?? ''
    textMask.update()

    fromEvent(timeInput, 'input')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        if ((event as KeyboardEvent).key === 'Enter') {
          timeInput.blur()
        } else {
          textMask.update()
        }
      })
  }
}

export type DayPeriod = 'am' | 'pm'

export const formatTimeTo12H = (hours: number, minutes: number, seconds?: number) => {
  const hoursStr = `${hours % 12 || 12}`.padStart(2, '0')
  const minutesStr = `${minutes}`.padStart(2, '0')
  const secondsStr = `${seconds}`.padStart(2, '0')
  if (typeof seconds === 'number') {
    return `${hoursStr}:${minutesStr}:${secondsStr}`
  }
  return `${hoursStr}:${minutesStr}`
}

// Returns time in 24h format
export const parseTimeFrom12H = (
  time: string,
  period: DayPeriod,
):
  | {
      hours: number
      minutes: number
      seconds?: number
      period: DayPeriod
    }
  | undefined => {
  if (!time.includes('_')) {
    let [hours, minutes, seconds] = time.split(':').map((v) => parseInt(v))
    if (hours > 12) {
      hours = hours % 12 || 12
      period = 'pm'
    }
    if (minutes >= 60) {
      minutes = 59
    }
    if (seconds >= 60) {
      seconds = 59
    }

    // Convert the hours to 24-hour format
    if (period.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0
    }

    if (seconds) {
      return { hours, minutes, seconds, period }
    }

    return { hours, minutes, period }
  }
}
