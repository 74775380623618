import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-distance',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.499 5.24488C6.36106 5.24543 6.24963 5.3576 6.25 5.49554C6.25037 5.63348 6.36239 5.74506 6.50033 5.74488C6.63827 5.74469 6.75 5.63282 6.75 5.49488C6.75035 5.42829 6.72399 5.36435 6.67681 5.31735C6.62964 5.27036 6.56558 5.24426 6.499 5.24488"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 5.5V5.5C3 3.567 4.567 2 6.5 2V2C8.433 2 10 3.567 10 5.5V5.5C10 7.13851 8.212 8.88881 7.192 9.75435C6.78918 10.0819 6.21182 10.0819 5.809 9.75435C4.788 8.88881 3 7.13851 3 5.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 17.2C16 16.8397 16.1938 16.5073 16.5073 16.3298C16.8208 16.1522 17.2056 16.1571 17.5145 16.3425L20.5145 18.1425C20.8157 18.3232 21 18.6487 21 19C21 19.3512 20.8157 19.6768 20.5145 19.8575L17.5145 21.6575C17.2056 21.8428 16.8208 21.8477 16.5073 21.6702C16.1938 21.4927 16 21.1603 16 20.8V17.2Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2341 18.9951H8.6271C7.07681 18.8858 5.90439 17.5473 6.0001 15.9961V15.9931C5.90439 14.4419 7.07681 13.1035 8.6271 12.9941H15.3721C16.9224 12.8848 18.0948 11.5463 17.9991 9.99512C18.0965 8.44244 16.9221 7.10237 15.3701 6.99512L13.0001 7.00012"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DistanceIconComponent extends BaseIconComponent {}
