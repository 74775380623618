import { inject, Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { BrowserLocalStorage, BrowserSessionStorage, injectDestroy$ } from '@ti-platform/web/common'
import { MyProfile } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { AuthSession, LOGIN_AS_SESSION, Profile } from '@ti-platform/web/auth'
import environment from '@ti-platform/tsp-admin/env'
import * as Sentry from '@sentry/angular'

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  protected readonly api = inject(ApiService)
  protected readonly profile = inject(Profile)
  protected readonly authSession = inject(AuthSession)
  protected readonly loginAsSession = inject(LOGIN_AS_SESSION)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly destroy$ = injectDestroy$()

  protected readonly data$ = new ReplaySubject<MyProfile>(1)

  public init() {
    this.authSession.state.currentUserAttributes$.subscribe((user) => {
      if (environment.sentry.enabled && user) {
        Sentry.setUser({
          id: user.sub,
          username: user.name,
          email: user.email,
        })
      }
    })
    this.authSession.state.onSignedOut$.subscribe(() => {
      if (environment.sentry.enabled) {
        Sentry.setUser(null)
      }
      this.sessionStorage.clear()
      this.localStorage.clear()
    })
    if (!this.loginAsSession) {
      this.profile.state.subscribe((profile) => {
        if (profile) {
          return this.updateTimezone(profile)
        }
      })
    }
  }

  protected async updateTimezone(profile: MyProfile) {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (browserTimezone && browserTimezone !== profile.timezone) {
      await this.api.tsp.user.setTimezone(browserTimezone)
    }
  }
}
