@if (deviceService.isMobile$ | async) {
  <header class="grid-header-mobile flex gap-16" [class.mb-16]="filterPanelStyle !== 'default'">
    @if (showMobileMenuButton && !mobileMenuButtonTemplate) {
      <p-button
        (click)="sidebarService.show()"
        [severity]="'secondary'"
        class="menu-button h--40 show-on--mobile"
        icon="pi pi-bars"
      ></p-button>
    }

    @if (mobileMenuButtonTemplate) {
      <ng-container [ngTemplateOutlet]="mobileMenuButtonTemplate"></ng-container>
    }

    @if (!isSearchOpened()) {
      <h1 class="text-16 fw-600">{{ titleText | translate }}</h1>

      @if (showSearch) {
        <app-icon-search
          class="clickable"
          [height]="20"
          [width]="20"
          [color]="'#667085'"
          (click)="isSearchOpened.set(true)"
        ></app-icon-search>
      }

      @if (filterOptions && filterPanelStyle === 'compact') {
        <app-header-action-menu
          [gridSortOrder]="sortOrder"
          [defaultSortOrder]="defaultSortOrder"
          [filtersCount]="filtersCount"
        >
        </app-header-action-menu>
      }

      @if (actionButtonsTemplate) {
        <section class="grid-actions">
          <ng-container
            [ngTemplateOutlet]="actionButtonsTemplate"
            [ngTemplateOutletContext]="{ isMobile: true }"
          ></ng-container>
        </section>
      }
    } @else {
      <div class="search-container p-fluid">
        <p-iconField class="h--40">
          <p-inputIcon>
            <app-icon-search [height]="20" [width]="20" [color]="'#667085'"></app-icon-search>
          </p-inputIcon>
          <input
            (ngModelChange)="searchChanged.emit($event)"
            [ngModel]="search"
            [placeholder]="'button.search' | translate"
            class="search-input text-14"
            pInputText
            type="text"
          />
          <p-inputIcon *ngIf="search?.length">
            <app-icon-close
              class="clickable"
              (click)="clearSearch.emit()"
              [color]="'var(--color-gray-500)'"
            ></app-icon-close>
          </p-inputIcon>
        </p-iconField>
      </div>

      <p-button
        severity="primary"
        [link]="true"
        (click)="clearSearch.emit(); isSearchOpened.set(false)"
      >
        {{ 'common.done' | translate }}
      </p-button>
    }
  </header>

  @if (filterPanelStyle === 'default' && (showDatePicker || (filterOptions?.length ?? 0) > 0)) {
    <app-filter-bar
      class="p-16 flex"
      [dateRange]="dateRange"
      [defaultSortOrder]="defaultSortOrder"
      [gridSortOrder]="sortOrder"
      [filtersCount]="filtersCount"
      [showFilters]="(filterOptions?.length ?? 0) > 0"
      [showDatePicker]="showDatePicker"
      (dateRangeChanged)="dateRangeChanged.emit($event)"
    />
  }
} @else {
  @if (breadcrumbs) {
    <section class="breadcrumbs flex flex-row">
      <p-button
        (click)="sidebarService.show()"
        [severity]="'secondary'"
        class="menu-button h--40 show-on--mobile"
        icon="pi pi-bars"
      ></p-button>

      <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
    </section>
  }

  <header class="grid-header" [class.with-breadcrumbs]="!!breadcrumbs">
    <div class="heading-group flex">
      @if (returnButtonTemplate) {
        <ng-container [ngTemplateOutlet]="returnButtonTemplate"></ng-container>
      }

      <section class="flex flex--col">
        <section class="title-section">
          <h1 class="text-{{ headerTextSize }} fw-600">{{ titleText | translate }}</h1>
          @if (titleBadge) {
            <ng-container [ngTemplateOutlet]="titleBadge"></ng-container>
          }
        </section>

        @if (descriptionText) {
          <span class="text-light-description--header hide-on--mobile">
            {{ descriptionText | translate }}
          </span>
        }
      </section>

      @if (countTemplate) {
        <ng-container [ngTemplateOutlet]="countTemplate"></ng-container>
      }
    </div>

    <div class="controls">
      @if (showSearch) {
        <div class="search-container p-fluid">
          <p-iconField class="h--40">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20" [color]="'#667085'"> </app-icon-search>
            </p-inputIcon>
            <input
              (ngModelChange)="searchChanged.emit($event)"
              [ngModel]="search"
              [placeholder]="'button.search' | translate"
              class="search-input text-14"
              pInputText
              type="text"
            />
            <p-inputIcon *ngIf="search?.length">
              <app-icon-close
                class="clickable"
                (click)="clearSearch.emit()"
                [color]="'var(--color-gray-500)'"
              ></app-icon-close>
            </p-inputIcon>
          </p-iconField>
        </div>
      }

      @if (showDatePicker) {
        <app-date-range-picker-dialog
          (dateRangeChange)="dateRangeChanged.emit($event)"
          [btnClass]="'fw-btn--mobile'"
          [withTime]="showDatePickerTime"
          [dateRange]="dateRange || undefined"
          class="controls-button"
          [class.hide-on--mobile]="filterPanelStyle === 'none'"
        ></app-date-range-picker-dialog>
      }

      @if (filterOptions) {
        <p-button
          (click)="filterPanelRef.open()"
          [severity]="'secondary'"
          badge="{{ filtersCount || undefined }}"
          class="controls-button fw-btn--mobile filters text-14 fw-600 h--40 p--18"
        >
          <app-icon-filters [height]="20" [width]="20" class="icon"></app-icon-filters>
          {{ 'button.filters' | translate }}
        </p-button>
      }

      @if (extraControlsTemplate) {
        <ng-container [ngTemplateOutlet]="extraControlsTemplate"></ng-container>
      }

      @if (actionButtonsTemplate) {
        <div class="vertical-spacer--12"></div>

        <section class="grid-actions">
          <ng-container [ngTemplateOutlet]="actionButtonsTemplate"></ng-container>
        </section>
      }
    </div>
  </header>

  @if (filterTags?.length) {
    <div class="filters-tags-wrapper">
      <app-slider [tags]="filterTags ?? []" (tagRemoved)="removeFilter($event)"></app-slider>
    </div>
  }
}

<app-filter-panel
  #filterPanelRef
  (filterValueChange)="filterChanged.emit($event)"
  (gridSortChanged)="sortChanged.emit($event)"
  [class.hide-on--mobile]="filterPanelStyle === 'none'"
  [defaultSortOrder]="defaultSortOrder"
  [filterOptions]="filterOptions"
  [filterValue]="filterValue"
  [gridColumns]="columns"
  [gridSortOrder]="sortOrder"
  [isFilteringEnabled]="true"
  [isSortingEnabled]="true"
/>
