import { inject, Injectable } from '@angular/core'
import { from, map, Observable, takeUntil } from 'rxjs'
import { SelectItem } from 'primeng/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { FuelConsumptionType, MeasurementSystem } from '@ti-platform/contracts'
import { shareReplay } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class FormatOptionsProvider {
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  @Memoize()
  public get timeZone$(): Observable<SelectItem[]> {
    return from(
      Promise.resolve([
        { label: 'GMT-0', value: 'GMT-0' },
        { label: 'GMT-1', value: 'GMT-1' },
        { label: 'GMT-2', value: 'GMT-2' },
        { label: 'GMT-3', value: 'GMT-3' },
        { label: 'GMT-4', value: 'GMT-4' },
        { label: 'GMT-5', value: 'GMT-5' },
        { label: 'GMT-6', value: 'GMT-6' },
        { label: 'GMT-7', value: 'GMT-7' },
        { label: 'GMT-8', value: 'GMT-8' },
        { label: 'GMT-9', value: 'GMT-9' },
      ]),
    )
  }

  @Memoize()
  public get distance$(): Observable<SelectItem[]> {
    return this.languageService
      .massTranslate$({
        miles: 'measurement-system.miles',
        kilometers: 'measurement-system.kilometers',
      })
      .pipe(
        map((labels) => {
          return [
            { label: labels.miles, value: MeasurementSystem.Imperial },
            { label: labels.kilometers, value: MeasurementSystem.Metric },
          ]
        }),
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
  }

  @Memoize()
  public get volume$(): Observable<SelectItem[]> {
    return this.languageService
      .massTranslate$({
        gallons: 'measurement-system.gallons',
        liters: 'measurement-system.liters',
      })
      .pipe(
        map((labels) => {
          return [
            { label: labels.gallons, value: MeasurementSystem.Imperial },
            { label: labels.liters, value: MeasurementSystem.Metric },
          ]
        }),
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
  }

  @Memoize()
  public get consumption$(): Observable<SelectItem[]> {
    return this.languageService
      .massTranslate$({
        mpg: 'measurement-system.mpg',
        lkm: 'measurement-system.lkm',
        kmGal: 'measurement-system.km-gal',
      })
      .pipe(
        map((labels) => {
          return [
            { label: labels.mpg, value: FuelConsumptionType.MPG },
            { label: labels.lkm, value: FuelConsumptionType.L100Km },
            { label: labels.kmGal, value: FuelConsumptionType.KmGal },
          ]
        }),
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
  }

  @Memoize()
  public get temperature$(): Observable<SelectItem[]> {
    return this.languageService
      .massTranslate$({
        fahrenheit: 'measurement-system.fahrenheit',
        celsius: 'measurement-system.celsius',
      })
      .pipe(
        map((labels) => {
          return [
            { label: labels.fahrenheit, value: MeasurementSystem.Imperial },
            { label: labels.celsius, value: MeasurementSystem.Metric },
          ]
        }),
        takeUntil(this.destroy$),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
  }
}
