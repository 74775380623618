<div
  class="round-icon-marker-wrapper"
  [ngStyle]="{
    '--icon-color': color,
  }"
>
  <div class="icon-outer">
    <div class="icon-inner" [style.background-color]="color">
      <app-transport-icon-selector
        [color]="'var(--color-white)'"
        [icon]="icon"
      ></app-transport-icon-selector>
    </div>
  </div>

  @if (status === VehicleStatus.Stopped) {
    <div class="status-icon static">
      <svg
        class="icon-stopped"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          fill="#667085"
          style="fill: #667085; fill: color(display-p3 0.4 0.4392 0.5216); fill-opacity: 1"
        />
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          stroke="#F4EBFF"
          style="stroke: #f4ebff; stroke: color(display-p3 0.9569 0.9216 1); stroke-opacity: 1"
          stroke-width="2"
        />
        <g clip-path="url(#clip0_170_6792)">
          <rect
            x="8.66699"
            y="8.66663"
            width="6.66667"
            height="6.66667"
            fill="white"
            style="fill: white; fill-opacity: 1"
          />
        </g>
      </svg>
    </div>
  } @else if (status === VehicleStatus.Idling) {
    <div class="status-icon static">
      <svg
        class="icon-idling"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          fill="var(--icon-color)"
          style="fill-opacity: 1"
        />
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          stroke="var(--border-color)"
          style="stroke-opacity: 1"
          stroke-width="2"
        />
        <path
          d="M8.5 12H15.5"
          stroke="white"
          style="stroke: white; stroke-opacity: 1"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  }
</div>
