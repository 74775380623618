import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-copy-outlined',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_197_10062)">
        <path
          d="M3.33331 10H2.66665C2.31302 10 1.97389 9.85956 1.72384 9.60952C1.47379 9.35947 1.33331 9.02033 1.33331 8.66671V2.66671C1.33331 2.31309 1.47379 1.97395 1.72384 1.7239C1.97389 1.47385 2.31302 1.33337 2.66665 1.33337H8.66665C9.02027 1.33337 9.35941 1.47385 9.60946 1.7239C9.8595 1.97395 9.99998 2.31309 9.99998 2.66671V3.33337M7.33331 6.00004H13.3333C14.0697 6.00004 14.6666 6.59699 14.6666 7.33337V13.3334C14.6666 14.0698 14.0697 14.6667 13.3333 14.6667H7.33331C6.59693 14.6667 5.99998 14.0698 5.99998 13.3334V7.33337C5.99998 6.59699 6.59693 6.00004 7.33331 6.00004Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_197_10062">
          <rect width="16" height="16" fill="white" style="fill:white;fill-opacity:1;" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CopyOutlinedIconComponent extends BaseIconComponent {
  @Input() override height = 16
  @Input() override width = 16
}
