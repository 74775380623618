import { inject, Injectable, signal } from '@angular/core'
import { BrowserSessionStorage } from '@ti-platform/web/common/models'

@Injectable({ providedIn: 'root' })
export class SidebarService {
  public readonly sessionStorage = inject(BrowserSessionStorage)
  public readonly isHidden = signal(true)
  public readonly isCollapsed = signal(false)

  public hide() {
    this.isHidden.set(!this.isHidden())
    this.isCollapsed.set(false)
  }

  public show() {
    this.isHidden.set(!this.isHidden())
    this.isCollapsed.set(false)
  }

  public open() {
    this.isHidden.set(false)
    this.isCollapsed.set(false)
  }

  public collapse() {
    this.isCollapsed.set(!this.isCollapsed())
    if (this.isCollapsed()) {
      this.isHidden.set(true)
    }
    return this.sessionStorage.setItem('sidebarCollapsed', this.isCollapsed().toString())
  }
}
