import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-alerts-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83333 14.5001V3.12012"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1654 9.8465V3.1665"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83203 9.8333C3.83203 9.8333 4.41536 9.34863 6.16536 9.34863C7.91536 9.34863 9.08203 10.5 10.832 10.5C12.582 10.5 13.1654 9.84863 13.1654 9.84863"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83203 3.12133C3.83203 3.12133 4.41536 2.5 6.16536 2.5C7.91536 2.5 9.08203 3.65133 10.832 3.65133C12.582 3.65133 13.1654 3.16667 13.1654 3.16667"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Alerts1IconComponent extends BaseIconComponent {}
