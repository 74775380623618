import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { signIn, signOut } from 'aws-amplify/auth'
import { SignInOutput } from '@aws-amplify/auth/src/providers/cognito/types'
import { BrowserSessionStorage } from '@ti-platform/web/common'
import { LOGIN_AS_SESSION } from '../constants'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected router = inject(Router)
  protected sessionStorage = inject(BrowserSessionStorage)
  protected loginAsSession = inject(LOGIN_AS_SESSION, { optional: true })

  async signIn(username: string, password: string): Promise<SignInOutput> {
    return signIn({ username, password })
  }

  async signOut(): Promise<void> {
    // Do not sign out in LoginAs mode
    if (this.loginAsSession) {
      this.sessionStorage.clear()
      await this.router.navigate(['/', 'login'])
      return window.location.reload()
    }
    return signOut()
  }
}
