import { Component, inject, Input, OnInit } from '@angular/core'
import { injectDestroy$, sleep } from '@ti-platform/web/common'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { BehaviorSubject } from 'rxjs'

export interface BulkActionWithLoadingDialogData {
  header: string
  description: string
  completeDescription: string
  confirmButton: string
  cancelButton: string
  items: any[]
  itemKey: string
  chunkSize?: number
  action: ((data: any) => Promise<void>) | null
  getFailDescription: ((data: any) => Promise<string>) | null
  getErrorSummary: ((value: number) => Promise<string>) | null
  autoClose?: boolean
  delayBeforeStart?: number
}

interface KeyResult {
  status: boolean
  description: string
}

@Component({
  selector: 'app-bulk-action-with-loading-dialog',
  templateUrl: 'bulk-action-with-loading-dialog.component.html',
  styleUrl: 'bulk-action-with-loading-dialog.component.scss',
})
export class BulkActionWithLoadingDialogComponent
  implements OnInit, BulkActionWithLoadingDialogData
{
  @Input() header = ''
  @Input() description = ''
  @Input() completeDescription = ''
  @Input() confirmButton = ''
  @Input() cancelButton = ''
  @Input() items: any[] = []
  @Input() itemKey = ''
  @Input() chunkSize = 50
  @Input() action: ((data: any) => Promise<void>) | null = null
  @Input() getFailDescription: ((data: any) => Promise<string>) | null = null
  @Input() getErrorSummary: ((value: number) => Promise<string>) | null = null
  @Input() autoClose = false
  @Input() delayBeforeStart = 0

  protected readonly destroy$ = injectDestroy$()

  protected readonly dynamicDialogRef = inject(DynamicDialogRef)
  protected readonly dynamicDialogConfig = inject(DynamicDialogConfig)

  protected readonly isDestroyed$ = new BehaviorSubject<boolean>(false)
  protected readonly progress$ = new BehaviorSubject<number>(0)
  protected readonly completed$ = new BehaviorSubject<boolean>(false)
  protected readonly allItemsLength$ = new BehaviorSubject<number>(0)
  protected readonly successItemKeys$ = new BehaviorSubject<any[]>([])
  protected readonly failedItemKeys$ = new BehaviorSubject<any[]>([])
  protected readonly failedDescriptionList$ = new BehaviorSubject<string[]>([])
  protected readonly errorsSummary$ = new BehaviorSubject<string>('')
  protected errorsAccordionIndex = 1

  public ngOnInit() {
    // Assign data from dynamic dialog config
    Object.assign(this, this.dynamicDialogConfig.data)
    this.destroy$.subscribe(() => {
      this.isDestroyed$.next(true)
    })
    this.allItemsLength$.next(this.items.length)
    this.startBulkAction()
  }

  public onCancel() {
    this.dynamicDialogRef.close({ false: true })
  }

  public onConfirm() {
    this.dynamicDialogRef.close({
      result: true,
      value: {
        all: this.allItemsLength$.value,
        success: this.successItemKeys$.value.length,
        fail: this.failedItemKeys$.value.length,
      },
    })
  }

  public onClose() {
    if (this.completed$.value) {
      this.onConfirm()
    } else {
      this.onCancel()
    }
  }

  protected async startBulkAction() {
    if (this.isDestroyed$.value || !this.action) {
      return
    }
    if (this.delayBeforeStart) {
      await sleep(this.delayBeforeStart)
      if (this.isDestroyed$.value || !this.action) {
        return
      }
    }

    const resultByKey: Record<string, KeyResult> = {}
    const allNumber = this.items.length

    const waitingTimeout = this.items.length > 200 ? 500 : 100
    let firstIndex = 0
    let lessThenIndex = Math.min(firstIndex + this.chunkSize, this.items.length)
    while (firstIndex < this.items.length) {
      const promises: Promise<void>[] = []
      for (let i = firstIndex; i < lessThenIndex; i++) {
        const data = this.items[i]
        resultByKey[data[this.itemKey]] = {
          status: false,
          description: '',
        }
        promises.push(this.processItemAction(data, resultByKey))
      }
      await Promise.all(promises)
      this.progress$.next(Math.round((lessThenIndex / allNumber) * 100))

      if (this.isDestroyed$.value) {
        return
      }
      firstIndex = firstIndex + this.chunkSize
      lessThenIndex = Math.min(firstIndex + this.chunkSize, this.items.length)

      if (firstIndex < this.items.length) {
        await sleep(waitingTimeout)
      }
    }

    if (this.isDestroyed$.value) {
      return
    }
    const successKeys: string[] = []
    const failedKeys: string[] = []
    const failedDescriptionList: string[] = []
    Object.keys(resultByKey).forEach((key) => {
      if (resultByKey[key].status) {
        successKeys.push(key)
      } else {
        failedKeys.push(key)
        failedDescriptionList.push(resultByKey[key].description)
      }
    })
    this.successItemKeys$.next(successKeys)
    this.failedItemKeys$.next(failedKeys)
    this.progress$.next(100)
    if (this.autoClose) {
      // so that to show loading more pretty way
      await sleep(1000)
      this.onConfirm()
    } else {
      if (this.getErrorSummary) {
        this.errorsSummary$.next(await this.getErrorSummary(failedKeys.length))
      }
      this.failedDescriptionList$.next(failedDescriptionList)
      this.completed$.next(true)
    }
  }

  async processItemAction(data: any, resultByKey: Record<string, KeyResult>) {
    if (this.isDestroyed$.value || !this.action) {
      return
    }
    try {
      await this.action(data)
      resultByKey[data[this.itemKey]].status = true
    } catch (e) {
      resultByKey[data[this.itemKey]].status = false
      if (this.getFailDescription) {
        resultByKey[data[this.itemKey]].description = await this.getFailDescription(data)
      }
    }
  }
}
