import { Pipe, PipeTransform } from '@angular/core'
import { Vehicle } from '@ti-platform/contracts'

@Pipe({ name: 'formatVehicleDescription', pure: true })
export class FormatVehicleDescriptionPipe implements PipeTransform {
  public transform(vehicle: Vehicle, separator = '|'): string {
    if (!vehicle) return ''

    const startParts: string[] = []

    if (vehicle.year) {
      startParts.push(vehicle.year.toString())
    }

    if (vehicle.make) {
      startParts.push(vehicle.make)
    }

    if (vehicle.model) {
      startParts.push(vehicle.model)
    }

    const firstPart = startParts.join(' ')
    const secondPart = vehicle.licensePlate || ''
    const formattedSeparator = `&nbsp;&nbsp;${separator}&nbsp;&nbsp;`

    return firstPart && secondPart
      ? `${firstPart} ${formattedSeparator} ${secondPart}`
      : firstPart || secondPart || ''
  }
}
