import { take } from 'rxjs'
import { MapLibreMapAdapter } from '../adapters'
import { BaseMarker } from './base'
import { TripMarkerComponent } from '../components'
import { MaplibreComponentMarker } from './component'
import { Coordinates } from '../contracts'
import { toLngLat } from '../utils'

export abstract class AbstractTripMarker extends BaseMarker {
  public override readonly options!: TripMarkerOptions
}

export interface TripMarkerOptions {
  tripId: number
  coordinates: Coordinates[]
}

export class MaplibreTripMarker extends AbstractTripMarker {
  protected readonly color = '#7F56D9'
  protected readonly layerID!: string
  protected readonly sourceID!: string

  protected startMarker!: MaplibreComponentMarker<TripMarkerComponent>
  protected finishMarker!: MaplibreComponentMarker<TripMarkerComponent>

  public constructor(
    public override readonly options: TripMarkerOptions,
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.layerID = `layer-trip-${options.tripId}`
    this.sourceID = `source-trip-${options.tripId}`

    this.startMarker = new MaplibreComponentMarker(
      { style: 'start', latLng: this.options.coordinates[0] },
      this.adapter,
      TripMarkerComponent,
    )

    this.finishMarker = new MaplibreComponentMarker(
      { style: 'finish', latLng: this.options.coordinates[this.options.coordinates.length - 1] },
      this.adapter,
      TripMarkerComponent,
    )

    this.adapter.onInit$.pipe(take(1)).subscribe(() => this.init())
  }

  public init() {
    this.initSource()
    this.initLayers()

    // Reinitialize the layers after style data is updated
    const repairAfterStyleRefresh = () => {
      this.initSource()
      this.initLayers()
    }
    this.adapter.map.on('styledata', repairAfterStyleRefresh)
    this.destroy$.subscribe(() => this.adapter.map.off('styledata', repairAfterStyleRefresh))
  }

  public override destroy() {
    super.destroy()
    this.adapter.map.removeLayer(this.layerID)
    this.adapter.map.removeSource(this.sourceID)
    this.startMarker.destroy()
    this.finishMarker.destroy()
  }

  protected initSource() {
    if (!this.adapter.map.getSource(this.sourceID)) {
      this.adapter.map.addSource(this.sourceID, {
        type: 'geojson',
        data: {
          id: 'trip-line',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            // Convert coordinates to LngLat format
            coordinates: this.options.coordinates.map((item) => toLngLat(item)),
          },
        },
      })
    }
  }

  protected initLayers() {
    if (!this.adapter.map.getLayer(this.layerID)) {
      this.adapter.map.addLayer({
        id: this.layerID,
        type: 'line',
        source: this.sourceID,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.color,
          'line-width': 3,
        },
      })
    }
  }
}
