import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'

@Directive({
  selector: '[appScrollDetector]',
})
export class ScrollDetectorDirective implements OnInit, OnDestroy {
  private resizeObserver?: ResizeObserver
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly renderer = inject(Renderer2)
  protected readonly elementRef = inject(ElementRef<HTMLElement>)

  public ngOnInit() {
    this.changeDetector.detectChanges()
    const element = this.elementRef.nativeElement

    this.resizeObserver = new ResizeObserver(() => {
      if (element.scrollHeight - element.clientHeight > 0) {
        this.renderer.addClass(element, 'scrollable')
      } else {
        this.renderer.removeClass(element, 'scrollable')
      }
    })

    this.resizeObserver.observe(element)
  }

  public ngOnDestroy() {
    this.resizeObserver?.unobserve(this.elementRef.nativeElement)
  }
}
