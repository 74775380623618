import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef } from '@angular/core'
import { SidebarService } from '@ti-platform/web/ui-kit/layout/services'

@Component({
  selector: 'app-header-panel',
  templateUrl: 'header-panel.component.html',
  styleUrls: ['header-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderPanelComponent {
  @Input() public title = ''
  @Input() public description = ''

  @Input() public returnButtonTemplate: TemplateRef<unknown> | null = null
  @Input() public actionButtonsTemplate: TemplateRef<unknown> | null = null
  @Input() public countTemplate: TemplateRef<unknown> | null = null

  protected readonly sidebarService = inject(SidebarService)
}
