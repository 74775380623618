import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-flag-cross',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 9.8C3.3335 9.8 3.91683 9.2 5.66683 9.2C7.41683 9.2 8.5835 10.4 10.3335 10.4C12.0835 10.4 12.6668 9.8 12.6668 9.8V2.6C12.6668 2.6 12.0835 3.2 10.3335 3.2C8.5835 3.2 7.41683 2 5.66683 2C3.91683 2 3.3335 2.6 3.3335 2.6V9.8Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M3.3335 9.8C3.3335 9.8 3.91683 9.2 5.66683 9.2C7.41683 9.2 8.5835 10.4 10.3335 10.4C12.0835 10.4 12.6668 9.8 12.6668 9.8V2.6C12.6668 2.6 12.0835 3.2 10.3335 3.2C8.5835 3.2 7.41683 2 5.66683 2C3.91683 2 3.3335 2.6 3.3335 2.6V9.8ZM3.3335 9.8V14"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 5L9.33317 7.66667M9.33317 5L6.6665 7.66667"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FlagCrossIconComponent extends BaseIconComponent {}
