import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-closable-tag',
  templateUrl: 'closable-tag.component.html',
  styleUrl: 'closable-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosableTagComponent {
  @Output() closeClicked = new EventEmitter<void>()
  @Input() label = ''
  @Input() styleClass = ''

  protected async onCloseClick() {
    this.closeClicked.emit()
  }
}
