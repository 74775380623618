import { Action, CRUD_ACTIONS, Resource, UserPermissions } from '../models'

export const TSP_OWNER_PERMISSIONS = {
  [Resource.Billing]: [Action.View],
  [Resource.Device]: [...CRUD_ACTIONS],
  [Resource.Fleet]: [...CRUD_ACTIONS, Action.Suspend, Action.LoginAs],
  [Resource.User]: [...CRUD_ACTIONS],
  [Resource.WhiteLabel]: [Action.View, Action.Update],
} as UserPermissions

export const TSP_ADMIN_PERMISSIONS = {
  [Resource.Billing]: [Action.View],
  [Resource.Device]: [...CRUD_ACTIONS],
  [Resource.Fleet]: [...CRUD_ACTIONS, Action.Suspend, Action.LoginAs],
  [Resource.User]: [...CRUD_ACTIONS],
  [Resource.WhiteLabel]: [Action.View],
}

export const TSP_FINANCE_PERMISSIONS = {
  [Resource.Billing]: [Action.View],
} as UserPermissions

export const TSP_SUPPORT_PERMISSIONS = {
  [Resource.Device]: [Action.View, Action.Update],
  [Resource.Fleet]: [Action.View, Action.LoginAs],
  [Resource.WhiteLabel]: [Action.View],
} as UserPermissions

export const TSP_VIEW_ONLY_PERMISSIONS = {
  [Resource.Device]: [Action.View],
  [Resource.Fleet]: [Action.View],
  [Resource.WhiteLabel]: [Action.View],
} as UserPermissions

export const FLEET_OWNER_PERMISSIONS = {
  [Resource.Alert]: [...CRUD_ACTIONS],
  [Resource.Billing]: [Action.View],
  [Resource.Driver]: [...CRUD_ACTIONS],
  [Resource.Fleet]: [Action.View, Action.Update],
  [Resource.Location]: [...CRUD_ACTIONS],
  [Resource.Reports]: [Action.View],
  [Resource.User]: [...CRUD_ACTIONS],
  [Resource.Vehicle]: [Action.View, Action.Update],
  [Resource.Media]: [...CRUD_ACTIONS],
} as UserPermissions

export const FLEET_ADMIN_PERMISSIONS = { ...FLEET_OWNER_PERMISSIONS }

export const FLEET_INSTALLER_PERMISSIONS = {
  [Resource.Fleet]: [Action.View],
  [Resource.Vehicle]: [Action.View, Action.Update],
} as UserPermissions

export const FLEET_VIEW_ONLY_PERMISSIONS = {
  [Resource.Alert]: [Action.View],
  [Resource.Billing]: [Action.View],
  [Resource.Driver]: [Action.View],
  [Resource.Fleet]: [Action.View],
  [Resource.Location]: [Action.View],
  [Resource.Reports]: [Action.View],
  [Resource.User]: [Action.View],
  [Resource.Vehicle]: [Action.View],
  [Resource.Media]: [Action.View],
} as UserPermissions

export const FLEET_DEMO_PERMISSIONS = { ...FLEET_VIEW_ONLY_PERMISSIONS }
