@if (state.step$ | async; as step) {
  @if (step === GeofenceEditMarkerStep.SelectShape) {
    <div class="card select-shape">
      <div class="title text-14 fw-500">{{ 'map.geofence-form.create' | translate }}</div>
      <div class="description text-14 fw-400">
        {{ 'map.geofence-form.select-type' | translate }}
      </div>

      <div class="actions flex gap-12">
        <div class="circle clickable" (click)="events.setShape$.next('circle')">
          <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="88" height="88" rx="44" fill="var(--icon-color)" style="fill-opacity: 1" />
          </svg>
        </div>
        <div class="polygon clickable" (click)="events.setShape$.next('polygon')">
          <svg
            width="148"
            height="120"
            viewBox="0 0 148 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.9529 22.2834C34.759 18.6141 38.0097 16 41.7666 16H106.233C109.99 16 113.241 18.6141 114.047 22.2834L129.865 94.2833C130.962 99.2744 127.162 104 122.052 104H25.9484C20.8383 104 17.0382 99.2744 18.1347 94.2834L33.9529 22.2834Z"
              fill="var(--icon-color)"
              style="fill-opacity: 1"
            />
          </svg>
        </div>
      </div>
    </div>
  }

  @if (step === GeofenceEditMarkerStep.Create || step === GeofenceEditMarkerStep.Edit) {
    <div class="card edit-geofence">
      <form class="form p-fluid" [formGroup]="form">
        <div class="field">
          <div class="label mb-8 text-14 fw-500">
            {{ 'map.geofence-form.name' | translate }}
            <span class="required">*</span>
          </div>

          <input
            #nameInput
            formControlName="name"
            pInputText
            type="text"
            (click)="nameInput.focus()"
          />

          @if (form.touched && form.invalid) {
            <div class="errors-box">
              @if (form.controls.name.hasError('required')) {
                <p class="error-message">
                  {{ 'map.geofence-form.errors.name-required' | translate }}
                </p>
              }
              @if (form.controls.name.hasError('not-allowed')) {
                <p class="error-message">
                  {{ 'map.geofence-form.errors.name-not-allowed' | translate }}
                </p>
              }
            </div>
          }
        </div>

        <div class="actions flex gap-12">
          <p-button icon="pi" severity="secondary" (click)="events.cancel$.next()">
            <app-icon-close height="20" width="20"></app-icon-close>
          </p-button>

          @if (step === GeofenceEditMarkerStep.Create) {
            <p-button
              class="text-14 fw-500 w100 no-wrap"
              severity="secondary"
              (click)="onSubmit(true)"
            >
              {{ 'map.geofence-form.save-and-setup-alert' | translate }}
            </p-button>
          }

          @if (step === GeofenceEditMarkerStep.Edit) {
            <p-button icon="pi" severity="secondary" (click)="events.delete$.next()">
              <app-icon-delete
                height="20"
                width="20"
                color="var(--color-alert-500)"
              ></app-icon-delete>
            </p-button>
          }

          <p-button
            class="text-14 fw-500 no-wrap"
            severity="primary"
            [class.w100]="step === GeofenceEditMarkerStep.Edit"
            [disabled]="state.isSaving$ | async"
            (click)="onSubmit()"
          >
            {{ 'button.save' | translate }}
          </p-button>
        </div>
      </form>

      @if ({ value: state.isPinned$ | async }; as isPinned) {
        <div class="pin-btn clickable" (click)="events.pinned$.next(!isPinned.value)">
          <i class="pi {{ isPinned.value ? 'pi-lock' : 'pi-lock-open' }}"></i>
        </div>
      }
    </div>
  }
}
