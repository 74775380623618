<div class="autocomplete-select" [class]="className">
  <p-autoComplete
    #itemSelect
    [ngModel]="value"
    [disabled]="disabled"
    [delay]="delay"
    (completeMethod)="onCompleteMethod($event)"
    (onHide)="onSelectHide()"
    (onShow)="onSelectShow()"
    [completeOnFocus]="completeOnFocus"
    [forceSelection]="forceSelection"
    [suggestions]="suggestions"
    class="h--40 with-filter-icon"
    appendTo="body"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    (onSelect)="selectOption($event)"
    (onClear)="clearOption()"
    [virtualScrollItemSize]="25"
    panelStyleClass="autocomplete-select-panel"
    inputStyleClass="autocomplete-select-input"
  >
    <ng-template let-param pTemplate="item">
      <div class="option-item text-14">
        <div>{{ param[optionLabel] }}</div>
      </div>
    </ng-template>
    <ng-template pTemplate="empty">
      <div class="option-item text-14">
        <div>{{ 'common.no-results' | translate }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
  <div class="autocomplete-icon">
    <i (click)="onClickDropdown()" [class]="filterBtnStyleClass || ''"></i>
  </div>
</div>
