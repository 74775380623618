<div class="circle">
  <div class="text text-14 fw-600">
    {{ timerValue() }}
  </div>
  <svg>
    <circle class="bg" cx="22" cy="22" r="20"></circle>
    <circle
      class="progress"
      cx="22"
      cy="22"
      r="20"
      [style.stroke-dashoffset]="svgOffset()"
    ></circle>
  </svg>
</div>
