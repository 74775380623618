<div class="full-screen-container" [ngClass]="{ 'split-view': isSplitView$ | async }">
  <div class="top-section flex">
    <ng-container [ngTemplateOutlet]="topTmpl"></ng-container>
  </div>

  <div class="bottom-section flex--col">
    <header
      (touchstart)="startMobilePanelResize($event)"
      (mousedown)="startMobilePanelResize($event)"
    >
      <div class="header-content">
        <ng-container [ngTemplateOutlet]="headerTmpl"></ng-container>
      </div>

      <div class="drag-group show-in-split-view">
        <div class="drag-panel"></div>
      </div>

      <div class="counter-box show-in-split-view">
        <ng-container [ngTemplateOutlet]="counterTmpl"></ng-container>
      </div>
    </header>

    <div class="content-wrapper">
      <ng-container [ngTemplateOutlet]="bottomTmpl"></ng-container>
    </div>
  </div>

  @if (!isSplitView$.value && switchViewIconTmpl) {
    <div class="switch-view-button clickable" (click)="isSplitView$.next(true)">
      <ng-container [ngTemplateOutlet]="switchViewIconTmpl"></ng-container>
    </div>
  }

  <p-button
    class="menu-button h--40"
    icon="pi pi-bars"
    [severity]="'secondary'"
    (click)="sidebarService.show()"
  ></p-button>
</div>
