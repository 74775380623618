import {
  Command,
  CommandMainData,
  CreateCommandData,
  DataQueryProps,
  EditCommandData,
  TspCommandListData,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class CommandsRoute extends ApiRoute {
  public override init() {
    this.route = 'tsp/commands'
  }

  public countAll() {
    return this.get<number>().endpoint('count-all')
  }

  public createCommand(commandData: CreateCommandData) {
    return this.post<Command>().withData(commandData).endpoint('create')
  }

  public deleteCommand(commandId: string) {
    return this.delete<Command>().endpoint(`${commandId}`)
  }

  public editCommand(commandId: string, commandData: EditCommandData) {
    return this.put<Command>().withData(commandData).endpoint(`${commandId}`)
  }

  public list(options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<TspCommandListData[]>().withParams(data).endpoint('list')
  }

  public byDeviceTypeId(deviceTypeId: number) {
    return this.get<CommandMainData[]>().withParams({ deviceTypeId }).endpoint('by-device-type')
  }

  public assignCommandToDevices(commandId: string, deviceIds: string[]) {
    return this.put<string[]>().withData({ deviceIds }).endpoint(`${commandId}/add-devices`)
  }

  public unassignCommandFromDevices(commandId: string, deviceIds: string[]) {
    return this.put<string[]>().withData({ deviceIds }).endpoint(`${commandId}/delete-devices`)
  }
}
