import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'
import { MenuItem } from 'primeng/api'
import { from, Observable } from 'rxjs'
import { Memoize, OrderByData } from '@ti-platform/web/common'
import { DateFormatType, UnitType } from '@ti-platform/web/ui-kit/i18n'

export enum DataGridColumnType {
  Text = 'text',
  TextWithCopy = 'text-with-copy',
  OnOffStatus = 'on-off',
  Count = 'count',
  Date = 'date',
  Switch = 'switch',
  Button = 'button',
  User = 'user',
  DoubleRow = 'double-row',
  Tags = 'tags',
  Actions = 'actions',
  Template = 'template',
  // Unit texts
  Distance = 'distance',
  FuelEfficiency = 'fuel-efficiency',
  Pressure = 'pressure',
  Speed = 'speed',
  Temperature = 'temperature',
  Volume = 'volume',
  Duration = 'duration',
  Battery = 'battery',
}

export interface DataGridColumn {
  field: string
  label: string
  type: DataGridColumnType
  format?: DateFormatType
  sortable?: boolean
  placeholder?: string
  distancePrecision?: number
  distancePrecisionIfLess?: number
  actions?: DataGridColumnAction[] | DataGridColumnActionsFn
  clickable?: boolean
}

// TODO: Remove redundant type
export type DataGridSortOrder = OrderByData

export interface DataGridColumnAction<T = any> extends MenuItem {
  onClick?: (target: T) => void
}

export type DataGridColumnActionsFn = <T = any>(data: T) => Observable<DataGridColumnAction<T>[]>

@Component({
  selector: 'app-data-grid',
  templateUrl: 'data-grid.component.html',
  styleUrls: ['data-grid.component.scss'],
})
export class DataGridComponent<DataType extends object> {
  @Output() readonly sortUpdated = new EventEmitter<DataGridSortOrder>()
  @Output() readonly loadMoreClicked = new EventEmitter<boolean>()
  @Output() readonly actionsClicked = new EventEmitter<boolean>()
  @Output() readonly rowItemClicked = new EventEmitter<DataType>()
  @Output() readonly rowSelected = new EventEmitter<DataType>()
  @Output() readonly rowUnselected = new EventEmitter<DataType>()

  @Input() rows = 5
  @Input() rowsPerPageOptions: number[] = [5, 10, 20]
  @Input() paginationEnabled = false
  @Input() paginationControl: 'paginator' | 'infinite-list' = 'infinite-list'
  @Input() templates: Record<string, TemplateRef<unknown>> = {}
  @Input() sortOrder?: DataGridSortOrder
  @Input() isSelectable?: boolean = false
  @Input() isMultiSelectable?: boolean = false
  @Input() keyColumn?: string = 'id'
  @Input() multiSelection?: DataType[] = []

  @Input() data: DataType[] = []
  @Input() columns: DataGridColumn[] = []
  @Input() isLoading = false

  @Input() noDataTitle?: string = 'No data available'
  @Input() noDataDescription?: string = ''
  @Input() noDataIconTemplate?: TemplateRef<unknown>
  @Input() noDataAdditionalContentTemplate?: TemplateRef<unknown>
  @Input() selectedRow?: DataType | null = null
  @Input() rowClassFn?: ((data: DataType) => string) | null = null

  protected _actionsMap = new WeakMap<object, Observable<MenuItem[]>>()

  protected readonly elementRef = inject(ElementRef)

  protected readonly ColumnType = DataGridColumnType
  protected readonly UnitType = UnitType

  public get scrollableElement(): HTMLElement | undefined {
    return this.elementRef.nativeElement?.querySelector('.p-datatable-wrapper')
  }

  protected readonly colsTrackByFn = (i: number, col: DataGridColumn) => col.field

  protected shouldUsePlaceholder(value: unknown) {
    return value === undefined || value === null || value === ''
  }

  protected getPlaceholder(column: DataGridColumn): string {
    return column.placeholder ?? '---'
  }

  @Memoize()
  protected getRowClass(data: DataType) {
    if (!this.rowClassFn) {
      return ''
    } else {
      return this.rowClassFn(data) || ''
    }
  }

  protected prepareActions(
    target: DataType,
    actions?: DataGridColumnAction[] | DataGridColumnActionsFn,
  ) {
    if (actions && !this._actionsMap.has(target)) {
      if (typeof actions === 'function') {
        // handle actions callback
        this._actionsMap.set(target, actions(target))
      } else {
        this._actionsMap.set(
          target,
          from(
            Promise.resolve(
              Array.isArray(actions)
                ? actions.map((data) => {
                    if (data?.onClick) {
                      return {
                        ...data,
                        command: () => (data.onClick ? data.onClick(target) : undefined),
                      }
                    } else {
                      return data
                    }
                  })
                : [],
            ),
          ),
        )
      }
    }
    return this._actionsMap.get(target)
  }

  protected sortFunction(e: { field: string; mode: 'single' | 'multiple'; order: -1 | 1 }) {
    const order = e.order > 0 ? 'ASC' : 'DESC'
    if (this.sortOrder?.column !== e.field || this.sortOrder?.order !== order) {
      this.sortOrder = { column: e.field, order: e.order > 0 ? 'ASC' : 'DESC' }
      this.sortUpdated.next(this.sortOrder)
    }
  }

  protected onLoadMore() {
    this.loadMoreClicked.emit()
  }

  protected onClickActions() {
    this.actionsClicked.emit()
  }

  protected onRowItemClicked(data: DataType) {
    this.rowItemClicked.emit(data)
  }

  protected isSelected(data: DataType): boolean {
    return !!this.isSelectable && this.selectedRow === data
  }

  protected onRowSelect(event: any) {
    this.rowSelected.emit(event.data as DataType)
  }

  protected onRowUnselect(event: any) {
    this.rowUnselected.emit(event.data as DataType)
  }

  protected isMultiSelected(data: DataType): boolean {
    if (!(this.isMultiSelectable && this.keyColumn)) {
      return false
    }
    const key = this.keyColumn as string
    return !!this.multiSelection?.some(
      (item) => key && (item as Record<string, any>)[key] === (data as Record<string, any>)[key],
    )
  }
}
