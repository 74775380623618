<div class="media-layout-selector">
  @for (layout of allowedLayouts; track layout) {
    <p-button
      class="h--40"
      icon="pi"
      [severity]="$any(value !== layout ? 'secondary' : undefined)"
      (click)="changeLayout(layout)"
      [disabled]="layoutChangingDisabled"
    >
      @switch (layout) {
        @case (LayoutType.Column) {
          <app-icon-group-3
            [height]="20"
            [width]="20"
            [color]="'var(--icon-color)'"
          ></app-icon-group-3>
        }
        @case (LayoutType.Block) {
          <app-icon-group-2 [color]="'var(--icon-color)'"></app-icon-group-2>
        }
        @case (LayoutType.List) {
          <app-icon-group-1 [color]="'var(--icon-color)'"></app-icon-group-1>
        }
      }
    </p-button>
  }
</div>
