<p-menu #menu [model]="options" popup="true">
  <ng-template pTemplate="item" let-item let-i="index">
    <a
      pRipple
      class="flex menu-item p-menuitem-link"
      [class.selected]="value === item.value"
      (click)="selectOption(item)"
    >
      <div class="label text-14 fw-500">{{ item.label }}</div>

      <app-icon-check
        [color]="'var(--menu-icon-color)'"
        *ngIf="value === item.value && isCheckmarkVisible"
      ></app-icon-check>
    </a>
  </ng-template>
</p-menu>
