import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-bus-1-side',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0007 4.99728H4.99609"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0002 18.5029H6.16406"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0002 9.9994V4.99731"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7013 17.2981C19.1545 17.7513 19.2901 18.4329 19.0449 19.025C18.7996 19.6172 18.2218 20.0033 17.5809 20.0033C16.9399 20.0033 16.3621 19.6173 16.1168 19.0252C15.8715 18.433 16.007 17.7514 16.4602 17.2982L16.4603 17.2981C17.0792 16.6793 18.0824 16.6793 18.7013 17.2981V17.2981"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.70127 17.2982C6.1545 17.7514 6.29011 18.433 6.04487 19.0252C5.79962 19.6173 5.22181 20.0035 4.58088 20.0035C3.93994 20.0035 3.3621 19.6174 3.11679 19.0253C2.87149 18.4332 3.00703 17.7516 3.46022 17.2983L3.46054 17.298C3.75769 17.0009 4.16072 16.8339 4.58095 16.834C5.00118 16.834 5.40417 17.001 5.70127 17.2982V17.2982"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.9963 4.99731V9.9994"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.004 12.0001H18.0023L16.5875 10.5853C16.2123 10.2101 15.7034 9.99927 15.1727 9.99927H2.99609"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1718 18.5029H20.5034C21.3322 18.5029 22.004 17.8311 22.004 17.0023V8.99898C22.004 6.78892 20.2124 4.99731 18.0023 4.99731H2.99609"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Bus1SideIconComponent extends BaseIconComponent {}
