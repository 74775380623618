<div
  class="time-input flex gap-12"
  [ngClass]="{ outlined: isOutlined, 'seconds-support': isSecondsSupported }"
>
  <input
    #timeInputRef
    pInputText
    [placeholder]="placeholderMask"
    (blur)="onTimeChanges(timeInputRef.value)"
  />

  <div class="am-pm-switch flex gap-12">
    <span
      class="text-14 fw-500 clickable"
      [class.active]="period === 'am'"
      (click)="onPeriodChanged('am')"
    >
      AM
    </span>
    <ng-container [ngTemplateOutlet]="dividerTmpl"></ng-container>
    <span
      class="text-14 fw-500 clickable"
      [class.active]="period === 'pm'"
      (click)="onPeriodChanged('pm')"
    >
      PM
    </span>
  </div>
</div>

<ng-template #dividerTmpl>
  <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 17.5H0.5V0.5H1.5V17.5Z"
      fill="#E4E7EC"
      style="fill: #e4e7ec; fill: color(display-p3 0.8941 0.9059 0.9255); fill-opacity: 1"
    />
  </svg>
</ng-template>
