import { Component, inject, OnInit, signal } from '@angular/core'
import { TranslateService, TranslateStore } from '@ngx-translate/core'
import { LanguageCode } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-lang-switch-dialog',
  templateUrl: 'lang-switch-dialog.component.html',
  styleUrls: ['lang-switch-dialog.component.scss'],
  providers: [LanguageService, TranslateService, TranslateStore],
})
export class LangSwitchDialogComponent implements OnInit {
  protected readonly api = inject(ApiService)
  protected readonly localLanguageService = inject(LanguageService, { self: true })
  protected readonly parentLanguageService = inject(LanguageService, { skipSelf: true })

  protected readonly opened = signal(false)

  public async ngOnInit() {
    await this.localLanguageService.initialize()
    await this.resetSelectedLanguage()
  }

  public open() {
    this.opened.set(true)
  }

  public close() {
    this.opened.set(false)
  }

  protected async resetSelectedLanguage() {
    const currentLanguage = this.parentLanguageService.current()
    if (currentLanguage && currentLanguage !== this.localLanguageService.current()) {
      await this.localLanguageService.selectLanguage(currentLanguage)
    }
  }

  protected async saveSelectedLanguage() {
    const current = this.parentLanguageService.current()
    const selectedLanguage = this.localLanguageService.current()
    if (selectedLanguage && selectedLanguage !== current) {
      await this.parentLanguageService.selectLanguage(selectedLanguage)
    }
    this.close()
  }

  protected readonly LanguageCode = LanguageCode
}
