<div class="radio-group">
  <header>
    <h3 class="text-14 fw-600">{{ caption }}</h3>
    <p class="text-13 fw-400">{{ description }}</p>
  </header>

  <ng-container *ngFor="let option of options">
    <div
      class="radio-option"
      [class.selected]="selectedValue === option.value"
      (click)="selectOption(option)"
    >
      <p-radioButton
        [name]="'radio-group'"
        [value]="option.value"
        [(ngModel)]="selectedValue"
        (onClick)="selectOption(option)"
        class="radiobutton-secondary h--20 text text-14 fw-400 mr-8"
      ></p-radioButton>
      <div class="content">
        <ng-container *ngIf="option.template">
          <ng-container *ngTemplateOutlet="option.template"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
