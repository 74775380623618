import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-csv-file',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.625 3.5C3.625 2.46447 4.46447 1.625 5.5 1.625H15.5C16.5355 1.625 17.375 2.46447 17.375 3.5V5.16667V16.8333C17.375 17.8689 16.5355 18.7083 15.5 18.7083H5.5C4.46447 18.7083 3.625 17.8689 3.625 16.8333V3.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.25"
      />
      <rect
        x="2"
        y="6"
        width="17"
        height="9"
        rx="0.833333"
        fill="#1E88E5"
        style="fill:#1E88E5;fill:color(display-p3 0.1176 0.5333 0.8980);fill-opacity:1;"
      />
      <path
        d="M14.1111 8.11426L15.2206 11.2463L15.3681 11.8081L15.5085 11.2463L16.6181 8.11426H17.7557L16.0001 12.8895H14.6729L12.9172 8.11426H14.1111Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M10.0412 9.34129C10.0412 10.3596 12.8502 9.49579 12.8502 11.448C12.8502 12.4312 12.0356 13 10.919 13C9.78838 13 8.9808 12.4803 8.81226 11.3919H9.99905C10.0903 11.8624 10.4204 12.1222 10.926 12.1222C11.4316 12.1222 11.6985 11.9185 11.6985 11.5885C11.6985 10.5211 8.88248 11.3357 8.88248 9.44663C8.88248 8.64607 9.54962 8 10.7224 8C11.7125 8 12.5693 8.47051 12.7168 9.57303H11.5159C11.4176 9.10253 11.1437 8.87781 10.6802 8.87781C10.287 8.87781 10.0412 9.06039 10.0412 9.34129Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
      <path
        d="M8.41714 9.64326H7.23736C7.10393 9.17275 6.76685 8.91994 6.28933 8.91994C5.56601 8.91994 5.15169 9.52388 5.15169 10.507C5.15169 11.4972 5.55899 12.0801 6.28933 12.0801C6.73876 12.0801 7.08989 11.8413 7.22332 11.3919H8.41714C8.17135 12.4452 7.32163 13 6.30337 13C4.87781 13 4 12.052 4 10.507C4 8.96208 4.87781 8 6.31742 8C7.3427 8 8.21348 8.55477 8.41714 9.64326Z"
        fill="white"
        style="fill:white;fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CSVFileIconComponent extends BaseIconComponent {}
