import { inject, Injectable } from '@angular/core'
import { BaseConfig, CONFIG, injectDestroy$ } from '@ti-platform/web/common'
import { LOGIN_AS_SESSION } from '../constants'
import { ApiService } from '@ti-platform/web/api'
import { AuthSession, Profile } from '@ti-platform/web/auth'
import {
  getMessaging,
  getToken,
  MessagePayload,
  NextFn,
  Observer,
  onMessage,
} from 'firebase/messaging'
import { MessageService } from 'primeng/api'
import { Observable, ReplaySubject, takeUntil } from 'rxjs'
import { SwPush } from '@angular/service-worker'

@Injectable({
  providedIn: 'root',
})
export class PushClient {
  public readonly isRegistered$: Observable<boolean>
  protected readonly api = inject(ApiService)
  protected readonly authSession = inject(AuthSession)
  protected readonly config: BaseConfig = inject(CONFIG)
  protected readonly profile = inject(Profile)
  protected readonly loginAsSession = inject(LOGIN_AS_SESSION, { optional: true })
  protected readonly messageService = inject(MessageService)
  protected readonly isRegisteredSubject = new ReplaySubject<boolean>(1)
  protected readonly pushes = inject(SwPush)
  protected readonly destroy$ = injectDestroy$()

  constructor() {
    this.isRegistered$ = this.isRegisteredSubject.asObservable()
  }

  isRegistered() {
    if (this.loginAsSession) {
      // Pushes should be disabled for simulated logins due to user ids
      this.isRegisteredSubject.next(false)
      return false
    }

    const isRegistered = 'Notification' in window && Notification.permission === 'granted'
    this.isRegisteredSubject.next(isRegistered)

    return isRegistered
  }

  async register() {
    if (this.loginAsSession) {
      // Pushes should be disabled for simulated logins due to user ids
      return false
    }

    if (!('Notification' in window)) {
      console.error('Notification API is not defined in this browser')
      this.isRegisteredSubject.next(false)
      return false
    }

    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      this.isRegisteredSubject.next(true)
      if (!this.isPWA()) {
        // reload page in desktop browsers
        window.location.reload()
      }

      return this.subscribeToPushes()
    }

    this.isRegisteredSubject.next(false)
    return false
  }

  async subscribeToPushes() {
    if (this.loginAsSession || !this.isRegistered()) {
      // Pushes should be disabled for simulated logins due to user ids and not auto-prompted
      this.isRegisteredSubject.next(false)
      return false
    }

    if (await this.enableDevice()) {
      this.isRegisteredSubject.next(true)
      this.pushes.messages
        .pipe(takeUntil(this.destroy$))
        .subscribe((message: Record<string, any>) => {
          console.log('Received foregorund message', message)
          const summary = message?.data?.title ?? null
          const detail = message?.data?.body ?? null
          if (summary && detail) {
            this.messageService.add({
              summary,
              detail,
              // key: 'sw-push',
              closable: true,
              life: 5000,
            })
          }
        })
      return true
    }
    this.isRegisteredSubject.next(false)
    return false
  }

  async getDeviceToken() {
    console.log('Notification permission granted. Requesting for token.')
    const isReady = await navigator.serviceWorker.ready
    const worker = await this.getActiveWorker()

    if (!isReady || !worker) {
      console.error('Failed to get push token. Service worker is not defined.')
      return ''
    }
    const messaging = getMessaging()

    const token = await getToken(messaging, {
      vapidKey: this.config.fcmVapidKey,
      serviceWorkerRegistration: worker,
    })

    console.log('Device token', token)

    return token
  }

  async onForegroundMessage(callback: NextFn<MessagePayload> | Observer<MessagePayload>) {
    if (await this.getActiveWorker()) {
      const messaging = getMessaging()
      onMessage(messaging, callback)
    } else {
      console.error('Failed to subscribe for foreground message. Service worker is not defined.')
    }
  }

  async unassignDevice() {
    const token = await this.getDeviceToken()
    if (token.length < 1) {
      return
    }

    try {
      await this.api.fleet.user.unassignDevice(token)
    } catch (e) {}
  }

  public isPWA(): boolean {
    const mqStandAlone = '(display-mode: standalone)'
    // @ts-expect-error iOS property to check if PWA is loaded
    return window.navigator?.standalone || window.matchMedia(mqStandAlone).matches
  }

  protected async getActiveWorker(): Promise<ServiceWorkerRegistration | undefined> {
    const swFile = this.config.envType === 'local' ? 'service-worker.light.js' : 'service-worker.js'
    return navigator.serviceWorker.getRegistration(swFile)
  }

  protected async enableDevice() {
    const token = await this.getDeviceToken()
    if (token.length < 1) {
      return false
    }

    try {
      await this.api.fleet.user.ensureDeviceRegistration(token, this.isPWA() ? 'pwa' : 'browser')

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
