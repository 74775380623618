import { lineString } from '@turf/helpers'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import { bearing, point } from '@turf/turf'
import { toLngLat } from '@ti-platform/web/map'
import { Coordinates } from '../contracts'
import { arePointsClose } from './are-points-close'

// Returns direction angle close to the specified point
export const getPolylineDirection = (
  line: Coordinates[],
  atPoint: Coordinates,
): number | undefined => {
  if (!line?.length || !atPoint) {
    return undefined
  }

  // Find the nearest route point to primary marker position
  const nearestPoint = nearestPointOnLine(lineString(line), atPoint)
  let routeSegment: Coordinates[] | undefined
  for (let i = 1; i < line.length - 1; i++) {
    if (arePointsClose(line[i], nearestPoint.geometry.coordinates as Coordinates)) {
      routeSegment = [line[i - 1], line[i]]
      break
    }
  }

  if (routeSegment) {
    return bearing(point(toLngLat(routeSegment[0])), point(toLngLat(routeSegment[1])))
  }
}
