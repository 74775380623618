import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-temperature',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V14"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4142 14.5858C14.1953 15.3668 14.1953 16.6332 13.4142 17.4142C12.6332 18.1953 11.3668 18.1953 10.5858 17.4142C9.80474 16.6332 9.80474 15.3668 10.5858 14.5858C11.3668 13.8047 12.6332 13.8047 13.4142 14.5858"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 12.023V6C9 4.343 10.343 3 12 3C13.657 3 15 4.343 15 6V12.023C16.208 12.936 17 14.37 17 16C17 18.761 14.761 21 12 21C9.239 21 7 18.761 7 16C7 14.37 7.792 12.935 9 12.023Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TemperatureIconComponent extends BaseIconComponent {}
