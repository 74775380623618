import { inject, Injectable } from '@angular/core'
import { COUNTRY_CODES } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { sortBy } from 'lodash'
import { SelectItem } from 'primeng/api'
import { BehaviorSubject, map, Observable, takeUntil } from 'rxjs'

@Injectable()
export class CountyOptionsProvider {
  protected readonly api = inject(ApiService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly languageService = inject(LanguageService)
  readonly allCountryOptions$ = new BehaviorSubject<SelectItem[]>([])

  @Memoize()
  get countryCodeLabels$(): Observable<Record<string, string>> {
    const labelsMap = COUNTRY_CODES.reduce((carry, code) => {
      carry[code] = `countries.${code}`
      return carry
    }, {})
    return this.languageService.massTranslate$(labelsMap)
  }

  get countryOptions$(): Observable<SelectItem[]> {
    return this.countryCodeLabels$.pipe(
      takeUntil(this.destroy$),
      map((labels) => {
        const data = COUNTRY_CODES.map((code) => ({ label: labels[code], value: code }))
        const result = sortBy(data, (option) => option.label)
        this.allCountryOptions$.next(result)
        return result
      }),
    )
  }
}
