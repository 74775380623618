export type DateRange = [Date, Date]
export type RangeMs = [number, number]

export enum PreDefinedRange {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7days',
  Last30Days = 'last30days',
}

export enum ExportFormat {
  Excel,
  CSV,
  PDF,
}

export interface ReportColumn {
  field: string
  labelTranslation: string
}
