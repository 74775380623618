import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-compass',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 18C14.4 18 18 14.4 18 10C18 5.6 14.4 2 10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.77823 9.64029L9.67759 6.34108C9.82105 6.09213 10.1797 6.09213 10.3237 6.34056L12.2199 9.6155C12.3533 9.846 12.3539 10.1303 12.221 10.3613L10.3216 13.6605C10.1781 13.9095 9.81947 13.9095 9.67548 13.661L7.77929 10.3861C7.64584 10.1556 7.64532 9.87131 7.77823 9.64029Z"
        fill="#7F56D9"
        [attr.stroke]="color"
        style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99884 9.79134C9.88384 9.79134 9.79051 9.88467 9.79134 9.99967C9.79134 10.1147 9.88468 10.208 9.99968 10.208C10.1147 10.208 10.208 10.1147 10.208 9.99967C10.208 9.88467 10.1147 9.79134 9.99884 9.79134"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99967 2.5V3.75"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99967 17.5V16.25"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 10.0007H16.25"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 10.0007H3.75"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CompassIconComponent extends BaseIconComponent {}
