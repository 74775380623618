import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-no-search',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3335 7.66699L27.3335 3.66699"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3335 7.66699L23.3335 3.66699"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 28L16.8763 24.3763"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 19.25C4.5 23.2541 7.74594 26.5 11.75 26.5C15.7541 26.5 19 23.2541 19 19.25C19 15.2459 15.7541 12 11.75 12V12C7.74606 12.0003 4.50029 15.2461 4.5 19.25"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NoSearchIconComponent extends BaseIconComponent {
  @Input() override height = 32
  @Input() override width = 32
}
