import { MemoryStorage } from '../models/storage/memory.storage'

/**
 * Contains a MemoryStorage per each class instance
 *
 * WeakMap should be used to avoid memory leaks
 */
const globalStoragesWeakMap = new WeakMap<NonNullable<unknown>, MemoryStorage>()

const memoizeDestroyHookInitializedKey = '__memoizeDestroyHookInitialized'

/**
 * @warning Avoid of usage with non "clear" functions
 *
 * @description Memoize decorator allows to prevent multiple
 *       method calls by returning the first value each time
 */
export function Memoize(): MethodDecorator {
  /**
   * Returns a unique string for given arguments
   */
  function serializeArguments(args: any[]): string {
    return args
      .map((item: any): string => {
        switch (typeof item) {
          case 'object':
            return JSON.stringify(item)
          case 'function':
            return item.toString()
          default:
            return item
        }
      }, [])
      .join('')
  }

  function pluginOnDestroyHook(target: NonNullable<unknown>) {
    const originalDestructor = (
      'ngOnDestroy' in target ? target['ngOnDestroy'] : undefined
    ) as () => unknown | undefined
    const newDestructorDescriptor = {
      value: function (...args: unknown[]) {
        originalDestructor ? originalDestructor.apply(this, args as []) : null

        if (globalStoragesWeakMap.has(this)) {
          /**
           * Processing cache cleanup before the original object being destroyed
           */
          globalStoragesWeakMap.delete(this)
        }

        return typeof originalDestructor === 'function'
          ? originalDestructor.apply(this, args as [])
          : null
      },
      configurable: true,
      writeable: true,
    }

    // Delete old destructor and injecting wrapped one
    delete target.constructor.prototype['ngOnDestroy']
    Object.defineProperty(target.constructor.prototype, 'ngOnDestroy', newDestructorDescriptor)
  }

  /**
   * Returns a property decorator
   */
  return <T extends NonNullable<unknown> & { [key: string]: any }>(
    target: T,
    key: string | symbol,
    descriptor: TypedPropertyDescriptor<any>,
  ) => {
    /**
     * Defines what kind of descriptor is used
     */
    const isGetterMode = typeof descriptor.get === 'function'
    const originalMethod = descriptor[isGetterMode ? 'get' : 'value'] as () => any

    /**
     * It seems that TypeScript defines descriptor.value property in the wrong way.
     * It should match () => T type, but currently used T
     */
    descriptor[isGetterMode ? 'get' : 'value'] = function (...args: unknown[]) {
      let storage = globalStoragesWeakMap.get(this)
      if (!storage) {
        storage = new MemoryStorage()
        globalStoragesWeakMap.set(this, storage)
      }

      const storageKey = `${String(key)}_${serializeArguments(args)}`
      if (storage.length === 0 || !storage.getItem(storageKey)) {
        storage.setItem(storageKey, originalMethod.apply(this, args as []))
      }

      return storage.getItem(storageKey)
    }

    if (!target[memoizeDestroyHookInitializedKey]) {
      pluginOnDestroyHook(target)
    }

    ;(target as any)[memoizeDestroyHookInitializedKey] = true
  }
}
