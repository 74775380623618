import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-no-devices',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 23.0036L20.1527 12.8345C20.0657 11.7972 19.1995 11 18.1592 11H5.83548C4.79517 11 3.92891 11.7972 3.84188 12.8345L2.99463 23.0036"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 23.0035C21 21.8982 20.1047 21.0029 18.9994 21.0029H4.99523C3.8899 21.0029 2.99463 21.8982 2.99463 23.0035V26.0044C2.99463 27.1097 3.8899 28.005 4.99523 28.005H18.9994C20.1047 28.005 21 27.1097 21 26.0044V23.0035Z"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4988 24.5041H7.49609"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3335 7.66675L27.3335 3.66675"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.3335 7.66675L23.3335 3.66675"
        stroke="#101828"
        style="stroke:#101828;stroke:color(display-p3 0.0627 0.0941 0.1569);stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NoDevicesIconComponent extends BaseIconComponent {
  @Input() override height = 32
  @Input() override width = 32
}
