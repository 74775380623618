import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-line-dashed',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 2 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="4.37115e-08"
        x2="0.999999"
        y2="20"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-dasharray="6 6"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LineDashedIconComponent extends BaseIconComponent {
  @Input() override width = 2
  @Input() override height = 20
}
