import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-truck-big',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.4979 5.99756H17.5025C18.6079 5.99756 19.5033 6.89293 19.5033 7.99839V8.99881C19.5033 10.1043 18.6079 10.9996 17.5025 10.9996H6.4979C5.39244 10.9996 4.49707 10.1043 4.49707 8.99881V7.99839C4.49707 6.89293 5.39244 5.99756 6.4979 5.99756Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.49707 17.0021C4.49707 18.1076 5.39244 19.0029 6.4979 19.0029H17.5025C18.6079 19.0029 19.5033 18.1076 19.5033 17.0021V3.99668C19.5033 2.89122 18.6079 1.99585 17.5025 1.99585H6.4979C5.39244 1.99585 4.49707 2.89122 4.49707 3.99668V17.0021Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8862 15.0012H16.0019"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.9984 15.0012H6.7959"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5008 16.3288H10.4995"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5008 14.0778H10.4995"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2673 17.9365V20.5036C19.2673 21.0558 18.8191 21.504 18.2669 21.504H16.266C15.7138 21.504 15.2656 21.0558 15.2656 20.5036V19.003"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.73506 19.003V20.5036C8.73506 21.0558 8.28688 21.504 7.73465 21.504H5.73382C5.18159 21.504 4.7334 21.0558 4.7334 20.5036V17.9365"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.49714 6.99805H2.99651C2.44428 6.99805 1.99609 7.44623 1.99609 7.99846V11.0617"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5034 6.99805H21.004C21.5563 6.99805 22.0045 7.44623 22.0045 7.99846V11.0617"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TruckBigIconComponent extends BaseIconComponent {}
