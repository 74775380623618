import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { takeUntil } from 'rxjs'
import {
  checkExistApiError,
  getMessageFromException,
  getApiErrorKey,
  injectDestroy$,
  Memoize,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ApiErrors } from '@ti-platform/contracts'

export type NewPasswordType = 'create' | 'change' | 'reset'

export abstract class NewPasswordViewModel {
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  public readonly isLoading = signal(false)
  public readonly apiErrorMessage = signal('')
  public readonly newPasswordSaved = signal(false)

  public readonly mode = signal<NewPasswordType>('reset')
  public readonly secret = signal<string | undefined>(undefined)

  protected readonly passwordNumericRE = /\d+/
  protected readonly passwordUppercaseLetterRE = /[A-Z]+/

  @Memoize()
  public get form() {
    const form = new FormGroup<{
      current?: FormControl<string | null>
      password: FormControl<string | null>
      repeatPassword: FormControl<string | null>
    }>({
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(8),
        (control) => {
          const value = control.value ?? ''
          return value.match(this.passwordNumericRE)
            ? null
            : { 'password-must-contain-numeric': true }
        },
        (control) => {
          const value = control.value ?? ''
          return value.match(this.passwordUppercaseLetterRE)
            ? null
            : { 'password-must-contain-uppercase': true }
        },
      ]),
      repeatPassword: new FormControl<string>('', [Validators.required]),
    })

    form.controls.password.addValidators([
      (control) => {
        return control.value === this.form.controls.repeatPassword.value
          ? null
          : { 'passwords-are-not-equal': true }
      },
    ])
    form.controls.repeatPassword.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      form.controls.password.updateValueAndValidity()
    })

    if (this.mode() === 'change') {
      form.addControl('current', new FormControl<string>('', [Validators.required]))

      form.controls.password.addValidators([
        (control) => {
          if (!control.value || !this.form.controls.current) {
            return null
          }
          return control.value !== this.form.controls.current.value
            ? null
            : { 'new-password-equal-previous': true }
        },
      ])

      form.controls.current?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        form.controls.password.updateValueAndValidity()
      })
    }

    return form
  }

  public initialize(mode: NewPasswordType, secret?: string) {
    this.mode.set(mode)
    this.secret.set(secret)
  }

  public async submitForm() {
    if (this.isLoading()) {
      return
    }

    if (!this.form.valid) {
      return this.form.markAllAsTouched()
    }

    const secret = this.secret()
    if (secret) {
      await this.savePasswordForAnonymous(this.form.controls.password.value as string, secret)
    } else {
      await this.savePasswordForUser(
        this.form.controls.current?.value as string,
        this.form.controls.password.value as string,
      )
    }
  }

  // Change password for authenticated user
  public async savePasswordForUser(currentPassword: string, newPassword: string) {
    try {
      this.isLoading.set(true)
      await this.changePassword(currentPassword, newPassword)
      this.newPasswordSaved.set(true)
    } catch (error) {
      console.warn(`Cannot change password`, error)
      const message = getMessageFromException(error)
      this.apiErrorMessage.set(
        await this.languageService.translate(
          checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown),
        ),
      )
    } finally {
      this.isLoading.set(false)
    }
  }

  // Used for invite and reset-password flow
  public async savePasswordForAnonymous(password: string, secret: string) {
    try {
      this.isLoading.set(true)
      await this.confirmPasswordReset(password, secret)
      this.newPasswordSaved.set(true)
    } catch (error) {
      console.warn(`Cannot save password`, error)
      const message = getMessageFromException(error)
      this.apiErrorMessage.set(
        await this.languageService.translate(
          checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown),
        ),
      )
    } finally {
      this.isLoading.set(false)
    }
  }

  protected abstract changePassword(currentPassword: string, newPassword: string): Promise<void>

  protected abstract confirmPasswordReset(password: string, secret: string): Promise<void>
}

@Component({
  selector: 'app-new-password',
  templateUrl: 'new-password.component.html',
  styleUrl: 'new-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPasswordComponent implements OnInit {
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected readonly router = inject(Router)
  protected readonly model = inject(NewPasswordViewModel)

  // TODO: Move translation keys away from tsp-admin scope
  protected labelsPrefix!: 'create-password' | 'change-password' | 'reset-password'

  public ngOnInit() {
    let mode: NewPasswordType
    if (this.router.url.includes('create-password')) {
      mode = 'create'
      this.labelsPrefix = 'create-password'
    } else if (this.router.url.includes('change-password')) {
      mode = 'change'
      this.labelsPrefix = 'change-password'
    } else if (this.router.url.includes('reset-password')) {
      mode = 'reset'
      this.labelsPrefix = 'reset-password'
    } else {
      // TODO: Implement error message for users
      console.error(`Cannot identify mode`)
      return this.router.navigate(['/'])
    }

    const secret = this.activatedRoute.snapshot.paramMap.get('secret') || undefined
    if (mode !== 'change' && !secret) {
      console.error(`Secret is missing`) // TODO: Add error message
      return this.router.navigate(['/'])
    }

    this.model.initialize(mode, secret)
  }

  protected complete() {
    if (this.model.mode() === 'change') {
      return this.router.navigate(['/'])
    } else {
      return this.router.navigate(['/', 'sign-in'])
    }
  }
}
