<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4 26C4 13.8497 13.8497 4 26 4C38.1503 4 48 13.8497 48 26C48 38.1503 38.1503 48 26 48C13.8497 48 4 38.1503 4 26Z"
    fill="#B692F6"
    style="fill: #b692f6; fill: color(display-p3 0.7137 0.5725 0.9647); fill-opacity: 1"
  />
  <path
    d="M26 2C12.7452 2 2 12.7452 2 26C2 39.2548 12.7452 50 26 50C39.2548 50 50 39.2548 50 26C50 12.7452 39.2548 2 26 2Z"
    stroke="#7F56D9"
    stroke-opacity="0.2"
    style="stroke: #7f56d9; stroke: color(display-p3 0.4964 0.3384 0.8498); stroke-opacity: 0.2"
    stroke-width="4"
  />
  <rect
    x="17"
    y="17"
    width="18"
    height="18"
    rx="2"
    fill="white"
    style="fill: white; fill-opacity: 1"
  />
</svg>
