import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-pencil',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.667 2.99993C14.8859 2.78106 15.1457 2.60744 15.4317 2.48899C15.7176 2.37054 16.0241 2.30957 16.3337 2.30957C16.6432 2.30957 16.9497 2.37054 17.2357 2.48899C17.5216 2.60744 17.7815 2.78106 18.0003 2.99993C18.2192 3.2188 18.3928 3.47863 18.5113 3.7646C18.6297 4.05057 18.6907 4.35706 18.6907 4.66659C18.6907 4.97612 18.6297 5.28262 18.5113 5.56859C18.3928 5.85455 18.2192 6.11439 18.0003 6.33326L6.75033 17.5833L2.16699 18.8333L3.41699 14.2499L14.667 2.99993Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PencilIconComponent extends BaseIconComponent {}
