import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-truck',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0017 18.5029H8.16846"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9991 18.5028H21.004C21.5565 18.5028 22.0044 18.0549 22.0044 17.5023V11.3852C22.0044 11.1308 21.9558 10.8787 21.8613 10.6425L20.506 7.25514C20.2022 6.49559 19.4665 5.99756 18.6484 5.99756H15.0015"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0041 13.0004H18.5026C18.2264 13.0004 18.0024 12.7765 18.0024 12.5002V8.99878H21.2038"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5593 17.4459C18.9885 17.875 19.1169 18.5204 18.8847 19.0812C18.6524 19.642 18.1053 20.0076 17.4983 20.0076C16.8914 20.0077 16.3442 19.6421 16.1119 19.0813C15.8796 18.5206 16.0079 17.8752 16.4371 17.446L16.4372 17.4459C16.7186 17.1644 17.1003 17.0063 17.4982 17.0063C17.8962 17.0063 18.2779 17.1644 18.5593 17.4459V17.4459"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.55488 17.4457C7.98408 17.8749 8.1125 18.5203 7.88026 19.0811C7.64802 19.6418 7.10086 20.0075 6.49391 20.0075C5.88696 20.0075 5.33976 19.6419 5.10747 19.0812C4.87517 18.5205 5.00352 17.875 5.43268 17.4458L5.43298 17.4455C5.71437 17.1641 6.09602 17.0061 6.49396 17.0061C6.8919 17.0061 7.27352 17.1643 7.55486 17.4457V17.4457"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.99734 18.5029H2.99651C2.444 18.5029 1.99609 18.055 1.99609 17.5025V4.99724C1.99609 4.44473 2.444 3.99683 2.99651 3.99683H14.0011C14.5536 3.99683 15.0015 4.44473 15.0015 4.99724V14.001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0015 14.0009H1.99609"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TruckIconComponent extends BaseIconComponent {}
