import { inject, Injectable } from '@angular/core'
import {
  Action,
  ApiErrors,
  CommandFormData,
  DataQueryProps,
  MyProfile,
  Resource,
  TspCommandListData,
} from '@ti-platform/contracts'
import {
  AccessControl,
  checkExistApiError,
  FilterOptions,
  getApiErrorKey,
  getMessageFromException,
  ListModel,
  ListModelConfig,
} from '@ti-platform/web/common'
import { DeviceAddIconComponent, DeviceRemoveIconComponent } from '@ti-platform/web/ui-kit/icons'
import {
  DataGridColumnActionsFn,
  DataGridColumnType,
} from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService, SelectItem } from 'primeng/api'
import { BehaviorSubject, map, shareReplay, takeUntil } from 'rxjs'
import { DevicesTypesDataProvider } from './devices-types-data.provider'

export enum CommandSelectedPurpose {
  Edit = 'edit',
  AssignDevices = 'assign-devices',
  UnassignDevices = 'unassign-devices',
}

@Injectable()
export class CommandsListModel extends ListModel<TspCommandListData> {
  readonly commandFieldsLabels$ = new BehaviorSubject<Record<string, string>>({})
  protected readonly accessControl = inject(AccessControl)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly messageService = inject(MessageService)
  protected readonly deviceTypeOptions$ = new BehaviorSubject<SelectItem[]>([])
  protected readonly isCreatingCommand$ = new BehaviorSubject<boolean>(false)
  protected readonly creationErrorMessage$ = new BehaviorSubject<string>('')
  protected readonly isEditingCommand$ = new BehaviorSubject<boolean>(false)
  protected readonly editingErrorMessage$ = new BehaviorSubject<string>('')
  protected readonly selectedPurpose$ = new BehaviorSubject<CommandSelectedPurpose | null>(null)

  public constructor() {
    super()
    this.devicesTypesDataProvider.devicesTypesDataWithCommands$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value?.length) {
          const result = value.map((item) => {
            return {
              label: item.title,
              value: item.id,
            }
          })
          this.deviceTypeOptions$.next(result)
        } else {
          this.deviceTypeOptions$.next([])
        }
        this.updateFilterOptions()
      })
    this.getCommandFieldsLabels()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.commandFieldsLabels$.next(value)
      })
  }

  public get isFiltered() {
    const filter = this.store.filter$.getValue()
    return !!filter?.deviceTypes?.length
  }

  public get isCreatingCommand() {
    return this.isCreatingCommand$.getValue()
  }

  public get creationErrorMessage() {
    return this.creationErrorMessage$.getValue()
  }

  public get isEditingCommand() {
    return this.isEditingCommand$.getValue()
  }

  public get editingErrorMessage() {
    return this.editingErrorMessage$.getValue()
  }

  public get selectedPurpose() {
    return this.selectedPurpose$.getValue()
  }

  public get selectedItem() {
    return this.store.selectedItem$.value
  }

  public async createCommand(data: CommandFormData): Promise<boolean> {
    let result = false
    this.isCreatingCommand$.next(true)
    this.creationErrorMessage$.next('')
    try {
      await this.api.tsp.commands.createCommand(data)
      result = true
      const message = await this.languageService.translate('tsp-admin.devices.commands.added')
      this.messageService.add({
        severity: 'success',
        detail: message,
        life: 5000,
      })
    } catch (e) {
      const error = this.processErrorOnException(e)
      this.creationErrorMessage$.next(error)
    }

    this.isCreatingCommand$.next(false)
    return result
  }

  public async deleteCommand(commandId: string) {
    await this.api.tsp.commands.deleteCommand(commandId)
  }

  public async editCommand(data: CommandFormData) {
    const selectedCommand = this.store.selectedItem$.getValue()
    if (!selectedCommand) {
      return
    }
    const externalDeviceTypeId = data.externalDeviceTypeId
    const commandDescription =
      this.devicesTypesDataProvider.commandDataByDeviceTypeId[externalDeviceTypeId]
    const commandId = selectedCommand.id
    let needUpdate = false
    if (data.name !== selectedCommand.name) {
      needUpdate = true
    }
    const commandPropertiesList = Object.keys(commandDescription.schema.properties)
    for (const propertyName of commandPropertiesList) {
      if (data.config[propertyName] !== selectedCommand.config?.[propertyName]) {
        needUpdate = true
        break
      }
    }

    let result = false

    if (needUpdate) {
      const commandData = {
        name: data.name,
        externalName: data.externalName,
        config: data.config,
      }

      let dialogResult = true
      if (selectedCommand.numberOfDevices) {
        const labels = await this.getEditDialogLabels(selectedCommand.name as string)
        dialogResult = await this.dialogFacade.confirm({
          ...labels,
        })
      }
      if (dialogResult) {
        this.isEditingCommand$.next(true)
        this.editingErrorMessage$.next('')
        try {
          await this.api.tsp.commands.editCommand(commandId, commandData)
          result = true
          const message = await this.languageService.translate('tsp-admin.devices.commands.edited')
          this.messageService.add({
            severity: 'success',
            detail: message,
            life: 5000,
          })
          this.unselect()
        } catch (e) {
          const error = this.processErrorOnException(e)
          this.editingErrorMessage$.next(error)
        }
      }
    } else {
      result = true
    }

    this.isEditingCommand$.next(false)
    return result
  }

  public override unselect(): void {
    super.unselect()
    this.selectedPurpose$.next(null)
  }

  public cancelEdit() {
    if (!this.isEditingCommand$.getValue()) {
      this.editingErrorMessage$.next('')
      this.unselect()
    }
  }

  public cancelCreating() {
    if (!this.isCreatingCommand$.getValue()) {
      this.creationErrorMessage$.next('')
    }
  }

  public async getAndShowMessageOnException(e: any) {
    const message = getMessageFromException(e)
    if (message) {
      console.error(message)
    }
    await this.showUnknownErrorMessage()
  }

  public getFilterValue() {
    return this.store.filter$.getValue()
  }

  public getOrderByValue() {
    return this.store.orderBy$.getValue()
  }

  public async showUnknownErrorMessage() {
    const message = await this.languageService.translate(getApiErrorKey(ApiErrors.unknown))
    this.messageService.add({
      severity: 'error',
      detail: message,
      life: 2000,
    })
  }

  public async getByDeviceTypeId(deviceTypeId: number) {
    return this.api.tsp.commands.byDeviceTypeId(deviceTypeId)
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    console.error(message)
    return checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown)
  }

  protected async getEditDialogLabels(commandName: string) {
    return this.languageService.massTranslate(
      {
        summary: 'tsp-admin.devices.commands.edit-dialog.summary',
        description: 'tsp-admin.devices.commands.edit-dialog.description',
        confirmButton: 'tsp-admin.devices.commands.edit-dialog.save',
        cancelButton: 'button.cancel',
      },
      { name: commandName },
    )
  }

  protected applyRequestFilters(queryProps: DataQueryProps, filters: Record<string, any> | null) {
    if (filters?.deviceTypes?.length) {
      queryProps.filter = {
        externalDeviceTypeId: {
          in: filters.deviceTypes,
        },
      }
    } else if (queryProps.filter) {
      delete queryProps.filter
    }
  }

  protected config(): ListModelConfig {
    return {
      name: 'TspCommandsListModel',
      defaultOrderColumn: 'name',
      defaultOrderDirection: 'ASC',
      loadCount: () => this.loadCount(),
      loadFilterOptions: () => this.getFilterOptions(),
      applyRequestFilters: this.applyRequestFilters,
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.devices.commands.grid.name',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'deviceModel',
          label: 'tsp-admin.devices.commands.grid.model',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'config',
          label: 'tsp-admin.devices.commands.grid.config',
          type: DataGridColumnType.Template,
          sortable: false,
        },
        {
          field: 'numberOfDevices',
          label: 'tsp-admin.devices.commands.grid.number-of-devices',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'actions',
          label: '',
          type: DataGridColumnType.Actions,
          actions: ((target: TspCommandListData, user: MyProfile) => {
            return this.accessControl
              .massCheck$([
                [Resource.Device, Action.Update],
                [Resource.Device, Action.Delete],
              ])
              .pipe(
                map(([canUpdate, canDelete]) => {
                  return [
                    {
                      label: 'tsp-admin.devices.commands.assign-devices',
                      iconComponent: DeviceAddIconComponent,
                      iconComponentInputs: { width: 20, height: 20 },
                      styleClass: `${canUpdate ? '' : 'not-allowed'}`,
                      command: () => {
                        this.select(target)
                        this.selectedPurpose$.next(CommandSelectedPurpose.AssignDevices)
                      },
                    },
                    {
                      label: 'tsp-admin.devices.commands.edit-command',
                      icon: 'pi pi-pencil',
                      styleClass: `has-bottom-border ${canUpdate ? '' : 'not-allowed'}`,
                      command: () => {
                        this.select(target)
                        this.selectedPurpose$.next(CommandSelectedPurpose.Edit)
                      },
                    },
                    {
                      label: 'tsp-admin.devices.commands.unassign-devices',
                      iconComponent: DeviceRemoveIconComponent,
                      iconComponentInputs: { width: 20, height: 20 },
                      disabled: !target.numberOfDevices,
                      styleClass: `${canUpdate ? '' : 'not-allowed'}`,
                      command: () => {
                        this.select(target)
                        this.selectedPurpose$.next(CommandSelectedPurpose.UnassignDevices)
                      },
                    },
                    {
                      label: 'tsp-admin.devices.commands.delete-command',
                      icon: 'pi pi-trash',
                      styleClass: `error ${canDelete ? '' : 'not-allowed'}`,
                      command: () => {
                        this.delete(target)
                      },
                    },
                  ]
                }),
                takeUntil(this.destroy$),
                shareReplay({ bufferSize: 1, refCount: false }),
              )
          }) as unknown as DataGridColumnActionsFn,
        },
      ],
    }
  }

  protected async delete(command: TspCommandListData) {
    const commandName = command.name
    const labels = await this.getDeleteDialogLabels(commandName)
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      try {
        await this.deleteCommand(command.id)
        const summary = await this.languageService.translate('tsp-admin.devices.commands.deleted')
        this.messageService.add({ summary, life: 5000, severity: 'success' })
        this.reset()
      } catch (error) {
        await this.getAndShowMessageOnException(error)
      }
    }
  }

  protected getCommandFieldsLabels() {
    return this.languageService.massTranslate$({
      command_id: 'tsp-admin.devices.commands.fields.command-id',
      hex: 'tsp-admin.devices.commands.fields.hex',
      payload: 'tsp-admin.devices.commands.fields.payload',
      message: 'tsp-admin.devices.commands.fields.message',
      command_type: 'tsp-admin.devices.commands.fields.command-type',
      parameters: 'tsp-admin.devices.commands.fields.parameters',
      text: 'tsp-admin.devices.commands.fields.text',
      command: 'tsp-admin.devices.commands.fields.command',
      wait_ack: 'tsp-admin.devices.commands.fields.wait-ack',
      command_name: 'tsp-admin.devices.commands.fields.command-name',
      instruction_type: 'tsp-admin.devices.commands.fields.instruction-type',
      crlf: 'tsp-admin.devices.commands.fields.crlf',
      message_type: 'tsp-admin.devices.commands.fields.message-type',
      append_id_ts: 'tsp-admin.devices.commands.fields.append-id-ts',
      cmd: 'tsp-admin.devices.commands.fields.cmd',
      password: 'tsp-admin.devices.commands.fields.password',
      argument: 'tsp-admin.devices.commands.fields.argument',
      code: 'tsp-admin.devices.commands.fields.code',
      wait_response: 'tsp-admin.devices.commands.fields.wait-response',
      report_body: 'tsp-admin.devices.commands.fields.report-body',
      report_id: 'tsp-admin.devices.commands.fields.report-id',
      cmd_body: 'tsp-admin.devices.commands.fields.cmd-body',
      cmd_type: 'tsp-admin.devices.commands.fields.cmd-type',
    })
  }

  protected async getDeleteDialogLabels(name: string) {
    return this.languageService.massTranslate(
      {
        summary: 'tsp-admin.devices.commands.delete-dialog.summary',
        description: 'tsp-admin.devices.commands.delete-dialog.description',
        confirmButton: 'common.yes-delete',
        cancelButton: 'button.cancel',
      },
      { name },
    )
  }

  protected getFilterOptions(): FilterOptions[] {
    return [
      {
        key: 'deviceTypes',
        title: 'tsp-admin.devices.commands.grid.model',
        placeholder: 'tsp-admin.devices.commands.select',
        options: this.devicesTypesDataProvider.deviceTypesDataOptions,
        searchable: true,
      },
    ]
  }

  protected async loadCount() {
    return this.api.tsp.commands.countAll()
  }

  protected loadPage(props: DataQueryProps): Promise<TspCommandListData[]> {
    return this.api.tsp.commands.list(props)
  }

  protected updateFilterOptions() {
    this.store.filterOptions$.next(this.getFilterOptions())
  }
}
