import { inject } from '@angular/core'
import { ApiService } from '@ti-platform/web/api'
import { BrowserSessionStorage } from '../models'
import { Memoize } from '../decorators'
import { BehaviorSubject, ReplaySubject } from 'rxjs'
import { differenceWith, intersectionWith } from 'lodash'

export abstract class BaseCachedStore<T extends { id: string; updatedAt?: Date }> {
  protected readonly api = inject(ApiService)
  protected readonly sessionStorage = inject(BrowserSessionStorage)

  protected readonly store = {
    data$: new ReplaySubject<T[]>(1),
    isFresh$: new BehaviorSubject<boolean>(false),
    isLoading$: new BehaviorSubject<boolean>(true),
  }
  protected readonly dataMap = new Map<string, T>()

  protected abstract readonly CACHE_KEY: string
  protected abstract loadAll(): Promise<T[]>
  protected abstract loadById(ids: string[]): Promise<T[]>
  protected abstract loadSnapshot(): Promise<{ id: string; updatedAt: Date }[]>

  public constructor() {
    // Execute initialize asynchronously
    Promise.resolve().then(() => this.init().catch((error) => console.error(error)))
  }

  @Memoize()
  public get state() {
    return {
      items$: this.store.data$.asObservable(),
      isFresh$: this.store.isFresh$.asObservable(),
      isLoading$: this.store.isLoading$.asObservable(),
    }
  }

  public async refreshAll() {
    const data = this.getData().map((item) => this.dataToSnapshot(item))

    // Update data by
    const snapshot = await this.loadSnapshot()

    const dataToUpdate = intersectionWith(data, snapshot, (a, b) => a.id === b.id)
    const dataToRequest = differenceWith(snapshot, dataToUpdate, (a, b) => a.id === b.id)
    const dataToDelete = differenceWith(data, dataToUpdate, (a, b) => a.id === b.id)

    // Remove data elements from the store
    if (dataToDelete.length) {
      dataToDelete.forEach((data) => this.dataMap.delete(data.id))
      this.addData([]) // Refresh the data store
    }

    const idsToReload = dataToRequest.map((data) => data.id)
    dataToUpdate.forEach((data) => {
      const snapshotData = snapshot.find((item) => item.id === data.id)
      if (snapshotData && snapshotData.updatedAt?.valueOf() !== data.updatedAt?.valueOf()) {
        idsToReload.push(data.id)
      }
    })

    await this.refreshById(idsToReload)

    if (!this.store.isFresh$.value) {
      this.store.isFresh$.next(true)
    }
  }

  public async refreshById(id: string | string[]) {
    const ids = Array.isArray(id) ? id : [id]
    if (ids.length) {
      this.addData(await this.loadById(ids))
    }
  }

  protected async init() {
    const cachedItems = this.getCachedData()
    if (cachedItems && cachedItems.length) {
      // Bootstrap with cached data
      this.addData(cachedItems)

      // Refresh the data in the background
      this.refreshAll().catch((error) => {
        console.error(`Cannot refresh LocationsStore`, error)
      })
    } else {
      this.addData(await this.loadAll())
      this.store.isFresh$.next(true)
    }
    this.store.isLoading$.next(false)
  }

  protected getData() {
    return Array.from(this.dataMap.values())
  }

  protected addData(data: T[]) {
    data.forEach((item) => this.dataMap.set(item.id, item))
    this.store.data$.next(this.getData())
    this.setCachedData(this.getData())
  }

  protected getCachedData(): T[] {
    const cached = this.sessionStorage.getItem(this.CACHE_KEY)
    if (cached) {
      return JSON.parse(cached) as T[]
    }
    return []
  }

  protected setCachedData(data: T[]) {
    this.sessionStorage.setItem(this.CACHE_KEY, JSON.stringify(data))
  }

  protected dataToSnapshot(data: T): { id: string; updatedAt?: Date } {
    return data
  }
}
