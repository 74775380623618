<div class="text-wrapper" (click)="$event.stopPropagation()">
  <div class="content-text flex">
    <span class="text">
      {{ text }}
    </span>
    <app-copy-button
      class="icon"
      [tooltip]="tooltip"
      [copiedTooltip]="copiedTooltip"
      [tooltipPosition]="'top'"
      [text]="text"
    >
    </app-copy-button>
  </div>
</div>
