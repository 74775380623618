import { ChangeDetectorRef, effect, inject, Pipe, PipeTransform } from '@angular/core'
import { formatCommaSeparatedNumber } from '@ti-platform/web/common/utils/comma-separated-number'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n/services/language.service'

@Pipe({ name: 'formatCommaSeparated', pure: false })
export class FormatCommaSeparatedPipe implements PipeTransform {
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly languageService = inject(LanguageService)

  protected lastValue?: number
  protected lastLocale?: string
  protected lastResult?: string

  public constructor() {
    // Subscribe to the language changes
    effect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const language = this.languageService.current()
      this.changeDetector.markForCheck()
    })
  }

  public transform(value: string | number | undefined, precision = 0): string {
    if (typeof value === 'undefined') {
      return ''
    }

    const locale = this.languageService.current() ?? 'en'
    // Update the value only when inputs changed
    if (this.lastLocale !== locale || this.lastValue !== value) {
      this.updateValue(value, locale, precision)
    }

    return this.lastResult ?? ''
  }

  protected updateValue(value: string | number, locale: string, precision: number) {
    this.lastResult = formatCommaSeparatedNumber(
      (typeof value === 'string' ? parseFloat(value) : value).toFixed(precision),
      locale === 'en' ? ',' : '.',
    )
  }
}
