import { Component, inject, OnInit } from '@angular/core'
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router'
import { BehaviorSubject, filter } from 'rxjs'

@Component({
  selector: 'app-tsp-admin-root',
  templateUrl: 'tsp-admin-app.component.html',
  styleUrls: ['tsp-admin-add.component.scss'],
})
export class TspAdminAppComponent implements OnInit {
  protected readonly router = inject(Router)
  protected readonly loading$ = new BehaviorSubject<boolean>(true)

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof RouterEvent)).subscribe((e: any) => {
      this.navigationInterceptor(e)
    })
  }

  // Shows and hides the loading animation during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading$.next(true)
    }
    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.loading$.next(false)
    }
  }
}
