import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  DateRange,
  getDateRangeFromPredefined,
  injectDestroy$,
  Memoize,
  PreDefinedRange,
} from '@ti-platform/web/common'
import { DeviceService, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem } from 'primeng/api'
import { map, Observable, Subject, takeUntil } from 'rxjs'

type MonthRange = {
  month: number
  year: number
}

@Component({
  selector: 'app-month-range-select',
  templateUrl: 'month-range-select.component.html',
  styleUrls: ['month-range-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthRangeSelectComponent),
      multi: true,
    },
  ],
})
export class MonthRangeSelectComponent implements ControlValueAccessor, OnInit {
  protected readonly updateHighlight$ = new Subject<void>()
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)
  protected readonly destroy$ = injectDestroy$()
  protected readonly languageService = inject(LanguageService)
  protected readonly deviceService = inject(DeviceService)

  protected onChange: (value: MonthRange) => void = () => undefined
  protected onTouched: () => void = () => undefined

  protected lastMonthValue: MonthRange | undefined = undefined

  @Output() readonly valueChange = new EventEmitter<DateRange | PreDefinedRange>()
  @Output() readonly dateSelected = new EventEmitter<Date>()
  @Output() readonly monthRangeSelected = new EventEmitter<DateRange>()

  @Input() numberOfMonths = 12
  @Input() includeCurrentMonth = true
  @Input() useUTC = false
  @Input() touched = false
  @Input() disabled = false
  @Input() limitedByNow = false
  @Input() severity: 'primary' | 'secondary' = 'secondary'

  protected _value: MonthRange | undefined

  get value(): MonthRange | undefined {
    return this._value
  }

  @Input()
  set value(value: MonthRange | DateRange | PreDefinedRange | undefined | null) {
    let valueWasSet = false
    const range =
      Array.isArray(value) && value?.[0]
        ? value
        : typeof value === 'string'
          ? getDateRangeFromPredefined(value)
          : null
    if (range) {
      this._value = {
        month: this.useUTC ? range[0].getUTCMonth() : range[0].getMonth(),
        year: this.useUTC ? range[0].getUTCFullYear() : range[0].getFullYear(),
      }
      valueWasSet = true
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      this._value = value
      valueWasSet = true
    }

    if (!valueWasSet) {
      this._value = { ...this.lastMonth }
    }
  }

  protected setLastDate() {
    const now = new Date(Date.now())
    const lastDate = this.includeCurrentMonth
      ? now
      : new Date(new Date(now.getFullYear(), now.getMonth(), 0).setDate(3))
    this.lastMonthValue = {
      month: this.useUTC ? lastDate.getUTCMonth() : lastDate.getMonth(),
      year: this.useUTC ? lastDate.getUTCFullYear() : lastDate.getFullYear(),
    }
  }

  get lastMonth(): MonthRange {
    if (!this.lastMonthValue) {
      this.setLastDate()
    }
    return this.lastMonthValue as MonthRange
  }

  async ngOnInit() {
    this.setLastDate()
    if (!this._value) {
      this._value = { ...this.lastMonth }
    }
  }

  @Memoize()
  get monthLabels$() {
    const keys: number[] = []
    for (let i = 0; i < 12; i++) {
      keys.push(i)
    }
    const params: Record<number, string> = {}
    for (const key of keys) {
      params[key] = `primeng.monthNames.${key}`
    }
    return this.languageService.massTranslate$(params)
  }

  @Memoize()
  get items$(): Observable<MenuItem[]> {
    return this.monthLabels$.pipe(
      takeUntil(this.destroy$),
      map((labels) => {
        const items: MenuItem[] = []
        let month = this.lastMonth.month
        let year = this.lastMonth.year
        for (let i = 0; i < this.numberOfMonths; i++) {
          const value = {
            month,
            year,
          }
          items.push({
            label: `${labels[month]} ${year}`,
            value,
            command: () => {
              this.onMonthRangeSelected(value)
            },
            isActive: () => value.month === this.value?.month && value.year === this.value?.year,
          })
          if (month === 0) {
            month = 11
            year = year - 1
          } else {
            month = month - 1
          }
        }

        return items
      }),
    )
  }

  protected markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  public onMonthRangeSelected(value: MonthRange) {
    this.writeValue(value)
    const beginDate = this.useUTC
      ? new Date(Date.UTC(value.year, value.month, 1, 0, 0, 0, 0))
      : new Date(value.year, value.month, 1, 0, 0, 0, 0)

    const daysInMonth = new Date(
      value.month + 1 === 12 ? value.year + 1 : value.year,
      value.month + 1 === 12 ? 0 : value.month + 1,
      0,
    ).getDate()

    let endDate = this.useUTC
      ? new Date(Date.UTC(value.year, value.month, daysInMonth, 23, 59, 59, 999))
      : new Date(value.year, value.month, daysInMonth, 23, 59, 59, 999)

    const now = new Date(Date.now())
    if (this.limitedByNow && endDate > now) {
      endDate = now
    }

    this.monthRangeSelected.emit([beginDate, endDate])
  }

  public writeValue(value: MonthRange) {
    this.onChange(value)
    this.value = value
  }

  public registerOnChange(onChange: () => void) {
    this.onChange = onChange
  }

  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }
}
