import { Component, inject } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-unexpected-error-page',
  templateUrl: 'unexpected-error-page.component.html',
  styleUrl: 'unexpected-error-page.component.scss',
})
export class UnexpectedErrorPageComponent {
  protected readonly router = inject(Router)

  protected backToApp() {
    this.router.navigate(['/'])
  }
}
