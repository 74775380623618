import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-clock-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9948 16.0007V32.0007L42.6615 37.334M58.6615 32.0007C58.6615 46.7282 46.7224 58.6673 31.9948 58.6673C17.2672 58.6673 5.32812 46.7282 5.32812 32.0007C5.32812 17.2731 17.2672 5.33398 31.9948 5.33398C46.7224 5.33398 58.6615 17.2731 58.6615 32.0007Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Clock1IconComponent extends BaseIconComponent {}
