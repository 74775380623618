import { HttpException } from '@ti-platform/web/api/services/api.client'

export const getMessageFromException = (e: unknown): string => {
  let message = ''
  if (e instanceof HttpException) {
    message = e.getError()?.message ?? e.getMessage()
  } else if (e instanceof Error) {
    message = e.message
  } else {
    message = (e as any).toString()
  }
  return message
}
