import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-car-solid-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 12.917V10.6215C2.5 9.95791 2.76347 9.32144 3.2325 8.85199L4.16667 7.91699H15.8333L16.7675 8.85116C17.2365 9.32017 17.5 9.95629 17.5 10.6196V12.917C17.5 13.8375 16.7538 14.5837 15.8333 14.5837H4.16667C3.24619 14.5837 2.5 13.8375 2.5 12.917Z"
        [attr.fill]="color"
        [attr.stroke]="color"
        style="fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4993 12.917V15.8337C17.4993 16.2939 17.1263 16.667 16.666 16.667H14.9993C14.5391 16.667 14.166 16.2939 14.166 15.8337V14.5837"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83333 14.5837V15.8337C5.83333 16.2939 5.46024 16.667 5 16.667H3.33333C2.8731 16.667 2.5 16.2939 2.5 15.8337V12.917"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5007 7.08333L15.834 7.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16667 7.5L2.5 7.08333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16602 7.91634V7.49467L4.86268 4.88051C5.10586 3.96798 5.93222 3.33299 6.8766 3.33301H13.1955C14.1598 3.33301 14.9982 3.99492 15.2218 4.93301L15.8327 7.49467V7.91634"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 11.2497H6.66667"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.334 11.2497H15.0007"
        stroke="white"
        style="stroke:white;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CarSolid1IconComponent extends BaseIconComponent {}
