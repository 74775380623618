import { inject, Injectable } from '@angular/core'
import { injectDestroy$ } from '@ti-platform/web/common'
import {
  BulkActionWithLoadingDialogComponent,
  BulkActionWithLoadingDialogData,
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmWithCommentDialogComponent,
  ConfirmWithCommentDialogData,
  WarnDialogComponent,
  WarnDialogData,
} from '@ti-platform/web/ui-kit/layout/components'
import { DialogService } from 'primeng/dynamicdialog'
import { takeUntil } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class DialogFacade {
  protected readonly dialogService = inject(DialogService)
  protected readonly destroy$ = injectDestroy$()

  public async confirm(data: ConfirmDialogData): Promise<boolean> {
    return new Promise((resolve) => {
      const ref = this.dialogService.open(ConfirmDialogComponent, {
        data,
        showHeader: false,
        styleClass: 'unset-default-style',
        // need to open several times
        // duplicate: true,
      })
      ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(resolve)
    })
  }

  public async confirmWithComment(data: ConfirmWithCommentDialogData): Promise<boolean> {
    return new Promise((resolve) => {
      const ref = this.dialogService.open(ConfirmWithCommentDialogComponent, {
        data,
        showHeader: false,
        styleClass: 'unset-default-style',
        // need to open several times
        // duplicate: true,
      })
      ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(resolve)
    })
  }

  public async bulkActionWithLoading(data: BulkActionWithLoadingDialogData): Promise<{
    result: boolean
    value: { all: number; success: number; fail: number }
  }> {
    return new Promise((resolve) => {
      const ref = this.dialogService.open(BulkActionWithLoadingDialogComponent, {
        data,
        showHeader: false,
        styleClass: 'unset-default-style',
        // need to open confirm several times
        // duplicate: true,
      })
      ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(resolve)
    })
  }

  public async warn(data: WarnDialogData) {
    return new Promise((resolve) => {
      const ref = this.dialogService.open(WarnDialogComponent, {
        data,
        showHeader: false,
        styleClass: 'unset-default-style',
        // need to open confirm several times
        duplicate: true,
      })
      ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(resolve)
    })
  }
}
