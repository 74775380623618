import { inject, Injectable } from '@angular/core'
import {
  buffer,
  combineLatest,
  debounceTime,
  merge,
  Subject,
  take,
  takeUntil,
  throttleTime,
} from 'rxjs'
import { FeatureCollection } from 'geojson'
import { GeoJSONSource, MapLayerMouseEvent, MapTouchEvent } from 'maplibre-gl'
import { injectDestroy$ } from '@ti-platform/web/common'
import { iVehicleMarkerOptions } from '../contracts'
import { FONT_OPEN_SANS_SEMIBOLD, MapAdapter, MapLibreMapAdapter } from '../adapters'
import { AbstractVehicleMarker, MapLibreVehicleMarker } from '../markers'

export abstract class MapVehiclesManager {
  public abstract fitMapToViewAll(): void

  public abstract addVehicleMarker(options: iVehicleMarkerOptions): AbstractVehicleMarker
}

const MARKER_SOURCE_ID = 'marker-data'

@Injectable()
export class MaplibreVehiclesManager extends MapVehiclesManager {
  protected readonly adapter = inject(MapAdapter) as MapLibreMapAdapter
  protected readonly destroy$ = injectDestroy$()

  protected readonly markers = new Map<number, MapLibreVehicleMarker>()
  protected readonly displayedMarkerIds = new Set<number>()
  protected readonly dataSourceUpdates$ = new Subject<(dataSource: GeoJSONSource) => void>()

  // Temporary way to store the last version of data source
  // TODO: Find better way to keep data source after style is updated
  protected dataSourceRef?: GeoJSONSource

  public constructor() {
    super()

    // Initialize after Adapter is ready
    this.adapter.onInit$.subscribe(() => this.init())
  }

  public fitMapToViewAll() {
    this.adapter.onInit$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      const coords = Array.from(this.markers.values()).map((m) => m.getLatLng())
      if (!coords.length) return

      if (this.markers.size > 1) {
        this.adapter.fitBounds(coords, 12, 20)
      } else {
        this.adapter.moveTo(coords[0], Math.max(12, this.adapter.getZoomLevel()), 20)
      }
    })
  }

  public addVehicleMarker(options: iVehicleMarkerOptions) {
    const marker = new MapLibreVehicleMarker(options, this.adapter, (cb) =>
      this.dataSourceUpdates$.next(cb),
    )
    this.markers.set(marker.id, marker)
    return marker
  }

  protected init() {
    this.initClusterSource()
    this.initClusterLayers()
    this.initClusterEvents()
    this.initMapRotationHandler()
    this.initDataSourceUpdatesHandler()

    // Reinitialize the layers after style data is updated
    const repairAfterStyleRefresh = () => {
      this.initClusterSource()
      this.initClusterLayers()
    }
    this.adapter.map.on('styledata', repairAfterStyleRefresh)
    this.destroy$.subscribe(() => this.adapter.map.off('styledata', repairAfterStyleRefresh))
  }

  protected initClusterSource() {
    if (!this.adapter.map.getSource(MARKER_SOURCE_ID)) {
      this.adapter.map.addSource(MARKER_SOURCE_ID, {
        type: 'geojson',
        data: this.dataSourceRef?._data
          ? this.dataSourceRef._data
          : {
              type: 'FeatureCollection',
              features: [],
            },
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 50,
      })
    }
  }

  protected initClusterLayers() {
    const CLUSTER_BLUR_LAYER_ID = 'cluster-blur'
    const CLUSTER_SHADOW_LAYER_ID = 'cluster-shadow'
    const CLUSTER_CIRCLE_LAYER_ID = 'clusters'
    const CLUSTER_LABELS_LAYER_ID = 'cluster-labels'

    if (!this.adapter.map.getLayer(CLUSTER_BLUR_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: CLUSTER_BLUR_LAYER_ID,
        type: 'circle',
        source: MARKER_SOURCE_ID,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': 'rgb(216,216,216)',
          'circle-blur': 0.2,
          'circle-radius': ['step', ['get', 'point_count'], 36, 10, 46, 20, 56],
        },
      })
    }

    if (!this.adapter.map.getLayer(CLUSTER_SHADOW_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: CLUSTER_SHADOW_LAYER_ID,
        type: 'circle',
        source: MARKER_SOURCE_ID,
        filter: ['has', 'point_count'],
        paint: {
          // 30px circles when point count is less than 10
          // 40px circles when point count is between 10 and 20
          // 50px circles when point count is greater than or equal to 20
          'circle-color': 'rgb(255,255,255)',
          'circle-radius': ['step', ['get', 'point_count'], 30, 10, 40, 20, 50],
        },
      })
    }

    if (!this.adapter.map.getLayer(CLUSTER_CIRCLE_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: CLUSTER_CIRCLE_LAYER_ID,
        type: 'circle',
        source: MARKER_SOURCE_ID,
        filter: ['has', 'point_count'],
        paint: {
          // 26px circles when point count is less than 10
          // 36px circles when point count is between 10 and 20
          // 46px circles when point count is greater than or equal to 20
          'circle-color': 'rgba(134, 84, 224, 1.0)',
          'circle-radius': ['step', ['get', 'point_count'], 26, 10, 36, 20, 46],
        },
      })
    }

    if (!this.adapter.map.getLayer(CLUSTER_LABELS_LAYER_ID)) {
      this.adapter.map.addLayer({
        id: CLUSTER_LABELS_LAYER_ID,
        type: 'symbol',
        source: MARKER_SOURCE_ID,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['number-format', ['get', 'point_count'], {}],
          'text-font': [FONT_OPEN_SANS_SEMIBOLD],
          'text-size': ['step', ['get', 'point_count'], 14, 10, 16, 20, 18],
        },
        paint: {
          'text-color': 'white',
        },
      })
    }
  }

  protected initClusterEvents() {
    const handleClusterClick = async (e: MapTouchEvent | MapLayerMouseEvent) => {
      const features = this.adapter.map.queryRenderedFeatures(e.point)
      if (features?.length) {
        const feature = features[0]
        const pointCount = feature.properties.point_count
        if (pointCount) {
          const coordinates = (feature.geometry as any)?.coordinates
          if (coordinates) {
            const source = this.adapter.map.getSource(MARKER_SOURCE_ID) as GeoJSONSource
            const zoom = await source.getClusterExpansionZoom(feature.properties.cluster_id)
            this.adapter.map.flyTo({ center: coordinates, zoom, speed: 2 }) // Fly to the cluster coordinates with the calculated zoom
          }
        }
      }
    }

    this.adapter.map.on('click', 'clusters', handleClusterClick)
    this.adapter.map.on('touchstart', 'clusters', handleClusterClick)

    this.destroy$.subscribe(() => {
      this.adapter.map.off('click', 'clusters', handleClusterClick)
      this.adapter.map.off('touchstart', 'clusters', handleClusterClick)
    })

    const mapContainer = this.adapter.map
      .getContainer()
      .querySelector<HTMLDivElement>('div.maplibregl-interactive')

    // Set `cursor: pointer` on hover for clusters
    if (mapContainer) {
      const handleClusterEnter = () => (mapContainer.style.cursor = 'pointer')
      const handleClusterLeave = () => (mapContainer.style.cursor = '')

      this.adapter.map.on('mouseenter', 'clusters', handleClusterEnter)
      this.adapter.map.on('mouseleave', 'clusters', handleClusterLeave)

      this.destroy$.subscribe(() => {
        this.adapter.map.off('mouseenter', 'clusters', handleClusterEnter)
        this.adapter.map.off('mouseleave', 'clusters', handleClusterLeave)
      })
    }

    const markersDataUpdated$ = new Subject<void>()
    merge(this.adapter.onMove$, this.adapter.onZoom$, markersDataUpdated$)
      .pipe(throttleTime(100, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => this.refreshClusterMarkers())

    const handleMapDataUpdates = (e: { sourceId: string; isSourceLoaded: boolean }) => {
      if (e.sourceId !== MARKER_SOURCE_ID || !e.isSourceLoaded) {
        return
      }
      markersDataUpdated$.next()
    }
    this.adapter.map.on('data', handleMapDataUpdates)

    this.destroy$.subscribe(() => {
      markersDataUpdated$.complete()
      this.adapter.map.off('data', handleMapDataUpdates)
    })
  }

  protected refreshClusterMarkers() {
    const features = this.adapter.map
      .querySourceFeatures(MARKER_SOURCE_ID)
      .filter((feature) => !feature.properties.cluster)

    const featureIds = features.map((feature) => feature.id as number)

    // Ensure all un-clustered markers are visible
    featureIds.forEach((featureId) => {
      if (!this.displayedMarkerIds.has(featureId)) {
        const marker = this.markers.get(featureId)
        if (marker) {
          marker.nativeRef.addTo(this.adapter.map)
          this.displayedMarkerIds.add(marker.id)
        }
      }
    })

    // Remove clustered markers from the map
    this.displayedMarkerIds.forEach((markerId) => {
      if (!featureIds.includes(markerId)) {
        const marker = this.markers.get(markerId)
        if (marker) {
          marker.nativeRef.remove()
        }
        this.displayedMarkerIds.delete(markerId)
      }
    })
  }

  protected initMapRotationHandler() {
    const onRotate = () => {
      const bearing = this.adapter.getBearing()
      this.markers.forEach((marker) => marker.setMapBearing(bearing))
    }
    this.adapter.map.on('rotate', onRotate)
    this.destroy$.subscribe(() => this.adapter.map.off('rotate', onRotate))
  }

  protected initDataSourceUpdatesHandler() {
    this.dataSourceUpdates$
      .pipe(
        // Batch all data source updates operations
        buffer(
          // Buffer all updates until map is not initialized
          combineLatest([this.adapter.onInit$, this.dataSourceUpdates$]).pipe(debounceTime(256)),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((operations) => {
        const dataSource = this.adapter.map.getSource(MARKER_SOURCE_ID) as GeoJSONSource
        if (dataSource._data) {
          this.dataSourceRef = dataSource
          operations.forEach((op) => op(dataSource))
          dataSource.setData(dataSource._data)

          const collection = dataSource._data as FeatureCollection
          if (Array.isArray(collection.features)) {
            const featureIds = collection.features.map((f) => f.id)
            this.markers.forEach((marker) => {
              if (!featureIds.includes(marker.id)) {
                marker.nativeRef?.remove()
                this.markers.delete(marker.id)
                this.displayedMarkerIds.delete(marker.id)
              }
            })
          }
        }
      })
  }
}
