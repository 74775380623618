// Temporary solution to reduce the image quality for profile images
export const downsizeImage = (
  imgDataUrl: string,
  maxSize = 300,
  quality = 0.85,
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

      const width = img.width > maxSize ? maxSize : img.width
      const height = img.height > maxSize ? maxSize : img.height

      // Set canvas dimensions
      canvas.width = width
      canvas.height = height

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, width, height)

      // Convert canvas to data URL (base64 encoded image)
      resolve(canvas.toDataURL('image/png', quality))
    }
    img.src = imgDataUrl
  })
}
