export const formatCommaSeparatedNumber = (
  numberText: string,
  thousandSeparator = ',',
  decimalSeparator = thousandSeparator === ',' ? '.' : ',',
): string => {
  const parts = numberText.split('.')
  const reversedInteger = parts[0].split('').reverse().join('')
  let formattedInteger = reversedInteger.replace(/(\d{3})(?!$)/g, `$1${thousandSeparator}`)
  formattedInteger = formattedInteger.split('').reverse().join('')
  return formattedInteger + (parts.length > 1 ? decimalSeparator + parts[1] : '')
}
