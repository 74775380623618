import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'

import {
  DeviceMediaConfig,
  TspDeviceListData,
  VideoQualityStreamtype,
} from '@ti-platform/contracts'
import { CopyMediaConfigDevicesListModel } from '@ti-platform/tsp-admin/app/devices/models'
import { injectDestroy$, sleep } from '@ti-platform/web/common'
import { BehaviorSubject } from 'rxjs'

interface CopyMediaConfigResultData {
  all: number
  success: number
}

@Component({
  selector: 'app-copy-device-media-config-dialog',
  templateUrl: 'copy-device-media-config-dialog.component.html',
  styleUrl: 'copy-device-media-config-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyDeviceMediaConfigDialogComponent implements OnInit {
  protected readonly destroy$ = injectDestroy$()
  protected readonly model = inject(CopyMediaConfigDevicesListModel)
  protected readonly isExecuting$ = new BehaviorSubject<boolean>(false)
  protected readonly isDestroyed$ = new BehaviorSubject<boolean>(false)

  protected readonly VideoQualityStreamtype = VideoQualityStreamtype

  @Output() cancelEvent = new EventEmitter<void>()
  @Output() confirmEvent = new EventEmitter<CopyMediaConfigResultData>()

  @Input() device: TspDeviceListData | null = null
  protected _opened = false

  get opened() {
    return this._opened
  }

  @Input()
  set opened(value: boolean) {
    this._opened = value
    if (value) {
      this.model.setSelectedDevice(null)
      setTimeout(() => {
        this.model.setSelectedDevice(this.device)
      }, 200)
    } else {
      this.model.setSelectedDevice(null)
      this.model.resetMultiSelectedItems()
    }
  }

  get mediaConfig(): DeviceMediaConfig | null {
    return this.device?.mediaConfig || null
  }

  protected get noDataTitleKey() {
    let result = 'tsp-admin.devices.copy-media-config.no-devices.title'
    if (this.model.isActiveSearch) {
      result = 'search.no-data-title'
    }
    return result
  }

  protected get noDataDescriptionKey() {
    let result = ''
    if (this.model.isActiveSearch) {
      result = 'search.no-data-description'
    } else {
      result = 'tsp-admin.devices.copy-media-config.no-devices.description'
    }
    return result
  }

  public onCancel() {
    if (!this.isExecuting$.value) {
      this.cancelEvent.emit()
    }
  }

  protected onCloseClick() {
    this.onCancel()
  }

  protected async executeAction(deviceId: string, resultData: Record<string, boolean>) {
    if (this.isDestroyed$.value) {
      return
    }
    try {
      const result = await this.model.copyMediaConfigToDevice(deviceId, this.mediaConfig)
      if (result) {
        resultData[deviceId] = true
      }
    } catch (e) {
      console.error(e)
      resultData[deviceId] = false
    }
  }

  protected async processAll(): Promise<CopyMediaConfigResultData | null> {
    const deviceIds: string[] = this.model.selectedItems.map((device) => device.id as string)
    const result: CopyMediaConfigResultData = {
      all: deviceIds.length,
      success: 0,
    }

    const chunkSize = 50

    const resultByKey: Record<string, boolean> = {}

    const waitingTimeout = deviceIds.length > 200 ? 500 : 100
    let firstIndex = 0
    let lessThenIndex = Math.min(firstIndex + chunkSize, deviceIds.length)
    while (firstIndex < deviceIds.length) {
      const promises: Promise<void>[] = []
      for (let i = firstIndex; i < lessThenIndex; i++) {
        const deviceId = deviceIds[i]
        resultByKey[deviceId] = false
        promises.push(this.executeAction(deviceId, resultByKey))
      }
      await Promise.all(promises)

      if (this.isDestroyed$.value) {
        return null
      }
      firstIndex = firstIndex + chunkSize
      lessThenIndex = Math.min(firstIndex + chunkSize, deviceIds.length)

      if (firstIndex < deviceIds.length) {
        await sleep(waitingTimeout)
      }
    }

    if (this.isDestroyed$.value) {
      return null
    }

    result.success = Object.keys(resultByKey).filter((key) => resultByKey[key]).length

    return result
  }

  protected async onConfirm() {
    this.isExecuting$.next(true)
    const result = await this.processAll()
    this.isExecuting$.next(false)
    if (result) {
      this.confirmEvent.emit(result)
    }
  }

  protected secToMinutes(value) {
    return Math.round(value / 60)
  }

  public ngOnInit() {
    this.destroy$.subscribe(() => {
      this.isDestroyed$.next(true)
    })
  }
}
