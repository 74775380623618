import { Component, EventEmitter, Input, Output, signal } from '@angular/core'
import { KeyFilterPattern } from 'primeng/keyfilter'

@Component({
  selector: 'app-editable-input',
  templateUrl: 'editable-input.component.html',
  styleUrls: ['editable-input.component.scss'],
})
export class EditableInputComponent {
  @Output() readonly cancelled = new EventEmitter<void>()
  @Output() readonly submitted = new EventEmitter<string>()

  protected readonly currentValue = signal('')
  protected readonly isEditActive = signal(false)

  protected _value?: string

  @Input() height = 40
  @Input() isLoading = false
  @Input() keyFilter: KeyFilterPattern | undefined = undefined

  @Input() set value(value: string) {
    this._value = value
    this.currentValue.set(value)
  }

  protected onCancel(emitEvent = true) {
    this.isEditActive.set(false)
    this.currentValue.set(this._value ?? '')
    if (emitEvent) {
      this.cancelled.emit()
    }
  }

  protected onSubmit() {
    const value = this.currentValue()
    if (value !== this._value?.trim()) {
      this._value = value
      this.submitted.emit(value)
      this.isEditActive.set(false)
    } else {
      this.onCancel(false)
    }
  }
}
