import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-feedback-2',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.849 16.396C20.579 15.096 21 13.598 21 12C21 7.029 16.971 3 12 3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C13.598 21 15.096 20.579 16.396 19.849L21 21L19.849 16.396Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5882"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8226 12.323C11.9206 12.225 12.0786 12.225 12.1766 12.323C12.2746 12.421 12.2746 12.579 12.1766 12.677C12.0786 12.775 11.9206 12.775 11.8226 12.677C11.7256 12.579 11.7256 12.421 11.8226 12.323"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.82263 12.323C7.92063 12.225 8.07863 12.225 8.17663 12.323C8.27463 12.421 8.27463 12.579 8.17663 12.677C8.07863 12.775 7.92063 12.775 7.82263 12.677C7.72563 12.579 7.72563 12.421 7.82263 12.323"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8226 12.323C15.9206 12.225 16.0786 12.225 16.1766 12.323C16.2746 12.421 16.2746 12.579 16.1766 12.677C16.0786 12.775 15.9206 12.775 15.8226 12.677C15.7256 12.579 15.7256 12.421 15.8226 12.323"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Feedback2IconComponent extends BaseIconComponent {}
