<p-dialog
  [(visible)]="opened"
  [modal]="true"
  class="dialog-primary w420 headless"
  closable="false"
  [focusOnShow]="false"
>
  <ng-template pTemplate="header"></ng-template>
  <div class="dialog-content">
    <div class="header flex">
      <div class="header-title">
        {{ 'tsp-admin.devices.bulk-actions.assign.question.header' | translate }}
      </div>

      <div class="header-icon-wrapper">
        <i class="pi pi-times header-icon clickable" (click)="onCloseClick()"></i>
      </div>
    </div>

    <div class="body">
      <div class="description text-14 fw-400">
        {{ 'tsp-admin.devices.bulk-actions.assign.question.description' | translate }}
      </div>

      <div class="fleet-select">
        <app-autocomplete-select
          (completeMethod)="onFilterFleet($event)"
          [completeOnFocus]="true"
          [forceSelection]="true"
          [suggestions]="filteredFleets"
          [(value)]="fleet"
          optionLabel="name"
          placeholder="{{ 'tsp-admin.devices.select-fleet' | translate }}"
        ></app-autocomplete-select>
      </div>
    </div>
    <div class="footer">
      <p-button
        (click)="onCancel()"
        [severity]="'secondary'"
        class="cancel text-14 fw-600 w50 p--8 h--40 footer-button"
      >
        {{ 'button.cancel' | translate }}
      </p-button>

      <p-button
        (click)="onConfirm()"
        [disabled]="!fleet"
        class="submit text-14 fw-600 w50 h--40 footer-button"
        type="submit"
      >
        {{
          'tsp-admin.devices.bulk-actions.assign.question.confirm-button'
            | translate: { val: numberToAssignment }
        }}
      </p-button>
    </div>
  </div>
</p-dialog>
