import { Component, inject } from '@angular/core'
import { NavigationHistoryProvider } from '@ti-platform/web/common'

@Component({
  selector: 'app-tsp-admin-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class TspAdminLayoutComponent {
  protected readonly navigationHistoryProvider = inject(NavigationHistoryProvider)
}
