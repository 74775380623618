import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-map-pin-outlined',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 8.63636C15.8332 13.4091 9.99984 17.5 9.99984 17.5C9.99984 17.5 4.1665 13.4091 4.1665 8.63636C4.1665 7.0089 4.78109 5.44809 5.87505 4.2973C6.96901 3.14651 8.45274 2.5 9.99984 2.5C11.5469 2.5 13.0307 3.14651 14.1246 4.2973C15.2186 5.44809 15.8332 7.0089 15.8332 8.63636Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 10.6818C11.0737 10.6818 11.9443 9.76604 11.9443 8.63636C11.9443 7.50669 11.0737 6.59091 9.99984 6.59091C8.92595 6.59091 8.05539 7.50669 8.05539 8.63636C8.05539 9.76604 8.92595 10.6818 9.99984 10.6818Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class MapPinOutlinedIconComponent extends BaseIconComponent {
  @Input() override height = 20
  @Input() override width = 20
}
