import { inject, Injectable } from '@angular/core'
import { SwPush } from '@angular/service-worker'
import { TRIGGERED_ALERT_NOTIFICATIONS } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { BehaviorSubject, combineLatest, map, Observable, takeUntil, timer } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class SidebarBadgesModel {
  protected readonly unreviewedAlertsCount$ = new BehaviorSubject<number>(0)
  protected readonly refreshUnreviewedAlertCount$ = new BehaviorSubject<void>(undefined)
  protected readonly newMediaLibraryItemsCount$ = new BehaviorSubject<number>(0)
  protected readonly refreshNewMediaLibraryItemsCount$ = new BehaviorSubject<void>(undefined)

  protected readonly api = inject(ApiService)
  protected readonly pushes = inject(SwPush)
  protected readonly destroy$ = injectDestroy$()

  @Memoize()
  public get state() {
    return {
      counters$: this.countersDataStream(),
    }
  }

  public init() {
    combineLatest([timer(100, 60_000), this.refreshUnreviewedAlertCount$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadUnreviewedAlertsCount())

    combineLatest([timer(100, 60_000), this.refreshNewMediaLibraryItemsCount$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadNewMediaLibraryItemsCount())

    // update alert badge on get alert notification push
    this.pushes.messages.pipe(takeUntil(this.destroy$)).subscribe((msg: Record<string, any>) => {
      if (msg?.data?.type && TRIGGERED_ALERT_NOTIFICATIONS.includes(msg.data.type)) {
        this.refreshUnreviewedAlertCount$.next()
      }
    })
  }

  // can be other counters
  public countersDataStream(): Observable<Record<string, number>> {
    return combineLatest([this.newMediaLibraryItemsCount$, this.unreviewedAlertsCount$]).pipe(
      takeUntil(this.destroy$),
      map(([newMediaLibraryItems, unreviewedAlerts]) => {
        return {
          newMediaLibraryItems,
          unreviewedAlerts,
        }
      }),
    )
  }

  public refreshUnreviewedAlertsCount() {
    this.refreshUnreviewedAlertCount$.next()
  }

  public refreshNewMediaLibraryItemsCount() {
    this.refreshNewMediaLibraryItemsCount$.next()
  }

  public async loadUnreviewedAlertsCount() {
    try {
      const number = await this.api.fleet.triggeredAlert.unreviewedCount()
      if (number !== null && number !== undefined) {
        this.unreviewedAlertsCount$.next(number)
      }
    } catch (e) {
      console.error(e)
    }
  }

  public async loadNewMediaLibraryItemsCount() {
    try {
      const number = await this.api.fleet.media.countNew()
      this.newMediaLibraryItemsCount$.next(number)
    } catch (e) {
      console.error(e)
    }
  }
}
