<p-menu
  class="menu-primary personal-menu"
  #menuRef
  [popup]="true"
  [model]="(menuItems$ | async) ?? []"
>
  <ng-template pTemplate="start">
    @if (profile.state | async; as user) {
      <div class="personal-info">
        <app-avatar
          class="clickable"
          size="40"
          [photo]="user.photo"
          (click)="menuRef.toggle($event)"
        ></app-avatar>

        <div class="info-wrapper">
          <div class="name text-14 fw-600">
            {{ user.name }}

            @if (profile.roleLabelKey$ | async; as roleLabelKey) {
              <div class="counter text-12 fw-600">{{ roleLabelKey | translate }}</div>
            }
          </div>
          <div class="email text-14 fw-400">{{ user.email }}</div>
        </div>
      </div>
    }
  </ng-template>

  <ng-template let-item pTemplate="item">
    <a class="flex align-items-center p-menuitem-link" pRipple>
      <div class="p-menuitem {{ item.cssClass }}">
        @if (item.iconType) {
          <app-dynamic-host
            class="p-menuitem-icon"
            [componentType]="item.iconType"
            [componentInputs]="{ color: 'var(--icon-color)', height: 20, width: 20 }"
          ></app-dynamic-host>
        }

        @if (item.icon) {
          <i class="p-menuitem-icon" [class]="item.icon"></i>
        }

        <span class="p-menuitem-text">{{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>
