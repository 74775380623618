"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const code_1 = require("../code");
const codegen_1 = require("../../compile/codegen");
const subschema_1 = require("../../compile/subschema");
const validate_1 = require("../../compile/validate");
const util_1 = require("../../compile/util");
const def = {
    keyword: "patternProperties",
    type: "object",
    schemaType: "object",
    code(cxt) {
        const { gen, schema, data, parentSchema, it } = cxt;
        const { opts } = it;
        const patterns = code_1.schemaProperties(it, schema);
        // TODO mark properties matching patterns with always valid schemas as evaluated
        if (patterns.length === 0)
            return;
        const checkProperties = opts.strict && !opts.allowMatchingProperties && parentSchema.properties;
        const valid = gen.name("valid");
        if (it.props !== true && !(it.props instanceof codegen_1.Name)) {
            it.props = util_1.evaluatedPropsToName(gen, it.props);
        }
        const { props } = it;
        validatePatternProperties();
        function validatePatternProperties() {
            for (const pat of patterns) {
                if (checkProperties)
                    checkMatchingProperties(pat);
                if (it.allErrors) {
                    validateProperties(pat);
                }
                else {
                    gen.var(valid, true); // TODO var
                    validateProperties(pat);
                    gen.if(valid);
                }
            }
        }
        function checkMatchingProperties(pat) {
            for (const prop in checkProperties) {
                if (new RegExp(pat).test(prop)) {
                    validate_1.checkStrictMode(it, `property ${prop} matches pattern ${pat} (use allowMatchingProperties)`);
                }
            }
        }
        function validateProperties(pat) {
            gen.forIn("key", data, (key) => {
                gen.if(codegen_1._ `${code_1.usePattern(gen, pat)}.test(${key})`, () => {
                    cxt.subschema({
                        keyword: "patternProperties",
                        schemaProp: pat,
                        dataProp: key,
                        dataPropType: subschema_1.Type.Str,
                        strictSchema: it.strictSchema,
                    }, valid);
                    if (it.opts.unevaluated && props !== true) {
                        gen.assign(codegen_1._ `${props}[${key}]`, true);
                    }
                    else if (!it.allErrors) {
                        // can short-circuit if `unevaluatedProperties` is not supported (opts.next === false)
                        // or if all properties were evaluated (props === true)
                        gen.if(codegen_1.not(valid), () => gen.break());
                    }
                });
            });
        }
    },
};
exports.default = def;
