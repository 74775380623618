import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-taxi-1',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6L14.644 4.29555C14.5472 3.83207 14.1386 3.50001 13.6651 3.5H10.3771C9.91245 3.5 9.509 3.82011 9.40334 4.27263L9 6"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 18.1157V20.0001C20.5 20.5524 20.0523 21.0001 19.5 21.0001H17.5C16.9477 21.0001 16.5 20.5524 16.5 20.0001V18.5001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 18.5001V20.0001C7.5 20.5524 7.05228 21.0001 6.5 21.0001H4.5C3.94772 21.0001 3.5 20.5524 3.5 20.0001V18.1157"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 17V14.7436C3 13.9485 3.3157 13.1858 3.87773 12.6233L5 11.5H19L20.1213 12.6213C20.6839 13.1839 21 13.947 21 14.7426V17C21 17.8284 20.3284 18.5 19.5 18.5H4.5C3.67157 18.5 3 17.8284 3 17Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 10.5L19 11"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 11L3 10.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 11.5V10.994L5.836 7.857C6.12781 6.76196 7.11945 5.99998 8.2527 6H15.8353C16.9926 6 17.9986 6.7943 18.267 7.92L19 10.994V11.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 15H8"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 15H18"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Taxi1IconComponent extends BaseIconComponent {}
