import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { VehicleStatus } from '@ti-platform/web/map/contracts'

@Component({
  selector: 'app-map-static-vehicle-marker',
  templateUrl: 'static-vehicle-marker.component.html',
})
export class StaticVehicleMarkerComponent implements AfterViewInit {
  @Input() color!: string
  @Input() icon!: string
  @Input() status: VehicleStatus.Idling | VehicleStatus.Stopped | undefined | null = undefined
  @Output() afterViewInit = new EventEmitter()

  protected readonly VehicleStatus = VehicleStatus

  public ngAfterViewInit() {
    this.afterViewInit.emit()
  }
}
