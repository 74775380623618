import { ApiRoute } from '../../../services/api.route'
import {
  DataQueryProps,
  Location,
  LocationPlace,
  LocationSuggestion,
  SaveLocationDto,
} from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'

export class LocationsRoute extends ApiRoute {
  public override init() {
    this.route = 'locations'
  }

  public one(id: number) {
    return this.get<Location>().endpoint(`${id}`)
  }

  list(params: DataQueryProps) {
    return this.get<Location[]>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('list')
  }

  listForAlerts() {
    return this.get<Location[]>().endpoint('list-for-alerts')
  }

  public count() {
    return this.get<number>().endpoint('count-all')
  }

  public search(term: string) {
    return this.get<Location[]>().withParams({ term }).endpoint('search')
  }

  public autocomplete(term: string, lang?: string, latLng?: [number, number]) {
    // Temporary solution to remove commas from the query parameters
    if (term.includes(',')) {
      term = term.replace(/,/g, ' ')
    }

    const params: Record<string, string> = { term }
    if (lang) {
      params.lang = lang
    }
    if (Array.isArray(latLng) && latLng.length === 2) {
      params.lat = `${latLng[0]}`
      params.lng = `${latLng[1]}`
    }
    return this.get<LocationSuggestion[]>().withParams(params).endpoint('autocomplete')
  }

  public getPlaceDetails(placeId: string) {
    return this.get<LocationPlace>().withParams({ id: placeId }).endpoint(`place-details`)
  }

  public searchByPosition(coordinates: [number, number], maxResults?: number) {
    return this.post<LocationPlace[]>()
      .withData({ coordinates, maxResults })
      .endpoint(`search-by-position`)
  }

  public create(dto: SaveLocationDto) {
    return this.post<Location>().withData(dto).endpoint('create')
  }

  public update(id: string, dto: SaveLocationDto) {
    return this.post<Location>().withData(dto).endpoint(`update/${id}`)
  }

  public deleteLocation(id: string) {
    return this.delete<boolean>().endpoint(`delete/${id}`)
  }
}
