import { Alert, AlertData, AlertsCountByTriggersData, DataQueryProps } from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class AlertRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/alert'
  }

  public list(options: DataQueryProps) {
    const data: Record<string, any> = stripEmptyKeys(options)
    Object.keys(data)?.forEach((key) => {
      data[key] = key === 'filter' ? JSON.stringify(options.filter) : `${data[key]}`
    })
    return this.get<Alert[]>().withParams(data).endpoint('list')
  }

  public countByTriggers() {
    return this.get<AlertsCountByTriggersData[]>().endpoint('count-by-triggers')
  }

  public countAll() {
    return this.get<number>().endpoint('count-all')
  }

  public getById(id: string) {
    return this.get<Alert>().endpoint(id)
  }

  public createAlert(alertData: AlertData) {
    const data: Record<string, any> = stripEmptyKeys(alertData)
    return this.post<Alert>().withData(data).endpoint('create')
  }

  public editAlert(id: string, alertData: Partial<AlertData>) {
    const data: Record<string, any> = stripEmptyKeys(alertData)
    return this.put<Alert>().withData(data).endpoint(id)
  }

  public deleteAlert(id: string) {
    return this.delete<Alert>().endpoint(id)
  }
}
