export interface Event {
  id: string
  eventType: EventTypes
  data: Record<string, any>
  scheduledAt: Date
  status: EventStatuses
}

export enum EventStatuses {
  Scheduled = 0,
  InProcess = 1,
  Failed = 2,
  Done = 3,
  Canceled = 4,
}

export enum EventTypes {
  CreateDelayedAlert = 'create_delayed_alert',
  CreateRepeatAlertNotification = 'create_reapet_alert_notification',
}
