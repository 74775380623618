<p-menu #menu [popup]="true" [model]="model.vehiclesInfo.list" [appendTo]="appendTo">
  <ng-template pTemplate="start">
    @if (model.vehiclesInfo.all || model.vehiclesInfo.number === 0) {
      <div class="menu-item name text-14 fw-500">{{ model.vehiclesInfo.title }}</div>
    } @else if (model.vehiclesInfo.noVehiclesInfo) {
      <div class="menu-item name text-14 fw-500">{{ 'alerts.vehicles.no-data' | translate }}</div>
    }
  </ng-template>
  <ng-template pTemplate="item" let-item>
    @if (model.vehiclesInfo.list?.length) {
      <div class="menu-item avatar-double-description vehicle-menu-item alert-manage-menu-item">
        <div class="avatar flex" style="width: 40px; height: 40px">
          <div class="bg" [style.background-color]="item.iconColor"></div>
          <app-transport-icon-selector
            [icon]="item.icon"
            class="icon"
          ></app-transport-icon-selector>
        </div>
        <div>
          <div class="text-14 fw-500" [class]="{ name: item.description, full: !item.description }">
            {{ item.name }}
          </div>
          @if (item.description) {
            <div class="description text-14 fw-400">{{ item.description }}</div>
          }
        </div>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="end">
    @if (model.vehiclesInfo.more) {
      <div class="menu-item avatar-double-description alert-manage-menu-item">
        <div class="avatar flex" style="width: 40px; height: 40px">
          <i class="pi pi-ellipsis-h" style="color: var(--primary-color)"></i>
        </div>
        <div>
          <div class="full text-14 fw-500">{{ model.vehiclesInfo.more }}</div>
        </div>
      </div>
    }
  </ng-template>
</p-menu>
