import { inject, Injectable } from '@angular/core'
import {
  ApiErrors,
  DataQueryProps,
  DeviceMediaConfig,
  TspDeviceListData,
} from '@ti-platform/contracts'
import {
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  ListModel,
  ListModelConfig,
} from '@ti-platform/web/common'
import { DateFormatService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class CopyMediaConfigDevicesListModel extends ListModel<Partial<TspDeviceListData>> {
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly messageService = inject(MessageService)
  readonly selectedDevice$ = new BehaviorSubject<TspDeviceListData | null>(null)
  protected readonly dateFormatService = inject(DateFormatService)

  public setSelectedDevice(device: TspDeviceListData | null) {
    this.selectedDevice$.next(device)
    this.reset()
    this.loadCount()
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    console.error(message)
    return checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown)
  }

  public async getAndShowMessageOnException(e: any) {
    const message = getMessageFromException(e)
    if (message) {
      console.error(message)
    }
    await this.showUnknownErrorMessage()
  }

  public getFilterValue() {
    return this.store.filter$.getValue()
  }

  public getOrderByValue() {
    return this.store.orderBy$.getValue()
  }

  public get isActiveSearch() {
    return !!this.store.search$.value
  }

  public get selectedItems() {
    return this.store.multiSelectedItems$.value
  }

  public async showUnknownErrorMessage() {
    const message = await this.languageService.translate(getApiErrorKey(ApiErrors.unknown))
    this.messageService.add({
      severity: 'error',
      detail: message,
      life: 2000,
    })
  }

  protected config(): ListModelConfig {
    return {
      name: 'CopyMediaConfigDevicesListModel',
      defaultOrderColumn: 'name',
      defaultOrderDirection: 'ASC',
      loadCount: () => this.loadCount(),
      isMultiSelectable: true,
      keyColumn: 'id',
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.devices.grid.device-name',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'model',
          label: 'tsp-admin.devices.grid.brand-and-model',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'identifier',
          label: 'tsp-admin.devices.grid.imei-serial',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'fleetName',
          label: 'tsp-admin.devices.grid.fleet',
          type: DataGridColumnType.Text,
          sortable: true,
        },
      ],
    }
  }

  async loadCount() {
    const deviceId = this.selectedDevice$.value?.id
    if (!deviceId) {
      return 0
    }

    return this.api.tsp.devices.countPossibleVideoDevices(deviceId)
  }

  protected async loadPage(props: DataQueryProps): Promise<Partial<TspDeviceListData>[]> {
    const deviceId = this.selectedDevice$.value?.id
    if (!deviceId) {
      return []
    }

    props.filter = {
      id: {
        ne: deviceId,
      },
    }

    const result: Record<string, any>[] = await this.api.tsp.devices.possibleVideoDevicesList(props)
    result.forEach((item) => {
      if (item.fleet) {
        item.fleetName = item.fleet.name
      }
    })
    return result
  }

  async copyMediaConfigToDevice(deviceId: string, mediaConfig: DeviceMediaConfig | null) {
    return this.api.tsp.devices.setDeviceMediaConfig(deviceId, mediaConfig)
  }
}
