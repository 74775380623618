import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-send',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 2L23.2079 2.24776C23.3031 1.97582 23.2341 1.6734 23.0303 1.46967C22.8266 1.26594 22.5242 1.19693 22.2522 1.29211L22.5 2ZM15.5 22L14.8146 22.3046C14.9395 22.5856 15.2233 22.7619 15.5306 22.7494C15.8378 22.7368 16.1063 22.538 16.2079 22.2478L15.5 22ZM2.5 9L2.25224 8.29211C1.96199 8.39369 1.76316 8.66217 1.75062 8.96943C1.73809 9.27669 1.91439 9.56047 2.1954 9.68536L2.5 9ZM21.9697 1.46967L10.9697 12.4697L12.0303 13.5303L23.0303 2.53033L21.9697 1.46967ZM21.7921 1.75224L14.7921 21.7522L16.2079 22.2478L23.2079 2.24776L21.7921 1.75224ZM16.1854 21.6954L12.1854 12.6954L10.8146 13.3046L14.8146 22.3046L16.1854 21.6954ZM11.8046 12.3146L2.8046 8.31464L2.1954 9.68536L11.1954 13.6854L11.8046 12.3146ZM2.74776 9.70789L22.7478 2.70789L22.2522 1.29211L2.25224 8.29211L2.74776 9.70789Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class SendIconComponent extends BaseIconComponent {}
