<app-grid-controls
  (clearSearch)="model.setSearch('')"
  (searchChanged)="model.setSearch($event)"
  (sortChanged)="model.setOrder($event)"
  [actionButtonsTemplate]="actionButtons"
  [columns]="state.columns$ | async"
  [countTemplate]="count"
  [search]="state.search$ | async"
  [sortOrder]="state.gridSortOrder$ | async"
  [defaultSortOrder]="state.defaultSortOrder$ | async"
  [showMobileMenuButton]="false"
  titleText="users.title"
></app-grid-controls>

<ng-template #count>
  <app-counter [brackets]="false" [text]="''" [value]="(state.count$ | async) ?? 0">
    <app-icon-users [height]="22" [width]="22"></app-icon-users>
  </app-counter>
</ng-template>

<ng-template #actionButtons let-isMobile="isMobile">
  <p-button
    class="h--40 p--18 text-14 fw-500"
    [icon]="isMobile ? 'pi' : ''"
    [severity]="isMobile ? 'secondary' : 'primary'"
    [appCheckAccess]="[Resource.User, Action.Create]"
    (click)="createUserFormOpened$.next(true)"
  >
    <app-icon-plus [height]="20" [width]="20" class="icon"></app-icon-plus>
    @if (!isMobile) {
      {{ 'users.add-btn' | translate }}
    }
  </p-button>
</ng-template>

<app-users-grid></app-users-grid>

@if (state.selectedItem$ | async; as userData) {
  <app-edit-user-dialog
    [opened]="true"
    [user]="userData"
    [displayRoles]="'TSP'"
    (submitEdit)="model.update($event)"
    (cancelEdit)="model.unselect()"
  ></app-edit-user-dialog>
}

@if (createUserFormOpened$ | async) {
  <app-add-user-dialog
    [opened]="true"
    [displayRoles]="'TSP'"
    (submitAdd)="model.create($event); createUserFormOpened$.next(false)"
    (cancelAdd)="createUserFormOpened$.next(false)"
  ></app-add-user-dialog>
}
