import { inject, Injectable } from '@angular/core'
import {
  ApiErrors,
  DataQueryProps,
  TspCommandListData,
  TspDeviceListData,
} from '@ti-platform/contracts'
import {
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  ListModel,
  ListModelConfig,
} from '@ti-platform/web/common'
import { DateFormatService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { BehaviorSubject, combineLatest, debounceTime, takeUntil } from 'rxjs'

@Injectable()
export class CommandDevicesListModel extends ListModel<Partial<TspDeviceListData>> {
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly messageService = inject(MessageService)
  readonly selectedCommand$ = new BehaviorSubject<TspCommandListData | null>(null)
  // assigned (need to unassign) - true, or unassigned - need to assign - false
  readonly commandAssignedStatus$ = new BehaviorSubject<boolean | null>(null)
  protected readonly dateFormatService = inject(DateFormatService)

  public constructor() {
    super()
    combineLatest([this.selectedCommand$, this.commandAssignedStatus$])
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(() => {
        this.reset()
        this.loadCount()
      })
  }

  public setSelectedCommandAndStatus(command: TspCommandListData | null, status: boolean | null) {
    this.commandAssignedStatus$.next(status)
    this.selectedCommand$.next(command)
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    console.error(message)
    return checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown)
  }

  public async getAndShowMessageOnException(e: any) {
    const message = getMessageFromException(e)
    if (message) {
      console.error(message)
    }
    await this.showUnknownErrorMessage()
  }

  public getFilterValue() {
    return this.store.filter$.getValue()
  }

  public getOrderByValue() {
    return this.store.orderBy$.getValue()
  }

  public get isActiveSearch() {
    return !!this.store.search$.value
  }

  public get selectedItems() {
    return this.store.multiSelectedItems$.value
  }

  public async showUnknownErrorMessage() {
    const message = await this.languageService.translate(getApiErrorKey(ApiErrors.unknown))
    this.messageService.add({
      severity: 'error',
      detail: message,
      life: 2000,
    })
  }

  protected config(): ListModelConfig {
    return {
      name: 'CommandDevicesListModel',
      defaultOrderColumn: 'name',
      defaultOrderDirection: 'ASC',
      loadCount: () => this.loadCount(),
      isMultiSelectable: true,
      keyColumn: 'id',
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.devices.grid.device-name',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'identifier',
          label: 'tsp-admin.devices.grid.imei-serial',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'fleetName',
          label: 'tsp-admin.devices.grid.fleet',
          type: DataGridColumnType.Text,
          sortable: true,
        },
      ],
    }
  }

  async loadCount() {
    const status = this.commandAssignedStatus$.value
    const commandId = this.selectedCommand$.value?.id
    if (!commandId || status === null) {
      return 0
    }

    if (status) {
      return this.api.tsp.devices.countAssignedToCommand(commandId)
    } else {
      return this.api.tsp.devices.countNotAssignedToCommand(commandId)
    }
  }

  protected async loadPage(props: DataQueryProps): Promise<Partial<TspDeviceListData>[]> {
    const status = this.commandAssignedStatus$.value
    const commandId = this.selectedCommand$.value?.id
    if (!commandId || status === null) {
      return []
    }

    let result: Record<string, any>[] = []
    if (status) {
      result = await this.api.tsp.devices.listAssignedToCommand(commandId, props)
    } else {
      result = await this.api.tsp.devices.listNotAssignedToCommand(commandId, props)
    }
    result.forEach((item) => {
      if (item.fleet) {
        item.fleetName = item.fleet.name
      }
    })
    return result
  }

  async assignCommandToDevices(commandId: string, deviceIds: string[]) {
    return this.api.tsp.commands.assignCommandToDevices(commandId, deviceIds)
  }

  async unassignCommandFromDevices(commandId: string, deviceIds: string[]) {
    return this.api.tsp.commands.unassignCommandFromDevices(commandId, deviceIds)
  }
}
