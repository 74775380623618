import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CommandFormData, TspCommandListData } from '@ti-platform/contracts'

@Component({
  selector: 'app-command-edit',
  templateUrl: 'command-edit.component.html',
  styleUrl: 'command-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandEditComponent {
  @Input() opened = false
  @Input({ required: true }) command!: TspCommandListData | null

  @Input() processing = false
  @Input() errorMessage = ''

  @Output() cancelEvent = new EventEmitter<void>()
  @Output() submitEvent = new EventEmitter<CommandFormData>()

  public async onCancel() {
    this.cancelEvent.emit()
  }

  public onSubmit(data: CommandFormData) {
    this.submitEvent.emit(data)
  }
}
