import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { TspDeviceListData } from '@ti-platform/contracts'

@Component({
  selector: 'app-device-edit',
  templateUrl: 'device-edit.component.html',
  styleUrl: 'device-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceEditComponent {
  @Input() opened = false
  @Input({ required: true }) device!: TspDeviceListData | null

  @Output() cancelEvent = new EventEmitter<void>()
  @Output() deviceEditedEvent = new EventEmitter<boolean>()
  @Output() submitWithStartCopyMediaConfigEvent = new EventEmitter<{
    result: boolean
    device: TspDeviceListData
  }>()
  @Output() copyMediaConfigEvent = new EventEmitter<TspDeviceListData>()

  public async onCancel() {
    this.cancelEvent.emit()
  }

  public onSubmit(result: boolean) {
    this.deviceEditedEvent.emit(result)
  }

  public onSubmitWithStartCopyMediaConfigEvent(event: any) {
    this.submitWithStartCopyMediaConfigEvent.emit(event)
  }

  public onCopyMediaConfigEvent(event: any) {
    this.copyMediaConfigEvent.emit(event)
  }
}
