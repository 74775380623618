@if (this.deviceService.isMobilePlatform) {
  @switch (iconStyle) {
    @case ('solid') {
      <app-icon-copy (click)="onCopy()"></app-icon-copy>
    }
    @case ('outlined') {
      <app-icon-copy-outlined (click)="onCopy()"></app-icon-copy-outlined>
    }
  }
} @else {
  <app-change-tooltip-on-click-wrapper
    [tooltip]="tooltip || ('common.copy' | translate)"
    [tooltipOnClick]="copiedTooltip || ('common.copied' | translate)"
    [tooltipPosition]="tooltipPosition"
    [clickAction]="onCopy.bind(this)"
  >
    @switch (iconStyle) {
      @case ('solid') {
        <app-icon-copy></app-icon-copy>
      }
      @case ('outlined') {
        <app-icon-copy-outlined></app-icon-copy-outlined>
      }
    }
  </app-change-tooltip-on-click-wrapper>
}
