export enum NotificationType {
  SosAlarm,
  Speeding,
  Movement,
  Ignition,
  EngineIdling,
  Towing,
  GeofenceActivity,
}

export enum NotificationTarget {
  Email = 'email',
  PushToMobile = 'push_to_mobile',
  Browser = 'browser',
}

export const TRIGGERED_ALERT_NOTIFICATIONS = [
  NotificationType.SosAlarm,
  NotificationType.Speeding,
  NotificationType.Movement,
  NotificationType.Ignition,
  NotificationType.EngineIdling,
  NotificationType.Towing,
  NotificationType.GeofenceActivity,
]
