import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-user-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M10.0007 9.16667C11.8416 9.16667 13.334 7.67428 13.334 5.83333C13.334 3.99238 11.8416 2.5 10.0007 2.5C8.1597 2.5 6.66732 3.99238 6.66732 5.83333C6.66732 7.67428 8.1597 9.16667 10.0007 9.16667Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5H16.6673ZM13.334 5.83333C13.334 7.67428 11.8416 9.16667 10.0007 9.16667C8.1597 9.16667 6.66732 7.67428 6.66732 5.83333C6.66732 3.99238 8.1597 2.5 10.0007 2.5C11.8416 2.5 13.334 3.99238 13.334 5.83333Z"
        [attr.fill]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class UserSolidIconComponent extends BaseIconComponent {}
