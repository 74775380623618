import { NgModule } from '@angular/core'
import { AppCommonModule } from '@ti-platform/web/common/common.module'
import { IconsModule } from '@ti-platform/web/ui-kit/icons/icons.module'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import {
  AlertMakerComponent,
  GeofenceFormComponent,
  GeofenceMarkerComponent,
  LocationSearchComponent,
  MapComponent,
  MapGuideComponent,
  StaticVehicleMarkerComponent,
  StopMarkerComponent,
  TilesSelectorComponent,
  TripMarkerComponent,
  VehicleMarkerComponent,
} from './components'

const COMPONENTS = [
  AlertMakerComponent,
  GeofenceFormComponent,
  GeofenceMarkerComponent,
  LocationSearchComponent,
  MapComponent,
  MapGuideComponent,
  StopMarkerComponent,
  TripMarkerComponent,
  VehicleMarkerComponent,
  TilesSelectorComponent,
  StaticVehicleMarkerComponent,
]

@NgModule({
  imports: [UiKitModule, AppCommonModule, IconsModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class MapModule {}
