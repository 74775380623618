import { HttpHeaders, HttpParams } from '@angular/common/http'
import { ApiClient } from '../services/api.client'

export interface HttpOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] }
  observe?: 'body'
  params?: HttpParams | { [param: string]: string | string[] | number | number[] | boolean }
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
}

export interface ApiResponse<T = unknown> {
  success: boolean
  message: string
  data?: T
}

export interface ApiContext {
  client: ApiClient
  config: Record<string, any>
}

export type HttpRequestHeaders = HttpHeaders | { [header: string]: string | string[] }

export type HttpRequestParams =
  | HttpParams
  | { [param: string]: string | string[] | number | number[] | boolean }

export enum RequestType {
  Get = 0,
  Post = 1,
  Put = 2,
  Patch = 3,
  Delete = 4,
}

export interface RequestBuilder<ResponseData> {
  withHeaders(headers: HttpRequestHeaders): this

  withParams(params: HttpRequestParams): this

  reportProgress(): this

  withData(data: any): this

  withCredentials(): this

  endpoint(endpoint: string): Promise<ResponseData>
}
