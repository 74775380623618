@if (model) {
  @if (model.state.selectedOption$ | async; as option) {
    <p-panel
      [ngClass]="{ 'offset-bottom': addBottomOffset }"
      class="info-panel__{{ option.placement }} panel-primary w-285 content-16 header-16 border-top"
    >
      <ng-template pTemplate="header">
        <div>
          <div class="hint flex mb-16">
            <app-icon-feedback-2
              [color]="'var(--color-gray-500)'"
              class="icon mr-8"
            ></app-icon-feedback-2>
            <div class="text-14 fw-500">
              {{ translatePrefix + '.' + option.id + '.title' | translate }}
            </div>
          </div>

          <ul class="hint-list">
            @for (itemId of option.items; track itemId) {
              <li class="list-item">
                @if ((deviceService.isMobile$ | async) === false) {
                  {{ translatePrefix + '.' + option.id + '.items.' + itemId | translate }}
                } @else {
                  {{ translatePrefix + '.' + option.id + '.mobile-items.' + itemId | translate }}
                }
              </li>
            }
          </ul>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <p-button
          class="flex text-14 fw-600 w100 mb-12"
          (onClick)="model.hideSelectedOption(doNotShowAgainRef.checked())"
        >
          {{ 'common.ok' | translate }}
        </p-button>

        <div class="checkbox-group flex">
          <p-checkbox #doNotShowAgainRef class="h--20 mr-8" [binary]="true"></p-checkbox>
          <div class="text-14">{{ 'common.do-not-show-again' | translate }}</div>
        </div>
      </ng-template>
    </p-panel>
  }
}
