import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core'
import { DateFormatType, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { injectDestroy$ } from '@ti-platform/web/common'
import { takeUntil } from 'rxjs'
import { LanguageCode } from '@ti-platform/contracts'
import { DateFormatService } from '../services'

@Pipe({
  name: 'formatDate',
  pure: false,
})
export class FormatDatePipe implements PipeTransform {
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly dateFormatService = inject(DateFormatService)

  protected lastValue?: Date | string | number
  protected lastLocale?: LanguageCode | null
  protected lastResult?: string

  protected format?: DateFormatType

  public constructor() {
    this.languageService.current$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      if (lang && this.lastLocale !== lang) {
        this.lastLocale = lang
        this.updateValue()
      }
    })
  }

  public transform(value: Date | string | number | undefined, format: DateFormatType): string {
    if (!value) {
      return ''
    }
    const locale = this.languageService.current()
    if (this.lastValue !== value || this.format !== format || this.lastLocale !== locale) {
      this.format = format
      this.lastValue = value
      this.lastLocale = this.languageService.current()
      this.updateValue()
    }
    return this.lastResult ?? ''
  }

  protected updateValue() {
    if (this.lastValue && this.format) {
      const promise = this.dateFormatService.formatDate(this.lastValue, this.format)
      promise.then((result) => {
        this.lastResult = result
        this.changeDetector.markForCheck()
      })
    }
  }
}
