import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-arrow-block-solid',
  template: `
    <svg
      [class.rotated-180]="direction === 'left'"
      [class.rotated-90]="direction === 'down'"
      [class.rotated--90]="direction === 'up'"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_114_20462)">
        <g filter="url(#filter0_d_114_20462)">
          <path
            d="M2.92503 21.0671C2.34956 20.4917 2.22375 19.5932 2.61882 18.8821L6.44219 12L2.61882 5.11795C2.22372 4.40676 2.34956 3.50835 2.9248 2.93311C3.50814 2.34977 4.38777 2.22067 5.11391 2.61165L21.5913 11.4841C21.6844 11.5343 21.7622 11.6087 21.8165 11.6996C21.8707 11.7904 21.8994 11.8942 21.8994 12C21.8994 12.1058 21.8707 12.2096 21.8165 12.3005C21.7622 12.3913 21.6844 12.4657 21.5913 12.5159L5.11385 21.3884C4.38777 21.7793 3.50814 21.6502 2.92503 21.0671Z"
            [attr.fill]="color"
            style="fill-opacity:1;"
          />
          <path
            d="M2.18174 18.6392L2.61882 18.8821L2.18173 18.6392C1.67824 19.5456 1.83818 20.6874 2.57147 21.4207C3.31072 22.1599 4.43041 22.3243 5.3509 21.8286L21.8283 12.9562L21.8284 12.9561C22.001 12.8631 22.1452 12.7251 22.2458 12.5568C22.3463 12.3885 22.3994 12.1961 22.3994 12C22.3994 11.8039 22.3463 11.6115 22.2458 11.4432C22.1452 11.2749 22.001 11.1369 21.8284 11.0439L21.8283 11.0439L5.35096 2.17141L5.35095 2.17141C4.43037 1.67573 3.31069 1.84011 2.57125 2.57955C1.83815 3.31265 1.67823 4.45443 2.18173 5.36077L2.18174 5.36078L5.87021 12L2.18174 18.6392Z"
            stroke="white"
            style="stroke:white;stroke-opacity:1;"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_114_20462"
          x="-54.6094"
          y="-54.6125"
          width="133.508"
          height="133.225"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_114_20462"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="30" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_20462" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_114_20462"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rotated-180 {
        transform: rotate(180deg);
      }
      .rotated-90 {
        transform: rotate(90deg);
      }
      .rotated--90 {
        transform: rotate(-90deg);
      }
    `,
  ],
})
export class ArrowBlockSolidIconComponent extends BaseIconComponent {
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'right'
}
