import {
  FleetAlertSettings,
  FleetReportSettings,
  MeasurementSystemConfig,
} from '@ti-platform/contracts'
import { ApiRoute } from '../../../services/api.route'

export class SettingsRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/settings'
  }

  public update(data: UpdateFleetSettingsData) {
    return this.post().withData(data).endpoint(`update`)
  }

  public patchAlertSettings(data: Partial<FleetAlertSettings>) {
    return this.put().withData(data).endpoint(`patch-alert-settings`)
  }

  public patchReportSettings(data: Partial<FleetReportSettings>) {
    return this.put().withData(data).endpoint(`patch-report-settings`)
  }
}

interface UpdateFleetSettingsData {
  measurementSystem?: MeasurementSystemConfig
  address?: string
  city?: string
  region?: string
  zipCode?: string
  matchGeofenceNames?: boolean
}
