import { inject, Injectable } from '@angular/core'
import { LanguageCode } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

@Injectable()
export class TspAdminLanguageService extends LanguageService {
  protected readonly api = inject(ApiService)

  protected override async saveCurrentUserLanguage(language: LanguageCode) {
    if (await this.authSession.isLoggedIn()) {
      await this.api.tsp.user.setLanguage(language)
    }
  }
}
