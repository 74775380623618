import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core'
import { takeUntil } from 'rxjs'
import { Action, Resource } from '@ti-platform/contracts'
import { AccessControl, injectDestroy$ } from '@ti-platform/web/common'

@Directive({
  selector: '[appCheckAccess]',
})
export class CheckAccessDirective implements AfterViewInit {
  protected readonly accessControl = inject(AccessControl)
  protected readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
  protected readonly destroy$ = injectDestroy$()

  @Input() public appCheckAccess?: [Resource, Action]

  public ngAfterViewInit() {
    if (this.appCheckAccess?.[0] && this.appCheckAccess?.[1]) {
      this.accessControl
        .check$(this.appCheckAccess[0], this.appCheckAccess[1])
        .pipe(takeUntil(this.destroy$))
        .subscribe((isAllowed) => {
          isAllowed
            ? this.elementRef.nativeElement.classList.remove('not-allowed')
            : this.elementRef.nativeElement.classList.add('not-allowed')
        })
    }
  }
}
