import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-launch',
  template: `
    <svg
      [attr.width]="20"
      [attr.height]="20"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2482 10.603L9.74906 14.2023C9.41734 14.4677 8.93914 14.4412 8.63876 14.1408L6.3593 11.8614C6.05892 11.561 6.03243 11.0828 6.2978 10.7511L9.89714 6.25192C11.543 4.19458 14.0348 2.99695 16.6695 2.99695V2.99695C17.1299 2.99695 17.5032 3.3702 17.5032 3.83063V3.83063C17.5032 6.46531 16.3056 8.95714 14.2482 10.603Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3348 11.3337V14.9869C13.3348 15.3027 13.1564 15.5914 12.8739 15.7326L10.8598 16.7397C10.6437 16.8478 10.3912 16.8567 10.168 16.7642C9.94468 16.6717 9.77252 16.4869 9.69609 16.2576L9.16638 14.6685"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83166 11.3337L4.24253 10.804C4.01326 10.7275 3.82843 10.5554 3.73595 10.3321C3.64346 10.1088 3.65242 9.85641 3.7605 9.64025L4.76758 7.62613C4.90879 7.34371 5.19744 7.16528 5.51319 7.16528H9.16638"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.78965 17.6587L2.49695 18.0032L2.84138 15.7104C2.95312 14.9667 3.53697 14.3828 4.28072 14.2711V14.2711C4.81818 14.1903 5.36207 14.3695 5.74638 14.7538C6.13069 15.1381 6.3098 15.682 6.22904 16.2194V16.2194C6.11728 16.9632 5.53341 17.547 4.78965 17.6587V17.6587Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class LaunchIconComponent extends BaseIconComponent {
  @Input() override height = 20
  @Input() override width = 20
}
