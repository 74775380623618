import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseIconComponent {
  @Input() width: string | number = 24
  @Input() height: string | number = 24
  @Input() color = 'currentColor'
}
