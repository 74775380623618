import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-time-idling',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12.391H12.391V7"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9414 11C20.4187 6.31796 16.373 2.83169 11.6651 3.00628C6.95715 3.18087 3.18084 6.95721 3.00628 11.6651C2.83172 16.3731 6.31801 20.4187 11.0001 20.9415"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="13"
        y="13"
        width="9"
        height="9"
        rx="4.5"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <g clip-path="url(#clip0_2711_593)">
        <path
          d="M16.1875 17.5H18.8125"
          stroke="white"
          style="stroke:white;stroke-opacity:1;"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TimeIdlingIconComponent extends BaseIconComponent {
  @Input() override width = 24
  @Input() override height = 24
}
