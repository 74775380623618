import {
  DateStyle,
  LanguageCode,
  MeasurementSystemConfig,
  SeparatorType,
} from '@ti-platform/contracts'

export enum UnitType {
  Distance = 'distance',
  FuelEfficiency = 'fuel-efficiency',
  Pressure = 'pressure',
  Speed = 'speed',
  Temperature = 'temperature',
  Volume = 'volume',
  Voltage = 'voltage',
}

export enum FormatDurationStyle {
  Fleet = 'fleet',
  Alert = 'alert',
  Clock = 'clock',
}

export interface I18NConfig {
  lang: LanguageCode
  decimalSeparator: SeparatorType
  dateStyle: DateStyle
  measurementSystem: MeasurementSystemConfig
}

export interface FormatUnitTypeOptions {
  addLabel?: boolean
  precision?: number
  commaSeparated?: boolean
  usePreciseIfLess?: number | null
}
