import { Component } from '@angular/core';
import { BaseIconComponent } from '../base-icon.component';

@Component({
  selector: 'app-icon-plus',
  template: `
    <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5H13ZM11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19H11ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13V11ZM19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11V13ZM11 5V19H13V5H11ZM5 13H19V11H5V13Z" style="fill-opacity:1;"
            [attr.fill]="color" />
    </svg>

  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `]
})
export class PlusIconComponent extends BaseIconComponent {}
