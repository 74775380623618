import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-cog',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5_3986)">
        <path
          d="M12.091 8.90901C12.9697 9.7877 12.9697 11.2123 12.091 12.091C11.2123 12.9697 9.7877 12.9697 8.90901 12.091C8.03033 11.2123 8.03033 9.7877 8.90901 8.90901C9.7877 8.03033 11.2123 8.03033 12.091 8.90901"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.87505 10.5C4.87505 10.7475 4.89755 10.995 4.92755 11.235L3.60422 12.27C3.31089 12.5 3.23089 12.9108 3.41755 13.2333L4.59422 15.2692C4.78005 15.5917 5.17505 15.7275 5.52089 15.5892L6.70589 15.1133C6.94005 15.0192 7.20089 15.0567 7.41089 15.1958C7.59422 15.3175 7.78422 15.4292 7.98089 15.5292C8.20589 15.6433 8.36922 15.8475 8.40505 16.0975L8.58589 17.3583C8.63839 17.7267 8.95422 18 9.32589 18H11.6734C12.0451 18 12.3609 17.7267 12.4134 17.3583L12.5942 16.0983C12.6301 15.8483 12.7951 15.6425 13.0209 15.5292C13.2167 15.4308 13.4059 15.32 13.5884 15.1992C13.8001 15.0592 14.0617 15.0192 14.2967 15.1142L15.4792 15.5892C15.8242 15.7275 16.2192 15.5917 16.4059 15.2692L17.5826 13.2333C17.7692 12.9108 17.6892 12.4992 17.3959 12.27L16.0726 11.235C16.1026 10.995 16.1251 10.7475 16.1251 10.5C16.1251 10.2525 16.1026 10.005 16.0726 9.765L17.3959 8.73C17.6892 8.5 17.7692 8.08917 17.5826 7.76667L16.4059 5.73083C16.2201 5.40833 15.8251 5.2725 15.4792 5.41083L14.2967 5.88583C14.0617 5.98 13.8001 5.94083 13.5884 5.80083C13.4059 5.68 13.2167 5.56917 13.0209 5.47083C12.7951 5.3575 12.6301 5.15167 12.5942 4.90167L12.4142 3.64167C12.3617 3.27333 12.0459 3 11.6742 3H9.32672C8.95505 3 8.63922 3.27333 8.58672 3.64167L8.40505 4.90333C8.36922 5.1525 8.20505 5.3575 7.98089 5.47167C7.78422 5.57167 7.59422 5.68417 7.41089 5.805C7.20005 5.94333 6.93922 5.98083 6.70505 5.88667L5.52089 5.41083C5.17505 5.2725 4.78005 5.40833 4.59422 5.73083L3.41755 7.76667C3.23089 8.08917 3.31089 8.50083 3.60422 8.73L4.92755 9.765C4.89755 10.005 4.87505 10.2525 4.87505 10.5V10.5Z"
          [attr.stroke]="color"
          style="stroke-opacity:1;"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CogIconComponent extends BaseIconComponent {}
