import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-duration-dialog',
  templateUrl: 'duration-dialog.component.html',
  styleUrl: 'duration-dialog.component.scss',
})
export class DurationDialogComponent {
  @Output() readonly durationChange = new EventEmitter<number>()
  @Output() readonly closed = new EventEmitter<void>()

  protected readonly deviceService = inject(DeviceService)

  @Input() caption?: string
  @Input() description?: string
  @Input() visible = false
  @Input() dissmisable?: boolean = true
  @Input() modal?: boolean = true

  protected _duration?: number

  set duration(value: number) {
    this._duration = value
  }
}
