import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-trip-driving-time',
  template: `
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        width="48"
        height="48"
        rx="24"
        fill="#F9F5FF"
        style="fill:#F9F5FF;fill:color(display-p3 0.9765 0.9608 1.0000);fill-opacity:1;"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.745 16.8234C18.108 15.7345 19.127 15 20.2749 15H28.0585C29.2063 15 30.2253 15.7345 30.5883 16.8234L31.5713 19.7724L32.0327 19.506C32.3516 19.3219 32.7593 19.4311 32.9434 19.75C33.1275 20.0689 33.0182 20.4766 32.6994 20.6607L32.0017 21.0635L32.1204 21.4197C33.1384 21.987 33.8282 23.0725 33.8333 24.3194C33.3389 23.8267 32.7539 23.4247 32.1056 23.1406C31.7411 22.6507 31.1576 22.3333 30.5 22.3333H17.8333C16.7288 22.3333 15.8333 23.2288 15.8333 24.3333V28.3333C15.8333 29.0697 16.4303 29.6667 17.1667 29.6667C17.903 29.6667 18.5 29.0697 18.5 28.3333V28C18.5 27.6318 18.7985 27.3333 19.1667 27.3333H24.2546C24.1968 27.6579 24.1667 27.9921 24.1667 28.3333C24.1667 28.4452 24.1699 28.5564 24.1763 28.6667H19.8127C19.6487 29.9821 18.5265 31 17.1667 31C15.6939 31 14.5 29.8061 14.5 28.3333V24.3333C14.5 23.0805 15.1911 21.9891 16.2129 21.4197L16.3209 21.0959L15.567 20.6607C15.2481 20.4766 15.1389 20.0689 15.323 19.75C15.5071 19.4311 15.9148 19.3219 16.2337 19.506L16.7512 19.8048L17.745 16.8234ZM29.3234 17.245L30.575 21H17.7583L19.0099 17.245C19.1914 16.7006 19.7009 16.3333 20.2749 16.3333H28.0585C28.6324 16.3333 29.1419 16.7006 29.3234 17.245Z"
        fill="#7F56D9"
        style="fill-opacity:1;"
      />
      <path
        d="M20.1666 25.6663C20.5348 25.6663 20.8333 25.3679 20.8333 24.9997C20.8333 24.6315 20.5348 24.333 20.1666 24.333H18.8333C18.4651 24.333 18.1666 24.6315 18.1666 24.9997C18.1666 25.3679 18.4651 25.6663 18.8333 25.6663H20.1666Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M30.4065 29.8455C30.6894 30.0812 31.1097 30.043 31.3454 29.7601C31.5812 29.4773 31.5429 29.0569 31.2601 28.8212L30.5 28.1877V26.6667C30.5 26.2985 30.2015 26 29.8333 26C29.4651 26 29.1666 26.2985 29.1666 26.6667V28.5C29.1666 28.6979 29.2545 28.8855 29.4065 29.0121L30.4065 29.8455Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1666 28.3337C25.1666 25.7563 27.256 23.667 29.8333 23.667C32.4106 23.667 34.5 25.7563 34.5 28.3337C34.5 30.911 32.4106 33.0003 29.8333 33.0003C27.256 33.0003 25.1666 30.911 25.1666 28.3337ZM29.8333 25.0003C27.9924 25.0003 26.5 26.4927 26.5 28.3337C26.5 30.1746 27.9924 31.667 29.8333 31.667C31.6742 31.667 33.1666 30.1746 33.1666 28.3337C33.1666 26.4927 31.6742 25.0003 29.8333 25.0003Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TripDrivingTimeIconComponent extends BaseIconComponent {}
