import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, takeUntil } from 'rxjs'
import { BrowserLocalStorage, injectDestroy$, Memoize } from '@ti-platform/web/common'

export interface MapGuideOption {
  id: string
  items: string[]
  mobileItems: string[]
  placement: string
}

export enum MapGuideOptions {
  AddLocation = 'add-location',
  EditGeofence = 'edit-geofence',
  SavingAddress = 'saving-address',
  AddingCircleGeofence = 'adding-circle-geofence',
  EditingCircleGeofence = 'editing-circle-geofence',
  AddingPolygonGeofence = 'adding-polygon-geofence',
  EditingPolygonGeofence = 'editing-polygon-geofence',
  GeofenceToggleIntro = 'geofence-toggle-intro',
}

const GUIDE_OPTIONS: MapGuideOption[] = [
  {
    id: MapGuideOptions.AddLocation, // Show after `Add new location` button is clicked
    items: ['0'],
    mobileItems: ['0'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.EditGeofence, // Show after location is clicked in the grid
    items: ['0'],
    mobileItems: ['0'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.SavingAddress, // Show when address Pin is added
    items: ['0'],
    mobileItems: ['0'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.AddingCircleGeofence, // Show when editing new geofence / circle
    items: ['0'],
    mobileItems: ['0'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.EditingCircleGeofence, // Show when editing existing geofence / circle
    items: ['0'],
    mobileItems: ['0'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.AddingPolygonGeofence, // Show when new existing geofence / polygon
    items: ['0', '1'],
    mobileItems: ['0', '1'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.EditingPolygonGeofence, // Show when editing existing geofence / polygon
    items: ['0', '1'],
    mobileItems: ['0', '1'],
    placement: 'top-right',
  },
  {
    id: MapGuideOptions.GeofenceToggleIntro, // Show by default on the fleet page
    items: ['0', '1'],
    mobileItems: ['0', '1'],
    placement: 'bottom-left',
  },
]

@Injectable()
export class MapGuideModel {
  protected readonly localStorage = inject(BrowserLocalStorage)
  protected readonly destroy$ = injectDestroy$()

  protected readonly store = {
    selectedOptionId$: new BehaviorSubject<string | void>(undefined),
    disabledOptionIds$: new BehaviorSubject<string[]>([]),
  }

  protected readonly supportedOptions: MapGuideOption[] = GUIDE_OPTIONS
  protected readonly DISABLED_OPTIONS_STORAGE_KEY = 'TI_MAP_GUIDE_DISABLED_OPTIONS'

  public constructor() {
    Promise.resolve()
      .then(() => this.init())
      .catch((error) => console.error(`Cannot init MapGuideModel`, error))
  }

  @Memoize()
  public get state() {
    return {
      selectedOptionId$: this.store.selectedOptionId$.asObservable(),
      selectedOption$: this.createSelectedOptionStream(),
    }
  }

  public showOption(optionId: MapGuideOptions) {
    this.store.selectedOptionId$.next(optionId)
  }

  public hideSelectedOption(disable?: boolean) {
    const selectedOptionId = this.store.selectedOptionId$.value
    this.store.selectedOptionId$.next(undefined)
    if (selectedOptionId && disable) {
      this.disableOption(selectedOptionId)
    }
  }

  protected disableOption(optionId: string) {
    this.store.disabledOptionIds$.value.push(optionId)
    this.localStorage.setItem(
      this.DISABLED_OPTIONS_STORAGE_KEY,
      JSON.stringify(this.store.disabledOptionIds$.value),
    )
  }

  protected init() {
    const disabledOptionsSerialized = this.localStorage.getItem(this.DISABLED_OPTIONS_STORAGE_KEY)
    if (disabledOptionsSerialized) {
      const disabledOptions = JSON.parse(disabledOptionsSerialized)
      if (Array.isArray(disabledOptions) && disabledOptions.length) {
        this.store.disabledOptionIds$.next(disabledOptions)
      }
    }
  }

  protected createSelectedOptionStream(): Observable<MapGuideOption | undefined> {
    return combineLatest([this.store.selectedOptionId$, this.store.disabledOptionIds$]).pipe(
      map(([selectedId, disabledItemIds]) => {
        return selectedId && !disabledItemIds.includes(selectedId)
          ? this.supportedOptions.find((item) => item.id === selectedId)
          : undefined
      }),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: true }),
    )
  }
}
