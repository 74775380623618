import { Provider } from '@angular/core'
import { TspAdminLanguageService } from './language.service'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'

export * from './language.service'

export const LANG_PROVIDERS: Provider[] = [
  {
    provide: LanguageService,
    useClass: TspAdminLanguageService,
  },
]
