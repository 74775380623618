import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-truck-cargo',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12.4191L18.8397 8.45941C18.6995 7.5883 18.002 6.91377 17.1267 6.80285C13.7191 6.42932 10.2809 6.42932 6.87327 6.80285C5.99795 6.91377 5.30044 7.5883 5.16027 8.45941L4.5 12.419"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2637 18.4341V21.0001C19.2637 21.5524 18.816 22.0001 18.2637 22.0001H16.2637C15.7114 22.0001 15.2637 21.5524 15.2637 21.0001V19.5001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.73633 19.5001V21.0001C8.73633 21.5524 8.28861 22.0001 7.73633 22.0001H5.73633C5.18404 22.0001 4.73633 21.5524 4.73633 21.0001V18.4341"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.884 16H15"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 16H7"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5 12C15.2368 13.3333 8.76316 13.3333 2.5 12"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="4.5"
        y="2.5"
        width="15"
        height="17"
        rx="2"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TruckCargoIconComponent extends BaseIconComponent {}
