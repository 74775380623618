import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { VehicleStatus } from '../../../contracts'

@Component({
  selector: 'app-map-vehicle-marker',
  templateUrl: 'vehicle-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleMarkerComponent {
  protected readonly HEADING_OFFSET_INITIAL = 8
  protected readonly HEADING_OFFSET_MEDIUM = 18
  protected readonly HEADING_OFFSET_LARGE = 28

  @Input() name!: string
  @Input() color!: string
  @Input() icon!: string
  @Input() status!: VehicleStatus
  @Input() statusInvalid = false
  @Input() directionDeg = 0
  @Input() isLightMode = true

  public get headingOffset(): string {
    let headingOffset
    if (this.status === VehicleStatus.Running) {
      headingOffset =
        this.status === VehicleStatus.Running
          ? this.directionDeg < 40 || this.directionDeg > 330
            ? this.HEADING_OFFSET_LARGE
            : this.directionDeg < 60 || this.directionDeg > 300
              ? this.HEADING_OFFSET_MEDIUM
              : this.HEADING_OFFSET_INITIAL
          : this.HEADING_OFFSET_INITIAL
    } else {
      headingOffset = this.HEADING_OFFSET_INITIAL
    }
    return `${-headingOffset}px`
  }

  public get directionAngleDeg(): string {
    return `${this.directionDeg}deg`
  }

  protected readonly VehicleStatus = VehicleStatus
}
