import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-bus-2',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5015 19.003H6.49693C5.39147 19.003 4.49609 18.1076 4.49609 17.0022V4.99717C4.49609 3.89171 5.39147 2.99634 6.49693 2.99634H17.5015C18.607 2.99634 19.5023 3.89171 19.5023 4.99717V17.0022C19.5023 18.1076 18.607 19.003 17.5015 19.003Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.49609 16.0017H8.49651"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 16.0017H16.5004"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.49609 13.0005H19.5023"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0025 10.9997V7.99846C22.0025 7.44623 21.5543 6.99805 21.0021 6.99805H2.99456C2.44233 6.99805 1.99414 7.44623 1.99414 7.99846V10.9997"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.23367 19.0031V20.5037C8.23367 21.0559 7.78549 21.5041 7.23326 21.5041H6.23284C5.68061 21.5041 5.23242 21.0559 5.23242 20.5037V18.5469"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.7649 18.5469V20.5037C18.7649 21.0559 18.3167 21.5041 17.7645 21.5041H16.7641C16.2119 21.5041 15.7637 21.0559 15.7637 20.5037V19.0031"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class Bus2IconComponent extends BaseIconComponent {}
