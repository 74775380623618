import { Injectable, inject } from '@angular/core'
import { PricingPlans } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { Memoize } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { Observable } from 'rxjs'

@Injectable()
export class PricingPlansProvider {
  protected readonly api = inject(ApiService)
  protected readonly languageService = inject(LanguageService)

  @Memoize()
  get pricingPlansLabels$(): Observable<Record<PricingPlans, string>> {
    const roleList = Object.values(PricingPlans)
    const labelsMap: Record<number, string> = {}
    roleList.forEach((planId) => {
      labelsMap[planId as number] = `tsp-admin.fleets.pricing-plans.${planId}`
    })
    return this.languageService.massTranslate$(labelsMap)
  }
}
