import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core'
import { debounceTime, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AutoComplete } from 'primeng/autocomplete'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { LocationPlace, LocationSuggestion } from '@ti-platform/contracts'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MapAdapter } from '../../adapters'

@Component({
  selector: 'app-map-location-search',
  templateUrl: 'location-search.component.html',
  styleUrls: ['location-search.component.scss'],
})
export class LocationSearchComponent {
  @Output() cleared = new EventEmitter<void>()
  @Output() placeSelected = new EventEmitter<LocationPlace>()

  @ViewChild('autoCompleteRef')
  protected readonly autocompleteRef!: AutoComplete

  protected readonly api = inject(ApiService)
  protected readonly map = inject(MapAdapter)
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  protected searchCriteria$ = new Subject<string>()

  public clear() {
    this.autocompleteRef.clear()
    this.cleared.emit()
  }

  @Memoize()
  protected get suggestions$(): Observable<LocationSuggestion[]> {
    return this.searchCriteria$.pipe(
      debounceTime(25),
      withLatestFrom(this.languageService.current$),
      switchMap(async ([criteria, language]) => {
        try {
          return await this.api.fleet.locations.autocomplete(
            criteria,
            language ?? 'en',
            this.map.getCenter(),
          )
        } catch (error) {
          console.warn(`Cannot load autocomplete suggestions`, error)
          return []
        }
      }),
      takeUntil(this.destroy$),
    )
  }

  protected async onLocationSelected(suggestion: LocationSuggestion) {
    if (!suggestion.placeId) {
      console.warn('The `placeId` is missing in the location', suggestion)
      return undefined
    }

    const place = await this.api.fleet.locations.getPlaceDetails(suggestion.placeId)
    if (place) {
      this.placeSelected.emit(place)
    }
  }
}
