import { Component, inject, OnInit, signal } from '@angular/core'
import { DeviceService, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { BrandingService } from '@ti-platform/web/ui-kit/services/branding.service'

@Component({
  selector: 'app-pwa-install-guide',
  templateUrl: 'pwa-install-guide.component.html',
  styleUrl: 'pwa-install-guide.component.scss',
})
export class PwaInstallGuideComponent implements OnInit {
  protected readonly isOpened = signal(false)
  protected readonly step = signal(1)
  protected readonly languageService = inject(LanguageService)
  protected readonly deviceService = inject(DeviceService)
  protected readonly brandingService = inject(BrandingService)
  protected baseUrl = signal('')

  public ngOnInit() {
    this.initializeBaseUrl()
  }

  public open() {
    this.isOpened.set(true)
  }

  public close() {
    this.isOpened.set(false)
    this.step.set(1)
  }

  protected stepperFunction(stepNumber: number) {
    this.step.set(stepNumber)
  }

  protected initializeBaseUrl() {
    const fullUrl = document.location.href
    const url = new URL(fullUrl)
    this.baseUrl.set(`${url.protocol}//${url.host}/`)
  }
}
