<div class="avatar avatar--{{ size }} flex">
  @if (photo) {
    <img [src]="photo" alt />
  } @else {
    @if (iconTemplate) {
      <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
    } @else {
      <app-icon-user color="var(--icon-color)"></app-icon-user>
    }
  }
</div>
