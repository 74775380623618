import { inject, Injectable } from '@angular/core'
import { ResolveEnd, Router } from '@angular/router'
import { BehaviorSubject, takeUntil } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'

@Injectable({
  providedIn: 'root',
})
export class FilterPanelService {
  public readonly isPanelOpened$ = new BehaviorSubject(false)

  protected readonly router = inject(Router)
  protected readonly destroy$ = injectDestroy$()

  public constructor() {
    Promise.resolve().then(() => this.initialize())
  }

  public openPanel() {
    if (!this.isPanelOpened$.value) {
      this.isPanelOpened$.next(true)
    }
  }

  public closePanel() {
    if (this.isPanelOpened$.value) {
      this.isPanelOpened$.next(false)
    }
  }

  protected initialize() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof ResolveEnd) {
        this.closePanel()
      }
    })

    const closeListener = () => this.closePanel()
    this.isPanelOpened$.pipe(takeUntil(this.destroy$)).subscribe((isOpened) => {
      if (isOpened) {
        setTimeout(() => document.body.addEventListener('click', closeListener), 125)
      } else {
        document.body.removeEventListener('click', closeListener)
      }
    })
  }
}
