import { Component, inject, Input } from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MapGuideModel } from '../../services/guide-model'

@Component({
  selector: 'app-map-guide',
  templateUrl: 'map-guide.component.html',
  styleUrls: ['map-guide.component.scss'],
})
export class MapGuideComponent {
  protected readonly translatePrefix = 'map.guide'
  protected readonly deviceService = inject(DeviceService)
  protected readonly model = inject(MapGuideModel, { optional: true })

  @Input() addBottomOffset? = false
}
