import { ApiRoute } from '../../../services/api.route'
import { DomainAssociation, WhiteLabelSettings } from '@ti-platform/contracts'

export class WhiteLabelRoute extends ApiRoute {
  public override init() {
    this.route = 'tsp/white-label'
  }

  public getSettings() {
    return this.get<WhiteLabelSettings>().endpoint('settings')
  }

  public setDomain(domain: string) {
    return this.post().withData({ domain }).endpoint(`set-domain`)
  }

  public getDomainStatus() {
    return this.get<DomainAssociation>().endpoint(`get-domain-status`)
  }

  public uploadLargeLogo(base64: string, contentType: string) {
    return this.post().withData({ base64, contentType }).endpoint(`set-large-logo-base-64`)
  }

  public uploadCompactLogo(base64: string, contentType: string) {
    return this.post().withData({ base64, contentType }).endpoint(`set-compact-logo-base-64`)
  }

  public removeLargeLogo() {
    return this.post().endpoint(`remove-large-logo`)
  }

  public removeCompactLogo() {
    return this.post().endpoint(`remove-compact-logo`)
  }

  public setGoogleMapsKey(googleMapsKey: string) {
    return this.post().withData({ googleMapsKey }).endpoint(`set-google-maps-key`)
  }
}
