@if (vehicle.telemetry.ignitionStatus === undefined) {
  <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
} @else {
  <div
    class="indicator ignition on"
    [class.on]="vehicle.telemetry.ignitionStatus && !vehicle.telemetry.lastStatusInvalid"
    [pTooltip]="
      vehicle.telemetry.ignitionStatus && vehicle.telemetry.lastStatusInvalid
        ? ('fleet.message.may-not-be-current' | translate)
        : ''
    "
    [tooltipPosition]="'bottom'"
  >
    <i class="show-on--desktop">
      <app-icon-ignition
        [width]="32"
        [height]="32"
        [color]="'var(--indicator-icon-color)'"
      ></app-icon-ignition>
    </i>
    <div class="text-group flex--col">
      <div class="status">
        <app-icon-ignition
          class="icon show-on--mobile"
          [color]="'var(--indicator-icon-color)'"
          [height]="16"
          [width]="16"
        ></app-icon-ignition>

        @switch (vehicle.telemetry.ignitionStatus) {
          @case (true) {
            {{ 'fleet.status.on' | translate }}
          }
          @case (false) {
            {{ 'fleet.status.off' | translate }}
          }
          @default {
            {{ 'fleet.status.na' | translate }}
          }
        }
      </div>
      <div class="type">{{ 'fleet.indicator.ignition' | translate }}</div>
    </div>
  </div>
}

@if (!ALPHA_1_MODE) {
  @if ((vehicle.telemetry.fuelLevel || vehicle.telemetry.fuelVolume) !== undefined) {
    <div class="indicator fuel-level">
      <i class="show-on--desktop">
        <app-icon-fuel-level
          [width]="32"
          [height]="32"
          [color]="'var(--indicator-icon-color)'"
        ></app-icon-fuel-level>
      </i>
      <div class="text-group flex--col">
        <div class="status">
          <app-icon-fuel-level
            class="icon show-on--mobile"
            [color]="'var(--indicator-icon-color)'"
            [height]="16"
            [width]="16"
          ></app-icon-fuel-level>

          {{ vehicle.telemetry.fuelLevel }}
          <span *ngIf="vehicle.telemetry.fuelLevel">%</span>
          <span *ngIf="vehicle.telemetry.fuelLevel && vehicle.telemetry.fuelVolume">|</span>
          {{ vehicle.telemetry.fuelVolume | formatUnit: UnitType.Volume }}
        </div>
        <div class="type">{{ 'fleet.indicator.fuel-level' | translate }}</div>
      </div>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
  }

  @if (vehicle.telemetry.engineRPM !== undefined) {
    <div class="indicator rpm">
      <i class="show-on--desktop">
        <app-icon-rpm
          [width]="32"
          [height]="32"
          [color]="'var(--indicator-icon-color)'"
        ></app-icon-rpm>
      </i>
      <div class="text-group flex--col">
        <app-icon-rpm
          class="icon show-on--mobile"
          [color]="'var(--indicator-icon-color)'"
          [height]="16"
          [width]="16"
        ></app-icon-rpm>

        <div class="status">{{ vehicle.telemetry.engineRPM }}</div>
        <div class="type">RPM</div>
      </div>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
  }

  @if (vehicle.telemetry.tirePressure !== undefined) {
    <div class="indicator psi">
      <i class="show-on--desktop">
        <app-icon-tpms
          [width]="32"
          [height]="32"
          [color]="'var(--indicator-icon-color)'"
        ></app-icon-tpms>
      </i>
      <div class="text-group flex--col">
        <div class="status">
          <app-icon-tpms
            class="icon show-on--mobile"
            [color]="'var(--indicator-icon-color)'"
            [height]="16"
            [width]="16"
          ></app-icon-tpms>

          {{ vehicle.telemetry.tirePressure }}
        </div>
        <div class="type">PSI</div>
      </div>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
  }

  @if (vehicle.telemetry.engineHours !== undefined) {
    <div class="indicator engine-hours">
      <i class="show-on--desktop">
        <app-icon-engine-hours
          [width]="32"
          [height]="32"
          [color]="'var(--indicator-icon-color)'"
        ></app-icon-engine-hours>
      </i>
      <div class="text-group flex--col">
        <div class="status">
          <app-icon-engine-hours
            class="icon show-on--mobile"
            [color]="'var(--indicator-icon-color)'"
            [height]="16"
            [width]="16"
          ></app-icon-engine-hours>

          @if (vehicle.telemetry.ignitionStatus !== null) {
            {{ vehicle.telemetry.engineHours | formatCommaSeparated }}
          } @else {
            {{ 'fleet.status.na' | translate }}
          }
        </div>
        <app-ticker>
          <div class="type">
            {{ 'fleet.indicator.engine-hours' | translate }}
          </div>
        </app-ticker>
      </div>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
  }
}

@if (vehicle.telemetry.odometer !== undefined) {
  <div class="indicator odometer">
    <i class="show-on--desktop">
      <app-icon-mileage
        [width]="32"
        [height]="32"
        [color]="'var(--indicator-icon-color)'"
      ></app-icon-mileage>
    </i>
    <div class="text-group flex--col">
      <div class="status">
        <app-icon-mileage
          class="icon show-on--mobile"
          [color]="'var(--indicator-icon-color)'"
          [height]="16"
          [width]="16"
        ></app-icon-mileage>

        @if (vehicle.telemetry.odometer !== null) {
          {{ vehicle.telemetry.odometer | formatUnit: UnitType.Distance }}
        } @else {
          {{ 'fleet.status.na' | translate }}
        }
      </div>
      <div class="type">{{ 'fleet.indicator.odometer' | translate }}</div>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
}

@if (vehicle.telemetry.battery !== undefined) {
  <div class="indicator engine-hours">
    <i class="show-on--desktop">
      <app-icon-battery
        [width]="32"
        [height]="32"
        [color]="'var(--indicator-icon-color)'"
      ></app-icon-battery>
    </i>
    <div class="text-group flex--col">
      <div class="status">
        <app-icon-battery
          class="icon show-on--mobile"
          [color]="'var(--indicator-icon-color)'"
          [height]="16"
          [width]="16"
        ></app-icon-battery>

        @if (vehicle.telemetry.battery !== null) {
          {{ vehicle.telemetry.battery.toFixed(1) }} V
        } @else {
          {{ 'fleet.status.na' | translate }}
        }
      </div>
      <app-ticker>
        <div class="type">
          {{ 'fleet.indicator.battery' | translate }}
        </div>
      </app-ticker>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="IndicatorsSkeletonSingleTmpl"></ng-container>
}

<ng-template #IndicatorsSkeletonSingleTmpl>
  <div class="indicator-skeleton">
    <p-skeleton animation="wave" height="100%" width="100%"></p-skeleton>
  </div>
</ng-template>
