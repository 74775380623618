import { inject, Injectable } from '@angular/core'
import { firstValueFrom, map, shareReplay, takeUntil } from 'rxjs'
import {
  Action,
  checkAccess,
  FLEET_OWNER_PERMISSIONS,
  Resource,
  UserPermissions,
  UserRole,
  validatePermissionsToCheck,
} from '@ti-platform/contracts'
import { Profile } from '@ti-platform/web/auth'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'

@Injectable({ providedIn: 'root' })
export class AccessControl {
  protected readonly profile = inject(Profile)
  protected readonly destroy$ = injectDestroy$()

  public check(resource: Resource, action: Action) {
    return firstValueFrom(this.check$(resource, action))
  }

  public massCheck(permissions: Array<[Resource, Action]>) {
    return firstValueFrom(this.massCheck$(permissions))
  }

  @Memoize()
  public check$(resource: Resource, action: Action) {
    // Throws error if invalid action for resource is used
    validatePermissionsToCheck(resource, [action], false)

    return this.currentUserPermissions$.pipe(
      map((currentUserPermissions) => checkAccess(resource, action, currentUserPermissions)),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }

  @Memoize()
  public massCheck$(permissions: Array<[Resource, Action]>) {
    permissions.forEach((permission) =>
      validatePermissionsToCheck(permission[0], [permission[1]], false),
    )

    return this.currentUserPermissions$.pipe(
      map((currentUserPermissions) =>
        permissions.map((permission) =>
          checkAccess(permission[0], permission[1], currentUserPermissions),
        ),
      ),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }

  @Memoize()
  public get currentUserPermissions$() {
    return this.profile.state.pipe(
      map((profile) => {
        if (profile.roleId === UserRole.FleetDemo) {
          return FLEET_OWNER_PERMISSIONS
        }
        return profile?.role?.permissions ?? ({} as UserPermissions)
      }),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: false }),
    )
  }
}
