import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'app-alert-marker',
  templateUrl: 'alert-maker.component.html',
  styleUrls: ['alert-maker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMakerComponent {
  @Input() type!: 'primary' | 'secondary'
  @Input() color!: string
  @Input() showArrow?: boolean
  @Input() arrowDirection?: number
}
