import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'phone',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return ''
    }

    // Strip out non-numeric characters
    const phone = value.replace(/\D/g, '')

    // Check if the input is of correct length
    if (phone.length !== 10) {
      return value // return the original value if it's not 10 digits
    }

    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
  }
}
