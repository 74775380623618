@if (isFloating()) {
  <app-icon-feedback-2 color="var(--icon-color)"></app-icon-feedback-2>
} @else {
  <p-button
    class="text-14 fw-600 h--40 w100 feedback-button"
    [icon]="isCompact() ? 'pi' : ''"
    [ngClass]="{ compact: isCompact() }"
    [pTooltip]="isCompact() ? ('feedback.title' | translate) : ''"
    [tooltipPosition]="'bottom'"
  >
    <app-icon-feedback-2 class="icon" [height]="20" [width]="20"></app-icon-feedback-2>
    @if (!isCompact()) {
      {{ 'feedback.title' | translate }}
    }
  </p-button>
}
