import { inject, Injectable } from '@angular/core'
import {
  FuelConsumptionType,
  LanguageCode,
  MeasurementSystem,
  MeasurementSystemConfig,
} from '@ti-platform/contracts'
import { injectDestroy$, Memoize } from '@ti-platform/web/common'
import { COUNTRY_SETTINGS } from '@ti-platform/web/ui-kit/i18n'
import { firstValueFrom, map, Observable, shareReplay, takeUntil } from 'rxjs'
import { I18NConfig, UnitType } from '../contracts'
import { LanguageService } from '../services/language.service'
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
  galToLiter,
  kmGalTol100km,
  kmhToMph,
  kmToMil,
  l100kmToKmGal,
  l100kmToMpg,
  literToGal,
  milToKm,
  mpgToL100km,
  mphToKmh,
} from '../utils/convert-units'

export const extractSystemPerUnit = (
  config: MeasurementSystemConfig,
  unit: UnitType,
): MeasurementSystem | FuelConsumptionType => {
  switch (unit) {
    case UnitType.Distance:
      return config.distance

    case UnitType.FuelEfficiency:
      return config.consumption

    case UnitType.Temperature:
      return config.temperature

    case UnitType.Speed:
      return config.distance

    case UnitType.Volume:
      return config.volume

    case UnitType.Voltage:
      return MeasurementSystem.Metric

    default:
      throw new Error(`Unit ${unit} is not supported`)
  }
}

export abstract class I18NConfigProvider {
  abstract config$: Observable<I18NConfig>
  abstract getConfig(): Promise<I18NConfig>
}

@Injectable()
export class LangI18NConfigProvider extends I18NConfigProvider {
  protected readonly languageService = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()

  @Memoize()
  public get config$(): Observable<I18NConfig> {
    return this.languageService.current$.pipe(
      map((lang) => (lang === LanguageCode.English ? COUNTRY_SETTINGS.US : COUNTRY_SETTINGS.MX)),
      takeUntil(this.destroy$),
      shareReplay({ bufferSize: 1, refCount: true }),
    )
  }

  public async getConfig(): Promise<I18NConfig> {
    return firstValueFrom(this.config$)
  }
}

@Injectable()
export class UnitConversionService {
  protected readonly internalMeasurementSystem: MeasurementSystemConfig = {
    distance: MeasurementSystem.Metric,
    volume: MeasurementSystem.Metric,
    consumption: FuelConsumptionType.MPG,
    temperature: MeasurementSystem.Metric,
  }

  protected readonly i18NConfigProvider = inject(I18NConfigProvider)

  public async toUser(value: number, unit: UnitType) {
    const i18NConfig = await this.i18NConfigProvider.getConfig()
    return this.convertUnitValue(
      value,
      unit,
      this.internalMeasurementSystem,
      i18NConfig.measurementSystem,
    )
  }

  public async toInternal(value: number, unit: UnitType) {
    const i18NConfig = await this.i18NConfigProvider.getConfig()
    return this.convertUnitValue(
      value,
      unit,
      i18NConfig.measurementSystem,
      this.internalMeasurementSystem,
    )
  }

  protected convertUnitValue(
    value: number,
    unit: UnitType,
    from: MeasurementSystemConfig,
    to: MeasurementSystemConfig,
  ): [number, string] {
    switch (unit) {
      case UnitType.Distance:
        return this.convertDistance(value, from.distance, to.distance)

      case UnitType.FuelEfficiency:
        return this.convertFuelEfficiency(value, from.consumption, to.consumption)

      case UnitType.Temperature:
        return this.convertTemperature(value, from.temperature, to.temperature)

      case UnitType.Speed:
        return this.convertSpeed(value, from.distance, to.distance)

      case UnitType.Volume:
        return this.convertVolume(value, from.volume, to.volume)
      case UnitType.Voltage:
        return [value, 'V']

      default:
        return [NaN, '']
    }
  }

  protected convertDistance(
    value: number,
    from: MeasurementSystem,
    to: MeasurementSystem,
  ): [number, string] {
    if (from !== to) {
      return to === MeasurementSystem.Metric ? [milToKm(value), 'km'] : [kmToMil(value), 'mi']
    } else {
      return to === MeasurementSystem.Metric ? [value, 'km'] : [value, 'mi']
    }
  }

  protected convertFuelEfficiency(
    value: number,
    from: FuelConsumptionType,
    to: FuelConsumptionType,
  ): [number, string] {
    const lTo100Km =
      from === FuelConsumptionType.L100Km
        ? value
        : from === FuelConsumptionType.MPG
          ? mpgToL100km(value)
          : kmGalTol100km(value)
    return to === FuelConsumptionType.L100Km
      ? [lTo100Km, 'L/100km']
      : to === FuelConsumptionType.MPG
        ? [l100kmToMpg(lTo100Km), 'mpg']
        : [l100kmToKmGal(lTo100Km), 'km/gal']
  }

  protected convertTemperature(
    value: number,
    from: MeasurementSystem,
    to: MeasurementSystem,
  ): [number, string] {
    if (from !== to) {
      return to === MeasurementSystem.Metric
        ? [fahrenheitToCelsius(value), '°C']
        : [celsiusToFahrenheit(value), '°F']
    } else {
      return to === MeasurementSystem.Metric ? [value, '°C'] : [value, '°F']
    }
  }

  protected convertSpeed(
    value: number,
    from: MeasurementSystem,
    to: MeasurementSystem,
  ): [number, string] {
    if (from !== to) {
      return to === MeasurementSystem.Metric ? [mphToKmh(value), 'km/h'] : [kmhToMph(value), 'mph']
    } else {
      return to === MeasurementSystem.Metric ? [value, 'km/h'] : [value, 'mph']
    }
  }

  protected convertVolume(
    value: number,
    from: MeasurementSystem,
    to: MeasurementSystem,
  ): [number, string] {
    if (from !== to) {
      return to === MeasurementSystem.Metric ? [galToLiter(value), 'L'] : [literToGal(value), 'gal']
    } else {
      return to === MeasurementSystem.Metric ? [value, 'L'] : [value, 'gal']
    }
  }
}
