import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-clock-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M7.99967 4.00016V8.00016L10.6663 9.3335"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <path
        d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4H8.75ZM8 8H7.25C7.25 8.28408 7.4105 8.54378 7.66459 8.67082L8 8ZM10.3313 10.0042C10.7017 10.1894 11.1522 10.0392 11.3375 9.66874C11.5227 9.29826 11.3726 8.84775 11.0021 8.66251L10.3313 10.0042ZM7.25 4V8H8.75V4H7.25ZM7.66459 8.67082L10.3313 10.0042L11.0021 8.66251L8.33541 7.32918L7.66459 8.67082Z"
        fill="#F2F4F7"
        style="fill:#F2F4F7;fill:color(display-p3 0.9490 0.9569 0.9686);fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class ClockSolidIconComponent extends BaseIconComponent {
  @Input() override width = 16
  @Input() override height = 16
}
