import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms'
import { ApiErrors, TspCommandListData } from '@ti-platform/contracts'
import { BehaviorSubject, takeUntil } from 'rxjs'

import { CommandFormData } from '@ti-platform/contracts'
import {
  CommandsListModel,
  DevicesTypesDataProvider,
  DeviceTypeDataOption,
} from '@ti-platform/tsp-admin/app/devices/models'
import {
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  injectDestroy$,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import Ajv from 'ajv'
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete'

@Component({
  selector: 'app-command-form',
  templateUrl: 'command-form.component.html',
  styleUrl: 'command-form.component.scss',
})
export class CommandFormComponent implements OnInit {
  protected readonly commandForm = new FormGroup({
    deviceType: new FormControl<DeviceTypeDataOption | null>(null, [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
    config: new FormGroup({}),
  })
  protected readonly commandsListModel = inject(CommandsListModel)
  protected readonly configFieldsSet$ = new BehaviorSubject<boolean>(false)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly destroy$ = injectDestroy$()
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly disableMode$ = new BehaviorSubject<boolean>(false)
  protected readonly errorMessageKey$ = new BehaviorSubject<string>('')
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false)
  protected readonly languageService = inject(LanguageService)
  protected readonly modelWasChanged$ = new BehaviorSubject<boolean>(false)

  protected _command: Partial<TspCommandListData> = {}

  protected filteredTypesData: DeviceTypeDataOption[] = []

  @Output() public cancelEvent = new EventEmitter<void>()
  @Input() public header = ''
  @Output() public submitEvent = new EventEmitter<CommandFormData>()

  public get allTypesDataOptions() {
    return this.devicesTypesDataProvider.deviceTypesDataOptions
  }

  public get processing(): boolean {
    return this.isLoading$.getValue()
  }

  @Input()
  public set processing(value: boolean) {
    const oldValue = this.isLoading$.getValue()
    if (oldValue && !value) {
      this.disableMode$.next(false)
    }
    this.isLoading$.next(value)
  }

  public get errorMessage(): string {
    return this.errorMessageKey$.getValue()
  }

  @Input()
  public set errorMessage(value: string) {
    this.errorMessageKey$.next(value)
  }

  public get command(): Partial<TspCommandListData> {
    return this._command
  }

  @Input()
  public set command(value: Partial<TspCommandListData>) {
    this.resetForm()
    this._command = value
    // set start values
    let deviceType: DeviceTypeDataOption | null = null
    if (this.command.externalDeviceTypeId && this.allTypesDataOptions.length) {
      deviceType =
        this.allTypesDataOptions.find((item) => item.value === this.command.externalDeviceTypeId) ??
        null
    }
    deviceType = deviceType ?? null

    this.commandForm.setValue(
      {
        name: this.command.name || '',
        deviceType,
        config: {},
      },
      { emitEvent: false },
    )
    this.modelWasChanged$.next(false)

    if (this.command.id) {
      this.commandForm.controls['deviceType'].disable({ emitEvent: false })
      this.setConfigControl()
      this.commandPropertiesList.forEach((property) => {
        if (this.command.config?.[property] !== undefined) {
          const value =
            typeof this.command.config?.[property] === 'object'
              ? JSON.stringify(this.command.config?.[property])
              : this.command.config?.[property]
          this.commandForm.get(['config', property])?.setValue(value, { emitEvent: false })
        }
      })
    } else if (this.deviceType?.value) {
      this.setConfigControl()
    }
  }

  public get commandDescription() {
    return this.deviceType?.value
      ? this.devicesTypesDataProvider.commandDataByDeviceTypeId[this.deviceType?.value]
      : null
  }

  public get commandFieldsLabels() {
    return this.commandsListModel.commandFieldsLabels$.getValue()
  }

  public get commandInnerName(): string | undefined {
    return this.commandDescription?.name
  }

  public get commandProperties(): Record<string, any> | undefined {
    return this.commandSchema?.properties
  }

  public get commandPropertiesList(): string[] {
    return Object.keys(this.commandSchema?.properties ?? {})
  }

  public get commandRequiredProperties(): string[] {
    return this.commandSchema?.required ?? []
  }

  public get commandSchema(): Record<string, any> | undefined {
    return this.commandDescription?.schema
  }

  public get deviceType() {
    return this.commandForm.get('deviceType')?.value
  }

  public get deviceTypeData() {
    return this.deviceType?.value
      ? this.devicesTypesDataProvider.deviceTypeDataByTypeId[this.deviceType?.value]
      : null
  }

  public get isValidForm() {
    return this.commandForm.valid
  }

  public get newCommandMode() {
    return !this._command.id
  }

  public ngOnInit() {
    this.subscribeToFormChanges()
  }

  public onCancel() {
    if (!this.disableMode$.getValue()) {
      this.resetForm()
      // add remove fields except name and device type id
      this.cancelEvent.emit()
    }
  }

  protected getConfigData(): Record<string, any> {
    const data = this.commandForm.getRawValue()
    const config = data.config
    const result: Record<string, any> = {}
    this.commandPropertiesList.forEach((propertyName) => {
      const value = config[propertyName]
      if (value !== undefined && value !== '' && value !== null) {
        const fieldDescription = this.commandProperties?.[propertyName]
        if (fieldDescription?.type === 'object') {
          result[propertyName] = JSON.parse(value)
        } else {
          result[propertyName] = value
        }
      }
    })
    return result
  }

  protected subscribeToFormChanges() {
    this.commandForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessageKey$.next('')
      this.modelWasChanged$.next(true)
    })
    this.commandForm.controls['deviceType'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setConfigControl()
      })
  }

  protected getFieldValidatorBySchema(fieldName: string) {
    const isRequired = this.commandRequiredProperties.includes(fieldName)
    const fieldSchema = this.commandProperties?.[fieldName]
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value
      const noValue = isRequired ? !value : false
      if (noValue) {
        return { required: control.value }
      } else {
        if (fieldSchema.type === 'object') {
          try {
            value = JSON.parse(value)
          } catch (e) {
            return { 'wrong-json': control.value }
          }
        }
        const avj = new Ajv({ strict: false })
        const validate = avj.compile(fieldSchema)
        try {
          validate(value)
          if (validate.errors?.length) {
            return { 'field-schema': value }
          }
        } catch (e) {
          console.error(e)
          return { 'field-schema': value }
        }
        return null
      }
    }
  }

  protected getNewConfigFieldValidators(name: string) {
    const validator = this.getFieldValidatorBySchema(name)
    return [validator]
  }

  protected onCloseClick() {
    this.onCancel()
  }

  protected onFilterTypesData(event: AutoCompleteCompleteEvent) {
    const query = event.query
    if (query === '') {
      this.filteredTypesData = [...this.allTypesDataOptions]
    } else {
      this.filteredTypesData = this.allTypesDataOptions.filter((value) =>
        value.label.toLowerCase().includes(query.toLowerCase()),
      )
    }
  }

  protected async onSubmit() {
    this.disableMode$.next(true)
    this.commandForm.updateValueAndValidity()
    if (!this.isValidForm || !this.configFieldsSet$.getValue()) {
      return
    }
    this.commandForm.disable({ emitEvent: false })
    this.disableMode$.next(true)
    this.errorMessageKey$.next('')

    this.isLoading$.next(true)

    const data = this.commandForm.getRawValue()
    const commandData = {
      name: data.name as string,
      externalDeviceTypeId: this.deviceType?.value as number,
      externalName: this.commandInnerName as string,
      config: this.getConfigData(),
    }
    this.submitEvent.emit(commandData)

    this.commandForm.enable({ emitEvent: false })
    this.isLoading$.next(false)
    this.disableMode$.next(false)
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    this.errorMessageKey$.next(
      checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown),
    )
    console.error(message)
  }

  protected resetForm() {
    this.configFieldsSet$.next(false)
    this.commandForm.setControl('config', new FormGroup({}), { emitEvent: false })
    this.commandForm.reset()
  }

  protected setConfigControl() {
    this.configFieldsSet$.next(false)
    this.commandForm.setControl('config', new FormGroup({}), { emitEvent: false })
    if (this.commandSchema && this.commandProperties) {
      const configFields: Record<string, any> = {}
      Object.keys(this.commandProperties).forEach((propertyName) => {
        const propertyData: Record<string, any> = this.commandProperties?.[propertyName]
        const propertyType: string = propertyData.type
        let propertyControl: FormControl | undefined = undefined
        switch (propertyType) {
          case 'string':
          case 'object':
            propertyControl = new FormControl<string>('')
            break
          case 'boolean':
            propertyControl = new FormControl<boolean>(true)
            break
          case 'number':
          case 'integer':
            propertyControl = new FormControl<number | null>(null)
            break
        }
        if (propertyControl) {
          configFields[propertyName] = propertyControl
          propertyControl.setValidators(this.getFieldValidatorBySchema(propertyName))
        }
      })
      this.commandForm.setControl('config', new FormGroup(configFields), { emitEvent: false })
      this.configFieldsSet$.next(true)
    }
  }
}
