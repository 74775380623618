import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-file-search',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6663 14.6667H10.6663M13.333 20.0001H10.6663M21.333 9.33341H10.6663M26.6663 14.0001V9.06675C26.6663 6.82654 26.6663 5.70643 26.2304 4.85079C25.8469 4.09814 25.235 3.48622 24.4823 3.10272C23.6267 2.66675 22.5066 2.66675 20.2663 2.66675H11.733C9.4928 2.66675 8.37269 2.66675 7.51705 3.10272C6.7644 3.48622 6.15248 4.09814 5.76898 4.85079C5.33301 5.70643 5.33301 6.82654 5.33301 9.06675V22.9334C5.33301 25.1736 5.33301 26.2937 5.76898 27.1494C6.15248 27.902 6.7644 28.5139 7.51705 28.8974C8.37269 29.3334 9.4928 29.3334 11.733 29.3334H15.333M29.333 29.3334L27.333 27.3334M28.6663 24.0001C28.6663 26.5774 26.577 28.6667 23.9997 28.6667C21.4223 28.6667 19.333 26.5774 19.333 24.0001C19.333 21.4228 21.4223 19.3334 23.9997 19.3334C26.577 19.3334 28.6663 21.4228 28.6663 24.0001Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class FileSearchIconComponent extends BaseIconComponent {
  public override width = 32
  public override height = 32
  public override color = 'var(--color-gray-900)'
}
