import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-inline-text-copy',
  templateUrl: 'inline-text-copy.component.html',
  styleUrl: 'inline-text-copy.component.scss',
})
export class InlineTextCopyComponent {
  @Input() text = ''
  @Input() tooltip = ''
  @Input() copiedTooltip = ''
}
