import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core'
import { DataGridColumn, DataGridSortOrder } from '../data-grid'

export interface CustomSortOption<T = any> {
  label: string
  value: T
}

@Component({
  selector: 'app-sort-list',
  templateUrl: 'sort-list.component.html',
  styleUrls: ['sort-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortListComponent implements OnChanges {
  @Output() gridSortOrderChanged = new EventEmitter<DataGridSortOrder>()
  @Output() customSortValueChanged = new EventEmitter<any>()

  @Input() gridColumns?: DataGridColumn[] | null
  @Input() gridSortOrder?: DataGridSortOrder | null
  @Input() gridDefaultSortOrder?: DataGridSortOrder | null

  @Input() customSortOptions?: CustomSortOption[]
  @Input() customSortValue?: any

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridColumns) {
      this._columns.set(changes.gridColumns.currentValue ?? [])
    }
  }

  protected toggleColumnOrder(column: string) {
    if (!this.gridColumns?.length) {
      return
    }

    // Change order type if column is already selected
    if (this.gridSortOrder?.column === column) {
      this.gridSortOrder = {
        column: this.gridSortOrder.column,
        order: this.gridSortOrder.order === 'ASC' ? 'DESC' : 'ASC',
      }
    } else {
      this.gridSortOrder = { column: column, order: 'ASC' }
    }

    this.gridSortOrderChanged.emit(this.gridSortOrder)
  }

  protected selectCustomOption(option: CustomSortOption) {
    this.customSortValue = option
    this.customSortValueChanged.emit(option)
  }

  protected readonly _columns = signal<DataGridColumn[]>([])
  protected readonly columnSortOptions = computed(() => {
    return this._columns()
      .filter((column) => column.sortable)
      .map((column) => {
        return {
          label: column.label,
          column: column.field,
        }
      })
  })
}
