@if (state.step$ | async; as step) {
  @if (step === GeofenceMarkerMode.MapPin || step === GeofenceMarkerMode.SelectShape) {
    <div
      class="map-pin"
      [class.clickable]="step === GeofenceMarkerMode.MapPin"
      [pTooltip]="'map.geofence-form.pin-tooltip' | translate"
      [tooltipPosition]="'top'"
      [tooltipDisabled]="step !== GeofenceMarkerMode.MapPin"
      (click)="step === GeofenceMarkerMode.MapPin ? events.activate$.next() : undefined"
      (touchstart)="step === GeofenceMarkerMode.MapPin ? events.activate$.next() : undefined"
    >
      <app-icon-map-pin
        [height]="28"
        [width]="28"
        color="var(--color-primary-500)"
      ></app-icon-map-pin>
    </div>
  }
}
