import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core'
import { FleetView, phoneOrIccidPattern } from '@ti-platform/contracts'
import { BehaviorSubject, takeUntil } from 'rxjs'

import { ApiErrors, CreateDeviceData } from '@ti-platform/contracts'
import {
  DevicesListModel,
  DevicesTypesDataProvider,
  DeviceTypeDataOption,
} from '@ti-platform/tsp-admin/app/devices/models'
import { getApiErrorKey, formatFileSize, injectDestroy$, sleep } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser'
import { MessageService } from 'primeng/api'
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete'

enum UploadSteps {
  selectDeviceType = 0,
  selectFile = 1,
  fileUploading = 2,
  fileValidationError = 3,
  uploadConfiguration = 4,
  dataValidation = 5,
  dataValidationResult = 6,
  addingDevices = 7,
  addingResult = 8,
}

interface FileRowData {
  rowIndex: number
  rowData: string[]
}

interface FileRowValidatedData {
  rowIndex: number
  ident: string
  name: string
  phone?: string
  password?: string
  validationError?: string
}

interface ColumnOption {
  value: number
  label: string
}

enum ColumnType {
  Name = 'name',
  Ident = 'ident',
  Password = 'password',
  Phone = 'phone',
}

@Component({
  selector: 'app-load-devices',
  templateUrl: 'load-devices.component.html',
  styleUrl: 'load-devices.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadDevicesComponent {
  @Input() opened = false
  @Output() devicesAddedEvent = new EventEmitter<{ added: number; all: number }>()
  @Output() cancelEvent = new EventEmitter<void>()
  @Output() abortAddingEvent = new EventEmitter<void>()
  protected readonly destroy$ = injectDestroy$()
  protected readonly isDestroyed$ = new BehaviorSubject<boolean>(false)
  protected readonly devicesViewModel = inject(DevicesListModel)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly languageService = inject(LanguageService)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly messageService = inject(MessageService)
  protected readonly ngxCsvParser = inject(NgxCsvParser)
  protected filteredTypesData: DeviceTypeDataOption[] = []
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false)
  protected readonly currentStep$ = new BehaviorSubject<UploadSteps>(UploadSteps.selectDeviceType)
  protected readonly currentFleet$ = new BehaviorSubject<FleetView | null>(null)
  protected readonly columnConfiguration$ = new BehaviorSubject<Record<ColumnType, number | null>>({
    [ColumnType.Name]: null,
    [ColumnType.Ident]: null,
    [ColumnType.Password]: null,
    [ColumnType.Phone]: null,
  })
  protected readonly fileData$ = new BehaviorSubject<FileRowData[]>([])
  protected readonly columnOptions$ = new BehaviorSubject<ColumnOption[]>([])

  protected readonly configuratedData$ = new BehaviorSubject<FileRowValidatedData[]>([])
  protected readonly dataRowIndexsForValidation$ = new BehaviorSubject<number[]>([])
  protected readonly dataValidationProgress$ = new BehaviorSubject<number>(0)
  protected readonly allRecordsNumber$ = new BehaviorSubject<number>(0)
  protected readonly unvalidatedData$ = new BehaviorSubject<FileRowValidatedData[]>([])
  protected readonly validatedData$ = new BehaviorSubject<FileRowValidatedData[]>([])

  protected readonly addingDevicesProgress$ = new BehaviorSubject<number>(0)
  protected readonly successAddedIdents$ = new BehaviorSubject<string[]>([])
  protected readonly failedAddedIdents$ = new BehaviorSubject<string[]>([])

  protected readonly UploadSteps = UploadSteps
  protected readonly ColumnType = ColumnType

  protected readonly ColumnsLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']

  protected deviceType: DeviceTypeDataOption | null = null
  protected firstRowIsTitle = false
  protected requirementsAccordionIndex: number | null = 0
  protected notAddedDeviceInfoAccordionIndex: number | null = 1
  protected fileName = ''
  protected fileSize = 0
  protected formattedFileSize = '0 MB'
  protected fileSelectErrorSummary = ''
  protected fileSelectErrorDescription = ''
  protected readonly fileUploadProgress$ = new BehaviorSubject<number>(0)

  protected get currentStep() {
    return this.currentStep$.value
  }

  public async onSubmit() {
    this.opened = false
    this.devicesAddedEvent.emit({
      added: this.successAddedIdents$.value.length,
      all: this.validatedData$.value.length,
    })
  }

  public async onCancel() {
    let result = true
    if (
      this.currentStep === UploadSteps.dataValidation ||
      this.currentStep === UploadSteps.addingDevices
    ) {
      const labels = await this.languageService.massTranslate({
        summary:
          this.currentStep === UploadSteps.dataValidation
            ? 'tsp-admin.devices.add.load-list.abort-validation.summary'
            : 'tsp-admin.devices.add.load-list.abort-adding.summary',
        description:
          this.currentStep === UploadSteps.dataValidation
            ? 'tsp-admin.devices.add.load-list.abort-validation.description'
            : 'tsp-admin.devices.add.load-list.abort-adding.description',
        confirmButton: 'button.abort-process',
        cancelButton: 'button.cancel',
      })
      result = await this.dialogFacade.confirm({
        ...labels,
        cancelButtonColor: 'var(--color-alert-500)',
      })
    }
    if (result) {
      this.opened = false
      if (this.currentStep === UploadSteps.addingDevices) {
        this.abortAddingEvent.emit()
      } else {
        this.cancelEvent.emit()
      }
    }
  }

  protected async onCloseClick() {
    if (this.currentStep === UploadSteps.addingResult) {
      await this.onSubmit()
    } else {
      await this.onCancel()
    }
  }

  get canContinue() {
    if (
      this.currentStep === UploadSteps.uploadConfiguration &&
      this.columnConfiguration$.value[ColumnType.Name] !== null &&
      this.columnConfiguration$.value[ColumnType.Ident] !== null
    ) {
      return true
    } else if (
      this.currentStep === UploadSteps.dataValidationResult &&
      this.validatedData$.value.length
    ) {
      return true
    } else {
      return false
    }
  }

  protected async onContinue() {
    if (this.currentStep === UploadSteps.uploadConfiguration) {
      this.currentStep$.next(UploadSteps.dataValidation)
      await this.configurateDataByColumns()
      await this.validateData()
    } else if (
      this.currentStep === UploadSteps.dataValidationResult &&
      this.validatedData$.value.length
    ) {
      this.currentStep$.next(UploadSteps.addingDevices)
      await this.addingDevices()
    }
  }

  get allTypesDataOptions() {
    return this.devicesTypesDataProvider.deviceTypesDataOptions
  }

  get deviceTypeData() {
    return this.deviceType?.value
      ? this.devicesTypesDataProvider.deviceTypeDataByTypeId[this.deviceType?.value]
      : null
  }

  get withPassword() {
    return !!this.deviceTypeData?.configuration?.properties?.password
  }

  get configWithPhone() {
    return !!this.deviceTypeData?.configuration?.properties?.phone
  }

  get identPattern(): string | null {
    return this.deviceTypeData?.configuration?.properties?.ident?.pattern ?? null
  }

  ngOnInit() {
    this.destroy$.subscribe(() => {
      this.isDestroyed$.next(true)
    })

    this.devicesViewModel.selectedFleet$.pipe(takeUntil(this.destroy$)).subscribe((fleet) => {
      if (fleet) {
        this.currentFleet$.next(fleet)
      } else {
        this.currentFleet$.next(null)
      }
    })
  }

  protected async checkIdentPattern(value: string): Promise<string> {
    if (!value) {
      return await this.languageService.translate(
        'tsp-admin.devices.add.load-list.validate-errors.no-ident',
      )
    }
    if (!this.identPattern) {
      return ''
    } else {
      const matchResult = value.match(new RegExp(this.identPattern))
      if (matchResult) {
        return ''
      } else {
        return await this.languageService.translate(
          'tsp-admin.devices.add.load-list.validate-errors.wrong-ident',
        )
      }
    }
  }

  protected async checkPhonePattern(value: string): Promise<string> {
    if (!value) {
      return ''
    }

    const matchResult = value.match(new RegExp(phoneOrIccidPattern))
    if (matchResult) {
      return ''
    } else {
      return await this.languageService.translate(
        'tsp-admin.devices.add.load-list.validate-errors.wrong-phone',
      )
    }
  }

  protected onFilterTypesData(event: AutoCompleteCompleteEvent) {
    const query = event.query
    if (query === '') {
      this.filteredTypesData = [...this.allTypesDataOptions]
    } else {
      this.filteredTypesData = this.allTypesDataOptions.filter((value) =>
        value.label.toLowerCase().includes(query.toLowerCase()),
      )
    }
  }

  protected onChangeDeviceType(event: DeviceTypeDataOption | null) {
    this.deviceType = event
    if (this.deviceType && this.currentStep === UploadSteps.selectDeviceType) {
      // if set null - it does not work
      this.requirementsAccordionIndex = 1
      this.currentStep$.next(UploadSteps.selectFile)
    } else if (!this.deviceType && this.currentStep !== UploadSteps.selectDeviceType) {
      this.requirementsAccordionIndex = 0
      this.currentStep$.next(UploadSteps.selectDeviceType)
    }
  }

  protected loadFile(file: File) {
    this.fileSelectErrorSummary = ''
    this.fileSelectErrorDescription = ''
    this.fileName = ''
    this.fileSize = 0
    this.formattedFileSize = formatFileSize(this.fileSize)
    if (file.type !== 'text/csv' || file.size > 1024 * 1024) {
      this.fileSelectErrorSummary = 'tsp-admin.devices.add.load-list.requirements.errors.title'
      this.fileSelectErrorDescription =
        'tsp-admin.devices.add.load-list.requirements.errors.not-meet-requirements'
      this.currentStep$.next(UploadSteps.fileValidationError)
      return
    }
    this.processFile(file)
  }

  protected onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement
    if (target?.files?.length) {
      this.loadFile(target.files[0])
      target.value = ''
    }
  }

  protected processFile(file: File) {
    this.fileName = file.name
    this.fileSize = file.size
    this.formattedFileSize = formatFileSize(this.fileSize)
    this.currentStep$.next(UploadSteps.fileUploading)
    this.ngxCsvParser
      .parse(file, { delimiter: ',', header: false })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result): void => {
          let fileData: FileRowData[] = []
          let maxColumns = 0
          const lines: any[] = result as unknown as any[]
          const processAllNumber = lines!.length
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i]
            if (line.length && !(line.length === 1 && !line[0])) {
              maxColumns = Math.max(maxColumns, line.length)
              fileData.push({
                rowData: line,
                rowIndex: i,
              })
            }
            this.fileUploadProgress$.next(Math.round(((i + 1) / processAllNumber) * 100))
          }

          if (fileData.length > 1001 || fileData.length < 1 || maxColumns > 10 || maxColumns < 2) {
            this.fileSelectErrorSummary =
              'tsp-admin.devices.add.load-list.requirements.errors.title'
            this.fileSelectErrorDescription =
              'tsp-admin.devices.add.load-list.requirements.errors.not-meet-requirements'
            fileData = []
          }

          this.fileData$.next(fileData)
          if (this.fileSelectErrorDescription) {
            this.currentStep$.next(UploadSteps.fileValidationError)
          } else {
            this.setColumnsOptions()
            this.currentStep$.next(UploadSteps.uploadConfiguration)
          }
        },
        error: (error: NgxCSVParserError): void => {
          console.error('Error to parse file', error)
          this.fileSelectErrorSummary =
            'tsp-admin.devices.add.load-list.requirements.errors.error-parse'
          this.fileSelectErrorDescription =
            'tsp-admin.devices.add.load-list.requirements.errors.try-other-file'
          this.currentStep$.next(UploadSteps.fileValidationError)
          this.fileData$.next([])
        },
      })
  }

  protected setColumnsOptions() {
    const columnOptions: ColumnOption[] = []
    const fileData = this.fileData$.value
    if (fileData.length) {
      const columnLength = fileData[0].rowData.length
      const rowsLength = Math.min(4, fileData.length)
      for (let i = 0; i < columnLength; i++) {
        const item: ColumnOption = {
          value: i,
          label: '',
        }
        const labelList: any[] = []
        for (let j = 0; j < rowsLength; j++) {
          labelList.push(fileData[j].rowData[i])
        }
        item.label = labelList.join(', ')
        if (item.label.length > 30) {
          item.label = `${item.label.substring(0, 24)}...`
        }
        columnOptions.push(item)
      }
      this.columnOptions$.next(columnOptions)
    }
  }

  protected onConfigurationColumnChange(paramName, value) {
    const configuration = this.columnConfiguration$.value
    configuration[paramName] = value
    this.columnConfiguration$.next(configuration)
  }

  protected async configurateDataByColumns() {
    let fileData = this.fileData$.value
    if (this.firstRowIsTitle) {
      fileData = fileData.slice(1)
      this.fileData$.next(fileData)
    }

    const validatedRows: FileRowValidatedData[] = []
    const dataRowIndexsForAdditionalCheck: number[] = []

    const identLists: string[] = []

    const columnIndexs = this.columnConfiguration$.value
    for (let i = 0; i < fileData.length; i++) {
      const line = fileData[i]
      const validationErrors: string[] = []
      const row: FileRowValidatedData = {
        rowIndex: line.rowIndex,
        name: line.rowData[columnIndexs[ColumnType.Name]!].toString(),
        ident: line.rowData[columnIndexs[ColumnType.Ident]!].toString(),
      }

      if (!row.name || row.name.length > 100) {
        validationErrors.push(
          await this.languageService.translate(
            'tsp-admin.devices.add.load-list.validate-errors.name-length',
          ),
        )
      }
      const identError = await this.checkIdentPattern(row.ident)
      if (identError) {
        validationErrors.push(identError)
      } else {
        if (identLists.includes(row.ident)) {
          validationErrors.push(
            await this.languageService.translate(
              'tsp-admin.devices.add.load-list.validate-errors.dublicate-ident',
            ),
          )
        } else {
          identLists.push(row.ident)
        }
      }

      if (this.withPassword && columnIndexs[ColumnType.Password]) {
        row.password = line.rowData[columnIndexs[ColumnType.Password]].toString()
      }
      if (columnIndexs[ColumnType.Phone]) {
        row.phone = line.rowData[columnIndexs[ColumnType.Phone]].toString()
        const phoneError = await this.checkPhonePattern(row.phone)
        if (phoneError) {
          validationErrors.push(phoneError)
        }
      }
      if (validationErrors.length) {
        row.validationError = validationErrors.join(', ')
      } else {
        dataRowIndexsForAdditionalCheck.push(i)
      }

      validatedRows.push(row)
    }

    if (!this.isDestroyed$.value) {
      this.configuratedData$.next(validatedRows)
      this.dataRowIndexsForValidation$.next(dataRowIndexsForAdditionalCheck)
      this.allRecordsNumber$.next(validatedRows.length)
    }
  }

  protected async validateData(): Promise<void> {
    if (this.isDestroyed$.value) {
      return
    }

    const dataRowIndexsForValidation = this.dataRowIndexsForValidation$.value
    const configuratedData = this.configuratedData$.value
    const allNumber = dataRowIndexsForValidation.length

    const waitingTimeout = dataRowIndexsForValidation.length > 200 ? 500 : 100
    const chunkSize = 50
    let firstIndex = 0
    let lessThenIndex = Math.min(firstIndex + chunkSize, dataRowIndexsForValidation.length)
    while (firstIndex < dataRowIndexsForValidation.length) {
      const promises: Promise<void>[] = []
      for (let i = firstIndex; i < lessThenIndex; i++) {
        promises.push(this.doRequestValidation(configuratedData[i]))
      }
      await Promise.all(promises)
      this.dataValidationProgress$.next(Math.round((lessThenIndex / allNumber) * 100))

      if (this.isDestroyed$.value) {
        return
      }
      firstIndex = firstIndex + chunkSize
      lessThenIndex = Math.min(firstIndex + chunkSize, dataRowIndexsForValidation.length)

      if (firstIndex < dataRowIndexsForValidation.length) {
        await sleep(waitingTimeout)
      }
    }

    if (this.isDestroyed$.value) {
      return
    }
    const validatedData = configuratedData.filter((item) => !item.validationError)
    const unvalidatedData = configuratedData.filter((item) => item.validationError)
    this.validatedData$.next(validatedData)
    this.unvalidatedData$.next(unvalidatedData)
    this.configuratedData$.next([])
    this.dataValidationProgress$.next(100)
    this.currentStep$.next(UploadSteps.dataValidationResult)
  }

  protected async doRequestValidation(data: FileRowValidatedData) {
    const validationErrors: string[] = []
    try {
      const params = {
        identifier: data.ident,
        deviceTypeId: this.deviceTypeData!.id,
        protocolId: this.deviceTypeData!.protocolId,
      }
      const double = await this.devicesViewModel.checkDoubleDevice(params)
      if (double) {
        const errorKey = getApiErrorKey(ApiErrors.suchIdentifierAndProtocolAlreadyExists)
        validationErrors.push(await this.languageService.translate(errorKey))
      }
    } catch (e) {
      validationErrors.push(await this.languageService.translate('errors.unknown'))
    }
    if (data && validationErrors.length) {
      data.validationError = validationErrors.join(', ')
    }
  }

  protected async addingDevices() {
    if (this.isDestroyed$.value) {
      return
    }
    const resultByIdent: Record<string, boolean> = {}
    const validatedData = this.validatedData$.value
    const allNumber = validatedData.length

    const waitingTimeout = validatedData.length > 200 ? 500 : 100
    const chunkSize = 50
    let firstIndex = 0
    let lessThenIndex = Math.min(firstIndex + chunkSize, validatedData.length)
    while (firstIndex < validatedData.length) {
      const promises: Promise<void>[] = []
      for (let i = firstIndex; i < lessThenIndex; i++) {
        const data = validatedData[i]
        resultByIdent[data.ident] = false
        promises.push(this.addDevice(data, resultByIdent))
      }
      await Promise.all(promises)
      this.addingDevicesProgress$.next(Math.round((lessThenIndex / allNumber) * 100))

      if (this.isDestroyed$.value) {
        return
      }
      firstIndex = firstIndex + chunkSize
      lessThenIndex = Math.min(firstIndex + chunkSize, validatedData.length)

      if (firstIndex < validatedData.length) {
        await sleep(waitingTimeout)
      }
    }

    if (this.isDestroyed$.value) {
      return
    }
    const successIdents: string[] = []
    const failedIdents: string[] = []
    Object.keys(resultByIdent).forEach((key) => {
      if (resultByIdent[key]) {
        successIdents.push(key)
      } else {
        failedIdents.push(key)
      }
    })
    this.successAddedIdents$.next(successIdents)
    this.failedAddedIdents$.next(failedIdents)
    this.addingDevicesProgress$.next(100)

    this.currentStep$.next(UploadSteps.addingResult)
  }

  protected async addDevice(data: FileRowValidatedData, resultByIdent: Record<string, boolean>) {
    if (this.isDestroyed$.value) {
      return
    }
    try {
      const config: Record<string, any> = {
        ident: data.ident,
      }
      if (this.withPassword && data.password) {
        config.password = data.password
      }
      if (this.configWithPhone && data.phone) {
        config.phone = data.phone
      }
      const params: CreateDeviceData = {
        name: data.name,
        sensorsConfig: {},
        identifier: data.ident,
        deviceTypeId: this.deviceTypeData!.id,
        config: config,
        isVideo: false,
      }
      if (data.phone) {
        params.sim = data.phone
      }
      if (this.currentFleet$.value) {
        params.fleetId = this.currentFleet$.value.id
      }
      const device = await this.devicesViewModel.createDevice(params)
      if (device && resultByIdent) {
        resultByIdent[data.ident] = true
      }
    } catch (e) {
      console.error(`Error to create device with IMEI ${data.ident}`, e)
    }
  }
}
