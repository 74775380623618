import { AlertRequestVideoSetting } from './alert'
import { Device } from './device'
import { PricingPlan } from './pricing-plan'
import { UserProfile } from './user-profile'

export enum LanguageCode {
  English = 'en',
  Spanish = 'es',
}

export enum SeparatorType {
  Comma,
  Dot,
}

export enum DateStyle {
  EU,
  US,
}

export enum MeasurementSystem {
  Metric, // km
  Imperial, // miles
}

export enum FuelConsumptionType {
  L100Km,
  MPG,
  KmGal,
}

export interface MeasurementSystemConfig {
  distance: MeasurementSystem
  volume: MeasurementSystem
  consumption: FuelConsumptionType
  temperature: MeasurementSystem
}

export type FleetAlertSettings = {
  defaultVideoSettings?: AlertRequestVideoSetting
}

export type FleetEngineIdlingReportSettings = {
  // in seconds
  minDuration?: number
}

export type FleetReportSettings = {
  engineIdling?: FleetEngineIdlingReportSettings
}

export interface Fleet {
  id: number
  tspId: number
  name: string
  contactName: string
  email: string
  phone?: string
  language: LanguageCode
  country: string
  address?: string
  city?: string
  region?: string
  zipCode?: string
  measurementSystem: MeasurementSystemConfig
  evaluationDistance?: number
  matchGeofenceNames?: boolean
  idleTime?: number
  pricingPlanId: number
  createdAt: Date
  createdBy: string
  pricingPlan?: PricingPlan
  creator?: UserProfile
  isActive: boolean
  deleted: boolean
  isDemo?: boolean
  alertSettings?: FleetAlertSettings
  reportSettings?: FleetReportSettings

  devices?: Device[]
}

export interface FleetView extends Fleet {
  devicesCount?: number
}
