import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-ignition',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_232_23405)">
        <path
          d="M34.9465 9.05384C33.2504 7.34666 31.2333 5.9918 29.0115 5.06726C26.7896 4.14272 24.4068 3.66675 22.0003 3.66675C19.5938 3.66675 17.211 4.14272 14.9891 5.06726C12.7673 5.9918 10.7502 7.34666 9.05409 9.05384C7.34691 10.75 5.99205 12.7671 5.06751 14.9889C4.14296 17.2108 3.66699 19.5935 3.66699 22.0001C3.66699 24.4066 4.14296 26.7894 5.06751 29.0112C5.99205 31.2331 7.34691 33.2501 9.05409 34.9463C10.7502 36.6535 12.7673 38.0083 14.9891 38.9329C17.211 39.8574 19.5938 40.3334 22.0003 40.3334C24.4068 40.3334 26.7896 39.8574 29.0115 38.9329C31.2333 38.0083 33.2504 36.6535 34.9465 34.9463C36.6537 33.2501 38.0086 31.2331 38.9331 29.0112C39.8577 26.7894 40.3336 24.4066 40.3336 22.0001C40.3336 19.5935 39.8577 17.2108 38.9331 14.9889C38.0086 12.7671 36.6537 10.75 34.9465 9.05384ZM20.9263 13.408C20.9263 12.8149 21.4072 12.334 22.0003 12.334C22.5934 12.334 23.0743 12.8149 23.0743 13.408V19.136C23.0743 19.7292 22.5934 20.2101 22.0003 20.2101C21.4072 20.2101 20.9263 19.7292 20.9263 19.136V13.408ZM22.0003 29.9477C17.6179 29.9477 14.0527 26.3824 14.0527 22.0001C14.0528 20.8663 14.2954 19.7457 14.7643 18.7134C15.2331 17.6812 15.9173 16.7611 16.771 16.0151C17.2175 15.6246 17.896 15.6701 18.2865 16.1166C18.6769 16.5631 18.6314 17.2416 18.1849 17.6321C17.5619 18.1766 17.0625 18.848 16.7203 19.6014C16.378 20.3548 16.2009 21.1726 16.2007 22.0001C16.2007 25.198 18.8024 27.7997 22.0004 27.7997C25.1983 27.7997 27.8 25.198 27.8 22.0001C27.8 20.3431 27.0886 18.7614 25.8482 17.6606C25.4046 17.2668 25.3641 16.5881 25.7578 16.1444C26.1515 15.7007 26.8303 15.6603 27.274 16.0539C28.9734 17.5621 29.948 19.7294 29.948 22C29.948 26.3824 26.3827 29.9477 22.0003 29.9477Z"
          [attr.fill]="color"
          style="fill-opacity:1;"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class IgnitionIconComponent extends BaseIconComponent {
  @Input() override width = 44
  @Input() override height = 44
}
