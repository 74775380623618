import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { BehaviorSubject, Subject, takeUntil, throttleTime } from 'rxjs'
import { Nullable } from 'primeng/ts-helpers'
import { injectDestroy$ } from '@ti-platform/web/common'

@Component({
  selector: 'app-video-player',
  templateUrl: 'video-player.component.html',
  styleUrl: 'video-player.component.scss',
})
export class VideoPlayerComponent implements OnInit, OnChanges {
  @Output() paused = new EventEmitter<void>()
  @Output() started = new EventEmitter<void>()
  @Output() restarted = new EventEmitter<void>()
  @Output() timeUpdated = new EventEmitter<number>()

  @Input() isPaused?: Nullable<boolean>
  @Input() isEnded?: Nullable<boolean>
  @Input() alertTime?: Nullable<number>
  @Input() currentTime?: Nullable<number>
  @Input() endTime!: number
  @Input() startTime!: number

  @ViewChild('slider') slider!: ElementRef<HTMLDivElement>
  @ViewChild('cursor') cursor!: ElementRef<HTMLDivElement>

  protected readonly destroy$ = injectDestroy$()

  protected readonly cursorPositionPct$ = new BehaviorSubject<string>('0%')
  protected readonly alertSegmentPositionPct$ = new BehaviorSubject<string>('')

  protected durationMs = 0
  protected relativeCurrentTime = 0
  protected isDragging = false

  public ngOnInit() {
    this.durationMs = this.endTime - this.startTime
    this.relativeCurrentTime = this.currentTime ? this.currentTime - this.startTime : 0
    this.calculatePercents()

    if (this.alertTime) {
      const duration = this.endTime - this.startTime
      const alertOffset = this.alertTime - this.startTime
      this.alertSegmentPositionPct$.next(`${100 / Math.floor(duration / alertOffset)}%`)
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.currentTime && !changes.currentTime.firstChange) {
      const newValue = changes.currentTime.currentValue - this.startTime
      if (newValue >= this.durationMs) {
        this.pause()
        this.relativeCurrentTime = this.durationMs
        this.isEnded = true
      } else {
        this.relativeCurrentTime = Math.max(newValue, 0)
      }
      this.calculatePercents()
    }
  }

  protected setCursorPositionPct(percent: number) {
    this.cursorPositionPct$.next(`${percent}%`)
  }

  protected sliderClick(event: MouseEvent) {
    const sliderRect = this.slider.nativeElement.getBoundingClientRect()
    this.updateSliderClickCursorPosition(event.clientX - sliderRect.left)
  }

  protected handleMouseDown() {
    this.pause()
    this.isDragging = true

    const updateTime$ = new Subject<number>()
    const subscription = updateTime$
      .pipe(
        throttleTime(64, undefined, { leading: false, trailing: true }),
        takeUntil(this.destroy$),
      )
      .subscribe((time) => this.timeUpdated.emit(time))

    const mouseMoveListener = (event: MouseEvent) => {
      if (!this.isDragging) return

      const cursorRect = this.cursor.nativeElement.getBoundingClientRect()
      const cursorLeft = cursorRect.left

      this.updateDragCursorPosition(event.clientX - cursorLeft)

      const isAllowedToUpdate = this.durationMs - this.relativeCurrentTime !== 0
      if (isAllowedToUpdate) {
        updateTime$.next(this.relativeCurrentTime)
      }
    }

    const mouseUpListener = () => {
      const isAllowedToUpdate = this.durationMs - this.relativeCurrentTime !== 0
      if (isAllowedToUpdate && this.isEnded) {
        this.isEnded = false
      }

      subscription.unsubscribe()
      document.removeEventListener('mouseup', mouseUpListener)
      document.removeEventListener('mousemove', mouseMoveListener)
    }

    document.addEventListener('mousemove', mouseMoveListener)
    document.addEventListener('mouseup', mouseUpListener)
  }

  protected play() {
    this.isPaused = false
    this.isEnded = false
    this.started.emit()
  }

  protected pause() {
    this.isPaused = true
    this.paused.emit()
  }

  protected restart() {
    this.isPaused = true
    this.isEnded = false
    this.restarted.emit()
    this.relativeCurrentTime = 0
    this.calculatePercents()
  }

  protected calculatePercents() {
    const value = +((this.relativeCurrentTime / this.durationMs) * 100).toFixed(1)
    this.setCursorPositionPct(value > 100 ? 100 : value)
  }

  protected updateSliderClickCursorPosition(offsetAbsolute: number) {
    const percentage = +(offsetAbsolute / this.slider.nativeElement.getBoundingClientRect().width)

    if (percentage > 1) {
      this.setCursorPositionPct(100)
    } else if (percentage < 0) {
      this.setCursorPositionPct(0)
    } else {
      this.setCursorPositionPct(percentage * 100)
    }

    this.relativeCurrentTime = this.durationMs * percentage
    this.timeUpdated.emit(this.relativeCurrentTime)
    this.isEnded = false
  }

  protected updateDragCursorPosition(offsetRelative: number) {
    const sliderWidth = this.slider.nativeElement.getBoundingClientRect().width
    const offsetAbsolute = Math.abs(offsetRelative)

    if (offsetRelative < 0) {
      const percentage = offsetAbsolute / sliderWidth
      const offset = this.durationMs * percentage

      const cursorNewPosition = this.relativeCurrentTime + (0 - offset)

      if (cursorNewPosition < 0) {
        this.relativeCurrentTime = 0
      } else {
        this.relativeCurrentTime = cursorNewPosition
      }

      this.calculatePercents()
    } else {
      const percentage = offsetRelative / sliderWidth
      const offset = this.durationMs * percentage
      const cursorNewPosition = this.relativeCurrentTime + offset

      if (cursorNewPosition > this.durationMs) {
        this.relativeCurrentTime = this.durationMs
      } else {
        this.relativeCurrentTime = cursorNewPosition
      }

      this.calculatePercents()
    }
  }
}
