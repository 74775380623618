import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-copy-button',
  templateUrl: 'copy-button.component.html',
  styleUrl: 'copy-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
  protected readonly deviceService = inject(DeviceService)
  protected readonly messageService = inject(MessageService)

  @Input() text = ''
  @Input() tooltip = ''
  @Input() copiedTooltip = ''
  @Input() tooltipPosition: 'left' | 'top' | 'bottom' | 'right' = 'top'
  @Input() message = ''
  @Input() iconStyle: 'solid' | 'outlined' = 'solid'

  protected async onCopy() {
    await navigator.clipboard.writeText(this.text)
    if (this.deviceService.isMobilePlatform && this.message) {
      this.messageService.add({
        severity: 'success',
        detail: this.message,
        life: 2000,
      })
    }
  }
}
