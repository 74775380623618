<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (state.items$ | async)?.length === 0 }">
  <app-data-grid
    (loadMoreClicked)="onLoadMoreClicked()"
    (rowItemClicked)="rowItemClicked.emit($event)"
    (sortUpdated)="onSortUpdated($event)"
    [columns]="(state.columns$ | async) ?? []"
    [data]="(state.items$ | async) ?? []"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [noDataIconTemplate]="
      model.isSearchOrFilterApplied ? gridEmptySearchIconTmpl : gridEmptyStateIconTmpl
    "
    [noDataTitle]="
      (model.isSearchOrFilterApplied
        ? 'search.no-data-title'
        : 'tsp-admin.fleets.grid.no-data-title'
      ) | translate
    "
    [noDataDescription]="
      (model.isSearchOrFilterApplied ? 'search.no-data-description' : '') | translate
    "
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [templates]="{ name, pricingPlanId, isActive }"
  ></app-data-grid>
</div>

<ng-template #gridEmptySearchIconTmpl>
  <app-icon-no-search></app-icon-no-search>
</ng-template>

<ng-template #gridEmptyStateIconTmpl>
  <app-icon-file-search></app-icon-file-search>
</ng-template>

<ng-template #name let-row="row">
  <div (click)="editFleet.emit(row)" class="fleet-name flex text-14">
    <app-icon-fleet [height]="40" [width]="40"></app-icon-fleet>
    <section class="fleet-info-container flex flex--col">
      <div class="fleet-name">
        {{ row.name }}
        @if (!row.isActive) {
          <p-tag [rounded]="true" class="alert fw-600 p-2-6">
            {{ 'tsp-admin.fleets.grid.suspended' | translate }}
          </p-tag>
        }
        @if (row.isDemo) {
          <p-tag [rounded]="true" class="warning fw-600 p-2-6">Demo</p-tag>
        }
      </div>

      <span class="fleet-email">{{ row.email }}</span>
    </section>
  </div>
</ng-template>

<!-- <ng-template #invoicesOverdue>
  <app-yes-badge [color]="'var(--color-alert-500)'" [value]="false"></app-yes-badge>
</ng-template> -->

<ng-template #pricingPlanId let-row="row">
  <div class="pricing-plan">
    {{ (pricingPlansProvider.pricingPlansLabels$ | async)?.[row.pricingPlanId] || '-' }}
  </div>
</ng-template>

<ng-template #isActive let-row="row">
  <app-yes-badge [color]="'var(--color-alert-500)'" [value]="!row.isActive"></app-yes-badge>
</ng-template>
