import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-circle',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="6.25"
        [attr.fill]="color"
        fill-opacity="0.12"
        [attr.stroke]="color"
        style="fill-opacity:0.12;stroke-opacity:1;"
        stroke-width="1.5"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CircleIconComponent extends BaseIconComponent {}
