<app-grid-controls
  (clearSearch)="model.setSearch('')"
  (searchChanged)="model.setSearch($event)"
  (sortChanged)="model.setOrder($event)"
  [actionButtonsTemplate]="actionButtons"
  [columns]="state.columns$ | async"
  [countTemplate]="count"
  [search]="state.search$ | async"
  [sortOrder]="state.gridSortOrder$ | async"
  [defaultSortOrder]="state.defaultSortOrder$ | async"
  [showMobileMenuButton]="false"
  titleText="tsp-admin.fleets.title"
></app-grid-controls>

<ng-template #count>
  <app-counter [brackets]="false" [text]="''" [value]="(state.count$ | async) ?? 0">
    <app-icon-fleets-count [height]="22" [width]="22"></app-icon-fleets-count>
  </app-counter>
</ng-template>

<ng-template #actionButtons let-isMobile="isMobile">
  <p-button
    class="header-button h--40 p--18 text-14 fw-500"
    [appCheckAccess]="[Resource.Fleet, Action.Create]"
    [icon]="isMobile ? 'pi' : ''"
    [severity]="isMobile ? 'secondary' : 'primary'"
    (click)="openFleetLaunchDialog()"
  >
    <app-icon-plus [height]="20" [width]="20" class="icon"></app-icon-plus>
    @if (!isMobile) {
      {{ 'tsp-admin.fleets.grid.launch-new-btn' | translate }}
    }
  </p-button>
</ng-template>

<app-tsp-fleets-grid
  (editFleet)="openFleetPage($event)"
  (rowItemClicked)="openFleetPage($event)"
  [class.hidden]="model.state.selectedItem$ | async"
></app-tsp-fleets-grid>

@if (showLaunchForm$ | async) {
  <app-tsp-admin-add-fleet-dialog
    [opened]="true"
    [processing]="!!(launchingFleet$ | async)"
    [errorMessage]="(state.errorMessage$ | async) || ''"
    (launch)="launchFleet($event)"
    (cancel)="showLaunchForm$.next(false)"
  ></app-tsp-admin-add-fleet-dialog>
}
