export interface DnsVerificationRecord {
  hostname: string
  type: string
  value: string
}

export interface DomainAssociation {
  status: DnsVerificationStatus
  dnsVerification: DnsVerificationRecord[]
}

export enum DnsVerificationStatus {
  Available = 'AVAILABLE',
  PendingVerification = 'PENDING_VERIFICATION',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
}
