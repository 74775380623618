import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-download',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 15C22 14.4477 21.5523 14 21 14C20.4477 14 20 14.4477 20 15H22ZM3 19H2H3ZM4 15C4 14.4477 3.55228 14 3 14C2.44772 14 2 14.4477 2 15H4ZM7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L7.70711 9.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289L17.7071 10.7071ZM13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3H13ZM20 15V19H22V15H20ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071L21.1213 21.1213C21.6839 20.5587 22 19.7957 22 19H20ZM19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20V22C19.7957 22 20.5587 21.6839 21.1213 21.1213L19.7071 19.7071ZM19 20H5V22H19V20ZM5 20C4.73478 20 4.48043 19.8946 4.29289 19.7071L2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22V20ZM4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19H2C2 19.7957 2.31607 20.5587 2.87868 21.1213L4.29289 19.7071ZM4 19V15H2V19H4ZM6.29289 10.7071L11.2929 15.7071L12.7071 14.2929L7.70711 9.29289L6.29289 10.7071ZM12.7071 15.7071L17.7071 10.7071L16.2929 9.29289L11.2929 14.2929L12.7071 15.7071ZM13 15V3H11V15H13Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class DownloadIconComponent extends BaseIconComponent {}
