import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-stop-solid',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" [attr.fill]="color" style="fill-opacity:1;" />
      <g clip-path="url(#clip0_608_8835)">
        <rect
          x="5"
          y="5"
          width="6"
          height="6"
          rx="1"
          fill="#F2F4F7"
          style="fill:#F2F4F7;fill:color(display-p3 0.9490 0.9569 0.9686);fill-opacity:1;"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class StopSolidIconComponent extends BaseIconComponent {
  @Input() override width = 16
  @Input() override height = 16
}
