import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-csv-file-colorful',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3406_143012)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.91477 0C0.864015 0 0.0123901 0.852 0.0123901 1.90275V22.0931C0.0123901 23.1443 0.864015 23.9959 1.91477 23.9959H19.08C20.1304 23.9959 20.9828 23.1443 20.9828 22.0931V7.61625L13.8941 0L1.91477 0Z"
          fill="#45B058"
          style="fill:#45B058;fill:color(display-p3 0.2706 0.6902 0.3451);fill-opacity:1;"
        />
        <path
          d="M7.61478 16.1983C7.66203 16.2523 7.68903 16.3198 7.68903 16.394C7.68903 16.5358 7.57428 16.664 7.4254 16.664C7.3579 16.664 7.28365 16.637 7.2364 16.5763C6.97315 16.259 6.52728 16.0561 6.10878 16.0561C5.1229 16.0561 4.37365 16.8125 4.37365 17.8861C4.37365 18.953 5.12328 19.7158 6.10878 19.7158C6.53403 19.7158 6.95253 19.5335 7.2364 19.2024C7.28328 19.1484 7.3579 19.115 7.4254 19.115C7.58103 19.115 7.68903 19.2496 7.68903 19.3918C7.68903 19.4593 7.66203 19.5268 7.61478 19.5808C7.2634 19.9453 6.78415 20.2156 6.10915 20.2156C4.81278 20.2156 3.79315 19.2703 3.79315 17.8861C3.79315 16.502 4.81278 15.5566 6.10915 15.5566C6.78415 15.557 7.27015 15.827 7.61478 16.1983ZM10.1655 20.216C9.49728 20.216 8.9704 19.9933 8.58565 19.6554C8.5249 19.6014 8.4979 19.5271 8.4979 19.4529C8.4979 19.3175 8.59915 19.169 8.76115 19.169C8.81515 19.169 8.8759 19.1825 8.92315 19.223C9.23365 19.5001 9.66565 19.7158 10.1858 19.7158C10.9894 19.7158 11.2459 19.2838 11.2459 18.9391C11.2459 17.7778 8.5789 18.4194 8.5789 16.8121C8.5789 16.0696 9.2404 15.563 10.125 15.563C10.7055 15.563 11.2054 15.7381 11.5699 16.0423C11.6307 16.0963 11.6644 16.1705 11.6644 16.2448C11.6644 16.3798 11.5497 16.5148 11.4008 16.5148C11.3468 16.5148 11.286 16.4945 11.2388 16.454C10.908 16.184 10.4963 16.0625 10.0842 16.0625C9.54415 16.0625 9.15903 16.3528 9.15903 16.7784C9.15903 17.7913 11.826 17.2104 11.826 18.8919C11.8264 19.5474 11.3809 20.216 10.1655 20.216ZM16.5383 15.9958L14.9787 19.8714C14.9044 20.0536 14.7218 20.1751 14.5332 20.1751H14.5197C14.3239 20.1751 14.1417 20.0536 14.067 19.8714L12.5138 15.9958C12.5003 15.962 12.4935 15.9283 12.4935 15.8878C12.4935 15.7528 12.6147 15.5904 12.7973 15.5904C12.912 15.5904 13.02 15.6579 13.0673 15.7726L14.5257 19.5196L15.984 15.7726C16.0245 15.6646 16.1325 15.5904 16.254 15.5904C16.4295 15.5904 16.5578 15.7321 16.5578 15.8878C16.5582 15.9215 16.5514 15.962 16.5383 15.9958Z"
          fill="white"
          style="fill:white;fill-opacity:1;"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0004 7.63427V8.00927H16.2004C16.2004 8.00927 13.8334 7.53677 13.9024 5.49414C13.9024 5.49414 13.9804 7.63427 16.1535 7.63427H21.0004Z"
          fill="#349C42"
          style="fill:#349C42;fill:color(display-p3 0.2039 0.6118 0.2588);fill-opacity:1;"
        />
        <path
          opacity="0.5"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9117 0.00195312V5.46233C13.9117 6.08333 14.3257 7.63395 16.2007 7.63395H21.0007L13.9117 0.00195312Z"
          fill="white"
          style="fill:white;fill-opacity:1;"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class CSVFileColorfulIconComponent extends BaseIconComponent {
  @Input() override height = 24
  @Input() override width = 21
}
