import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AppCommonModule } from '@ti-platform/web/common/common.module'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import {
  PersonalMenuComponent,
  UserAddDialogComponent,
  UserEditDialogComponent,
  UserFormComponent,
  UserSettingsDialogComponent,
  UsersGridComponent,
} from './components'
import { UserRolesProvider } from './providers'

const COMPONENTS = [
  PersonalMenuComponent,
  UserAddDialogComponent,
  UserEditDialogComponent,
  UserFormComponent,
  UsersGridComponent,
  UserSettingsDialogComponent,
]

@NgModule({
  imports: [CommonModule, AppCommonModule, UiKitModule],
  exports: [COMPONENTS],
  declarations: [COMPONENTS],
  providers: [UserRolesProvider],
})
export class UserModule {}
