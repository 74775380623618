import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-trip-distance',
  template: `
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="48"
        height="48"
        rx="24"
        fill="#F9F5FF"
        style="fill:#F9F5FF;fill:color(display-p3 0.9765 0.9608 1.0000);fill-opacity:1;"
      />
      <path
        d="M18.499 17.2451C18.3611 17.2457 18.2496 17.3578 18.25 17.4958C18.2504 17.6337 18.3624 17.7453 18.5003 17.7451C18.6383 17.7449 18.75 17.6331 18.75 17.4951C18.7504 17.4285 18.724 17.3646 18.6768 17.3176C18.6296 17.2706 18.5656 17.2445 18.499 17.2451"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 17.5V17.5C15 15.567 16.567 14 18.5 14V14C20.433 14 22 15.567 22 17.5V17.5C22 19.1385 20.212 20.8888 19.192 21.7544C18.7892 22.0819 18.2118 22.0819 17.809 21.7544C16.788 20.8888 15 19.1385 15 17.5Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 29.2002C28 28.8399 28.1938 28.5075 28.5073 28.33C28.8208 28.1525 29.2056 28.1573 29.5145 28.3427L32.5145 30.1427C32.8157 30.3234 33 30.6489 33 31.0002C33 31.3515 32.8157 31.677 32.5145 31.8577L29.5145 33.6577C29.2056 33.8431 28.8208 33.8479 28.5073 33.6704C28.1938 33.4929 28 33.1605 28 32.8002V29.2002Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.234 30.9951H20.627C19.0767 30.8858 17.9043 29.5473 18 27.9961V27.9931C17.9043 26.4419 19.0767 25.1035 20.627 24.9941H27.372C28.9223 24.8848 30.0947 23.5463 29.999 21.9951C30.0963 20.4424 28.922 19.1024 27.37 18.9951L25 19.0001"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class TripDistanceIconComponent extends BaseIconComponent {}
