import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-counter',
  templateUrl: 'counter.component.html',
  styleUrl: 'counter.component.scss',
})
export class CounterComponent {
  @Input() value?: number | null
  @Input() text?: string
  @Input() brackets?: boolean = true
}
