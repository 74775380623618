import { Component, inject, OnInit, signal } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Action, DnsVerificationStatus, Resource, WhiteLabelSettings } from '@ti-platform/contracts'
import { DomainAssociationProvider } from '@ti-platform/tsp-admin/app/white-label/services/domain-association.provider'
import { ApiService } from '@ti-platform/web/api'
import {
  CONFIG,
  injectDestroy$,
  Memoize,
  WhiteLabelSettingsProvider,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { MessageService } from 'primeng/api'
import { BehaviorSubject, firstValueFrom, map, takeUntil } from 'rxjs'

export enum ImageSize {
  Large,
  Compact,
}

@Component({
  selector: 'app-white-label',
  templateUrl: 'white-label-page.component.html',
  styleUrl: 'white-label-page.component.scss',
})
export class TspAdminWhiteLabelPageComponent implements OnInit {
  protected readonly api = inject(ApiService)
  protected readonly config = inject(CONFIG)
  protected readonly messageService = inject(MessageService)
  protected readonly languageService = inject(LanguageService)
  protected readonly whiteLabelSettings = inject(WhiteLabelSettingsProvider)
  protected readonly domainAssociation = inject(DomainAssociationProvider)
  protected readonly destroy$ = injectDestroy$()

  protected readonly croppedImageDataUrl$ = new BehaviorSubject<string>('')
  protected readonly selectedImageDataUrl$ = new BehaviorSubject<string>('')
  protected readonly currentImageSize$ = new BehaviorSubject<ImageSize | undefined>(undefined)

  protected readonly isLoading = signal(true)
  protected readonly hasChanges = signal(false)
  protected readonly errorMessage = signal('')
  protected readonly cropperDialogOpened = signal(false)

  protected readonly form = new FormGroup({
    domain: new FormControl<string>('', []),
    logoLargeUrl: new FormControl<string | undefined>('', []),
    logoCompactUrl: new FormControl<string | undefined>('', []),
    googleMapsKey: new FormControl<string>(''),
  })

  protected domainEditDisabled = false
  protected isCustomDomainSet = false
  protected domainInstructionsReady = false

  protected readonly Action = Action
  protected readonly Resource = Resource
  protected readonly ImageSize = ImageSize

  @Memoize()
  protected get cropperDimensions$() {
    return this.currentImageSize$.pipe(
      map((size) => {
        const height = 144
        const width = size === ImageSize.Large ? 432 : 144
        return {
          height,
          width,
          ratio: width / height,
        }
      }),
    )
  }

  public async ngOnInit() {
    this.domainAssociation.data$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (
        data.dnsVerification.length > 0 &&
        (data.status === DnsVerificationStatus.PendingVerification ||
          data.status === DnsVerificationStatus.Available)
      ) {
        this.domainInstructionsReady = true
      }
    })
    this.whiteLabelSettings.data$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.domain && data.domain !== this.config.fleetDefaultDomain) {
        this.isCustomDomainSet = true
        this.domainEditDisabled = true
        this.form.controls.domain.disable({ emitEvent: false })
      }

      if (!this.hasChanges()) {
        this.form.setValue(
          {
            domain: data.domain || this.config.fleetDefaultDomain,
            logoLargeUrl: data.logoLargeUrl,
            logoCompactUrl: data.logoCompactUrl,
            googleMapsKey: data.googleMapsKey,
          },
          { emitEvent: false },
        )
      }
    })

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.hasChanges.set(true))
  }

  protected async saveChanges() {
    if (!this.form.valid) {
      return this.form.markAllAsTouched()
    }

    try {
      this.isLoading.set(true)
      const tasks = new Array<Promise<unknown>>()
      const formData = { ...this.form.value } as WhiteLabelSettings
      const initialData = await firstValueFrom(this.whiteLabelSettings.data$)

      if (formData.domain) {
        formData.domain = formData.domain
          .replace(/(https*:\/\/)/, '')
          .replace(/\/$/, '')
          .trim()
        if (formData.domain === this.config.fleetDefaultDomain) {
          formData.domain = ''
        }
      }
      if (!this.domainEditDisabled && formData.domain !== initialData.domain) {
        tasks.push(this.api.tsp.whiteLabel.setDomain(formData.domain))
      }
      if (formData.logoLargeUrl !== initialData.logoLargeUrl) {
        const base64Data = formData.logoLargeUrl.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
        if (base64Data) {
          tasks.push(this.api.tsp.whiteLabel.uploadLargeLogo(base64Data[2], base64Data[1]))
        } else if (formData.logoLargeUrl === '') {
          tasks.push(this.api.tsp.whiteLabel.removeLargeLogo())
        }
      }
      if (formData.logoCompactUrl !== initialData.logoCompactUrl) {
        const base64Data = formData.logoCompactUrl.match(/^data:([A-Za-z-+/]+);base64,(.+)$/)
        if (base64Data) {
          tasks.push(this.api.tsp.whiteLabel.uploadCompactLogo(base64Data[2], base64Data[1]))
        } else if (formData.logoCompactUrl === '') {
          tasks.push(this.api.tsp.whiteLabel.removeCompactLogo())
        }
      }
      if (formData.googleMapsKey !== initialData.googleMapsKey) {
        tasks.push(this.api.tsp.whiteLabel.setGoogleMapsKey(formData.googleMapsKey))
      }

      await Promise.all(tasks)
      this.whiteLabelSettings.refresh()
      this.domainAssociation.refresh()

      const labels = await this.languageService.massTranslate({
        summary: 'tsp-admin.white-label.updated-message.summary',
        detail: 'tsp-admin.white-label.updated-message.detail',
      })
      this.messageService.add({ ...labels, severity: 'success', life: 3000 })
    } catch (error) {
      console.error(`Cannot save white-label changes`, error)
    } finally {
      this.isLoading.set(false)
      this.hasChanges.set(false)
    }
  }

  protected loadLogoImage(file: File, imageSize: ImageSize) {
    this.cropperDialogOpened.set(true)
    this.currentImageSize$.next(imageSize)
    this.imageToDataURL(file).then((base64) => this.selectedImageDataUrl$.next(base64))
  }

  protected onLogoImageFileSelected(event: Event, imageSize: ImageSize) {
    const target = event.target as HTMLInputElement
    if (target?.files?.length) {
      this.loadLogoImage(target.files[0], imageSize)
      target.value = ''
    }
  }

  protected imageToDataURL(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(typeof reader.result === 'string' ? reader.result : '')
      reader.readAsDataURL(file)
    })
  }

  protected imageCropped(event: ImageCroppedEvent) {
    if (event.base64) {
      this.croppedImageDataUrl$.next(event.base64)
    }
  }

  protected deleteCroppedImage(imageSize: ImageSize) {
    switch (imageSize) {
      case ImageSize.Large:
        this.form.patchValue({ logoLargeUrl: '' })
        break
      case ImageSize.Compact:
        this.form.patchValue({ logoCompactUrl: '' })
        break
    }
  }

  protected onCroppedImageSave() {
    switch (this.currentImageSize$.value) {
      case ImageSize.Large:
        this.form.patchValue({ logoLargeUrl: this.croppedImageDataUrl$.value })
        break
      case ImageSize.Compact:
        this.form.patchValue({ logoCompactUrl: this.croppedImageDataUrl$.value })
        break
    }
    this.clearCropperData()
    this.cropperDialogOpened.set(false)
  }

  protected onImageCancel() {
    this.clearCropperData()
    this.cropperDialogOpened.set(false)
  }

  protected clearCropperData() {
    this.croppedImageDataUrl$.next('')
    this.selectedImageDataUrl$.next('')
  }
}
